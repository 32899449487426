// import Qrscan from "components/Schedule/Qrscan";
import React, { useEffect, useState } from "react";
import { useGeolocated, GeolocatedConfig } from "react-geolocated";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";

import location from "../assets/lotties/location";
import Invalid from "../assets/lotties/Invalid";
import welcome from "../assets/lotties/welcome-animation";
import Lottie from "react-lottie";
import { ScanQR } from "actions/OperationAction";
import jwt from "jwt-decode";
import { checkUserReservation } from "actions/OperationAction";
import moment from "moment-business-days";
import { fetchBuildingByID } from "actions/BuildingAction";
import ClipLoader from "react-spinners/ClipLoader";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
const color = "#294e87";

function handlePermission() {
  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    if (result.state === "granted") {
      report(result.state);
      //   geoBtn.style.display = "none";
    } else if (result.state === "prompt") {
      report(result.state);
      //   geoBtn.style.display = "none";
      //   navigator.geolocation.getCurrentPosition(
      //     revealPosition,
      //     positionDenied,
      //     geoSettings
      //   );
    } else if (result.state === "denied") {
      report(result.state);
      //   geoBtn.style.display = "inline";
    }
    result.addEventListener("change", () => {
      report(result.state);
    });
  });
}

function report(state) {}
const locationOptions = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const welcomeOptions = {
  loop: true,
  autoplay: true,
  animationData: welcome,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const invalidOptions = {
  loop: true,
  autoplay: true,
  animationData: Invalid,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Scan = (props) => {
  const [lat, setlat] = useState("");
  const [lng, setLng] = useState("");
  const { open, setOpen } = props;
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const [qrcode, setQrQode] = useState("");
  var date = moment();
  var slot = moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A");
  const [message, setMessage] = useState({ type: undefined, text: undefined });

  const user = jwt(token);
  const { t } = useTranslation();
  //   handlePermission();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      //   positionOptions: {
      //     enableHighAccuracy: false,
      //   },
      //   userDecisionTimeout: 5000,
      positionOptions: {
        enableHighAccuracy: false,
        maximumAge: 10,
        timeout: Infinity,
      },
      watchPosition: false,
      userDecisionTimeout: null,
      suppressLocationOnMount: false,
      geolocationProvider: navigator.geolocation,
      isOptimisticGeolocationEnabled: true,
    });

  useEffect(() => {
    handlePermission();
  }, []);

  useEffect(() => {
    if (coords) {
      setlat(coords.latitude);
      setLng(coords.longitude);
    }
  }, [coords]);

  useEffect(() => {
    const fetch = async () => {
      //   console.log("date,", date, "slot", slot);
      var result = await checkUserReservation(
        user.id,
        date.format().substring(0, 10),
        slot,
        "desk"
      );
      console.log("🚀 ~ result:", result);
      if (!result) {
        setMessage({
          type: "error",
          text: t("You don't have a reservation for this timeslot"),
        });
      } else {
        var building = await fetchBuildingByID(result.desk.zone.floor.building);
        var latBuilding = building.latitude;
        var lngBuilding = building.longitude;

        setQrQode(result?.desk?.QRCode);

        // console.log(
        //   "hhhhhhhhhhhhhhhhh",
        //   coords.latitude,
        //   coords.longitude,
        //   Number(latBuilding),
        //   lngBuilding,
        //   Math.abs(coords.latitude - Number(latBuilding)).toFixed(2)
        // );

        // Math.abs(a - b)

        // if (
        //   // coords.latitude.toString() != latBuilding &&
        //   // coords.longitude.toString() != lngBuilding
        //   Math.abs(coords.latitude - Number(latBuilding)).toFixed(2) > 0.02 &&
        //   Math.abs(coords.longitude - Number(lngBuilding)).toFixed(2) > 0.02
        // ) {
        //   setMessage({
        //     type: "location",
        //     text: "Wrong location ! You are not in the building",
        //   });
        // } else {
        // setMessage({
        //   type: "location",
        //   text: "Checkin" + coords.latitude + coords.longitude,
        // });
        // var res = await ScanQR(user.id, result.desk.QRCode);
        // console.log("🚀 ~~ res:", res);
        // if (res.data == "not available") {
        //   setMessage({ type: "error", text: t("Reservation NOT found!") });
        // } else if (res.data == "checkOut") {
        //   setMessage({ type: "success", text: t("See you next time!") });
        // } else if (res.data == "checkIn") {
        //   setMessage({ type: "success", text: t("Welcome!") });
        // }
        // }
      }
    };

    // coords && fetch();
    fetch();
  }, []);

  useEffect(() => {
    checkin();
  }, [qrcode]);

  const checkin = async () => {
    var res = await ScanQR(user.id, qrcode);
    console.log("🚀 ~~ res:", res);
    if (res.data == "not available") {
      setMessage({ type: "error", text: t("Reservation NOT found!") });
    } else if (res.data == "checkOut") {
      setMessage({ type: "success", text: t("See you next time!") });
    } else if (res.data == "checkIn") {
      setMessage({ type: "success", text: t("Welcome!") });
    }
  };

  //   useEffect(() => {
  //     handlePermission();
  //     if ("geolocation" in navigator) {
  //       console.log("Available");
  //     } else {
  //       console.log("Not Available");
  //     }
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       console.log("position", position);
  //       console.log("Latitude is :", position.coords.latitude);
  //       console.log("Longitude is :", position.coords.longitude);
  //       setlat(position.coords.latitude);
  //       setLng(position.coords.longitude);
  //     });
  //   }, [navigator.geolocation]);
  //   return (
  //     <div>
  //       latitude: {lat}
  //       longitude: {lng}
  //       <Qrscan />
  //     </div>
  //   );

  // console.log("coords", coords);

  return (
    <Dialog open={open} size="md">
      {/* <div className="lex flex-wrap justify-center">
        <DialogHeader>
          <div className="text-center">
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        </DialogHeader>
      </div> */}
      <div>
        {message.text != undefined && (
          <Lottie
            options={
              message.type == "error"
                ? invalidOptions
                : message.type == "success"
                ? welcomeOptions
                : locationOptions
            }
            height={200}
            width={200}
          />
        )}
      </div>
      {/* <DialogBody> */}
      <div
        className={
          isDesktop
            ? "flex content-center items-center justify-center"
            : "flex content-center items-center justify-center flex-wrap"
        }
      >
        <div className="text-center">
          <Typography variant="text" style={{ color: color }}>
            {
              // !isGeolocationAvailable ? (
              //   <div>
              //     <Lottie options={locationOptions} height={50} width={50} />
              //     Your browser does not support Geolocation
              //   </div>
              // ) : !isGeolocationEnabled ? (
              //   <div>
              //     <Lottie
              //       options={locationOptions}
              //       height={isDesktop ? 200 : 70}
              //       width={isDesktop ? 200 : 70}
              //     />
              //     Geolocation is not enabled
              //   </div>
              // ) :
              // coords ? (
              //   coords.latitude == "" && coords.longitude == "" ? (
              //   coords.latitude == "36.8800831" &&
              //   coords.longitude == "10.1927499" ? (
              //     <div>
              //       You have booked the desk :
              //       <br />
              //       <Button
              //         variant="gradient"
              //         color="green"
              //         className="mt-2"
              //         onClick={checkin}
              //       >
              //         <span>Check-In</span>
              //       </Button>
              //     </div>
              //   ) : (
              //     <div>Wrong location</div>
              //   )
              message.type != undefined ? (
                message.text
              ) : (
                <div className="text-center mt-5">
                  <ClipLoader color="blue" loading={true} size={50} />
                </div>
              )
              // ) : (
              // <table>
              //   <tbody>
              //     <tr>
              //       <td>latitude</td>
              //       <td>{coords.latitude}</td>
              //     </tr>
              //     <tr>
              //       <td>longitude</td>
              //       <td>{coords.longitude}</td>
              //     </tr>
              //     <tr>
              //       <td>altitude</td>
              //       <td>{coords.altitude}</td>
              //     </tr>
              //     <tr>
              //       <td>heading</td>
              //       <td>{coords.heading}</td>
              //     </tr>
              //     <tr>
              //       <td>speed</td>
              //       <td>{coords.speed}</td>
              //     </tr>
              //   </tbody>
              // </table>
              // <div>Getting the location data&hellip; </div>
              // )
            }
          </Typography>
        </div>
      </div>
      {/* </DialogBody> */}
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={() => setOpen(false)}
          className="mr-1"
        >
          <span>
            <Translation message={"Close".toLowerCase()} />
          </span>
        </Button>
        {/* <Button
          variant="gradient"
          color="green"
          //   onClick={() => handleOpen(null)}
        >
          <span>Confirm</span>
        </Button> */}
      </DialogFooter>
    </Dialog>
  );
  //   console.log("coords", coords);
  //   return !isGeolocationAvailable ? (
  //     <div>Your browser does not support Geolocation</div>
  //   ) : !isGeolocationEnabled ? (
  //     <div>Geolocation is not enabled</div>
  //   ) : coords ? (
  //     <table>
  //       <tbody>
  //         <tr>
  //           <td>latitude</td>
  //           <td>{coords.latitude}</td>
  //         </tr>
  //         <tr>
  //           <td>longitude</td>
  //           <td>{coords.longitude}</td>
  //         </tr>
  //         <tr>
  //           <td>altitude</td>
  //           <td>{coords.altitude}</td>
  //         </tr>
  //         <tr>
  //           <td>heading</td>
  //           <td>{coords.heading}</td>
  //         </tr>
  //         <tr>
  //           <td>speed</td>
  //           <td>{coords.speed}</td>
  //         </tr>
  //       </tbody>
  //     </table>
  //   ) : (
  //     <div>Getting the location data&hellip; </div>
  //   );
};

export default Scan;
