import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  ButtonGroup,
  IconButton,
} from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import { Link } from "react-router-dom";
import "./style.css";
import Legend from "./Maps/Legend";
import Autocomplete from "react-autocomplete";
import Floors from "./Maps/Floors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { fetchHorizon } from "actions/RessourcesAction";
import moment from "moment";
import "dayjs/locale/fr";
import { format } from "date-fns";
import { addDays } from "date-fns";

const color = "#294e87";

const DialogScreen = ({ open, setOpen }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];
  const [colleague, setColleague] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );

  const [horizon, setHorizon] = useState();
  const { i18n, t } = useTranslation();

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchHorizon();
      setHorizon(data.NBDays);
    };
    fetch();
  }, []);

  return (
    <div>
      <Dialog
        open={open.open}
        size="xl"
        handler={() => {
          setOpen({ open: false, card: {} });
        }}
      >
        <DialogBody>
          <div
            className={
              isDesktop
                ? "flex content-center items-center justify-center"
                : "flex content-center items-center justify-center flex-wrap"
            }
          >
            {open.card.name == "maps" ? (
              <div className="flex-col">
                {/* <div className="flex flex-row place-items-center mt-2">
                  <ButtonGroup
                    variant="outlined"
                    color="blue"
                    size="sm"
                    className="min-w-[12rem]"
                  >
                    <Button>Floor 7</Button>
                    <Button>Floor 8</Button>
                  </ButtonGroup>

                  <div className="relative flex w-full  max-w-[20rem] ml-2">
                    <Input
                      type="date"
                      label="Date"
                      // value={email}
                      // onChange={onChange}

                      containerProps={{
                        className: "min-w-0",
                      }}
                    />
                  </div>
                  <div className="relative flex w-full  max-w-[20rem] ml-2">
                    <Autocomplete
                      getItemValue={(item) => item}
                      items={options}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                          }}
                        >
                          {item}
                        </div>
                      )}
                      value={colleague}
                      onChange={(e) => setColleague(e.target.value)}
                      onSelect={(value) => setColleague(value)}
                      renderInput={(params) => (
                        // <TextField
                        //   // style={{ height: "0.5em" }}
                        //   {...params}
                        //   label="Select a colleague"
                        //   variant="outlined"
                        //   InputProps={{ ...params.InputProps, type: "search" }}
                        //   // onChange={handleInputChange}
                        //   onChange={(e) => setColleague(e.target.value)}
                        // />
                        <Input
                          {...params}
                          type="text"
                          label="Search colleague"
                          value={colleague}
                          InputProps={{ ...params.InputProps, type: "search" }}
                          onChange={(e) => setColleague(e.target.value)}
                          className="pr-20"
                          containerProps={{
                            className: "min-w-0",
                          }}
                        />
                      )}
                    />

                   
                  </div>
                </div> */}

                <div className="flex flex-row justify-between mt-2">
                  {/* <h2 className="text-blue-gray-700">Floor {numberFloor} </h2> */}
                  <div className="relative flex w-full  max-w-[20rem] ml-2">
                    <Input
                      type="date"
                      label="Date"
                      value={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e.target.value);
                      }}
                      containerProps={{
                        className: "min-w-0",
                      }}
                      min={new Date().toISOString().split("T")[0]}
                      max={
                        new Date(moment().add("days", horizon - 1)._d)
                          .toISOString()
                          .split("T")[0]
                      }
                    />
                    {/* <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n?.language}
                      className="z-[99999]"
                    >
                      <DatePicker
                        className="z-[99999]"
                        label="Date"
                        minDate={new Date()}
                        // maxDate={momentBusinessDays().businessAdd(horizon)._d}
                        maxDate={moment().add("days", horizon - 1)._d}
                        inputFormat="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={(newValue) => {
                          setSelectedDate(new Date(newValue));
                        }}
                        renderInput={(params) => (
                          <TextField onKeyDown={onKeyDown} {...params} />
                        )}
                        
                      />
                    </LocalizationProvider> */}
                  </div>
                  <Link style={{ outline: "none" }} to={"/reservation"}>
                    <IconButton
                      size="sm"
                      color="indigo"
                      title={t("Book Now".toLowerCase())}
                    >
                      <i className="fas fa-building" />
                    </IconButton>
                  </Link>
                </div>

                <div style={{ width: "750px" }}>
                  <Legend />
                  <Floors
                    // selectedDate={selectedDate.toISOString().substring(0, 10)}
                    selectedDate={selectedDate}
                  />
                </div>
              </div>
            ) : (
              <>
                <img src={open.card.img} width={"60%"}></img>
                <div className="ml-5 text-center">
                  <Typography variant="h3" style={{ color: color }}>
                    {t(open.card.title)}
                  </Typography>
                  <div className="mt-5">{t(open.card.paragraph)}</div>
                  <div className="flex content-center items-center justify-center ">
                    {open.card.linkRequests && (
                      <Link
                        style={{ outline: "none" }}
                        to={open.card.linkRequests}
                      >
                        <Button
                          className="mt-5"
                          variant="gradient"
                          color="indigo"
                          style={{ outline: "none" }}
                        >
                          <span>{t("My requests")}</span>
                        </Button>
                      </Link>
                    )}

                    {open.card.linkAddPeriod && (
                      <Link
                        style={{ outline: "none" }}
                        to={`${open.card.linkAddPeriod}/?startDate=${format(
                          new Date(),
                          "yyyy-MM-dd"
                        )}&endDate=${format(
                          addDays(new Date(), 1),
                          "yyyy-MM-dd"
                        )}`}
                      >
                        <Button
                          className="mt-5 ml-5"
                          variant="gradient"
                          color="indigo"
                          style={{ outline: "none" }}
                        >
                          <span>{t("Book for a period".toUpperCase())}</span>
                        </Button>
                      </Link>
                    )}

                    {open.card.linkAdd && (
                      <Link style={{ outline: "none" }} to={open.card.linkAdd}>
                        <Button
                          className="mt-5 ml-5"
                          variant="outlined"
                          color="indigo"
                          style={{ outline: "none" }}
                        >
                          <span>{t("New Request".toUpperCase())}</span>
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpen({ open: false, card: {} })}
            className="mr-1"
          >
            <span>{t("Close")}</span>
          </Button>
          {/* <Button
            variant="gradient"
            color="green"
            onClick={() => handleOpen(null)}
          >
            <span>Confirm</span>
          </Button> */}
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DialogScreen;
