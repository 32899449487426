import { Input, Textarea, Typography } from "@material-tailwind/react";
import { Add, Minimize } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { fetchPerdiem } from "actions/travel.service";
import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { differenceInDays } from "date-fns";
import { fetchCurrencies } from "actions/travel.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import Translation from "Translation";
import "../../pages/style.css";

const PerdiemPanel = ({
  request,
  edit,
  handleFormChange,
  formData,
  formules,
  selectedCountry,
  currentUser,
  missionData,
}) => {
  const [perdiem, setPerdiem] = useState(null);
  const [perdiems, setPerdiems] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [estimatedCosts, setEstimatedCosts] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCurrencies();
      if (response.status == 200) {
        setCurrencies(response.data);
      }
      if (
        request?.mission?.missionCountry &&
        request?.mission?.Formula?._id &&
        request?.idSender?.grade?._id
      ) {
        // const res = await fetchPerdiem(
        //   request?.mission?.missionCountry?.region?._id,
        //   request?.mission?.Formula?._id,
        //   request?.idSender?.grade?._id
        // );
        // setPerdiem(res);
      }

      if (request?.mission?.expenses?.perdiems) {
        setPerdiems(request.mission.expenses.perdiems);
      }
    };
    fetchData();
  }, [request]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await fetchPerdiem(
  //       formData?.missionCountry
  //         ? formData?.missionCountry?.region?._id
  //         : request?.mission?.missionCountry?.region?._id,
  //       formData?.Formula
  //         ? formData?.Formula?._id
  //         : request?.mission?.Formula?._id,
  //       request?.idSender?.grade?._id
  //     );
  //     setPerdiem(res);
  //   };

  //   request && fetchData();
  // }, [formData?.Formula, formData?.missionCountry]);

  // useEffect(() => {
  //   const updatePerdiems = async () => {
  //     let updatedPerdiems = [];
  //     if (formData && formData["accomodation"]?.stay?.length > 0) {
  //       for (const stayEntry of formData["accomodation"]?.stay) {
  //         // Check if there is already a perdiem entry with the same dates
  //         if (stayEntry.startDate != null && stayEntry.endDate != null) {
  //           const existingPerdiem = updatedPerdiems.find(
  //             (perdiemEntry) =>
  //               perdiemEntry.startDate === stayEntry.startDate &&
  //               perdiemEntry.endDate === stayEntry.endDate
  //           );

  //           if (!existingPerdiem) {
  //             const formulaSelected = formules.find(
  //               (item) =>
  //                 item.type?.toLowerCase() == stayEntry.type?.toLowerCase()
  //             );
  //             const perdiemResult = await fetchPerdiem(
  //               request?.mission?.missionCountry?.region?._id,
  //               formulaSelected?._id,
  //               currentUser?.grade?._id
  //             );
  //             // Add the new perdiem result to the updatedPerdiems array
  //             updatedPerdiems.push({
  //               ...stayEntry,
  //               formula: formulaSelected,
  //               perdiem: perdiemResult,
  //               indemnity: perdiemResult?.indemnity,
  //               currency: perdiemResult?.currency?._id,
  //               nb:
  //                 differenceInDays(
  //                   new Date(stayEntry.endDate),
  //                   new Date(stayEntry.startDate)
  //                 ) + 1,
  //             });
  //           }
  //         }
  //       }
  //     } else if (formData?.Formula) {
  //       // If there are no stay entries, prepare perdiems based on formule type
  //       if (formData?.Formula?.type.toLowerCase() === "hybride") {
  //         // Prepare perdiems with two types: 'hotel' and 'personal accomodation'
  //         const formulaHotel = formules.find(
  //           (item) => item.type?.toLowerCase() == "hotel"
  //         );
  //         const formulaPersonal = formules.find(
  //           (item) => item.type?.toLowerCase() == "personal accomodation"
  //         );

  //         const perdiemHotel = await fetchPerdiem(
  //           request?.mission?.missionCountry?.region?._id,
  //           formulaHotel?._id,
  //           currentUser?.grade?._id
  //         );

  //         const perdiemPersonal = await fetchPerdiem(
  //           request?.mission?.missionCountry?.region?._id,
  //           formulaPersonal?._id,
  //           currentUser?.grade?._id
  //         );
  //         updatedPerdiems = [
  //           {
  //             type: "hotel",
  //             perdiem: perdiemHotel,
  //             indemnity: perdiemHotel?.indemnity,
  //             currency: perdiemHotel?.currency?._id,
  //           },
  //           {
  //             type: "personal accomodation",
  //             perdiem: perdiemPersonal,
  //             indemnity: perdiemPersonal?.indemnity,
  //             currency: perdiemPersonal?.currency?._id,
  //           },
  //         ];
  //       } else {
  //         // Prepare perdiems with a single entry based on the formule
  //         const perdiem = await fetchPerdiem(
  //           selectedCountry?.region?._id,
  //           formData?.Formula?._id,
  //           currentUser?.grade?._id
  //         );
  //         updatedPerdiems = [
  //           {
  //             type: formData?.Formula?.type?.toLowerCase(),
  //             perdiem: perdiem,
  //             startDate: request?.mission?.startDate,
  //             endDate: request?.mission?.endDate,
  //             indemnity: perdiem?.indemnity,
  //             currency: perdiem?.currency?.name,
  //             nb:
  //               differenceInDays(
  //                 new Date(request?.mission?.endDtae),
  //                 new Date(request?.mission?.startDate)
  //               ) + 1,
  //           },
  //         ];
  //       }
  //     }

  //     setPerdiems(updatedPerdiems);
  //   };

  //   updatePerdiems();
  // }, [formData, request]);
  useEffect(() => {
    const updatePerdiems = async () => {
      let updatedPerdiems = [];
      console.log("changement staaaaaaaaaaaaay", formData?.stay);
      if (formData?.stay?.length > 0) {
        for (const stayEntry of formData?.stay) {
          console.log("🚀 ~ updatePerdiems ~ stayEntry:", stayEntry);
          // Check if there is already a perdiem entry with the same dates
          if (stayEntry.startDate != null && stayEntry.endDate != null) {
            const existingPerdiem = updatedPerdiems.find(
              (perdiemEntry) =>
                perdiemEntry.startDate === stayEntry.startDate &&
                perdiemEntry.endDate === stayEntry.endDate
            );
            // console.log(
            //   "🚀 ~ updatePerdiems ~ existingPerdiem:",
            //   existingPerdiem
            // );

            // If there is no existing perdiem entry with the same dates, fetch and add it
            if (!existingPerdiem) {
              const formulaSelected = formules.find(
                (item) =>
                  item.type?.toLowerCase() == stayEntry.type?.toLowerCase()
              );

              if (request?.mission?.missionType == "simple") {
                var perdiemResult = await fetchPerdiem(
                  missionData?.missionCountry?.region?._id,
                  formulaSelected?._id,
                  currentUser?.grade?._id
                );
              } else {
                var perdiemResult = await fetchPerdiem(
                  stayEntry.country?.region?._id,
                  formulaSelected?._id,
                  currentUser?.grade?._id
                );
              }

              // Add the new perdiem result to the updatedPerdiems array
              updatedPerdiems.push({
                ...stayEntry,
                formula: formulaSelected,
                perdiem: perdiemResult,
                indemnity: perdiemResult?.indemnity,
                currency: perdiemResult?.currency?._id,
                nb:
                  differenceInDays(
                    new Date(stayEntry.endDate),
                    new Date(stayEntry.startDate)
                  ) + 1,
              });
            }
          }
        }
        setPerdiems(updatedPerdiems);
        console.log("🚀 ~ updatePerdiems ~ updatedPerdiems:", updatedPerdiems);

        handleFormChange({ perdiems: updatedPerdiems });
      } else if (formData?.Formula) {
        // If there are no stay entries, prepare perdiems based on formule type
        if (formData?.Formula.type.toLowerCase() === "hybride") {
          // Prepare perdiems with two types: 'hotel' and 'personal accomodation'
          const formulaHotel = formules.find(
            (item) => item.type?.toLowerCase() == "hotel"
          );
          const formulaPersonal = formules.find(
            (item) => item.type?.toLowerCase() == "personal accomodation"
          );

          const perdiemHotel = await fetchPerdiem(
            missionData?.missionCountry?.region?._id,
            formulaHotel?._id,
            currentUser?.grade?._id
          );

          const perdiemPersonal = await fetchPerdiem(
            missionData?.missionCountry?.region?._id,
            formulaPersonal?._id,
            currentUser?.grade?._id
          );
          updatedPerdiems.push({
            type: "hotel",
            perdiem: perdiemHotel,
            indemnity: perdiemHotel?.indemnity,
            currency: perdiemHotel?.currency?._id,
          });
          updatedPerdiems.push({
            type: "personal accomodation",
            perdiem: perdiemPersonal,
            indemnity: perdiemPersonal?.indemnity,
            currency: perdiemPersonal?.currency?._id,
          });
        } else {
          // Prepare perdiems with a single entry based on the formule
          const perdiem = await fetchPerdiem(
            missionData?.missionCountry?.region?._id,
            formData?.Formula?._id,
            currentUser?.grade?._id
          );
          updatedPerdiems.push({
            type: formData?.Formula.type?.toLowerCase(),
            perdiem: perdiem,
            startDate: request?.mission?.startDate,
            endDate: request?.mission?.endDate,
            indemnity: perdiem?.indemnity,
            currency: perdiem?.currency?.name,
            nb:
              differenceInDays(
                new Date(request?.mission?.endDtae),
                new Date(request?.mission?.startDate)
              ) + 1,
          });
        }

        setPerdiems(updatedPerdiems);
        console.log("🚀 ~ updatePerdiems ~ updatedPerdiems:", updatedPerdiems);

        handleFormChange({ perdiems: updatedPerdiems });
      }
    };

    updatePerdiems();
  }, [formData?.Formula, formData?.stay]);

  const handleAddRow = () => {
    let newPerdiems = {
      type: null,
      startDate: null,
      endDate: null,
    };

    setPerdiems([...perdiems, newPerdiems]);
    handleFormChange({ perdiems: newPerdiems });
  };
  const handleRemoveRow = (index) => {
    const values = [...perdiems];
    values.splice(index, 1);
    setPerdiems(values);
    handleFormChange({ perdiems: values });
  };
  const handleChangeType = async (index, event) => {
    let newPerdiems = [...perdiems];
    newPerdiems[index].type = event.target.value;
    const formulaSelected = formules.find(
      (item) =>
        item.type?.toLowerCase() == newPerdiems[index].type?.toLowerCase()
    );

    const perdiemResult = await fetchPerdiem(
      selectedCountry?.region?._id,
      formulaSelected?._id,
      currentUser?.grade?._id
    );

    newPerdiems[index].perdiem = perdiemResult;
    setPerdiems(newPerdiems);
    handleFormChange({ perdiems: newPerdiems });
  };

  const handleChangeStartDate = (date, index) => {
    const values = [...perdiems];
    values[index].startDate = date;

    setPerdiems(values);
    handleFormChange({ perdiems: values });
  };

  const handleChangeEndDate = (date, index) => {
    const values = [...perdiems];
    values[index].endDate = date;

    setPerdiems(values);
    handleFormChange({ perdiems: values });
  };

  useEffect(() => {
    const arraysPerDevise = {};

    perdiems?.forEach((objet) => {
      if (objet.indemnity && objet.nb && objet.currency) {
        // console.log('🚀 ~  objet stay:', objet);
        const { indemnity, nb, currency, extraExpenses } = objet;
        const curr = currency?._id ? currency?._id : currency;
        const extra = extraExpenses ? Number(extraExpenses) : 0;

        const total = indemnity * nb + extra;
        if (!arraysPerDevise[curr]) {
          arraysPerDevise[curr] = [total];
        } else {
          arraysPerDevise[curr].push(total);
        }
      }
    });

    const sums = {};

    for (const key in arraysPerDevise) {
      let sum = 0;
      for (let i = 0; i < arraysPerDevise[key].length; i++) {
        if (arraysPerDevise[key][i]) {
          sum += parseFloat(arraysPerDevise[key][i]);
        }
      }
      sums[key] = sum;
    }

    if (request?.mission?.expenses?.currency) {
      if (sums[request?.mission?.expenses?.currency]) {
        sums[request?.mission?.expenses?.currency] +=
          parseFloat(request?.mission?.expenses?.extraExpenses) || 0;
      } else {
        sums[request?.mission?.expenses?.currency] =
          parseFloat(request?.mission?.expenses?.extraExpenses) || 0;
      }
    }

    setEstimatedCosts(sums);
  }, [perdiems]);
  console.log("🚀 ~ perdiems:", perdiems);

  return (
    <>
      <div className="m-2">
        {edit ? (
          <>
            <div className="w-150">
              <Typography className=" text-sm  text-gray-800 pt-1">
                <Translation message="Applicable perdiems" />
              </Typography>
            </div>
            <List>
              {perdiems?.map((item, index) => (
                <ListItem
                  key={item?._id}
                  index={index + 1}
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "space-between",
                  //     flexWrap: "wrap",
                  //   }}
                  className="flex flex-wrap gap-2"
                >
                  <div style={{ textAlign: "left" }} className="mt-5">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700"
                    >
                      <Translation message="Accommodation type" />
                    </Typography>
                    <Input
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.type}
                      placeholder={"Extra expenses"}
                    />
                  </div>

                  <div
                    style={{ textAlign: "left", width: 100 }}
                    className="mt-5"
                  >
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700"
                    >
                      <Translation message="Indemnity" />
                    </Typography>
                    <Input
                      style={{ width: 100 }}
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400 w-50"
                      labelProps={{
                        className:
                          "before:content-none after:content-none w-50",
                      }}
                      color="blue-gray"
                      type="text"
                      value={
                        item.perdiem
                          ? item.perdiem?.indemnity +
                            " " +
                            item.perdiem?.currency?.name
                          : ""
                      }
                      placeholder={"Indemnity"}
                    />
                  </div>

                  <div style={{ textAlign: "left" }} className="mt-5">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Start date"} />
                    </Typography>
                    <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={
                        item.startDate
                          ? format(new Date(item.startDate), "dd-MM-yyyy")
                          : ""
                      }
                    />
                  </div>

                  <div style={{ textAlign: "left" }} className="mt-5">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"End date"} />
                    </Typography>
                    <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={
                        item.endDate
                          ? format(new Date(item.endDate), "dd-MM-yyyy")
                          : ""
                      }
                    />
                  </div>

                  <div
                    style={{ textAlign: "left", width: 180 }}
                    className="mt-5"
                  >
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Extra expenses"} />
                    </Typography>
                    <Input
                      style={{ width: 180 }}
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      onChange={(e) => {
                        const values = [...perdiems];
                        values[index].extraExpenses = Number(e.target.value);
                        setPerdiems(values);
                        handleFormChange({ perdiems: values });
                      }}
                      value={item.extraExpenses || ""}
                      placeholder={"Extra expenses"}
                      type="number"
                    />
                  </div>

                  {/* <Button
                    className="mt-5"
                    onClick={() => {
                      handleRemoveRow(index);
                    }}
                    size="small"
                    style={{ outline: "none" }}
                  >
                    <Minimize />
                  </Button> */}
                </ListItem>
              ))}
            </List>
            {/* <Button
              onClick={handleAddRow}
              style={{ marginTop: 10, outline: "none" }}
              size="small"
            >
              <Add />
            </Button> */}
          </>
        ) : (
          <>
            {perdiems?.length > 0 ? (
              <>
                <div className="w-150">
                  <Typography className=" text-sm  text-gray-700 pt-1">
                    <Translation message="Applicable perdiems" />
                  </Typography>
                </div>
                <List>
                  {perdiems?.map((item, index) => (
                    <ListItem
                      key={item?._id}
                      index={index + 1}
                      className="flex flex-wrap gap-8 "
                    >
                      <div style={{ textAlign: "left" }} className="mt-5">
                        <Typography className=" text-sm  text-gray-800">
                          <Translation message="Accomodation type" />
                        </Typography>{" "}
                        <Typography className=" text-sm  text-gray-600">
                          {item?.type}
                        </Typography>
                      </div>

                      <div style={{ textAlign: "left" }} className="mt-5">
                        <Typography className=" text-sm  text-gray-800">
                          <Translation message="Perdiem per day" />
                        </Typography>{" "}
                        <Typography className=" text-sm  text-gray-600">
                          {item.perdiem
                            ? item.perdiem?.indemnity +
                              " " +
                              item.perdiem?.currency?.name
                            : ""}
                        </Typography>
                      </div>

                      <div style={{ textAlign: "left" }} className="mt-5">
                        <Typography className=" text-sm  text-gray-800">
                          <Translation message="Start date" />
                        </Typography>{" "}
                        <Typography className=" text-sm  text-gray-600">
                          {item.startDate &&
                            format(new Date(item.startDate), "dd-MM-yyyy")}
                        </Typography>
                      </div>

                      <div style={{ textAlign: "left" }} className="mt-5">
                        <Typography className=" text-sm  text-gray-800">
                          <Translation message="End date" />
                        </Typography>{" "}
                        <Typography className=" text-sm  text-gray-600">
                          {item.endDate &&
                            format(new Date(item.endDate), "dd-MM-yyyy")}
                        </Typography>
                      </div>

                      <div style={{ textAlign: "left" }} className="mt-5">
                        <Typography className=" text-sm  text-gray-800">
                          <Translation message="Extra expenses" />
                        </Typography>{" "}
                        <Typography className=" text-sm  text-gray-600">
                          {item.extraExpenses}
                        </Typography>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>

      {/* <div className="flex flex-wrap">


        {edit ? (
          <div className="m-2">
            <p className="title-field bold">
              Extra expenses (needs your partner validation){" "}
            </p>{" "}
            <Input
              color="indigo"
              onChange={(e) =>
                handleFormChange({ extraExpenses: e.target.value })
              }
              value={
                formData?.extraExpenses
                  ? formData?.extraExpenses
                  : request?.mission?.expenses?.extraExpenses
              }
            />
          </div>
        ) : request?.mission?.expenses?.extraExpenses &&
          request?.mission?.expenses?.extraExpenses > 0 ? (
          <div className="m-2">
            <p
              className="title-field bold blue"
              style={{ color: "rgb(83 33 242)" }}
            >
              Extra expenses
            </p>{" "}
            <Typography className=" text-sm  text-gray-600">
              {" "}
              {request?.mission?.expenses?.extraExpenses}{" "}
              {perdiem?.currency?.name}
            </p>
          </div>
        ) : (
          ""
        )}
      </div> */}

      {edit ? (
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-800"
          >
            <Translation message={"Why do you need extra expenses?"} />
          </Typography>
          <Textarea
            className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
            // placeholder="Comment"
            value={
              formData?.expensesComment
                ? formData?.expensesComment
                : request?.mission?.expenses?.expensesComment
            }
            onChange={(e) => {
              handleFormChange({ expensesComment: e.target.value });
            }}
            outline
            placeholder={"Why do you need extra expenses?"}
          />
        </div>
      ) : (
        request?.mission?.expenses?.expensesComment &&
        request?.mission?.expenses?.expensesComment != "" && (
          <div className="m-2">
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-800"
            >
              <Translation message={"Why do you need extra expenses?"} />
            </Typography>
            <Typography className=" text-sm  text-gray-600">
              {" "}
              {request?.mission?.expenses?.expensesComment}
            </Typography>
          </div>
        )
      )}
      {request?.mission?.expenses?.adminComment &&
        request?.mission?.expenses?.adminComment != "" && (
          <div className="m-2">
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-800"
            >
              <Translation message={"Admin comment"} />
            </Typography>
            <Typography className=" text-sm  text-gray-600">
              {" "}
              {request?.mission?.expenses?.adminComment}
            </Typography>
          </div>
        )}
      {perdiems?.length > 0 ? (
        request?.mission?.expenses?.totalExpenses &&
        Object.keys(request?.mission?.expenses?.totalExpenses)?.length > 0 ? (
          Object.keys(request?.mission?.expenses?.totalExpenses)?.map((key) => (
            <div className="m-2">
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-medium text-gray-700"
              >
                Total {currencies?.find((item) => item._id == key)?.name}{" "}
              </Typography>
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-medium text-gray-700"
              >
                {request?.mission?.expenses?.totalExpenses[key]}{" "}
                {currencies?.find((item) => item._id == key)?.name}
              </Typography>
            </div>
          ))
        ) : (
          <div className="m-2">
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-800"
            >
              <Translation message={"Estimated Total expenses"} />
            </Typography>
            {estimatedCosts &&
              Object.keys(estimatedCosts)?.map((key) => (
                <div className="m-2">
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-700"
                  >
                    Total {currencies?.find((item) => item._id == key)?.name}{" "}
                  </Typography>{" "}
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-700"
                  >
                    {estimatedCosts[key]}{" "}
                    {currencies?.find((item) => item._id == key)?.name}
                  </Typography>
                </div>
              ))}
          </div>
        )
      ) : (
        request?.mission?.expenses?.totalExpenses &&
        request?.mission?.expenses?.totalExpenses > 0 && (
          <div className="m-2">
            <Typography className=" text-sm  text-gray-800">
              Total expenses
            </Typography>{" "}
            <Typography className=" text-sm  text-gray-600">
              {" "}
              {request?.mission?.expenses?.totalExpenses}{" "}
              {perdiem?.currency?.name}
            </Typography>
          </div>
        )
      )}
    </>
  );
};

export default PerdiemPanel;
