import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import { fetchALLUsers } from "actions/UserAction";
import UsersInput from "./UsersInput";
import { ClipLoader } from "react-spinners";
import { SearchColleague } from "actions/UserAction";
import { createSearchParams, useNavigate } from "react-router-dom";
import Calendar from "./Calendar";
import Lottie from "react-lottie";
import SearchLottie from "../../assets/lotties/search";
import Translation from "Translation";

// import Select from "react-select";

function SortArray(x, y) {
  if (x.firstname < y.firstname) {
    return -1;
  }
  if (x.firstname > y.firstname) {
    return 1;
  }
  return 0;
}

const Search = (props) => {
  const { search, handleOpenSearch } = props;
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [message, setMessage] = useState();
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const OptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: SearchLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchALLUsers();
      setUsers(result.sort(SortArray));
      //   if (result) {
      //     var tempArray = [];
      //     result.map((element) => {
      //       var obj = element;
      //       obj["label"] = element.firstname + " " + element.lastname;
      //       obj["value"] = element._id;
      //       tempArray.push(obj);
      //     });
      //     setUsers(tempArray.sort(SortArray));
      //   }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (user?._id) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user]);

  const handleSearch = async () => {
    setLoading(true);
    // var search = {};
    // if (user) {
    //   search.user = user;
    var res = await SearchColleague(user._id, selectedDate);
    setLoading(false);
    if (res.length > 0) {
      if (
        (res.length > 1 && res[0].floor_id == res[1].floor_id) ||
        res.length == 1
      ) {
        // navigate({
        //   pathname: `/floor/${res[0]?.floor_id}`,
        //   search: createSearchParams({
        //     id: user,
        //     date: selectedDate,
        //     reservations: res,
        //   }).toString(),
        // });
        navigate(`/floor/${res[0]?.floor_id}`, {
          state: {
            user: user,
            date: selectedDate,
            reservations: res,
          },
        });
      } else {
        setMessage(
          `Your colleague has booked in Floor ${res[0].floor} and Floor ${res[1].floor}`
        );
      }
      // if (res?.floor_id) {
      //     navigate({
      //       pathname: `/floor/${res?.floor_id}`,
      //       search: createSearchParams({ id: user._id }).toString(),
      //     });
      //   }
    } else {
      setMessage(
        <Translation
          message={"Your colleague has not planned on site for this date"}
        />
      );
    }

    // handleOpenSearch(!search);
  };

  return (
    <Dialog open={search} handler={handleOpenSearch}>
      {/* <DialogHeader>Are you looking for your colleague?</DialogHeader> */}
      <Lottie options={OptionsLottie} height={200} width={200} />
      <div className="text-center mt-5">
        <Translation message={"Are you looking for your colleague?"} />
      </div>

      <div
        style={{ height: "200px" }}
        className="flex flex-wrap justify-center justify-between mt-5 pl-10 pr-10"
      >
        {users?.length > 0 && <UsersInput list={users} setItem={setUser} />}

        <div>
          <Input
            label="Date"
            placeholder="date placeholder"
            type="date"
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
            // width="100px"
            value={selectedDate}
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-center mt-5">
        <br></br>
        {message}
      </div>

      <DialogFooter>
        <Button
          variant="gradient"
          color="indigo"
          onClick={handleSearch}
          className="ml-5"
          size="sm"
          disabled={disabled}
        >
          {loading ? (
            <ClipLoader color="white" loading={loading} size={20} />
          ) : (
            <Translation message={"Confirm"} />
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Search;
