import React from "react";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";

const Legend = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  return isDesktop ? (
    <>
      <svg height="70" width="100%" className="">
        <circle cx="50" cy="50" r="5" fill="#FE3993" />
        <text x="70" y="55" fill="#32325d" fontSize={15}>
          <Translation message={"Occupied"} />
        </text>
        <circle cx="170" cy="50" r="5" fill="#ff861c" />
        <text x="190" y="55" fill="#32325d" fontSize={15}>
          <Translation message={"Booked"} />
        </text>

        <circle cx="290" cy="50" r="5" fill="#14e39a" />
        <text x="310" y="55" fill="#32325d" fontSize={15}>
          <Translation message={"Available"} />
        </text>
        <circle cx="390" cy="50" r="5" fill="#a9a9ba" fillOpacity="0.4" />
        <text x="410" y="55" fill="#32325d" fontSize={15}>
          <Translation message={"Blocked"} />
        </text>
        <path
          fill="#ffffff"
          stroke="#88889D"
          d="M510.477 48.891c-.122-7.047 5.818-8.856 9.268-8.973l.221 8.76.221 8.759c-7.449.118-9.587-5.499-9.71-8.546z"
        ></path>
        <text x="540" y="55" fill="#32325d" fontSize={15}>
          AM
        </text>

        <path
          fill="#ffffff"
          stroke="#88889D"
          d="M610.477 48.891l-.221-8.76c7.449-.118 9.587 5.499 9.71 8.547.122 7.047-5.818 8.855-9.268 8.972l-.221-8.759z"
        ></path>
        <text x="640" y="55" fill="#32325d" fontSize={15}>
          PM
        </text>
        {/* <path fill="#88889D" fillOpacity="0.4" d="M10,10A10,10 0 0,1 10,40"></path> */}
      </svg>
    </>
  ) : (
    <>
      <svg height="70" width="100%" className="mt-5">
        <circle cx="20" cy="20" r="7" fill="#FE3993" />
        <text x="35" y="25" fill="#32325d" fontSize="smaller">
          <Translation message={"Occupied"} />
        </text>
        <circle cx="110" cy="20" r="7" fill="#ff861c" />
        <text x="125" y="25" fill="#32325d" fontSize="smaller">
          <Translation message={"Occupied"} />
        </text>

        <circle cx="190" cy="20" r="7" fill="#14e39a" />
        <text x="205" y="25" fill="#32325d" fontSize="smaller">
          <Translation message={"Available"} />
        </text>
        <circle cx="280" cy="20" r="7" fill="#a9a9ba" fillOpacity="0.4" />
        <text x="295" y="25" fill="#32325d" fontSize="smaller">
          <Translation message={"Blocked"} />
        </text>
        <path
          fill="#ffffff"
          stroke="#88889D"
          d="M130.477 48.891c-.122-7.047 5.818-12.856 13.268-12.973l.221 12.76.221 12.759c-7.449.118-13.587-5.499-13.71-12.546z"
        ></path>
        <text x="150" y="55" fill="#32325d" fontSize="smaller">
          AM
        </text>

        <path
          fill="#ffffff"
          stroke="#88889D"
          d="M190.477 48.891l-.221-12.76c7.449-.118 13.587 5.499 13.71 12.547.122 7.047-5.818 12.855-13.268 12.972l-.221-12.759z"
        ></path>
        <text x="210" y="55" fill="#32325d" fontSize="smaller">
          PM
        </text>
        {/* <path fill="#88889D" fillOpacity="0.4" d="M10,10A10,10 0 0,1 10,40"></path> */}
      </svg>
    </>
  );
};

export default Legend;
