
import Form from './Form';

export default function ContactSection() {
    return (
        <section className="pb-20 relative block">
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
               
                <Form />
            </div>
        </section>
    );
}
