import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { blue, orange } from '@material-ui/core/colors';

import { users } from "./tasks";
import { withStyles } from "@material-ui/core/styles";

import { fetchTeamUsers } from "../../actions/UserAction";
import { fetchUsersWithNoTraceByManager } from "../../actions/OperationAction";

import H3, { Chip } from "@material-tailwind/react";
import Paragraph from "@material-tailwind/react";
import { Typography, Button } from "@material-tailwind/react";
import jwt from "jwt-decode";
import useMediaQuery from "useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
// import { CalendarIcon, DownloadIcon } from "@heroicons/react/solid";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import DownloadIcon from "@mui/icons-material/SaveAlt";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";
import Translation from "Translation";

export default function NoTraceView() {
  const color = "#3949ab";

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(true);

  const [calendar, setCalendar] = useState(false);

  const [dates, setDates] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const columns = [
    {
      field: "fullName",
      headerName: <Translation message={"Full name"} />,
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    {
      field: "grade",
      headerName: <Translation message={"Grade"} />,
      sortable: false,
      width: 200,
    },

    {
      field: "department",
      headerName: <Translation message={"Department"} />,
      sortable: false,
      width: 250,
    },

    {
      field: "date",
      headerName: <Translation message={"Date"} />,
      width: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      setOpen(true);
      const result = await fetchUsersWithNoTraceByManager(
        user.id,
        moment(dates[0].startDate).format().substring(0, 10),
        moment(dates[0].endDate).format().substring(0, 10)
      );
      var rowsData = [];

      if (result?.length > 0) {
        for (const item of result) {
          rowsData.push({
            id: item._id,
            lastName: item.lastname,
            firstName: item.firstname,
            grade: item.grade,
            department: item.sl,
            date:
              item.date?.substring(0, 10).split("-").reverse().join("-") +
              " " +
              item.timeslot,
          });
        }
      }

      setRows(rowsData);
      setOpen(false);
    };

    if (dates[0]?.startDate && dates[0]?.endDate) {
      fetch();
    }
  }, [dates]);

  function exportDataToCsv(rows, columns) {
    var resultToExport = [];
    rows.map((item) => {
      resultToExport.push({
        "Full Name": item?.firstName + " " + item?.lastName,
        Grade: item.grade,
        Department: item.department,
        Date: item?.date,
      });
    });
    const worksheet = XLSX.utils.json_to_sheet(resultToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `UntracedMembers${moment(dates[0].startDate)
        .format()
        ?.substring(0, 10)
        .split("-")
        .reverse()
        .join("-")} / ${moment(dates[0].endDate)
        .format()
        ?.substring(0, 10)
        .split("-")
        .reverse()
        .join("-")}.xlsx`
    );
  }

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <div className="container max-w-8xl mx-auto px-4 ">
      <div className="flex flex-wrap justify-center">
        <div className="flex flex-wrap justify-center flex-grow-3 gap-8 mb-12">
          <div className="mt-10 mb-20" style={{ width: "100%" }}>
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
              <div className="min-w-0 flex-1">
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {`${dates[0]?.startDate
                      ?.toISOString()
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")} -> ${dates[0]?.endDate
                      ?.toISOString()
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")} `}
                  </div>
                </div>
              </div>
              <div className="mt-5 flex lg:mt-0 lg:ml-4">
                <span className="hidden sm:block">
                  <button
                    onClick={() => {
                      setCalendar(!calendar);
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <CalendarIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <Translation message={"Period"} />
                  </button>
                </span>

                <span className="sm:ml-3">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => exportDataToCsv(rows, columns)}
                  >
                    <DownloadIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <Translation message={"Export"} />
                  </button>
                </span>
              </div>
            </div>
            {calendar && (
              <div className="mt-2  flex items-center text-sm text-gray-500">
                <DateRangePicker
                  onChange={(item) => setDates([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={dates}
                  direction="horizontal"
                  rangeColors={[color]}
                  color={color}
                  maxDate={subDays(new Date(), 0)}
                />
              </div>
            )}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              loading={open}
              disableColumnSelector
              stickyHeader={true}
              style={{ height: 600 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
