import api from "./api";
import { Encrypt } from "Crypto";

const url = process.env.REACT_APP_API_URL + "Request/";

export const fetchRequestsByUser = async (token, id) => {
  const result = await api
    .get(url + `getRequestsByUser/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addRequest = async (token, data) => {
  var request = Encrypt(data);
  const result = await api
    .post(
      url + `addNewRequest`,
      { request: request },
      {
        headers: {
          "x-access-token": token,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const deleteRequest = async (token, id) => {
  const result = await api
    .delete(url + `cancelRequest/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchRequestByID = async (token, id) => {
  const result = await api
    .get(url + `getRequestByID/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getPendingRequestsByManager = async (token, id, type) => {
  const result = await api
    .get(url + `getPendingRequestsByManager/${id}?filter=${type}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const updateRequest = async (token, id, data) => {
  const result = await api
    .put(url + `updateRequest/${id}`, data, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const delegateRequest = async (data) => {
  const result = await api
    .put(url + `DelegateRequest/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchUserClaims = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Claim/getUserCLAIMS/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addClaim = async (data) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `Claim/add`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const deleteClaim = async (id, user) => {
  const result = await api
    .delete(process.env.REACT_APP_API_URL + `Claim/delete/${id}/${user}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getMyRequests = async (id) => {
  const result = await api
    .get(url + `getMyRequests/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addDraft = async (data) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `Draft/add`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};
