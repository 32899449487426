import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import MissionsCard from "./MissionsCard";
import Translation from "Translation";
import useMediaQuery from "useMediaQuery";
import { getUserPendingMissions } from "actions/travel.service";
import jwtDecode from "jwt-decode";
import { NotificationManager } from "react-notifications";

const OngoingMissions = () => {
  const color = "#083985";

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwtDecode(token);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const limit = 6;
  const [totalPages, setTotalPages] = useState(0);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getUserPendingMissions(user.id, page, limit);

      if (result.status == 200) {
        // const sortedResult = result?.data?.sort(
        //   (objA, objB) =>
        //     Number(new Date(objB.updatedAt)) - Number(new Date(objA.updatedAt))
        // );
        setData(result?.data);
        setTotalPages(result.totalPages);
      } else {
        NotificationManager.error("An error has occurred");
      }

      setLoading(false);
    };
    page && fetchData();
  }, [page]);

  return (
    <div className="flex justify-center">
      {loading ? (
        <ClipLoader
          color="blue"
          loading={loading}
          size={50}
          className="justify-center"
        />
      ) : (
        <div
          className={
            isDesktop
              ? "flex flex-wrap justify-center flex-grow-3 gap-8 mt-16 mb-12"
              : "justify-center flex-grow-3 gap-8 mt-16 mb-12"
          }
        >
          {data && data.length > 0 ? (
            data.map((item) => {
              return <MissionsCard key={item._id} request={item} edit={true} />;
            })
          ) : (
            <Typography>No pending missions</Typography>
          )}

          {/* {dataFiltred && dataFiltred.length !== 0 ? (
            dataFiltred.map((request) => {
              return <MissionsCard key={request._id} item={request} />;
            })
          ) : (
            <Typography color="gray">
              <Translation message={"You have no requests"} />
            </Typography>
          )} */}
        </div>
      )}
    </div>
  );
};

export default OngoingMissions;
