import React, { useEffect, useState } from "react";

import {
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import { fetchHistory } from "actions/UserAction";
import Paper from "@material-ui/core/Paper";
// import Tab from "@material-ui/core/Tab";
// import Tabs from "@material-ui/core/Tabs";
import WFHTab from "./WFHTab";
import TeamTab from "./TeamTab";
import jwtDecode from "jwt-decode";
import ClientSiteTab from "./ClientSiteTab";
import ReservationTab from "./ReservationTab";
import ClipLoader from "react-spinners/ClipLoader";
import Translation from "Translation";

// import ToggleButton from './ToggleButton'

export default function Content() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwtDecode(token);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);
  const [WFHHistory, setWFHHistory] = useState([]);
  const [ClientSiteHistory, setClientSiteHistory] = useState([]);
  const [reservationHistory, setReservationHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var result = await fetchHistory(user.id);
      if (result.length > 0) {
        setData(result);
        setWFHHistory(
          result.filter(
            (item) =>
              item.Request?.name == "WFH" ||
              item.Request?.name == "REMOTE_WORKING"
          )
        );

        setClientSiteHistory(
          result.filter((item) => item.Request?.name == "Client site")
        );

        setReservationHistory(
          result.filter(
            (item) =>
              item.Reservation && Object.keys(item?.Reservation).length != 0
          )
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const dataTabs = [
    {
      label: <Translation message={"WFH"} />,
      value: "0",
      desc: <WFHTab data={WFHHistory} />,
    },
    {
      label: <Translation message={"Third party site"} />,
      value: "1",
      desc: <ClientSiteTab data={ClientSiteHistory} />,
    },
    {
      label: <Translation message={"On Site"} />,
      value: "2",
      desc: <ReservationTab data={reservationHistory} />,
    },

    {
      label: <Translation message={"Team's requests management"} />,
      value: "3",
      desc: <TeamTab />,
    },
  ];

  // console.log('WFH', WFHHistory)

  return (
    <section className="relative py-16 bg-gray-100">
      <div className="container px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div className="relative"></div>
              </div>
            </div>

            <div className="text-center my-8">
              <Typography variant="h3" color="gray">
                {" "}
                <Translation message={"History"} />
              </Typography>
            </div>
            <div className="flex gap-12 mt-16 mb-12"></div>

            {/* <Paper square className="mt-20"> */}
            {/* <Tabs
                value={value}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                style={{ outline: "none" }}
              >
                <Tab label="My WFH's Requests " style={{ outline: "none" }} />
                <Tab
                  label="My third party site's history"
                  style={{ outline: "none" }}
                />
                <Tab
                  label="My reservation's history"
                  style={{ outline: "none" }}
                />
                {(user.role === "validator" ||
                  user.role === "manager_validator") && (
                  <Tab label="Team's requests" style={{ outline: "none" }} />
                )}
              </Tabs> */}

            <Tabs value="0">
              <TabsHeader>
                {dataTabs.map(({ label, value }) => (
                  <Tab key={value} value={value}>
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {dataTabs.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>

            <div className="text-center mb-5">
              <ClipLoader color="blue" loading={loading} size={50} />
            </div>

            {/* {value == 0 ? (
                <WFHTab data={WFHHistory} />
              ) : value == 1 ? (
                <ClientSiteTab data={ClientSiteHistory} />
              ) : value == 2 ? (
                <ReservationTab data={reservationHistory} />
              ) : value == 3 ? (
                <TeamTab />
              ) : (
                ""
              )} */}
            {/* </Paper> */}
          </div>
        </div>
      </div>
    </section>
  );
}
