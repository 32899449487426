import React, { useState, useEffect } from "react";

import { DataGrid, GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import { Button } from "@material-tailwind/react";
import jwt from "jwt-decode";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { updateRequest } from "actions/RequestAction";
import { fetchOperationsByRequest } from "actions/OperationAction";
import { fetchUserBalances } from "actions/UserAction";
import PopupDeledate from "components/Team/PopupDelegate";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { getPendingMissionRequestsByManager } from "actions/travel.service";
import { format } from "date-fns";
import { Chip } from "@mui/material";
import { ValidateRequest } from "actions/travel.service";
import { rejectRequest } from "actions/travel.service";

export default function Travel() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const [rows, setRows] = useState([]);
  const user = jwt(token);
  const [openPopup, setOpenPopup] = useState({ open: false });
  const [validator, setValidator] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    selectionModel.length > 0 ? setVisible(true) : setVisible(false);
  }, [selectionModel]);

  const columns = [
    {
      field: "fullName",
      headerName: "Full name",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    // {
    //   field: "manager",
    //   headerName: "Manager",
    //   // type: 'number',
    //   width: 120,
    // },
    // {
    //   field: "partner",
    //   headerName: "Partner",
    //   // type: 'number',
    //   width: 120,
    // },
    {
      field: "name",
      headerName: "Type",
      // type: 'number',
      width: 150,
      renderCell: (params) => {
        return params.row?.name?.toLowerCase() == "mission" ? (
          <Chip
            label={
              params.row?.mission?.missionType == "compound"
                ? "compound " + params.row?.name
                : params.row?.name
            }
            color="success"
            variant="outlined"
          />
        ) : (
          <Chip label={params.row?.name} color="primary" variant="outlined" />
        );
      },
    },

    {
      field: "period",
      headerName: "Mission Period",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `From ${
          format(
            new Date(params.getValue(params.id, "startDate")),
            "dd-MM-yyyy"
          ) || ""
        } To ${
          format(
            new Date(params.getValue(params.id, "endDate")),
            "dd-MM-yyyy"
          ) || ""
        }`,
      // valueGetter: (params) =>
      //   `${params.getValue(params.id, "startDate") || ""} ${
      //     params.getValue(params.id, "endDate") || ""
      //   }`,
      // renderCell: (params) => {
      //   console.log("params", params);
      //   // var ch = "";
      //   // params.formattedValue?.map((day) => {
      //   //   ch =
      //   //     "From " +
      //   //     day.date_debut.substring(0, 10) +
      //   //     " To " +
      //   //     day.date_fin.substring(0, 10);
      //   // });
      //   return (
      //     <p>
      //       From{" "}
      //       {format(new Date(params.row?.operation?.date_debut), "dd-MM-yyyy")}
      //       {" To "}
      //       {format(new Date(params.row?.operation?.date_fin), "dd-MM-yyyy")}
      //     </p>
      //   );
      // },
    },
    {
      field: "country",
      headerName: "Country",
      // type: 'number',
      width: 120,
    },
    {
      field: "city",
      headerName: "City",
      // type: 'number',
      width: 120,
    },

    // { field: "date", headerName: "Sent date", width: 160 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,
    // },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 320,
      fixed: true,
      renderCell: (params) => {
        const onApprove = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          // NotificationManager.success('Updated successfully')
          // setRows(rows.filter(item => item.id != params.id ))
          // const result = await updateRequest("", params.id, {
          //   status: "accepted",
          // });
          var res = await ValidateRequest(params.id, "mission");
          if (res.status == 200) {
            NotificationManager.success(res.message);
            // fetchRequest();
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(res);
          }
        };

        const onReject = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          NotificationManager.success("Updated successfully");
          const result = await rejectRequest(params.id, params.mission?._id);
          if (result.status === 200) {
            NotificationManager.success(result.message);
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(result.message);
          }
        };

        return (
          <div className="flex">
            <Button
              color="green"
              variant="text"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApprove}
              disabled={disabled}
            >
              <i className="fas fa-check fa"></i>
            </Button>
            <Button
              color="red"
              variant="text"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onReject}
              disabled={disabled}
            >
              <i className="fas fa-times fa"></i>
            </Button>
            {/* <Button
              color="indigo"
              buttonType="link"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onDelegate}
              disabled={disabled}
            >
              <i className="fas fa-paper-plane fa"></i>
            </Button> */}
            <Link
              to={{
                pathname: `/${params.row?.name?.toLowerCase()}/${params.id}`,
                // state: window.location.pathname + "#/Leave",
                state: "validation",
              }}
            >
              <Button
                color="indigo"
                variant="text"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="dark"
                // onClick={onClick}
              >
                check to validate
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setOpen(true);
      const result = await getPendingMissionRequestsByManager(user.id);
      console.log("🚀 ~ fetchData ~ result:", result);
      var rowsData = [];
      for (const item of result) {
        // const data = await fetchOperationsByRequest(token, item._id);
        // const balances = await fetchUserBalances(token, item.idSender._id)
        // console.log(balances)
        rowsData.push({
          name: item.name,
          id: item._id,
          mission: item.mission,
          lastName: item.idSender ? item.idSender?.lastname : item?.guest,
          firstName: item.idSender ? item.idSender?.firstname : "",
          status: item.status,
          country:
            item.mission?.missionType == "compound"
              ? item.mission?.missions.map((m) => m.country?.name).join(" ")
              : item.mission?.missionCountry
              ? item.mission?.missionCountry?.name
              : item.visa?.country?.name,
          city:
            item.mission?.missionType == "compound"
              ? item.mission?.missions.map((m) => m.city?.name).join(" ")
              : item.mission?.missionCity
              ? item.mission?.missionCity?.name
              : item.visa?.city?.name,
          manager:
            item.manager &&
            item.manager?.firstname + " " + item?.manager?.lastname,
          partner:
            item.partner &&
            item.partner?.firstname + " " + item?.partner?.lastname,
          date: "",
          // item?.createdAt?.substring(0, 10).split("-").reverse().join("-") +
          // " " +
          // item?.createdAt?.substring(11, 16),
          startDate:
            item.mission?.missionType == "compound"
              ? item.mission?.missions[0]?.startDate
              : item.mission?.startDate
              ? item.mission?.startDate
              : item.visa?.plannedStartDate,
          endDate:
            item.mission?.missionType == "compound"
              ? item.mission.missions[item.mission.missions.length - 1]?.endDate
              : item.mission?.endDate
              ? item.mission?.endDate
              : item.visa?.plannedEndDate,
        });
      }

      console.log("rowsData", rowsData);

      setRows(rowsData);
      setOpen(false);
    }
    fetchData();
  }, []);

  const onApproveAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "accepted",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request approved");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onRejectAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "refused",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request rejected");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onDelegateAll = async (e) => {
    e.preventDefault();

    setOpenPopup({ open: true, ids: selectionModel, multiple: true });
  };

  return (
    <>
      <div className="mt-10" style={{ height: 500, width: "100%" }}>
        {/* {visible && (
          <div className="flex justify-center mb-5">
            <Button
              color="green"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApproveAll}
            >
              <i className="fas fa-check fa"></i> Accept selected
            </Button>
            <Button
              color="red"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onRejectAll}
            >
              <i className="fas fa-times fa"></i> Reject selected
            </Button>
            <Button
              color="indigo"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onDelegateAll}
            >
              <i className="fas fa-paper-plane fa"></i>Delegate selected
            </Button>
          </div>
        )} */}

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={open}
          onRowClick={(row) =>
            (window.location.href = `/${row?.row?.name?.toLowerCase()}/${
              row.id
            }`)
          }

          // autoHeight
          // headerHeight={0}
        />
      </div>
      <PopupDeledate
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setValidator={setValidator}
        validator={validator}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
      />
      <NotificationContainer />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>
  );
}
