import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Navbar as MTNavbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";

import logo from "../../assets/img/white.png";
import routes from "../../routes";
import jwt from "jwt-decode";

import { logout } from "actions/UserAction";
import i18n from "i18n";
import Translation from "Translation";
// import { GlobeAltIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

export function Navbar({ brandName, action }) {
  const [openNav, setOpenNav] = React.useState(false);
  const storage = JSON.parse(localStorage.getItem("user"));

  const token = storage.token;
  const user = jwt(token);
  const { t } = useTranslation();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const handleSignOut = async (event) => {
    event.preventDefault();
    await logout();
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {routes.map(({ name, path, icon, target, childs, parent, isManager }) => {
        return isManager ? (
          user.role == "validator" || user.role == "manager_validator" ? (
            <Typography
              key={name}
              as="li"
              variant="small"
              color="inherit"
              className="capitalize"
            >
              {path ? (
                <Link
                  to={path}
                  target={target}
                  className="flex items-center gap-1 p-1 font-normal"
                >
                  {icon && <i className={icon} />}
                  {/* React.createElement(icon, {
                       className: "w-[18px] h-[18px] opacity-75 mr-1",
                     })} */}
                  <Translation message={name} />
                </Link>
              ) : (
                parent && (
                  <Menu>
                    <MenuHandler className="flex">
                      <button
                        style={{
                          backgroundColor: "transparent",
                          backgroundRepeat: "no-repeat",
                          border: "none",
                          outline: "none",
                          overflow: "hidden",
                        }}
                      >
                        {
                          icon && <i className={`${icon} mr-1 mt-1`} />
                          // React.createElement(icon, {
                          //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                          // })
                        }
                        {name == "Profile" ? (
                          user.firstname + " " + user.lastname
                        ) : (
                          <Translation message={name} />
                        )}
                      </button>
                    </MenuHandler>
                    <MenuList>
                      {childs.map((item) =>
                        item.path ? (
                          <MenuItem className="flex">
                            <Link
                              to={item.path}
                              target={target}
                              className="flex items-center gap-1 p-1 font-normal"
                            >
                              {" "}
                              {item.icon && (
                                // React.createElement(item.icon, {
                                //   className:
                                //     "w-[18px] h-[18px] opacity-75 mr-1",
                                // })

                                <i className={item.icon} />
                              )}
                              <Translation message={item.name} />
                            </Link>
                          </MenuItem>
                        ) : (
                          <MenuItem className="flex" onClick={handleSignOut}>
                            <Link
                              to="#"
                              target={target}
                              className="flex items-center gap-1 p-1 font-normal"
                            >
                              {item.icon && (
                                // React.createElement(item.icon, {
                                //   className:
                                //     "w-[18px] h-[18px] opacity-75 mr-1",
                                // })
                                <i className={item.icon} />
                              )}

                              <Translation message={item.name} />
                            </Link>
                          </MenuItem>
                        )
                      )}
                    </MenuList>
                  </Menu>
                )
              )}
            </Typography>
          ) : (
            ""
          )
        ) : (
          <Typography
            key={name}
            as="li"
            variant="small"
            color="inherit"
            className="capitalize"
          >
            {path ? (
              <Link
                to={path}
                target={target}
                className="flex items-center gap-1 p-1 font-normal"
              >
                {icon && (
                  // React.createElement(icon, {
                  //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                  // })
                  <i className={icon} />
                )}
                <Translation message={name} />
              </Link>
            ) : (
              parent && (
                <Menu>
                  <MenuHandler className="flex">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        outline: "none",
                        overflow: "hidden",
                      }}
                    >
                      {icon && (
                        // React.createElement(icon, {
                        //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                        // })
                        <i className={`${icon} mr-1 mt-1`} />
                      )}
                      {name == "Profile" ? (
                        user.firstname + " " + user.lastname
                      ) : (
                        <Translation message={name} />
                      )}
                    </button>
                  </MenuHandler>
                  <MenuList>
                    {childs.map((item) =>
                      item.path ? (
                        <MenuItem className="flex">
                          <Link
                            to={item.path}
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            {" "}
                            {item.icon && (
                              // React.createElement(item.icon, {
                              //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                              // })
                              <i className={item.icon} />
                            )}
                            <Translation message={item.name} />
                          </Link>
                        </MenuItem>
                      ) : (
                        <MenuItem className="flex" onClick={handleSignOut}>
                          <Link
                            to="#"
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            {item.icon && (
                              // React.createElement(item.icon, {
                              //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                              // })
                              <i className={item.icon} />
                            )}
                            <Translation message={item.name} />
                          </Link>
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </Menu>
              )
            )}
          </Typography>
        );
      })}

      <Menu>
        <MenuHandler className="flex">
          <button
            style={{
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              border: "none",
              outline: "none",
              overflow: "hidden",
            }}
          >
            {/* {React.createElement(GlobeAltIcon, {
              className: "w-[18px] h-[18px] opacity-75 mr-1",
            })} */}

            <i className="fas fa-globe" />
          </button>
        </MenuHandler>
        <MenuList>
          <MenuItem
            className="flex"
            onClick={() => {
              i18n.changeLanguage("fr");
            }}
          >
            French
          </MenuItem>
          <MenuItem
            className="flex"
            onClick={() => {
              i18n.changeLanguage("en");
            }}
          >
            English
          </MenuItem>
        </MenuList>
      </Menu>
    </ul>
  );

  return (
    <MTNavbar color="transparent" className="p-3">
      <div className="container mx-auto flex items-center justify-between text-white">
        {/* <Link to="/"> */}
        <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-bold">
          <img
            src={logo}
            width={150}
            className="mr-4 ml-2 cursor-pointer py-1.5 font-bold"
          />
        </Typography>
        {/* </Link> */}
        <div className="hidden lg:block">{navList}</div>

        {/* <IconButton
          variant="text"
          size="sm"
          color="white"
          className="ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <MenuIcon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton> */}
      </div>
      <MobileNav
        className="rounded-xl bg-white px-4 pt-2 pb-4 text-blue-gray-900"
        open={openNav}
      >
        <div className="container mx-auto">{navList}</div>
      </MobileNav>
    </MTNavbar>
  );
}

Navbar.defaultProps = {
  brandName: "Material Tailwind React",
  action: (
    <a
      // href="https://www.creative-tim.com/product/material-tailwind-kit-react"
      target="_blank"
    ></a>
  ),
};

Navbar.propTypes = {
  brandName: PropTypes.string,
  action: PropTypes.node,
};

Navbar.displayName = "/src/widgets/layout/navbar.jsx";

export default Navbar;
