import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  StepIcon,
  TextField,
  Box,
  Autocomplete,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FileViewer from "./FileViewer";
import Select from "react-select";

import moment from "moment";
import { FlightTakeoff, FlightLand, Refresh, Cached } from "@material-ui/icons";
import { format } from "date-fns";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { fetchCurrencies } from "actions/travel.service";
import { fetchCities } from "actions/OperationAction";
import {
  Add,
  Download,
  Minimize,
  Train,
  Visibility,
} from "@mui/icons-material";
import {
  Textarea,
  Input,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { fetchAllCities } from "actions/travel.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { fetchFile } from "actions/travel.service";
import { fetchFileWithId } from "actions/travel.service";
import Translation from "Translation";
import { FaPlane, FaTrain } from "react-icons/fa";
import { t } from "i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { MdMinimize } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const color = "#083985";

const TransportationPanel = ({
  request,
  edit,
  handleFormChange,
  formData,
  countries,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [cities, setCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const [itinerary, setItinerary] = useState(null);
  const [commentTransportation, setCommentTransportation] = useState("");
  const [otherCosts, setOtherCosts] = useState([]);

  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);

  const { i18n } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  function Sort(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (request?.mission?.transportation?.otherCosts) {
        setOtherCosts(request?.mission?.transportation?.otherCosts);
      }
      const response = await fetchCurrencies();
      if (response.status == 200) {
        setCurrencies(response.data);
      }
    };
    fetchData();
  }, [request]);

  useEffect(() => {
    const fetchData = async () => {
      if (request?.mission?.transportation?.itinerary) {
        setItinerary(request.mission.transportation.itinerary);
      }
    };
    const getCities = async () => {
      try {
        const allCities = await fetchAllCities();
        setCities(allCities.sort(Sort));
        // handleFormChange({ departureCity: null });
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
    fetchData();
  }, [request]);

  const handleAddRow = () => {
    // setItinerary([
    //   ...itinerary,
    //   {
    //     departureCountry: null,
    //     departureCity: null,
    //     arrivalCountry: null,
    //     arrivalCity: null,
    //     departureDate: null,
    //     transportType: null,
    //   },
    // ]);
    let newItinerary = {
      departureCountry: null,
      departureCity: null,
      arrivalCountry: null,
      arrivalCity: null,
      departureDate: null,
      transportType: null,
    };

    if (itinerary.length > 0) {
      const lastItinerary = itinerary[itinerary.length - 1];
      newItinerary.departureCountry = lastItinerary.arrivalCountry;
      newItinerary.departureCity = lastItinerary.arrivalCity;
      newItinerary.departureCities = lastItinerary.arrivalCities;
    }

    setItinerary([...itinerary, newItinerary]);
    handleFormChange({ itinerary: newItinerary });
  };

  const handleRemoveRow = (index) => {
    const values = [...itinerary];
    values.splice(index, 1);
    setItinerary(values);
    handleFormChange({ itinerary: values });
  };

  const handleChangeDepartureCountry = async (index, event) => {
    // const values = [...routes];
    // values[index].departureCountry = event;

    // setItinerary(values);

    const countryId = event._id;
    const newItinerary = [...itinerary];
    newItinerary[index].departureCountry = event;
    newItinerary[index].departureCity = null;

    // setItinerary(newItinerary);

    // await dispatch(getCitiesByCountry(countryId));
    // const cities = useAppSelector((state: any) => state.city.data).map(item => ({
    //   label: item.name,
    //   value: item._id
    // }));
    const citiesCountry = cities.filter((city) => city.country == countryId);

    newItinerary[index].departureCities = citiesCountry.map((item) => ({
      ...item,
      label: item.name,
      value: item._id,
    }));
    setItinerary(newItinerary);
    handleFormChange({ itinerary: newItinerary });
  };

  const handleChangeTransportType = (index, event) => {
    let newItinerary = [...itinerary];
    newItinerary[index].transportType = event.target.value;
    setItinerary(newItinerary);
    handleFormChange({ itinerary: newItinerary });
  };

  const handleChangeDepartureCity = (index, event) => {
    const values = [...itinerary];
    values[index].departureCity = event;

    setItinerary(values);
    handleFormChange({ itinerary: values });
  };

  const handleChangeArrivalCountry = async (index, event) => {
    const countryId = event._id;
    const newItinerary = [...itinerary];
    newItinerary[index].arrivalCountry = event;
    newItinerary[index].arrivalCity = null;

    const citiesCountry = cities.filter((city) => city.country == countryId);

    newItinerary[index].arrivalCities = citiesCountry.map((item) => ({
      ...item,
      label: item.name,
      value: item._id,
    }));
    setItinerary(newItinerary);
    handleFormChange({ itinerary: newItinerary });
  };

  const handleChangeArrivalCity = (index, event) => {
    const values = [...itinerary];
    values[index].arrivalCity = event;

    setItinerary(values);
    handleFormChange({ itinerary: values });
  };

  const handleChangeDepartureDate = (date, index) => {
    const values = [...itinerary];
    // values[index].departureDate = date ? moment(date).format() : null;
    values[index].departureDate = date;
    // if (values[index].departureDate && values[index].arrivalDate) {
    //   // const date1 = new Date(values[index].departureDate);
    //   // const date2 = new Date(values[index].arrivalDate);
    //   const date1 = values[index].departureDate;
    //   const date2 = values[index].arrivalDate;
    //   const diffMs = Math.abs(date2.getTime() - date1.getTime());
    //   const diffHrs = Math.floor(diffMs / 3600000); // 1 heure = 3600000 millisecondes
    //   const diffMins = Math.floor((diffMs % 3600000) / 60000);
    //   const hoursAndMinutes = diffHrs + ':' + diffMins;
    //   const duration = moment.duration(hoursAndMinutes);
    //   const formattedDuration = `${duration.hours()}:${duration.minutes()}`;

    //   // values[index].flightDuration = hoursAndMinutes;
    //   values[index].flightDuration = formattedDuration;

    //   const durationDate = moment();
    //   durationDate.hour(15);
    //   durationDate.minute(30);
    //   console.log('durationDate', durationDate);
    // }

    setItinerary(values);
    handleFormChange({ itinerary: values });
  };

  const handleFetchFile = async (id) => {
    setOpen(true);
    const response = await fetchFileWithId(request?.mission?._id, id);
    // const contentType = response.headers['content-type'];
    // const data = await response.data;
    // item.fileType = contentType.split('/').pop();
    // item.file = new Uint8Array(data);

    const contentType =
      response.headers["Content-Type"] || response.headers["content-type"];
    // item.fileType = contentType?.split('/').pop();
    const fileType =
      contentType == "application/octet-stream" ? "eml" : contentType;
    console.log("🚀 ~ handleFetchFile ~ fileType:", fileType);
    // const res = await response.data;
    const data = await response.data;
    const fileData = new Uint8Array(data);

    setFileData(new Uint8Array(data));
    setFileType(fileType);
    setDisplay(true);
    setOpen(false);

    // fileType == "eml" || fileType == "msg"
    //   ? handleFileDownload({ fileData: fileData, fileType: fileType }, "file")
    //   : handleFileDisplay(fileData, fileType);

    // setFileData();
    // setFileType(fileType);
  };

  const meansTransport = [
    {
      value: "flight",
      label: t("flight"),
    },
    { value: "train", label: t("train") },
  ];

  return (
    <div>
      {edit ? (
        <>
          <Typography className=" text-sm  text-gray-700 pt-1">
            <Translation message="Compose your trip" />
          </Typography>
          <div className="w-full mt-10">
            {itinerary?.map((item, index) => (
              <div className="flex flex-row flex-wrap gap-4 mt-10" key={index}>
                <div className="mt-10">
                  {item.transportType == "flight" ? (
                    <FaPlane color="#294e87" />
                  ) : (
                    <FaTrain color="#294e87" />
                  )}
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Means of transport" />
                  </Typography>

                  <Select
                    options={meansTransport}
                    onChange={(event) => {
                      handleChangeTransportType(index, event);
                    }}
                    value={
                      item.transportType
                        ? meansTransport.find(
                            (val) => val.value == item.transportType
                          )
                        : ""
                    }
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Departure country" />
                  </Typography>
                  <Select
                    options={countries}
                    value={item?.departureCountry}
                    onChange={(e, newValue) => {
                      handleChangeDepartureCountry(index, newValue);
                    }}
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Departure city" />
                  </Typography>
                  <Select
                    options={item.departureCities}
                    value={item?.departureCity}
                    onChange={(e, newValue) => {
                      handleChangeDepartureCity(index, newValue);
                    }}
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Arrival country" />
                  </Typography>
                  <Select
                    options={countries}
                    value={item?.arrivalCountry}
                    onChange={(e, newValue) => {
                      handleChangeArrivalCountry(index, newValue);
                    }}
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>

                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Arrival city" />
                  </Typography>
                  <Select
                    options={item.arrivalCities}
                    value={item?.arrivalCity}
                    onChange={(e, newValue) => {
                      handleChangeArrivalCity(index, newValue);
                    }}
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>

                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Departure date" />
                  </Typography>{" "}
                  <LocalizationProvider
                    style={{ outline: "none" }}
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n?.language}
                  >
                    <DatePicker
                      style={{ outline: "none" }}
                      value={item.departureDate}
                      onChange={(e) => {
                        handleChangeDepartureDate(e, index);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: 200,
                            "& .MuiInputBase-input": {
                              borderColor: "rgb(176 190 197)",
                              // borderWidth: 1,
                              borderStyle: "solid",
                              borderRadius: "16px",
                              height: 9,
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "rgb(96 125 139)",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(176 190 197)",
                            },
                          }}
                        />
                      )}
                      inputFormat="DD/MM/YYYY"
                      minDate={
                        formData.startDate
                          ? new Date(formData.startDate)
                          : new Date()
                      }
                    />
                  </LocalizationProvider>
                </div>

                {/* <Button
                  color="red"
                  variant="text"
                  className="mt-5"
                  onClick={() => {
                    handleRemoveRow(index);
                  }}
                  size="sm"
                  style={{ outline: "none" }}
                >
                  <Minimize />
                </Button> */}
                <Button
                  className="mt-5"
                  onClick={() => {
                    handleRemoveRow(index);
                  }}
                  size="sm"
                  variant="text"
                  color="red"
                >
                  <IoMdClose size={15} />
                </Button>
              </div>
            ))}
          </div>
          <IconButton
            onClick={handleAddRow}
            className="rounded-full mt-5"
            color="indigo"
            variant="text"
          >
            <Add />
          </IconButton>

          <div className="flex gap-8 mb-2 mt-5">
            <Typography className="text-sm text-gray-700 pt-1 mt-20">
              <Translation
                message={
                  "If you have any comments or preferences, write them down here"
                }
              />{" "}
            </Typography>
          </div>
          <Textarea
            placeholder="Write here"
            value={commentTransportation}
            onChange={(e) => {
              setCommentTransportation(e.target.value);
              handleFormChange({
                "transportation.transportationComment": e.target.value,
              });
            }}
            className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
          />
        </>
      ) : (
        <div className="ml-20">
          <Typography className=" text-sm  text-gray-800">
            <Translation message="Trip itinerary" />
          </Typography>

          {/* <Stepper
            activeStep={request?.mission?.transportation?.itinerary.length - 1}
            className="mt-5"
          > */}
          {request?.mission?.transportation?.itinerary?.map((item, index) => (
            // <Step key={index}>
            <div>
              <Stepper
                activeStep={
                  request?.mission?.transportation?.itinerary.length - 1
                }
                className="mt-5"
                style={{ color: "#294e87" }}
              >
                <Step>
                  <StepLabel
                    StepIconComponent={StepIcon}
                    StepIconProps={{
                      icon:
                        item.transportType == "flight" ? (
                          <FlightTakeoff fontSize="small" />
                        ) : (
                          <Train fontSize="small" />
                        ),
                    }}
                    style={{ color: "#294e87" }}
                  >
                    <Typography variant="small" style={{ color: "#294e87" }}>
                      {`${item?.departureCity?.name}, ${item?.departureCountry?.name}`}{" "}
                    </Typography>
                  </StepLabel>
                  <Typography className="text-field">
                    {item?.departureDate &&
                      moment(item?.departureDate).format("LLL")}
                  </Typography>
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={StepIcon}
                    StepIconProps={{
                      icon:
                        item.transportType == "flight" ? (
                          <FlightLand fontSize="small" />
                        ) : (
                          <Train fontSize="small" />
                        ),
                    }}
                    style={{ color: "#294e87" }}
                  >
                    <Typography
                      variant="small"
                      style={{ color: "#294e87" }}
                    >{`${item?.arrivalCity?.name}, ${item?.arrivalCountry?.name}`}</Typography>
                  </StepLabel>
                  <Typography className="text-field">
                    {item?.arrivalDate &&
                      moment(item?.arrivalDate).format("LLL")}
                  </Typography>
                </Step>

                {item?.stopoverTime?.length > 0 && (
                  <Step>
                    <StepLabel
                      StepIconComponent={StepIcon}
                      StepIconProps={{
                        icon: <Cached fontSize="small" />,
                      }}
                    >
                      Stopover
                    </StepLabel>
                    <Typography className="text-field">
                      {item?.stopoverTime}
                    </Typography>
                  </Step>
                )}
                {/* </Step> */}
              </Stepper>
            </div>
          ))}
          {/* </Stepper> */}
          {request?.mission?.transportation?.classGo && (
            <div className="m-2">
              <Typography variant="small" className="title-field">
                Class :
              </Typography>{" "}
              <Typography className=" text-sm  text-gray-800">
                {" "}
                {request?.mission?.transportation?.classGo}
              </Typography>
            </div>
          )}
        </div>
      )}
      {console.log("otherCosts", otherCosts)}

      {((request?.mission?.transportation?.ticketCost &&
        request?.mission?.transportation?.ticketCost > 0) ||
        request?.mission?.transportation?.otherCosts?.length > 0) && (
        <div className="ml-20 mt-5">
          <Typography className=" text-sm  text-gray-800">
            <Translation message="Costs" />
          </Typography>
          <List
          // sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {otherCosts?.map((item, index) => (
              <ListItem key={item?._id} index={index + 1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="m-2">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-800"
                    >
                      <Translation message="Comment" />
                    </Typography>{" "}
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-600"
                    >
                      {" "}
                      {item?.comment}{" "}
                    </Typography>
                  </div>
                  <div className="m-2">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-800"
                    >
                      <Translation message="Cost" />
                    </Typography>{" "}
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-600"
                    >
                      {item?.cost}{" "}
                      {
                        currencies?.find((curr) => curr._id == item.currency)
                          ?.name
                      }{" "}
                    </Typography>
                  </div>

                  {item?.fileData ? (
                    <div className=" m-2">
                      <Typography className=" text-sm  text-gray-800">
                        <Translation message="File" />{" "}
                      </Typography>{" "}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        className="mr-2"
                      >
                        <Visibility
                          style={{ color: "#083985" }}
                          fontSize="small"
                          // onClick={() =>
                          //   handleFileDisplay(item.fileData, item.contentType)
                          // }
                        />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        className="ml-2"
                      >
                        <Download
                          style={{ color: "#083985" }}
                          fontSize="small"
                          // onClick={() => handleFileDownload(item, "file")}
                        />
                      </IconButton>
                    </div>
                  ) : item.file ? (
                    <div className=" m-2">
                      <Typography className=" text-sm  text-gray-800">
                        <Translation message={"File"} />{" "}
                      </Typography>{" "}
                      <Visibility
                        style={{ color: color, cursor: "pointer" }}
                        onClick={() => handleFetchFile(item.file)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </ListItem>
            ))}
          </List>
          <div className="flex flex-wrap">
            {request?.mission?.transportation?.totalCosts &&
              Object.keys(request?.mission?.transportation?.totalCosts)?.map(
                (key) => (
                  <div className="m-2">
                    <Typography className=" text-sm  text-gray-800">
                      Total {currencies?.find((item) => item._id == key)?.name}{" "}
                    </Typography>{" "}
                    <Typography className=" text-sm  text-gray-800">
                      {request?.mission?.transportation?.totalCosts[key]}{" "}
                      {currencies?.find((item) => item._id == key)?.name}
                    </Typography>
                  </div>
                )
              )}
          </div>
        </div>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className="flex flex-col place-items-center">
          <CircularProgress color="inherit" />
          <Translation message="Loading file" />
        </div>
      </Backdrop>

      {fileData && fileType && (
        <FileViewer
          fileData={fileData}
          view={display}
          // view={true}
          setView={setDisplay}
          // handleDownload={handleFileDownload}
          fileType={fileType}
          download={true}
        />
      )}
    </div>
  );
};

export default TransportationPanel;
