
import MeetingSchedule from './MeetingSchedule';

export default function Section() {
    return (
             <div className=" lg:pt-24"> 
               
                <MeetingSchedule />
            </div>
    );
}
