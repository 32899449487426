import {
  Button,
  Card,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemPrefix,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Translation from "Translation";
import { fetchCityCap } from "actions/travel.service";
import { format } from "date-fns";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiMinus } from "react-icons/bi";
import { FaHome, FaHotel, FaPlane, FaTrain } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

const Transportation = (props) => {
  const { formData, setFormData, countries } = props;
  const { i18n } = useTranslation();

  const handleAddRow = () => {
    let newItinerary = {
      departureCountry: null,
      departureCity: null,
      arrivalCountry: null,
      arrivalCity: null,
      departureDate: null,
      transportType: null,
    };

    if (formData.itinerary.length > 0) {
      const lastItinerary = formData.itinerary[formData.itinerary.length - 1];
      newItinerary.departureCountry = lastItinerary.arrivalCountry;
      newItinerary.departureCity = lastItinerary.arrivalCity;
      newItinerary.departureCities = lastItinerary.arrivalCities;
    }

    setFormData((prevData) => ({
      ...prevData,
      itinerary: [...prevData.itinerary, newItinerary],
    }));
  };

  const handleRemoveRow = (index) => {
    const values = [...formData.itinerary];
    values.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      itinerary: values,
    }));
  };

  const meansTransport = [
    {
      value: "flight",
      label: t("flight"),
    },
    { value: "train", label: t("train") },
  ];

  return (
    <div className="lg:w-12/12 mt-50 ">
      <Typography className=" text-sm  text-gray-700 pt-1">
        <Translation message="Compose your trip" />
      </Typography>
      <div className="w-full mt-10">
        {/* <List> */}
        {/* <div></div> */}
        {formData.itinerary?.map((item, index) => (
          <div key={index}>
            <div className="flex flex-row flex-wrap gap-4 mt-10" key={index}>
              <div className="mt-10">
                {item.transportType == "flight" ? (
                  <FaPlane color="#294e87" />
                ) : (
                  <FaTrain color="#294e87" />
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Means of transport" />
                </Typography>
                <Select
                  options={meansTransport}
                  onChange={(e) => {
                    let newValues = [...formData.itinerary];
                    newValues[index].transportType = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      itinerary: newValues,
                    }));
                  }}
                  value={
                    item.transportType
                      ? meansTransport.find(
                          (val) => val.value == item.transportType
                        )
                      : ""
                  }
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Departure country" />
                </Typography>
                <Select
                  options={countries}
                  onChange={(e) => {
                    let newValues = [...formData.itinerary];
                    newValues[index].departureCountry = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      itinerary: newValues,
                    }));
                  }}
                  value={item.departureCountry}
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Departure city" />
                </Typography>
                <Select
                  options={item.departureCities}
                  onChange={(e) => {
                    let newValues = [...formData.itinerary];
                    newValues[index].departureCity = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      itinerary: newValues,
                    }));
                  }}
                  value={item.departureCity}
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Arrival country" />
                </Typography>
                <Select
                  options={countries}
                  onChange={(e) => {
                    let newValues = [...formData.itinerary];
                    newValues[index].arrivalCountry = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      itinerary: newValues,
                    }));
                  }}
                  value={item.arrivalCountry}
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Arrival city" />
                </Typography>
                <Select
                  options={item.arrivalCities}
                  onChange={(e) => {
                    let newValues = [...formData.itinerary];
                    newValues[index].arrivalCity = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      itinerary: newValues,
                    }));
                  }}
                  value={item.arrivalCity}
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Departure date" />
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language}
                >
                  <DatePicker
                    value={
                      item.departureDate ? new Date(item.departureDate) : null
                    }
                    onChange={(e) => {
                      const date = new Date(e);
                      if (!isNaN(date.getTime())) {
                        let newValues = [...formData.itinerary];
                        newValues[index].departureDate = format(
                          date,
                          "yyyy-MM-dd"
                        );
                        setFormData((prevData) => ({
                          ...prevData,
                          stay: newValues,
                        }));
                      } else {
                        return;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: 200,
                          "& .MuiInputBase-input": {
                            borderColor: "rgb(176 190 197)",
                            // borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: "16px",
                            height: 9,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgb(96 125 139)",
                            },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(176 190 197)",
                          },
                        }}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={
                      formData.startDate
                        ? new Date(formData.startDate)
                        : new Date()
                    }
                  />
                </LocalizationProvider>
              </div>
              <Button
                className="mt-5"
                onClick={() => {
                  handleRemoveRow(index);
                }}
                size="sm"
                variant="text"
                color="red"
              >
                <IoMdClose size={15} />
              </Button>
            </div>
            <hr className="my-6 border-gray-300" />
          </div>
        ))}

        <IconButton
          className="rounded-full mt-2"
          color="indigo"
          onClick={handleAddRow}
          variant="text"
        >
          <i className="fas fa-plus" />
        </IconButton>
        {/* </List> */}

        <div className="mt-5">
          <Typography
            variant="small"
            className="mb-2 font-medium text-gray-700"
          >
            <Translation
              message={
                "If you have any comments or preferences, write them down here"
              }
            />{" "}
          </Typography>
          <Textarea
            value={formData.transportationComment}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                transportationComment: e.target.value,
              }));
            }}
            className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
          />
        </div>
      </div>
    </div>
  );
};

export default Transportation;
