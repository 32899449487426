import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";

// import { featuresData } from "../data";
import Navbar from "components/Navbars/Navbar";
import SimpleFooter from "components/Footers/LoginFooter";
import Dialog from "./Dialog";
import "./style.css";
import { fetchSetting } from "actions/SettingAction";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import { fetchSetting1 } from "../actions/SettingAction";
import { useTrail, animated } from "react-spring";

export function Home() {
  const [open, setOpen] = useState({ open: false, name: "" });
  const [featuresData, setFeaturesData] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const result = await fetchSetting("Homepage");
        console.log("result", result);
        setFeaturesData(result[0].Web);
        const config = result[0]?.configuration;
        localStorage.setItem("config", JSON.stringify(config));
      } catch (error) {}
    };

    fetchFeatures();
  }, []);

  console.log("features", featuresData);

  const trail = useTrail(featuresData.length, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
  });

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <Navbar />
      </div>
      <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <div className="absolute top-0 h-full w-full bg-[url('https://images.pexels.com/photos/380768/pexels-photo-380768.jpeg?auto=compress&cs=tinysrgb&w=1600')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
        <div className="max-w-12xl container relative mx-auto">
          <div className="flex flex-wrap items-center mt-20">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-12/12 mt-20 ">
              {isDesktop ? (
                // <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-6 ">
                //   {featuresData.map(
                //     ({
                //       color,
                //       title,
                //       icon,
                //       description,
                //       name,
                //       img,
                //       paragraph,
                //       linkRequests,
                //       linkAdd,
                //     }) => (
                //       <Card
                //         className="rounded-2xl shadow-lg shadow-gray-500/10 pointer"
                //         onClick={() => {
                //           setOpen({
                //             open: true,
                //             card: {
                //               color,
                //               title,
                //               icon,
                //               description,
                //               name,
                //               img,
                //               paragraph,
                //               linkRequests,
                //               linkAdd,
                //             },
                //           });
                //         }}
                //       >
                //         <CardBody className="px-8 text-center">
                //           <IconButton
                //             variant="gradient"
                //             size="lg"
                //             color={color}
                //             className="pointer-events-none mb-6 rounded-full"
                //           >
                //             {/* <i className={icon} /> */}
                //             <div dangerouslySetInnerHTML={{ __html: icon }} />
                //           </IconButton>
                //           <Typography
                //             variant="h5"
                //             className="mb-2"
                //             color="blue-gray"
                //           >
                //             <Translation message={title} />
                //           </Typography>
                //           <Typography className="font-normal text-blue-gray-600">
                //             <Translation message={description} />
                //           </Typography>
                //         </CardBody>
                //       </Card>
                //     )
                //   )}
                // </div>
                // <div
                //   className={`grid grid-cols-1 gap-4 md:grid-cols-2 items-center ${
                //     featuresData?.length > 5
                //       ? `lg:grid-cols-${featuresData?.length}`
                //       : "lg:grid-cols-6"
                //   }  `}
                // >
                <div className="flex flex-wrap content-center items-center justify-center gap-6 ">
                  {trail.map((style, index) => (
                    <animated.div style={style} key={featuresData[index].id}>
                      <Card
                        className="rounded-2xl shadow-lg shadow-gray-500/10 pointer"
                        onClick={() => {
                          setOpen({
                            open: true,
                            card: featuresData[index],
                          });
                        }}
                        style={{
                          height: "300px",
                          width: "200px",
                        }}
                      >
                        <CardBody className="px-8 text-center">
                          <IconButton
                            variant="gradient"
                            size="lg"
                            color={featuresData[index].color}
                            className="pointer-events-none mb-6 rounded-full"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: featuresData[index].icon,
                              }}
                            />
                          </IconButton>
                          <Typography
                            variant="h5"
                            className="mb-2"
                            color="blue-gray"
                          >
                            <Translation message={featuresData[index].title} />
                          </Typography>
                          <Typography className="font-normal text-blue-gray-600">
                            <Translation
                              message={featuresData[index].description}
                            />
                          </Typography>
                        </CardBody>
                      </Card>
                    </animated.div>
                  ))}
                </div>
              ) : (
                <div
                  className="mt-20"
                  style={{ height: "700px", overflow: "scroll" }}
                >
                  {featuresData.map(
                    ({
                      color,
                      title,
                      icon,
                      description,
                      name,
                      img,
                      paragraph,
                      linkRequests,
                      linkAdd,
                    }) => (
                      <Card
                        className="rounded-2xl shadow-lg shadow-gray-500/10 pointer mt-2"
                        onClick={() => {
                          setOpen({
                            open: true,
                            card: {
                              color,
                              title,
                              icon,
                              description,
                              name,
                              img,
                              paragraph,
                              linkRequests,
                              linkAdd,
                            },
                          });
                        }}
                      >
                        <CardBody className="px-8 text-center">
                          <IconButton
                            variant="gradient"
                            size="lg"
                            color={color}
                            className="pointer-events-none mb-6 rounded-full"
                          >
                            {/* <i className={icon} /> */}
                            <div dangerouslySetInnerHTML={{ __html: icon }} />
                          </IconButton>
                          <Typography
                            variant="h5"
                            className="mb-2"
                            color="blue-gray"
                          >
                            <Translation message={title} />
                          </Typography>
                          <Typography className="font-normal text-blue-gray-600">
                            <Translation message={description} />
                          </Typography>
                        </CardBody>
                      </Card>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <section className="-mt-32 bg-gray-50 px-4 pb-20 pt-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {featuresData.map(({ color, title, icon, description }) => (
              <Card className="rounded-2xl shadow-lg shadow-gray-500/10">
                <CardBody className="px-8 text-center">
                  <IconButton
                    variant="gradient"
                    size="lg"
                    color={color}
                    className="pointer-events-none mb-6 rounded-full"
                  >
                    {icon}
                  </IconButton>
                  <Typography variant="h5" className="mb-2" color="blue-gray">
                    {title}
                  </Typography>
                  <Typography className="font-normal text-blue-gray-600">
                    {description}
                  </Typography>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      </section> */}

      {isDesktop && (
        <div className="container absolute bottom-6 left-2/4 z-10 mx-auto -translate-x-2/4 text-white">
          <SimpleFooter />
        </div>
      )}
      {open.open && <Dialog open={open} setOpen={setOpen} />}
    </>
  );
}

export default Home;
