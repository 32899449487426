import {
  Button,
  ButtonGroup,
  Input,
  Typography,
} from "@material-tailwind/react";
import Select from "react-select";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Translation from "Translation";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";

import { t } from "i18next";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { fr, enUS } from "date-fns/locale";
import CustomFileInput from "components/CustomFileInput";
import { MdDelete } from "react-icons/md";
import Preview from "components/Preview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
// import { makeStyles } from "@material-ui/core";

const Passport = (props) => {
  const { formData, setFormData, users, setPassportFile, passportFile } = props;

  const handleChangeSender = (event, newType) => {
    if (newType) {
      setFormData((prevData) => ({ ...prevData, senderType: newType }));
    }
  };

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (i18n.language == "fr") {
      setLanguage(fr);
    } else {
      setLanguage(enUS);
    }
  }, [i18n.language]);

  const handleFileChange = (file) => {
    setPassportFile(file);
  };

  const handleFileDisplay = () => {
    var reader = new FileReader();
    reader.onload = (event) => {
      setPreviewUrl(event.target.result);
    };
    reader.readAsDataURL(passportFile);
    setFileType(passportFile.type);
    setDisplay(true);
  };

  return (
    <div className="lg:w-12/12 mt-50 ">
      {/* <div className="flex flex-wrap justify-start gap-10 ">
        <div className="flex gap-8">
          <Typography className="mt-5 text-sm  text-gray-700">
            <Translation message={"Traveller"} />
          </Typography>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={formData.senderType}
              exclusive
              onChange={handleChangeSender}
            >
              <ToggleButton value="myself" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Myself"} />
                </p>
              </ToggleButton>
              <ToggleButton value="colleague" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Colleague"} />
                </p>
              </ToggleButton>
              <ToggleButton value="guest" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Guest"} />
                </p>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        {formData?.senderType == "colleague" && (
          <div className="flex gap-8">
            <Typography className=" mt-5 text-sm  text-gray-700">
              <Translation message={"Colleague"} />
            </Typography>
            <Select
              options={users}
              onChange={(e) => {
                setFormData((prevData) => ({ ...prevData, idSender: e }));
              }}
              value={formData.idSender}
              placeholder={<Translation message="Choose your colleague" />}
              isClearable={true}
            />
          </div>
        )}
        {formData?.senderType == "guest" && (
          <div className="flex gap-8">
            <Typography className="mt-5 text-sm  text-gray-700">
              <Translation message={"Guest"} />
            </Typography>
            <Input
              label={<Translation message="Guest name" />}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  guest: e.target.value,
                }));
              }}
              value={formData.guest}
              color="indigo"
            />
          </div>
        )}
        {formData?.senderType == "guest" && (
          <div className="flex gap-8">
            <Typography className=" text-sm  text-gray-700">
              <Translation message={"Colleague"} />
            </Typography>
            <div className="flex" style={{ width: "300px" }}>
              <Select
                options={users}
                onChange={(e) => {
                  setFormData((prevData) => ({ ...prevData, idSender: e }));
                }}
                value={formData.idSender}
                placeholder={<Translation message="Choose your colleague" />}
                isClearable={true}
              />
              <Tooltip
                title={
                  <Translation message="The colleague who will be handling the guest's mission change" />
                }
              >
                <FaInfoCircle size={20} className="mt-3" color="gray" />
              </Tooltip>
           
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-start gap-10 mt-5">
        <div className="flex gap-8">
          <Typography className=" text-sm  text-gray-700">
            <Translation message={"Passport"} />
          </Typography>
          <Input
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                passportNumber: e.target.value,
              }));
            }}
            value={formData.passportNumber}
            color="indigo"
          />
        </div>
      </div> */}
      <div className="my-4 flex items-center gap-4">
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Traveller"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={formData.senderType}
              exclusive
              onChange={handleChangeSender}
            >
              <ToggleButton value="myself" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Myself"} />
                </p>
              </ToggleButton>
              <ToggleButton value="colleague" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Colleague"} />
                </p>
              </ToggleButton>
              <ToggleButton value="guest" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Guest"} />
                </p>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      <div className="my-4 flex flex-wrap items-center gap-4">
        {formData?.senderType == "guest" && (
          <div>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700"
            >
              <Translation message={"Guest"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>
            <Input
              type="text"
              placeholder={t("Guest")}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  guest: e.target.value,
                }));
              }}
              value={formData.guest}
            />
          </div>
        )}
        {(formData?.senderType == "colleague" ||
          formData?.senderType == "guest") && (
          <div style={{ width: "200px" }}>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700 "
            >
              <Translation message={"Colleague"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>
            <Select
              options={users}
              onChange={(e) => {
                setFormData((prevData) => ({ ...prevData, idSender: e }));
              }}
              value={formData.idSender}
              placeholder={<Translation message="Choose your colleague" />}
              isClearable={true}
              className="border-t-blue-gray-200 focus:border-t-blue-gray-400"
            />
          </div>
        )}
      </div>

      <div className="my-3 ">
        <div className="my-4 w-full flex  flex-wrap items-center gap-4">
          <div>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700 "
            >
              <Translation message={"Passport Number"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>

            <Input
              maxLength={20}
              type="text"
              placeholder={t("Passport Number")}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  passportNumber: e.target.value,
                }));
              }}
              value={formData.passportNumber}
              pattern="[a-zA-Z0-9]+"
            />
          </div>
          <div>
            <Typography
              variant="small"
              className="mb-2 font-medium text-gray-700 "
            >
              <Translation message={"Place of passport issue"} />
            </Typography>

            <Input
              type="text"
              placeholder={t("Place of passport issue")}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  placePassport: e.target.value,
                }));
              }}
              value={formData.placePassport}
            />
          </div>
          <div>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700 "
            >
              <Translation message={"Date of passport issue"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>

            {/* <Input
              type="date"
              placeholder={""}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              onChange={(e) => {
                if (e.target.value) {
                  const date = new Date(e.target.value);
                  const newDate = new Date(date.getTime());
                  newDate.setFullYear(date.getFullYear() + 5);
                  newDate.setDate(newDate.getDate() - 1);
                  setFormData((prevData) => ({
                    ...prevData,
                    expiryPassport: newDate?.toISOString()?.split("T")[0],
                  }));
                } else {
                  console.error("Date invalide :", e);
                  setFormData((prevData) => ({
                    ...prevData,
                    expiryPassport: "",
                  }));
                }
                setFormData((prevData) => ({
                  ...prevData,
                  datePassport: e.target.value,
                }));
              }}
              value={formData.datePassport}
              max={new Date().toISOString().split("T")[0]}
              lang="fr-FR"
            /> */}

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n?.language}
            >
              <DatePicker
                value={
                  formData.datePassport ? new Date(formData.datePassport) : null
                }
                onChange={(e) => {
                  const date = new Date(e);
                  if (!isNaN(date.getTime())) {
                    const newDate = new Date(date.getTime());
                    newDate.setFullYear(date.getFullYear() + 5);
                    newDate.setDate(newDate.getDate() - 1);
                    setFormData((prevData) => ({
                      ...prevData,
                      expiryPassport: newDate?.toISOString()?.split("T")[0],
                    }));
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      expiryPassport: "",
                    }));
                    return;
                  }
                  setFormData((prevData) => ({
                    ...prevData,
                    datePassport: format(date, "yyyy-MM-dd"),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 200,
                      "& .MuiInputBase-input": {
                        borderColor: "rgb(176 190 197)",
                        // borderWidth: 1,
                        borderStyle: "solid",
                        borderRadius: "16px",
                        height: 9,
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgb(96 125 139)",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgb(176 190 197)",
                      },
                    }}

                    // onChange={() => null}
                    // value={
                    //   formData.datePassport
                    //     ? format(new Date(formData.datePassport), "dd/MM/yyyy")
                    //     : ""
                    // }
                  />
                )}
                inputFormat="DD/MM/YYYY"
                maxDate={new Date()}
              />
            </LocalizationProvider>

            {/* <Popover placement="right">
              <PopoverHandler>
                <Input
                  onChange={() => null}
                  value={
                    formData.datePassport
                      ? format(new Date(formData.datePassport), "dd-MM-yyyy")
                      : ""
                  }
                  //   value={
                  //     formData.datePassport
                  //       ? format(formData.datePassport, "dd-MM-yyyy")
                  //       : ""
                  //   }
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  placeholder="dd-mm-yyyy"
                />
              </PopoverHandler>
              <PopoverContent>
                <DayPicker
                  locale={language}
                  mode="single"
                  //   selected={
                  //     formData.datePassport
                  //       ? format(new Date(formData.datePassport), "dd-MM-yyyy")
                  //       : ""
                  //   }
                  selected={
                    formData.datePassport ? new Date(formData.datePassport) : ""
                  }
                  onSelect={(date) => {
                    console.log("🚀 ~ Passport ~ e:", date);
                    if (date) {
                      const newDate = new Date(date.getTime());
                      newDate.setFullYear(date.getFullYear() + 5);
                      newDate.setDate(newDate.getDate() - 1);
                      setFormData((prevData) => ({
                        ...prevData,
                        expiryPassport: newDate,
                      }));
                    } else {
                      console.error("Date invalide :", date);
                      setFormData((prevData) => ({
                        ...prevData,
                        expiryPassport: "",
                      }));
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      datePassport: format(date, "yyyy-MM-dd"),
                    }));
                  }}
                  showOutsideDays
                  className="border-0"
                  classNames={{
                    caption:
                      "flex justify-center py-2 mb-4 relative items-center",
                    caption_label: "text-sm font-medium text-gray-900",
                    nav: "flex items-center",
                    nav_button:
                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                    nav_button_previous: "absolute left-1.5",
                    nav_button_next: "absolute right-1.5",
                    table: "w-full border-collapse",
                    head_row: "flex font-medium text-gray-900",
                    head_cell: "m-0.5 w-9 font-normal text-sm",
                    row: "flex w-full mt-2",
                    cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 font-normal",
                    day_range_end: "day-range-end",
                    day_selected:
                      "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                    day_today: "rounded-md bg-gray-200 text-gray-900",
                    day_outside:
                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                    day_disabled: "text-gray-500 opacity-50",
                    day_hidden: "invisible",
                  }}
                  components={{
                    IconLeft: ({ ...props }) => (
                      <ChevronLeftIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                    IconRight: ({ ...props }) => (
                      <ChevronRightIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                  }}
                  modifiers={{
                    disabled: [
                      {
                        after: new Date(),
                      },
                    ],
                  }}
                  //   formatters={{
                  //     formatDate: (date) => format(date, "dd-MM-yyyy"),
                  //   }}
                  //   month={
                  //     formData.datePassport
                  //       ? new Date(formData.datePassport)
                  //       : null
                  //   }
                />
              </PopoverContent>
            </Popover> */}
          </div>
          <div>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700 "
            >
              <Translation message={"Expiry passport date"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>

            {/* <Input
              type="date"
              placeholder={""}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  expiryPassport: e.target.value,
                }));
              }}
              value={formData.expiryPassport}
            /> */}
            {/* <Popover placement="right">
              <PopoverHandler>
                <Input
                  onChange={() => null}
                  //   value={
                  //     formData.expiryPassport
                  //       ? format(formData.expiryPassport, "dd-MM-yyyy")
                  //       : ""
                  //   }
                  value={
                    formData.expiryPassport
                      ? format(new Date(formData.expiryPassport), "dd-MM-yyyy")
                      : ""
                  }
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  placeholder="dd-mm-yyyy"
                />
              </PopoverHandler>
              <PopoverContent>
                <DayPicker
                  locale={language}
                  mode="single"
                  selected={formData.endDate ? new Date(formData.endDate) : ""}
                  onSelect={(date) => {
                    if (date) {
                      const newDate = new Date(date.getTime());
                      newDate.setFullYear(date.getFullYear() + 5);
                      newDate.setDate(newDate.getDate() - 1);
                    } else {
                      console.error("Date invalide :", date);
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      expiryPassport: format(date, "yyyy-MM-dd"),
                    }));
                  }}
                  showOutsideDays
                  className="border-0"
                  classNames={{
                    caption:
                      "flex justify-center py-2 mb-4 relative items-center",
                    caption_label: "text-sm font-medium text-gray-900",
                    nav: "flex items-center",
                    nav_button:
                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                    nav_button_previous: "absolute left-1.5",
                    nav_button_next: "absolute right-1.5",
                    table: "w-full border-collapse",
                    head_row: "flex font-medium text-gray-900",
                    head_cell: "m-0.5 w-9 font-normal text-sm",
                    row: "flex w-full mt-2",
                    cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 font-normal",
                    day_range_end: "day-range-end",
                    day_selected:
                      "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                    day_today: "rounded-md bg-gray-200 text-gray-900",
                    day_outside:
                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                    day_disabled: "text-gray-500 opacity-50",
                    day_hidden: "invisible",
                  }}
                  components={{
                    IconLeft: ({ ...props }) => (
                      <ChevronLeftIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                    IconRight: ({ ...props }) => (
                      <ChevronRightIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                  }}
                  modifiers={{
                    disabled: [
                      {
                        before: new Date(),
                      },
                    ],
                  }}
                  //   formatters={{
                  //     formatDate: (date) => format(date, "dd-MM-yyyy"),
                  //   }}
                  //   month={
                  //     formData.expiryPassport
                  //       ? new Date(formData.expiryPassport)
                  //       : ""
                  //   }
                />
              </PopoverContent>
            </Popover> */}
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n?.language}
            >
              <DatePicker
                value={
                  formData.expiryPassport
                    ? new Date(formData.expiryPassport)
                    : null
                }
                onChange={(e) => {
                  const date = new Date(e);

                  setFormData((prevData) => ({
                    ...prevData,
                    expiryPassport: format(date, "yyyy-MM-dd"),
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: 200,
                      "& .MuiInputBase-input": {
                        borderColor: "rgb(176 190 197)",
                        // borderWidth: 1,
                        borderStyle: "solid",
                        borderRadius: "10px",
                        height: 9,
                      },

                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "rgb(176 190 197)",
                        },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgb(176 190 197)",
                      },
                    }}
                    // onChange={() => null}
                    // value={
                    //   formData.datePassport
                    //     ? format(new Date(formData.datePassport), "dd/MM/yyyy")
                    //     : ""
                    // }
                  />
                )}
                inputFormat="DD/MM/YYYY"
                minDate={new Date()}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="flex mt-10">
          <Typography
            className="flex gap-1 text-sm  text-gray-700 pt-1"
            style={{ marginRight: 20 }}
          >
            <Translation message="Upload passport file" />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <CustomFileInput onFileChange={handleFileChange} />
        </div>
        {passportFile && (
          <div className="flex mt-10 ">
            <Typography className=" text-sm  text-gray-700 pt-1">
              {passportFile?.name}
            </Typography>
            <ButtonGroup
              style={{
                marginLeft: "10px",
                outline: "none",
                color: "#294e87",
              }}
              size="small"
              variant="text"
            >
              <Button
                style={{ outline: "none" }}
                onClick={() => {
                  handleFileDisplay();
                }}
              >
                <FaEye size={20} style={{ color: "#294e87" }} />
              </Button>
              <Button
                style={{ outline: "none" }}
                onClick={() => {
                  setPassportFile(null);
                }}
              >
                <MdDelete size={20} style={{ color: "#294e87" }} />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>

      {previewUrl && fileType && (
        <Preview
          view={display}
          setView={setDisplay}
          fileType={fileType}
          previewUrl={previewUrl}
        />
      )}
    </div>
  );
};

export default Passport;
