import {
  Button,
  Card,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemPrefix,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Translation from "Translation";
import { fetchCityCap } from "actions/travel.service";
import { format } from "date-fns";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiMinus } from "react-icons/bi";
import { FaHome, FaHotel } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

const Accommodation = (props) => {
  const { formData, setFormData, missions, stay, setStay } = props;
  const { i18n } = useTranslation();

  const handleAddRow = () => {
    // setStay([
    //   ...stay,
    //   {
    //     departureCountry: null,
    //     departureCity: null,
    //     arrivalCountry: null,
    //     arrivalCity: null,
    //     departureDate: null,
    //     transportType: null,
    //   },
    // ]);
    let newStay = {
      type: null,
    };
    if (stay.length > 0) {
      const lastLineEndDate = new Date(stay[stay.length - 1].endDate);
      const newStartDate = new Date(lastLineEndDate.getTime());
      newStartDate.setDate(lastLineEndDate.getDate() + 1);
      newStay.startDate = newStartDate;
      newStay.endDate = newStartDate;
    }
    setFormData((prev) => ({ ...prev, stay: [...formData.stay, newStay] }));
    setStay(newStay);
  };

  const handleRemoveRow = (index) => {
    if (
      formData.missionType == "simple" &&
      formData.Formula?.type.toLowerCase() === "hybride" &&
      formData?.stay.length == 2
    ) {
      NotificationManager.error(
        "The chosen formula does not allow the deletion of rows. You can change the content of the fields in the rows."
      );
      return;
    }

    if (stay.length == 1) {
      NotificationManager.error(
        "The chosen formula does not allow the deletion of rows. You can change the content of the fields in the rows."
      );
      return;
    }
    const values = [...formData.stay];
    values.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      stay: values,
    }));
  };

  console.log("formData", formData);

  const accomodationTypes = [
    {
      value: "personal accomodation",
      label: t("personal accommodation"),
    },
    { value: "hotel", label: t("hotel") },
  ];

  return (
    <div className="lg:w-12/12 mt-50 ">
      <Typography className=" text-sm  text-gray-700 pt-1">
        <Translation message="Compose your stay" />
      </Typography>
      <div className="w-full mt-10">
        {/* <List> */}
        {/* <div></div> */}
        {formData.stay?.map((item, index) => (
          <div key={index}>
            <div className="flex flex-row flex-wrap gap-4 mt-10" key={index}>
              <div className="mt-10">
                {item.type == "hotel" ? (
                  <FaHotel color="#294e87" />
                ) : (
                  <FaHome color="#294e87" />
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Accommodation type" />
                </Typography>
                <Select
                  options={accomodationTypes}
                  onChange={(e) => {
                    let newStay = [...formData.stay];
                    newStay[index].type = e.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      stay: newStay,
                    }));
                  }}
                  value={
                    item.type
                      ? accomodationTypes.find((val) => val.value == item.type)
                      : ""
                  }
                  className="select-width-200"
                  style={{ zIndex: 9999 }}
                />
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Start date" />
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language}
                >
                  <DatePicker
                    value={item.startDate ? new Date(item.startDate) : null}
                    onChange={(e) => {
                      const date = new Date(e);
                      if (!isNaN(date.getTime())) {
                        let newStay = [...formData.stay];
                        newStay[index].startDate = format(date, "yyyy-MM-dd");
                        setFormData((prevData) => ({
                          ...prevData,
                          stay: newStay,
                        }));
                      } else {
                        return;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: 200,
                          "& .MuiInputBase-input": {
                            borderColor: "rgb(176 190 197)",
                            // borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: "16px",
                            height: 9,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgb(96 125 139)",
                            },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(176 190 197)",
                          },
                        }}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={
                      formData.startDate
                        ? new Date(formData.startDate)
                        : new Date()
                    }
                  />
                </LocalizationProvider>
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="End date" />
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language}
                >
                  <DatePicker
                    value={item.startDate ? new Date(item.endDate) : null}
                    onChange={(e) => {
                      const date = new Date(e);
                      if (!isNaN(date.getTime())) {
                        let newStay = [...formData.stay];
                        newStay[index].endDate = format(date, "yyyy-MM-dd");
                        if (index < newStay.length - 1) {
                          const newStartDate = new Date(date);
                          newStartDate.setDate(newStartDate.getDate() + 1);
                          newStay[index + 1].startDate = newStartDate;
                        }
                        setFormData((prevData) => ({
                          ...prevData,
                          stay: newStay,
                        }));
                      } else {
                        return;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: 200,
                          "& .MuiInputBase-input": {
                            borderColor: "rgb(176 190 197)",
                            // borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: "16px",
                            height: 9,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgb(96 125 139)",
                            },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(176 190 197)",
                          },
                        }}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={
                      formData.startDate
                        ? new Date(formData.startDate)
                        : new Date()
                    }
                  />
                </LocalizationProvider>
              </div>

              {formData.missionType === "compound" ? (
                <>
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Mission"} />
                    </Typography>

                    <Select
                      options={missions?.map((mission) => ({
                        ...mission,
                        value: mission.title,
                        label: mission.title,
                      }))}
                      onChange={async (e) => {
                        let newStay = [...formData.stay];
                        newStay[index].title = e.value;
                        const selectedMission = missions
                          ?.map((mission) => ({
                            ...mission,
                            value: mission.title,
                            label: mission.title,
                          }))
                          .find((val) => val.value == item.title);
                        newStay[index].engagementCode =
                          selectedMission?.engagementCode;
                        const res = await fetchCityCap(
                          selectedMission.country?._id,
                          selectedMission.city?._id
                        );
                        newStay[index].cityCap = res?._id ? res : null;

                        setFormData((prevData) => ({
                          ...prevData,
                          stay: newStay,
                        }));
                      }}
                      value={
                        item.title
                          ? missions
                              ?.map((mission) => ({
                                ...mission,
                                value: mission.title,
                                label: mission.title,
                              }))
                              .find((val) => val.value == item.title)
                          : ""
                      }
                      className="select-width-200"
                      style={{ zIndex: 9999 }}
                    />

                    {/* <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.title}
                    /> */}
                  </div>
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Engagement code"} />
                    </Typography>

                    <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.engagementCode ? item.engagementCode : ""}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {!item.undeletable && (
                <Button
                  className="mt-5"
                  onClick={() => {
                    handleRemoveRow(index);
                  }}
                  size="sm"
                  variant="text"
                  color="red"
                >
                  <IoMdClose size={15} />
                </Button>
              )}
            </div>
            <hr className="my-6 border-gray-300" />
          </div>
        ))}

        <IconButton
          className="rounded-full mt-2"
          color="indigo"
          onClick={handleAddRow}
          variant="text"
        >
          <i className="fas fa-plus" />
        </IconButton>
        {/* </List> */}

        <div className="mt-5">
          <Typography
            variant="small"
            className="mb-2 font-medium text-gray-700"
          >
            <Translation message={"Do you have any preferences?"} />{" "}
          </Typography>
          <Textarea
            value={formData.accomodationComment}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                accomodationComment: e.target.value,
              }));
            }}
            className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
          />
        </div>
      </div>
    </div>
  );
};

export default Accommodation;
