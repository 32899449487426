import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-tailwind/react/components/Button";
import { fetchOperationsByRequest } from "../actions/OperationAction";
import moment from "moment";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogContent: {
    textAlign: "center",
    //   padding: theme.spacing(5)
  },
  operations: {
    paddingTop: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: "center",
  },
}));

export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog, token, informations } = props;
  const classes = useStyles();
  const [details, setDetails] = useState();
  const [message, setMessage] = useState();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (confirmDialog.isOpen === true && informations) {
        const data = await fetchOperationsByRequest(token, informations._id);
        setDetails(data);
      }
    };
    fetch();
    setDisabled(false);
  }, [confirmDialog]);

  useEffect(() => {
    details &&
      details.map((item) => {
        if (moment(item.date).format() < moment().format()) {
          setMessage(
            t(
              "This request contains past slots, you can't delete it, try deleting slots in your calendar."
            )
          );
          return;
        }
      });
  }, [details]);

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle></DialogTitle>
      {message ? (
        <>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="subtitle2">{message}</Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              color="gray"
              onClick={() => {
                setConfirmDialog({ isOpen: false, title: "", subtitle: "" });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h6">{confirmDialog.title}</Typography>
            <Typography variant="subtitle2">
              {confirmDialog.subtitle}
            </Typography>
            {informations && (
              <Typography className={classes.operations} variant="subtitle2">
                <Translation
                  message={"This request contains the following slots"}
                />
              </Typography>
            )}

            {details &&
              details.map((operation) => {
                return (
                  <>
                    {operation.OperationType === "REMOTE_WORKING" ? (
                      <Typography
                        className={classes.operations}
                        variant="subtitle2"
                      >
                        {" "}
                        {operation.date_debut
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}{" "}
                        {" -> "}
                        {operation.date_fin
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}
                      </Typography>
                    ) : operation.OperationType === "Leave" ? (
                      <Typography
                        className={classes.operations}
                        variant="subtitle2"
                      >
                        From{" "}
                        {operation.date_debut
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          operation.slot_debut}{" "}
                        To{" "}
                        {operation.date_fin
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          operation.slot_fin}
                      </Typography>
                    ) : (
                      <Typography
                        className={classes.operations}
                        variant="subtitle2"
                      >
                        {operation.date
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}{" "}
                        {operation.timeslot}
                      </Typography>
                    )}
                  </>
                );
              })}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {!disabled ? (
              <Button
                style={{ backgroundColor: "red" }}
                onClick={() => {
                  setDisabled(true);
                  confirmDialog.onConfirm();
                }}
              >
                <Translation message={"Yes"} />
              </Button>
            ) : (
              <ClipLoader color="red" size={20} />
            )}

            <Button
              color="gray"
              onClick={() => {
                setConfirmDialog({ isOpen: false, title: "", subtitle: "" });
              }}
            >
              <Translation message={"No"} />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
