import React, { useState, useEffect } from "react";

// import { blue, orange } from '@material-ui/core/colors';
import { fetchTeamOperationsByManager } from "../../actions/OperationAction";

import H3, { Chip } from "@material-tailwind/react";
import Paragraph from "@material-tailwind/react";
import { Typography, Button } from "@material-tailwind/react";
import jwt from "jwt-decode";
import useMediaQuery from "useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
// import { CalendarIcon, DownloadIcon } from "@heroicons/react/solid";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import DownloadIcon from "@mui/icons-material/SaveAlt";

import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";
import Translation from "Translation";
import { t } from "i18next";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { fr, enUS } from "date-fns/locale";

export default function ListView() {
  const color = "#3949ab";

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(true);

  const { i18n } = useTranslation();

  const [calendar, setCalendar] = useState(false);

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const columns = [
    {
      field: "fullName",
      headerName: <Translation message={"Full name"} />,
      //description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    {
      field: "operationType",
      headerName: <Translation message={"Work mode"} />,
      sortable: false,
      width: 290,
      renderCell: (params) => {
        // console.log("params", params);
        // console.log(" params.row.authorizedDays", params.row.authorizedDays);
        return (
          <>
            {params.row.operationType}
            {params.row.operationType == "WFH" && params.row.authorizedDays && (
              <p>
                <span
                  style={{ color: "#bfbfbf", marginLeft: 2 }}
                >{`(${params.row.authorizedDays?.map((day, index) =>
                  index == params.row.authorizedDays.length - 1
                    ? // <Translation message={day} />
                      t(day)
                    : // <Translation message={day} /> + ", "
                      t(day)
                )}
                
                )`}</span>
                <br />
              </p>
            )}
          </>
        );
      },
    },

    {
      field: "date",
      headerName: <Translation message={"Requested Days"} />,
      sortable: false,
      width: 250,
      // renderCell: (params) => {
      //   const day = moment(params.row.date, "DD-MM-YYYY").format("dddd");
      //   return <>{`${day} ${params.row.date} ${params.row.timeslot}`}</>;
      // },
    },
    {
      field: "status",
      headerName: <Translation message={"Status"} />,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.status && (
              <Chip
                style={{
                  float: "right",
                  color:
                    params.row.status.toLowerCase() == "booked" ||
                    params.row.status.toLowerCase() == "pending"
                      ? "#B78103"
                      : params.row.status.toLowerCase() == "checked-out" ||
                        params.row.status.toLowerCase() == "rejected"
                      ? "#b70303"
                      : "#229A16",
                  // width: 50,
                  // fontSize: 12,
                  // height: 25,
                  // "& svg, img": { width: 1, height: 1, objectFit: "cover" },
                  backgroundColor:
                    params.row.status.toLowerCase() == "booked" ||
                    params.row.status.toLowerCase() == "pending"
                      ? "rgba(255, 193, 7, 0.16)"
                      : params.row.status.toLowerCase() == "checked-out" ||
                        params.row.status.toLowerCase() == "rejected"
                      ? "rgba(255, 0, 0, 0.16)"
                      : "rgba(84, 214, 44, 0.16)",
                }}
                className="text-white"
                value={params.row.status}
              />
            )}
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: <Translation message={"Submission date"} />,
      width: 160,
      sortable: false,
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      setOpen(true);
      const result = await fetchTeamOperationsByManager(
        user.id,
        moment(dates[0].startDate).format().substring(0, 10),
        moment(dates[0].endDate).format().substring(0, 10)
      );
      var rowsData = [];
      console.log("result", result);

      if (result?.length > 0) {
        for (const item of result) {
          // const day = moment(item.date, "DD-MM-YYYY").format("dddd");
          const day = item.reservationdate
            ? format(new Date(item.reservationdate), "EEEE", {
                locale: i18n.language == "fr" ? fr : enUS,
              })
            : item.date
            ? format(new Date(item.date), "EEEE", {
                locale: i18n.language == "fr" ? fr : enUS,
              })
            : ` ${format(new Date(item.date_debut), "dd-MM-yyyy", {
                locale: i18n.language == "fr" ? fr : enUS,
              })} to ${format(new Date(item.date_fin), "dd-MM-yyyy", {
                locale: i18n.language == "fr" ? fr : enUS,
              })}`;
          rowsData.push({
            id: item._id,
            lastName: item.user?.lastname,
            firstName: item.user?.firstname,
            status:
              item.status && item.status == "ACTIVE"
                ? "Booked"
                : item.status && item.status == "ENDED"
                ? "Checked-In"
                : item.status && item.status == "CLOSED"
                ? "Checked-Out"
                : item.request?.status == "accepted"
                ? "Approved"
                : item.request?.status == "refused"
                ? "Rejected"
                : "Pending",
            createdAt:
              item.createdAt?.substring(0, 10).split("-").reverse().join("-") +
              " " +
              item?.createdAt.substring(11, 16),
            // date: item.date
            //   ? item.date?.substring(0, 10).split("-").reverse().join("-") +
            //     " " +
            //     item.timeslot
            //   : item.reservationdate
            //       ?.substring(0, 10)
            //       .split("-")
            //       .reverse()
            //       .join("-") +
            //     " " +
            //     item.timeslot,
            // date: item.date?.substring(0, 10).split("-").reverse().join("-"),
            date: `${day} ${
              item.reservationdate
                ? item.reservationdate
                    ?.substring(0, 10)
                    .split("-")
                    .reverse()
                    .join("-")
                : item.date
                ? item.date?.substring(0, 10).split("-").reverse().join("-")
                : ""
            } ${item.timeslot ? item.timeslot : ""}`,
            timeslot: item.timeslot,
            operationType:
              // item.OperationType == "RESERVATION" ? (
              //   <Translation message={"On site"} />
              // ) : item.OperationType == "REMOTE_WORKING" ? (
              //   <Translation message={"Extended WFH"} />
              // ) : item.OperationType == "WFH" ? (
              //   <Translation message={"WFH"} />
              // ) : (
              //   <Translation message={"Third party site"} />
              // ),
              item.OperationType == "RESERVATION"
                ? t("On site")
                : item.OperationType == "REMOTE_WORKING"
                ? t("Exceptional WFH")
                : t(item.OperationType),
            authorizedDays: item?.user?.wfhProfile?.authorizedWfhDays,
          });
        }
      }

      setRows(rowsData);
      setOpen(false);
    };

    if (dates[0]?.startDate && dates[0]?.endDate) {
      fetch();
    }
  }, [dates]);

  function exportDataToCsv(rows, columns) {
    var resultToExport = [];
    rows.map((item) => {
      resultToExport.push({
        "Full Name": item?.firstName + " " + item?.lastName,
        "Work mode":
          item.operationType == "RESERVATION"
            ? "On site"
            : item.operationType == "WFH"
            ? item.operationType + " (" + item.authorizedDays + ")"
            : item.operationType,
        Date: item?.date,

        STATUS: item?.status,
        "Sent date": item?.createdAt,
      });
    });
    const worksheet = XLSX.utils.json_to_sheet(resultToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `TeamsPlanning${moment(dates[0].startDate)
        .format()
        ?.substring(0, 10)
        .split("-")
        .reverse()
        .join("-")} / ${moment(dates[0].endDate)
        .format()
        ?.substring(0, 10)
        .split("-")
        .reverse()
        .join("-")}.xlsx`
    );
  }

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <div className="container max-w-8xl mx-auto px-4 ">
      <div className="flex flex-wrap justify-center">
        <div className="flex flex-wrap justify-center flex-grow-3 gap-8 mb-12">
          <div className="mt-10 mb-20" style={{ width: "100%" }}>
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
              <div className="min-w-0 flex-1">
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {`${dates[0]?.startDate
                      ?.toISOString()
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")} -> ${dates[0]?.endDate
                      ?.toISOString()
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")} `}
                  </div>
                </div>
              </div>
              <div className="mt-5 flex lg:mt-0 lg:ml-4">
                <span className="hidden sm:block">
                  <button
                    onClick={() => {
                      setCalendar(!calendar);
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <CalendarIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <Translation message={"Period"} />
                  </button>
                </span>

                <span className="sm:ml-3">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => exportDataToCsv(rows, columns)}
                  >
                    <DownloadIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <Translation message={"Export"} />
                  </button>
                </span>
              </div>
            </div>
            {calendar && (
              <div className="mt-2  flex items-center text-sm text-gray-500">
                <DateRangePicker
                  onChange={(item) => setDates([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={dates}
                  direction="horizontal"
                  rangeColors={[color]}
                  color={color}
                />
              </div>
            )}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              loading={open}
              disableColumnSelector
              stickyHeader={true}
              style={{ height: 600 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
