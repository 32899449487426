import React, { useState, useEffect } from "react";

import { DataGrid, GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import Button from "@material-tailwind/react/components/Button";
import {
  getPendingRequestsByManager,
  updateRequest,
} from "../../actions/RequestAction";
import jwt from "jwt-decode";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { fetchOperationsByRequest } from "actions/OperationAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PopupDeledate from "components/Team/PopupDelegate";
import { Link } from "react-router-dom";
import Translation from "Translation";

export default function ClientSite(props) {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const [rows, setRows] = useState([]);
  const user = jwt(token);
  const [openPopup, setOpenPopup] = useState({ open: false });
  const [validator, setValidator] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);

  const columns = [
    {
      field: "fullName",
      headerName: <Translation message={"Full name"} />,
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    {
      field: "operations",
      headerName: <Translation message={"Requested Days"} />,
      sortable: false,
      width: 450,
      renderCell: (params) => {
        var ch = "";
        params.formattedValue.map((day) => {
          ch = ch + day.date.substring(0, 10) + " " + day.timeslot + "\n";
          // return <Button  color="green"
          //           buttonType="link"
          //           size="regular"
          //           rounded={false}
          //           block={false}
          //           iconOnly={false}
          //           ripple="dark" ><i className="fas fa-check fa"></i></Button>;
        });
        return <p>{ch}</p>;
      },
    },
    {
      field: "date",
      headerName: <Translation message={"Sent date"} />,
      width: 160,
    },
    {
      field: "status",
      headerName: <Translation message={"Status"} />,
      // type: 'number',
      width: 120,
    },
    {
      field: "accept",
      headerName: "",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        setHide(params.row.status == "pending" ? false : true);
        const onApprove = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          // NotificationManager.success('Updated successfully')
          // setRows(rows.filter(item => item.id != params.id ))
          const result = await updateRequest("", params.id, {
            status: "accepted",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message, "Success");
            setRows(rows.filter((item) => item.id != params.id));
          } else {
            NotificationManager.error(result.message, "Error");
          }
        };

        return (
          <Button
            color="green"
            variant="text"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="dark"
            onClick={onApprove}
            disabled={params.row.status == "pending" ? false : true}
          >
            <i className="fas fa-check fa"></i>
          </Button>
        );
      },
    },
    {
      field: "refuse",
      headerName: "",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        setHide(params.row.status == "pending" ? false : true);
        const onReject = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          // console.log('params', params)
          // const api: GridApi = params.api;
          // const thisRow: Record<string, GridCellValue> = {};

          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== '__check__' && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
          //   );
          // NotificationManager.success('Updated successfully')
          // setRows(rows.filter(item => item.id != params.id ))
          const result = await updateRequest("", params.id, {
            status: "refused",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message, "Success");
            setRows(rows.filter((item) => item.id != params.id));
          } else {
            NotificationManager.error(result.message, "Error");
          }
        };

        return (
          <Button
            color="red"
            variant="text"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="dark"
            onClick={onReject}
            disabled={params.row.status == "pending" ? false : true}
          >
            <i className="fas fa-times fa"></i>
          </Button>
        );
      },
    },
    {
      field: "details",
      headerName: "",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          // console.log('params', params)
          // const api: GridApi = params.api;
          // const thisRow: Record<string, GridCellValue> = {};

          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== '__check__' && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
          //   );
          let path = `/request/${params.id}`;
          //   console.log(thisRow)

          return (window.location.href = path);
        };

        return (
          <Link to={`/request/${params.id}`}>
            <Button
              color="indigo"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              // onClick={onClick}
            >
              <Translation message={"Details"} />
            </Button>
          </Link>
        );
      },
    },

    // {
    //     field: 'action',
    //     headerName: 'Action',
    //     sortable: false,
    //     width: 260,
    //     renderCell: (params) => {
    //       const onClick = (e) => {
    //         e.stopPropagation(); // don't select this row after clicking
    //         console.log('params', params)
    //         const api: GridApi = params.api;
    //         const thisRow: Record<string, GridCellValue> = {};

    //         api
    //           .getAllColumns()
    //           .filter((c) => c.field !== '__check__' && !!c)
    //           .forEach(
    //             (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //           );
    //           let path = `/request/${params.id}`;
    //         //   console.log(thisRow)

    //         return window.location.href = path;
    //       };

    //       return <Button  color="indigo"
    //             buttonType="link"
    //             size="regular"
    //             rounded={false}
    //             block={false}
    //             iconOnly={false}
    //             ripple="dark" onClick={onClick}>Edit</Button>;
    //     },
    //   }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      const result = await getPendingRequestsByManager(
        token,
        user.id,
        "Client site"
      );
      const CSRequets =
        result && result?.filter((item) => item._id.name == "Client site");
      var rowsData = [];
      for (const item of CSRequets) {
        // const data = await fetchOperationsByRequest(token, item._id);
        rowsData.push({
          id: item._id._id,
          lastName: item.idSender?.lastname,
          firstName: item.idSender?.firstname,
          status: item._id.status,
          date:
            item._id.createdAt.substring(0, 10).split("-").reverse().join("-") +
            " " +
            item._id.createdAt.substring(11, 16),
          operations: item.operations,
        });
      }
      // setData(CSRequets)
      setRows(rowsData);
      setOpen(false);
    };
    fetchData();
  }, []);

  const onApproveAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "accepted",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request approved");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onRejectAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "refused",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request rejected");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onDelegateAll = async (e) => {
    e.preventDefault();

    setOpenPopup({ open: true, ids: selectionModel, multiple: true });
  };

  useEffect(() => {
    selectionModel.length > 0 ? setVisible(true) : setVisible(false);
  }, [selectionModel]);

  return (
    <>
      <div className="mt-10" style={{ height: 1000, width: "100%" }}>
        {visible && (
          <div className="flex justify-center mb-5">
            <Button
              color="green"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApproveAll}
            >
              <i className="fas fa-check fa"></i> Accept selected
            </Button>
            <Button
              color="red"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onRejectAll}
            >
              <i className="fas fa-times fa"></i> Reject selected
            </Button>
            <Button
              color="indigo"
              buttonType="link"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onDelegateAll}
            >
              <i className="fas fa-paper-plane fa"></i>Delegate selected
            </Button>
          </div>
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={open}
          columnVisibilityModel={{
            accept: !hide,
            refuse: !hide,
          }}
        />
      </div>
      <PopupDeledate
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setValidator={setValidator}
        validator={validator}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
      />
      <NotificationContainer />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>
  );
}
