import * as React from "react";
import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Button from '@mui/material/Button';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Sick from "@mui/icons-material/Sick";
import BeachAccess from "@mui/icons-material/BeachAccess";
import HomeWork from "@mui/icons-material/HomeWork";
import Cached from "@mui/icons-material/Cached";
import CardTravel from "@mui/icons-material/CardTravel";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import H3 from "@material-tailwind/react";
import H5 from "@material-tailwind/react";
import Typography from "@material-tailwind/react";
import Input from "@material-tailwind/react/components/Input";
import Textarea from "@material-tailwind/react/components/Textarea";
import Button from "@material-tailwind/react/components/Button";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import FlightIcon from "@mui/icons-material/Flight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HailIcon from "@mui/icons-material/Hail";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import Typography from "@mui/material/Typography";
import TeleworkForm from "./TeleworkForm";
import GroupsIcon from "@mui/icons-material/Groups";
import useMediaQuery from "../../useMediaQuery";

import "./form.css";
import { Hail, Support } from "@mui/icons-material";
import ClientSiteForm from "./ClientSiteForm";
import Drawer from "./Drawer";
import ClaimForm from "./ClaimForm";
import { useNavigate } from "react-router-dom";
// import { featuresData } from "../../data";
// import { QrReader } from "react-qr-reader";
// import Qrscan from "./Qrscan";
import Scan from "pages/Scan";
import { useTranslation } from "react-i18next";
import { fetchSetting } from "actions/SettingAction";
import IconComponent from "components/IconComponent";
import Leave from "./Leave";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";

const color = "#083985";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Form({
  dates,
  toggleDrawer,
  state,
  action,
  openCheckin,
  setOpenCheckin,
}) {
  // const [state, setState] = React.useState({ left: false });
  // const [selected, setSelected] = useState(people[3]);
  const [loading, setloading] = useState(false);
  const [type, setType] = useState("casual");
  // const [action, setAction] = useState("");
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [scan, setScan] = useState(false);
  // const [open, setOpen] = useState(false);
  const [features, setFeatures] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSetting("features");
      setFeatures(result[0]?.Web);
    };

    fetchData();
  }, []);

  const handleChange = (event, newType) => {
    setType(newType);
  };

  const handleSend = () => {
    setloading(true);
    setTimeout(function () {
      setloading(false);
      NotificationManager.success("Request sent successfully", "Success");
      // NotificationManager.error('Something went wrong', 'Error', 5000, () => {
      //     alert('callback');
      //   });
      // setNotif({isOpen:true, message:'Request sended successfully', type: 'success'})
    }, 1000);
  };

  const OnSite = () => {
    // <Navigate to="/reservation" />;
    // window.location.pathname = "/reservation";
    navigate("/reservation");
  };

  const clientSteRequest = () => (
    <Box role="presentation">
      <ClientSiteForm dates={dates} />
    </Box>
  );

  const formHomeWork = (anchor) => (
    <Box role="presentation">
      {" "}
      <TeleworkForm dates={dates} />{" "}
    </Box>
  );

  const formLeave = (anchor) => navigate("/leave");

  const formTravel = (anchor) => navigate("/travel");

  // <Box role="presentation">
  //   {" "}
  //   <Leave dates={dates} />{" "}
  // </Box>

  const supportRequest = () => (
    <Box role="presentation">
      <ClaimForm />
    </Box>
  );

  const renderForm = () => {
    switch (action) {
      case "WFH":
        return formHomeWork("left");
      case "On Site":
        return OnSite("left");
      case "Support":
        return supportRequest();
      case "Leave":
        return formLeave();
      case "Third party site":
        return clientSteRequest();
      case "Travel":
        return formTravel();
      default:
        return;
    }
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const theme = createMuiTheme({
    zIndex: {
      popover: 1300,
    },
  });

  return (
    <div>
      <Box sx={{ height: 100, transform: "translateZ(0px)", flexGrow: 1 }}>
        {/* <p style={{
           position: 'absolute', bottom: 19, right: 90 
         }}  >Add</p> */}

        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 10, right: 40 }}
          icon={<SpeedDialIcon />}
          // color='red'
          // ButtonProps={{ color: "secondary" }}
          FabProps={{
            size: "large",
            style: { backgroundColor: "#136bc2", outline: "none" },
          }}
          direction="left"
        >
          {/* {featuresData.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={t(action.name)}
              onClick={toggleDrawer("left", true, action.name)}
              // FabProps={{ style: { color: "#294e87" } }}
              FabProps={{ style: { color: action.color, outline: "none" } }}

              // onClick={handleOpen(action.name)}
            />
          ))} */}

          {features.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={<IconComponent name={action.icon} />}
              tooltipTitle={t(action.name)}
              onClick={toggleDrawer("left", true, action.name)}
              // FabProps={{ style: { color: "#294e87" } }}
              FabProps={{ style: { color: action.color, outline: "none" } }}

              // onClick={handleOpen(action.name)}
            />
          ))}
        </SpeedDial>
      </Box>
      <React.Fragment key={"left"}>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
          PaperProps={{
            sx: { width: isDesktop ? "60%" : "90%" },
          }}
        >
          {/* {action === "WFH"
            ? formHomeWork("left")
            : // : action === "Meeting"
            // ? Meeting("left")
            action === "On Site"
            ? OnSite("left")
            : action === "Support"
            ? supportRequest()
            : clientSteRequest()} */}

          {renderForm()}
        </SwipeableDrawer>
      </React.Fragment>

      {openCheckin && <Scan open={openCheckin} setOpen={setOpenCheckin} />}
    </div>
  );
}
