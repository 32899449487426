import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogFooter,
  Typography,
  Button,
  Textarea,
  Input,
} from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import Image from "./Image";
import { WarningAmber } from "@mui/icons-material";
import { Alert } from "@mui/material";
import jwtDecode from "jwt-decode";
import { UpdatePasswordUser } from "actions/UserAction";
import Translation from "Translation";
// import image from "./GDPR.gif";

const color = "#294e87";

const UpdatePassword = (props) => {
  const { open, handleOpen } = props;
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [loading, setloading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [isMatch, setIsMatch] = useState(true);

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwtDecode(token);

  useEffect(() => {
    setIsMatch(newPassword === repeatPassword);
  }, [newPassword, repeatPassword]);

  const handleSave = async () => {
    if (
      oldPassword.length == 0 ||
      newPassword.length == 0 ||
      repeatPassword.length == 0
    ) {
      NotificationManager.error(<Translation message={"Fill in the fields!"} />);
    } else if (!isMatch) {
      NotificationManager.error(<Translation message={"Passwords do not match!"} />);
    } else {
      var res = await UpdatePasswordUser(user.id, {
        newpassword: newPassword,
        oldpassword: oldPassword,
      });

      if (res.status == 201) {
        NotificationManager.error(res.message);
      } else if (res.status == 200) {
        NotificationManager.success(res.message);
        handleOpen();
      } else {
        NotificationManager.error(res.message);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        handler={handleOpen}
        // style={{ height: "90%" }}
        size={isDesktop ? "lg" : "xl"}
      >
        <div
          className={
            isDesktop
              ? "flex content-center items-center justify-center"
              : "flex content-center items-center justify-center flex-wrap"
          }
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                {isDesktop ? (
                  <>
                    <div className=" text-center">
                      {/* <img src={image} height={100} /> */}
                      <Image />
                      {/* <HomeWork style={{ color: color }} fontSize="large" /> */}
                      {/* <Typography variant="h4" style={{ color: color }}>
                        You want to update Password
                      </Typography> */}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex justify-center ">
                        <div>
                          <Input
                            required={true}
                            color="indigo"
                            className="mr-5"
                            type={showOld ? "text" : "password"}
                            label="Old password"
                            value={oldPassword}
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                            }}
                            icon={
                              oldPassword.length > 0 && (
                                <i
                                  className={
                                    showOld ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowOld(!showOld);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="ml-1">
                          <Input
                            color="indigo"
                            className="mr-1"
                            type={showNew ? "text" : "password"}
                            label="New password"
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            icon={
                              newPassword.length > 0 && (
                                <i
                                  className={
                                    showNew ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowNew(!showNew);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="ml-1">
                          <Input
                            color="indigo"
                            type={showNew ? "text" : "password"}
                            label="Confirm password"
                            value={repeatPassword}
                            onChange={(e) => {
                              setRepeatPassword(e.target.value);
                            }}
                            icon={
                              repeatPassword.length > 0 && (
                                <i
                                  className={
                                    showNew ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowRepeat(!showRepeat);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                      </div>
                    </form>
                    <div className="mt-2">
                      {!isMatch && (
                        <Alert severity="error"> <Translation message={"Passwords do not match!"} /></Alert>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" text-center">
                      {/* <img src={image} height={100} /> */}
                      <Image />
                      {/* <HomeWork style={{ color: color }} fontSize="large" /> */}
                      {/* <Typography variant="h4" style={{ color: color }}>
                        You want to update Password
                      </Typography> */}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div
                        className="flex justify-center"
                        style={{ flexDirection: "column" }}
                      >
                        <div>
                          <Input
                            required={true}
                            color="indigo"
                            className="mr-5"
                            type={showOld ? "text" : "password"}
                            name = "Old password"
                            label= "Old password"
                            value={oldPassword}
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                            }}
                            icon={
                              oldPassword.length > 0 && (
                                <i
                                  className={
                                    showOld ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowOld(!showOld);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <Input
                            color="indigo"
                            className="mr-1"
                            type={showNew ? "text" : "password"}
                            label="New password"
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            icon={
                              newPassword.length > 0 && (
                                <i
                                  className={
                                    showNew ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowNew(!showNew);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <Input
                            color="indigo"
                            type={showNew ? "text" : "password"}
                            label="Confirm password"
                            value={repeatPassword}
                            onChange={(e) => {
                              setRepeatPassword(e.target.value);
                            }}
                            icon={
                              repeatPassword.length > 0 && (
                                <i
                                  className={
                                    showNew ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShowRepeat(!showRepeat);
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                      </div>
                    </form>
                    <div className="mt-2">
                      {!isMatch && (
                        <Alert severity="error"> <Translation message={"Passwords do not match!"} /></Alert>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        <DialogFooter
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "#083985" }}
            ripple="light"
            onClick={() => {
              handleSave();
            }}
          >
            <ClipLoader color="white" loading={loading} size={20} />
            <Translation message={"Save"} />
          </Button>
          <Button
            className="ml-2"
            variant="outlined"
            color="indigo"
            onClick={() => {
              handleOpen();
            }}
          >
            <Translation message={"Cancel"} />
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default UpdatePassword;
