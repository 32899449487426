import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
// import {
//   Scheduler,
//   MonthView,
//   Appointments,
//   DayView,
//   WeekView,
//   ViewSwitcher,
//   Toolbar,
//   DateNavigator,
//   AppointmentTooltip,
//   AppointmentForm,
//   ConfirmationDialog,
//   Resources,
//   AllDayPanel,
//   EditRecurrenceMenu,
// } from "@devexpress/dx-react-scheduler-material-ui";
import Scheduler from "devextreme-react/scheduler";
import { createSearchParams, useNavigate } from "react-router-dom";

import { alpha } from "@material-ui/core/styles";

import Form from "./Form";

// import Sick from '@material-ui/icons/';
import { withStyles } from "@material-ui/core/styles";
import { types } from "./tasks";
import HomeWork from "@mui/icons-material/HomeWork";
// import CardTravel from '@mui/icons-material/Plane';
import FlightIcon from "@mui/icons-material/Flight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HailIcon from "@mui/icons-material/Hail";
import Place from "@mui/icons-material/Place";
import {
  fetchOperations,
  deleteOperation,
} from "../../actions/OperationAction";
import Delete from "@mui/icons-material/Delete";
import ConfirmDialog from "../ConfirmDialog";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";
import jwt from "jwt-decode";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  AccessTime,
  AirplanemodeActive,
  Apartment,
  CalendarMonthOutlined,
  Groups,
  Hail,
  Person,
  PersonPin,
  WorkOff,
} from "@mui/icons-material";

import "./style.css";
import DialogPage from "./DialogPage";
import WFHDialog from "./WFHDialog";
import ClintSiteDialog from "./ClientSiteDialog";
import { fetchHorizon } from "actions/RessourcesAction";

import frMessage from "devextreme/localization/messages/fr.json";
import enMessage from "devextreme/localization/messages/en.json";

import { locale, loadMessages, formatMessage } from "devextreme/localization";
import { useTranslation } from "react-i18next";
import Translation from "Translation";
require("moment/locale/fr");

const resources = [
  {
    fieldName: "ownerId",
    title: "Types",
    instances: types,
  },
];

export default function Schedule() {
  // const [data, setData] = useState(appointments);
  const [data, setData] = useState();
  const [dates, setDates] = useState([]);
  const [state, setState] = React.useState({ left: false });
  const [action, setAction] = useState("");
  const [openCheckin, setOpenCheckin] = useState(false);
  const [name, setName] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [horizon, setHorizon] = useState();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const handleOpenForm = () => setOpenForm(!openForm);

  const { i18n, t } = useTranslation();

  const initMessages = () => {
    loadMessages(frMessage);
    loadMessages(enMessage);
    // loadMessages({
    //   en: {
    //     allDay: "All Day",
    //     week: "Week",
    //     day: "Day",
    //   },
    //   fr: {
    //     allDay: "Toute la journée",
    //     week: "Semaine",
    //     day: "Journée",
    //   },
    // });
  };

  const language = localStorage.getItem("i18nextLng");
  // console.log("languaaaaaaaaaaaage", language);

  useEffect(() => {
    const onLanguageChange = () => {
      locale(i18n.language);
      moment.locale(i18n.language);
    };

    i18n.on("languageChanged", onLanguageChange);

    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    locale(language);

    initMessages();
    const fetchData = async () => {
      const data = await fetchHorizon();
      setHorizon(data?.NBDays);
      const result = await fetchOperations(token, user.id);
      var tab = [];
      result.map((op) => {
        if (op) {
          if (op.OperationType === "WFH" || op.OperationType == "Client site") {
            var date = new Date(op.date);
            var d = date.toISOString().substring(0, 10);
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = d.split("-").pop();
            if (op.timeslot === "AM") {
              var start = new Date(year, month, day, 8, 0);
              var end = new Date(year, month, day, 12, 0);
            } else {
              var start = new Date(year, month, day, 13, 0);
              var end = new Date(year, month, day, 18, 0);
            }
            tab.push({
              id: op._id,
              title:
                op.OperationType == "Client site"
                  ? "Third party site"
                  : op.OperationType,
              startDate: start,
              endDate: end,
              ownerId: op.OperationType == "Client site" ? 3 : 1,
              request: op.request,
              Color: "#1ca858",
              status: op.request && op.request.status,
              client: op.OperationType == "Client site" && op.request.client,
            });
          } else if (op.OperationType === "RESERVATION") {
            var date = new Date(op.reservationdate);
            var d = date.toISOString().substring(0, 10);
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = d.split("-").pop();
            if (op.timeslot === "AM") {
              var start = new Date(year, month, day, 8, 0);
              var end = new Date(year, month, day, 12, 0);
            } else {
              var start = new Date(year, month, day, 13, 0);
              var end = new Date(year, month, day, 18, 0);
            }
            tab.push({
              id: op._id,
              title: "On site",
              startDate: start,
              endDate: end,
              ownerId: 2,
              desk: op.desk.name,
              Color: "#1ca858",
              status: op.status,
              guest: op.guest,
            });
          } else if (
            op.OperationType === "REMOTE_WORKING" ||
            op.OperationType == "TRAVEL"
          ) {
            var startDate = new Date(op.date_debut);
            var endDate = new Date(op.date_fin);
            var s = startDate.toISOString().substring(0, 10);
            var e = endDate.toISOString().substring(0, 10);

            const yearStart = startDate.getFullYear();
            const monthStart = startDate.getMonth();
            const dayStart = s.split("-").pop();

            const yearEnd = endDate.getFullYear();
            const monthEnd = endDate.getMonth();
            const dayEnd = e.split("-").pop();

            var start = new Date(yearStart, monthStart, dayStart, 8, 0);
            var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);

            tab.push({
              id: op._id,
              title:
                op.OperationType == "REMOTE_WORKING"
                  ? "Exceptional WFH"
                  : op.OperationType,
              startDate: start,
              endDate: end,
              ownerId: 1,
              request: op.request,
              Color: "#1ca858",
              status: op.request && op.request.status,
              allDay: true,
            });
          } else if (op.OperationType === "MEETING_RESERVATION") {
            tab.push({
              id: op._id,
              title: "MEETING",
              startDate: op.date_debut.toString().substring(0, 23),
              endDate: op.date_fin.toString().substring(0, 23),
              ownerId: 2,
              desk: op.desk.name,
              Color: "#1ca858",
              status: op.status,
            });
          } else if (op.OperationType == "Leave") {
            var startDate = new Date(op.date_debut);
            var endDate = new Date(op.date_fin);
            var s = startDate.toISOString().substring(0, 10);
            var e = endDate.toISOString().substring(0, 10);

            const yearStart = startDate.getFullYear();
            const monthStart = startDate.getMonth();
            const dayStart = s.split("-").pop();

            const yearEnd = endDate.getFullYear();
            const monthEnd = endDate.getMonth();
            const dayEnd = e.split("-").pop();

            if (op.slot_debut == "AM" && op.slot_fin == "AM") {
              var start = new Date(yearStart, monthStart, dayStart, 8, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 12, 0);
            } else if (op.slot_debut == "PM") {
              var start = new Date(yearStart, monthStart, dayStart, 13, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
            } else {
              var start = new Date(yearStart, monthStart, dayStart, 8, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
            }

            tab.push({
              id: op._id,
              title: op.OperationType,
              startDate: start,
              endDate: end,
              userId: op.user._id,
              Color: "#1ca858",
              status: op.status,
              allDay:
                op.slot_debut == "Full" ||
                (op.slot_debut == "AM" && op.slot_fin == "PM")
                  ? true
                  : false,
            });
          }
        }
      });

      setData(tab);
    };

    fetchData();
  }, []);
  // console.log(data);

  const handleDelete = async (operation) => {
    const result = await deleteOperation(token, operation.id);

    // setVisibility(false);
    // setTimeout(() => {
    //   setVisibility();
    // }, 2000);
    if (result.status === 200) {
      NotificationManager.success(result.message);
      const newData = data.filter((item) => item.id !== operation.id);
      setData(newData);
      setConfirmDialog({ isOpen: false });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } else {
      NotificationManager.error("Something went wrong");
    }
  };
  const toggleDrawer = (anchor, open, name) => (event) => {
    if (name != "On Site" && name != "Check-In/Out") {
      setState({ ...state, ["left"]: open });
    }

    // if (name == "Scan" && !isDesktop) {
    //   setScan(true);
    // }
    if (name == "Check-In/Out") {
      setOpenCheckin(true);
    }

    setAction(name);
  };

  const handleClickFeature = (name) => {
    setState({ ...state, ["left"]: true });
    setAction(name);
  };

  const views = ["day", "week", "workWeek", "month"];
  const currentDate = new Date();

  const onAppointmentDblClick = (e) => {
    e.cancel = true;
  };

  // const onAppointmentClick = (e) => {
  //   // e.cancel = true;
  //   console.log(e);
  //   if (e.component.__tooltipTimeout) {
  //     console.log("hhhhhhhh", e.component.__tooltipTimeout);
  //     // clearTimeout(e.component.__tooltipTimeout);
  //   }
  //   e.popupVisible = false;
  // };

  function AppointmentTooltip(model) {
    const { appointmentData } = model.data;
    const isFutureOrToday =
      new Date(appointmentData.startDate) >=
      new Date(new Date().setHours(0, 0, 0, 0));

    return (
      <div
        className="pl-2 pr-2 pt-2"
        // onClick={(e) => {
        //   e.cancel = true;
        // }}
        // style={{ pointerEvents: "painted" }}
      >
        <div className="delete-section" style={{ pointerEvents: "auto" }}>
          {isFutureOrToday && (
            <Delete
              htmlColor="#767676"
              onClick={() => {
                // setInformations(appointmentData)
                setConfirmDialog({
                  isOpen: true,
                  title: (
                    <Translation
                      message={"Are you sure to delete  this slot?"}
                    />
                  ),
                  subtitle: (
                    <Translation message={"You can't undo this operation!"} />
                  ),
                  onConfirm: () => {
                    handleDelete(appointmentData);
                  },
                });
              }}
            />
          )}
          {/* {
            // moment(appointmentData.startDate).format("L") >
            // moment().format("L")
            new Date(appointmentData.startDate) > new Date() ? (
              <Delete
                htmlColor="#767676"
                onClick={() => {
                  // setInformations(appointmentData)
                  setConfirmDialog({
                    isOpen: true,
                    title: (
                      <Translation
                        message={"Are you sure to delete  this slot?"}
                      />
                    ),
                    subtitle: (
                      <Translation message={"You can't undo this operation!"} />
                    ),
                    onConfirm: () => {
                      handleDelete(appointmentData);
                    },
                  });
                }}
              />
            ) : // moment(appointmentData.startDate).format("L") ==
            //   moment().format("L")
            new Date(appointmentData.startDate) == new Date() ? (
              moment(appointmentData.startDate).format("A") == "AM" &&
              moment().format("A") == "PM" ? (
                ""
              ) : (
                <Delete
                  htmlColor="#767676"
                  onClick={() => {
                    // setInformations(appointmentData)
                    setConfirmDialog({
                      isOpen: true,
                      title: (
                        <Translation
                          message={"Are you sure to delete  this slot?"}
                        />
                      ),
                      subtitle: (
                        <Translation
                          message={"You can't undo this operation!"}
                        />
                      ),
                      onConfirm: () => {
                        handleDelete(appointmentData);
                      },
                    });
                  }}
                />
              )
            ) : (
              ""
            )
          } */}
        </div>
        <div className="mt-5">
          <div className="flex">
            {/* {appointmentData.title === "On site" && (
              <Apartment fontSize="medium" htmlColor="#5262ff" />
            )} */}
            {console.log("appointmentData", appointmentData)}

            {appointmentData.title === "On site" && appointmentData.guest && (
              <PersonPin fontSize="medium" htmlColor="#5262ff" />
            )}

            {appointmentData.title === "On site" && !appointmentData.guest && (
              <Apartment fontSize="medium" htmlColor="#5262ff" />
            )}
            {appointmentData.title === "MEETING" && (
              <Groups fontSize="medium" htmlColor="#1ACC8D" />
            )}
            {(appointmentData.title === "WFH" ||
              appointmentData.title === "Exceptional WFH") && (
              <HomeWork fontSize="medium" htmlColor="#2ec6ff" />
            )}

            {appointmentData.title == "Third party site" && (
              <Hail fontSize="medium" htmlColor="#b741c4" />
            )}
            {appointmentData.title === "Leave" && (
              <WorkOff fontSize="medium" htmlColor="#c6d13e" />
            )}
            {appointmentData.title === "TRAVEL" && (
              <AirplanemodeActive fontSize="medium" htmlColor="#de62b4" />
            )}

            <b className="ml-2">{t(appointmentData.title)}</b>
          </div>
          <div className="flex mt-2">
            <CalendarMonthOutlined
              fontSize="small"
              htmlColor="#767676"
              className="mr-2"
            />
            <p style={{ whiteSpace: "pre-line" }}>
              {moment(appointmentData.startDate).format("dddd")}{" "}
              {moment(appointmentData.startDate).format("LL")} {"-"}{" "}
              {moment(appointmentData.endDate).format("dddd")}{" "}
              {moment(appointmentData.endDate).format("LL")}
            </p>
          </div>
          <div className="flex mt-2">
            <AccessTime fontSize="small" htmlColor="#767676" className="mr-2" />
            {moment(appointmentData.startDate).format("LT")}
            {"-"}
            {moment(appointmentData.endDate).format("LT")}
          </div>
          <div className="flex mt-2">
            {appointmentData.title === "WFH" ||
            appointmentData.title === "Exceptional WFH" ||
            appointmentData.name === "Leave" ||
            appointmentData.name === "Client site" ||
            appointmentData.title === "TRAVEL" ? (
              appointmentData.status === "accepted" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#1bcc64",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                    <Translation message={"Approved"} />
                  </p>
                </>
              ) : appointmentData.status === "refused" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#ed2121",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                    <Translation message={"Rejected"} />
                  </p>
                </>
              ) : appointmentData.status === "pending" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#f0dc2b",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                    <Translation message={"Pending"} />
                  </p>
                </>
              ) : (
                ""
              )
            ) : appointmentData.title === "On site" ||
              appointmentData.title === "MEETING" ? (
              <>
                <Place fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                  {appointmentData.desk}
                </p>
              </>
            ) : appointmentData.title === "Third party site" ? (
              <>
                <Place fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                  {appointmentData.client}
                </p>
              </>
            ) : appointmentData.title === "TRAVEL" ? (
              <>
                <Place fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                  {appointmentData.client}
                </p>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="flex">
            {appointmentData.title === "On site" && appointmentData.guest && (
              <>
                <Person fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "1em", marginBottom: "1em" }}>
                  {appointmentData.guest}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  function onSelectedCellData(modal) {
    modal.cancel = true;

    // var cellData = modal.data("dxCellData");
    // console.log("cellData", cellData);
    var count = data.filter(
      (item) =>
        moment(item.startDate).format("L") ==
          moment(modal.appointmentData.startDate).format("L") ||
        moment(item.endDate).format("L") ==
          moment(modal.appointmentData.endDate).format("L") ||
        // (moment(item.startDate).format("L") ==
        //   moment(modal.appointmentData.endDate).format("L"))
        moment(item.startDate).isBetween(
          moment(modal.appointmentData.startDate),
          moment(modal.appointmentData.endDate)
        )
    ).length;

    // if (count > 0) {
    //   NotificationManager.error("You have already an operation");
    //   return;
    // }

    var yesterday = moment().subtract(1, "days").format().substring(0, 10);
    var startToBook = moment(modal.appointmentData.startDate)
      .format()
      .substring(0, 10);

    if (
      // moment(startToBook).isBefore(yesterday, "year")
      // moment(modal.appointmentData.startDate).format("l") <=
      // moment().subtract(1, "days").format("l")
      moment(modal.appointmentData.startDate).format() <=
      moment().subtract(1, "days").format()
    ) {
      NotificationManager.error(
        <Translation message={"You cannot select date in the past"} />
      );
      return;
    }

    // if (
    //   moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") == "PM" &&
    //   startToBook == moment().format().substring(0, 10)
    // ) {
    //   NotificationManager.error(
    //     <Translation
    //       message={
    //         "Booking all day for today is not allowed, try to book only PM slot"
    //       }
    //     />
    //   );
    //   return;
    // }

    //  else {
    var start = moment(modal.appointmentData.startDate).startOf("day");
    var end = moment(modal.appointmentData.endDate).startOf("day");
    setStartDate(start.format().toString().substring(0, 10));
    setEndDate(end.format().toString().substring(0, 10));
    var arr = [];

    if (
      moment(modal.appointmentData.startDate).format("yyyy-MM-DD") !=
      moment(modal.appointmentData.endDate).format("yyyy-MM-DD")
    ) {
      arr.push(start.format().toString().substring(0, 10));
      while (start.add(1, "days").diff(end) < 0) {
        var d = start.clone().format();
        arr.push(d.toString().substring(0, 10));
      }
    }
    arr.push(end.format().toString().substring(0, 10));
    const uniqueDates = [...new Set(arr)];

    setDates(uniqueDates);
    handleOpen();
    // }
  }

  // const onCellClick = (e) => {
  //   console.log(e);
  // };

  // const locale = "fr-FR";

  // const allDayLocalizationMessages = {
  //   "fr-FR": {
  //     allDay: "Temps plein",
  //   },
  //   "de-GR": {
  //     allDay: "Ganztägig",
  //   },
  //   "en-US": {
  //     allDay: "All Day",
  //   },
  // };

  // const getAllDayMessages = (locale) => allDayLocalizationMessages[locale];

  return (
    <>
      <Paper style={{ paddingTop: "130px", paddingBottom: "100px" }}>
        <Form
          dates={dates}
          toggleDrawer={toggleDrawer}
          state={state}
          action={action}
          openCheckin={openCheckin}
          setOpenCheckin={setOpenCheckin}
        />
        <Scheduler
          className="mt-5"
          // locale={locale}
          dataSource={data}
          views={views}
          defaultCurrentView="workWeek"
          defaultCurrentDate={currentDate}
          height={500}
          startDayHour={8}
          endDayHour={18}
          cellDuration={180}
          editing={{
            allowAdding: true,
            allowDeleting: true,
            allowResizing: false,
            allowDragging: false,
            allowUpdating: false,
          }}
          appointmentRender={AppointmentTemplate}
          onAppointmentDblClick={onAppointmentDblClick}
          appointmentTooltipComponent={AppointmentTooltip}
          onAppointmentFormOpening={onSelectedCellData}
          maxAppointmentsPerCell="unlimited"
          // onCellClick={onCellClick}
          // onAppointmentClick={onAppointmentClick}

          // selectedCellData={onSelectedCellData}
          // onCellClick={onSelectedCellData}
          // appointmentTooltipTemplate={AppointmentTooltip}

          // onAppointmentFormOpening={this.onAppointmentFormOpening}
        />

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          token={token}
          // informations={informations}
        />

        <NotificationContainer />
      </Paper>
      {open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
          }}
          onClick={handleOpen}
        >
          <DialogPage
            open={open}
            handleOpen={handleOpen}
            handleClickFeature={handleClickFeature}
            setName={setName}
            setOpenForm={setOpenForm}
            horizon={horizon}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}

      {name == "WFH" && (
        <WFHDialog open={openForm} handleOpen={handleOpenForm} dates={dates} />
      )}
      {name == "Third party site" && (
        <ClintSiteDialog
          open={openForm}
          handleOpen={handleOpenForm}
          dates={dates}
        />
      )}

      {name == "On Site" &&
        navigate({
          pathname: `/search_desk`,
          search: createSearchParams({
            startDate: startDate,
            endDate: endDate,
          }).toString(),
        })}
      {name == "TRAVEL" &&
        navigate({
          pathname: `/travel`,
        })}
    </>
  );
}

function AppointmentTemplate(model) {
  // const movieInfo = getMovieById(model.appointmentData.movieId) || {};
  return (
    <div className="">
      {/* {model.appointmentData.title === "On site" && (
        <Apartment fontSize="medium" htmlColor="#5262ff" />
      )} */}

      {model.appointmentData.title?.includes("On site") &&
        model.appointmentData.guest && (
          <PersonPin fontSize="medium" htmlColor="#5262ff" />
        )}

      {model.appointmentData.title === "On site" &&
        !model.appointmentData.guest && (
          <Apartment fontSize="medium" htmlColor="#5262ff" />
        )}
      {model.appointmentData.title === "MEETING" && (
        <Groups fontSize="medium" htmlColor="#1ACC8D" />
      )}
      {(model.appointmentData.title === "WFH" ||
        model.appointmentData.title === "Exceptional WFH") && (
        <HomeWork fontSize="medium" htmlColor="#2ec6ff" />
      )}

      {model.appointmentData.title === "Leave" && (
        <WorkOff fontSize="medium" htmlColor="#c6d13e" />
      )}

      {model.appointmentData.title == "Third party site" && (
        <Hail fontSize="medium" htmlColor="#b741c4" />
      )}
      {model.appointmentData.title === "TRAVEL" && (
        <AirplanemodeActive fontSize="medium" htmlColor="#de62b4" />
      )}

      <div style={{ position: "absolute", top: "5px", right: "8px" }}>
        {model.appointmentData.status === "accepted" && (
          // || model.appointmentData.status === "ACTIVE"
          <span
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: "#12e669",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
        )}
        {model.appointmentData.status === "refused" && (
          <span
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: "#cc002c",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
        )}
        {model.appointmentData.status === "pending" && (
          <span
            style={{
              height: "10px",
              width: "10px",
              backgroundColor: "#f7e223",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
        )}
      </div>
    </div>
  );
}
