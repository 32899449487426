import {
  Input,
  Textarea,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import {
  Add,
  Download,
  Home,
  Hotel,
  Minimize,
  Visibility,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Backdrop,
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { fetchFileWithId } from "actions/travel.service";
import {
  fetchCurrencies,
  fetchCityCap,
  fetchFile,
} from "actions/travel.service";
import { differenceInDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import FileViewer from "./FileViewer";
import Translation from "Translation";
import Select from "react-select";
import { FaEye, FaHome, FaHotel } from "react-icons/fa";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";

const color = "#083985";

const AccomodationPanel = ({
  request,
  edit,
  handleFormChange,
  formData,
  missions,
  missionData,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [stay, setStay] = useState(null);
  const [estimatedCosts, setEstimatedCosts] = useState();

  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCurrencies();
      if (response.status == 200) {
        setCurrencies(response.data);
      }
      if (request?.mission?.accomodation?.stay) {
        setStay(request.mission.accomodation.stay);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const updateAccomodation = async () => {
  //     let updatedStay = [];
  //     if (formData?.Formula) {
  //       if (formData?.Formula.type.toLowerCase() === "hybride") {
  //         updatedStay = [
  //           {
  //             type: "hotel",
  //             startDate: formData?.startDate || request?.mission?.startDate,
  //             endDate: formData?.startDate || request?.mission?.startDate,
  //           },
  //           {
  //             type: "personal accomodation",
  //             startDate: formData?.endDate || request?.mission?.endDate,
  //             endDate: formData?.endDate || request?.mission?.endDate,
  //           },
  //         ];
  //       } else {
  //         updatedStay = [
  //           {
  //             type: formData?.Formula.type?.toLowerCase(),
  //             startDate: formData?.startDate || request?.mission?.startDate,
  //             endDate: formData?.endDate || request?.mission?.endDate,
  //           },
  //         ];
  //       }
  //     }

  //     handleFormChange({
  //       stay: updatedStay,
  //     });

  //     setStay(updatedStay);
  //   };

  //   formData?.Formula && updateAccomodation();
  // }, [formData?.Formula]);

  useEffect(() => {
    const updateAccomodation = async () => {
      // let updatedStay = [];
      if (request?.mission?.missionType == "simple") {
        if (formData?.Formula) {
          const res = await fetchCityCap(
            missionData?.missionCountry?._id,
            missionData?.missionCity?._id
          );
          if (formData?.Formula.type.toLowerCase() === "hybride") {
            var updatedStay = [
              {
                type: "hotel",
                startDate: formData?.startDate || request?.mission?.startDate,
                endDate: formData?.startDate || request?.mission?.startDate,
                cityCap: res?._id ? res : null,
              },
              {
                type: "personal accomodation",
                startDate: formData?.endDate || request?.mission?.endDate,
                endDate: formData?.endDate || request?.mission?.endDate,
                cityCap: res?._id ? res : null,
              },
            ];
          } else {
            var updatedStay = [
              {
                type: formData?.Formula.type?.toLowerCase(),
                startDate: formData?.startDate || request?.mission?.startDate,
                endDate: formData?.endDate || request?.mission?.endDate,
                cityCap: res?._id ? res : null,
              },
            ];
          }

          setStay(updatedStay);
          handleFormChange({ stay: updatedStay });
        }
      } else {
        if (formData?.missions?.length > 0) {
          var updatedStay = [];
          await Promise.all(
            formData?.missions?.map(async (item) => {
              const formule = item.Formula;
              const missionSelected = formData?.missions?.find(
                (m) =>
                  m.engagementCode == item.engagementCode || m.title == m.title
              );

              const cityCap = await fetchCityCap(
                item.country?._id,
                item.city?._id
              );

              if (formule) {
                if (formule?.type.toLowerCase() === "hybride") {
                  updatedStay.push({
                    type: "hotel",
                    startDate: item.startDate,
                    endDate: item.startDate,
                    engagementCode: item.engagementCode,
                    title: item.title,
                    country: item.country,
                    undeletable: true,
                    cityCap: cityCap,
                    mission: missionSelected?._id,
                    // },
                  });
                  updatedStay.push({
                    type: "personal accomodation",
                    startDate: item.endDate,
                    endDate: item.endDate,
                    engagementCode: item.engagementCode,
                    title: item.title,
                    country: item.country,
                    undeletable: true,
                    cityCap: cityCap,
                    mission: missionSelected?._id,
                  });
                } else {
                  updatedStay.push({
                    // return {
                    type: formule.type?.toLowerCase(),
                    startDate: item.startDate,
                    endDate: item.endDate,
                    engagementCode: item.engagementCode,
                    title: item.title,
                    country: item.country,
                    undeletable: true,
                    cityCap: cityCap,
                    mission: missionSelected?._id,
                    // };
                  });
                  // console.log("uppp ", updatedStay);
                }
              }
            })
          );
          setStay(updatedStay);
          handleFormChange({ stay: updatedStay });
        }
      }
    };

    updateAccomodation();
  }, [formData?.missions, formData?.Formula]);

  const handleAddRow = () => {
    // setStay([
    //   ...stay,
    //   {
    //     departureCountry: null,
    //     departureCity: null,
    //     arrivalCountry: null,
    //     arrivalCity: null,
    //     departureDate: null,
    //     transportType: null,
    //   },
    // ]);
    let newStay = {
      type: null,
      startDate: null,
      endDate: null,
    };

    if (stay.length > 0) {
      const lastLineEndDate = new Date(stay[stay.length - 1].endDate);
      const newStartDate = new Date(lastLineEndDate.getTime());
      newStartDate.setDate(lastLineEndDate.getDate() + 1);
      newStay.startDate = newStartDate;
      newStay.endDate = newStartDate;
    }

    setStay([...stay, newStay]);
    handleFormChange({
      stay: newStay,
    });
  };
  const handleRemoveRow = (index) => {
    if (
      (formData?.Formula?.type.toLowerCase() === "hybride" &&
        stay.length == 2) ||
      (request?.mission?.Formula?.type.toLowerCase() === "hybride" &&
        stay.length == 2)
    ) {
      NotificationManager.error(
        "The chosen formula does not allow the deletion of rows. You can change the content of the fields in the rows."
      );
      return;
    }

    if (stay.length == 1) {
      NotificationManager.error(
        "The chosen formula does not allow the deletion of rows. You can change the content of the fields in the rows."
      );
      return;
    }
    const values = [...stay];
    values.splice(index, 1);
    setStay(values);
    handleFormChange({
      stay: values,
    });
  };

  const handleChangeType = (index, event) => {
    let newStay = [...stay];
    newStay[index].type = event.target.value;
    setStay(newStay);
    handleFormChange({
      stay: newStay,
    });
  };

  const handleChangeStartDate = (date, index) => {
    const values = [...stay];
    if (
      values[index].endDate &&
      new Date(date) > new Date(values[index].endDate)
    ) {
      NotificationManager.error("Start date cannot be after end date");
      return;
    }
    values[index].startDate = format(new Date(date), "yyyy-MM-dd");

    setStay(values);
    handleFormChange({
      stay: values,
    });
  };

  const handleChangeEndDate = (date, index) => {
    const values = [...stay];
    if (
      values[index].startDate &&
      new Date(date) < new Date(values[index].startDate)
    ) {
      NotificationManager.error("End date cannot be before start date");
      return;
    }
    values[index].endDate = format(new Date(date), "yyyy-MM-dd");

    if (index < values.length - 1) {
      const newStartDate = new Date(date);
      newStartDate.setDate(newStartDate.getDate() + 1);
      values[index + 1].startDate = format(
        new Date(newStartDate),
        "yyyy-MM-dd"
      );
    }

    setStay(values);
    handleFormChange({
      stay: values,
    });
  };

  const handleChangeMission = (index, event) => {
    const values = [...stay];

    values[index].title = event.target.value;
    const engagementCode = (
      formData?.missions ? formData?.missions : request?.mission?.missions
    ).find((item) => item.title == event.target.value)?.engagementCode;
    values[index].engagementCode = engagementCode || "";
    setStay(values);

    handleFormChange({
      stay: values,
    });
  };

  function calculateDaysBetween(date1, date2) {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      throw new Error("Both arguments must be Date objects");
    }

    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());

    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays;
  }

  console.log("stay", stay);

  useEffect(() => {
    const arraysPerDevise = {};

    stay?.forEach((objet) => {
      console.log("🚀 ~ stay?.forEach ~ objet:", objet);
      if (objet.startDate && objet.endDate && objet.type == "hotel") {
        // const nb = calculateDaysBetween(
        //   new Date(objet.startDate),
        //   new Date(objet.endDate)
        // );
        // console.log(
        //   "objet.startDate",
        //   objet.startDate,
        //   objet.endDate?.toISOString()?.substring(0, 10)
        // );
        // console.log(
        //   "objet.endDate",
        //   objet.endDate,
        //   objet.endDate?.toISOString()?.substring(0, 10)
        // );
        // const nb = differenceInDays(
        //   format(new Date(objet.endDate), "yyyy-MM-dd"),
        //   format(new Date(objet.startDate), "yyyy-MM-dd")
        // );
        console.log("enter in iffffffffffffffffffffffff");

        const nb = differenceInDays(
          new Date(
            objet.endDate instanceof Date
              ? objet.endDate?.toISOString()?.substring(0, 10)
              : objet.endDate?.toString()?.substring(0, 10)
          ),
          new Date(
            objet.startDate instanceof Date
              ? objet.startDate?.toISOString()?.substring(0, 10)
              : objet.startDate?.toString()?.substring(0, 10)
          )
        );
        console.log("nb", nb);

        // if (missionData?.accomodation?.cityCap) {
        //   var total =
        //     missionData?.accomodation?.cityCap?.Mximum_rate_per_night * nb;
        //   var curr = missionData?.accomodation?.cityCap?.currency?._id;
        // } else {
        var total = objet.cityCap?.Mximum_rate_per_night * nb;
        var curr = objet?.cityCap?.currency?._id;
        // }

        if (objet.extraExpenses) {
          total += objet.extraExpenses;
        }

        if (!arraysPerDevise[curr]) {
          arraysPerDevise[curr] = [total];
        } else {
          arraysPerDevise[curr].push(total);
        }
      }
    });

    missionData?.accomodation?.otherCosts.forEach((objet) => {
      if (objet.cost && objet.currency) {
        const { cost, currency } = objet;
        const curr = currency?._id ? currency?._id : currency;
        if (!arraysPerDevise[curr]) {
          arraysPerDevise[curr] = [cost];
        } else {
          arraysPerDevise[curr].push(cost);
        }
      }
    });

    const sums = {};

    for (const key in arraysPerDevise) {
      let sum = 0;
      for (let i = 0; i < arraysPerDevise[key].length; i++) {
        if (arraysPerDevise[key][i]) {
          sum += parseFloat(arraysPerDevise[key][i]);
        }
      }
      sums[key] = sum;
    }

    if (missionData?.expenses?.currency) {
      if (sums[missionData?.expenses?.currency]) {
        sums[missionData?.expenses?.currency] +=
          parseFloat(missionData?.expenses?.extraExpenses) || 0;
      } else {
        sums[missionData?.expenses?.currency] =
          parseFloat(missionData?.expenses?.extraExpenses) || 0;
      }
    }

    setEstimatedCosts(sums);
  }, [missionData, stay]);

  const shouldShowButton = (
    formulaTypeFromData,
    formulaTypeFromRequest,
    index
  ) => {
    if (formulaTypeFromData) {
      if (
        (formulaTypeFromData.toLowerCase() === "hybride" && index > 1) ||
        (formulaTypeFromData.toLowerCase() !== "hybride" && index > 0)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        (formulaTypeFromRequest?.toLowerCase() === "hybride" && index > 1) ||
        (formulaTypeFromRequest?.toLowerCase() !== "hybride" && index > 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleFileDisplay = (fileBuffer, type) => {
    // const base64String = Buffer.from(fileBuffer.data).toString("base64");
    // console.log("base64String", base64String);
    // setFileData(base64String);

    setFileData(new Uint8Array(fileBuffer.data));
    // const extension = type.split("/")[1];
    console.log("🚀 ~ handleFileDisplay ~ extension:", type);
    setOpen(false);

    setFileType(type);
    setDisplay(true);
  };

  console.log("display", display, fileData);

  const handleFetchFile = async (id) => {
    setOpen(true);
    const response = await fetchFileWithId(request?.mission?._id, id);
    // const contentType = response.headers['content-type'];
    // const data = await response.data;
    // item.fileType = contentType.split('/').pop();
    // item.file = new Uint8Array(data);

    const contentType =
      response.headers["Content-Type"] || response.headers["content-type"];
    // item.fileType = contentType?.split('/').pop();
    const fileType =
      contentType == "application/octet-stream" ? "eml" : contentType;
    // const res = await response.data;
    const data = await response.data;
    const fileData = new Uint8Array(data);

    setFileData(new Uint8Array(data));
    setFileType(fileType);
    setDisplay(true);
    setOpen(false);

    // fileType == "eml" || fileType == "msg"
    //   ? handleFileDownload({ fileData: fileData, fileType: fileType }, "file")
    //   : handleFileDisplay(fileData, fileType);

    // setFileData();
    // setFileType(fileType);
  };

  const accomodationTypes = [
    {
      value: "personal accomodation",
      label: t("personal accommodation"),
    },
    { value: "hotel", label: t("hotel") },
  ];

  return (
    <div>
      {edit ? (
        <>
          <Typography className=" text-sm  text-gray-800 pt-1">
            <Translation message="Compose your stay" />
          </Typography>

          <div className="w-full mt-10">
            {stay?.map((item, index) => (
              <div className="flex flex-row flex-wrap gap-4 mt-10" key={index}>
                <div className="mt-10">
                  {item.type == "hotel" ? (
                    <FaHotel color="#294e87" />
                  ) : (
                    <FaHome color="#294e87" />
                  )}
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Accommodation type" />
                  </Typography>
                  <Select
                    options={accomodationTypes}
                    value={
                      item.type
                        ? accomodationTypes.find(
                            (val) => val.value == item.type
                          )
                        : ""
                    }
                    onChange={(event) => {
                      handleChangeType(index, event);
                    }}
                    className="select-width-200"
                    style={{ zIndex: 9999 }}
                  />
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="Start date" />
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n?.language}
                  >
                    <DatePicker
                      value={item.startDate ? new Date(item.startDate) : null}
                      onChange={(e) => {
                        const date = new Date(e);
                        if (!isNaN(date.getTime())) {
                          handleChangeStartDate(e, index);
                        } else {
                          return;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: 200,
                            "& .MuiInputBase-input": {
                              borderColor: "rgb(176 190 197)",
                              // borderWidth: 1,
                              borderStyle: "solid",
                              borderRadius: "16px",
                              height: 9,
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "rgb(96 125 139)",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(176 190 197)",
                            },
                          }}
                        />
                      )}
                      inputFormat="DD/MM/YYYY"
                      minDate={
                        formData.startDate
                          ? new Date(formData.startDate)
                          : new Date()
                      }
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-700"
                  >
                    <Translation message="End date" />
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n?.language}
                  >
                    <DatePicker
                      value={item.startDate ? new Date(item.endDate) : null}
                      onChange={(e) => {
                        const date = new Date(e);
                        if (!isNaN(date.getTime())) {
                          handleChangeEndDate(
                            format(new Date(e), "yyyy-MM-dd"),
                            index
                          );
                        } else {
                          return;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: 200,
                            "& .MuiInputBase-input": {
                              borderColor: "rgb(176 190 197)",
                              // borderWidth: 1,
                              borderStyle: "solid",
                              borderRadius: "16px",
                              height: 9,
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "rgb(96 125 139)",
                              },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgb(176 190 197)",
                            },
                          }}
                        />
                      )}
                      inputFormat="DD/MM/YYYY"
                      minDate={
                        formData.startDate
                          ? new Date(formData.startDate)
                          : new Date()
                      }
                    />
                  </LocalizationProvider>
                </div>
                {missions?.length > 0 && (
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Mission"} />
                    </Typography>

                    <Select
                      options={missions?.map((mission) => ({
                        ...mission,
                        value: mission.title,
                        label: mission.title,
                      }))}
                      value={missions
                        ?.map((mission) => ({
                          ...mission,
                          value: mission.title,
                          label: mission.title,
                        }))
                        ?.find((m) => m.title == item?.title)}
                      onChange={(event) => {
                        handleChangeMission(index, event);
                      }}
                      className="select-width-200"
                      style={{ zIndex: 9999 }}
                    />

                    {/* <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.title}
                    /> */}
                  </div>
                )}

                {item.file && (
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"File"} />
                    </Typography>
                    <IconButton
                      size="md"
                      edge="end"
                      aria-label="delete"
                      variant="text"
                    >
                      <FaEye
                        size={15}
                        style={{ color: color, cursor: "pointer" }}
                        onClick={() => handleFetchFile(item.file)}
                      />
                    </IconButton>
                  </div>
                )}

                {!item.undeletable && (
                  <Button
                    className="mt-5"
                    onClick={() => {
                      handleRemoveRow(index);
                    }}
                    size="sm"
                    variant="text"
                    color="red"
                  >
                    <IoMdClose size={15} />
                  </Button>
                )}
              </div>
            ))}
          </div>
          <IconButton
            onClick={handleAddRow}
            className="rounded-full mt-5"
            color="indigo"
            variant="text"
          >
            <Add />
          </IconButton>
        </>
      ) : (
        <>
          {request?.mission?.accomodation?.stay?.length > 0 && (
            <List
            // sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {request?.mission?.accomodation?.stay?.map((item, index) => (
                <ListItem key={item?._id} index={index + 1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="mt-5">
                      {item.type == "hotel" ? (
                        <FaHotel color="#294e87" />
                      ) : (
                        <FaHome color="#294e87" />
                      )}
                    </div>
                    <div className="m-2">
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-800"
                      >
                        <Translation message="Accommodation type" />
                      </Typography>{" "}
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-600"
                      >
                        {" "}
                        {item.type}{" "}
                      </Typography>
                    </div>

                    <div className="m-2">
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-800"
                      >
                        <Translation message="Start date" />
                      </Typography>{" "}
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-600"
                      >
                        {" "}
                        {item.startDate &&
                          format(
                            new Date(item?.startDate),
                            "dd-MM-yyyy HH:mm"
                          )}{" "}
                      </Typography>
                    </div>

                    <div className="m-2">
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-800"
                      >
                        <Translation message="End date" />
                      </Typography>{" "}
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-600"
                      >
                        {" "}
                        {item.endDate &&
                          format(
                            new Date(item?.endDate),
                            "dd-MM-yyyy HH:mm"
                          )}{" "}
                      </Typography>
                    </div>

                    {item?.hotel?.name && (
                      <div className="m-2">
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-800"
                        >
                          <Translation message="Hotel Name" />
                        </Typography>{" "}
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-600"
                        >
                          {item?.hotel?.name}
                        </Typography>
                      </div>
                    )}
                    {item?.hotel?.address && (
                      <div className="m-2">
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-800"
                        >
                          <Translation message="Hotel address" />
                        </Typography>{" "}
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-600"
                        >
                          {item?.hotel?.address}
                        </Typography>
                      </div>
                    )}
                    {item?.pricePerNight && (
                      <div className="m-2">
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-800"
                        >
                          <Translation message="Price Per night" />
                        </Typography>{" "}
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-600"
                        >
                          {item?.pricePerNight} {item?.currency?.name}
                        </Typography>
                      </div>
                    )}
                    {item?.cost && (
                      <div className="m-2">
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-800"
                        >
                          <Translation message="Cost" />
                        </Typography>{" "}
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-600"
                        >
                          <b>
                            {item?.cost} {item?.currency?.name}
                          </b>
                        </Typography>
                      </div>
                    )}

                    <div className="m-2">
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-800"
                      >
                        Mission{" "}
                      </Typography>{" "}
                      <Typography
                        variant="small"
                        className="mb-2 font-medium text-gray-600"
                      >
                        {formData?.missions?.length > 0
                          ? formData?.missions?.find(
                              (m) => m._id == item.mission
                            )?.title
                          : request?.mission?.missions?.find(
                              (m) => m._id == item.mission
                            )?.title}{" "}
                      </Typography>
                    </div>

                    {item.file && (
                      <div className=" m-2">
                        <Typography
                          variant="small"
                          className="mb-2 font-medium text-gray-800"
                        >
                          <Translation message={"File"} />{" "}
                        </Typography>{" "}
                        <IconButton
                          size="sm"
                          edge="end"
                          aria-label="delete"
                          variant="text"
                        >
                          <FaEye
                            size={15}
                            style={{ color: color, cursor: "pointer" }}
                            onClick={() => handleFetchFile(item.file)}
                          />
                        </IconButton>
                      </div>
                    )}

                    {item.fileData && (
                      <div className="m-2">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          className="mr-2"
                        >
                          <Visibility
                            style={{ color: "#083985" }}
                            fontSize="small"
                            onClick={() =>
                              handleFileDisplay(item.fileData, item.contentType)
                            }
                          />
                        </IconButton>

                        <IconButton
                          edge="end"
                          aria-label="delete"
                          className="ml-2"
                        >
                          <Download
                            style={{ color: "#083985" }}
                            fontSize="small"
                            // onClick={() => handleFileDownload(item, "file")}
                          />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {request?.mission?.accomodation?.otherCosts?.length > 0 && (
        <div className=" mt-5">
          <Typography
            variant="small"
            className="mb-2 font-semi-bold text-gray-800"
          >
            <Translation message="Other costs" />
          </Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {request?.mission?.accomodation?.otherCosts?.map((item, index) => (
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="m-2">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-800"
                    >
                      <Translation message="Comment" />
                    </Typography>{" "}
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-600"
                    >
                      {" "}
                      {item?.comment}{" "}
                    </Typography>
                  </div>
                  <div className="m-2">
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-800"
                    >
                      <Translation message="Cost" />
                    </Typography>{" "}
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-600"
                    >
                      {" "}
                      {item?.cost}{" "}
                      {
                        currencies?.find((curr) => curr._id == item.currency)
                          ?.name
                      }{" "}
                    </Typography>
                  </div>
                  {item.file && (
                    <div className=" m-2">
                      <Typography
                        variant="small"
                        className=" font-medium text-gray-800"
                      >
                        <Translation message={"File"} />{" "}
                      </Typography>{" "}
                      <IconButton
                        size="sm"
                        edge="end"
                        aria-label="delete"
                        variant="text"
                      >
                        <FaEye
                          size={15}
                          style={{ color: color, cursor: "pointer" }}
                          onClick={() => handleFetchFile(item.file)}
                        />
                      </IconButton>
                    </div>
                  )}
                  {/* <ListItemText
                  primary={item?.comment}
                  secondary={
                    <>
                      {item?.cost}{" "}
                      {
                        currencies?.find((curr) => curr._id == item.currency)
                          ?.name
                      }{" "}
                    </>
                  }
                /> */}
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {edit ? (
        <div className="m-2">
          <Typography
            variant="small"
            className="mb-2 font-medium text-gray-800"
          >
            <Translation message={"Do you have any preferences?"} />{" "}
          </Typography>
          <Textarea
            // placeholder="Comment"
            value={
              formData?.accomodationComment
                ? formData?.accomodationComment
                : request?.mission?.accomodation?.accomodationComment
            }
            onChange={(e) => {
              handleFormChange({ accomodationComment: e.target.value });
            }}
            className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
          />
        </div>
      ) : (
        request?.mission?.accomodation?.accomodationComment?.length > 0 && (
          <div className="m-2">
            <Typography
              variant="small"
              className="mb-2 font-medium text-gray-800"
            >
              Preferences{" "}
            </Typography>{" "}
            <Typography
              variant="small"
              className="mb-2 font-medium text-gray-600"
            >
              {request?.mission?.accomodation?.accomodationComment}
            </Typography>
          </div>
        )
      )}

      <div className="flex flex-wrap">
        {request?.mission?.accomodation?.totalCosts &&
        Object.keys(request?.mission?.accomodation?.totalCosts)?.length > 0 ? (
          Object.keys(request?.mission?.accomodation?.totalCosts)?.map(
            (key) => (
              <div className="m-2">
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-600"
                >
                  Total {currencies?.find((item) => item._id == key)?.name}{" "}
                </Typography>{" "}
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-600"
                >
                  {" "}
                  {request?.mission?.accomodation?.totalCosts[key]}{" "}
                  {currencies?.find((item) => item._id == key)?.name}
                </Typography>
              </div>
            )
          )
        ) : (
          <div className="m-2">
            <Typography
              variant="small"
              className="mb-2 font-medium text-gray-800"
            >
              <Translation message={"Estimated Total costs"} />{" "}
            </Typography>
            {estimatedCosts &&
              Object.keys(estimatedCosts)?.map((key) => (
                <div className="m-2">
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-600"
                  >
                    Total {currencies?.find((item) => item._id == key)?.name}{" "}
                  </Typography>{" "}
                  <Typography
                    variant="small"
                    className="mb-2 font-medium text-gray-600"
                  >
                    {estimatedCosts[key]}{" "}
                    {currencies?.find((item) => item._id == key)?.name}
                  </Typography>
                </div>
              ))}
          </div>
        )}
      </div>
      {/* {request?.mission?.accomodation?.cost &&
        request?.mission?.accomodation?.cost > 0 && (
          <div className="m-2">
             <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-800"
                >Cost </Typography>{" "}
             <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-600"
                >
              <b>
                {request?.mission?.accomodation?.cost}{" "}
                {request?.mission?.accomodation?.currency?.name}
              </b>
            </Typography>
          </div>
        )} */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className="flex flex-col place-items-center">
          <CircularProgress color="inherit" />
          <Translation message="Loading file" />
        </div>
      </Backdrop>

      {fileData && fileType && (
        <FileViewer
          fileData={fileData}
          view={display}
          // view={true}
          setView={setDisplay}
          // handleDownload={handleFileDownload}
          fileType={fileType}
          download={true}
        />
      )}
    </div>
  );
};

export default AccomodationPanel;
