import { Carousel, Spinner } from "@material-tailwind/react";
import { fetchFloors } from "actions/RessourcesAction";
import React, { useState } from "react";
import { useEffect } from "react";
//import Floor8 from "./Floor8/Floor8";
import Floor from "./Floor";

const Floors = ({ selectedDate }) => {
  const [floors, setFloors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchFloors();
      setFloors(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <center>
          <Spinner className="h-8 w-8" />
        </center>
      ) : (
        <Carousel
          className="rounded-xl"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i ? "bg-white w-8" : "bg-white/50 w-4"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {/* {floors?.map((floor) => {
          return floor.type != "fake" && floor.floor_num == "08" ? (
            <Floor8 floorId={floor._id} />
          ) : floor.type != "fake" && floor.floor_num == "07" ? (
            <Floor8 floorId={floor._id} />
          ) : (
            ""
          );
        })} */}

          {floors?.map((floor) => {
            return (
              floor.type != "fake" && (
                <Floor floor={floor} selectedDate={selectedDate} />
              )
            );
          })}

          {/* {floors?.map((floor) => {
          let content;
          switch (floor.floor_num) {
            case "07":
              content = <Floor8 floorId={floor._id} />;
              break;
            case "08":
              content = <Floor8 />;
              break;

            default:
              break;
          }
          return (
            floor.type != "fake" && (
              <div key={floor._id}>
                {content}
              </div>
            )
          );
        })} */}
        </Carousel>
      )}
    </div>
  );
};

export default Floors;
