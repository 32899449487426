import React, { useEffect, useState } from "react";

import Sick from "@mui/icons-material/Sick";
import HomeWork from "@mui/icons-material/HomeWork";

import H3 from "@material-tailwind/react";
import { Typography, Textarea, Button } from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./form.css";
import { fetchRequestByID, updateRequest } from "actions/RequestAction";
import { useParams } from "react-router";
// import { Typography } from "@mui/material";
import { fetchOperationsByRequest } from "actions/OperationAction";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import PopupDeledate from "./PopupDelegate";
import { Hail } from "@mui/icons-material";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
import moment from "moment";

const color = "#083985";

export default function Form() {
  const { i18n } = useTranslation();

  const [loadingAccpet, setloadingAccept] = useState(false);
  const [loadingRefuse, setloadingRefuse] = useState(false);

  const [type, setType] = React.useState("WFH");
  const [data, setData] = useState();
  const [operations, setOperations] = useState();
  const [comment, setComment] = useState("");
  const [openPopup, setOpenPopup] = useState({ open: false });
  const [validator, setValidator] = useState();

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const { t } = useTranslation();

  const { id } = useParams();
  const isDesktop = useMediaQuery("(min-width: 960px)");

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchRequestByID(token, id);
      setData(result);
      result && setType(result.name);
      const op = await fetchOperationsByRequest(token, id);
      setOperations(op);
    };
    fetch();
  }, []);

  const handleSend = async (status) => {
    var data;
    if (comment !== "") {
      data = {
        status: status,
        commentManager: comment,
      };
    } else {
      data = {
        status: status,
      };
    }

    if (status === "accepted") {
      setloadingAccept(true);
    } else {
      setloadingRefuse(true);
    }

    const result = await updateRequest(token, id, data);
    if (result.status === 200) {
      NotificationManager.success(t(result.message), "Success");
    } else {
      NotificationManager.error(t(result.message), "Error");
    }

    setloadingAccept(false);
    setloadingRefuse(false);
    setTimeout(function () {
      //   window.location.reload(false);
      //   window.history.back();
      //   return false;
      //   window.location.reload();

      window.location.replace("/teamRequests");
    }, 1000);
  };

  const formLeave = () => (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <div className="w-full text-center">
                <div className="header-form">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    {data?.type} <Translation message={"Leave"} />
                  </Typography>
                </div>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="flex justify-center mb-5 mt-20">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Member"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data && data?.idSender?.firstname}{" "}
                      {data && data?.idSender?.lastname}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5 ">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Type of Leave" />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data?.type}
                    </Typography>
                  </div>
                </div>

                {data.dead && (
                  <div className="flex justify-center mb-5 mt-20">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Relationship" />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data && data?.dead}
                      </Typography>
                    </div>
                  </div>
                )}

                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Submission date"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data &&
                        data.createdAt
                          ?.substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          data.createdAt?.substring(11, 16)}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Days requested"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {operations &&
                      operations.map((operation) => {
                        return (
                          <>
                            <Typography className=" text-sm  text-gray-700 pt-1">
                              {" "}
                              {operation.date_debut
                                .substring(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") +
                                " " +
                                operation.slot_debut}{" "}
                              <i className="fas fa-solid fa-arrow-right" />{" "}
                              {operation.date_fin
                                .substring(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") +
                                " " +
                                operation.slot_fin}
                            </Typography>{" "}
                          </>
                        );
                      })}
                  </div>
                </div>
                {data?.sickBalance && (
                  <div className="flex justify-center mb-5">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Current Sick balance" />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data?.sickBalance}
                      </Typography>
                    </div>
                  </div>
                )}
                {data?.LeaveBalance && (
                  <div className="flex justify-center mb-5">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Current Leave balance" />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data?.LeaveBalance}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of requested days" />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {operations && operations[0]?.NB_Days}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of counted days" />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {operations && operations[0]?.NB_BalanceDays}
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Member's comment"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {data && data.commentUser && data.commentUser ? (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data && data.commentUser}
                      </Typography>
                    ) : (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"No comment"} />
                      </Typography>
                    )}
                  </div>
                </div>

                {/*         
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      Medical certificate
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <div className="flex gap-8">
                      <label
                        className="w-50 flex flex-row items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide border border-blue
                                        cursor-pointer hover:bg-indigo-700 hover:text-white text-indigo-500 ease-linear transition-all duration-150"
                      >
                        <i className="fas fa-cloud-download-alt fa"></i>
                      </label>
                      <label
                        className="
                                                                    w-50
                                                                    flex flex-row
                                                                    items-center
                                                                    px-2
                                                                    py-2
                                                                    bg-white
                                                                    rounded-md
                                                                    shadow-md
                                                                    tracking-wide
                                                                    
                                                                    border border-blue
                                                                    cursor-pointer
                                                                    hover:bg-indigo-700 hover:text-white
                                                                    text-indigo-500
                                                                    ease-linear
                                                                    transition-all
                                                                    duration-150
                                                                "
                      >
                        <i className="fas fa-file-alt fa"></i>
                      </label>
                    </div>
                  </div>
                </div> */}

                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-8/12 px-6 mt-20">
                    <Textarea
                      color="indigo"
                      placeholder="Comment"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="flex gap-8 mt-16 mb-12">
                                    <span className="block text-sm font-medium text-gray-700 mt-4">Medical certificate</span>
                                    <span className="block text-sm font-medium text-gray-700 mt-4">Certificat.pdf</span>
                                    <label className="w-50 flex flex-row items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide border border-blue
                                        cursor-pointer hover:bg-indigo-700 hover:text-white text-indigo-500 ease-linear transition-all duration-150"
                                    >
                                      <i className="fas fa-cloud-download-alt fa"></i>
                                    </label>
                                    <label
                                                                className="
                                                                    w-50
                                                                    flex flex-row
                                                                    items-center
                                                                    px-2
                                                                    py-2
                                                                    bg-white
                                                                    rounded-md
                                                                    shadow-md
                                                                    tracking-wide
                                                                    
                                                                    border border-blue
                                                                    cursor-pointer
                                                                    hover:bg-indigo-700 hover:text-white
                                                                    text-indigo-500
                                                                    ease-linear
                                                                    transition-all
                                                                    duration-150
                                                                "
                                                                >
                                                                <i className="fas fa-file-alt fa"></i>

                                                                </label>
                                                        
                                                
                                                    </div> */}

                <div className="flex justify-center mt-20">
                  <Button
                    style={{ backgroundColor: "#f2f2f2" }}
                    onClick={() => {
                      handleSend("accepted");
                    }}
                  >
                    <ClipLoader
                      color="green"
                      loading={loadingAccpet}
                      size={20}
                    />
                    <Check size="xl" style={{ color: "green" }} />
                    {/* <i className="fas fa-check"></i> */}
                  </Button>
                  <Button
                    style={{ backgroundColor: "#f2f2f2" }}
                    className="ml-5"
                    onClick={() => {
                      handleSend("refused");
                    }}
                  >
                    <ClipLoader color="red" loading={loadingRefuse} size={20} />
                    <CloseIcon style={{ color: "red" }} />
                    {/* <i className="fas fa-times text-red"></i> */}
                  </Button>
                  {/* <Button
                    style={{ backgroundColor: "#f2f2f2" }}
                    className="pl-20"
                    onClick={() => setOpenPopup({ open: true, id: data?._id })}
                  >
                    <SendIcon className="text-indigo-500" />
                  </Button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* {
        <PopupDeledate
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setValidator={setValidator}
          validator={validator}
        />
      } */}
      <NotificationContainer />
    </>
  );

  console.log("data", data);

  const formHomeWork = () => (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <div className="w-full text-center">
                <div className="">
                  {" "}
                  {/* <HomeWork style={{ color: color }} className="ml-4 mt-4" /> */}
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation message={"WFH"} />
                  </Typography>
                </div>
                {/* <Typography color="gray">
                  {data && data?.idSender?.firstname} wants to work from home.
                </Typography> */}
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="flex justify-center mb-5 mt-20">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Member"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data && data?.idSender?.firstname}{" "}
                      {data && data?.idSender?.lastname}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Submission date"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data &&
                        data?.createdAt
                          ?.substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          data?.createdAt?.substring(11, 16)}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  {data && data.name && data.name === "WFH" ? (
                    <>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Days requested"} />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        {/* {  request && request.name && request.name === 'WFH' ? */}
                        {operations &&
                          operations.map((operation) => {
                            return (
                              <Typography className=" text-sm  text-gray-700 pt-1">
                                {" "}
                                {operation.date &&
                                  moment(operation.date)
                                    .locale(i18n?.language)
                                    .format("dddd") +
                                    " " +
                                    operation.date
                                      .substring(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("-") +
                                    " " +
                                    operation.timeslot}
                              </Typography>
                            );
                          })}
                        {/* : request && request.name && request.name === 'REMOTE_WORKING' ?
                                                              <Typography className=" text-sm  text-gray-700 pt-1"> From {operations[0].date_debut.substring(0,10).split('-').reverse().join('-') + ' To ' + operations[0].date_fin.substring(0,10).split('-').reverse().join('-')}</Typography>
                                                            : ''
                                                    } */}
                      </div>
                    </>
                  ) : data && data.name && data.name === "REMOTE_WORKING" ? (
                    <>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Period"} />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        {operations &&
                          operations.map((operation) => {
                            return (
                              <Typography className=" text-sm  text-gray-700 pt-1">
                                {" "}
                                {operation.date_debut
                                  .substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-")}{" "}
                                <i className="fas fa-light fa-arrow-right" />{" "}
                                {operation.date_fin
                                  .substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-")}
                              </Typography>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {data?.name === "WFH" && (
                  <>
                    <div className="flex justify-center mb-5 ">
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          <Translation message={"Authorized WFH days"} />
                        </Typography>
                      </div>

                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          {data?.idSender?.wfhProfile?.authorizedWfhDays.map(
                            (day, index) =>
                              index ==
                              data?.idSender?.wfhProfile.authorizedWfhDays
                                .length -
                                1
                                ? t(day)
                                : t(day) + ", "
                          )}
                        </Typography>
                      </div>
                    </div>

                    <div className="flex justify-center mb-5 ">
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          <Translation message={"Extra balance"} />
                        </Typography>
                      </div>

                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          {data.extraBalance}
                        </Typography>
                      </div>
                    </div>
                    {data?.idSender?.partTime && (
                      <div className="flex justify-center mb-5 ">
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            <Translation message={"Part-time"} />
                          </Typography>
                        </div>

                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            {data?.idSender?.partTime}%
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* <div  className="flex justify-center mb-5">
                                    <div className="w-full lg:w-4/12 px-6">
                                        <Typography className=" text-sm  text-gray-700 pt-1">Days requested</Typography>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-6">
                                        { operations && operations.map(operation => {
                                            return <Typography className=" text-sm  text-gray-700 pt-1"> {operation.date.substring(0,10).split('-').reverse().join('-') + ' ' + operation.timeslot}</Typography>
                                        }) }
                                        
                                    </div>
                                </div> */}

                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Member's comment"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {data && data.commentUser && data.commentUser ? (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data && data.commentUser}
                      </Typography>
                    ) : (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"No comment"} />
                      </Typography>
                    )}
                  </div>
                </div>

                {/* <div className="flex justify-center lg:w-8/12 mt-20">
                  <Textarea
                    color="indigo"
                    // variant="static"
                    label="Comment"
                    placeholder="Leave your comment here"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </div> */}

                {data?.status == "pending" && (
                  <>
                    <div className="mt-20">
                      <Textarea
                        color="indigo"
                        variant="static"
                        label={<Translation message={"Comment"} />}
                        //placeholder="Leave your comment here"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex justify-center mt-20">
                      <Button
                        style={{ backgroundColor: "#f2f2f2" }}
                        onClick={() => {
                          handleSend("accepted");
                        }}
                      >
                        <ClipLoader
                          color="green"
                          loading={loadingAccpet}
                          size={20}
                        />
                        <Check size="xl" style={{ color: "green" }} />
                        {/* <i className="fas fa-check"></i> */}
                      </Button>
                      <Button
                        style={{ backgroundColor: "#f2f2f2" }}
                        className="ml-5"
                        onClick={() => {
                          handleSend("refused");
                        }}
                      >
                        <ClipLoader
                          color="red"
                          loading={loadingRefuse}
                          size={20}
                        />
                        <CloseIcon style={{ color: "red" }} />
                      </Button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );

  const formThirdPartySite = () => (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <div className="w-full text-center">
                <div className="">
                  {" "}
                  {/* <HomeWork style={{ color: color }} className="ml-4 mt-4" /> */}
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    Third Party Site
                  </Typography>
                </div>
                {/* <Typography color="gray">
                  {data && data?.idSender?.firstname} wants to work from home.
                </Typography> */}
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="flex justify-center mb-5 mt-20">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"User"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data && data.idSender.firstname}{" "}
                      {data && data.idSender.lastname}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Submission date"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {data &&
                        data.createdAt
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          data.createdAt.substring(11, 16)}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Days requested"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {/* {  request && request.name && request.name === 'WFH' ? */}
                    {operations &&
                      operations.map((operation) => {
                        return (
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            {operation.date
                              .substring(0, 10)
                              .split("-")
                              .reverse()
                              .join("-") +
                              " " +
                              operation.timeslot}
                          </Typography>
                        );
                      })}
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Member's comment"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {data && data.commentUser && data.commentUser ? (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {data && data.commentUser}
                      </Typography>
                    ) : (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"No comment"} />
                      </Typography>
                    )}
                  </div>
                </div>

                {data?.status == "pending" && (
                  <>
                    <Textarea
                      color="indigo"
                      placeholder="Comment"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />

                    <div className="flex justify-center mt-20">
                      <Button
                        style={{ backgroundColor: "#f2f2f2" }}
                        onClick={() => {
                          handleSend("accepted");
                        }}
                      >
                        <ClipLoader
                          color="green"
                          loading={loadingAccpet}
                          size={20}
                        />
                        <Check size="xl" style={{ color: "green" }} />
                        {/* <i className="fas fa-check"></i> */}
                      </Button>
                      <Button
                        style={{ backgroundColor: "#f2f2f2" }}
                        className="pl-20"
                        onClick={() => {
                          handleSend("refused");
                        }}
                      >
                        <ClipLoader
                          color="red"
                          loading={loadingRefuse}
                          size={20}
                        />
                        <CloseIcon style={{ color: "red" }} />
                        {/* <i className="fas fa-times text-red"></i> */}
                      </Button>
                      <Button
                        style={{ backgroundColor: "#f2f2f2" }}
                        className="pl-20"
                        onClick={() =>
                          setOpenPopup({ open: true, id: data?._id })
                        }
                      >
                        {/* <ClipLoader color="red" loading={loadingRefuse}  size={20} /> */}
                        <SendIcon className="text-indigo-500" />
                      </Button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );

  return (
    <div>
      {type === "WFH" || type === "REMOTE_WORKING"
        ? formHomeWork()
        : type === "Leave"
        ? formLeave()
        : type === "Client site"
        ? formThirdPartySite()
        : ""}

      <PopupDeledate
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setValidator={setValidator}
        validator={validator}
      />
    </div>
  );
}
