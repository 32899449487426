import { Apartment, Hail, HomeWork } from "@mui/icons-material";

export const schedulerData = [
  {
    icon: <Apartment htmlColor="#5262ff" fontSize="large" />,
    name: "On Site",
    color: "#5262ff",
    href: "/reservation",
  },
  {
    icon: <HomeWork htmlColor="#2ec6ff" fontSize="large" />,
    name: "WFH",
    color: "#2ec6ff",
    href: "/onlinework",
  },

  {
    icon: <Hail htmlColor="#b741c4" fontSize="large" />,
    name: "Third party site",
    color: "#b741c4",
    href: "/thirdpartysite_request",
  },
];

export default schedulerData;
