import React, { useState, Fragment } from "react";
import {
  Dialog,
  DialogFooter,
  Typography,
  Button,
  Textarea,
  Input,
} from "@material-tailwind/react";
import "./style.css";
import PersonIcon from "@mui/icons-material/Person";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import useMediaQuery from "useMediaQuery";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import { addRequest } from "../../actions/RequestAction";
import NotifPerson from "./NotifPerson";
import FormGroup from "@mui/material/FormGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import {
  fetchValidators,
  fetchUser,
  fetchALLUsers,
  fetchUserBalances,
  fetchBalances,
} from "../../actions/UserAction";
import jwt from "jwt-decode";
import { useEffect } from "react";
import { KeyboardArrowDown, Done, Hail } from "@mui/icons-material";
import Translation from "Translation";
const color = "#294e87";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ClintSiteDialog = (props) => {
  const { open, handleOpen, dates } = props;
  const [selected, setSelected] = useState({});
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [personNotif, setPersonNotif] = useState([]);
  var today = new Date();
  const [selectedDates, setSelectedDates] = useState([]);

  const [loading, setloading] = useState(false);
  const [user, setUser] = useState({});
  const [nbrWeek, setNbrWeek] = useState();
  const [nbrMonth, setNbrMonth] = useState();
  const [nbrMonthTwo, setNbrMonthTwo] = useState();
  const [client, setClient] = useState("");

  const [weekBalance, setWeekBalance] = useState();
  const [monthBalance, setMonthBalance] = useState();

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const decodedToken = jwt(token);

  const [week, setWeek] = useState();
  const [month, setMonth] = useState();
  const [monthTwo, setMonthTwo] = useState();
  const [diff, setDiff] = useState(true);
  const [comment, setComment] = useState("");
  const [firstMonth, setFirstMonth] = useState("");
  const [secondMonth, setSecondMonth] = useState("");

  function SortArray(x, y) {
    if (x.firstname < y.firstname) {
      return -1;
    }
    if (x.firstname > y.firstname) {
      return 1;
    }
    return 0;
  }

  var tab = [];
  var startDate;
  var endDate;
  var today = new Date();

  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  useEffect(() => {
    if (dates && dates.length > 0) {
      setSelectedDates(dates);
      console.log(dates);
      //   const start = new Date(dates[0]).startOf("week");
      //   const end = new Date(dates[0]).endOf("week");
      //   console.log("dates[dates.length] - 1", dates[dates.length] - 1);
      var result = getWeekNumber(new Date(dates[dates.length - 1]));
      setWeek(result);
      setMonth(new Date(dates[0]).getMonth() + 1);
    }
  }, [dates]);

  // useEffect(() => {
  // //   setNbrMonth(nbrMonth - dates.length);

  // }, [selectedDates ]);

  useEffect(() => {
    const start = selectedDates[0];
    var end = selectedDates[selectedDates.length - 1];

    if (start) {
      if (start.substring(5, 7) === end.substring(5, 7)) {
        setDiff(false);
      } else {
        setDiff(true);
        setFirstMonth(startDate.toLocaleString("en-GB", { month: "long" }));
        setSecondMonth(endDate.toLocaleString("en-GB", { month: "long" }));
        // end.substring(5,7).charAt(0) === 0 ? setMonthTwo(end.substring(6,7)) : setMonthTwo(end.substring(5,7))
        // console.log(end.substring(6,7))
        // setMonthTwo(end.substring(5,7).charAt(0))
      }
    }
  }, [selectedDates]);

  useEffect(() => {
    const fetchData = async () => {
      var valueWeek;
      var valueMonth;
      var valueMonthTwo;
      const balances = await fetchUserBalances(token, decodedToken.id);
      //   console.log("balances", balances);
      valueWeek =
        balances[0]?.WFHweekBalance.find((element) => element.nb === week)
          .count + selectedDates.length;
      valueMonth =
        balances &&
        balances[0]?.WFHmonthBalance.find((element) => element.nb === month)
          .count + selectedDates.length;

      if (monthTwo) {
        valueMonthTwo =
          balances[0]?.WFHmonthBalance.find(
            (element) => element.nb === parseInt(endDate.getMonth() + 1)
          ).count - selectedDates.length;
        // valueMonthTwo && valueMonthTwo = valueMonthTwo - selectedDates.length
      }
      setNbrWeek(valueWeek);
      //   console.log("valueWeek", valueWeek);
      //   valueMonth && setNbrMonth(valueMonth);
      setNbrMonth(valueMonth);
      setNbrMonthTwo(valueMonthTwo);
    };
    fetchData();
  }, [selectedDates, week, monthTwo]);

  useEffect(() => {
    const fetchData = async () => {
      const user = await fetchUser(token, decodedToken.id);
      setUser(user);
      const result = await fetchValidators(token);
      var listValidators = result
        .filter((item) => item._id !== user._id)
        .sort(SortArray);
      setManagers(listValidators);

      const users = await fetchALLUsers(token);
      setUsers(users.sort(SortArray));
      user.manager &&
        setSelected(result.find((item) => item._id === user.manager._id));
      const data = await fetchBalances(token);
      setWeekBalance(data?.WFHweekBalance);
      setMonthBalance(data?.WFHmonthBalance);
    };

    fetchData();
  }, []);

  const handleSend = async () => {
    var data;
    if (!selected || Object.keys(selected).length === 0) {
      NotificationManager.warning(<Translation message={"Please choose your validator"} />);
      return;
    }

    if (personNotif.length > 0) {
      var usersNotif = Array.from(personNotif, ({ _id }) => _id);
    } else {
      var usersNotif = [];
    }

    var dates = [];
    selectedDates.map((date) => {
      dates.push({
        day: date.slice(0, 10),
        slot: "AM",
      });
      dates.push({
        day: date.slice(0, 10),
        slot: "PM",
      });
    });

    data = {
      name: "Client site",
      idSender: user._id,
      idReciever: selected._id,
      UserNotif: usersNotif,
      date: dates,
      commentUser: comment,
      client: client,
    };

    // console.log("data", data);
    setloading(true);
    const result = await addRequest(token, data);
    setloading(false);
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        setTimeout(() => {
          window.location.href = "/requests";
        }, 2000);
      }
    } else {
      NotificationManager.error(result.message);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        handler={handleOpen}
        style={{ height: "90%" }}
        size={isDesktop ? "lg" : "xxl"}
      >
        <div
          className="flex flex-wrap justify-center mt-5"
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                {isDesktop ? (
                  <>
                    <div className="w-full text-center">
                      <Hail style={{ color: color }} fontSize="large" />
                      <Typography variant="h3" style={{ color: color }}>
                      <Translation
                      message={
                        "Are you planning to work from a third party site?"
                      }
                    />
                      </Typography>

                      <Typography variant="small" color="gray" className="mt-2">
                      <Translation
                      message={"Complete this form to send a request."}
                    />
                      </Typography>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex gap-8 mt-16 mb-12">
                        <div className="w-150">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Validator"} />
                          </Typography>
                        </div>
                        <Listbox
                          value={
                            selected &&
                            selected.firstname + " " + selected.lastname
                          }
                          onChange={setSelected}
                        >
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-300 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <span className="flex items-center">
                                {selected && selected.photo ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_UPLOADS_URL +
                                      selected.photo
                                    }
                                    alt=""
                                    className="flex-shrink-0 h-6 w-6 rounded-full"
                                  />
                                ) : (
                                  <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                    <PersonIcon style={{ color: "gray" }} />{" "}
                                  </div>
                                )}
                                {/* <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpcLzYU8SsybUPTpqpI01wbVK1Ysqi5FU98w&usqp=CAU' alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                                <span className="ml-3 block truncate">
                                  {selected && selected.firstname}{" "}
                                  {selected && selected.lastname}
                                </span>
                              </span>
                              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                {/* <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                /> */}
                                <KeyboardArrowDown
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-300 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {managers.map((person) => (
                                  <Listbox.Option
                                    key={person._id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-indigo-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          {person && person.photo ? (
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_UPLOADS_URL +
                                                person.photo
                                              }
                                              alt=""
                                              className="flex-shrink-0 h-6 w-6 rounded-full"
                                            />
                                          ) : (
                                            <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                              <PersonIcon
                                                style={{ color: "gray" }}
                                              />{" "}
                                            </div>
                                          )}

                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.firstname} {person.lastname}
                                          </span>
                                        </div>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            {/* <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            /> */}
                                            <Done
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div className="flex gap-8 mt-16 mb-12">
                        <div className="w-150">
                          <Typography className="text-sm text-gray-700 pt-1">
                          <Translation message={"Persons to notify"} />
                          </Typography>
                        </div>
                        <NotifPerson
                          users={users}
                          setPersonNotif={setPersonNotif}
                        />
                      </div>

                      <div className="flex gap-8 mb-12">
                        <div className="w-150">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message={"Days"} />
                          </Typography>
                        </div>

                        <FormGroup>
                          {selectedDates &&
                            selectedDates.map((day, index) => {
                              if (
                                day.date <= today.toISOString().substring(0, 10)
                              ) {
                                return (
                                  <>
                                    <div className="flex gap-8">
                                      <div className="w-250">
                                        <Typography className=" text-sm  text-gray-700 pt-1">
                                          {day.value}
                                        </Typography>
                                      </div>
                                      <ToggleButtonGroup
                                        color="primary"
                                        className="mt-1"
                                      >
                                        <ToggleButton value="sick" disabled>
                                          <p htmlColor="#083985">AM</p>
                                        </ToggleButton>
                                        <ToggleButton value="casual" disabled>
                                          <p htmlColor="#083985"> PM </p>
                                        </ToggleButton>
                                        <ToggleButton value="casual" disabled>
                                          <p htmlColor="#083985">FULL</p>
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="flex gap-8">
                                    <div className="w-250">
                                      <Typography className=" text-sm  text-gray-700 pt-1">
                                        {day.split("-").reverse().join("-")}
                                      </Typography>
                                    </div>

                                    <ToggleButtonGroup
                                      color="primary"
                                      // value={slots}
                                      // onChange={handleSlots}
                                      aria-label="text formatting"
                                      className="mt-1"
                                      // exclusive
                                      // onClick={() => {
                                      //   getDate(day.date);
                                      // }}
                                    >
                                      <ToggleButton
                                        // selected={checkedAM[index]}
                                        // onClick={() => handleChangeAM(index)}
                                        // value={`${day.date} AM`}
                                        // value={"AM"}
                                        style={{ outline: "none" }}
                                        // disabled={true}
                                      >
                                        AM
                                      </ToggleButton>
                                      <ToggleButton
                                        // selected={checkedPM[index]}
                                        // onClick={() => handleChangePM(index)}
                                        // value={`${day.date} PM`}
                                        // // value="PM"
                                        style={{ outline: "none" }}
                                        // disabled={true}
                                      >
                                        PM
                                      </ToggleButton>
                                      <ToggleButton
                                        // selected={checkedFull[index]}
                                        // onClick={() => {
                                        //   handleChangeFULL(index);
                                        // }}
                                        // // value="Full"
                                        // // value={`${day.date} FULL`}
                                        // value={[
                                        //   `${day.date} AM`,
                                        //   `${day.date} PM`,
                                        // ]}
                                        // style={{ outline: "none" }}
                                        value="FULL"
                                        selected
                                        // disabled={false}
                                      >
                                        FULL
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>
                                );
                              }
                            })}
                        </FormGroup>
                      </div>
                      <Input
                        color="indigo"
                        variant="static"
                        placeholder="Client"
                        label="Client"
                        value={client}
                        onChange={(e) => {
                          setClient(e.target.value);
                        }}
                      />

                      <div className="mt-20">
                        <Textarea
                          color="indigo"
                          variant="static"
                          label= {<Translation message={"Comment"} />}
                        // placeholder="Leave a comment here"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                      </div>

                      {/* <div className="flex justify-center mt-20">
                        <Button
                          style={{ backgroundColor: "#083985" }}
                          ripple="light"
                          onClick={() => {
                            handleSend();
                          }}
                        >
                          <ClipLoader
                            color="white"
                            loading={loading}
                            size={20}
                          />
                          Send Request
                        </Button>
                      </div> */}
                    </form>
                  </>
                ) : (
                  <>
                    <div className="w-full text-center">
                      <Hail style={{ color: color }} fontSize="small" />
                      <Typography variant="h5" style={{ color: color }}>
                      <Translation message={"Are you planning to work from a third party site?"} />
                      </Typography>

                      <Typography variant="small" color="gray" className="mt-2">
                      <Translation message={"Complete this form to send a client site's request."} />
                      </Typography>
                    </div>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="mt-16"
                    >
                      <div className="mt-5">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Validator"} /> 
                        </Typography>
                        <Listbox
                          value={
                            selected &&
                            selected.firstname + " " + selected.lastname
                          }
                          onChange={setSelected}
                        >
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-250 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <span className="flex items-center">
                                {selected && selected.photo ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_UPLOADS_URL +
                                      selected.photo
                                    }
                                    alt=""
                                    className="flex-shrink-0 h-6 w-6 rounded-full"
                                  />
                                ) : (
                                  <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                    <PersonIcon style={{ color: "gray" }} />{" "}
                                  </div>
                                )}
                                {/* <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpcLzYU8SsybUPTpqpI01wbVK1Ysqi5FU98w&usqp=CAU' alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                                <span className="ml-3 block truncate">
                                  {selected && selected.firstname}{" "}
                                  {selected && selected.lastname}
                                </span>
                              </span>
                              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                {/* <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                /> */}
                                <KeyboardArrowDown
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-250 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {managers.map((person) => (
                                  <Listbox.Option
                                    key={person._id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-indigo-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <div className="flex items-center">
                                          {person && person.photo ? (
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_UPLOADS_URL +
                                                person.photo
                                              }
                                              alt=""
                                              className="flex-shrink-0 h-6 w-6 rounded-full"
                                            />
                                          ) : (
                                            <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                              <PersonIcon
                                                style={{ color: "gray" }}
                                              />{" "}
                                            </div>
                                          )}

                                          <span
                                            className={classNames(
                                              selected
                                                ? "font-semibold"
                                                : "font-normal",
                                              "ml-3 block truncate"
                                            )}
                                          >
                                            {person.firstname} {person.lastname}
                                          </span>
                                        </div>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? "text-white"
                                                : "text-indigo-600",
                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}
                                          >
                                            {/* <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            /> */}
                                            <Done
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <div className="mt-5">
                        <Typography className="text-sm text-gray-700 pt-1">
                        <Translation message={"Persons to notify"} /> 
                        </Typography>
                        <NotifPerson
                          users={users}
                          setPersonNotif={setPersonNotif}
                        />
                      </div>

                      <div className="">
                        <Typography className="mb-5 text-sm  text-gray-700 pt-1">
                        <Translation message={"Days"} />
                        </Typography>

                        <FormGroup>
                          {selectedDates &&
                            selectedDates.map((day, index) => {
                              if (
                                day.date <= today.toISOString().substring(0, 10)
                              ) {
                                return (
                                  <>
                                    <div className="flex gap-8">
                                      <div className="w-200">
                                        <Typography className=" text-sm  text-gray-700 pt-1">
                                          {day.value}
                                        </Typography>
                                      </div>
                                      <ToggleButtonGroup
                                        color="primary"
                                        className="mt-1"
                                      >
                                        <ToggleButton value="sick" disabled>
                                          <p htmlColor="#083985">AM</p>
                                        </ToggleButton>
                                        <ToggleButton value="casual" disabled>
                                          <p htmlColor="#083985"> PM </p>
                                        </ToggleButton>
                                        <ToggleButton value="casual" disabled>
                                          <p htmlColor="#083985"> Full </p>
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="flex gap-8">
                                    <div className="w-200">
                                      <Typography className=" text-sm  text-gray-700 pt-1">
                                        {day.value}
                                      </Typography>
                                    </div>
                                    {/* <ToggleButtonGroup
                                      color="primary"
                                      // value={slots}
                                      // onChange={handleSlots}
                                      aria-label="text formatting"
                                      className="mt-1"
                                      // exclusive
                                      // onClick={() => {
                                      //   getDate(day.date);
                                      // }}
                                    >
                                      <ToggleButton
                                        selected={checkedAM[index]}
                                        onClick={() => handleChangeAM(index)}
                                        value={`${day.date} AM`}
                                        // value={"AM"}
                                        style={{ outline: "none" }}
                                        disabled={
                                          selectedPM.includes(day.date) ||
                                          selectedFull.includes(day.date)
                                        }
                                      >
                                        AM
                                      </ToggleButton>
                                      <ToggleButton
                                        selected={checkedPM[index]}
                                        onClick={() => handleChangePM(index)}
                                        value={`${day.date} PM`}
                                        // value="PM"
                                        style={{ outline: "none" }}
                                        disabled={
                                          selectedAM.includes(day.date) ||
                                          selectedFull.includes(day.date)
                                        }
                                      >
                                        PM
                                      </ToggleButton>
                                      <ToggleButton
                                        selected={checkedFull[index]}
                                        onClick={() => {
                                          handleChangeFULL(index);
                                        }}
                                        // value="Full"
                                        // value={`${day.date} FULL`}
                                        value={[
                                          `${day.date} AM`,
                                          `${day.date} PM`,
                                        ]}
                                        style={{ outline: "none" }}
                                        disabled={
                                          selectedPM.includes(day.date) ||
                                          selectedAM.includes(day.date)
                                        }
                                      >
                                        FULL
                                      </ToggleButton>
                                    </ToggleButtonGroup> */}
                                  </div>
                                );
                              }
                            })}
                        </FormGroup>
                      </div>

                      {nbrMonth >= monthBalance ? (
                        <Alert severity="error">
                          <Translation message={"You have reached the maximum number of days allowed for this month!"} />
                        </Alert>
                      ) : (
                        ""
                      )}
                      {nbrWeek >= weekBalance ? (
                        <Alert severity="error">
                          <Translation message={"You have reached the maximum number of days allowed for this week!"} />
                        </Alert>
                      ) : (
                        ""
                      )}

                      <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Balance for current week Vs Best practices  "} />{" "}
                        <b>
                          {nbrWeek} / {weekBalance}
                        </b>
                      </Typography>
                      {diff === false ? (
                        <>
                          <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Balance for current month Vs Best practices  "} />{" "}
                            <b>
                              {nbrMonth} / {monthBalance}{" "}
                            </b>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message={"Balance for "} /> {firstMonth} <Translation message={"Vs Best practices "} /> {" "}
                            <b>
                              {nbrMonth} / {monthBalance}{" "}
                            </b>
                          </Typography>
                          <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Balance for "} /> {secondMonth} <Translation message={"Vs Best practices "} />{" "}
                            <b>
                              {nbrMonthTwo} /{monthBalance}{" "}
                            </b>
                          </Typography>
                        </>
                      )}

                      <div className="mt-20">
                        <Textarea
                          color="indigo"
                          variant="static"
                          label= {<Translation message={"Comment"} />}
                        // placeholder="Leave a comment here"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                      </div>
                      {/* 
                      <div className="flex justify-center mt-20">
                        <Button
                          style={{ backgroundColor: "#083985" }}
                          ripple="light"
                          onClick={() => {
                            handleSend();
                          }}
                        >
                          <ClipLoader
                            color="white"
                            loading={loading}
                            size={20}
                          />
                          Send Request
                        </Button>
                      </div> */}
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        <DialogFooter
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "#083985" }}
            ripple="light"
            onClick={() => {
              handleSend();
            }}
          >
            <ClipLoader color="white" loading={loading} size={20} />
            <Translation message={"Send Request"} />
          </Button>
          <Button
            className="ml-2"
            variant="outlined"
            color="indigo"
            onClick={() => {
              handleOpen();
            }}
          >
            <Translation message={"Cancel"} />
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default ClintSiteDialog;
