import React from "react";
import { useMemo } from "react";

import { FaUserPlus, FaUserCheck, FaUsersCog } from "react-icons/fa";
import { TbReportMoney } from "react-icons/tb";
import { BsFolderCheck } from "react-icons/bs";
import { RiRefund2Fill } from "react-icons/ri";

const icons: Record<IconName, React.ElementType> = {
  FaUserPlus,
  FaUserCheck,
  FaUsersCog,
  TbReportMoney,
  RiRefund2Fill,
  BsFolderCheck,
};

const ReactIconComponent = ({ name, ...rest }) => {
  const IconComponent = useMemo(() => icons[name], [name]);

  if (!IconComponent) {
    throw new Error(`Invalid icon name: ${name}`);
  }

  return <IconComponent {...rest} />;
};

export default ReactIconComponent;
