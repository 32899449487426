import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { fetchHorizon } from "actions/RessourcesAction";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "dayjs/locale/fr";

var momentBusinessDays = require("moment-business-days");

export default function Calendar(props) {
  const { selectedDate, setSelectedDate } = props;

  const [horizon, setHorizon] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchHorizon();
      setHorizon(data.NBDays);
    };
    fetch();
  }, []);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n?.language}
      >
        <DatePicker
          label="Date"
          minDate={new Date()}
          // maxDate={momentBusinessDays().businessAdd(horizon)._d}
          maxDate={moment().add("days", horizon - 1)._d}
          inputFormat="DD/MM/YYYY"
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(new Date(newValue));
          }}
          renderInput={(params) => (
            <TextField onKeyDown={onKeyDown} {...params} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
