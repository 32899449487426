import api from "./api";

const url = process.env.REACT_APP_API_URL + "Desk/";

export const fetchRooms = async () => {
  const result = await api
    .get(url + `rooms`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log("error ", error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchAccessRooms = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Zone/getAccesRooms/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log("error ", error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchFloorById = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Floor/search/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchHorizon = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Setting/getHorizon`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const handleLock = async (id, start, end) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `LockRessource/CheckLockRessource`, {
      ressource: id,
      start: start,
      end: end,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const handleUnlock = async (id, start, end) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `LockRessource/UnLockRessource`, {
      ressource: id,
      start: start,
      end: end,
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchFloors = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Floor/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};
