// import React, { useState } from "react";
// import Select from "react-select";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import * as styles from "./style.css";
// // import "react-select/dist/react-select.css";

// const options = [
//   { label: "Mango", value: "mg" },
//   { label: "Guava", value: "gv" },
//   { label: "Peach", value: "pc" },
//   { label: "Apple", value: "ap" },
// ];

// const DropDown = (props) => {
//   const options = props.multi
//     ? [{ label: "Select All", value: "all" }, ...props.options]
//     : props.options;
//   console.log(options);
//   return (
//     <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
//       <Select
//         name="example"
//         options={options}
//         multi={props.multi}
//         value={props.value ? props.value : null}
//         onChange={(selected) => {
//           props.multi &&
//           selected.length &&
//           selected.find((option) => option.value === "all")
//             ? props.handleChange(options.slice(1))
//             : !props.multi
//             ? props.handleChange((selected && selected.value) || null)
//             : props.handleChange(selected);
//         }}
//       />
//     </div>
//   );
// };

// const SelectAllOption = ({ selectAll, setSelectAll }) => {
//   const handleSelectAll = () => {
//     setSelectAll(!selectAll);
//   };

//   return (
//     <div className="flex items-center cursor-pointer" onClick={handleSelectAll}>
//       <FontAwesomeIcon
//         icon={faCheck}
//         className={`mr-2 text-${selectAll ? "green" : "gray"}-500`}
//       />
//       <span>Select All</span>
//     </div>
//   );
// };

// const SelectAutocomplete = () => {
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);

//   const handleOptionChange = (selectedValues) => {
//     if (selectedValues.find((option) => option.value === "all")) {
//       setSelectedOptions(options);
//       setSelectAll(true);
//     } else {
//       setSelectedOptions(selectedValues);
//       setSelectAll(false);
//     }
//   };

//   const allOption = { value: "all", label: "Select All" };
//   const displayOptions = selectAll ? [allOption] : options;

//   const handleChange = (value) => setSelectedOptions(value);

//   return (
//     <div className="w-80">
//       <DropDown
//         value={selectedOptions}
//         options={options}
//         handleChange={handleChange}
//         multi={true}
//       />
//       {/* <Select
//         options={displayOptions}
//         value={selectedOptions}
//         onChange={handleOptionChange}
//         isMulti
//         closeMenuOnSelect={false}
//         components={{
//           Option: ({ innerProps, data }) => (
//             <div {...innerProps}>
//               {data.value === "all" ? (
//                 <SelectAllOption
//                   selectAll={selectAll}
//                   setSelectAll={setSelectAll}
//                 />
//               ) : (
//                 <div className="flex items-center">
//                   <FontAwesomeIcon
//                     icon={faCheck}
//                     className={`mr-2 text-${
//                       selectedOptions.includes(data) ? "green" : "gray"
//                     }-500`}
//                   />
//                   <span>{data.label}</span>
//                 </div>
//               )}
//             </div>
//           ),
//           MultiValue: ({ data }) => (
//             <div className="flex items-center bg-green-500 rounded-full py-1 px-2 mr-2 mb-2">
//               <span className="text-white">{data.label}</span>
//             </div>
//           ),
//         }}
//       /> */}
//     </div>
//   );
// };

// export default SelectAutocomplete;

// import React from "react";
// // import "react-select/dist/";
// import Select from "react-select";
// import * as styles from "./style.css";

// const fruits = [
//   { label: "Mango", value: "mg" },
//   { label: "Guava", value: "gv" },
//   { label: "Peach", value: "pc" },
//   { label: "Apple", value: "ap" },
// ];

// const states = [
//   { label: "MN", value: "MN" },
//   { label: "CA", value: "CA" },
//   { label: "CT", value: "CT" },
//   { label: "MI", value: "MI" },
// ];

// const DropDown = (props) => {
//   const options = props.multi
//     ? [{ label: "Select All", value: "all" }, ...props.options]
//     : props.options;
//   console.log(options);
//   return (
//     <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
//       <Select
//         name="example"
//         options={options}
//         multi={true}
//         value={props.value ? props.value : null}
//         onChange={(selected) => {
//           props.multi &&
//           selected.length &&
//           selected.find((option) => option.value === "all")
//             ? props.handleChange(options.slice(1))
//             : !props.multi
//             ? props.handleChange((selected && selected.value) || null)
//             : props.handleChange(selected);
//         }}
//       />
//     </div>
//   );
// };

// class SelectAutocomplete extends React.Component {
//   state = {
//     fruits: null,
//     states: null,
//   };

//   handleChange = (value) => this.setState({ fruits: value });
//   updateState = (value) => this.setState({ states: value });

//   render() {
//     return (
//       <div>
//         <DropDown
//           value={this.state.fruits}
//           options={fruits}
//           handleChange={this.handleChange}
//           multi={true}
//         />
//         {this.state.fruits && <p>{JSON.stringify(this.state.fruits)}</p>}
//         <br />
//         <DropDown
//           value={this.state.states}
//           options={states}
//           handleChange={this.updateState}
//         />
//         {this.state.states && <p>{JSON.stringify(this.state.states)}</p>}
//       </div>
//     );
//   }
// }

// export default SelectAutocomplete;

import React, { useState } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./style.css";
import Translation from "Translation";
const animatedComponents = makeAnimated();

export default function SelectAutocomplete(props) {
  const { users, setPerson } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  var options = [
    { label: <Translation message={"Select All"} />, value: "all" },
    ...users.map((item) => {
      return { value: item._id, label: item.firstname + " " + item.lastname };
    }),
  ];

  //   const handleChange = (value) => setPerson(value);
  const handleChange = (newValue) => {
    setSelectedOptions(newValue);
    setPerson(newValue);
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      options={options}
      onChange={(selected) => {
        selected.find((option) => option.value === "all")
          ? handleChange(options.filter((item) => item.value != "all"))
          : handleChange(selected);
      }}
      value={selectedOptions}
    />
  );
}
