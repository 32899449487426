import DefaultFooter from "../components/Footers/DefaultFooter";

import Section from "components/Reservation/Section";
import DefaultNavbar from "../components/Navbars/DefaultNavbar";

export default function Reservation() {
  return (
    <>
      {/* <div className="absolute w-full z-20 ml-2"> */}
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <DefaultNavbar />
      </div>
      <main>
        {" "}
        <Section />
      </main>
      <DefaultFooter />
    </>
  );
}
