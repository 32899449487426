import React, { useState, useEffect } from "react";
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  TimelineViews,
  Resize,
  DragAndDrop,
  TimelineMonth,
  Day,
} from "@syncfusion/ej2-react-schedule";
import { L10n } from "@syncfusion/ej2-base";
import { fetchAccessRooms } from "actions/RessourcesAction";
import {
  addRoomReservation,
  deleteOperation,
  getRoomsOperations,
} from "actions/OperationAction";
import jwt from "jwt-decode";
import Paper from "@material-ui/core/Paper";
import meeting from "./round-table.png";

import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";

import UsersField from "./UsersField";
import { fetchALLUsers } from "actions/UserAction";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import "./style.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Delete from "@mui/icons-material/Delete";
import Button from "@material-tailwind/react/components/Button";
import CapacityIcon from "@mui/icons-material/ReduceCapacityOutlined";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import { fetchHorizon } from "actions/UserAction";
var momentBusinessDays = require("moment-business-days");

L10n.load({
  "en-US": {
    schedule: {
      saveButton: "Save",
      cancelButton: "Close",
      deleteButton: "Remove",
      newEvent: "New Reservation",
    },
  },
});

const Meetingschedule = () => {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const decodedToken = jwt(token);

  var scheduleObj;
  const [roomsData, setRoomsData] = useState([]);
  const [roomsId, setRoomsId] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [team, setTeam] = useState();
  const [users, setUsers] = useState();
  const [horizon, setHorizon] = useState();

  var teamMembers;
  var room;

  var date = new Date();
  var minDate = moment().subtract(1, "days");

  useEffect(() => {
    const fetch = async () => {
      const rooms = await fetchAccessRooms(decodedToken.id);
      setRoomsData(rooms);
      rooms.length != 0 && setRoomsId(Array.from(rooms, ({ _id }) => _id));
      const result = await fetchALLUsers();
      setUsers(result);
      const data = await fetchHorizon();
      setHorizon(data.NBDays);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (roomsId.length != 0) {
        const result = await getRoomsOperations(roomsId);
        if (result.length != 0) {
          var cardReservation = [];

          for (const r of result) {
            cardReservation.push({
              id: r._id,
              StartTime: r.date_debut.toString().substring(0, 23),
              EndTime: r.date_fin.toString().substring(0, 23),
              Subject: r.user
                ? r.user.firstname + " " + r.user.lastname
                : r.guest,
              IsReadonly: r.guest ? false : true,
              Location: r.desk.zone.floor.name,
              RoomId: r.desk._id,
              Color: "#0e44a1",
              UserId: r.user ? r.user._id : r.guest,
            });
          }
          setReservations(cardReservation);
        }
      }
    };
    fetch();
  }, [roomsId]);

  const resourceHeaderTemplate = (props) => {
    return (
      <div className="template-wrap">
        <div className="employee-category">
          {/* <table>
                        <tr>
                            <td> <span className="room-name">{props.resourceData.name}</span></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="room-designation">{props.resourceData.zone.floor.name}</span>
                            </td>
                            <td>
                                <td> <span className="room-designation" style={{ marginRight: '5px', color: '#3161ad' }}>{props.resourceData.capacity}</span></td>
                                <td>  <span className="room-designation" style={{ float: 'right', color: '#3161ad' }}><CapacityIcon fontSize='small' /></span></td>
                            </td>
                        
                        </tr>
                        <tr>
                           
                        </tr>
                    </table> */}
          <div className="room-name">{props.resourceData.name}</div>
          <div className="room-designation">
            <span>{props.resourceData.zone.floor.name}</span>
            <div style={{ float: "right", color: "#083985" }}>
              <span style={{ marginRight: "6px", marginTop: "10px" }}>
                {props.resourceData.capacity}
              </span>
              <CapacityIcon
                fontSize="small"
                style={{ float: "right", color: "#3161ad" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPopupOpen = (args) => {
    if (args.type === "QuickInfo") {
      if (args.target.classList.contains("e-selected-cell")) {
        var dialogObj = args.element.ej2_instances[0];
        dialogObj.hide();
      } else {
        var buttonElement =
          args.type === "QuickInfo"
            ? ".e-event-popup .e-edit"
            : ".e-schedule-dialog .e-event-edit";
        var editButton = document.querySelector(buttonElement);
        if (editButton?.ej2_instances) {
          editButton.ej2_instances[0].disabled = true;
        }
      }

      var currentAction = args.target.classList.contains("e-work-cells")
        ? "Add"
        : "Save";
      if (args.target.classList.contains("e-work-cells")) {
        scheduleObj.openEditor(args.data, currentAction);
      }
    }
  };

  const onActionBegin = async (args) => {
    if (args.requestType === "eventCreate") {
      let data = args.data instanceof Array ? args.data[0] : args.data;

      var start = moment(data.StartTime).format();
      var end = moment(data.EndTime).format();

      var reservation = {
        user: decodedToken.id,
        desk: room,
        date_debut: start.toString().substring(0, 19) + "Z",
        date_fin: end.toString().substring(0, 19) + "Z",
        team: teamMembers,
      };

      const result = await addRoomReservation(reservation);
      if (result.status === 200) {
        if (result.data && result.data.status == 201) {
          NotificationManager.error(result.data.message);
        } else {
          NotificationManager.success(result.message);
          var newElement = {
            // Color: "#0e44a1",
            RoomId: room,
            EndTime: data.EndTime,
            IsReadonly: true,
            // Location: "Zone 02.01",
            StartTime: data.StartTime,
            Subject: decodedToken.firstname + " " + decodedToken.lastname,
            // ZoneId: zone
          };

          setReservations([...reservations, newElement]);
        }
      } else {
        NotificationManager.error(result.message);
      }
    }
  };

  const customItems = (props) => {
    return (
      <div className="team-item">
        <span>
          {props.photo ? (
            <img
              className="empImg"
              src={process.env.REACT_APP_UPLOADS_URL + props.photo}
            />
          ) : (
            <div className="empImg">
              <PersonIcon style={{ color: "gray" }} />{" "}
            </div>
          )}
        </span>
        <span>
          <div className="name">
            {props.firstname} {props.lastname}{" "}
          </div>
        </span>
      </div>
    );
  };
  const handleDelete = async (id) => {
    if (id) {
      const result = await deleteOperation("", id);

      if (result.status === 200) {
        if (result.data && result.data.status == 201) {
          NotificationManager.error(result.message);
        } else {
          NotificationManager.success(result.message);
          const newData = reservations.filter((item) => item.id !== id);
          setReservations(newData);
        }
      } else {
        NotificationManager.error(result.message);
      }
    } else {
      NotificationManager.error("Please retry again");
      window.location.reload();
    }
  };
  const footerTemplate = (props) => {
    if (props.elementType == "event") {
      if (props.UserId == decodedToken.id) {
        return (
          <div className="e-event-header e-popup-header">
            <div className="e-header-icon-wrapper">
              <Button
                //  style={{ backgroundColor : '#3393de' }}
                ripple="light"
                size="sm"
                buttonType="outline"
                onClick={() => {
                  handleDelete(props.id);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      } else return <div></div>;
    } else {
      return <div></div>;
    }
  };
  const editorTemplate = (props) => {
    room = props.RoomId;
    const data =
      roomsData && roomsData.find((element) => element._id == props.RoomId);
    return props !== undefined ? (
      <div style={{ height: 300 }}>
        <table
          className="custom-event-editor"
          style={{ width: "100%", cellpadding: "5", marginTop: "10px" }}
        >
          <tbody>
            <tr>
              <td className="e-textlabel">From</td>
              <td style={{ colspan: "4" }}>
                <DateTimePickerComponent
                  readOnly
                  enabled={false}
                  id="StartTime"
                  format="dd/MM/yy hh:mm a"
                  data-name="StartTime"
                  value={new Date(props.startTime || props.StartTime)}
                  className="e-field"
                ></DateTimePickerComponent>
              </td>
            </tr>
            <tr>
              <td className="e-textlabel">To</td>
              <td style={{ colspan: "4" }}>
                <DateTimePickerComponent
                  readOnly
                  enabled={false}
                  id="EndTime"
                  format="dd/MM/yy hh:mm a"
                  data-name="EndTime"
                  value={new Date(props.endTime || props.EndTime)}
                  className="e-field"
                ></DateTimePickerComponent>
              </td>
            </tr>
            {/* <tr><td className="e-textlabel">Team</td><td style={{ colspan: '4' }}>
            <UsersField users={users} setTeam={setTeam} />
          </td></tr> */}
            <tr>
              <td className="e-textlabel">Team</td>
              <td style={{ colspan: "4" }}>
                <MultiSelectComponent
                  dataSource={users}
                  fields={{ text: "firstname", value: "_id" }}
                  itemTemplate={customItems}
                  placeholder="Choose the team of meeting"
                  maximumSelectionLength={data?.capacity}
                  autofill={true}
                  onChange={(select) => {
                    teamMembers = select?.value;
                  }}
                ></MultiSelectComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      <div></div>
    );
  };

  return (
    <div>
      <Paper style={{ paddingTop: "140px", paddingBottom: "250px" }}>
        <div className="schedule-control-section">
          <div className="col-lg-12 control-section">
            <div className="control-wrapper drag-sample-wrapper">
              <div className="schedule-container">
                <ScheduleComponent
                  cssClass="block-events"
                  height="auto"
                  width="auto"
                  style={{ paddingBottom: "150px" }}
                  currentView="TimelineWeek"
                  selectedDate={new Date()}
                  views={[
                    "Day",
                    "Week",
                    "Month",
                    "TimelineDay",
                    "TimelineWeek",
                  ]}
                  resourceHeaderTemplate={resourceHeaderTemplate}
                  eventSettings={{ dataSource: reservations }}
                  group={{ enableCompactView: false, resources: ["Room"] }}
                  delayUpdate="true"
                  rowAutoHeight={true}
                  startHour="08:00"
                  endHour="18:00"
                  timeScale={{
                    enable: true,
                    slotCount: 4,
                  }}
                  quickInfoOnSelectionEnd="true"
                  ref={(schedule) => (scheduleObj = schedule)}
                  editorTemplate={editorTemplate}
                  actionBegin={onActionBegin}
                  popupOpen={onPopupOpen}
                  quickInfoTemplates={{ footer: footerTemplate }}
                  minDate={new Date(minDate)}
                  maxDate={momentBusinessDays().businessAdd(horizon)._d}
                  showWeekend={false}
                >
                  <ResourcesDirective>
                    <ResourceDirective
                      field="RoomId"
                      title="Rooms"
                      name="Room"
                      allowMultiple={true}
                      dataSource={roomsData}
                      textField="name"
                      idField="_id"
                      colorField="Color"
                    ></ResourceDirective>
                  </ResourcesDirective>

                  <Inject
                    services={[
                      Day,
                      TimelineViews,
                      TimelineMonth,
                      Resize,
                      DragAndDrop,
                    ]}
                  />
                </ScheduleComponent>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <NotificationContainer />
    </div>
  );
};

export default Meetingschedule;
