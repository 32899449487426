import { Encrypt } from "Crypto";
import api from "./api";

const urlTravel = process.env.REACT_APP_API_TRAVEL;

export const fetchCountries = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Country/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchCities = async (idCountry) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `City/search/country/${idCountry}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchAlCities = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `City/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchPerdiem = async (region, formule, grade) => {
  const result = await api
    .get(urlTravel + `PerDiemPolicy/getperdiem/${region}/${formule}/${grade}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchFormulas = async (region, formule, grade) => {
  // console.log("region", region, "formule", formule, "grade", grade);
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Formula/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchCityCap = async (country, city) => {
  const result = await api
    .get(
      process.env.REACT_APP_API_TRAVEL + `CityCap/getCityCap/${country}/${city}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchVisaHistory = async (user) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `userVisas/${user}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchVaccineHistory = async (user) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `userVaccines/${user}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addMission = async (data) => {
  var request = Encrypt(data);

  const result = await api
    .post(
      process.env.REACT_APP_API_TRAVEL + `Request/addMissionRequest`,
      // "http://localhost:5003/api/Request/addMissionRequest",
      { request: request }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const editRequest = async (data, id) => {
  // var request = Encrypt(data);

  const result = await api
    .put(
      process.env.REACT_APP_API_TRAVEL + `Request/updateRequest/${id}`,
      // "http://localhost:5003/api/Request/addMissionRequest",
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getWorkflowSteps = async (type) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Workflow/${type}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getUserPendingMissions = async (id, page, limit) => {
  const result = await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Request/getPendingRequestsByUser/${id}/${page}/${limit}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getUserMissions = async (id, page, limit) => {
  const result = await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Request/getRequestsByUser/${id}/${page}/${limit}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchMissionRequestByID = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Request/getRequestByID/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

// export const fetchTask = async (idMission) => {
//   const result = await api
//     .get(process.env.REACT_APP_API_TRAVEL + `Task/getRequestByID/${idMission}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       const errorMsg = error.message;
//       return errorMsg;
//     });

//   return result;
// };

export const fetchCurrencies = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Currency/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getComments = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Mission/fetchComments/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addComment = async (id, comment, taggedUsers) => {
  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `Mission/addComment/${id}`, {
      comment: comment,
      taggedUsers: taggedUsers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getPendingMissionRequestsByManager = async (id) => {
  const result = await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Request/getPendingRequestsByManager/${id}`,
      {}
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getUserPermissions = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Privilege/search`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const ValidateRequest = async (idRequest, type) => {
  return await api
    .get(
      `${process.env.REACT_APP_API_TRAVEL}Request/validateRequest/${type}/${idRequest}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data.message;
      return errorMsg;
    });
};

export const rejectRequest = async (idRequest) => {
  return await api
    .get(
      `${process.env.REACT_APP_API_TRAVEL}Request/rejectRequest/${idRequest}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data.message;
      return errorMsg;
    });
};

export const PreviousStep = async (mission, type) => {
  return await api
    .get(
      `${process.env.REACT_APP_API_TRAVEL}Step/PreviousStep/${type}/${mission}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });
};

export const getTeamMissions = async (start, end) => {
  const result = await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Mission/getTeamMission/${start}/${end}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addFile = async (data, id) => {
  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `Mission/addFile/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchFile = async (mission, fileName) => {
  return await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Mission/getFile/${mission}/${fileName}`,
      { responseType: "arraybuffer" }
    )
    .then((response) => {
      return response;
    })

    .catch((error) => {
      console.error("Erreur lors du chargement du fichier:", error);
    });

  //   const blob = await response.blob();
  //   const objectUrl = URL.createObjectURL(blob);
  //   return objectUrl;
  //   // return [URL.createObjectURL(blob), null];
  // } catch (error) {
  //   console.error(`get: error occurred ${error}`);
  //   return [null, error];
};

// export const getFile = async (mission, fileName) => {
//   const result = await api
//     .get(
//       process.env.REACT_APP_API_TRAVEL +
//         `Mission/getFile/${mission}/${fileName}`
//     )
//     .then((response) => {
//       // console.log("response", response);
//       return response.data;
//     })
//     .catch((error) => {
//       const errorMsg = error.message;
//       return errorMsg;
//     });

//   return result;
// };

export const addBills = async (id, data) => {
  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `Mission/addBills/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getBills = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `Mission/getBills/${id}`, {
      // responseType: "arraybuffer",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const deleteMissionRequest = async (id, type) => {
  const result = await api
    .delete(
      process.env.REACT_APP_API_TRAVEL + `Request/cancelRequest/${type}/${id}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      const errorMsg = error.message;
      return error;
    });

  return result;
};

export const addFileVisa = async (data, id) => {
  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `userVisas/addFile/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addVisa = async (data) => {
  var request = Encrypt(data);

  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `Request/addVisaRequest`, {
      request: request,
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

// export const addManagerComment = async (id, data) => {
//   const result = await api
//     .post(
//       `${process.env.REACT_APP_API_TRAVEL}Mission/addManagerComment/${id}`,
//       data
//     )
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       const errorMsg = error.message;
//       return errorMsg;
//     });

//   return result;
// };

export const updateMission = async (data, id) => {
  // var request = Encrypt(data);

  const result = await api
    .put(process.env.REACT_APP_API_TRAVEL + `Mission/update/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getVisaType = async (departure, destination) => {
  return await api
    .get(
      `${process.env.REACT_APP_API_TRAVEL}Visa/visaType/${departure}/${destination}`
    )
    .then(function (res) {
      return res.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const fetchAllCities = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_TRAVEL + `City/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchFileWithId = async (mission, fileId) => {
  return await api
    .get(
      process.env.REACT_APP_API_TRAVEL +
        `Mission/fetchFile/${mission}/${fileId}`,
      { responseType: "arraybuffer" }
    )
    .then((response) => {
      return response;
    })

    .catch((error) => {
      console.error("Erreur lors du chargement du fichier:", error);
    });
};

export const addFiles = async (data, id) => {
  const result = await api
    .post(process.env.REACT_APP_API_TRAVEL + `Mission/addFiles/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      return errorMsg;
    });

  return result;
};
