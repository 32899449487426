export default {
  "X-Point.-1": require("./Floor-1.svg").ReactComponent,
  "X-Point.01": require("./Floor1.svg").ReactComponent,
  "X-Point.02": require("./Floor2.svg").ReactComponent,
  "X-Point.03": require("./Floor3.svg").ReactComponent,
  "X-Point.04": require("./Floor1.svg").ReactComponent,
  "X-Point.05": require("./Floor1.svg").ReactComponent,
  "X-Point.06": require("./Floor1.svg").ReactComponent,
  "X-Point.07": require("./Floor1.svg").ReactComponent,
  "X-Point.08": require("./Floor1.svg").ReactComponent,
  "Arcueil.-1": require("./Floor-1.svg").ReactComponent,
  "Arcueil.01": require("./Floor1.svg").ReactComponent,
  "Arcueil.02": require("./Floor1.svg").ReactComponent,
  "Arcueil.03": require("./Floor1.svg").ReactComponent,
  "Arcueil.04": require("./Floor1.svg").ReactComponent,
  "Arcueil.05": require("./Floor1.svg").ReactComponent,
  "Arcueil.06": require("./Floor1.svg").ReactComponent,
  "Arcueil.07": require("./Floor7.svg").ReactComponent,
  "Arcueil.08": require("./Floor7.svg").ReactComponent,
};
