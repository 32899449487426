import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-tailwind/react/components/Button";
import { fetchALLUsers } from "actions/UserAction";

import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { delegateRequest } from "actions/RequestAction";

const Root = styled("div")(
  ({ theme }) => `
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    font-size: 14px;
  `
);

const InputWrapper = styled("div")(
  ({ theme }) => `
    width: 100%;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
  
    &:hover {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    }
  
    &.focused {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  
    & input {
      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
      color: ${
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
      };
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
    }
  `
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const Listbox = styled("ul")(
  ({ theme }) => `
    width: 80%;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  
    & li {
      padding: 5px 12px;
      display: flex;
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
      }
    }
  
    & li[aria-selected='true'] {
      background-color: ${
        theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"
      };
      font-weight: 600;
  
      & svg {
        color: #1890ff;
      }
    }
  
    & li[data-focus='true'] {
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
    }
  `
);
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "flex",
    top: theme.spacing(5),
    minHeight: "50vh",
  },
  dialogContent: {
    textAlign: "center",
    marginTop: "1em",
    //   padding: theme.spacing(5)
  },
  operations: {
    paddingTop: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: "center",
  },
}));

export default function PopupDeledate(props) {
  const {
    openPopup,
    setOpenPopup,
    setValidator,
    validator,
    setOpen,
    rows,
    setRows,
  } = props;
  const [users, setUsers] = useState([]);
  const classes = useStyles();

  function SortArray(x, y) {
    if (x.firstname < y.firstname) {
      return -1;
    }
    if (x.firstname > y.firstname) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const fetch = async () => {
      const users = await fetchALLUsers();
      setUsers(users.sort(SortArray));
    };
    fetch();
  }, []);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    options: users,
    getOptionLabel: (option) => option.firstname + " " + option.lastname,
  });

  useEffect(() => {
    setValidator(value);
  }, [value]);

  const handleDelegate = async () => {
    if (!validator) {
      NotificationManager.warning("Choose the validator");
      return;
    }

    if (openPopup.multiple) {
      var index = 1;
      setOpen(true);
      var arr = rows;
      for (const item of openPopup.ids) {
        const result = await delegateRequest({
          idReciever: validator._id,
          id: item,
        });

        if (result.status === 200) {
          if (result.data && result.data.status == 201) {
            NotificationManager.error(result.data.message);
          } else {
            NotificationManager.success(index + " request delegated");
            index++;
            arr = arr.filter((request) => request.id != item);
            setOpenPopup({ open: false });
          }
        } else {
          NotificationManager.error(result.message);
        }
      }
      setRows(arr);
      setOpen(false);
    } else {
      const result = await delegateRequest({
        idReciever: validator._id,
        id: openPopup.id,
      });

      if (result.status === 200) {
        if (result.data && result.data.status == 201) {
          NotificationManager.error(result.data.message);
        } else {
          NotificationManager.success(result.message);
          setOpenPopup({ open: false });
        }
      } else {
        NotificationManager.error(result.message);
      }
    }
  };

  return (
    <Dialog open={openPopup.open} classes={{ paper: classes.dialog }}>
      <DialogTitle>You delegate the request to which validator</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Root>
          <div {...getRootProps()}>
            <InputWrapper
              ref={setAnchorEl}
              className={focused ? "focused" : ""}
            >
              <input {...getInputProps()} />
            </InputWrapper>
          </div>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.firstname + " " + option.lastname}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
        </Root>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button style={{ backgroundColor: "#083985" }} onClick={handleDelegate}>
          Save
        </Button>

        <Button
          color="gray"
          onClick={() => {
            setOpenPopup({ open: false });
          }}
        >
          Cancel
        </Button>
      </DialogActions>

      <NotificationContainer />
    </Dialog>
  );
}
