import { useEffect, useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./form.css";
import { useParams } from "react-router";
import { fetchRequestByID, deleteRequest } from "../../actions/RequestAction";
import { fetchOperationsByRequest } from "../../actions/OperationAction";

import ConfirmDialog from "../ConfirmDialog";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import { fetchUserBalances } from "actions/UserAction";
import { t } from "i18next";

const color = "#294e87";

export default function Details() {
  const [loading, setloading] = useState(false);
  const [request, setRequest] = useState({});
  const [operations, setOperations] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [extraBalance, setExtraBalance] = useState();
  const [balance, setBalance] = useState();

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const { id } = useParams();

  // let history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchRequestByID(token, id);
      setRequest(result);
      const data = await fetchOperationsByRequest(token, id);
      setOperations(data);
    };
    fetch();
  }, [id]);

  useEffect(() => {
    const fetch = async () => {
      const balanceData = await fetchUserBalances(
        token,
        request?.idSender?._id
      );
      setBalance(balanceData[0]);
      setExtraBalance(balanceData[0]?.extraBalance);
    };
    request?.idSender?._id && fetch();
  }, [request?.idSender?._id]);

  const handleDelete = async (id) => {
    const result = await deleteRequest(token, id);
    setConfirmDialog({ isOpen: false });
    if (result.status === 200) {
      NotificationManager.success(result.message, "Success");
      setTimeout(() => {
        window.location.pathname = "/requests";
      }, 2000);
    } else {
      NotificationManager.error(result.message, "Error");
    }
  };
  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="w-full lg:w-10/12">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <div className="w-full text-center">
                <Typography
                  variant={isDesktop ? "h3" : "h6"}
                  style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                >
                  {request &&
                  request.name &&
                  request.name === "REMOTE_WORKING" ? (
                    <Translation message={"Exceptional WFH"} />
                  ) : request?.name === "Client site" ? (
                    <Translation message={"Third party site"} />
                  ) : request?.name === "WFH" ? (
                    <Translation message={"WFH"} />
                  ) : (
                    <Translation message={request?.name} />
                  )}{" "}
                  {/* <Translation message={"Request"} />  */}
                </Typography>
                <Typography color="gray">
                  <Translation
                    message={"Consult the details of your request."}
                  />
                </Typography>
              </div>
              <div>
                {request?.type && (
                  <div className="flex justify-center mb-5 mt-20">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Type"} />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {request?.type}
                      </Typography>
                    </div>
                  </div>
                )}

                {request?.dead && (
                  <div className="flex justify-center mb-5">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Relationship"} />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {request?.dead}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mb-5 ">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Validator"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {request &&
                        request.idReciever &&
                        request.idReciever?.firstname}{" "}
                      {request &&
                        request.idReciever &&
                        request.idReciever?.lastname}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Persons to notify"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    {request &&
                    request.UserNotif &&
                    request.UserNotif?.length === 0 ? (
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        <Translation message={"No person to notify"} />
                      </Typography>
                    ) : (
                      request &&
                      request.UserNotif &&
                      request.UserNotif?.map((user) => {
                        return (
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            {user?.firstname + " " + user?.lastname}
                          </Typography>
                        );
                      })
                    )}
                    {/* { request && request.UserNotif.map(user => {
                                            return <Typography className=" text-sm  text-gray-700 pt-1"> {user.firstname + ' ' + user.lastname}</Typography>
                                        }) } */}
                  </div>
                </div>
                <div className="flex justify-center mb-5">
                  {(request && request.name && request.name === "WFH") ||
                  request.name === "Client site" ? (
                    <>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {/* {
                                                                request && request.name && request.name === 'WFH' ? 'Days requested' : 'Period'
                                                            }
                                                             */}
                          <Translation message={"Days requested"} />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        {/* {  request && request.name && request.name === 'WFH' ? */}
                        {operations &&
                          operations.map((operation) => {
                            return (
                              <Typography className=" text-sm  text-gray-700 pt-1">
                                {" "}
                                {operation?.date
                                  .substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-") +
                                  " " +
                                  operation.timeslot}
                              </Typography>
                            );
                          })}
                        {/* : request && request.name && request.name === 'REMOTE_WORKING' ?
                                                              <Typography className=" text-sm  text-gray-700 pt-1"> From {operations[0].date_debut.substring(0,10).split('-').reverse().join('-') + ' To ' + operations[0].date_fin.substring(0,10).split('-').reverse().join('-')}</Typography>
                                                            : ''
                                                    } */}
                      </div>
                    </>
                  ) : request &&
                    request.name &&
                    request.name === "REMOTE_WORKING" ? (
                    <>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message={"Period"} />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        {operations &&
                          operations?.map((operation) => {
                            return (
                              <Typography className=" text-sm  text-gray-700 pt-1">
                                {" "}
                                <Translation message={"From"} />{" "}
                                {operation?.date_debut
                                  ?.substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-") +
                                  t(" To ") +
                                  operation.date_fin
                                    ?.substring(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("-")}
                              </Typography>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {request.name == "Leave" && (
                  <div className="flex justify-center mb-5">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Days"} />
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      {operations &&
                        operations.map((operation) => {
                          return (
                            <Typography className=" text-sm  text-gray-700 pt-1">
                              {" "}
                              From{" "}
                              {operation.date_debut
                                .substring(0, 10)
                                .split("-")
                                .reverse()
                                .join("-") +
                                " " +
                                operation.slot_debut +
                                " To " +
                                operation.date_fin
                                  .substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-") +
                                " " +
                                operation.slot_fin}
                            </Typography>
                          );
                        })}
                    </div>
                  </div>
                )}

                {request?.name == "Leave" && (
                  <>
                    {balance?.sickBalance && (
                      <div className="flex justify-center mb-5">
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message="Current Sick balance" />
                          </Typography>
                        </div>
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {balance?.sickBalance}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {balance?.LeaveBalance && (
                      <div className="flex justify-center mb-5">
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message="Current Leave balance" />
                          </Typography>
                        </div>
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {balance?.LeaveBalance}
                          </Typography>
                        </div>
                      </div>
                    )}

                    <div className="flex justify-center mb-5">
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Number of requested days" />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {operations && operations[0]?.NB_Days}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex justify-center mb-5">
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Number of counted days" />
                        </Typography>
                      </div>
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {operations &&
                            operations[0] &&
                            operations[0]?.NB_BalanceDays}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
                {request?.name === "WFH" && (
                  <>
                    <div className="flex justify-center mb-5 ">
                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          <Translation message={"Authorized WFH days"} />
                        </Typography>
                      </div>

                      <div className="w-full lg:w-4/12 px-6">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          {" "}
                          {request?.idSender?.wfhProfile?.authorizedWfhDays.map(
                            (day, index) =>
                              index ==
                              request?.idSender?.wfhProfile.authorizedWfhDays
                                .length -
                                1
                                ? t(day)
                                : t(day) + ", "
                          )}
                        </Typography>
                      </div>
                    </div>

                    {extraBalance && (
                      <div className="flex justify-center mb-5 ">
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            <Translation message={"Extra balance"} />
                          </Typography>
                        </div>

                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            {extraBalance}
                          </Typography>
                        </div>
                      </div>
                    )}
                    {request?.idSender?.partTime && (
                      <div className="flex justify-center mb-5 ">
                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            <Translation message={"Part-time"} />
                          </Typography>
                        </div>

                        <div className="w-full lg:w-4/12 px-6">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            {" "}
                            {request?.idSender?.partTime}%
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {request.name == "Client site" && (
                  <div className="flex justify-center mb-5 ">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        <Translation message={"Site"} />
                      </Typography>
                    </div>

                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        {request?.client}{" "}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mb-5">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      <Translation message={"Created at"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      {request &&
                        request.createdAt &&
                        request?.createdAt
                          .substring(0, 10)
                          .split("-")
                          .reverse()
                          .join("-") +
                          " " +
                          request.createdAt?.substring(11, 16)}{" "}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5 ">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      <Translation message={"Status of request"} />
                    </Typography>
                  </div>
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      {request &&
                        request.status &&
                        (request.status === "accepted" ? (
                          <Translation message={"Approved"} />
                        ) : request.status === "refused" ? (
                          <Translation message={"Rejected"} />
                        ) : request.status === "pending" ? (
                          <Translation message={"Pending"} />
                        ) : (
                          request.status
                        ))}{" "}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-5 ">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      <Translation message={"My Comment"} />
                    </Typography>
                  </div>
                  {request && request.commentUser ? (
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        {request.commentUser}{" "}
                      </Typography>
                    </div>
                  ) : (
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        <Translation message={"No comment"} />{" "}
                      </Typography>
                    </div>
                  )}
                </div>

                <div className="flex justify-center mb-5 ">
                  <div className="w-full lg:w-4/12 px-6">
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      <Translation message={"Validator's comment"} />
                    </Typography>
                  </div>
                  {request && request.commentManager ? (
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        {request.commentManager}{" "}
                      </Typography>
                    </div>
                  ) : (
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {" "}
                        <Translation message={"No comment"} />{" "}
                      </Typography>
                    </div>
                  )}
                </div>

                <div className="flex justify-center mt-20">
                  <Button
                    color="indigo"
                    ripple="light"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: (
                          <Translation
                            message={"Are you sure to delete  this request ?"}
                          />
                        ),
                        subtitle: (
                          <Translation
                            message={"You can't undo this operation !"}
                          />
                        ),
                        onConfirm: () => {
                          handleDelete(id);
                        },
                      });
                    }}
                  >
                    <ClipLoader color="white" loading={loading} size={20} />
                    <Translation message={"Delete Request"} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        token={token}
      />

      <NotificationContainer />
    </>
  );
}
