import React, { useEffect, useState } from "react";

import {
  fetchUser,
  fetchNbrReservations,
  fetchNbrWfhs,
  fetchUserBalances,
} from "actions/UserAction";
import jwt from "jwt-decode";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Hail } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Icon from "@material-tailwind/react";

import moment from "moment";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";

export default function ClientSiteTab(props) {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const decodedToken = jwt(token);
  const { data } = props;
  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <div className="mb-10 py-2 border-t border-gray-200">
      <div className="flex flex-wrap ">
        <div className="w-full lg:w-12/12 px-4 flex flex-col ">
          <div className="mt-20">
            <Timeline
              align="left"
              style={{
                marginLeft: isDesktop ? 0 : "-50%",
                // position: "fixed",
                // left: -20,
              }}
            >
              {data?.map((item) => {
                return (
                  <TimelineItem>
                    <TimelineOppositeContent
                      style={{ flex: 0.1 }}
                      sx={{ m: "auto 0" }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      {moment(item.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: "#b741c4" }}>
                        <Hail />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <div className="content_timeline">
                        <div>
                          <Typography>
                            <b><Translation message={"Third party site"} /></b>
                          </Typography>
                          <Typography color="text.secondary">
                            {/* <Icon color="gray" name="person" />{" "} */}
                            <i className="far fa-user mr-2" />
                            {item.Request?.idReciever?.firstname}{" "}
                            {item.Request?.idReciever?.lastname}
                          </Typography>
                          <Typography color="text.secondary">
                            {/* <Icon color="gray" name="event" />{" "} */}
                            <i className="far fa-calendar mr-2" />
                            {item.Request?.dates?.map((date) => {
                              return (
                                date?.day
                                  ?.substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-") +
                                " " +
                                date?.slot +
                                ", "
                              );
                            })}
                          </Typography>
                          {/* <Typography color="text.secondary">
                            <Icon color="gray" name="location_on" />{" "}
                            {item.Request?.client}{" "}
                          </Typography> */}
                          <Typography color="text.secondary">
                            <i className="fas fa-map-pin mr-2" />
                            {item.Request?.client}{" "}
                          </Typography>
                          {!isDesktop && (
                            <Typography color="text.secondary">
                              <Translation message={"Sent: "} />{" "}
                              {moment(item.updatedAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          )}
                          {item.isSystemOp == true && (
                            <Typography color="text.secondary">
                              <Translation message={"System operation"} />
                            </Typography>
                          )}
                        </div>

                        <div className="footer-right">
                          {item.TransactionType ==
                          "CLIENT_SITE_CANCELLATION" ? (
                            <Typography fontSize={14} color="red">
                               <Translation message={"Cancellation"} />
                            </Typography>
                          ) : item.TransactionType ==
                            "CLIENT_SITE_SUBMISSION" ? (
                            <Typography fontSize={14} color="orange">
                              <Translation message={"Submission"} />
                            </Typography>
                          ) : item.TransactionType ==
                            "CLIENT_SITE_REVIEWING" ? (
                            <Typography fontSize={14} color="orange">
                              <Translation message={"Edition"} />
                            </Typography>
                          ) : item.TransactionType == "CLIENT_SITE_APPROVAL" ? (
                            <Typography fontSize={14} color="green">
                              <Translation message={"Approval"} />
                            </Typography>
                          ) : item.TransactionType ==
                            "CLIENT_SITE_REJECTION" ? (
                            <Typography fontSize={14} color="red">
                              <Translation message={"Rejection"} />
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
}
