import Navbar from "components/Navbars/Navbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import Header from "components/History/Header";
import Content from "components/History/Content";

export default function History() {
  return (
    <>
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <Navbar />
      </div>
      <main>
        <Header />
        <Content />
      </main>
      <DefaultFooter />
    </>
  );
}
