import api from "./api";

const url = process.env.REACT_APP_API_URL + `Setting/`;
//const url1 = process.env.REACT_APP_API_URL_PREP + `Setting/`;

export const fetchSetting = async (name) => {
  const result = await api
    .get(url + `getSettingsName/${name}`)
    .then(async (response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

//export const fetchSetting1 = async (name) => {
//  const result = await api
//    .get(url1 + `getSettingsName/${name}`)
//    .then(async (response) => {
//      return response.data.data;
//    })
//    .catch((error) => {
//      const errorMsg = error.message;
//      return errorMsg;
//    });

//  return result;
//};

