import Schedule from "./Schedule";

export default function ContactSection() {
  return (
    // <section className="pb-20 relative block bg-gray-100">
    <div className=" lg:pt-24">
      <Schedule />
    </div>
    // </section>
  );
}
