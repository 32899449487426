import React, { useState, useEffect, useRef } from "react";
import jwt from "jwt-decode";
import openSocket, { Manager } from "socket.io-client";
// import { Tooltip } from "react-svg-tooltip";
import { fetchAccesszone } from "actions/ZoneAction";

import moment from "moment";
import Legend from "components/Reservation/Legend";
import Swal from "sweetalert2";

import { addReservations, addReservation } from "actions/OperationAction";
// import FloorPlan7 from "./FloorPlan7";
import { checkAvailibility } from "actions/OperationAction";
import useMediaQuery from "useMediaQuery";
import { Space } from "react-zoomable-ui";
import { Tooltip, ThemeProvider } from "@material-tailwind/react";
import Popup from "./Popup";
import { handleLock } from "actions/RessourcesAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import floorPlans from "../Reservation/Plans/floorPlans";
import Error403Img from "../../assets/img/404.svg";
import { ClipLoader } from "react-spinners";

// const socket = openSocket(process.env.REACT_APP_SOCKET_URL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });

// var socket = openSocket(process.env.REACT_APP_SOCKET_URL, {
//   transports: ["websocket"],
// });

var socket;

export default function Floor({
  start,
  end,
  floor,
  setGuestErrors,
  guestReservation,
}) {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const [desks, setDesks] = useState();

  const [FloorPlanComponent, setFloorPlanComponent] = useState("");
  const [loading, setLoading] = useState(true);

  // const [date, setDate] = useState(
  //   moment(selectedDate).format().substring(0, 10)
  // );

  const [zone, setZone] = useState([]);
  const [openPopup, setOpenPopup] = React.useState({ open: false });
  const [AM, setAM] = useState(200);
  const [PM, setPM] = useState(200);
  const refAm = useRef(null);
  const refPm = useRef(null);
  const [socketId, setSocketId] = useState(null);

  useEffect(() => {
    setLoading(true);
    const FloorPlan =
      floorPlans[`${floor?.building?.address}.${floor.floor_num}`];

    if (FloorPlan) {
      setFloorPlanComponent(() => FloorPlan);
    } else {
      setFloorPlanComponent(null);
      console.error(`No floor plan found for floor number: ${floor.floor_num}`);
    }

    setLoading(false);
  }, [floor.floor_num]);

  // useEffect(() => {
  //   socket = openSocket(process.env.REACT_APP_SOCKET_URL, {
  //     transports: ["websocket"],
  //   });
  // }, []);

  // useEffect(() => {
  //   socket = openSocket(process.env.REACT_APP_SOCKET_URL, {
  //     transports: ["websocket"],
  //   });

  //   socket.on("connect", () => {
  //     setSocketId(socket.id);
  //     console.log("Connected to socket with ID:", socket.id, num);
  //   });

  //   // return () => {
  //   //   newSocket.disconnect();
  //   // };
  // }, []);

  const socketRef = useRef();
  const credentials = btoa(
    `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
  );
  useEffect(() => {
    // Si la socket existe déjà, ne pas la recréer
    if (socketRef.current) {
      socketRef.current.emit(`search_desks_User_F${floor?.floor_num}`, {
        startDate: start,
        endDate: end,
        zone: zone,
        socketId: socketRef.current.id,
      });
      return;
    }

    // const newSocket = openSocket(process.env.REACT_APP_SOCKET_URL, {
    //   transports: ["websocket"],
    //   extraHeaders: {
    //     Authorization: `Basic ${credentials}`,
    //   },
    // });

    const newSocket = openSocket(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });

    // const manager = new Manager(process.env.REACT_APP_SOCKET_URL, {
    //   reconnectionDelayMax: 10000,
    //   extraHeaders: {
    //     authorization: `Basic ${credentials}`,
    //   },
    // });
    // const newSocket = manager.socket("/", {
    //   // auth: {
    //   //   token: credentials,
    //   // },
    // });

    socketRef.current = newSocket;

    newSocket.on("connect", () => {
      setSocketId(newSocket.id);

      // Émettre un événement pour demander les positions pour l'étage actuel
      newSocket.emit(`search_desks_User_F${floor?.floor_num}`, {
        startDate: start,
        endDate: end,
        zone: zone,
        socketId: newSocket.id,
      });
      // // Écouter les données pour l'étage actuel
      // newSocket.on(`get_search_desks_User_F${num}`, getData);
      // newSocket.on(`change_search_data_User_F${num}`, changeData);
    });

    // Nettoyer la socket lorsque le composant est démonté
    // return () => {
    //   newSocket.disconnect();
    //   // Désabonner les écouteurs d'événements
    //   newSocket.off(`get_search_desks_User_F${num}`);
    //   newSocket.off(`change_search_data_User_F${num}`);
    // };
  }, [start, end, zone]);

  // useEffect(() => {
  //   setDate(moment(selectedDate).format().substring(0, 10));
  // }, [selectedDate]);

  // console.log("desks", desks.length);

  const getData = (desks_data) => {
    // console.log(
    //   `Received data for floor ${num}:`,
    //   desks_data.socketId,
    //   socketId
    // );

    if (
      // desks_data.socketId === socketId &&
      desks_data.startDate === start &&
      desks_data.endDate === end
      //  &&
      // desks_data.data?.length != 0
    ) {
      setDesks(desks_data.data);
      console.log("Updated desks state:", desks_data.data);
    }
    // else {
    //   console.log("");
    // }
    // if (desks_data.length == 0) {
    //   socket.emit("search_desks_User_F7", {
    //     startDate: start,
    //     endDate: end,
    //     zone: zone,
    //   });
    // } else {
    //   setDesks(desks_data);
    // }
  };
  // const changeData = () => {
  //   socket.emit("initial_data_User_F1",  {date: date ,zone : zone});
  // }

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on(
        `get_search_desks_User_F${floor.floor_num}`,
        getData
      );
      socketRef.current.on(
        `change_search_data_User_F${floor.floor_num}`,
        changeData
      );

      // // Nettoyer la socket lorsque le composant est démonté
      // return () => {
      //   // Désabonner les écouteurs d'événements
      //   socketRef.current.off(`get_search_desks_User_F${floor.floor_num}`);
      //   socketRef.current.off(`change_search_data_User_F${floor.floor_num}`);
      // };
    }
  }, [zone, start, end, socketId]);

  const changeData = (data) => {
    // if (date == date_change) {
    // socket.emit("get_search_desks_User_F7", {
    //   startDate: start,
    //   endDate: end,
    //   zone: zone,
    // });
    if (
      moment(data.date).format() >= moment(start).format() &&
      moment(data.date).format() <= moment(end).format()
    ) {
      socketRef.current.emit(`search_desks_User_F${floor.floor_num}`, {
        startDate: start,
        endDate: end,
        zone: zone,
        socketId: socketRef.current.id,
      });

      // if (data.status != "AVAILABLE") {
      //   console.log(
      //     "na7iweh desk",
      //     data.desk,
      //     data.status,
      //     desks.filter((item) => item.Desk._id == data.desk)
      //   );
      //   socket.emit("search_desks_User_F7", {
      //     startDate: start,
      //     endDate: end,
      //     zone: zone,
      //   });

      //   // desks.map(
      //   //   (item) =>
      //   //     item.Desk._id == data.desk &&
      //   //     console.log("item.Desk._id", item.Desk._id)
      //   // );

      //   // setDesks(desks.filter((item) => item.Desk._id != data.desk));
      // }
      // console.log(desks);
    }
    // }
  };

  // console.log("socket", socket, socketId);
  // console.log("zone", zone.length);

  // useEffect(() => {
  //   // console.log(start, end, socketId);
  //   if (zone.length != 0) {
  //     socket.emit(`search_desks_User_F${floor.floor_num}`, {
  //       startDate: start,
  //       endDate: end,
  //       zone: zone,
  //       socketId: socketId,
  //     });
  //     socket.on(`get_search_desks_User_F${floor.floor_num}`, getData);
  //     socket.on(`change_search_data_User_F${floor.floor_num}`, changeData);
  //   }
  // }, [zone, start, end, socketId]);

  useEffect(() => {
    const zoneaccess = async () => {
      const result = await fetchAccesszone(token, user.id);
      var res = result.filter((e) => e.floor_num == floor.floor_num);
      let data = Array.from(res, ({ zone_id }) => zone_id);
      setZone(data);
    };
    zoneaccess();
  }, []);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    const fetch = async (startDate, endDate) => {
      var start = moment(startDate).startOf("day");
      var end = moment(endDate).startOf("day");

      var arr = [];
      arr.push(start.format().toString().substring(0, 10));
      while (start.add(1, "days").diff(end) < 0) {
        var d = start.clone().format();
        arr.push(d.toString().substring(0, 10));
      }
      arr.push(end.format().toString().substring(0, 10));
      for (var date of arr) {
        var res = await checkAvailibility(user.id, date, "AM", "Desk");
        if (res.AM != 200) {
          setAM(res.AM);
        }
        if (res.PM != 200) {
          setPM(res.PM);
        }
      }

      // var res = await checkAvailibility(user.id, date, "AM", "Desk");
      // // var resPM = await checkAvailibility(user.id, date, "PM", "Desk")
      // setAM(res.AM);
      // setPM(res.PM);
    };
    fetch(start, end);
  }, [start, end]);

  // console.log("guestReservation", guestReservation);

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleLockRessource = async (desk) => {
    if (guestReservation.book == true) {
      if (guestReservation.name?.length == 0) {
        setGuestErrors({ name: "required field" });
        return;
      }
      if (guestReservation.email?.length == 0) {
        setGuestErrors({ email: "required field" });
        return;
      }
      if (!isValidEmail(guestReservation.email)) {
        setGuestErrors({ email: "invalid email format" });
        return;
      }
    }
    // if (
    //   (AM == 201 && PM == 201) ||
    //   (PM == 201 &&
    //     isToday(new Date(start)) == true &&
    //     moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") == "PM")
    // ) {
    //   NotificationManager.error("Booking is not allowed");
    // } else {
    //   var res = await handleLock(desk._id, start, end);
    //   // setOpenPopup({ open: true, desk: desk });
    //   if (res.status == 201) {
    //     NotificationManager.error(res.message);
    //   } else {
    //     setOpenPopup({ open: true, desk: desk });
    //   }
    // }
    if (
      // (AM == 201 && PM == 201) ||
      PM == 201 &&
      isToday(new Date(start)) == true &&
      moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") == "PM"
    ) {
      NotificationManager.error("Booking is not allowed");
    } else {
      var res = await handleLock(desk._id, start, end);
      // setOpenPopup({ open: true, desk: desk });
      if (res.status == 201) {
        NotificationManager.error(res.message);
      } else {
        setOpenPopup({ open: true, desk: desk });
      }
    }
  };

  // useEffect(() => {
  //   if (openPopup.open == true) {
  //     var text = "";
  //     var allowed = true;

  //     if (
  //       (AM == 201 && PM == 201) ||
  //       (PM == 201 &&
  //         isToday(start) == true &&
  //         moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") == "PM")
  //     ) {
  //       text = "Booking is not allowed";
  //       allowed = false;
  //     } else {
  //       text =
  //         "From " +
  //         start.split("-").reverse().join("-") +
  //         " to " +
  //         end.split("-").reverse().join("-");
  //     }

  //     var htmlDiv = "<p>" + text + "</p><br/>";

  //     Swal.fire({
  //       title: openPopup.desk.Desk.name,
  //       // text: "Choose your time slot",
  //       icon: "info",
  //       html: htmlDiv,

  //       // showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "BOOK",
  //       showConfirmButton: allowed,
  //       // preConfirm: () => {
  //       //   var slotAM = document.getElementById("checkboxAM").checked;
  //       //   var slotPM = document.getElementById("checkboxPM").checked;
  //       //   var slotFULL = document.getElementById("checkboxFULL").checked;
  //       //   if (!slotAM && !slotPM && !slotFULL) {
  //       //     Swal.showValidationMessage(`You need to choose a time slot!`);
  //       //   }
  //       // },
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         var data = {
  //           user: user.id,
  //           desk: openPopup.desk.Desk._id,
  //           start: start,
  //           end: end,
  //         };
  //         const result = await addReservation(data);
  //         if (result.status == 200) {
  //           if (result.data && result.data.status == 201) {
  //             Swal.fire({
  //               icon: "error",
  //               text: result.data.message,
  //               confirmButtonText: "Close",
  //               confirmButtonColor: "#5e72e4",
  //             });
  //           } else {
  //             Swal.fire({
  //               icon: "success",
  //               text: result.message,
  //               confirmButtonText: "Close",
  //               confirmButtonColor: "#5e72e4",
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             text: result.message,
  //             confirmButtonText: "Close",
  //             confirmButtonColor: "#5e72e4",
  //           });
  //         }
  //       }
  //     });
  //   }
  // }, [openPopup]);

  // useEffect(() => {
  //   if (openPopup.open == true) {
  //     if (
  //       AM == 200 &&
  //       PM == 200 &&
  //       isToday(start) == true &&
  //       moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") != "PM"
  //     ) {
  //       handleLock(openPopup.desk.Desk._id);
  //     }
  //   }
  // }, [openPopup]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const theme = {
    tooltip: {
      styles: {
        base: {
          bg: "bg-white",
          color: "text-black",
        },
      },
    },
  };

  return (
    <>
      {loading ? (
        <ClipLoader color="white" loading={loading} size={20} />
      ) : FloorPlanComponent ? (
        <ThemeProvider value={theme}>
          <Legend className="center-legend" />
          {isDesktop ? (
            <svg
              className="plan mt-10 ml-20"
              viewBox="0 0 1500 1200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="transparent"
              strokeWidth={0}
              style={{ outline: "none" }}
            >
              <FloorPlanComponent />
              {desks &&
                desks.map((desk) => {
                  return (
                    <>
                      {desk.statusAM === "AVAILABLE" ? (
                        <>
                          <Tooltip
                            content={
                              desk.Desk.name + " / " + desk.Desk.zone.name
                            }
                            animate={{
                              mount: { scale: 1, y: 0 },
                              unmount: { scale: 0, y: 25 },
                            }}
                          >
                            <path
                              className="path"
                              fill="#14e39a"
                              ref={refAm}
                              d={desk.Desk.coordinations_web_am}
                              onClick={() => {
                                handleLockRessource(desk.Desk);
                              }}
                            ></path>
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}

                      {desk.statusPM === "AVAILABLE" ? (
                        <>
                          <Tooltip
                            content={
                              desk.Desk.name + " / " + desk.Desk.zone.name
                            }
                            animate={{
                              mount: { scale: 1, y: 0 },
                              unmount: { scale: 0, y: 25 },
                            }}
                          >
                            <path
                              className="path"
                              ref={refPm}
                              fill="#14e39a"
                              d={desk.Desk.coordinations_web_pm}
                              onClick={() => handleLockRessource(desk.Desk)}
                            ></path>
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </svg>
          ) : (
            <div style={{ width: "100%", height: 600, position: "relative" }}>
              <Space
                onCreate={(vp) => vp.setBounds({ x: [0, 600], y: [0, 600] })}
              >
                <svg
                  className="plan"
                  viewBox="0 0 1400 1000"
                  // height="400"
                  // width="400"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="transparent"
                  strokeWidth={0}
                  style={{ outline: "none" }}
                >
                  <FloorPlanComponent />
                  {desks &&
                    desks.map((desk) => {
                      return (
                        <>
                          {desk.statusAM === "AVAILABLE" ? (
                            <>
                              <path
                                className="path"
                                fill="#14e39a"
                                ref={refAm}
                                d={desk.Desk.coordinations_web_am}
                                onClick={() => handleLockRessource(desk.Desk)}
                              ></path>
                            </>
                          ) : (
                            ""
                          )}

                          {desk.statusPM === "AVAILABLE" ? (
                            <>
                              <path
                                className="path"
                                ref={refPm}
                                fill="#14e39a"
                                d={desk.Desk.coordinations_web_pm}
                                onClick={() => handleLockRessource(desk.Desk)}
                              ></path>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                </svg>
              </Space>
            </div>
          )}
          {openPopup.open && (
            <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              AM={AM}
              PM={PM}
              start={start}
              end={end}
              guestReservation={guestReservation}
            />
          )}
          <NotificationContainer />
        </ThemeProvider>
      ) : (
        // "Erreur lors de chargement de l'etage"
        <center>
          <img src={Error403Img} width={"30%"} />
        </center>
      )}
    </>
  );
}
