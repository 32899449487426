import * as React from "react";
// import { Document, Page } from "react-pdf";
// import { Viewer } from "@react-pdf-viewer/core";
// import FileViewer from "react-file-viewer";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import Translation from "Translation";

export default function Viewer({
  fileData,
  view,
  setView,
  fileType,
  isBill,
  previewUrl,
  download,
}) {
  const handleClose = () => {
    setView(false);
  };

  const handleDownload = (name) => {
    const fileBlob = new Blob([fileData]);
    const fileURL = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = `${name}.${fileType}`;
    a.click();
  };

  const [base64Image, setBase64Image] = React.useState(null);

  React.useEffect(() => {
    let binary = "";
    const bytes = new Uint8Array(fileData);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64Data = window.btoa(binary);
    setBase64Image(base64Data);
  }, [fileData]);

  return (
    <div>
      <Dialog
        open={view}
        onClose={handleClose}
        handler={() => {
          handleClose();
        }}
        size="xl"
      >
        <DialogBody>
          <center>
            {fileType == "pdf" ? (
              //   <FileViewer
              //     fileType={fileType}
              //     filePath={fileData}
              //     // fileBlob={fileData}
              //     // filePath=""

              //     // errorComponent={CustomErrorComponent}
              //     // onError={onError}
              //   />
              <iframe
                src={fileData}
                style={{ width: "100%", height: "600px" }}
              />
            ) : (
              // file &&
              // Array.isArray(file) && (
              //   <img
              //     src={`data:image/jpeg;base64,${window.btoa(
              //       String.fromCharCode.apply(null, file)
              //     )}`}
              //     alt="Image"
              //   />
              // )

              base64Image && (
                <img
                  src={`data:image/jpeg;base64,${base64Image}`}
                  alt="Image"
                />
              )
              // <img src={`data:image/jpeg;base64,${btoa(fileData)}`} alt="Image" />

              // <img
              //   // ref={elementRef}
              //   src={`data:image/png;base64,${fileData}`}
              //   alt="Image"
              //   // onClick={enterFullscreen}
              //   style={{ cursor: 'pointer' }}
              // />
            )}
          </center>

          {/* {previewUrl ? (
            <div>
              {fileType.startsWith("image/") ? (
                <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
              ) : (
                <embed
                  src={previewUrl}
                  type="application/pdf"
                  width="900"
                  height="900"
                />
              )}
            </div>
          ) : isBill ? (
            fileType.startsWith("image/") || fileType != "pdf" ? (
              <img
                // ref={elementRef}
                src={`data:image/png;base64,${fileData}`}
                alt="Image"
                // onClick={enterFullscreen}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <embed
                // ref={elementRef}
                src={`data:application/pdf;base64,${fileData}`}
                type="application/pdf"
                width="900"
                height="900"
                // onClick={enterFullscreen}
                style={{ cursor: "pointer" }}
              />
            )
          ) : fileType == "pdf" ? (
            <FileViewer
              fileType={fileType}
              filePath={fileData}
              // errorComponent={CustomErrorComponent}
              // onError={onError}
            />
          ) : (
            <img
              src={`data:image/jpeg;base64,${window.btoa(
                String.fromCharCode.apply(null, fileData)
              )}`}
              alt="Image"
            />
          )} */}
        </DialogBody>
        <DialogFooter>
          {download && (
            <Button
              style={{ outline: "none" }}
              onClick={() => {
                handleDownload("file");
              }}
            >
              <Translation message="Download" />
            </Button>
          )}
          <Button variant="text" color="indigo" onClick={handleClose}>
            <Translation message="Close" />
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}
