// import {
//   HomeIcon,
//   CalendarIcon,
//   DocumentDuplicateIcon,
//   UserIcon,
//   DocumentTextIcon,
//   ExclamationCircleIcon,
//   UsersIcon,
//   NewspaperIcon,
//   LogoutIcon,
// } from "@heroicons/react/solid";

export const routes = [
  {
    // icon: HomeIcon,
    icon: "fas fa-home",
    name: "home",
    path: "/home",
    // element: <Home />,
  },
  {
    // icon: CalendarIcon,
    name: "My schedule",
    icon: "fas fa-calendar",
    path: "/schedule",
    // element: <Schedule />,
  },
  {
    // icon: DocumentDuplicateIcon,
    name: "Requests",
    icon: "fas fa-file",
    parent: true,
    childs: [
      {
        // icon: DocumentTextIcon,
        // icon: "fas fa-file",
        name: "Flex requests",
        path: "/requests",
      },
      {
        name: "My reservations",
        path: "/reservationsList",
      },
      {
        name: "Business travel requests",
        path: "/missions/#/pending",
      },
      {
        // icon: ExclamationCircleIcon,
        // icon: "fas fa-info",
        name: "My support requests",
        path: "/claims",
      },
    ],
    // path: "/sign-in",
    // element: <SignIn />,
  },

  {
    // icon: UsersIcon,
    name: "My team",
    icon: "fas fa-users",
    parent: true,
    childs: [
      {
        // icon: CalendarIcon,
        // icon: "fas fa-calendar",
        name: "Team's schedule",
        path: "/team",
      },
      {
        // icon: DocumentTextIcon,
        // icon: "fas fa-file",
        name: "Team's requests",
        path: "/teamRequests",
      },

      {
        // icon: DocumentTextIcon,
        // icon: "fas fa-file",
        name: "Team members",
        path: "/teamMembers",
      },
    ],
    isManager: true,
  },
  {
    // icon: UserIcon,
    icon: "fas fa-user",
    name: "Profile",
    parent: true,
    childs: [
      {
        // icon: UserIcon,
        name: "Profile",
        path: "/profile",
      },
      {
        // icon: NewspaperIcon,
        name: "History",
        path: "/history",
      },
      {
        icon: "fas fa-right-from-bracket",
        // icon: LogoutIcon,
        name: "Logout",
        // path: "/history",
      },
    ],
    // path: "/sign-in",
    // element: <SignIn />,
  },
  // {
  //   icon: UserIcon,
  //   name: "Sign Up",
  //   path: "/sign-up",
  //   element: <SignUp />,
  // },
  {
    // icon: DocumentTextIcon,
    name: "Docs",
    href: "https://www.material-tailwind.com/docs/react/installation",
    target: "_blank",
    element: "",
  },
];

export default routes;
