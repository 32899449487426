import React, { useEffect, useState } from "react";
import "./form.css";
import Building from "./Building";
import jwt from "jwt-decode";
import { Breadcrumbs, Carousel, Typography } from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import { Space } from "react-zoomable-ui";
import { fetchBuilding } from "../../actions/BuildingAction";
import { ClipLoader } from "react-spinners";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Home, PersonSearch, WhereToVote } from "@mui/icons-material";
import Scan from "pages/Scan";
import Search from "./Search";
import { Link } from "react-router-dom";
import Translation from "Translation";
import { useTranslation } from "react-i18next";

const color = "#083985";

const actions = [
  {
    icon: <PersonSearch />,
    name: "Find a colleague",
    color: "#2ec6ff",
    type: "findColleage",
  },

  {
    icon: <WhereToVote />,
    name: "Check-In/Out",
    color: "gray",
    type: "checkin",
  },
];

export default function Requests() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const { t } = useTranslation();
  // var [floor, setFloors] = useState([]);
  var [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleOpenSearch = () => setSearch(!search);

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchBuilding(token);
      setBuildings(result);
      // console.log("result", result);
      // let data = Array.from(result, ({ Floors }) => Floors);
      // const sorted = data[0].sort((a, b) =>
      //   a.floor_num > b.floor_num ? 1 : b.floor_num > a.floor_num ? -1 : 0
      // );
      // setFloors(sorted);
      setLoading(false);
    };
    fetch();
  }, []);

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <Link to="/reservation" className="opacity-60">
                  <span>Flex Office</span>
                </Link>
                {/* <a href="#">Building </a> */}
              </Breadcrumbs>
            </div>
          )}
          <div className="w-full lg:w-12/12 px-4">
            <div className="w-full text-center mt-20">
              {/* <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} /> */}

              <Typography
                variant={isDesktop ? "h3" : "h6"}
                style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
              >
                <Translation message={"Are you planning to work on site?"} />
              </Typography>
              <Typography color="gray">
                <Translation message={"Choose the floor to book your desk."} />
              </Typography>
              {/* <div>
                <Box
                  sx={{
                    height: 80,
                    transform: "translateZ(0px)",
                    flexGrow: 0,
                  }}
                >
                  <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: "absolute", right: 40 }}
                    icon={<SpeedDialIcon />}
                    // color='red'
                    // ButtonProps={{ color: "secondary" }}
                    FabProps={{
                      size: "large",
                      style: { backgroundColor: "#136bc2", outline: "none" },
                    }}
                    direction={"down"}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          action.type == "checkin"
                            ? setOpen(true)
                            : setSearch(true);
                        }}
                        // onClick={() => {
                        //   window.location.pathname = action.href;
                        // }}
                        // onClick={() => {
                        //   navigate(action.href);
                        // }}
                        FabProps={{
                          style: { color: action.color, outline: "none" },
                        }}
                      />
                    ))}
                  </SpeedDial>
                </Box>
              </div> */}

              {/* <Building selectedDate={selectedDate} /> */}
              {/* {buildings.map((building, i) => {
                const sorted = building.Floors.sort((a, b) =>
                  a.floor_num > b.floor_num
                    ? 1
                    : b.floor_num > a.floor_num
                    ? -1
                    : 0
                );
                return (
                  <div className="block w-full">
                    <Building floor={sorted} />
                  </div>
                );
              })} */}
              {isDesktop ? (
                <>
                  <div style={{ marginBottom: "50px" }} className="z-[99999]">
                    <Box
                      sx={{
                        height: 80,
                        transform: "translateZ(0px)",
                        flexGrow: 0,
                      }}
                      className="z-[99999]"
                    >
                      <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: "absolute", right: 40 }}
                        icon={<SpeedDialIcon />}
                        // color='red'
                        // ButtonProps={{ color: "secondary" }}
                        FabProps={{
                          size: "large",
                          style: {
                            backgroundColor: "#136bc2",
                            outline: "none",
                          },
                        }}
                        direction={"left"}
                        className="z-[99999]"
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={t(action.name)}
                            onClick={() => {
                              action.type == "checkin"
                                ? setOpen(true)
                                : setSearch(true);
                            }}
                            // onClick={() => {
                            //   window.location.pathname = action.href;
                            // }}
                            // onClick={() => {
                            //   navigate(action.href);
                            // }}
                            FabProps={{
                              style: { color: action.color, outline: "none" },
                            }}
                          />
                        ))}
                      </SpeedDial>
                    </Box>
                  </div>
                  <div className="flex flex-wrap justify-center  gap-4 mb-6 -mt-20">
                    {loading ? (
                      <ClipLoader color="blue" loading={loading} size={50} />
                    ) : (
                      // <div
                      //   id="carouselExampleCaptions"
                      //   className="carousel slide relative"
                      //   // data-bs-ride="carousel"
                      // >
                      //   <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                      //     {buildings.map((building, i) => {
                      //       return (
                      //         <button
                      //           type="button"
                      //           data-bs-target="#carouselExampleCaptions"
                      //           data-bs-slide-to={i}
                      //           className={`${i == 0 && "active"}`}
                      //           aria-current="true"
                      //           aria-label={building.address}
                      //           style={{ backgroundColor: "#083985" }}
                      //         ></button>
                      //       );
                      //     })}
                      //   </div>
                      //   <div className="carousel-inner relative w-full overflow-hidden">
                      //     {buildings.map((building, i) => {
                      //       const sorted = building.Floors.sort((a, b) =>
                      //         a.floor_num > b.floor_num
                      //           ? 1
                      //           : b.floor_num > a.floor_num
                      //           ? -1
                      //           : 0
                      //       );
                      //       return (
                      //         <div
                      //           className={`carousel-item ${
                      //             i == 0 ? "active" : ""
                      //           } relative float-left w-full`}
                      //         >
                      //           <div className="block w-full">
                      //             <Building floor={sorted} />
                      //           </div>
                      //           <div className="carousel-caption hidden md:block absolute text-center">
                      //             <h5
                      //               style={{ color: "#083985" }}
                      //               className="text-xl"
                      //             >
                      //               {building.address}
                      //             </h5>
                      //           </div>
                      //         </div>
                      //       );
                      //     })}
                      //   </div>
                      //   <button
                      //     className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline "
                      //     type="button"
                      //     // data-bs-target="#carouselExampleCaptions"
                      //     // data-bs-slide="prev"
                      //     style={{
                      //       marginRight: "100px",
                      //       width: "50px",
                      //     }}
                      //   >
                      //     <span
                      //       className="carousel-control-prev-icon inline-block bg-no-repeat"
                      //       aria-hidden="true"
                      //       data-bs-target="#carouselExampleCaptions"
                      //       data-bs-slide="prev"
                      //       style={{
                      //         backgroundColor: "#083985",
                      //       }}
                      //     ></span>
                      //     <span class="visually-hidden">Previous</span>
                      //   </button>
                      //   <button
                      //     class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                      //     type="button"
                      //     // data-bs-target="#carouselExampleCaptions"
                      //     // data-bs-slide="next"
                      //     style={{
                      //       marginLeft: "100px",
                      //       width: "50px",
                      //     }}
                      //   >
                      //     <span
                      //       class="carousel-control-next-icon inline-block bg-no-repeat"
                      //       aria-hidden="true"
                      //       style={{
                      //         backgroundColor: "#083985",
                      //       }}
                      //       data-bs-target="#carouselExampleCaptions"
                      //       data-bs-slide="next"
                      //     ></span>
                      //     <span class="visually-hidden">Next</span>
                      //   </button>
                      //   {/* <SpeedDial
                      //   ariaLabel="SpeedDial basic example"
                      //   // sx={{ position: "absolute", bottom: 30, right: 40 }}
                      //   icon={<WhereToVote />}
                      //   FabProps={{
                      //     size: "large",
                      //     style: {
                      //       backgroundColor: "#136bc2",
                      //       outline: "none",
                      //       position: "absolute",
                      //       bottom: 30,
                      //       right: 40,
                      //     },
                      //   }}
                      //   onClick={() => {
                      //     setOpen(true);
                      //   }}
                      // ></SpeedDial> */}
                      // </div>
                      <Carousel
                        className="rounded-xl"
                        navigation={({
                          setActiveIndex,
                          activeIndex,
                          length,
                        }) => (
                          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                              <span
                                key={i}
                                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                  activeIndex === i
                                    ? "bg-white w-8"
                                    : "bg-white/50 w-4"
                                }`}
                                onClick={() => setActiveIndex(i)}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {buildings.map((building, i) => {
                          const sorted = building.Floors.sort((a, b) =>
                            a.floor_num > b.floor_num
                              ? 1
                              : b.floor_num > a.floor_num
                              ? -1
                              : 0
                          );
                          return (
                            // <div
                            //   className={`carousel-item ${
                            //     i == 0 ? "active" : ""
                            //   } relative float-left w-full`}
                            // >
                            //   <div className="block w-full">
                            <div className="relative h-full w-full">
                              <Building floor={sorted} />
                              <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                <Typography
                                  variant="h5"
                                  color="indigo"
                                  className="mb-4"
                                >
                                  {building.address}
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                </>
              ) : loading ? (
                <ClipLoader color="blue" loading={loading} size={50} />
              ) : (
                // <div className="flex flex-wrap justify-center  gap-4 mb-6">
                // <div
                //   style={{ width: "100%", height: 600, position: "relative" }}
                // >
                //   <Space
                //     onCreate={(vp) =>
                //       vp.setBounds({ x: [0, 600], y: [0, 600] })
                //     }
                //   >
                <>
                  <div
                    id="carouselExampleCaptions"
                    className="carousel slide relative"
                    // data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                        style={{ backgroundColor: "#083985" }}
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                        style={{ backgroundColor: "#083985" }}
                      ></button>
                    </div>
                    <div className="carousel-inner relative w-full overflow-hidden">
                      {buildings.map((building, i) => {
                        const sorted = building.Floors.sort((a, b) =>
                          a.floor_num > b.floor_num
                            ? 1
                            : b.floor_num > a.floor_num
                            ? -1
                            : 0
                        );
                        return (
                          <div
                            className={`carousel-item ${
                              i == 0 ? "active" : ""
                            } relative float-left w-full`}
                          >
                            <div className="block w-full mt-10">
                              <div
                                style={{
                                  width: "100%",
                                  height: 600,
                                  position: "relative",
                                }}
                              >
                                <Space
                                  onCreate={(vp) =>
                                    vp.setBounds({ x: [0, 600], y: [0, 600] })
                                  }
                                >
                                  <Building floor={sorted} />
                                </Space>
                              </div>
                            </div>

                            <div className="carousel-caption hidden md:block absolute text-center">
                              <h5
                                style={{ color: "#083985" }}
                                className="text-xl"
                              >
                                {building.address}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline "
                      type="button"
                      // data-bs-target="#carouselExampleCaptions"
                      // data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon inline-block bg-no-repeat"
                        aria-hidden="true"
                        style={{
                          backgroundColor: "#083985",
                          marginRight: "20px",
                        }}
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                      ></span>
                      <span class="visually-hidden">
                        <Translation message={"Previous"} />
                      </span>
                    </button>
                    <button
                      class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                      type="button"
                      // data-bs-target="#carouselExampleCaptions"
                      // data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon inline-block bg-no-repeat"
                        aria-hidden="true"
                        style={{
                          backgroundColor: "#083985",
                          marginLeft: "20px",
                        }}
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                      ></span>
                      <span class="visually-hidden">
                        <Translation message={"Next"} />
                      </span>
                    </button>
                    {/* <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      // sx={{ position: "absolute", bottom: 30, right: 40 }}
                      icon={<WhereToVote />}
                      FabProps={{
                        size: "large",
                        style: {
                          backgroundColor: "#136bc2",
                          outline: "none",
                          position: "absolute",
                          bottom: 30,
                          right: 40,
                        },
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    ></SpeedDial> */}
                    {/* <Box
                      sx={{
                        height: 80,
                        transform: "translateZ(0px)",
                        flexGrow: 0,
                      }}
                    > */}
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{ position: "absolute", right: 40, bottom: 30 }}
                      icon={<SpeedDialIcon />}
                      // color='red'
                      // ButtonProps={{ color: "secondary" }}
                      FabProps={{
                        size: "large",
                        style: {
                          backgroundColor: "#136bc2",
                          outline: "none",
                        },
                      }}
                      // direction={"up"}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={t(action.name)}
                          onClick={() => {
                            action.type == "checkin"
                              ? setOpen(true)
                              : setSearch(true);
                          }}
                          // onClick={() => {
                          //   window.location.pathname = action.href;
                          // }}
                          // onClick={() => {
                          //   navigate(action.href);
                          // }}
                          FabProps={{
                            style: { color: action.color, outline: "none" },
                          }}
                        />
                      ))}
                    </SpeedDial>
                    {/* </Box> */}
                  </div>
                  <div></div>
                </>

                //   </Space>
                // </div>
              )}
            </div>
          </div>

          {open && <Scan open={open} setOpen={setOpen} />}
          {search && (
            <Search search={search} handleOpenSearch={handleOpenSearch} />
          )}
        </div>
      </div>
    </section>
  );
}

// function Item(props) {
//   return (
//     <>
//       <h2>{props.item.name}</h2>
//       <p>{props.item.description}</p>
//     </>
//   );
// }

// <div
// style={{ width: "100%", height: 800, position: "relative" }}
// >
// {/* <Space
//   onCreate={(vp) =>
//     vp.setBounds({ x: [0, 600], y: [0, 600] })
//   }
// > */}
// {loading ? (
//   <ClipLoader color="blue" loading={loading} size={50} />
// ) : (
//   <div
//     id="carouselExampleCaptions"
//     className="carousel slide relative"
//     // data-bs-ride="carousel"
//   >
//     <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
//       <button
//         type="button"
//         data-bs-target="#carouselExampleCaptions"
//         data-bs-slide-to="0"
//         className="active"
//         aria-current="true"
//         aria-label="Slide 1"
//         style={{ backgroundColor: "#083985" }}
//       ></button>
//       <button
//         type="button"
//         data-bs-target="#carouselExampleCaptions"
//         data-bs-slide-to="1"
//         aria-label="Slide 2"
//         style={{ backgroundColor: "#083985" }}
//       ></button>
//     </div>
//     <div className="carousel-inner relative w-full overflow-hidden">
//       {buildings.map((building, i) => {
//         console.log(building);
//         const sorted = building.Floors.sort((a, b) =>
//           a.floor_num > b.floor_num
//             ? 1
//             : b.floor_num > a.floor_num
//             ? -1
//             : 0
//         );
//         return (
//           <div
//             className={`carousel-item ${
//               i == 0 ? "active" : ""
//             } relative float-left w-full`}
//           >
//             {/* <img
//             src="https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg"
//             className="block w-full"
//             alt="..."
//           /> */}
//             <div className="block w-full">
//               {/* <Space
//               onCreate={(vp) =>
//                 vp.setBounds({ x: [0, 600], y: [0, 600] })
//               }
//             > */}
//               <Building floor={sorted} />
//               {/* </Space> */}
//             </div>
//             <div className="carousel-caption hidden md:block absolute text-center">
//               <h5
//                 style={{ color: "#083985" }}
//                 className="text-xl"
//               >
//                 {building.address}
//               </h5>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//     <button
//       class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
//       type="button"
//       data-bs-target="#carouselExampleCaptions"
//       data-bs-slide="prev"
//     >
//       <span
//         className="carousel-control-prev-icon inline-block bg-no-repeat"
//         aria-hidden="true"
//         style={{
//           backgroundColor: "#083985",
//           marginRight: "20px",
//         }}
//       ></span>
//       <span class="visually-hidden">Previous</span>
//     </button>
//     <button
//       class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
//       type="button"
//       data-bs-target="#carouselExampleCaptions"
//       data-bs-slide="next"
//     >
//       <span
//         class="carousel-control-next-icon inline-block bg-no-repeat"
//         aria-hidden="true"
//         style={{
//           backgroundColor: "#083985",
//           marginLeft: "20px",
//         }}
//       ></span>
//       <span class="visually-hidden">Next</span>
//     </button>
//   </div>
// )}
// {/* </Space> */}
// </div>
