import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Typography,
} from "@material-tailwind/react";
// import Icon from "@material-tailwind/react";
import "./form.css";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import HomeWork from "@mui/icons-material/HomeWorkOutlined";
// import FlightIcon from '@mui/icons-material/Flight';
import Close from "@mui/icons-material/Close";
import ConfirmDialog from "../ConfirmDialog";
import { deleteRequest } from "../../actions/RequestAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import { fetchOperationsByRequest } from '../../actions/OperationAction'
import moment from "moment";
import { Hail } from "@mui/icons-material";
import { LocationMarkerIcon, UserIcon } from "@heroicons/react/solid";
import useMediaQuery from "useMediaQuery";
import { fetchOperationsByRequest } from "actions/OperationAction";
import Translation from "Translation";

export default function CardRequest(props) {
  const { data, setData, token, operations, item } = props;

  const [informations, setInformations] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  // const [operations, setOperations] = useState([]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = await fetchOperationsByRequest(token, props.request._id);
  //     setOperations(data);
  //   };
  //   fetch();
  // }, []);

  const handleDelete = async (id) => {
    const result = await deleteRequest(token, id);
    const newData = data.filter((item) => item._id !== id);
    setData(newData);
    setConfirmDialog({ isOpen: false });
    if (result.status === 200) {
      NotificationManager.success(result.message, "Success");
      window.location.reload();
    } else {
      NotificationManager.error(result.message, "Error");
    }
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
    <>
      <Card className={isDesktop ? "width-Card" : "mt-5"}>
        <CardBody>
          <div className="flex gap-8  mb-2">
            <Close
              className="close-btn"
              fontSize="small"
              htmlColor="red"
              onClick={() => {
                setInformations(props.request);
                setConfirmDialog({
                  isOpen: true,
                  title: (
                    <Translation
                      message={"Are you sure to delete  this request ?"}
                    />
                  ),
                  subtitle: (
                    <Translation message={"You can't undo this operation !"} />
                  ),
                  onConfirm: () => {
                    handleDelete(props.request._id);
                  },
                });
              }}
            />
          </div>
          <div
            className="card-title"
            onClick={() => {
              window.location.href = `/details/${props.request._id}`;
            }}
          >
            {/* {   props.request.name == 'REMOTE_WORKING' || props.request.name == 'WFH' ? 
                        <HomeWork className='mt-10 ml-2' fontSize="large" htmlColor="#2ec6ff" />
                    : <WorkOffIcon className='mt-10 ml-2' fontSize="large" htmlColor="#c6d13e" />
                } */}

            <div>
              <div className="header">
                {props.request.name == "REMOTE_WORKING" ||
                props.request.name == "WFH" ? (
                  <HomeWork
                    className="ml-1"
                    fontSize="large"
                    htmlColor="#2ec6ff"
                  />
                ) : props.request.name == "Leave" ? (
                  <WorkOffIcon
                    className="ml-1"
                    fontSize="large"
                    htmlColor="#c6d13e"
                  />
                ) : props.request.name == "Client site" ||
                  props.request.name == "Mission" ? (
                  <Hail htmlColor="#b741c4" className="ml-1" fontSize="large" />
                ) : (
                  ""
                )}
                <p color="gray" className="title">
                  {props.request.name === "REMOTE_WORKING" ? (
                    <Translation message={"Exceptional WFH"} />
                  ) : props.request.name === "Client site" ? (
                    <Translation message={"Third party site"} />
                  ) : props.request.name === "WFH" ? (
                    <Translation message={"WFH"} />
                  ) : (
                    <Translation message={props.request.name} />
                  )}
                </p>
              </div>

              {/* <p className='title  ml-15'>{props.request.name === 'REMOTE_WORKING' ? 'Extended WFH' : props.request.name}</p> */}
              {props.request.name == "Leave" ? (
                <p className="title ml-15">
                  {operations &&
                    operations[0]?.date_debut
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-") +
                      " " +
                      operations[0]?.slot_debut}{" "}
                  <i className="fas fa-light fa-arrow-right" />{" "}
                  {operations[0]?.date_fin
                    .substring(0, 10)
                    .split("-")
                    .reverse()
                    .join("-") +
                    " " +
                    operations[0]?.slot_fin}
                </p>
              ) : props.request.name == "WFH" ||
                props.request.name == "Client site" ? (
                <>
                  <div className="ml-15 title">
                    <Typography variant="small">
                      <i className="fas fa-calendar mr-2" />
                      <Translation message={"Requested days"} />
                      {/* <p className="title ml-15">Requested days:</p> */}
                      {operations?.map((item) => {
                        return (
                          <p>
                            {item.date
                              ?.substring(0, 10)
                              .split("-")
                              .reverse()
                              .join("-") +
                              " " +
                              item.timeslot}
                          </p>
                        );
                      })}
                    </Typography>
                  </div>
                </>
              ) : props.request.name == "REMOTE_WORKING" ? (
                <p className="title ml-15">
                  {operations &&
                    operations[0]?.date_debut
                      ?.substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}{" "}
                  <i className="fas fa-light fa-arrow-right" />{" "}
                  {operations &&
                    operations[0]?.date_fin
                      ?.substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-")}
                </p>
              ) : (
                ""
              )}

              <div className="ml-15 title">
                <Typography variant="small">
                  <i className="fas fa-paper-plane mr-2" />
                  {props.request?.createdAt
                    .substring(0, 10)
                    .split("-")
                    .reverse()
                    .join("-") +
                    " " +
                    props.request?.createdAt.substring(11, 16)}
                </Typography>
                {/* <UserIcon color="gray" className="h-4 w-4" /> */}
                <Typography variant="small">
                  <i className="fas fa-user mr-2" />
                  {item?.idReciever?.firstname +
                    " " +
                    item?.idReciever?.lastname}
                </Typography>
                {props.request?.client && (
                  <>
                    <Typography variant="small">
                      <i className="fas fa-map-marker-alt fa-sm mt-[3px] mr-2" />
                      {props.request?.client}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        </CardBody>
        <div className="">
          <CardFooter
            style={{
              // position: "absolute",
              // bottom: 0,
              // width: "100%",
              // float: "right",
              // marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {props?.request?.cancellationStatus ? (
              <Chip
                variant="ghost"
                size="sm"
                value={
                  <p>
                    {" "}
                    {props?.request?.cancellationStatus === "refused" ? (
                      <Translation message={"Rejected cancellation"} />
                    ) : props?.request?.cancellationStatus === "pending" ? (
                      <Translation message={"Pending cancellation"} />
                    ) : (
                      ""
                    )}{" "}
                  </p>
                }
                className="rounded-full py-1.5"
                style={{
                  float: "right",
                  color:
                    props?.request?.cancellationStatus == "pending"
                      ? "#B78103"
                      : props?.request?.cancellationStatus == "refused"
                      ? "#b70303"
                      : "",
                  // width: 50,
                  fontSize: 10,
                  // height: 25,
                  // "& svg, img": { width: 1, height: 1, objectFit: "cover" },
                  backgroundColor:
                    props?.request?.cancellationStatus == "pending"
                      ? "rgba(255, 193, 7, 0.16)"
                      : props?.request?.cancellationStatus == "refused"
                      ? "rgba(255, 0, 0, 0.16)"
                      : "",
                }}
              />
            ) : (
              <p>{""}</p>
            )}
            {/* <Typography variant="small"/> */}
            <Chip
              variant="ghost"
              size="sm"
              value={
                <p>
                  {" "}
                  {props.request &&
                    props.request.status &&
                    (props.request.status === "accepted" ? (
                      <Translation message={"Approved"} />
                    ) : props.request.status === "refused" ? (
                      <Translation message={"Rejected"} />
                    ) : props.request.status === "pending" ? (
                      <Translation message={"Pending"} />
                    ) : (
                      props.request.status
                    ))}{" "}
                </p>
              }
              className="rounded-full py-1.5"
              style={{
                float: "right",
                color:
                  props.request?.status == "pending" ||
                  props.request?.status == "edited"
                    ? "#B78103"
                    : props.request?.status == "refused"
                    ? "#b70303"
                    : "#229A16",
                // width: 50,
                fontSize: 10,
                // height: 25,
                // "& svg, img": { width: 1, height: 1, objectFit: "cover" },
                backgroundColor:
                  props.request?.status == "pending" ||
                  props.request?.status == "edited"
                    ? "rgba(255, 193, 7, 0.16)"
                    : props.request?.status == "refused"
                    ? "rgba(255, 0, 0, 0.16)"
                    : "rgba(84, 214, 44, 0.16)",
              }}
            />
            {/* <Chip
              className="rounded-full py-1.5"
              style={{
                float: "right",
                color:
                  props.request?.status == "pending" ||
                  props.request?.status == "edited"
                    ? "#B78103"
                    : props.request?.status == "refused"
                    ? "#b70303"
                    : "#229A16",
                // width: 50,
                // fontSize: 12,
                // height: 25,
                // "& svg, img": { width: 1, height: 1, objectFit: "cover" },
                backgroundColor:
                  props.request?.status == "pending" ||
                  props.request?.status == "edited"
                    ? "rgba(255, 193, 7, 0.16)"
                    : props.request?.status == "refused"
                    ? "rgba(255, 0, 0, 0.16)"
                    : "rgba(84, 214, 44, 0.16)",
              }}
              size="sm" // value={props.request?.status}
              value={
                <Typography variant="small">
                  {" "}
                  {props.request &&
                    props.request.status &&
                    (props.request.status === "accepted" ? (
                      <Translation message={"Approved"} />
                    ) : props.request.status === "refused" ? (
                      <Translation message={"Rejected"} />
                    ) : props.request.status === "pending" ? (
                      <Translation message={"Pending"} />
                    ) : (
                      props.request.status
                    ))}{" "}
                </Typography>
              }
            /> */}
          </CardFooter>
        </div>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        token={props.token}
        informations={informations}
        details={props.request.operations}
      />

      <NotificationContainer />
    </>
  );
}
