import React, { useRef, useState, useEffect, useCallback } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
//import mapboxgl from "mapbox-gl";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "!mapbox-gl";
// @ts-ignore
//mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import "components/Map.css";
import favouriteHotel from "./favourite1.png";
import departureIcon from "./departure.png";
import arrivalIcon from "./arrival.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmF3ZW5tZXJzYW5pIiwiYSI6ImNsNmF6ejFsYTBtZXYzanFmcXp6MHJoZnUifQ.w144brpzhoKqRv9MODG5rQ";

export default function Map(props) {
  const {
    country,
    city,
    departureCity,
    destinationCity,
    lngCustomer,
    latCustomer,
    lngHotel,
    latHotel,
    latFavourite,
    lngFavourite,
  } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const geocoder = useRef(null);

  const [zoom] = useState(10);

  //   const fetchData = useCallback(() => {
  //     const geocodingClient = mbxGeocoding({
  //       accessToken: mapboxgl.accessToken,
  //     });

  //     return geocodingClient
  //       .forwardGeocode({
  //         query: city ? city.name : country?.name,
  //         // query: country.name,
  //         // query: "Baraki Ba",
  //         countries: [country?.country_code],
  //         limit: 1,
  //       })
  //       .send()
  //       .then((response) => {
  //         const match = response.body;
  //         const coordinates = match.features[0]?.geometry.coordinates;
  //         const placeName = match.features[0]?.place_name;
  //         const center = match.features[0]?.center;

  //         let name = city ? city.name : country?.name;

  //         geocoder.current.setPlaceholder("Search for places in " + name);
  //         country?.name &&
  //           !city &&
  //           geocoder.current.setBbox(match.features[0].bbox);
  //         country?.name &&
  //           !city &&
  //           geocoder.current.setCountries(
  //             match.features[0].properties.short_code
  //           );

  //         return {
  //           type: "Feature",
  //           center: center,
  //           geometry: {
  //             type: "Point",
  //             coordinates: coordinates,
  //           },
  //           properties: {
  //             description: placeName,
  //           },
  //         };
  //       });
  //   }, [country, city]);

  const fetchData = useCallback((name) => {
    const geocodingClient = mbxGeocoding({
      accessToken: mapboxgl.accessToken,
    });

    return geocodingClient
      .forwardGeocode({
        // query: city ? city.name : country?.name,
        // query: country.name,
        query: name,
        // countries: [country?.country_code],
        limit: 1,
      })
      .send()
      .then((response) => {
        const match = response.body;
        const coordinates = match.features[0]?.geometry.coordinates;
        const placeName = match.features[0]?.place_name;
        const center = match.features[0]?.center;

        geocoder.current.setPlaceholder("Search");

        return {
          type: "Feature",
          center: center,
          geometry: {
            type: "Point",
            coordinates: coordinates,
          },
          properties: {
            description: placeName,
          },
        };
      });
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: 2,
    });

    // const geocoder = new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   placeholder: "Search for places",
    //   marker: false,
    //   //   countries: ["al"],
    //   bbox: bbox,
    //   //   proximity: {
    //   //     longitude: -122.25948,
    //   //     latitude: 37.87221,
    //   //   },
    // });

    // console.log(geocoder);

    // map.current.addControl(geocoder);

    geocoder.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      //   placeholder: "Search for places",
      marker: false,
      width: "200px",
      //   countries: ["af"],
      //   bbox: bbox,
      //   proximity: {
      //     longitude: -122.25948,
      //     latitude: 37.87221,
      //   },
    });

    map.current.addControl(geocoder.current);
    map.current.addControl(new mapboxgl.FullscreenControl());
  });

  //   useEffect(() => {
  //     // marker.current.remove();
  //     const results = fetchData();
  //     results.then(async (resp) => {
  //       const customer = document.createElement("div");
  //       const hotel = document.createElement("div");
  //       customer.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/7720/7720736.png)`;
  //       // customer.style.backgroundImage = `url(${customerIcon})`;
  //       customer.style.width = `40px`;
  //       customer.style.height = `40px`;
  //       customer.style.backgroundSize = "100%";
  //       //   await marker.current
  //       //     .setLngLat([resp.geometry.coordinates[0], resp.geometry.coordinates[1]])
  //       //     .addTo(map.current);

  //       //   marker.current.on("dragend", onDragEnd);

  //       //   function onDragEnd() {
  //       //     const lngLat = marker.current.getLngLat();
  //       //     // console.log(`Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`);
  //       //     if (isCustomer) {
  //       //       setLngCustomer(lngLat.lng);
  //       //       setLatCustomer(lngLat.lat);
  //       //     } else {
  //       //       setLngHotel(lngLat.lng);
  //       //       setLatHotel(lngLat.lat);
  //       //     }
  //       //   }

  //       //   map.current.on("load", async () => {
  //       map.current.flyTo({
  //         center: resp.center,
  //       });
  //       //   });

  //       //   resp.geometry.coordinates &&
  //       //     marker.current
  //       //       .setLngLat([
  //       //         resp.geometry.coordinates[0],
  //       //         resp.geometry.coordinates[1],
  //       //       ])
  //       //       .addTo(map.current);

  //       //   map.current.on("click", (e) => {
  //       //     marker.current
  //       //       .setLngLat([e.lngLat.lng, e.lngLat.lat])
  //       //       .addTo(map.current);

  //       //     if (isCustomer) {
  //       //       setLngCustomer(e.lngLat.lng);
  //       //       setLatCustomer(e.lngLat.lat);
  //       //     } else {
  //       //       setLngHotel(e.lngLat.lng);
  //       //       setLatHotel(e.lngLat.lng);
  //       //     }
  //       //   });
  //     });
  //   }, [fetchData]);

  useEffect(() => {
    const fetchCoodinates = async (lng, lat, pin) => {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=pk.eyJ1IjoicmF3ZW5tZXJzYW5pIiwiYSI6ImNsNmF6ejFsYTBtZXYzanFmcXp6MHJoZnUifQ.w144brpzhoKqRv9MODG5rQ&autocomplete=true`;
      const response = await fetch(endpoint);
      const results = await response.json();
      // console.log("results", results);
      if (results.features?.length > 0) {
        const location = results.features?.find((item) =>
          item.place_type.includes("address")
        );
        const point = results.features?.find((item) =>
          item.place_type.includes("poi")
        );
        const popup = new mapboxgl.Popup({ offset: 25 }).setText(
          point ? point?.place_name : location?.place_name
        );

        const el = document.createElement("div");
        //   el.style.backgroundImage = `url(${pin})`;
        el.style.backgroundImage = `url(${pin})`;

        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "100%";
        // console.log("location", location, point);
        if (location || point) {
          new mapboxgl.Marker(el)
            .setLngLat(location ? location?.center : point?.center)
            .setPopup(popup)
            .addTo(map.current);
        }
      }
    };

    lngHotel &&
      latHotel &&
      fetchCoodinates(
        lngHotel,
        latHotel,
        "https://cdn-icons-png.flaticon.com/512/5193/5193714.png"
      );

    lngFavourite &&
      latFavourite &&
      fetchCoodinates(lngFavourite, latFavourite, favouriteHotel);

    lngCustomer &&
      latCustomer &&
      fetchCoodinates(
        lngCustomer,
        latCustomer,
        "https://cdn-icons-png.flaticon.com/512/5193/5193743.png"
      );
  }, [lngCustomer, latCustomer]);

  useEffect(() => {
    // if (map.current) return;

    // if (departureCity && destinationCity && map.current) {
    //   var coordinatesSource = [];
    //   var coordinatesDestination = [];

    //   const getCoordinates = async () => {
    //     // console.log("departureCity", departureCity);
    //     coordinatesSource = await fetchData(departureCity);
    //     // coordinatesSource = await fetchData('Tunis');

    //     coordinatesDestination = await fetchData(destinationCity);
    //     console.log("coordinatesDestination", coordinatesDestination);
    //     map.current.flyTo({
    //       center: coordinatesDestination.center,
    //     });
    //     //   map.current.flyTo({
    //     //     center: [coordinatesSource.center[0], coordinatesDestination.center[0]]
    //     //   });
    //     //   setCenter([
    //     //     coordinatesSource.center[0],
    //     //     coordinatesDestination.center[0],
    //     //   ]);
    //     const popupDeparture = new mapboxgl.Popup({ offset: 25 }).setText(
    //       coordinatesSource.properties.description
    //     );
    //     const popupDestination = new mapboxgl.Popup({ offset: 25 }).setText(
    //       coordinatesDestination.properties.description
    //     );

    //     const departure = document.createElement("div");
    //     const destination = document.createElement("div");
    //     // departure.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/7720/7720736.png)`;
    //     departure.style.backgroundImage = `url(${departureIcon})`;
    //     departure.style.width = `40px`;
    //     departure.style.height = `40px`;
    //     departure.style.backgroundSize = "100%";
    //     // destination.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/6747/6747208.png)`;
    //     // destination.style.backgroundImage = `url(${require('./landing.png')})`;
    //     destination.style.backgroundImage = `url(${arrivalIcon})`;
    //     //   destination.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/3967/3967046.png)`;
    //     //   destination.style.backgroundImage = `url(https://cdn-icons-png.flaticon.com/512/5621/5621185.png)`;

    //     destination.style.width = `40px`;
    //     destination.style.height = `40px`;
    //     destination.style.backgroundSize = "100%";
    //     new mapboxgl.Marker(departure)
    //       .setLngLat(coordinatesSource.center)
    //       .setPopup(popupDeparture)
    //       .addTo(map.current);
    //     new mapboxgl.Marker(destination)
    //       .setLngLat(coordinatesDestination.center)
    //       .setPopup(popupDestination)
    //       .addTo(map.current);

    //     //   map.current.flyTo({
    //     //     center: [coordinatesSource.center[0], coordinatesDestination.center[0]],
    //     //   });
    //   };

    //   departureCity && destinationCity && getCoordinates();

    //   map.current.on("load", () => {
    //     //   getCoordinates();
    //     // if (map.current.getSource("route")) {
    //     //   console.log("heeeee");
    //     // } else {
    //     //   map.current.addSource("route", {
    //     //     type: "geojson",
    //     //     data: {
    //     //       type: "Feature",
    //     //       properties: {},
    //     //       geometry: {
    //     //         type: "LineString",
    //     //         coordinates: [
    //     //           coordinatesSource.center,
    //     //           coordinatesDestination.center,
    //     //         ],
    //     //       },
    //     //     },
    //     //   });
    //     //   map.current.addLayer({
    //     //     id: "route",
    //     //     type: "line",
    //     //     source: "route",
    //     //     layout: {
    //     //       "line-join": "round",
    //     //       "line-cap": "round",
    //     //     },
    //     //     paint: {
    //     //       "line-color": "#fc6067",
    //     //       "line-width": 5,
    //     //     },
    //     //   });
    //     // }
    //   });
    // }

    if (destinationCity && map.current) {
      var coordinatesDestination = [];

      const getCoordinates = async () => {
        coordinatesDestination = await fetchData(destinationCity);
        map.current.flyTo({
          center: coordinatesDestination.center,
        });

        const popupDestination = new mapboxgl.Popup({ offset: 25 }).setText(
          coordinatesDestination.properties.description
        );

        const destination = document.createElement("div");
        destination.style.backgroundImage = `url(${arrivalIcon})`;

        destination.style.width = `40px`;
        destination.style.height = `40px`;
        destination.style.backgroundSize = "100%";

        new mapboxgl.Marker(destination)
          .setLngLat(coordinatesDestination.center)
          .setPopup(popupDestination)
          .addTo(map.current);
      };

      destinationCity && getCoordinates();
    }

    if (departureCity && map.current) {
      var coordinates = [];

      const getCoordinates = async () => {
        coordinates = await fetchData(departureCity);
        // map.current.flyTo({
        //   center: coordinates.center,
        // });

        const popup = new mapboxgl.Popup({ offset: 25 }).setText(
          coordinates.properties.description
        );

        const departure = document.createElement("div");
        departure.style.backgroundImage = `url(${departureIcon})`;

        departure.style.width = `40px`;
        departure.style.height = `40px`;
        departure.style.backgroundSize = "100%";

        new mapboxgl.Marker(departure)
          .setLngLat(coordinates.center)
          .setPopup(popup)
          .addTo(map.current);
      };

      destinationCity && getCoordinates();
    }
  }, [departureCity, destinationCity, map.current]);

  return (
    <div
      ref={mapContainer}
      className="map-container mt-20"
      style={{ height: "500px", width: "100%" }}
    />
  );
}
