import React, { useEffect, useState } from "react";
import Card from "./Card";
import "./form.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import jwt from "jwt-decode";
import SpeedDial from "@mui/material/SpeedDial";
import { fetchUserClaims } from "actions/RequestAction";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
const color = "#083985";

export default function Section() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const [data, setData] = useState();
  const [dataFiltred, setDataFiltred] = useState();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchUserClaims(user.id);

      setData(result);
      let sortedResult = result.sort(
        (a, b) =>
          new Date(...a.updatedAt.split("-").reverse()) -
          new Date(...b.updatedAt.split("-").reverse())
      );
      setDataFiltred(sortedResult);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (status != null) {
      data && setDataFiltred(data.filter((item) => item.status == status));
    } else {
      setDataFiltred(data);
    }
  }, [status]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                    
                  >
                    
                    <Translation message={"Follow up on your support requests"} />
                  </Typography>
                  <Typography color="gray">
                    <Translation message={"Check your requests' status."} />
                    
                  </Typography>
                </div>
              </div>
              <div className="flex justify-center flex-wrap">
                <div>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-label">
                     <Translation message={"Status"} />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="pending"><Translation message={"Pending"} /></MenuItem>
                      <MenuItem value="processing"><Translation message={"Processing"} /></MenuItem>
                      <MenuItem value="closed"><Translation message={"Closed"} /></MenuItem>
                      <MenuItem value={null}><Translation message={"ALL"} /></MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div
                className={
                  isDesktop
                    ? "flex flex-wrap justify-center flex-grow-3 gap-8 mt-16 mb-12"
                    : "justify-center flex-grow-3 gap-8 mt-16 mb-12"
                }
              >
                {dataFiltred && dataFiltred.length !== 0 ? (
                  dataFiltred.map((request) => {
                    return (
                      <Card
                        request={request}
                        data={dataFiltred}
                        setData={setDataFiltred}
                      />
                    );
                  })
                ) : (
                  <Typography color="gray">
                    <Translation message={"You have no claims"} />
                    
                  
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link to="/new_claim">
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 10, right: 40 }}
            icon={<Add />}
            FabProps={{
              size: "large",
              style: { backgroundColor: "#136bc2", outline: "none" },
            }}
          ></SpeedDial>
        </Link>
      </div>
    </section>
  );
}
