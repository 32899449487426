import React, { useEffect, useState } from "react";
import { Button, Chip, Typography } from "@material-tailwind/react";

import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { format } from "date-fns";
import { getReservationsByUser } from "actions/OperationAction";
import ConfirmDialog from "components/ConfirmDialog";
import { deleteOperation } from "actions/OperationAction";
import { deleteManyOperations } from "actions/OperationAction";

const color = "#083985";

export default function Section() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const [selectionModel, setSelectionModel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const config = JSON.parse(localStorage.getItem("config"));

  const [rows, setRows] = useState([]);
  const user = jwtDecode(token);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const columns = [
    {
      field: "Date",
      headerName: <Translation message={"Date"} />,
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.getValue(params.id, "date") || ""} ${
          params.getValue(params.id, "timeslot") || ""
        }`,
    },
    {
      field: "ressource",
      headerName: <Translation message={"Ressource"} />,
      sortable: false,
      width: 350,
    },
    {
      field: "guest",
      headerName: <Translation message={"Guest"} />,
      width: 250,
    },

    {
      field: "status",
      headerName: <Translation message={"Status"} />,
      // type: 'number',
      width: 120,
      renderCell: (params) => {
        console.log("🚀 ~ Section ~ params:", params);
        return (
          <Chip
            variant="ghost"
            size="sm"
            value={
              <p>
                {" "}
                {params.row.status?.toLowerCase() === "active" ? (
                  <Translation message={"Booked"} />
                ) : params.row.status?.toLowerCase() === "ended" ? (
                  <Translation message={"Checked-In"} />
                ) : params.row.status?.toLowerCase() === "closed" ? (
                  <Translation message={"Checked-Out"} />
                ) : (
                  ""
                )}{" "}
              </p>
            }
            className="rounded-full py-1.5"
            style={{
              float: "right",
              color:
                params.row.status?.toLowerCase() === "active"
                  ? "#B78103"
                  : params.row.status?.toLowerCase() === "ended"
                  ? "#229A16"
                  : params.row.status?.toLowerCase() === "closed"
                  ? "#b70303"
                  : "",
              fontSize: 10,

              backgroundColor:
                params.row.status?.toLowerCase() === "active"
                  ? "rgba(255, 193, 7, 0.16)"
                  : params.row.status?.toLowerCase() === "ended"
                  ? "rgba(84, 214, 44, 0.16)"
                  : params.row.status?.toLowerCase() === "closed"
                  ? "rgba(255, 0, 0, 0.16)"
                  : "",
            }}
          />
        );
      },
    },

    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div className="flex">
            <Button
              color="red"
              variant="text"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={(e) => {
                e.stopPropagation();
                setConfirmDialog({
                  isOpen: true,
                  title: (
                    <Translation
                      message={"Are you sure to delete  this slot?"}
                    />
                  ),
                  subtitle: (
                    <Translation message={"You can't undo this operation!"} />
                  ),
                  onConfirm: () => {
                    handleDelete(params.id);
                  },
                });
              }}
              disabled={disabled}
            >
              <i className="fas fa-trash fa"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const handleDelete = async (operationId) => {
    const result = await deleteOperation(token, operationId);
    if (result.status === 200) {
      if (result.data?.status == 201) {
        NotificationManager.error(result.data.message);
        setConfirmDialog({ isOpen: false });

        return;
      }
      NotificationManager.success(result.message);
      setRows(rows.filter((item) => item.id != operationId));
      setConfirmDialog({ isOpen: false });
    } else {
      NotificationManager.error(result.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      const operations = await getReservationsByUser(user.id);
      console.log("🚀 ~ fetchData ~ operations:", operations);

      var rowsData = [];
      for (const item of operations) {
        rowsData.push({
          id: item._id,
          date: format(new Date(item.reservationdate), "dd-MM-yyyy"),
          timeslot: item.timeslot,
          ressource:
            item.desk.name +
            " / " +
            item.desk.zone.name +
            " / " +
            item.desk.zone.floor.name,
          status: item.status,
          guest: item.guest,
          createdAt: format(new Date(item.createdAt), "dd-MM-yyyy  HH:mm"),
        });
      }
      setRows(rowsData);
      setOpen(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    selectionModel.length > 0 ? setVisible(true) : setVisible(false);
  }, [selectionModel]);

  const handleDeleteMany = async (e) => {
    setOpen(true);
    const result = await deleteManyOperations(selectionModel);
    if (result.status === 200) {
      NotificationManager.success(result.message);
      const filteredRows = rows.filter(
        (row) => !selectionModel.includes(row.id)
      );
      setRows(filteredRows);
      setConfirmDialog({ isOpen: false });
    } else {
      NotificationManager.error(result.message);
      setConfirmDialog({ isOpen: false });
    }

    setOpen(false);
  };

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-12/12 px-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation message={"Reservation's list"} />
                  </Typography>
                  <Typography color="gray">
                    <Translation
                      message={
                        "Check all your reservations as well as those made for your guests."
                      }
                    />
                  </Typography>
                </div>
              </div>
              <div className="flex flex-wrap justify-center flex-grow-3 gap-8 mt-5 mb-12">
                <div className="mt-10" style={{ height: 600, width: "100%" }}>
                  {visible && (
                    <div className="flex justify-center mb-5">
                      <Button
                        color="red"
                        variant="text"
                        size="regular"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // onClick={handleDeleteMany}
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title: (
                              <Translation
                                message={"Are you sure to delete  this slots?"}
                              />
                            ),
                            subtitle: (
                              <Translation
                                message={"You can't undo this operation!"}
                              />
                            ),
                            onConfirm: () => {
                              handleDeleteMany();
                            },
                          });
                        }}
                        disabled={disabled}
                      >
                        <i className="fas fa-trash fa"></i> Delete selected
                      </Button>
                    </div>
                  )}
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    loading={open}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    columnVisibilityModel={{
                      accept: !hide,
                      refuse: !hide,
                      balance: config?.DISPLAY_EXTRA_BALANCE,
                    }}
                    disableColumnSelector
                    stickyHeader={true}
                    style={{ height: 600 }}
                  />
                </div>

                <ConfirmDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                  token={token}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </section>
  );
}
