import React, { useState, useEffect } from "react";

import { DataGrid, GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import Button from "@material-tailwind/react/components/Button";
import {
  getPendingRequestsByManager,
  updateRequest,
} from "../../actions/RequestAction";
import jwt from "jwt-decode";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { fetchOperationsByRequest } from "actions/OperationAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Translation from "Translation";

export default function WFH(props) {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const config = JSON.parse(localStorage.getItem("config"));

  const [rows, setRows] = useState([]);
  const user = jwt(token);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const columns = [
    {
      field: "fullName",
      headerName: <Translation message={"Full name"} />,
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    {
      field: "operations",
      headerName: <Translation message={"Requested Days"} />,
      sortable: false,
      width: 350,
      renderCell: (params) => {
        var ch = "";
        if (params.formattedValue[0]?.OperationType == "WFH") {
          params.formattedValue.map((day) => {
            ch = ch + day.date.substring(0, 10) + " " + day.timeslot + "\n";
            // return <Button  color="green"
            //           buttonType="link"
            //           size="regular"
            //           rounded={false}
            //           block={false}
            //           iconOnly={false}
            //           ripple="dark" ><i className="fas fa-check fa"></i></Button>;
          });
        } else {
          ch =
            params.formattedValue[0]?.date_debut
              ?.substring(0, 10)
              ?.split("-")
              .reverse()
              .join("-") +
            " -> " +
            params.formattedValue[0]?.date_fin
              ?.substring(0, 10)
              ?.split("-")
              .reverse()
              .join("-");
        }

        return <p>{ch}</p>;
      },
    },
    {
      field: "balance",
      headerName: <Translation message={"Extra balance"} />,
      width: 120,
    },
    {
      field: "date",
      headerName: <Translation message={"Sent date"} />,
      width: 160,
    },
    {
      field: "status",
      headerName: <Translation message={"Status"} />,
      // type: 'number',
      width: 120,
    },
    // {
    //   field: "accept",
    //   headerName: "",
    //   sortable: false,
    //   width: 100,
    //   hide: hide,
    //   renderCell: (params) => {
    //     setHide(params.row.status == "pending" ? false : true);
    //     const onApprove = async (e) => {
    //       e.stopPropagation(); // don't select this row after clicking

    //       // NotificationManager.success('Updated successfully')
    //       // setRows(rows.filter(item => item.id != params.id ))
    //       const result = await updateRequest("", params.id, {
    //         status: "accepted",
    //       });
    //       if (result.status === 200) {
    //         NotificationManager.success(result.message, "Success");
    //         setRows(rows.filter((item) => item.id != params.id));
    //       } else {
    //         NotificationManager.error(result.message, "Error");
    //       }
    //     };

    //     return (
    //       <Button
    //         color="green"
    //         variant="text"
    //         size="regular"
    //         rounded={false}
    //         block={false}
    //         iconOnly={false}
    //         ripple="dark"
    //         onClick={onApprove}
    //         disabled={params.row.status == "pending" ? false : true}
    //       >
    //         <i className="fas fa-check fa"></i>
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "refuse",
    //   headerName: "",
    //   sortable: false,
    //   width: 100,
    //   hide: hide,
    //   renderCell: (params) => {
    //     setHide(params.row.status == "pending" ? false : true);
    //     const onReject = async (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       // console.log('params', params)
    //       // const api: GridApi = params.api;
    //       // const thisRow: Record<string, GridCellValue> = {};

    //       // api
    //       //   .getAllColumns()
    //       //   .filter((c) => c.field !== '__check__' && !!c)
    //       //   .forEach(
    //       //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //       //   );
    //       // NotificationManager.success('Updated successfully')
    //       // setRows(rows.filter(item => item.id != params.id ))
    //       const result = await updateRequest("", params.id, {
    //         status: "refused",
    //       });
    //       if (result.status === 200) {
    //         NotificationManager.success(result.message, "Success");
    //         setRows(rows.filter((item) => item.id != params.id));
    //       } else {
    //         NotificationManager.error(result.message, "Error");
    //       }
    //     };

    //     return (
    //       <Button
    //         color="red"
    //         variant="text"
    //         size="regular"
    //         rounded={false}
    //         block={false}
    //         iconOnly={false}
    //         ripple="dark"
    //         onClick={onReject}
    //         disabled={params.row.status == "pending" ? false : true}
    //       >
    //         <i className="fas fa-times fa"></i>
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "details",
    //   headerName: "",
    //   sortable: false,
    //   width: 120,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       // console.log('params', params)
    //       // const api: GridApi = params.api;
    //       // const thisRow: Record<string, GridCellValue> = {};

    //       // api
    //       //   .getAllColumns()
    //       //   .filter((c) => c.field !== '__check__' && !!c)
    //       //   .forEach(
    //       //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //       //   );
    //       let path = `/request/${params.id}`;
    //       //   console.log(thisRow)

    //       return (window.location.href = path);
    //     };

    //     return (
    //       <Link to={`/request/${params.id}`}>
    //         <Button
    //           color="indigo"
    //           variant="text"
    //           size="regular"
    //           rounded={false}
    //           block={false}
    //           iconOnly={false}
    //           ripple="dark"
    //           // onClick={onClick}
    //         >
    //           Details
    //         </Button>
    //       </Link>
    //     );
    //   },
    // },

    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 260,
      renderCell: (params) => {
        const onApprove = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          // NotificationManager.success('Updated successfully')
          // setRows(rows.filter(item => item.id != params.id ))
          const result = await updateRequest("", params.id, {
            status: "accepted",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message);
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(result.message);
          }
        };

        const onReject = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          NotificationManager.success("Updated successfully");
          const result = await updateRequest("", params.id, {
            status: "refused",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message);
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(result.message);
          }
        };

        return (
          <div className="flex">
            <Button
              color="green"
              variant="text"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApprove}
              disabled={disabled}
            >
              <i className="fas fa-check fa"></i>
            </Button>
            <Button
              color="red"
              variant="text"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onReject}
              disabled={disabled}
            >
              <i className="fas fa-times fa"></i>
            </Button>

            <Link
              to={{
                pathname: `/request/${params.id}`,
                state: window.location.pathname + "#/Leave",
              }}
            >
              <Button
                color="indigo"
                variant="text"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="dark"
                // onClick={onClick}
              >
                <Translation message={"Details"} />
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      const resultWFH = await getPendingRequestsByManager(
        token,
        user.id,
        "WFH"
      );
      const resultREMOTE = await getPendingRequestsByManager(
        token,
        user.id,
        "REMOTE_WORKING"
      );
      const result = [...resultWFH, ...resultREMOTE];
      const WFHRequest =
        result &&
        result?.filter(
          (item) => item._id.name == "WFH" || item._id.name == "REMOTE_WORKING"
        );
      var rowsData = [];
      for (const item of WFHRequest) {
        // const data = await fetchOperationsByRequest(token, item._id);
        rowsData.push({
          id: item._id._id,
          lastName: item.idSender?.lastname,
          firstName: item.idSender?.firstname,
          status: item._id.status,
          date:
            item._id.createdAt.substring(0, 10).split("-").reverse().join("-") +
            " " +
            item._id.createdAt.substring(11, 16),
          operations: item.operations,
          balance: item.balance?.extraBalance,
        });
      }
      // setData(WFHRequest)
      setRows(rowsData);
      setOpen(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="mt-10" style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          loading={open}
          columnVisibilityModel={{
            accept: !hide,
            refuse: !hide,
            balance: config?.DISPLAY_EXTRA_BALANCE,
          }}
          disableColumnSelector
          stickyHeader={true}
          style={{ height: 600 }}

          // columns={columns.map((col) => ({
          //   ...col,
          //   hide: rows.every(
          //     (row) => row[col.field] == null || row[col.field] == undefined
          //   ),
          // }))}
          // columns={columns.map((col) => ({
          //   ...col,
          //   hide: rows.every((row) => {
          //     console.log("row[col.field]", col.field, row[col.field]);
          //     return row[col.field] == null || row[col.field] == undefined;
          //   }),
          // }))}
        />
      </div>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>
  );
}
