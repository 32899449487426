import DefaultFooter from "../components/Footers/DefaultFooter";

import Section from "../components/Team/Section";
import DefaultNavbar from "../components/Navbars/DefaultNavbar";

export default function Team(props) {
  return (
    <>
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <DefaultNavbar />
      </div>
      <main>
        <Section users={props.users} />
      </main>
      <DefaultFooter />
    </>
  );
}
