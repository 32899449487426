import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogFooter,
  Typography,
  Button,
  Textarea,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import Image from "./Image";
import { WarningAmber } from "@mui/icons-material";
import { Alert } from "@mui/material";
import jwtDecode from "jwt-decode";
import Translation from "Translation";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { fr, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { fetchValidators } from "actions/UserAction";
import { t } from "i18next";
import {
  delegateLeaveValidation,
  getLeaveDelegation,
} from "actions/UserAction";
import { deleteDelegation } from "actions/UserAction";

const color = "#294e87";

const DelegateValidation = (props) => {
  const { open, handleOpen } = props;
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [loading, setLoading] = useState(false);

  const [language, setLanguage] = useState("");
  const [period, setPeriod] = useState(null);
  const [managers, setManagers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [delegations, setDelegations] = useState([]);
  const [activeDelegation, setActiveDelegation] = useState(null);

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwtDecode(token);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language == "fr") {
      setLanguage(fr);
    } else {
      setLanguage(enUS);
    }
  }, [i18n.language]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchValidators();

      var listValidators = result
        .filter((item) => item._id !== user._id)
        .sort(SortArray);
      setManagers(listValidators);
      const resultDelegations = await getLeaveDelegation({
        delegatedBy: user.id,
      });
      setActiveDelegation(
        resultDelegations.find(
          (item) => item.isActive == true && item.delegatedBy?._id == user.id
        )
      );
      // console.log("🚀 ~ fetchData ~ resultDelegations:", resultDelegations);
      setDelegations(resultDelegations);
    };
    fetchData();
  }, []);

  console.log("delegations", delegations);

  const handleSave = async () => {
    if (
      !period ||
      !selected ||
      (period && (period.from == undefined || period.to == undefined))
    ) {
      NotificationManager.error(
        <Translation message={"Fill in the fields!"} />
      );
    } else {
      setLoading(true);
      var res = await delegateLeaveValidation({
        delegatedBy: user.id,
        delegatedTo: selected,
        startDate: format(period.from, "yyyy-MM-dd"),
        endDate: format(period.to, "yyyy-MM-dd"),
      });
      if (res.status == 400) {
        setLoading(false);

        NotificationManager.error(res.message);
      } else {
        setLoading(false);
        await NotificationManager.success("success");
        handleOpen();
      }
    }
  };

  const handleDelete = async () => {
    if (activeDelegation !== null) {
      setLoading(true);
      var res = await deleteDelegation(user?.id);
      if (res.status == 400) {
        setLoading(false);
        NotificationManager.error(res.message);
      } else {
        setLoading(false);
        await NotificationManager.success("success");
        handleOpen();
      }
    }
  };

  const modifiers = {
    disabled: [{ before: new Date() }],
  };

  return (
    <div>
      <Dialog
        open={open}
        handler={handleOpen}
        // style={{ height: "90%" }}
        size={isDesktop ? "lg" : "xl"}
      >
        <div
          className={
            isDesktop
              ? "flex content-center items-center justify-center"
              : "flex content-center items-center justify-center flex-wrap"
          }
          style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
        >
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                {isDesktop ? (
                  <>
                    <div className=" text-center">
                      {/* <img src={image} height={100} /> */}
                      {/* <HomeWork style={{ color: color }} fontSize="large" /> */}
                      <Typography variant="h4" style={{ color: color }}>
                        Leave validation delegation
                      </Typography>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      {activeDelegation ? (
                        <div className="mt-20 ">
                          <p>
                            {t(
                              "You have delegated leave validation to {{delegate}} from {{startDate}} to {{endDate}}",
                              {
                                delegate:
                                  activeDelegation?.delegatedTo?.firstname +
                                  " " +
                                  activeDelegation?.delegatedTo?.lastname,
                                startDate: format(
                                  new Date(activeDelegation?.startDate),
                                  "dd-MM-yyyy"
                                ),
                                endDate: format(
                                  new Date(activeDelegation?.endDate),
                                  "dd-MM-yyyy"
                                ),
                              }
                            )}
                          </p>
                        </div>
                      ) : (
                        <div className="mt-20">
                          <div className="flex gap-8">
                            <Typography className="text-gray-700">
                              <Translation message="Delegate" />
                            </Typography>
                            <Select
                              options={managers.map((item) => {
                                return {
                                  ...item,
                                  value: item._id,
                                  label: item.firstname + " " + item.lastname,
                                };
                              })}
                              onChange={(e) => {
                                setSelected(e);
                              }}
                              value={selected}
                              placeholder={t("Choose your delegate")}
                              isClearable={true}
                            />
                          </div>
                          <div className="flex gap-8 mt-5">
                            <Typography className="text-gray-700">
                              <Translation message="Period" />
                            </Typography>
                            <DayPicker
                              locale={language}
                              mode="range"
                              selected={period}
                              onSelect={setPeriod}
                              showOutsideDays
                              className="border-0"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                              }}
                              modifiers={modifiers}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </>
                ) : (
                  <>
                    <div className=" text-center mt-20">
                      <Typography variant="h4" style={{ color: color }}>
                        Leave validation delegation
                      </Typography>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex gap-8">
                        <Typography className="text-gray-700">
                          <Translation message="Delegate" />
                        </Typography>
                        <Select
                          options={managers.map((item) => {
                            return {
                              ...item,
                              value: item._id,
                              label: item.firstname + " " + item.lastname,
                            };
                          })}
                          onChange={(e) => {
                            setSelected(e);
                          }}
                          value={selected}
                          placeholder={t("Choose your delegate")}
                          isClearable={true}
                        />
                      </div>
                      <div className="flex gap-8 mt-5">
                        <Typography className="text-gray-700">
                          <Translation message="Period" />
                        </Typography>
                        <DayPicker
                          locale={language}
                          mode="range"
                          selected={period}
                          onSelect={setPeriod}
                          showOutsideDays
                          className="border-0"
                          classNames={{
                            caption:
                              "flex justify-center py-2 mb-4 relative items-center",
                            caption_label: "text-sm font-medium text-gray-900",
                            nav: "flex items-center",
                            nav_button:
                              "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                            nav_button_previous: "absolute left-1.5",
                            nav_button_next: "absolute right-1.5",
                            table: "w-full border-collapse",
                            head_row: "flex font-medium text-gray-900",
                            head_cell: "m-0.5 w-9 font-normal text-sm",
                            row: "flex w-full mt-2",
                            cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                            day: "h-9 w-9 p-0 font-normal",
                            day_range_end: "day-range-end",
                            day_selected:
                              "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                            day_today: "rounded-md bg-gray-200 text-gray-900",
                            day_outside:
                              "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                            day_disabled: "text-gray-500 opacity-50",
                            day_hidden: "invisible",
                          }}
                          components={{
                            IconLeft: ({ ...props }) => (
                              <ChevronLeftIcon
                                {...props}
                                className="h-4 w-4 stroke-2"
                              />
                            ),
                            IconRight: ({ ...props }) => (
                              <ChevronRightIcon
                                {...props}
                                className="h-4 w-4 stroke-2"
                              />
                            ),
                          }}
                        />
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
        <DialogFooter
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <ClipLoader color="white" loading={loading} size={20} />
          ) : (
            <Button
              style={{ backgroundColor: activeDelegation ? "red" : "#083985" }}
              ripple="light"
              onClick={() => {
                activeDelegation ? handleDelete() : handleSave();
              }}
            >
              <Translation message={activeDelegation ? "Delete" : "Save"} />
            </Button>
          )}
          <Button
            className="ml-2"
            variant="outlined"
            color="indigo"
            onClick={() => {
              handleOpen();
            }}
          >
            <Translation message={"Cancel"} />
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DelegateValidation;

function SortArray(x, y) {
  if (x.firstname < y.firstname) {
    return -1;
  }
  if (x.firstname > y.firstname) {
    return 1;
  }
  return 0;
}
