import * as icons from "@mui/icons-material";
import { useMemo } from "react";

const IconComponent = ({ name, ...rest }) => {
  const IconComponent = useMemo(() => icons[name], [name]);

  if (!IconComponent) {
    throw new Error(`Invalid icon name: ${name}`);
  }

  return <IconComponent {...rest} />;
};

export default IconComponent;
