import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
// import Checkbox from "@material-tailwind/react/components/Checkbox";
import LoginNavbar from "components/Navbars/LoginNavbar";
import SimpleFooter from "components/Footers/LoginFooter";
import Page from "components/login/Page";
import Container from "components/login/Container";
import animationData from "../assets/lotties/animated";
import Lottie from "react-lottie";
// import H6 from '@material-tailwind/react';
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "./style.css";
import { login } from "../actions/UserAction";
import ForgetPassword from "components/login/ForgetPassword";
import Translation from "Translation";
import { t } from "i18next";

export default function Login(props) {
  const { setUser } = props;
  // const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const [forgetPasswordScreen, setForgetPasswordScreen] = useState({
    isOpen: false,
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (email.length == 0) {
      setLoading(false);
      // NotificationManager.warning(<Translation message={"Email is required"} />);
      NotificationManager.warning("Veuillez saisir votre email");

      return;
    }
    if (password.length == 0) {
      setLoading(false);
      // NotificationManager.warning(<Translation message={"Password is required"} />);
      NotificationManager.warning("Veuillez saisir votre mot de passe");
      return;
    }

    const result = await login(email, password);
    if (result === "Login Success") {
      setLoading(false);
      NotificationManager.success(t(result));
      await setUser(JSON.parse(localStorage.getItem("user")));
      setTimeout(function () {
        // history.push("/home");
        window.location.pathname = "/home";
      }, 1000);
    } else {
      setLoading(false);
      NotificationManager.error(result);
    }

    // form.current.validateAll();
    // if (form.current.context._errors.length === 0) {
    //     setLoading(true);

    //     const result = await login(email, password)
    // }

    // if (checkBtn.current.context._errors.length === 0) {
    //   dispatch(login(Email, password, props.history))
    //     .then(() => {
    //       setTimeout(function(){
    //         setLoading(false);
    //         // props.history.push("/web/admin");
    //       }, 1000);

    //       // debugger
    //       // window.location.reload();
    //     })
    //     .catch((e) => {
    //       setLoading(false);
    //     });
    // } else {
    //   setLoading(false);
    // }
  };

  return (
    <Page>
      <LoginNavbar />
      <Container>
        <section className="pb-20 relative block">
          <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
            <div className="flex flex-wrap justify-center mt-5">
              <div className="w-full lg:w-12/12">
                <div className="w-full text-center mt-10">
                  {/* <form onSubmit={handleLogin}> */}
                  <form onSubmit={handleLogin}>
                    <Card
                    // className="flex flex-wrap justify-center mt-5"
                    // style={{ width: "50%" }}
                    >
                      <CardBody>
                        <Lottie
                          options={defaultOptions}
                          height={250}
                          width={250}
                        />
                        {/* <center> <H6 color="indigo">WORK POINT</H6></center><br/> */}
                        <div className="mb-12 px-4 bg-bb">
                          <Input
                            type="email"
                            label="Email"
                            color="indigo"
                            placeholder="Email"
                            variant="static"
                            // iconName="email"
                            icon={<i className="fas fa-at" />}
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            required={true}
                          />
                        </div>
                        <div className="mb-8 px-4">
                          <Input
                            type={show ? "text" : "password"}
                            color="indigo"
                            variant="static"
                            placeholder={t("Password")}
                            label={t("Password")}
                            // iconName="lock"
                            // icon={<i className="fas fa-lock" />}
                            icon={
                              password.length > 0 ? (
                                <i
                                  className={
                                    show ? "fas fa-eye-slash" : "fas fa-eye"
                                  }
                                  onClick={() => {
                                    setShow(!show);
                                  }}
                                />
                              ) : (
                                <i className="fas fa-lock" />
                              )
                            }
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            required={true}
                          />
                        </div>
                        {/* <div className="mb-4 px-4">
                            <Checkbox
                                color="indigo"
                                text="Remember Me"
                                id="remember"
                            />
                        </div> */}
                        <div className="mb-4 px-4">
                          <small
                            className="forget-password"
                            onClick={() => {
                              setForgetPasswordScreen({ isOpen: true });
                            }}
                            color="indigo"
                          >
                            {/* Forgot password? */}
                            <Translation message="Forgot password?" />
                          </small>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <div className="flex justify-center bg-bb">
                          <Button
                            color="indigo"
                            // buttonType="filled"
                            size="lg"
                            // ripple="dark"
                            disabled={loading}
                            // onClick={handleLogin}
                            type="submit"
                          >
                            <ClipLoader
                              color="white"
                              loading={loading}
                              size={20}
                            />
                            <Translation message="Login" />
                          </Button>
                          {/* <Button
                            color="indigo"
                            buttonType="filled"
                            size="lg"
                            rounded={true}
                            ripple="dark"
                            disabled={loading}
                          >
                            <ClipLoader
                              color="white"
                              loading={loading}
                              size={20}
                            />
                            Login
                          </Button> */}
                        </div>
                      </CardFooter>
                    </Card>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <br />
      <br />
      <br />
      <br />
      {/* <div className="container absolute bottom-6 left-2/4 z-10 mx-auto -translate-x-2/4 text-white">
        <SimpleFooter />
      </div> */}

      <NotificationContainer />
      {forgetPasswordScreen.isOpen && (
        <ForgetPassword
          forgetPasswordScreen={forgetPasswordScreen}
          setForgetPasswordScreen={setForgetPasswordScreen}
        />
      )}
    </Page>
  );
}
