import React, { Fragment, useState, useEffect } from "react";

import {
  Breadcrumbs,
  Button,
  Typography,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Alert,
} from "@material-tailwind/react";
import Textarea from "@material-tailwind/react/components/Textarea";
import ClipLoader from "react-spinners/ClipLoader";

import "./form.css";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PickersDay from "@mui/lab/PickersDay";

import { styled } from "@mui/material/styles";

import {
  addDraft,
  addRequest,
  fetchRequestsByUser,
} from "../../actions/RequestAction";
// import NotifPerson from "./NotifPerson";
import { DayPicker, DayModifiers, Caption } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

import moment from "moment";
import jwt from "jwt-decode";

import useMediaQuery from "useMediaQuery";
import { Link } from "react-router-dom";
import { Home } from "@mui/icons-material";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/fr";
import { t } from "i18next";
// import { Select } from "components/Select";
import { fetchSetting } from "actions/SettingAction";
import { fetchUser } from "actions/UserAction";
import { fetchValidators } from "actions/UserAction";
import { fetchALLUsers } from "actions/UserAction";
import { UsersSelect } from "components/UsersSelect";
import SelectAutocomplete from "components/Team/Select";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import Badge from "@mui/material/Badge";

import {
  Badge,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { fetchUserBalances } from "actions/UserAction";
import { fetchPendingDays } from "actions/OperationAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Select from "react-select";
import { addDays, format, isBefore, subDays } from "date-fns";
import { DatePicker } from "@mui/lab";
import { fr, enUS } from "date-fns/locale";
// import { LEAVE_Note } from "../../config";

// import "react-day-picker/dist/style.css";

const color = "#294e87";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));
function SortArray(x, y) {
  if (x.firstname < y.firstname) {
    return -1;
  }
  if (x.firstname > y.firstname) {
    return 1;
  }
  return 0;
}
export default function Form() {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const decodedToken = jwt(token);

  const config = JSON.parse(localStorage.getItem("config"));

  const [value, setValue] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [typesLeave, setTypesLeave] = useState([]);
  const [selected, setSelected] = useState(null);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [personNotif, setPersonNotif] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [balance, setBalance] = useState(0);
  const [sickBalance, setSickBalance] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const [newSickBalance, setNewSickBalance] = useState(0);

  const [nbDays, setNbDays] = useState(0);
  const [nbRequests, setNbRequests] = useState(0);
  const [pendingDays, setPendingDays] = useState(0);
  const [requestedDays, setRequestedDays] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [disabledEndSlots, setDisabledEndSlots] = useState(false);
  const [slotStart, setSlotStart] = useState("");
  const [slotEnd, setSlotEnd] = useState("");
  const [loading, setloading] = useState(false);
  const [dead, setDead] = useState(null);
  const [comment, setComment] = useState("");
  const [language, setLanguage] = useState("");
  const [balances, setBalances] = useState({});
  const [newBalances, setNewBalances] = useState({});
  const [note, setNote] = useState("");

  var date = new Date();
  var dateStart = new Date();
  var dateEnd = new Date();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language == "fr") {
      setLanguage(fr);
    } else {
      setLanguage(enUS);
    }

    const validatorName = `${user?.secondValidator?.firstname} ${user?.secondValidator?.lastname}`;
    const leaveNote =
      i18n.language === "fr" ? config?.LeaveNoteFR : config?.LeaveNoteEN;
    const updatedMessage = leaveNote
      .replace(/{{validator}}/g, validatorName)
      .replace(/{{threshold}}/g, config.threshold);

    setNote(updatedMessage);
  }, [i18n.language, user]);

  useEffect(() => {
    const fetchData = async () => {
      const typesResult = await fetchSetting("Leave");
      setTypesLeave(typesResult);
      const user = await fetchUser(token, decodedToken.id);
      setUser(user);
      const result = await fetchValidators();

      var listValidators = result
        .filter((item) => item._id !== user._id)
        .sort(SortArray);
      const list = listValidators.map((item) => {
        return {
          ...item,
          value: item._id,
          label: item.firstname + " " + item.lastname,
        };
      });
      setManagers(list);
      const defaultManager = list.find((item) => item._id === user.manager._id);
      setSelected(defaultManager);

      const users = await fetchALLUsers(token);
      setUsers(users.sort(SortArray));
      const holidaysResult = await fetchSetting("Holidays");
      var dates = [];
      holidaysResult.map((item) => {
        var start = new Date(item.date_debut);
        var end = new Date(item.date_fin);
        while (start <= end) {
          var date = start.toISOString().substring(0, 10);
          dates.push({ date: date, type: item.type });
          start.setDate(start.getDate() + 1);
        }
      });
      setHolidays(dates);
      const balances = await fetchUserBalances(token, decodedToken.id);
      setBalances({
        sickBalance: balances[0]?.sickBalance,
        LeaveBalance: balances[0]?.LeaveBalance,
      });
      setNewBalances({
        sickBalance: balances[0]?.sickBalance,
        LeaveBalance: balances[0]?.LeaveBalance,
      });
      setBalance(balances[0]?.LeaveBalance);
      setSickBalance(balances[0]?.sickBalance);
      setNewBalance(balances[0]?.LeaveBalance);
      setNewSickBalance(balances[0]?.sickBalance);
      const requests = await fetchRequestsByUser(token, user._id);
      var leaveRequests = requests.filter(
        (item) => item.status == "pending" && item.name == "Leave"
      );
      var nb = await fetchPendingDays(user._id);
      setNbRequests(leaveRequests.length);
      setPendingDays(nb);
    };
    fetchData();
  }, []);

  const handleChangeSlotStart = (event, newAlignment) => {
    setSlotStart(newAlignment);
  };

  const handleChangeSlotEnd = (event, newAlignment) => {
    setSlotEnd(newAlignment);
  };

  function disableWeekends(date) {
    return (
      new Date(date).getDay() === 0 ||
      new Date(date).getDay() === 6 ||
      holidays
        .map((object) => object.date)
        .indexOf(moment(date).format().substring(0, 10)) > 0
    );
  }

  useEffect(() => {
    if (selectedType?.category == "compensatory") {
      // console.log(selectedType?.window, moment(date).subtract(selectedType?.window, 'days').format())
      // date.setDate(date.getDay() - selectedType?.window)
      // setMinDate(moment(date).subtract(selectedType?.window, 'days').format())
      // console.log(date)
      dateStart = new Date();
      dateEnd = new Date();
    }
    //  else {
    //  setMinDate(new Date());
    // }
    setStartDate(null);
    setEndDate(null);
    setSlotStart("");
    setSlotEnd("");
    setNbDays(0);
    setRequestedDays(0);
    setNewBalance(balance);
    setNewBalances(balances);
    setDisabledEndSlots(false);
    if (selectedType?.type) {
      setDisabled(false);
    }
  }, [selectedType]);

  useEffect(() => {
    if (startDate && endDate && selectedType) {
      if (moment(endDate).format("L") < moment(startDate).format("L")) {
        NotificationManager.warning("Invalid dates");
      }
      if (
        selectedType?.typeBalance == "sickBalance" &&
        newBalances[selectedType.typeBalance] < 0
      ) {
        NotificationManager.warning(
          <Translation message="You cannot submit a request for sick leave that exceeds the indicated sick balance" />
        );
      } else {
        if (moment(endDate).format("L") == moment(startDate).format("L")) {
          setDisabledEndSlots(true);
          setSlotEnd(slotStart);
          //   if( slotStart == "AM" ||  slotStart == "PM" ) {
          //     setNewBalance(balance - 0.5)
          //         setNbDays(0.5)

          // } else if ( slotStart == "Full") {
          //     setNewBalance(balance - 1)
          //     setNbDays(1)
          // }
          var value = balances[selectedType?.typeBalance];

          if (selectedType?.category == "paid") {
            if (slotStart == "AM" || slotStart == "PM") {
              setNewBalance(value - 0.5);
              setNbDays(0.5);
              setRequestedDays(0.5);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value - 0.5,
              }));
            } else if (slotStart == "Full") {
              setNewBalance(value - 1);
              setNbDays(1);
              setRequestedDays(1);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value - 1,
              }));
            }
          } else if (selectedType?.category == "compensatory") {
            if (slotStart == "AM" || slotStart == "PM") {
              setNewBalance(value + 0.5);
              setNbDays(0.5);
              setRequestedDays(0.5);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value + 0.5,
              }));
            } else if (slotStart == "Full") {
              setNewBalance(value + 1);
              setNbDays(1);
              setRequestedDays(1);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value + 1,
              }));
            }
          } else if (selectedType?.category == "exceptional") {
            setNbDays(0);
            setRequestedDays(0);
            setNewBalance(balance);
            setNewBalances(balances);
            if (slotStart == "AM" || slotStart == "PM") {
              setRequestedDays(0.5);
            } else if (slotStart == "Full") {
              setRequestedDays(1);
            }
          }
          //   else {

          //     endPeriod = moment(currDate).add(selectedType?.NBMonths, 'months')
          //     endPeriod = endPeriod.add(selectedType?.NBDays - 1, 'days')

          //   }
        } else {
          setDisabledEndSlots(false);
          if (slotStart && slotEnd) {
            // setDisabledEndSlots(false)
            // var value = balance;
            var value = balances[selectedType?.typeBalance];
            setNbDays(0);
            setRequestedDays(0);

            if (selectedType?.category == "paid") {
              var dates = [];
              var currDate = moment(startDate).startOf("day");
              var lastDate = moment(endDate).startOf("day");
              const start = currDate.clone().format();
              const end = lastDate.clone().format();

              while (currDate.add(1, "days").diff(lastDate) < 0) {
                console.log(currDate, moment(currDate).format("dddd"));
                const index = holidays
                  .map((object) => object.date)
                  .indexOf(moment(currDate).format().substring(0, 10));
                var paid = index > -1 && holidays[index].type == "paid";

                if (
                  moment(currDate).format("dddd") != "Saturday" &&
                  moment(currDate).format("dddd") != "Sunday" &&
                  moment(currDate).format("dddd").toLowerCase() != "samedi" &&
                  moment(currDate).format("dddd").toLowerCase() != "dimanche" &&
                  !paid
                ) {
                  var d = currDate.clone().format();
                  dates.push(d.toString().substring(0, 10));
                }
              }

              value = value - dates.length;
              setNbDays(dates.length);
              setRequestedDays(dates.length);

              if ((slotStart == "AM" && start != end) || slotStart == "Full") {
                value = value - 1;
                setNbDays((nbDays) => nbDays + 1);
                setRequestedDays((requestedDays) => requestedDays + 1);
              } else if (
                slotStart == "PM" ||
                (slotStart == "AM" && start == end)
              ) {
                value = value - 0.5;
                setNbDays((nbDays) => nbDays + 0.5);
                setRequestedDays((requestedDays) => requestedDays + 0.5);
              }

              if (start != end) {
                if (slotEnd == "PM" || slotEnd == "Full") {
                  value = value - 1;
                  setNbDays((nbDays) => nbDays + 1);
                  setRequestedDays((requestedDays) => requestedDays + 1);
                } else if (slotEnd == "AM") {
                  value = value - 0.5;
                  setNbDays((nbDays) => nbDays + 0.5);
                  setRequestedDays((requestedDays) => requestedDays + 0.5);
                }
              } else {
                setSlotEnd(slotStart);
                setDisabledEndSlots(true);
              }
              setNewBalance(value);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value,
              }));
            } else if (selectedType?.category == "compensatory") {
              var dates = [];
              var currDate = moment(startDate).startOf("day");
              var lastDate = moment(endDate).startOf("day");
              const start = currDate.clone().format();
              const end = lastDate.clone().format();

              while (currDate.add(1, "days").diff(lastDate) < 0) {
                var d = currDate.clone().format();
                dates.push(d.toString().substring(0, 10));
              }

              value = value + dates.length;
              setNbDays(dates.length);
              if ((slotStart == "AM" && start != end) || slotStart == "Full") {
                value = value + 1;
                setNbDays((nbDays) => nbDays + 1);
                setRequestedDays((requestedDays) => requestedDays + 1);
              } else if (
                slotStart == "PM" ||
                (slotStart == "AM" && start == end)
              ) {
                value = value + 0.5;
                setNbDays((nbDays) => nbDays + 0.5);
                setRequestedDays((requestedDays) => requestedDays + 0.5);
              }

              if (start != end) {
                if (slotEnd == "PM" || slotEnd == "Full") {
                  value = value + 1;
                  setNbDays((nbDays) => nbDays + 1);
                  setRequestedDays((requestedDays) => requestedDays + 1);
                } else if (slotEnd == "AM") {
                  value = value + 0.5;
                  setNbDays((nbDays) => nbDays + 0.5);
                  setRequestedDays((requestedDays) => requestedDays + 0.5);
                }
              } else {
                setSlotEnd(slotStart);
                setDisabledEndSlots(true);
              }
              setNewBalance(value);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value,
              }));
            } else if (selectedType?.category == "exceptional") {
              var dates = [];
              var currDate = moment(startDate).startOf("day");
              var lastDate = moment(endDate).startOf("day");
              var start = currDate.subtract(1, "days");
              const end = lastDate.clone().format();
              var count = 0;

              if (selectedType?.NBMonths && selectedType?.NBMonths > 0) {
                start = moment(currDate).add(selectedType?.NBMonths, "months");
              }

              while (start.add(1, "days").diff(lastDate) <= 0) {
                // console.log(moment(start).format());

                // count++;
                // if (count > selectedType?.NBDays) {
                //   NotificationManager.warning(
                //     "Period longer than the declared period"
                //   );
                // }
                const index = holidays
                  .map((object) => object.date)
                  .indexOf(moment(start).format().substring(0, 10));
                var paid = index > -1 && holidays[index].type == "paid";
                if (
                  moment(start).format("dddd") != "Saturday" &&
                  moment(start).format("dddd") != "Sunday" &&
                  moment(start).format("dddd").toLowerCase() != "samedi" &&
                  moment(start).format("dddd").toLowerCase() != "dimanche" &&
                  !paid
                  //  &&
                  // count > selectedType?.NBDays
                ) {
                  setRequestedDays((requestedDays) => requestedDays + 1);
                  count++;
                  if (count > selectedType?.NBDays) {
                    NotificationManager.warning(
                      <Translation message="The period of leave chosen exceeds the days offered" />
                    );
                    var d = start.clone().format();
                    dates.push(d.toString().substring(0, 10));
                    console.log(
                      "🚀 ~ file: Form.js:362 ~ useEffect ~ dates:",
                      dates
                    );
                  }
                }
              }

              var nb = dates.length;

              if (slotEnd == "AM" && count > selectedType?.NBDays) {
                nb = nb - 0.5;
              }

              if (slotStart == "PM") {
                setRequestedDays((requestedDays) => requestedDays - 0.5);
                nb = nb - 0.5;
              }

              if (slotEnd == "AM") {
                setRequestedDays((requestedDays) => requestedDays - 0.5);
              }

              setNbDays(nb);
              setNewBalance(value - nb);
              setNewBalances((prevBalances) => ({
                ...prevBalances,
                [selectedType.typeBalance]: value - nb,
              }));
            }
          }
        }
      }
    }

    if (startDate && endDate && !selectedType && slotStart && slotEnd) {
      NotificationManager.warning(
        <Translation message="Choose the type of leave" />
      );
    }
  }, [startDate, endDate, slotStart, slotEnd]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const modifiers = {
    disabled: [
      { dayOfWeek: [0, 6] },
      ...holidays.map((holiday) => new Date(holiday.date)),
      {
        after: selectedType?.dateControl == "after" ? new Date() : null,
        before:
          selectedType?.dateControl == "after"
            ? subDays(date, selectedType?.window)
            : selectedType?.dateControl == "before"
            ? new Date()
            : selectedType?.dateControl == "" && selectedType?.window
            ? subDays(date, selectedType?.window)
            : null,
      },
    ],
    // renderDay: renderDay,
    holiday: (date) => {
      const foundHoliday = holidays.find(
        (item) => item.date == format(date, "yyyy-MM-dd")
      );

      return foundHoliday !== undefined;
    },

    // minDate: new Date(),
    // maxDate: new Date("2023-12-19"),
  };

  const modifiersCompensatory = {
    disabled: [
      // { dayOfWeek: [0, 6] },
      // ...holidays.map((holiday) => new Date(holiday.date)),
      {
        after:
          selectedType?.dateControl?.length != 0 &&
          selectedType?.dateControl == "before"
            ? addDays(date, selectedType?.window)
            : new Date(),
        before:
          selectedType?.dateControl?.length != 0 &&
          selectedType?.dateControl == "after"
            ? subDays(date, selectedType?.window)
            : null,
      },
    ],
    // renderDay: renderDay,
    holiday: (date) => {
      const foundHoliday = holidays.find(
        (item) => item.date == format(date, "yyyy-MM-dd")
      );

      return foundHoliday !== undefined;
    },

    // minDate: new Date(),
    // maxDate: new Date("2023-12-19"),
  };

  const modifiersStyles = {
    holiday: {
      color: "white",
      backgroundColor: "#567791",
      borderRadius: "50px",
      position: "relative",
      ":hover": {
        color: "red",
      },
      // "::after": {
      //   content: '"Holiday!"',
      //   position: "absolute",
      //   width: "100%",
      //   height: "100%",
      //   top: "0",
      //   left: "0",
      //   backgroundColor: "rgba(0, 0, 0, 0.8)",
      //   color: "white",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   opacity: "0",
      //   transition: "opacity 0.3s",
      //   pointerEvents: "none",
      // },
      // ":hover::after": {
      //   opacity: "1",
      // },
    },
  };

  const handleSend = async () => {
    if (!selectedType || selectedType?.type == "Choose the type of leave") {
      NotificationManager.warning(
        <Translation message="Choose the type of leave" />
      );
      return;
    }

    if (selectedType && selectedType?.relationship && !dead) {
      NotificationManager.warning(
        <Translation message="Choose the relationship" />
      );
      return;
    }

    if (!selected || Object.keys(selected).length === 0) {
      NotificationManager.warning(
        <Translation message="Choose your validator" />
      );
      return;
    }

    if (personNotif.length > 0) {
      var usersNotif = Array.from(personNotif, ({ value }) => value);
    } else {
      var usersNotif = [];
    }

    if (!startDate || !endDate) {
      NotificationManager.warning(
        <Translation message="Choose the period of leave" />
      );
      return;
    }

    if (!slotStart || !slotEnd) {
      NotificationManager.warning(
        <Translation message="Choose the slots of leave" />
      );
      return;
    }

    if (
      selectedType?.typeBalance == "sickBalance" &&
      newBalances[selectedType.typeBalance] < 0
    ) {
      NotificationManager.warning(
        <Translation message="You cannot submit a request for sick leave that exceeds the indicated sick balance" />
      );
      return;
    }

    var data = {
      name: "Leave",
      idSender: decodedToken.id,
      idReciever: selected._id,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      startSlot: slotStart,
      endSlot: slotEnd,
      type: selectedType?.type,
      UserNotif: usersNotif,
      commentUser: comment,
      relationship: dead,
    };

    setloading(true);
    const result = await addRequest(token, data);
    setloading(false);
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        setTimeout(() => {
          window.location.href = "/requests";
        }, 1000);
      }
    } else {
      NotificationManager.error(result.message);
    }
  };

  return (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="container max-w-7xl mx-auto px-4">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <Link to="/leave" className="opacity-60">
                  <span>Leave</span>
                </Link>
              </Breadcrumbs>
            </div>
          )}
        </div>
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              {isDesktop ? (
                <>
                  <div className="w-full text-center">
                    <Typography variant="h3" style={{ color: color }}>
                      <Translation
                        message={"Are you planning to take days off?"}
                      />
                    </Typography>

                    <Typography variant="small" color="gray" className="mt-2">
                      <Translation
                        message={
                          "Complete this form to send a leave's request."
                        }
                      />
                    </Typography>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    {(config?.LeaveNoteEN || config?.LeaveNoteFR) && (
                      <Alert
                        // icon={<IconOutlined />}
                        className="mt-10 rounded-none border-l-4 border-[#2863bd] bg-[#2e98c9]/10 font-medium text-[#2863bd]"
                      >
                        {Object.keys(newBalances).length > 1 ? (
                          <Translation message="Your balances" />
                        ) : (
                          <Translation message="Your balance" />
                        )}
                        <ul className="mt-2 ml-5 list-inside list-disc">
                          {Object.entries(newBalances).map(([key, value]) => (
                            <li>
                              <Translation
                                message={`${
                                  key == "sickBalance"
                                    ? "Sick balance"
                                    : "Leave balance"
                                }`}
                              />{" "}
                              : <b>{value}</b>
                            </li>
                          ))}
                        </ul>
                        <div className="flex gap-2">
                          <IconOutlined /> <p className="mt-2">{note}</p>
                        </div>
                      </Alert>
                    )}
                    <div className="flex gap-8 mt-16 mt-5">
                      <div className="w-150">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Type of Leave" />
                        </Typography>
                      </div>

                      {/* <Select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e)}
                        options={typesLeave}
                        field="type"
                      /> */}
                      <Select
                        options={typesLeave.map((item) => {
                          return {
                            ...item,
                            value: item.type,
                            label: item.type,
                          };
                        })}
                        onChange={(e) => {
                          setSelectedType(e);
                        }}
                        value={selectedType}
                        placeholder={
                          <Translation message="Choose the type of leave" />
                        }
                        isClearable={true}
                        formatOptionLabel={(option) => {
                          let label = option.label;
                          let desc = "";
                          if (option.NBMonths && option.NBMonths !== 0) {
                            desc += `  ${option.NBMonths} ${
                              option.NBMonths === 1 ? t("month") : t("months")
                            }`;
                          }
                          if (option.NBDays && option.NBDays !== 0) {
                            desc += `  ${option.NBDays} ${
                              option.NBDays === 1 ? t("day") : t("days")
                            }`;
                          }
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>{label}</p>
                              <Typography variant="small" color="gray">
                                {" "}
                                {desc}
                              </Typography>
                            </div>
                          );
                          // return label;
                        }}
                      />
                    </div>
                    {selectedType && selectedType?.comment && (
                      <div className="flex gap-8">
                        <div className="w-150"></div>
                        <Typography className=" text-sm  text-gray-500 pt-1">
                          NB: {selectedType?.comment}
                        </Typography>
                      </div>
                    )}

                    {selectedType?.relationship && (
                      <div className="flex gap-8 mt-16 mt-5">
                        <div className="w-150">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message="Relationship" />
                          </Typography>
                        </div>
                        <Select
                          options={selectedType?.relationship?.map((item) => {
                            return {
                              ...item,
                              value: item,
                              label: item,
                            };
                          })}
                          onChange={(e) => {
                            setDead(e);
                          }}
                          value={dead}
                          placeholder={
                            <Translation message="Choose the relationship" />
                          }
                        />
                      </div>
                    )}

                    <div className="flex gap-8 mt-16 mb-12">
                      <div className="w-150">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Validator" />
                        </Typography>
                      </div>
                      {/* <UsersSelect
                        value={selected}
                        onChange={(e) => setSelected(e)}
                        options={managers}
                        field={["firstname", "lastname"]}
                      /> */}

                      <div style={{ width: "500px" }}>
                        <Select
                          options={managers}
                          onChange={(e) => {
                            setSelected(e);
                          }}
                          value={selected}
                          placeholder={
                            <Translation message="Choose your validator" />
                          }
                          isClearable={true}
                          isDisabled={true}
                        />
                      </div>
                    </div>

                    <div className="flex gap-8 mt-16 mb-12">
                      <div className="w-150">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Persons to notify" />
                        </Typography>
                      </div>
                      {users && (
                        <SelectAutocomplete
                          users={users}
                          setPerson={setPersonNotif}
                        />
                      )}
                    </div>

                    <div className="flex gap-8 mt-16 mt-5">
                      <div className="w-250">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="Start date" />
                        </Typography>
                      </div>

                      {/* {selectedType && ( */}
                      {selectedType?.category == "compensatory" ? (
                        // <DatePicker
                        //   style={{ outline: "none" }}
                        //   label="From"
                        //   inputFormat="dd/MM/yyyy"
                        //   value={startDate}
                        //   onChange={(newValue) => {
                        //     setStartDate(newValue);
                        //   }}
                        //   // minDate={dateStart.setDate(date.getDate()-selectedType?.window)}
                        //   minDate={
                        //     selectedType?.dateControl?.length != 0 &&
                        //     selectedType?.dateControl == "after"
                        //       ? dateStart.setDate(
                        //           date.getDate() - selectedType?.window
                        //         )
                        //       : null
                        //   }
                        //   maxDate={
                        //     selectedType?.dateControl?.length != 0 &&
                        //     selectedType?.dateControl == "before"
                        //       ? dateStart.setDate(
                        //           date.getDate() + selectedType?.window
                        //         )
                        //       : new Date()
                        //   }
                        //   // shouldDisableDate={disableDays}
                        //   renderInput={(params) => (
                        //     <TextField
                        //       style={{ outline: "none" }}
                        //       {...params}
                        //     />
                        //   )}
                        //   renderDay={(day, _value, DayComponentProps) => {
                        //     const isSelected =
                        //       !DayComponentProps.outsideCurrentMonth &&
                        //       holidays
                        //         .map((object) => object.date)
                        //         .indexOf(
                        //           moment(day).format().substring(0, 10)
                        //         ) > 0;

                        //     return (
                        //       <Badge
                        //         key={day.toString()}
                        //         overlap="circular"
                        //         badgeContent={isSelected ? "🎉" : undefined}
                        //         style={{ outline: "none" }}
                        //       >
                        //         <PickersDay
                        //           style={{ outline: "none" }}
                        //           {...DayComponentProps}
                        //         />
                        //       </Badge>
                        //     );
                        //   }}
                        // />
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message="From" />}
                              onChange={() => null}
                              value={
                                startDate ? format(startDate, "dd-MM-yyyy") : ""
                              }
                              disabled={disabled}

                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={startDate}
                              onSelect={setStartDate}
                              showOutsideDays
                              className="border-0"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiersCompensatory}
                              modifiersClassNames={{
                                holiday: "holiday",
                              }}
                              // modifiersStyles={modifiersStyles}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message="From" />}
                              onChange={() => null}
                              value={
                                startDate ? format(startDate, "dd-MM-yyyy") : ""
                              }
                              disabled={disabled}
                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={startDate}
                              onSelect={setStartDate}
                              // onSelect={(e) => {
                              //   if (
                              //     selectedType?.typeBalance == "sickBalance" &&
                              //     newBalances[selectedType.typeBalance] <= 0
                              //   ) {
                              //     NotificationManager.warning(
                              //       <Translation message="Your leave balance has been used up." />
                              //     );
                              //   } else {
                              //     setStartDate(e);
                              //   }
                              // }}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiers}
                              // modifiersStyles={modifiersStyles}
                              modifiersClassNames={{
                                holiday: "holiday",
                              }}
                              // disabledDays={{ after: new Date() }}

                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      )}
                      <ToggleButtonGroup
                        color="primary"
                        value={slotStart}
                        exclusive
                        onChange={handleChangeSlotStart}
                      >
                        <ToggleButton value="AM" style={{ outline: "none" }}>
                          AM
                        </ToggleButton>
                        <ToggleButton value="PM" style={{ outline: "none" }}>
                          PM
                        </ToggleButton>
                        {disabledEndSlots && (
                          <ToggleButton
                            value="Full"
                            style={{ outline: "none" }}
                          >
                            ALL DAY
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </div>

                    <div className="flex gap-8 mt-16 mt-5">
                      <div className="w-250">
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation message="End date" />
                        </Typography>
                      </div>
                      {selectedType?.category == "compensatory" ? (
                        // <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message=" To " />}
                              onChange={() => null}
                              value={
                                endDate ? format(endDate, "dd-MM-yyyy") : ""
                              }
                              disabled={disabled}

                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={endDate}
                              onSelect={setEndDate}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              // modifiers={modifiersCompensatory}
                              // modifiersStyles={modifiersStyles}
                              modifiers={modifiersCompensatory}
                              modifiersClassNames={{
                                holiday: "holiday",
                              }}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      ) : (
                        // </LocalizationProvider>
                        // <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message=" To " />}
                              onChange={() => null}
                              value={
                                endDate ? format(endDate, "dd-MM-yyyy") : ""
                              }
                              disabled={disabled}

                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={endDate}
                              onSelect={setEndDate}
                              // onSelect={(e) => {
                              //   if (
                              //     selectedType?.typeBalance == "sickBalance" &&
                              //     newBalances[selectedType.typeBalance] <= 0
                              //   ) {
                              //     NotificationManager.warning(
                              //       <Translation message="Your leave balance has been used up." />
                              //     );
                              //     if (isBefore(endDate, e)) {
                              //       setEndDate(e);
                              //     }
                              //   } else {
                              //     setEndDate(e);
                              //   }
                              // }}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiers}
                              modifiersStyles={modifiersStyles}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                        // </LocalizationProvider>
                      )}

                      <ToggleButtonGroup
                        color="primary"
                        value={slotEnd}
                        exclusive
                        onChange={handleChangeSlotEnd}
                        disabled={disabledEndSlots}
                      >
                        <ToggleButton value="AM" style={{ outline: "none" }}>
                          AM
                        </ToggleButton>
                        <ToggleButton value="PM" style={{ outline: "none" }}>
                          PM
                        </ToggleButton>
                        {disabledEndSlots && (
                          <ToggleButton
                            value="Full"
                            style={{ outline: "none" }}
                          >
                            ALL DAY
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </div>

                    {selectedType?.typeBalance == "sickBalance" &&
                      newBalances[selectedType.typeBalance] <= 0 && (
                        <Alert
                          icon={<Icon />}
                          className="mt-2 rounded-none border-l-4 border-[#ef7918] bg-[#fca965]/10 font-medium text-[#ef7918]"
                        >
                          <Translation message="Your leave balance has been used up." />
                        </Alert>
                      )}
                    <div className="mt-10 mb-5">
                      {Object.entries(balances).map(([key, value]) => (
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation
                            message={`Current ${
                              key == "sickBalance"
                                ? "Sick balance"
                                : "Leave balance"
                            }`}
                          />{" "}
                          : <b>{value}</b>
                        </Typography>
                      ))}
                    </div>
                    {/* <Typography className=" text-sm  text-gray-700 pt-1 mt-10">
                      <Translation message="Current Leave balance" /> :{" "}
                      <b>{balance}</b>
                    </Typography>
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Current sick balance" /> :{" "}
                      <b>{sickBalance}</b>
                    </Typography> */}
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of requested days" />:{" "}
                      <b>
                        {requestedDays}
                        {/* {selectedType?.NBMonths && selectedType?.NBDays
                      ? selectedType?.NBMonths +
                        " months and " +
                        selectedType?.NBDays +
                        nbDays +
                        " days"
                      : selectedType?.NBDays
                      ? selectedType?.NBDays + nbDays + " days"
                      : 0} */}
                      </b>
                    </Typography>
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of counted days" />:{" "}
                      <b>{nbDays}</b>
                    </Typography>
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Pending requests" /> :{" "}
                      <b>
                        {nbRequests}{" "}
                        {pendingDays > 0 &&
                          `( ${pendingDays} ${
                            pendingDays > 1 ? t("days") : t("day")
                          } )`}
                      </b>
                    </Typography>
                    {/* <div className="flex gap-8 mt-10 mb-12">
                      <Typography className=" text-sm  text-blue-700 pt-1">
                        <Translation message="New balance" /> :{" "}
                        <b>{newBalance}</b>
                      </Typography>
                    </div> */}
                    <div className="mt-10">
                      {Object.entries(newBalances).map(([key, value]) => (
                        <Typography className=" text-sm  text-blue-700 pt-1">
                          <Translation
                            message={`New ${
                              key == "sickBalance"
                                ? "Sick balance"
                                : "Leave balance"
                            }`}
                          />{" "}
                          : <b>{value}</b>
                        </Typography>
                      ))}
                    </div>

                    <div className="flex gap-8 mt-16 mb-12">
                      <Textarea
                        color="indigo"
                        // placeholder="If you have any comments write here"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        label={
                          <Translation message="Leave your comment here" />
                        }
                      />
                    </div>

                    <div className="flex justify-center mt-20">
                      <Button
                        color="indigo"
                        ripple="light"
                        onClick={() => {
                          handleSend();
                        }}
                      >
                        <ClipLoader color="white" loading={loading} size={20} />
                        <Translation message="Send Request" />
                      </Button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="w-full text-center">
                    <Typography
                      variant="h5"
                      style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                    >
                      <Translation
                        message={"Are you planning to take days off?"}
                      />
                    </Typography>

                    <Typography variant="small" color="gray" className="mt-2">
                      <Translation
                        message={
                          "Complete this form to send a leave's request."
                        }
                      />
                    </Typography>
                  </div>

                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex gap-8 mt-16 mt-5">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Type of Leave"} />
                      </Typography>
                    </div>
                    <div className="flex gap-8 pt-1">
                      <Select
                        options={typesLeave.map((item) => {
                          return {
                            ...item,
                            value: item.type,
                            label: item.type,
                          };
                        })}
                        onChange={(e) => {
                          setSelectedType(e);
                        }}
                        value={selectedType}
                        placeholder={
                          <Translation message="Choose the type of leave" />
                        }
                        isClearable={true}
                        style={{ width: 200 }}
                        formatOptionLabel={(option) => {
                          let label = option.label;
                          if (option.NBMonths && option.NBMonths !== 0) {
                            label += ` - ${option.NBMonths} ${
                              option.NBMonths === 1 ? t("month") : t("months")
                            }`;
                          }
                          if (option.NBDays && option.NBDays !== 0) {
                            label += ` - ${option.NBDays} ${
                              option.NBDays === 1 ? t("day") : t("days")
                            }`;
                          }
                          return label;
                        }}
                      />
                    </div>
                    {selectedType && selectedType?.comment && (
                      <div className="flex gap-8">
                        <Typography className=" text-sm  text-gray-500 pt-1">
                          NB: {selectedType?.comment}
                        </Typography>
                      </div>
                    )}

                    {selectedType?.relationship && (
                      <>
                        <div className="flex gap-8 mt-5">
                          <Typography className=" text-sm  text-gray-700 pt-1">
                            <Translation message="Relationship" />
                          </Typography>
                        </div>
                        <div className="flex gap-8 pt-1">
                          <Select
                            options={selectedType?.relationship?.map((item) => {
                              return {
                                ...item,
                                value: item,
                                label: item,
                              };
                            })}
                            onChange={(e) => {
                              setDead(e);
                            }}
                            value={dead}
                            placeholder={
                              <Translation message="Choose the relationship" />
                            }
                          />
                        </div>
                      </>
                    )}

                    <div className="flex gap-8 mt-5">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Validator" />
                      </Typography>
                    </div>
                    <div className="flex gap-8 pt-1">
                      <Select
                        options={managers}
                        onChange={(e) => {
                          setSelected(e);
                        }}
                        value={selected}
                        placeholder={
                          <Translation message="Choose your validator" />
                        }
                        isClearable={true}
                      />
                    </div>

                    <div className="flex gap-8 mt-5">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Persons to notify" />
                      </Typography>
                    </div>
                    <div className="flex gap-8 pt-1">
                      {users && (
                        <SelectAutocomplete
                          users={users}
                          setPerson={setPersonNotif}
                        />
                      )}
                    </div>

                    <div className="flex gap-8 mt-5">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="Start date" />
                      </Typography>
                    </div>

                    <div className="flex gap-8 pt-1">
                      {selectedType?.category == "compensatory" ? (
                        // <DatePicker
                        //   style={{ outline: "none" }}
                        //   label="From"
                        //   inputFormat="dd/MM/yyyy"
                        //   value={startDate}
                        //   onChange={(newValue) => {
                        //     setStartDate(newValue);
                        //   }}
                        //   // minDate={dateStart.setDate(date.getDate()-selectedType?.window)}
                        //   minDate={
                        //     selectedType?.dateControl?.length != 0 &&
                        //     selectedType?.dateControl == "after"
                        //       ? dateStart.setDate(
                        //           date.getDate() - selectedType?.window
                        //         )
                        //       : null
                        //   }
                        //   maxDate={
                        //     selectedType?.dateControl?.length != 0 &&
                        //     selectedType?.dateControl == "before"
                        //       ? dateStart.setDate(
                        //           date.getDate() + selectedType?.window
                        //         )
                        //       : new Date()
                        //   }
                        //   // shouldDisableDate={disableDays}
                        //   renderInput={(params) => (
                        //     <TextField
                        //       style={{ outline: "none" }}
                        //       {...params}
                        //     />
                        //   )}
                        //   renderDay={(day, _value, DayComponentProps) => {
                        //     const isSelected =
                        //       !DayComponentProps.outsideCurrentMonth &&
                        //       holidays
                        //         .map((object) => object.date)
                        //         .indexOf(
                        //           moment(day).format().substring(0, 10)
                        //         ) > 0;

                        //     return (
                        //       <Badge
                        //         key={day.toString()}
                        //         overlap="circular"
                        //         badgeContent={isSelected ? "🎉" : undefined}
                        //         style={{ outline: "none" }}
                        //       >
                        //         <PickersDay
                        //           style={{ outline: "none" }}
                        //           {...DayComponentProps}
                        //         />
                        //       </Badge>
                        //     );
                        //   }}
                        // />
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message="From" />}
                              onChange={() => null}
                              value={
                                startDate ? format(startDate, "dd-MM-yyyy") : ""
                              }

                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={startDate}
                              onSelect={setStartDate}
                              showOutsideDays
                              className="border-0"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiersCompensatory}
                              modifiersClassNames={{
                                holiday: "holiday",
                              }}
                              // modifiersStyles={modifiersStyles}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message="From" />}
                              onChange={() => null}
                              value={
                                startDate ? format(startDate, "dd-MM-yyyy") : ""
                              }
                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={startDate}
                              onSelect={setStartDate}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiers}
                              // modifiersStyles={modifiersStyles}
                              modifiersClassNames={{
                                holiday: "holiday",
                              }}
                              // disabledDays={{ after: new Date() }}

                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      )}
                      {/* </div>
                    <div className="flex gap-8 mt-5"> */}
                      <ToggleButtonGroup
                        color="primary"
                        value={slotStart}
                        exclusive
                        onChange={handleChangeSlotStart}
                      >
                        <ToggleButton value="AM" style={{ outline: "none" }}>
                          AM
                        </ToggleButton>
                        <ToggleButton value="PM" style={{ outline: "none" }}>
                          PM
                        </ToggleButton>
                        {disabledEndSlots && (
                          <ToggleButton
                            value="Full"
                            style={{ outline: "none" }}
                          >
                            ALL DAY
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </div>

                    <div className="flex gap-8 mt-5">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message="End date" />
                      </Typography>
                    </div>
                    <div className="flex gap-8 pt-1">
                      {selectedType?.category == "compensatory" ? (
                        // <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message=" To " />}
                              onChange={() => null}
                              value={
                                endDate ? format(endDate, "dd-MM-yyyy") : ""
                              }
                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={endDate}
                              onSelect={setEndDate}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiersCompensatory}
                              modifiersStyles={modifiersStyles}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                      ) : (
                        // </LocalizationProvider>
                        // <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Popover placement="bottom">
                          <PopoverHandler>
                            <Input
                              label={<Translation message=" To " />}
                              onChange={() => null}
                              value={
                                endDate ? format(endDate, "dd-MM-yyyy") : ""
                              }
                              // color="indigo"
                            />
                          </PopoverHandler>
                          <PopoverContent>
                            <DayPicker
                              locale={language}
                              mode="single"
                              selected={endDate}
                              onSelect={setEndDate}
                              showOutsideDays
                              className="border-0 Birthdays"
                              classNames={{
                                caption:
                                  "flex justify-center py-2 mb-4 relative items-center",
                                caption_label:
                                  "text-sm font-medium text-gray-900",
                                nav: "flex items-center",
                                nav_button:
                                  "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5",
                                nav_button_next: "absolute right-1.5",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-gray-900",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                  "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                                day_today:
                                  "rounded-md bg-gray-200 text-gray-900",
                                day_outside:
                                  "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                day_disabled: "text-gray-500 opacity-50",
                                day_hidden: "invisible",
                              }}
                              components={{
                                IconLeft: ({ ...props }) => (
                                  <ChevronLeftIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                IconRight: ({ ...props }) => (
                                  <ChevronRightIcon
                                    {...props}
                                    className="h-4 w-4 stroke-2"
                                  />
                                ),
                                // Caption: CaptionWithIcons,
                                // Day: ({ date, modifiers, onClick }) => {
                                //   // Customize the rendering of each day cell here
                                //   // const isToday = modifiers.today;
                                //   // const isSelected = modifiers.selected;
                                //   // const isOutside = modifiers.outside;

                                //   // Customize the rendering based on your requirements
                                //   return (
                                //     <div>
                                //       {date.getDate()} {"🎉"}
                                //     </div>
                                //   );
                                // },
                              }}
                              modifiers={modifiers}
                              modifiersStyles={modifiersStyles}
                              // components={{ Caption: CaptionWithIcons }}
                            />
                          </PopoverContent>
                        </Popover>
                        // </LocalizationProvider>
                      )}

                      <ToggleButtonGroup
                        color="primary"
                        value={slotEnd}
                        exclusive
                        onChange={handleChangeSlotEnd}
                        disabled={disabledEndSlots}
                      >
                        <ToggleButton value="AM" style={{ outline: "none" }}>
                          AM
                        </ToggleButton>
                        <ToggleButton value="PM" style={{ outline: "none" }}>
                          PM
                        </ToggleButton>
                        {disabledEndSlots && (
                          <ToggleButton
                            value="Full"
                            style={{ outline: "none" }}
                          >
                            ALL DAY
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </div>

                    {selectedType?.typeBalance == "sickBalance" &&
                      newBalances[selectedType.typeBalance] <= 0 && (
                        <Alert
                          icon={<Icon />}
                          className="mt-2 rounded-none border-l-4 border-[#ef7918] bg-[#fca965]/10 font-medium text-[#ef7918]"
                        >
                          <Translation message="Your leave balance has been used up." />
                        </Alert>
                      )}

                    <div className="mt-10 mb-5">
                      {Object.entries(balances).map(([key, value]) => (
                        <Typography className=" text-sm  text-gray-700 pt-1">
                          <Translation
                            message={`Current ${
                              key == "sickBalance"
                                ? "Sick balance"
                                : "Leave balance"
                            }`}
                          />{" "}
                          : <b>{value}</b>
                        </Typography>
                      ))}
                    </div>

                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of requested days" />:{" "}
                      <b>
                        {requestedDays}
                        {/* {selectedType?.NBMonths && selectedType?.NBDays
                      ? selectedType?.NBMonths +
                        " months and " +
                        selectedType?.NBDays +
                        nbDays +
                        " days"
                      : selectedType?.NBDays
                      ? selectedType?.NBDays + nbDays + " days"
                      : 0} */}
                      </b>
                    </Typography>
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Number of counted days" />:{" "}
                      <b>{nbDays}</b>
                    </Typography>
                    <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message="Pending requests" /> :{" "}
                      <b>
                        {nbRequests}{" "}
                        {pendingDays > 0 &&
                          `( ${pendingDays} ${
                            pendingDays > 1 ? t("days") : t("day")
                          } )`}
                      </b>
                    </Typography>
                    {/* <div className="flex gap-8 mt-5 mb-12">
                      <Typography className=" text-sm  text-blue-700 pt-1">
                        <Translation message="New balance" /> :{" "}
                        <b>{newBalance}</b>
                      </Typography>
                    </div> */}

                    <div className="mt-10">
                      {Object.entries(newBalances).map(([key, value]) => (
                        <Typography className=" text-sm  text-blue-700 pt-1">
                          <Translation
                            message={`New ${
                              key == "sickBalance"
                                ? "Sick balance"
                                : "Leave balance"
                            }`}
                          />{" "}
                          : <b>{value}</b>
                        </Typography>
                      ))}
                    </div>

                    <div className="flex gap-8 mt-16 mb-12">
                      <Textarea
                        color="indigo"
                        // placeholder="If you have any comments write here"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        label={
                          <Translation message="Leave your comment here" />
                        }
                      />
                    </div>

                    <div className="flex justify-center mt-5">
                      <Button
                        color="indigo"
                        ripple="light"
                        onClick={() => {
                          handleSend();
                        }}
                      >
                        <ClipLoader color="white" loading={loading} size={20} />
                        <Translation message="Send Request" />
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconOutlined() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-10 w-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>
  );
}
