import React, { useState } from "react";

import {
  Button,
  Typography,
  Textarea,
  Radio,
  Breadcrumbs,
} from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./form.css";
import { addClaim } from "../../actions/RequestAction";
import jwt from "jwt-decode";
import useMediaQuery from "useMediaQuery";
import { Link } from "react-router-dom";
import { Home } from "@mui/icons-material";
import Translation from "Translation";

const color = "#083985";

export default function Form() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState(null);

  const user = jwt(token);
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const handleSend = async () => {
    setLoading(true);
    if (comment.length == 0) {
      NotificationManager.warning(<Translation message={"Please fill the comment field"} />);
      setLoading(false);
      return;
    }

    if (recipient == null) {
      NotificationManager.warning(<Translation message={"Please choose the recipient"} />);
      setLoading(false);
      return;
    }

    var data = {
      type: "Support",
      idSender: user.id,
      recipient: recipient,
      CommentUser: comment,
    };

    const result = await addClaim(data);
    setLoading(false);
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        setTimeout(() => {
          window.location.href = "/claims";
        }, 2000);
      }
    } else {
      NotificationManager.error(result.message);
    }
  };

  return (
    <>
      <section className="pb-20 relative block">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
          <div className="flex flex-wrap justify-center mt-5">
            {isDesktop && (
              <div style={{ position: "absolute", right: "13%" }}>
                <Breadcrumbs>
                  <Link to="/home">
                    <Home fontSize="small" htmlColor="gray" />
                  </Link>
                  <Link to="/reservation" className="opacity-60">
                    <span>Flex Support</span>
                  </Link>
                  {/* <a href="#">Building </a> */}
                </Breadcrumbs>
              </div>
            )}
            <div className="w-full lg:w-8/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                <div className="flex-auto p-5 lg:p-10">
                  <div className="w-full text-center">
                    <Typography
                      variant={isDesktop ? "h3" : "h6"}
                      style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                    >
                      <Translation message={"You need a support?"} />
                      
                    </Typography>
                    <Typography color="gray">
                      <Translation message={"Complete this form to send a support request."} />
                      
                    </Typography>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mt-20">
                      <Textarea
                        color="indigo"
                        variant="static"
                        label={<Translation message={"Comment"} />}
                        // placeholder={<Translation message={"Leave your comment here"} />}
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </div>

                    <div className="flex gap-8 mb-12 mt-20">
                      <Radio
                        color="indigo"
                        id="facility"
                        name="type"
                        label="Flex Office"
                        value="Flex office"
                        onClick={(e) => {
                          setRecipient(e.target.value);
                        }}
                      />{" "}
                      <Radio
                        color="indigo"
                        id="talents"
                        name="type"
                        label="Flex working"
                        value="Flex working"
                        onClick={(e) => {
                          setRecipient(e.target.value);
                        }}
                      />{" "}
                    </div>

                    <div className="flex justify-center mt-20">
                      <Button
                        style={{ backgroundColor: "#083985" }}
                        ripple="light"
                        onClick={() => {
                          handleSend();
                        }}
                      >
                        <ClipLoader color="white" loading={loading} size={20} />
                         <Translation message={"Send Request"} />
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NotificationContainer />
    </>
  );
}
