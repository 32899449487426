import {
  Button,
  ButtonGroup,
  Radio,
  Typography,
} from "@material-tailwind/react";
import { Alert } from "@mui/material";
import Translation from "Translation";
import { getVisaType } from "actions/travel.service";
import CustomFileInput from "components/CustomFileInput";
import Preview from "components/Preview";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

const Visa = (props) => {
  const { formData, setFormData, setVisaFile, visaFile, missions } = props;
  const [suggestedVisa, setSuggestedVisa] = React.useState("");

  const [suggestedVisas, setSuggestedVisas] = React.useState([]);

  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (formData?.missionType == "simple") {
        if (formData.missionCountry && !formData.missionCountry?.visa) {
          const res = await getVisaType(
            "TN",
            formData.missionCountry?.country_code
          );
          // console.log("res", res);
          setSuggestedVisa(res.category);
        }
      } else {
        const uniqueItems = new Set();

        missions?.forEach((item) => {
          const text = `${item?.country?.name} : ${item?.country?.visa?.name} (${item?.country?.visa?.type})`;
          uniqueItems.add(text);
        });
        setSuggestedVisas(Array.from(uniqueItems).join(", "));
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (file) => {
    setVisaFile(file);
  };

  const handleFileDisplay = () => {
    var reader = new FileReader();
    reader.onload = (event) => {
      setPreviewUrl(event.target.result);
    };
    reader.readAsDataURL(visaFile);
    setFileType(visaFile.type);
    setDisplay(true);
  };

  return (
    <div className="lg:w-12/12 mt-10 ">
      <div className="my-3 flex flex-wrap items-center gap-5">
        <Typography
          variant="medium"
          className="flex gap-1 mb-2 font-medium text-blue-900"
        >
          {formData?.missionType == "simple" ? (
            <Translation message={"Required visa for the mission's country"} />
          ) : (
            <Translation
              message={"Required visas for the mission's countries"}
            />
          )}
        </Typography>
        {formData?.missionType == "simple" ? (
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            {formData?.missionCountry?.visa
              ? `${formData?.missionCountry?.visa?.name} (${formData?.missionCountry?.visa?.type})`
              : suggestedVisa}
          </Typography>
        ) : (
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            {suggestedVisas}
          </Typography>
        )}
      </div>
      {/* <div className="flex gap-8 mt-5">
        {activeVisa && (
          <Alert severity="success">{`You have an active visa expiring on ${format(
            new Date(activeVisa.expiry),
            "dd-MM-yyyy"
          )} ( length of stay : ${activeVisa.lengthStay} days)`}</Alert>
        )}
      </div> */}
      <div className="flex gap-8 mb-5 mt-1">
        <Typography className=" text-sm  text-gray-700 pt-1">
          <Translation message="For more information about visas you can visit" />{" "}
          <b>
            <a
              href="https://www.passportindex.org/passport/tunisia/"
              target="_blank"
              rel="noreferrer"
            >
              <Translation message="this link" />
            </a>{" "}
          </b>
          <Translation message="and pick your destination's country" />
        </Typography>
      </div>

      <div>
        <Typography
          variant="small"
          className="flex gap-1 mb-2 font-medium text-gray-700"
        >
          <Translation message={"choose an option"} />{" "}
          <p className="text-red-500">*</p>
        </Typography>
        <div className="flex flex-col">
          <Radio
            name="visa"
            label={t("I have a valid visa")}
            icon={<Icon />}
            value="validVisa"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                visa: false,
                visaType: null,
                visaOption: e.target.value,
                personalVisa: true,
              }));
            }}
            checked={formData.visaOption == "validVisa"}
            color="indigo"
          />
          <Radio
            name="visa"
            label={t("eVisa")}
            icon={<Icon />}
            value="eVisa"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                visa: false,
                visaType: null,
                visaOption: e.target.value,
                personalVisa: false,
              }));
            }}
            checked={formData.visaOption == "eVisa"}
            color="indigo"
          />
          <Radio
            name="visa"
            label={t("visa on arrival")}
            icon={<Icon />}
            value="arrivalVisa"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                visa: false,
                visaType: null,
                visaOption: e.target.value,
                personalVisa: false,
              }));
            }}
            checked={formData.visaOption === "arrivalVisa"}
            color="indigo"
          />
          <Radio
            name="visa"
            label={t("I don't have a valid visa")}
            icon={<Icon />}
            value="noneVisa"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                visa: true,
                visaType: null,
                visaOption: e.target.value,
                personalVisa: false,
              }));
            }}
            checked={formData.visaOption === "noneVisa"}
            color="indigo"
          />
          <Radio
            name="visa"
            label={t("I don't need a visa")}
            icon={<Icon />}
            value="withoutVisa"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                visa: false,
                visaType: null,
                visaOption: e.target.value,
                personalVisa: false,
              }));
            }}
            checked={formData.visaOption === "withoutVisa"}
            color="indigo"
          />
        </div>
      </div>

      {formData?.visaOption == "validVisa" && (
        <div className="flex mt-5">
          <h5 className="flex text-gray-700 dark:text-white mr-10 mt-2">
            <Translation message="Upload visa file" />{" "}
            <p className="text-red-500">*</p>
          </h5>
          <CustomFileInput onFileChange={handleFileChange} />
        </div>
      )}
      {visaFile && (
        <div className="flex mt-10 ">
          <Typography className=" text-sm  text-gray-700 pt-1">
            {visaFile?.name}
          </Typography>
          <ButtonGroup
            style={{
              marginLeft: "10px",
              outline: "none",
              color: "#294e87",
            }}
            size="small"
            variant="text"
          >
            <Button
              style={{ outline: "none" }}
              onClick={() => {
                handleFileDisplay();
              }}
            >
              <FaEye size={20} style={{ color: "#294e87" }} />
            </Button>
            <Button
              style={{ outline: "none" }}
              onClick={() => {
                setVisaFile(null);
              }}
            >
              <MdDelete size={20} style={{ color: "#294e87" }} />
            </Button>
          </ButtonGroup>
        </div>
      )}
      {formData.visaOption == "noneVisa" && (
        <Alert severity="error" className="mt-5">
          {`${t("You cannot submit a travel request")}, `}{" "}
          <Link
            to={{
              pathname: "/visa",
              state: {
                passportNumber: formData.passportNumber,
                placePassport: formData.placePassport,
                datePassport: formData.datePassport,
                expiryPassport: formData.expiryPassport,
              },
            }}
          >
            {" "}
            {`${t("click here to submit a visa request please")}`}{" "}
          </Link>
        </Alert>
      )}

      {previewUrl && fileType && (
        <Preview
          view={display}
          setView={setDisplay}
          fileType={fileType}
          previewUrl={previewUrl}
        />
      )}
    </div>
  );
};

export default Visa;

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-full w-full scale-105"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
