import axios from "axios";

const credentials = btoa(
  `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
);

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": 0,
    Authorization: `Basic ${credentials}`,
  },
});

instance.interceptors.request.use(
  (config) => {
    const storage = JSON.parse(localStorage.getItem("user"));
    const token = storage.token;
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     // console.log(err);

//     if (err.response) {
//       if (
//         err.response.status == 401 &&
//         !originalConfig._retry
//         //  &&
//         // isRefreshing == false
//       ) {
//         originalConfig._retry = true;
//         // isRefreshing = true;
//         // console.log('err.response.status', originalConfig)
//         const storedToken = JSON.parse(localStorage.getItem("user"));

//         try {
//           const response = await axios
//             .post(`${process.env.REACT_APP_API_URL}User/auth/refresh`, {
//               refreshToken: storedToken.refresh_token,
//             })
//             .then((response) => {
//               if (response.data) {
//                 let storage = JSON.parse(localStorage.getItem("user"));
//                 storage.token = response.data.token;
//                 storage.refresh_token = response.data.refreshToken;
//                 localStorage.setItem("user", JSON.stringify(storage));
//                 // axios.defaults.headers["x-access-token"] = response.data.token;
//               }

//               err.config.headers["x-access-token"] = response.data.token;
//               // err.config.baseURL = undefined;
//               // err.config._retry = false;
//               // isRefreshing = false;
//             })
//             .catch((e) => {
//               localStorage.removeItem("user");
//               // history.push("/login");
//               window.location.pathname = "/login";
//             });
//           err.config.headers["x-access-token"] = response.data.token;
//           err.config.baseURL = undefined;
//           err.config._retry = false;

//           return instance.request(err.config);
//         } catch (_error) {
//           // console.log(_error);
//           // localStorage.removeItem("user");
//           // // history.push("/login");
//           // window.location.pathname = "/login";
//           return Promise.reject(_error);
//         }
//       } else {
//         return Promise.reject(err);
//       }
//     }
//   }
// );

let isRefreshing = false;
let refreshSubscribers = [];

const refreshAccessToken = async () => {
  const storedToken = JSON.parse(localStorage.getItem("user"));
  return await axios
    .post(`${process.env.REACT_APP_API_URL}User/auth/refreshToken`, {
      refreshToken: storedToken.refresh_token,
      channel: "user",
    })
    .then((response) => {
      if (response.data) {
        let storage = JSON.parse(localStorage.getItem("user"));
        storage.token = response.data.token;
        storage.refresh_token = response.data.refreshToken;
        localStorage.setItem("user", JSON.stringify(storage));
        return response.data.token;
      }
    })
    .catch((e) => {
      localStorage.removeItem("user");
      window.location.pathname = "/login";
      return Promise.reject(e);
    });
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error", error);
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshAccessToken().then((newToken) => {
          isRefreshing = false;
          onRrefreshed(newToken);
        });
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers["x-access-token"] = token;
          originalRequest.baseURL = undefined;
          originalRequest._retry = false;
          resolve(axios(originalRequest));
        });

        // let storage = JSON.parse(localStorage.getItem("user"));
        // originalRequest.headers["x-access-token"] = storage.token;
        // originalRequest.baseURL = undefined;
        // originalRequest._retry = false;
        // resolve(axios(originalRequest));

        // return instance.request(err.config);
      });
      return retryOrigReq;
    } else {
      console.log(error);
      return Promise.reject(error);
    }
  }
);

function unsubscribeTokenRefresh(cb) {
  refreshSubscribers.filter(cb);
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
  // console.log(cb);
  // console.log("refreshSubscribers", refreshSubscribers);
}

function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
  let storage = JSON.parse(localStorage.getItem("user"));
  storage.token = token;
  // storage.refresh_token = response.data.refreshToken;
  localStorage.setItem("user", JSON.stringify(storage));
}

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     console.log(originalConfig);

//     if (err.response) {
//       console.log(err.response);
//       if (err.response.status == 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         // console.log('err.response.status', originalConfig)
//         const storedToken = JSON.parse(localStorage.getItem("user"));

//         try {
//           const response = await axios.post(
//             `${process.env.REACT_APP_API_URL}User/auth/refresh`,
//             {
//               refreshToken: storedToken.refresh_token,
//             }
//           );

//           if (response.data) {
//             let storage = JSON.parse(localStorage.getItem("user"));
//             storage.token = response.data.token;
//             localStorage.setItem("user", JSON.stringify(storage));
//           }

//           err.config.headers["x-access-token"] = response.data.token;
//           err.config.baseURL = undefined;
//           originalConfig._retry = false;

//           return instance.request(err.config);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       } else {
//         return Promise.reject(err);
//       }
//     }
//   }
// );

// old version
// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     // console.log(err);

//     if (err.response) {
//       if (err.response.status == 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         // console.log('err.response.status', originalConfig)
//         const storedToken = JSON.parse(localStorage.getItem("user"));

//         try {
//           const response = await axios.post(
//             `${process.env.REACT_APP_API_URL}User/auth/refresh`,
//             {
//               refreshToken: storedToken.refresh_token,
//             }
//           );

//           if (response.data) {
//             let storage = JSON.parse(localStorage.getItem("user"));
//             storage.token = response.data.token;
//             localStorage.setItem("user", JSON.stringify(storage));
//           }

//           err.config.headers["x-access-token"] = response.data.token;
//           err.config.baseURL = undefined;

//           return instance.request(err.config);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       } else {
//         return Promise.reject(err);
//       }
//     }
//   }
// );

export default instance;
