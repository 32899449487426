import React, { useState, useEffect } from "react";
import { Button, Step, Stepper, Typography } from "@material-tailwind/react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Check } from "@mui/icons-material";

import { getWorkflowSteps } from "actions/travel.service";
import { Alert } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import UserPanel from "components/Missions/UserPanel";
import MissionPanel from "components/Missions/MissionPanel";
import PerdiemPanel from "components/Missions/PerdiemPanel";
import TransportationPanel from "components/Missions/TransportationPanel";
import AccomodationPanel from "components/Missions/AccomodationPanel";
import { useParams } from "react-router-dom";
import { fetchMissionRequestByID } from "actions/travel.service";
// import ChatGroup from "components/Chat/ChatGroup";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import { getUserPermissions } from "actions/travel.service";
import jwtDecode from "jwt-decode";
import ConfirmDialog from "components/ConfirmDialog";
import { ClipLoader } from "react-spinners";
import { ValidateRequest } from "actions/travel.service";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { rejectRequest } from "actions/travel.service";
import Map from "components/Missions/Map";
import Translation from "Translation";
import useMediaQuery from "useMediaQuery";
import ReactIconComponent from "components/ReactIconComponent";
import { t } from "i18next";

const color = "#083985";

const MissionDetails = () => {
  const [steps, setSteps] = useState([]);

  const [permissions, setPermissions] = useState([]);

  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const user = jwtDecode(token);
  const [icons, setIcons] = useState({});

  const [expanded, setExpanded] = useState([true, true, true, true, true]);
  const [request, setRequest] = useState(null);
  const [validate, setValidate] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [loading, setLoading] = useState(true);
  const [loadingValidation, setLoadingValidation] = useState(false);

  const handleChange = (index) => {
    const updatedExpanded = [...expanded];
    updatedExpanded[index] = !updatedExpanded[index];
    setExpanded(updatedExpanded);
  };

  useEffect(() => {
    const fetchData = async () => {
      var result = await getWorkflowSteps("initial");
      if (result.status == 200) {
        var sotedResult = result.data?.steps?.sort((a, b) => a.order - b.order);
        sotedResult.forEach((item, index) => {
          icons[index + 1] = <i className={item.userIcon} key={item.index} />;
        });
        setSteps(sotedResult);
        fillIcons(sotedResult);
        // sotedResult.map((item, index) => {
        //   //   icons[index + 1] = <i className={item.userIcon} />;
        //   icons[index + 1] = item.userIcon;
        // });

        // console.log("iiiic", icons);
      }
      //   var res = await getUserPermissions();
      //   if (res.status == 200) {
      //     const permissions = res.data;
      //     console.log("permissions", permissions);
      //     const canValidate =
      //       permissions?.stepsValidation?.find(
      //         (item) => item.step == request?.mission?.currentStep?._id
      //       )?.permissions?.V == true;
      //     setValidate(canValidate);
      //     setLoading(false);
      //   }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var res = await getUserPermissions();
      if (res.status == 200) {
        const permissions = res.data;
        setPermissions(permissions);

        setLoading(false);
      }
    };

    fetchData();
  }, [request]);

  useEffect(() => {
    const fetchData = async () => {
      const validationStep = steps?.find(
        (item) => item.name?.toLowerCase() == "validation"
      );
      // const canValidate =
      //   permissions?.stepsValidation?.find(
      //     (item) => item.step == request?.mission?.currentStep?._id
      //   )?.permissions?.V == true;

      const canValidate =
        permissions?.stepsValidation?.find(
          (item) => item.step == validationStep?._id
        )?.permissions?.V == true;

      setValidate(canValidate);
      setLoading(false);
    };

    fetchData();
  }, [steps, permissions]);

  const fetchRequest = async () => {
    var res = await fetchMissionRequestByID(id);
    if (res.status == 200) {
      setRequest(res?.data);
    }
  };

  useEffect(() => {
    id && fetchRequest();
  }, [id]);

  const nextStep = async () => {
    setLoadingValidation(true);
    var res = await ValidateRequest(request._id, "mission");
    if (res.status == 200) {
      NotificationManager.success(res.message);
      // fetchRequest();
      setConfirmDialog({ isOpen: false });
      window.location.href = "/teamRequests#/travel";
    } else {
      NotificationManager.error(res);
    }
  };

  const reject = async () => {
    setLoadingValidation(true);
    var res = await rejectRequest(request._id, request.mission?._id);
    if (res.status == 200) {
      NotificationManager.success(res.message);
      // fetchRequest();
      setConfirmDialog({ isOpen: false });
      window.location.href = "/teamRequests#/Travel";
    } else {
      NotificationManager.error(res);
    }
  };

  const startAnimation = () => {
    const elementToAnimate = document.getElementById("chat-circle");
    if (elementToAnimate) {
      elementToAnimate.classList.add("wiggle");
    }

    setTimeout(() => {
      if (elementToAnimate) {
        elementToAnimate.classList.remove("wiggle");
      }
    }, 7000);
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  console.log("validate", validate);

  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <section className="pb-20 relative block">
          <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
            <div className="flex flex-wrap justify-center mt-5">
              <div className="w-full lg:w-10/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                  <div className="flex-auto p-5 lg:p-10">
                    <div className="w-full text-center">
                      <Typography
                        variant={isDesktop ? "h3" : "h6"}
                        style={{
                          color: color,
                          marginTop: isDesktop ? 0 : "30%",
                        }}
                      >
                        <Translation
                          message={"Follow up on the mission request"}
                        />
                      </Typography>
                      <Typography color="gray">
                        <Translation
                          message={"Check the progress of the mission folder"}
                        />
                      </Typography>
                    </div>
                  </div>
                  {loading ? (
                    <div className="flex flex-wrap justify-center flex-grow-3 gap-8 mb-12">
                      <ClipLoader color="blue" />{" "}
                    </div>
                  ) : (
                    <>
                      {steps.length > 0 && (
                        <Stepper
                          activeStep={request?.mission?.currentStep?.order}
                          activeLineClassName="bg-indigo-500"
                        >
                          {steps.map(
                            (item, index) => (
                              <Step
                                key={item.name}
                                activeClassName="bg-indigo-500"
                                completedClassName="bg-indigo-500 "
                              >
                                <ReactIconComponent
                                  name={item.icon}
                                  size={20}
                                  color={
                                    index ==
                                      request?.mission?.currentStep?.order &&
                                    "var(--rs-primary-800)"
                                  }
                                />
                                <div className="absolute -bottom-[4.5rem] w-max text-center">
                                  <Typography
                                    variant="medium"
                                    color={
                                      request?.mission?.currentStep?.order ===
                                      index
                                        ? "blue-gray"
                                        : "gray"
                                    }
                                  >
                                    <Translation message={item.name} />
                                  </Typography>
                                  <Typography
                                    color={
                                      request?.mission?.currentStep?.order ===
                                      index
                                        ? "blue-gray"
                                        : "gray"
                                    }
                                    variant="small"
                                    className="font-normal"
                                  >
                                    <Translation message={item.desc} />
                                  </Typography>
                                </div>
                              </Step>
                            )
                            //   console.log("item", item)
                          )}
                        </Stepper>
                      )}

                      {!validate && (
                        <Alert severity="error" className="mt-5">
                          <span>
                            <Translation message="You do not have permission to validate this step" />
                          </span>
                        </Alert>
                      )}

                      {
                        validate && request?.status == "pending" && (
                          <div
                            className={`flex  justify-between `}
                            style={{ marginTop: "10rem" }}
                          >
                            <Button
                              //   style={{
                              //     backgroundColor: "#083985",
                              //     marginRight: "5px",
                              //   }}
                              id="animationButton"
                              ripple="light"
                              color="red"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: t(
                                    "Are you sure to reject this request ?"
                                  ),
                                  subtitle: t(
                                    "This request will be definitely archived, are you sure you want to reject it? If you would instead like the traveler to make changes, please only add a comment using the icon at the bottom right of the page."
                                  ),
                                  color: "red",
                                  onConfirm: () => {
                                    reject();
                                  },
                                });
                                startAnimation();
                              }}
                              variant="text"
                            >
                              {t("Reject this request")}
                            </Button>
                            <Button
                              //   style={{ backgroundColor: "#083985" }}
                              ripple="light"
                              variant="text"
                              color="indigo"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: t(
                                    "Are you sure to validate this step and move on to the next step ?"
                                  ),
                                  subtitle: t(
                                    "You cannot go back to this step"
                                  ),
                                  onConfirm: () => {
                                    nextStep();
                                    // handleBack(request._id);
                                  },
                                });
                              }}
                            >
                              {t("Validate this request")}
                              <i className="fas fa-arrow-right w-4 "></i>
                            </Button>
                          </div>
                        )
                        //   : (
                        //     <Alert severity="error">
                        //       <span>You are not authorized to validate this step.</span>
                        //     </Alert>
                        //   )
                      }
                      <div className="mt-10">
                        {request?.status == "pending" && (
                          <Alert severity="warning">
                            <span>
                              <Translation message="waiting for your validation" />
                            </span>
                          </Alert>
                          // ) : (
                          //   <Alert severity="info">
                          //     <span>
                          //       waiting for {request?.mission?.currentStep?.name},
                          //       You can only read in this step
                          //     </span>
                          //   </Alert>
                        )}
                      </div>
                      <div className="mt-10">
                        <Accordion
                          expanded={expanded[0]}
                          onChange={() => handleChange(0)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography className="title-field text-indigo-900">
                              <i className="fas fa-user mr-2" />
                              <Translation message="Submitter information" />
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <UserPanel
                              request={request}
                              edit={false}
                              // handleFormChange={handleFormChange}
                              // formData={formData}
                              // missionData={missionData}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded[1]}
                          onChange={() => handleChange(1)}
                          className="mt-10"
                        >
                          <AccordionSummary
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                          >
                            <Typography className="title-field text-indigo-900">
                              <i className="fas fa-folder mr-2" />
                              <Translation message="Mission information" />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <MissionPanel
                              request={request}
                              edit={false}
                              missions={request?.mission?.missions}
                              isEngagementCodeValid={true}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded[2]}
                          onChange={() => handleChange(2)}
                        >
                          <AccordionSummary
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                          >
                            <Typography className="title-field text-indigo-900">
                              <i className="fas fa-dollar-sign mr-2" /> Perdiem
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <PerdiemPanel request={request} edit={false} />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded[3]}
                          onChange={() => handleChange(3)}
                        >
                          <AccordionSummary
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                          >
                            <Typography className="title-field text-indigo-900">
                              <i className="fas fa-plane mr-2" />{" "}
                              <Translation message="Transportation" />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TransportationPanel
                              request={request}
                              edit={false}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          expanded={expanded[4]}
                          onChange={() => handleChange(4)}
                        >
                          <AccordionSummary
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                          >
                            <Typography className="title-field text-indigo-900">
                              <i className="fas fa-hotel mr-2" />{" "}
                              <Translation message="Accommodation" />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <AccomodationPanel request={request} edit={false} />
                          </AccordionDetails>
                        </Accordion>
                      </div>

                      {/* {(request?.mission?.missionCountry?._id ||
                        request?.mission?.missionCity?._id) && (
                        <Map
                          country={request?.mission?.missionCountry}
                          city={request?.mission?.missionCity}
                          departureCity={
                            request?.mission?.transportation?.departureCity
                              ?.name
                          }
                          destinationCity={
                            request?.mission?.transportation?.destinationCity
                              ?.name
                          }
                          lngCustomer={
                            request?.mission?.accomodation?.lngCustomer
                          }
                          latCustomer={
                            request?.mission?.accomodation?.latCustomer
                          }
                          lngHotel={
                            request?.mission?.accomodation?.hotel?.longitude
                          }
                          latHotel={
                            request?.mission?.accomodation?.hotel?.altitude
                          }
                          lngFavourite={
                            request?.mission?.accomodation?.lngFavourite
                          }
                          latFavourite={
                            request?.mission?.accomodation?.latFavourite
                          }
                        />
                      )} */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <ChatGroup request={request} /> */}
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            loadingValidation={loadingValidation}
          />
          <NotificationContainer />
        </section>
      </main>
      <DefaultFooter />
    </>
  );
};

export default MissionDetails;

let icons = {}; // Déclaration de la variable en dehors de la fonction

const fillIcons = (iconData) => {
  iconData.forEach((item, index) => {
    icons[index + 1] = <i className={item.userIcon} key={item.index} />;
  });
};

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  // const icons = {
  //   1: <i className="fas fa-user-check" />,
  //   2: <i className="fas fa-user-tie" />,
  //   3: <i className="fas fa-users-gear" />,
  //   4: <i className="fas fa-sack-dollar" />,
  // };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        icons[String(props.icon)]
        // <i className={steps[String(props.icon)]?.userIcon} />
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,

  name: PropTypes.string,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(83 33 242) 0%, rgb(8 57 133) 50%, rgb(41 78 135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(83 33 242) 0%, rgb(8 57 133) 50%, rgb(41 78 135) 100%)",
  }),
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  borderRadius: "10px",
  marginTop: "20px",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
