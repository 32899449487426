import * as React from "react";

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { t } from "i18next";
export default function Preview({
  fileData,
  view,
  setView,
  handleDownload,
  fileType,
  isBill,
  previewUrl,
}) {
  const handleClose = () => {
    setView(false);
  };

  const [base64Image, setBase64Image] = React.useState(null);
  const [numPages, setNumPages] = React.useState(1);

  React.useEffect(() => {
    let binary = "";
    const bytes = new Uint8Array(fileData);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64Data = window.btoa(binary);
    setBase64Image(base64Data);
  }, [fileData]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Dialog open={view} size="xl" handler={handleClose}>
        <DialogBody>
          <center>
            {fileType == "pdf" ? (
              // <FileViewer
              //   fileType={fileType}
              //   filePath={fileData}
              //   // fileBlob={fileData}
              //   // filePath=""

              //   // errorComponent={CustomErrorComponent}
              //   // onError={onError}
              // />

              <iframe
                src={previewUrl}
                style={{ width: "100%", height: "600px" }}
              />
            ) : (
              // file &&
              // Array.isArray(file) && (
              //   <img
              //     src={`data:image/jpeg;base64,${window.btoa(
              //       String.fromCharCode.apply(null, file)
              //     )}`}
              //     alt="Image"
              //   />
              // )

              previewUrl && (
                <div style={{ height: "auto" }}>
                  <iframe
                    src={previewUrl}
                    style={{ width: "100%", height: "600px" }}
                  />
                </div>
              )
              // <img src={`data:image/jpeg;base64,${btoa(fileData)}`} alt="Image" />

              // <img
              //   // ref={elementRef}
              //   src={`data:image/png;base64,${fileData}`}
              //   alt="Image"
              //   // onClick={enterFullscreen}
              //   style={{ cursor: 'pointer' }}
              // />
            )}
          </center>

          {/* {previewUrl ? (
            <div>
              {fileType.startsWith("image/") ? (
                <img src={previewUrl} alt="Preview" style={{ width: "100%" }} />
              ) : (
                <embed
                  src={previewUrl}
                  type="application/pdf"
                  width="900"
                  height="900"
                />
              )}
            </div>
          ) : isBill ? (
            fileType.startsWith("image/") || fileType != "pdf" ? (
              <img
                // ref={elementRef}
                src={`data:image/png;base64,${fileData}`}
                alt="Image"
                // onClick={enterFullscreen}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <embed
                // ref={elementRef}
                src={`data:application/pdf;base64,${fileData}`}
                type="application/pdf"
                width="900"
                height="900"
                // onClick={enterFullscreen}
                style={{ cursor: "pointer" }}
              />
            )
          ) : fileType == "pdf" ? (
            <FileViewer
              fileType={fileType}
              filePath={fileData}
              // errorComponent={CustomErrorComponent}
              // onError={onError}
            />
          ) : (
            <img
              src={`data:image/jpeg;base64,${window.btoa(
                String.fromCharCode.apply(null, fileData)
              )}`}
              alt="Image"
            />
          )} */}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleClose}
            className="mr-1"
          >
            <span>{t("Close")}</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}
