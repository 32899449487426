import axios from "axios";
import { Decrypt } from "../Crypto";
import api from "./api";

const url = process.env.REACT_APP_API_URL + "User/";

export const fetchValidators = async (token) => {
  const result = await api
    .get(url + "fetchValidators", {
      headers: {
        "x-access-token": token,
      },
    })
    .then(async (response) => {
      var validators = await Decrypt(response.data.data);
      return validators;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchLeaveValidators = async (type) => {
  const result = await api
    .get(`${url}fetchValidators/${type}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then(async (response) => {
      var validators = await Decrypt(response.data.data);
      return validators;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchManagers = async (token) => {
  // let isLoading = true;
  const result = await api
    .get(url + "getManagers", {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchUser = async (token, id) => {
  const result = await api
    .get(url + `search/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchALLUsers = async () => {
  const result = await api
    .get(url + "getUsers")
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchUserBalances = async (token, id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Balance/getUserBalance/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchTeamUsers = async (token, id) => {
  const result = await api
    .get(url + `getTeamManager/${id}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchNbrReservations = async (token, id, start, end) => {
  const result = await api
    .get(url + `nbofreservations/${id}/${start}/${end}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchNbrWfhs = async (token, id, start, end) => {
  const result = await api
    .get(url + `nbofwfh/${id}/${start}/${end}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchBalances = async (token) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Setting/getBalances`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchHorizon = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `Setting/getHorizon`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const login = async (email, password) => {
  console.log("URL API", process.env.REACT_APP_API_URL);
  console.log("USERNAME", process.env.REACT_APP_USERNAME);
  console.log("PASSWORD", process.env.REACT_APP_PASSWORD);
  const credentials = btoa(
    `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
  );
  console.log("credentials", credentials);
  const data = {
    Email: email,
    password: password,
  };
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}User/loginUser`,
    headers: {
      Authorization: `Basic ${credentials}`,
      "Content-Type": "application/json",
    },
    data: data,
  };
  // const result = await axios
  //   .post(`${process.env.REACT_APP_API_URL}User/loginUser`, {
  //     Email: email,
  //     password: password,
  //   })
  const result = await axios(config)
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", response.data.result);
        // let returnedData = Buffer.from(response.data.result, "base64").toString();
        // returnedData = JSON.parse(returnedData);
        // var buff = new Buffer(returnedData.token).toString("base64");
        // let token = buff.toString('base64');

        // var data = {
        //   token,
        //   'user': returnedData.user
        // }
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return "Login Success";
    })
    .catch((e) => {
      if (e.response) {
        return e.response.data.errors || e.response.data.message;
      } else {
        return "Connexion error";
      }
    });

  return result;
};

// export const logout = async () => {
//   const storedToken = JSON.parse(localStorage.getItem("user"));
//   localStorage.removeItem("user");
//   await axios.post(`${url}logout`, {
//     token: storedToken.token,
//     refreshToken: storedToken.refresh_token,
//     channel: "user",
//   });
//   // history.push("/login");
//   window.location.pathname = "/login";
// };

export const logout = async () => {
  const storedToken = JSON.parse(localStorage.getItem("user"));
  const refreshToken = storedToken.refresh_token;
  await api
    .post(
      url + `logout/`,
      {},
      {
        headers: {
          "x-refresh-token": refreshToken,
        },
      }
    )
    .then((response) => {
      localStorage.removeItem("user");
      window.location.pathname = "/login";
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });
  // localStorage.removeItem("user");
  // history.push("/login");
  // window.location.reload();
};

export const forgetPassword = async (data) => {
  const result = await axios
    .post(`${url}forgotPassword`, data)
    .then((response) => {
      return { status: 200, message: response.data.message };
    })
    .catch((e) => {
      const message = e.response && e.response.data && e.response.data.error;
      return { status: 400, message: message };
    });

  return result;
};

// export const ChangePassword = async (password, id) => {
//   const result = await axios
//     .post(`${url}NewPasswd/${id}`, {
//       password: password,
//     })
//     .then((response) => {
//       return { status: 200, message: "Updated Successfully" };
//     })
//     .catch((e) => {
//       const message = e.response && e.response.data && e.response.data.error;
//       return message;
//     });

//   return result;
// };

export const ChangePassword = async (data) => {
  const result = await axios
    .post(`${url}resetPassword`, data)
    .then((response) => {
      console.log("response", response);
      return { status: 200, message: response.data.message };
    })
    .catch((e) => {
      const message = e.response && e.response.data?.message;
      return { status: 400, message: message };
    });

  return result;
};
export const fetchNbrCS = async (token, id, start, end) => {
  const result = await api
    .get(url + `nbofCST/${id}/${start}/${end}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchHistory = async (id) => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `history/cardhistory/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};
export const fetchManagerHistory = async () => {
  const result = await api
    .get(process.env.REACT_APP_API_URL + `history/managerHistory`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const SearchColleague = async (id, date) => {
  const result = await api
    .get(`${url}searchCollege/${id}/${date}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

// export const fetchUserImage = async () => {
//   return await api
//     .get(`${url}retrieveImage`)
//     .then((res) => {
//       console.log(res);
//       return res;
//     })
//     .catch((e) => {
//       console.log(e);
//     });
// };
const storage = JSON.parse(localStorage.getItem("user"));
const token = storage?.token;
export const fetchUserImage = async () => {
  try {
    const storage = JSON.parse(localStorage.getItem("user"));
    const token = storage?.token;
    const response = await fetch(`${url}retrieveImage`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "image/jpeg",
        "x-access-token": token,
      },
    });

    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl;
    // return [URL.createObjectURL(blob), null];
  } catch (error) {
    console.error(`get: error occurred ${error}`);
    return [null, error];
  }
};

export const UpdatePasswordUser = async (id, data) => {
  const result = await api
    .put(`${url}updatemyprofile/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      const message = e.response && e.response.data && e.response.data.error;
      return message;
    });

  return result;
};

export const fetchNbrLeaves = async (token, id, start, end) => {
  const result = await api
    .get(url + `getNumberOfLeaves/${id}/${start}/${end}`, {
      headers: {
        "x-access-token": token,
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchCombinedModesByUser = async (id) => {
  const result = await api
    .get(url + `getCombinedModesByUser/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const delegateLeaveValidation = async (data) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `Delegate/add`, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      return errorMsg;
    });

  return result;
};

export const getLeaveDelegation = async (data) => {
  const result = await api
    .post(process.env.REACT_APP_API_URL + `Delegate/getDelegations`, data)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const deleteDelegation = async (id) => {
  const result = await api
    .delete(
      process.env.REACT_APP_API_URL + `Delegate/removeUserDelegation/${id}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};
