import {
  Button,
  IconButton,
  Input,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import FileViewer from "./FileViewer";
import Select from "react-select";

import FolderIcon from "@mui/icons-material/RequestPage";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { addBills, getBills, fetchFileWithId } from "actions/travel.service";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { CircleLoader, ClipLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { addFiles } from "actions/travel.service";
import CustomFileInput from "components/CustomFileInput";
import Preview from "components/Preview";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import Translation from "Translation";
import { MdOutlineEuroSymbol } from "react-icons/md";
import { t } from "i18next";

const color = "#083985";
const types = [
  {
    label: "Hotel invoice",
    value: "hotel",
  },
  {
    label: "Extra expenses invoice",
    value: "expenses",
  },
  {
    label: "Transportation invoice",
    value: "transportation",
  },
  {
    label: "Visa invoice",
    value: "visa",
  },
];

const BillsPanel = ({ request, edit }) => {
  const [bills, setBills] = useState([]);

  const [billsFiles, setBillsFiles] = useState([]);
  const [display, setDisplay] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [previewUrl, setPreviewUrl] = useState(null);
  const [totalExpandedAmount, setTotalExpandedAmount] = useState(0);

  useEffect(() => {
    const calculateTotalSum = () => {
      return bills.reduce((acc, item) => {
        // if (item.type !== 'currencyReturn') {
        const value = item.expandedAmount
          ? parseFloat(item.expandedAmount)
          : parseFloat(item.expandedAmount);
        return !isNaN(value) ? acc + value : acc;
        // }
        return acc;
      }, 0);
    };

    setTotalExpandedAmount(calculateTotalSum());
  }, [bills]);

  const handleAddRow = () => {
    setBills([
      ...bills,
      {
        _id: uuidv4(),
        type: null,
        comment: null,
        file: null,
      },
    ]);
  };

  const handleFetchFile = async (id) => {
    setOpen(true);
    const response = await fetchFileWithId(request?.mission?._id, id);
    // const contentType = response.headers['content-type'];
    // const data = await response.data;
    // item.fileType = contentType.split('/').pop();
    // item.file = new Uint8Array(data);

    const contentType =
      response.headers["Content-Type"] || response.headers["content-type"];
    // item.fileType = contentType?.split('/').pop();
    const fileType =
      contentType == "application/octet-stream" ? "eml" : contentType;
    // const res = await response.data;
    const data = await response.data;
    const fileData = new Uint8Array(data);

    setFileData(new Uint8Array(data));
    setFileType(fileType);
    setDisplay(true);
    setOpen(false);
  };

  // console.log("bills", bills);

  const handleRemoveRow = (index) => {
    // console.log("index", index);
    const values = [...bills];
    values.splice(index, 1);
    // console.log("values", values);
    setBills(values);
  };

  const fetchBills = async () => {
    await getBills(request?.mission?._id)
      .then((response) => {
        if (response.status == 200) {
          setBills(response.data);
          // var data = response.data;
          // console.log(data[0].file);
          // setFileData(data[0].file);
          // const fileReader = new FileReader();
          // fileReader.onload = (event) => {
          //   const dataUrl = event.target.result;
          //   // Use the data URL in your React component state or pass it as a prop
          //   setFileData(dataUrl);
          // };

          // fileReader.readAsDataURL(data[0].file);

          // const uint8Array = new Uint8Array(data[0].file);
          // const blob = new Blob([uint8Array], { type: data[0].type });

          // const fileURL = URL.createObjectURL(blob);
          // window.open(fileURL);

          // console.log("fileURL", uint8Array);
          // setFileData(uint8Array);
          // const link = document.createElement("a");
          // link.href = fileURL;
          // link.download = "filename.pdf"; // Set the desired filename for the file
          // link.innerText = "Download File";
          // document.body.appendChild(link);

          // const blob = base64toBlob(data);
          // const url = URL.createObjectURL(blob);
        }
        //   const contentType = response.headers["content-type"];
        //   // const fileType = response.type;
        //   // console.log("Type de fichier :", fileType);
        //   // const contentType = response.headers.get("Content-Type");
        //   setFileType(contentType.split("/").pop());
        //   return response.data;

        //   // return response.arrayBuffer();
        // })
        // .then((data) => {
        //   // console.log("eeeeeeeeeeeee", data);
        //   // setOpen(false);

        //   // window.open(new Uint8Array(data), "_blank");
        //   setFileData(new Uint8Array(data));
        // setView(true);
      })

      .catch((error) => {
        console.error("Erreur lors du chargement du fichier:", error);
      });
  };

  useEffect(() => {
    fetchBills();
    // console.log("res", res);
  }, []);

  console.log("billsFiles", billsFiles);

  //   const handleFileChange = (file, index, path) => {
  //     console.log("🚀 ~ handleFileChange ~ index:", index);
  //     const newArray = [...billsFiles];
  //     console.log("🚀 ~ handleFileChange ~ newArray:", newArray);
  //     // file.id = newArray[index].id;
  //     // console.log("chaaaaaange file", file);
  //     while (newArray.length <= index) {
  //       newArray.push(undefined);
  //     }
  //     newArray[index].file = file;
  //     newArray[index].name = file.name;
  //     newArray[index].path = path;

  //     setBillsFiles(newArray);
  //   };

  const handleFileChange = (file, index, path) => {
    const newArray = [...billsFiles];

    while (newArray.length <= index) {
      newArray.push({ file: undefined, name: undefined, path: undefined });
    }

    newArray[index] = { ...newArray[index], file, name: file.name, path };

    setBillsFiles(newArray);
  };
  //old version
  // const handleSave = async () => {
  //   const formData = new FormData();

  //   setLoading(true);

  //   const filesInfo = [];

  //   var data = bills.map((obj, index) => {
  //     // if (!obj.file) {
  //     //   NotificationManager.warning(
  //     //     `The files are required, please add the bill n° ${index + 1}`
  //     //   );

  //     //   return;
  //     // }
  //     if (obj.file?.name) {
  //       formData.append("files", obj.file);
  //       filesInfo.push({ id: obj.id, name: obj.file.name });

  //       // console.log("iiiiiii", obj);
  //       return { ...obj, file: obj.file.name };
  //     } else {
  //       return { ...obj };
  //     }
  //   });
  //   formData.append("fileInfo", JSON.stringify(filesInfo));

  //   const jsonData = JSON.stringify(data);
  //   formData.append("bills", jsonData);
  //   // console.log("jsonData", jsonData, bills);
  //   var res = await addBills(request?.mission?._id, formData);
  //   setLoading(false);

  //   if (res.status == 200) {
  //     NotificationManager.success(res.message);
  //     fetchBills();
  //   } else {
  //     NotificationManager.success(res.message);
  //   }
  // };

  const handleSave = async () => {
    setLoading(true);

    var res = await addBills(request?.mission?._id, bills);


    for (const row of billsFiles) {
      if (row.file instanceof File) {
        const formData = new FormData();

        formData.append("file", row.file);
        formData.append("path", row.path);
        const res = await addFiles(formData, request?.mission?._id);
        if (res.status == 400) {
          NotificationManager.error(res.message);
        } else {
          NotificationManager.success("Success");
          delete row.file;
          delete row.path;
        }
      }
    }

    setLoading(false);

    if (res.status == 200) {
      NotificationManager.success(res.message);
      fetchBills();
    } else {
      NotificationManager.success(res.message);
    }
  };

  const handleFileDownload = () => {
    const fileBlob = new Blob([fileData]);
    const fileURL = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = `file.${fileType}`;
    a.click();
  };

  // console.log("fileData", fileData);
  // console.log("bills", bills);

  console.log("preview", previewUrl);

  const handleFileDisplay = (file) => {
    console.log("🚀 ~ handleFileDisplay ~ fileBuffer:", file);
    var reader = new FileReader();
    reader.onload = (event) => {
      const dataURL = reader.result;
      console.log("🚀 ~ handleFileDisplay ~ dataURL:", dataURL);
      console.log("🚀 ~ handleFileDisplay ~ event:", event);
      setPreviewUrl(event.target.result);
    };
    reader.readAsDataURL(file);
    setFileType(file.type);
    setDisplay(true);

    // const reader = new FileReader();

    // // Define the event when the FileReader has finished loading the file
    // reader.onload = () => {
    //   // Get the data URL of the image
    //   const dataURL = reader.result;
    //   setPreviewUrl(dataURL);
    //   setFileData(null);
    //   setFileType(type);
    //   setDisplay(true);
    // };

    // // Read the file as a data URL
    // reader.readAsDataURL(fileBuffer);
    // } else {
    //   setPreviewUrl(null);
    //   const base64String = Buffer.from(fileBuffer.data).toString("base64");
    //   // console.log("base64String", base64String);
    //   // setFileData(base64String);
    //   setFileData(new Uint8Array(fileBuffer.data));
    //   const extension = type.split("/")[1];

    //   setFileType(extension);
    //   setDisplay(true);
    // }
  };

  const elementRef = useRef(null);

  const enterFullscreen = () => {
    const element = elementRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  return (
    <div>
      <List dense={true}>
        {bills.map((bill, index) => (
          <>
            {" "}
            <ListItem
              className="mt-5"
              secondaryAction={
                edit ? (
                  <IconButton
                    edge="end"
                    onClick={() => handleRemoveRow(index)}
                    variant="text"
                  >
                    <DeleteIcon style={{ color: color }} />
                  </IconButton>
                ) : (
                  ""
                )
              }
            >
              <ListItemAvatar>
                {/* <FolderIcon style={{ color: color }} /> */}
                <FaFileInvoiceDollar color={color} />
              </ListItemAvatar>
              <div className="flex flex-wrap gap-2 mb-2">
                <div className="">
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-800"
                  >
                    <Translation message={"Type"} />{" "}
                    <p className="text-red-500">*</p>
                  </Typography>
                  {edit ? (
                    <Select
                      options={types}
                      onChange={(event) => {
                        const newArray = [...bills];
                        newArray[index].type = event.target.value;
                        setBills(newArray);
                      }}
                      value={types?.find((item) => item.value == bill.type)}
                      placeholder={<Translation message="Type" />}
                      isClearable={true}
                      className="select-custom-width"
                    />
                  ) : (
                    <Input
                      value={
                        types?.find((item) => item.value == bill.type)?.label
                      }
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                    />
                  )}
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-800"
                  >
                    <Translation message={"Comment"} />{" "}
                  </Typography>
                  <Input
                    className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    color="blue-gray"
                    onChange={(event) => {
                      const newArray = [...bills];
                      newArray[index].comment = event.target.value;
                      setBills(newArray);
                    }}
                    value={bill.comment}
                    readOnly={!edit}
                  />
                </div>
                <div>
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-800"
                  >
                    <Translation message={"Admin comment"} />{" "}
                  </Typography>
                  <Input
                    value={bill.adminComment}
                    className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    readOnly={true}
                    color="blue-gray"
                  />
                </div>

                {/* <TextField
                type="number"
                label="Expanded amount"
                style={{ marginLeft: 10 }}
                className="w-300 p-5 pl-5"
                value={bill.expandedAmount}
                onChange={(event) => {
                  const newArray = [...bills];
                  newArray[index].expandedAmount = event.target.value;
                  setBills(newArray);
                }}
              /> */}

                <div>
                  <Typography
                    variant="small"
                    className="flex gap-1 mb-2 font-medium text-gray-800"
                  >
                    <Translation message={"Expanded amount"} />{" "}
                  </Typography>
                  <Input
                    value={bill.expandedAmount}
                    onChange={(event) => {
                      const newArray = [...bills];
                      newArray[index].expandedAmount = event.target.value;
                      setBills(newArray);
                    }}
                    type="number"
                    className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    color="blue-gray"
                    icon={<MdOutlineEuroSymbol />}
                    readOnly={!edit}
                  />
                </div>
                {edit && (
                  <div>
                    <Typography
                      variant="small"
                      className="flex gap-1 mb-2 font-medium text-gray-800"
                    >
                      <Translation message={"File"} />{" "}
                    </Typography>
                    <CustomFileInput
                      onFileChange={(e) =>
                        handleFileChange(e, index, `billsFiles.${index}.file`)
                      }
                    />
                    {billsFiles?.length > 0 &&
                      billsFiles[index]?.file &&
                      billsFiles[index].file instanceof File && (
                        <p>{billsFiles[index].file?.name}</p>
                      )}
                  </div>
                )}
                {bill.fileData ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    variant="text"
                    className="mt-2"
                    // onClick={() => handleRemoveRow(index)}
                  >
                    <VisibilityIcon
                      style={{ color: color }}
                      // onClick={() => {
                      //   setDisplay(true);
                      // }}
                      onClick={() =>
                        handleFileDisplay(bill.fileData, bill.contentType)
                      }
                    />
                  </IconButton>
                ) : bill.file ? (
                  <IconButton
                    edge="end"
                    variant="text"
                    className="mt-5"
                    // onClick={() => handleRemoveRow(index)}
                  >
                    <VisibilityIcon
                      style={{ color: color }}
                      // onClick={() => {
                      //   setDisplay(true);
                      // }}
                      onClick={() =>
                        bill.file instanceof File
                          ? handleFileDisplay(bill.file)
                          : handleFetchFile(bill.file)
                      }
                    />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </ListItem>
            <Divider variant="inset" component="li" className="mt-2" />
          </>

          // <div key={index} className="flex justify-between mt-5">
          //   <Select label="Select type">
          //     <MenuItem value={"invoice"}>Invoice</MenuItem>
          //     <MenuItem value={"return"}>Currency's return</MenuItem>
          //   </Select>

          //   <CustomFileInput onFileChange={handleFileChange} />
          // </div>
        ))}
      </List>
      {edit && (
        <Button
          onClick={handleAddRow}
          size="sm"
          color="indigo"
          className="mt-2 ml-2"
          variant="outlined"
        >
          <i className="fa fa-plus" />
        </Button>
      )}
      <div className="flex justify-between mt-5 p-5">
        <TextField
          multiline
          label={t("Total expanded amount")}
          className="w-300 p-5"
          value={totalExpandedAmount}
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        {request?.mission?.returnedAmount && (
          <TextField
            multiline
            label={t("Amount to be returned")}
            className="w-300 p-5"
            value={request?.mission?.returnedAmount}
            disabled
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        )}
      </div>

      {/* <div>
        {bills.map((item, index) => (
          <div key={index}>
            <p>Type: {item.type}</p>
            <p>Comment: {item.comment}</p>
            <button onClick={() => handleFileDisplay(item.file)}>
              Afficher le fichier
            </button>
          </div>
        ))}
      </div> */}
      {/* {
        fileData &&
          fileType &&
          // <img src={`data:image/jpeg;base64,${fileData}`} alt="Image" />
          (fileType.startsWith("image/") ? (
            <img
              ref={elementRef}
              src={`data:image/png;base64,${fileData}`}
              alt="Image"
              onClick={enterFullscreen}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <embed
              ref={elementRef}
              src={`data:application/pdf;base64,${fileData}`}
              type="application/pdf"
              width="100%"
              height="100%"
              onClick={enterFullscreen}
              style={{ cursor: "pointer" }}
            />
          ))
        // <embed
        //   src={`data:application/pdf;base64,${fileData}`}
        //   type="application/pdf"
        //   width="500"
        //   height="400"
        // />
      } */}

      {/* {previewUrl && (
        <div>
          <img src={previewUrl} alt="Preview" style={{ maxWidth: "300px" }} />
        </div>
      )} */}

      {edit && (
        <div className="flex justify-end ">
          {loading ? (
            <ClipLoader color="blue" loading={loading} size={20} />
          ) : (
            <Button
              onClick={handleSave}
              size="sm"
              color="indigo"
              className="mt-2 ml-2"
              buttonType="outline"
            >
              <i className="fa fa-save" /> <Translation message="Save" />
            </Button>
          )}
        </div>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <div className="flex flex-col place-items-center">
          <CircularProgress color="inherit" />
          <Translation message="Loading file" />
        </div>
      </Backdrop>

      {fileData && fileType && (
        <FileViewer
          fileData={fileData}
          view={display}
          // view={true}
          setView={setDisplay}
          fileType={fileType}
          // isBill={true}
          previewUrl={previewUrl}
          download={true}
        />
      )}

      {previewUrl && fileType && (
        <Preview
          view={display}
          setView={setDisplay}
          fileType={fileType}
          previewUrl={previewUrl}
        />
      )}
      <NotificationContainer />
    </div>
  );
};

export default BillsPanel;
