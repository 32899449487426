import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  IconButton,
  Input,
  Radio,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Translation from "Translation";
import { t } from "i18next";
import React from "react";
import Mission from "./Mission";
import { NotificationManager } from "react-notifications";
import { MdDelete } from "react-icons/md";
import Box from "@mui/material/Box";
import { IoIosAdd } from "react-icons/io";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { format } from "date-fns";

const MissionPanel = (props) => {
  const {
    request,
    edit,
    formData,
    setFormData,
    setIsEngagementCodeValid,
    isEngagementCodeValid,
    countries,
    allCities,
    users,
    formules,
    missions,
    setMissions,
    errors,
    missionData,
    handleFormChange,
    engagementCodeData,
    setEngagementCodeData,
  } = props;
  console.log("🚀 ~ MissionPanel ~ missions:", missions);

  const [open, setOpen] = React.useState(1);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    if (
      engagementCodeData?.statusEngagementCode?.length > 0 ||
      engagementCodeData?.engagementCode?.length > 0
    ) {
      NotificationManager.warning(
        "Please save your changes before navigating to another tab."
      );
      return;
    }
    setValue(newValue);
  };

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const handleChangeType = (event, newType) => {
    if (newType !== formData?.missionType) {
      setFormData((prevData) => {
        const {
          title,
          statusEngagementCode,
          engagementCode,
          startDate,
          endDate,
          missionCountry,
          missionCity,
          manager,
          partner,
          Formula,
          customer,
          ...rest
        } = prevData;
        return { ...rest, missionType: newType };
      });
      handleFormChange({ missionType: newType });

      // setFormData((prevData) => ({ ...prevData, missionType: newType,  }));
    }
  };

  const handleRemoveRow = (index) => {
    const values = [...missions];
    values.splice(index, 1);
    setMissions(values);
  };

  const handleAddRow = () => {
    // setErrors({});
    let errorsMessage = {};
    let isValid = true;
    if (missions.length > 0) {
      const previousRow = missions[missions.length - 1];
      if (previousRow && !previousRow.title) {
        errorsMessage[`title-${missions.length - 1}`] = "required field";
        isValid = false;
      }

      if (previousRow && !previousRow.statusEngagementCode) {
        errorsMessage[`statusEngagementCode-${missions.length - 1}`] =
          "required field";
        isValid = false;
      }
      if (
        previousRow &&
        previousRow.statusEngagementCode == "obtained" &&
        !previousRow.engagementCode
      ) {
        errorsMessage[`engagementCode-${missions.length - 1}`] =
          "required field";
        isValid = false;
      }

      if (previousRow && previousRow.engagementCode?.length > 0) {
        const regex = /^[EI]-\d{8}$/;
        if (regex.test(previousRow.engagementCode)) {
          errorsMessage[`engagementCode-${missions.length - 1}`] = null;
        } else {
          errorsMessage[`engagementCode-${missions.length - 1}`] =
            "Invalid format. The value should start with E or I followed by - and then 8 digits.";
          isValid = false;
        }
      }

      if (previousRow && !previousRow.startDate) {
        errorsMessage[`startDate-${missions.length - 1}`] = "required field";
        isValid = false;
      }

      if (previousRow && !previousRow.endDate) {
        errorsMessage[`endDate-${missions.length - 1}`] = "required field";
        isValid = false;
      }
      if (previousRow && !previousRow.country) {
        errorsMessage[`country-${missions.length - 1}`] = "required field";
        isValid = false;
      }

      if (previousRow && !previousRow.city) {
        errorsMessage[`city-${missions.length - 1}`] = "required field";
        isValid = false;
      }

      if (previousRow && !previousRow.Formula) {
        errorsMessage[`Formula-${missions.length - 1}`] = "required field";
        isValid = false;
      }
    }

    if (!isValid) {
      // setErrors(errorsMessage);
      NotificationManager.error(
        "Please fill in all required fields before adding a new mission"
      );
    } else {
      let newRow = {
        title: null,
        manager: null,
        partner: null,
        country: null,
        city: null,
        startDate: null,
        endDate: null,
        Formula: null,
        engagementCode: null,
      };

      if (missions.length > 0) {
        const lastLineEndDate = new Date(missions[missions.length - 1].endDate);
        const newStartDate = new Date(lastLineEndDate.getTime());
        const newEndDate = new Date(lastLineEndDate.getTime());
        newStartDate.setDate(lastLineEndDate.getDate() + 1);
        newRow.startDate = format(newStartDate, "yyyy-MM-dd");
        newEndDate.setDate(lastLineEndDate.getDate() + 2);
        newRow.endDate = format(newEndDate, "yyyy-MM-dd");
      }

      setMissions([...missions, newRow]);
    }
    return isValid;
  };
  return (
    <div>
      <div className="my-4 flex items-center gap-4">
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-800"
          >
            <Translation message={"Mission type"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={formData?.missionType || request?.mission?.missionType}
              exclusive
              onChange={handleChangeType}
              disabled={!edit}
            >
              <ToggleButton value="simple" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Simple mission"} />
                </p>
              </ToggleButton>
              <ToggleButton value="compound" style={{ outline: "none" }}>
                <p>
                  <Translation message={"Compound mission"} />
                </p>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
      {(formData?.missionType && formData?.missionType === "simple") ||
      (!formData?.missionType && request?.mission?.missionType === "simple") ? (
        <Mission
          request={request}
          edit={edit}
          formData={formData}
          setFormData={setFormData}
          setIsEngagementCodeValid={setIsEngagementCodeValid}
          isEngagementCodeValid={isEngagementCodeValid}
          countries={countries}
          allCities={allCities}
          users={users}
          formules={formules}
          errors={errors}
          missionData={missionData}
          handleFormChange={handleFormChange}
          engagementCodeData={engagementCodeData}
          setEngagementCodeData={setEngagementCodeData}
        />
      ) : (formData?.missionType && formData?.missionType === "compound") ||
        (!formData?.missionType &&
          request?.mission?.missionType === "compound") ? (
        <div className="mt-5">
          <AntTabs
            value={value}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            {missions?.map((item, index) => (
              <AntTab
                label={
                  item.title ? (
                    <p className="flex gap-3">
                      <Avatar
                        src={`https://flagcdn.com/w20/${item.country?.country_code?.toLowerCase()}.png`}
                        alt="avatar"
                        size="xs"
                      />

                      {item.title}
                    </p>
                  ) : (
                    `Mission ${index + 1}`
                  )
                }
                {...a11yProps(0)}
                style={{ outline: "none", minWidth: "250px" }}
                icon={
                  edit ? (
                    <MdDelete
                      size={20}
                      color="red"
                      onClick={() => {
                        handleRemoveRow(index);
                      }}
                      style={{ position: "absolute", right: "10px" }}
                      // sx={{ alignSelf: "flex-end" }}
                      title={t("Delete this mission")}
                    />
                  ) : (
                    <></>
                  )
                }
                iconPosition="end"
              />
            ))}
            {edit && (
              <AntTab
                {...a11yProps(0)}
                style={{
                  outline: "none",
                  width: "50px",

                  backgroundColor: "#ffffff",
                  borderRadius: "6px 6px 0 0",
                  border: "none",
                }}
                icon={
                  <IoIosAdd
                    size={30}
                    color="#2070b9"
                    title={t("Add another mission")}
                  />
                }
                iconPosition="end"
                onClick={(e) => {
                  // e.stopPropagation();
                  if (!handleAddRow()) {
                    setValue(value);
                  }
                }}
              />
            )}
          </AntTabs>
          {missions?.map((item, index) => (
            <CustomTabPanel value={value} index={index}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <img
                  loading="lazy"
                  // width="50"
                  src={`https://flagcdn.com/w40/${item.country?.country_code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${item.country?.country_code.toLowerCase()}.png 2x`}
                  alt=""
                  // style={{ position: "relative", right: "10px" }}
                /> */}
              </div>
              <Mission
                request={request}
                edit={edit}
                formData={formData}
                setFormData={setFormData}
                setIsEngagementCodeValid={setIsEngagementCodeValid}
                isEngagementCodeValid={isEngagementCodeValid}
                countries={countries}
                allCities={allCities}
                users={users}
                formules={formules}
                missions={missions}
                setMissions={setMissions}
                index={index}
                engagementCodeData={engagementCodeData}
                setEngagementCodeData={setEngagementCodeData}
                missionData={missionData}
              />
            </CustomTabPanel>
          ))}
        </div>
      ) : (
        ""
      )}

      <div className="mt-5">
        <Typography variant="small" className="mb-2 font-medium text-gray-700">
          <Translation
            message={
              "Please mention below the people involved in the validation of your mission"
            }
          />{" "}
        </Typography>
        <Textarea
          value={formData?.comment || request?.mission?.comment}
          onChange={(e) => {
            setFormData((prevData) => ({
              ...prevData,
              comment: e.target.value,
            }));
          }}
          className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          color="blue-gray"
        />
      </div>
    </div>
  );
};

export default MissionPanel;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 5,
            border: "1px solid #e8e8e8 ",
            borderTop: 0,
            backgroundColor: "#f5f5f8c2",
            borderRadius: "0 6px 6px 6px",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  // borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#2070b9",
  },
  maxHeight: "20px",
  outline: "none",
  // height: "20px",
  ".MuiTabs-indicator": {
    backgroundColor: "#ffffff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },

    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      // color: "#2070b9",
      opacity: 1,
      backgroundColor: "#f2f2f2",
      borderRadius: "6px 6px 0 0",
    },
    "&.Mui-selected": {
      color: "#01579b",
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: "6px 6px 0 0",
      // border: "1px solid #2070b9",
      border: "1px solid #e8e8e8",
      // backgroundColor: "#ebf2f7",
      backgroundColor: "#f5f5f8c2",
      zIndex: 1,
      borderBottom: "none",
      "& .MuiTabs-indicator": {
        borderBottom: "none",
      },
    },
    "&.MuiTab-labelIcon": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    outline: "none",
    paddingBottom: "30px",
    border: "1px solid #e8e8e8",
    borderRadius: "6px 6px 0 0",
  })
);
