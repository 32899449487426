import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Chip,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import { RiFoldersFill } from "react-icons/ri";
import { CiBarcode } from "react-icons/ci";
import { IoCalendarOutline, IoPricetagOutline } from "react-icons/io5";
import { AiOutlineEnvironment } from "react-icons/ai";
import { IoMdTime } from "react-icons/io";
import { format } from "date-fns";
import { FaEdit, FaFolder } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import Translation from "Translation";

const MissionsCard = (props) => {
  const { request, edit } = props;
  return (
    <div>
      <Card className="mt-6 w-96">
        <CardBody>
          <div className="flex justify-between ">
            <Typography variant="h6" className="text-blue-900 mb-2">
              {request.mission?.missionType == "compound"
                ? request?.mission?.missions?.map((m) => m.title + ", ")
                : request?.mission?.title}
            </Typography>
            {request.mission?.missionType == "compound" ? (
              <RiFoldersFill className="text-blue-900" size={25} />
            ) : (
              <FaFolder className="text-blue-900" size={25} />
            )}
          </div>
          <div>
            <Typography
              variant="small"
              className="flex gap-2 text-gray-700 mb-2"
            >
              <CiBarcode />
              {request.mission?.missionType == "simple" &&
              request?.mission?.engagementCode
                ? request?.mission?.engagementCode
                : request.mission?.missionType == "simple" &&
                  !request?.mission?.engagementCode
                ? request?.mission?.statusEngagementCode
                : request?.mission?.missions?.map((m) =>
                    m.engagementCode
                      ? m.engagementCode + ", "
                      : m.statusEngagementCode + ", "
                  )}
            </Typography>
            <Typography
              variant="small"
              className="flex gap-2 text-gray-700 mb-2"
            >
              <IoCalendarOutline />
              {request?.mission?.missionType == "compound" ? (
                <>
                  {`${
                    request?.mission?.missions[0]?.startDate &&
                    format(
                      new Date(request?.mission?.missions[0]?.startDate),
                      "dd-MM-yyyy"
                    )
                  } To ${
                    request?.mission?.missions[
                      request?.mission?.missions?.length - 1
                    ]?.endDate &&
                    format(
                      new Date(
                        request?.mission?.missions[
                          request?.mission?.missions?.length - 1
                        ]?.endDate
                      ),
                      "dd-MM-yyyy"
                    )
                  }`}
                </>
              ) : (
                <>
                  {`${
                    request?.mission &&
                    request?.mission?.startDate &&
                    format(new Date(request?.mission?.startDate), "dd-MM-yyyy")
                  } To ${
                    request?.mission &&
                    request?.mission?.endDate &&
                    format(new Date(request?.mission?.endDate), "dd-MM-yyyy")
                  }`}
                </>
              )}
            </Typography>
            <Typography
              variant="small"
              className="flex gap-2 text-gray-700 mb-2"
            >
              <AiOutlineEnvironment />
              {request?.mission?.missionType == "compound"
                ? request?.mission?.missions[0]?.city?.name +
                  ", " +
                  request?.mission?.missions[
                    request?.mission?.missions.length - 1
                  ]?.city?.name
                : request?.mission
                ? `${request?.mission?.missionCountry?.name}, ${request?.mission?.missionCity?.name}`
                : `${request?.visa?.country?.name}, ${request?.visa?.city?.name}`}
            </Typography>
            <Typography
              variant="small"
              className="flex gap-2 text-gray-700 mb-2"
            >
              <IoMdTime />
              {request?.createdAt &&
                format(new Date(request?.createdAt), "dd-MM-yyyy hh:mm ")}
            </Typography>
            <Typography
              variant="small"
              className="flex gap-2 text-gray-700 mb-2"
            >
              <IoPricetagOutline />
              {request?.mission?.currentStep?.name}
            </Typography>
            <div className="w-max ml-3">
              <Chip
                size="sm"
                variant="ghost"
                value={
                  request.status == "refused"
                    ? "rejected"
                    : request.status == "pending"
                    ? "waiting validation"
                    : "approved"
                }
                color={
                  request.status == "refused"
                    ? "red"
                    : request.status == "pending"
                    ? "amber"
                    : "green"
                }
                //   color={
                //     status === "paid"
                //       ? "green"
                //       : status === "pending"
                //       ? "amber"
                //       : "red"
                //   }
              />
            </div>
          </div>
        </CardBody>
        <CardFooter className="pt-0 flex justify-between">
          <Link to={`/mission_details/${request?._id}`}>
            <Button
              size="sm"
              variant="text"
              className="flex items-center gap-2"
              color="indigo"
              style={{ background: "none" }}
              title="consult the details"
            >
              <Translation message="Learn More" />
              <FaArrowRightLong />
            </Button>
          </Link>
          {edit ? (
            <ButtonGroup variant="outlined" color="indigo" size="sm">
              <Button
                size="sm"
                // variant="filled"
                className="flex items-center gap-2"
                // color="indigo"
                style={{ background: "none" }}
                title="edit"
              >
                <Link
                  to={{
                    pathname: `/mission_details/${request?._id}`,
                    // state: { edit: true },
                  }}
                  state={{ edit: true }}
                >
                  <FaEdit size={14} style={{ background: "none" }} />
                </Link>
              </Button>
              <Button
                size="sm"
                // variant="text"
                className="flex items-center gap-2"
                style={{ background: "none" }}
                title="delete"
              >
                <MdDelete
                  size={16}
                  style={{ background: "none" }}
                  color="red"
                />
              </Button>
            </ButtonGroup>
          ) : (
            ""
          )}
          {/* <ButtonGroup variant="text">
            <Link
              to={{
                pathname: `mission_details/${request?._id}`,
                state: { edit: true },
              }}
            >
              <Button title="edit the request">
                <FaEdit />
              </Button>
            </Link>
            {request.status != "refused" && (
              <Button title="cancel the request">
                <MdDelete color="primary" />
              </Button>
            )}
          </ButtonGroup> */}
        </CardFooter>
      </Card>
    </div>
  );
};

export default MissionsCard;
