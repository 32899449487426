import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import reportWebVitals from "reportWebVitals";

import { ThemeProvider } from "@material-tailwind/react";
// import {
//   createTheme,
//   StyledEngineProvider,
//   ThemeProvider,
// } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./tailwind.css";
import { createRoot } from "react-dom/client";
import "./i18n";

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// const theme = createTheme({
//   components: {
//     MuiPopover: {
//       defaultProps: {
//         container: rootElement,
//       },
//     },
//     MuiPopper: {
//       defaultProps: {
//         container: rootElement,
//       },
//     },
//   },
// });

// root.render(
//   <React.StrictMode>
//     <StyledEngineProvider injectFirst>
//       <BrowserRouter>
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <App />
//         </ThemeProvider>
//       </BrowserRouter>
//     </StyledEngineProvider>
//   </React.StrictMode>
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
