import React from "react";

const Image = () => {
  return (
    <div>
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "svg#freepik_stories-gdpr:not(.animated) .animable {opacity: 0;}svg#freepik_stories-gdpr.animated #freepik--Lockpad--inject-2 {animation: 1.5s Infinite  linear floating;animation-delay: 0s;}            @keyframes floating {                0% {                    opacity: 1;                    transform: translateY(0px);                }                50% {                    transform: translateY(-10px);                }                100% {                    opacity: 1;                    transform: translateY(0px);                }            }        .animator-hidden { display: none; }",
          }}
        />
        <svg
          className="animated"
          id="freepik_stories-gdpr"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 750 500"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          //   xmlns:svgjs="http://svgjs.com/svgjs"
        >
          <g
            id="freepik--background-complete--inject-2"
            className="animable"
            style={{ transformOrigin: "374.915px 240.46px" }}
          >
            <path
              d="M682.37,304.22l-13.12.28-6.66.11c-2.23.05-4.47,0-6.7.06h-.44v-.45L655.39,293v-.5h.5l13.24.06c4.42,0,8.83.11,13.24.16h.28V293c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.82.16-13.24.16l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05L669,304Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "669.02px 298.585px",
              }}
              id="elsslh0ijr9jq"
              className="animable"
            />
            <path
              d="M667.6,318.33l-13.12.28-6.67.11c-2.22,0-4.46,0-6.69.06h-.44v-.45l0-11.19v-.5h.5l13.24.07c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.83.16-13.24.16l-13.24.06.5-.5,0,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.57.12Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "654.31px 312.71px",
              }}
              id="ely39as1mdl8a"
              className="animable"
            />
            <path
              d="M697.14,318.33l-13.12.28-6.66.11c-2.23,0-4.47,0-6.7.06h-.44v-.45l-.06-11.19v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.11-3.7-.13-5.54l.27.28c-4.41.05-8.82.16-13.24.16l-13.24.06.5-.5,0,11.19-.45-.44c2.19,0,4.36,0,6.55.05l6.58.12Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "683.79px 312.71px",
              }}
              id="elpciudbftnpk"
              className="animable"
            />
            <path
              d="M349.87,116.08l-13.12.28-6.66.11c-2.23,0-4.47,0-6.7.06H323v-.45l-.06-11.19v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.11-3.71-.13-5.54l.27.28c-4.41,0-8.82.15-13.24.16l-13.24.06.5-.5-.05,11.19-.45-.44c2.18,0,4.36,0,6.55,0l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "336.57px 110.46px",
              }}
              id="el8a33tgzxy2e"
              className="animable"
            />
            <path
              d="M335.1,130.19l-13.12.28-6.66.11c-2.23,0-4.47,0-6.7.06h-.44v-.45L308.12,119v-.5h.5l13.24.07c4.42,0,8.83.11,13.24.16h.28V119c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c0-1.86-.11-3.71-.13-5.54l.27.28c-4.41,0-8.82.15-13.24.16l-13.24.06.5-.5-.05,11.19-.45-.44c2.18,0,4.36,0,6.55.05l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "321.75px 124.57px",
              }}
              id="elqn40efk42eo"
              className="animable"
            />
            <path
              d="M364.65,130.19l-13.13.28-6.66.11c-2.23,0-4.46,0-6.7.06h-.44v-.45L337.67,119v-.5h.5l13.25.07c4.41,0,8.82.11,13.24.16h.27V119c0,1.9-.09,3.79-.13,5.66Zm0,0-.15-5.65c0-1.86-.11-3.71-.13-5.54l.28.28c-4.42,0-8.83.15-13.24.16l-13.25.06.5-.5-.05,11.19-.45-.44c2.19,0,4.36,0,6.55.05l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "351.3px 124.57px",
              }}
              id="el0owbp2y2udn"
              className="animable"
            />
            <path
              d="M158,231.87l-13.13.28-6.66.11c-2.22.05-4.46,0-6.69.05h-.44v-.45l0-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.17h.27v.27c0,1.9-.08,3.79-.13,5.65Zm0,0-.14-5.66c0-1.86-.11-3.7-.13-5.53l.27.27c-4.41.05-8.83.16-13.24.17l-13.24.06.5-.5L132,231.87l-.44-.45c2.18,0,4.36,0,6.54.06l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "144.705px 226.24px",
              }}
              id="elc6zeglv88x9"
              className="animable"
            />
            <path
              d="M682.09,62.38,669,62.66l-6.66.11c-2.22,0-4.46,0-6.69.06h-.45v-.45l0-11.19v-.5h.5l13.24.06c4.41,0,8.82.12,13.24.17h.27v.27c0,1.9-.09,3.79-.13,5.65Zm0,0L682,56.73c-.05-1.87-.12-3.71-.14-5.54l.28.27c-4.42,0-8.83.16-13.24.17l-13.24.06.5-.5-.06,11.19-.44-.45c2.18,0,4.36,0,6.54.06l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "668.825px 56.76px",
              }}
              id="elocq1qbr2f9r"
              className="animable"
            />
            <path
              d="M696.86,76.49l-13.13.28-6.66.11c-2.22.05-4.46,0-6.69.05h-.44v-.45l0-11.19v-.5h.5l13.24.06c4.41,0,8.83.12,13.24.17h.28v.27c0,1.9-.08,3.79-.13,5.65Zm0,0-.14-5.65c-.05-1.87-.11-3.71-.13-5.54l.27.27c-4.41.05-8.83.16-13.24.17l-13.24.06.5-.5-.06,11.19-.44-.45c2.18,0,4.36,0,6.54.06l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "683.57px 70.86px",
              }}
              id="elt7k2byaf5gj"
              className="animable"
            />
            <path
              d="M79.34,356.64l-13.13.28-6.66.12c-2.22,0-4.46,0-6.69,0h-.45v-.45l0-11.18V345h.51L66.1,345c4.41,0,8.82.11,13.24.16h.27v.27c0,1.89-.09,3.78-.13,5.65Zm0,0L79.19,351c0-1.86-.11-3.7-.13-5.53l.28.27c-4.42,0-8.83.16-13.24.16L52.86,346l.5-.49-.06,11.18-.44-.44c2.18,0,4.35,0,6.54.05l6.58.12Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "66.01px 351.02px",
              }}
              id="el70ryyfltmf7"
              className="animable"
            />
            <path
              d="M94.11,370.75,81,371l-6.66.12c-2.22,0-4.46,0-6.69.05h-.45v-.45l-.05-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.16h.27v.26c0,1.9-.08,3.79-.13,5.66Zm0,0L94,365.1c0-1.86-.12-3.7-.13-5.54l.27.28c-4.41.05-8.83.16-13.24.16l-13.24.06.5-.5-.06,11.19-.44-.44c2.18,0,4.36,0,6.54.05l6.58.12Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "80.775px 365.1px",
              }}
              id="elcuupalb5g3"
              className="animable"
            />
            <path
              d="M79.34,62.39l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69.06h-.45v-.45l0-11.19v-.5h.51l13.24.07c4.41,0,8.82.11,13.24.16h.27v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.15-5.65c0-1.87-.11-3.71-.13-5.54l.28.27c-4.42.06-8.83.16-13.24.17l-13.24.06.5-.5L53.3,62.39,52.86,62c2.18,0,4.35,0,6.54,0l6.58.11Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "66.04px 56.77px",
              }}
              id="elt4roqti2fs"
              className="animable"
            />
            <path
              d="M68.2,260a3,3,0,0,1,3.11,0c.26.16.52.31.79.45s.55.29.83.42a3,3,0,0,1,1.73,2.57l.12,3.82,12.46-.38-.12-3.83a3,3,0,0,1,1.57-2.68c.54-.3,1.07-.63,1.58-1a3,3,0,0,1,3.09-.2L96.72,261l5.89-11-3.36-1.81a3,3,0,0,1-1.55-2.68,14.45,14.45,0,0,0-.06-1.86A3,3,0,0,1,99,240.91l3.25-2L95.7,228.3l-3.27,2a3,3,0,0,1-3.1,0l-.79-.46-.82-.41A3,3,0,0,1,86,226.86L85.86,223l-12.45.39.12,3.81A3,3,0,0,1,72,229.92a18.31,18.31,0,0,0-1.59,1,3,3,0,0,1-3.09.2l-3.37-1.8-5.89,11,3.36,1.8a3,3,0,0,1,1.55,2.69q0,.93.06,1.86a3,3,0,0,1-1.38,2.79l-3.24,2L65,262Zm3.12-19.68a10.21,10.21,0,1,1,4.18,13.83A10.23,10.23,0,0,1,71.32,240.34Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "80.335px 245.13px",
              }}
              id="elt73gadvbir"
              className="animable"
            />
            <path
              d="M552.24,100.22a2.06,2.06,0,0,1,1,1.93c0,.21,0,.42,0,.64s0,.43,0,.65a2.07,2.07,0,0,1-1,1.92l-2.32,1.34,4.36,7.56,2.32-1.34a2.09,2.09,0,0,1,2.18.08,12.53,12.53,0,0,0,1.12.65,2.08,2.08,0,0,1,1.15,1.84v2.68h8.73v-2.68a2.08,2.08,0,0,1,1.15-1.84,12.7,12.7,0,0,0,1.13-.65,2.07,2.07,0,0,1,2.17-.08l2.32,1.34,4.37-7.56-2.33-1.35a2.07,2.07,0,0,1-1-1.92c0-.21,0-.43,0-.64s0-.43,0-.64a2.08,2.08,0,0,1,1-1.93l2.33-1.34-4.37-7.56-2.31,1.34a2.09,2.09,0,0,1-2.18-.08c-.36-.23-.74-.45-1.13-.65a2.08,2.08,0,0,1-1.15-1.85V87.41h-8.73v2.67a2.08,2.08,0,0,1-1.15,1.85c-.39.2-.77.42-1.13.65a2.09,2.09,0,0,1-2.18.08l-2.31-1.34-4.36,7.56Zm13.18-4.59a7.16,7.16,0,1,1-7.15,7.16A7.17,7.17,0,0,1,565.42,95.63Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "565.42px 102.79px",
              }}
              id="elowzqaqfy88"
              className="animable"
            />
            <path
              d="M615.87,98.65a34,34,0,1,0,34-34A34,34,0,0,0,615.87,98.65Zm18.12,0a15.89,15.89,0,1,1,15.88,15.88A15.89,15.89,0,0,1,634,98.65Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "649.87px 98.65px",
              }}
              id="eldqw5f99vvkd"
              className="animable"
            />
            <polygon
              points="643.34 66.05 656.41 66.05 655.69 56.8 644.06 56.8 643.34 66.05"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "649.875px 61.425px",
              }}
              id="elejuj9mbd7y8"
              className="animable"
            />
            <polygon
              points="668.23 70.46 677.47 79.71 683.51 72.65 675.28 64.42 668.23 70.46"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "675.87px 72.065px",
              }}
              id="elm262dnjlq2b"
              className="animable"
            />
            <polygon
              points="682.71 91.18 682.71 104.25 691.97 103.53 691.97 91.9 682.71 91.18"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "687.34px 97.715px",
              }}
              id="elktofwww4anh"
              className="animable"
            />
            <polygon
              points="678.31 116.07 669.06 125.31 676.12 131.35 684.34 123.12 678.31 116.07"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "676.7px 123.71px",
              }}
              id="eli40idgk3guc"
              className="animable"
            />
            <polygon
              points="657.59 130.55 644.51 130.55 645.24 139.81 656.87 139.81 657.59 130.55"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "651.05px 135.18px",
              }}
              id="elzu43s1rv44"
              className="animable"
            />
            <polygon
              points="632.7 126.15 623.45 116.9 617.42 123.96 625.64 132.18 632.7 126.15"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "625.06px 124.54px",
              }}
              id="ely7ypb1zfkaq"
              className="animable"
            />
            <polygon
              points="618.21 105.43 618.21 92.35 608.96 93.08 608.96 104.71 618.21 105.43"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "613.585px 98.89px",
              }}
              id="elnz14ncof6c"
              className="animable"
            />
            <polygon
              points="622.62 80.54 631.87 71.29 624.81 65.26 616.59 73.48 622.62 80.54"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "624.23px 72.9px",
              }}
              id="elx0oxvx1lzwf"
              className="animable"
            />
            <polygon
              points="656.71 430.23 655.71 430.23 655.71 304.41 655.8 304.27 682.44 267.92 682.44 199.75 683.44 199.75 683.44 268.25 683.34 268.38 656.71 304.73 656.71 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "669.575px 314.99px",
              }}
              id="el804nv01fjnc"
              className="animable"
            />
            <path
              d="M682.94,200.29a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,682.94,200.29Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,682.94,195.54Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "682.93px 197.41px",
              }}
              id="elyuhyrldy5js"
              className="animable"
            />
            <polygon
              points="663.59 430.23 662.59 430.23 662.59 308.81 662.69 308.68 689.32 272.33 689.32 224.75 690.32 224.75 690.32 272.66 690.23 272.79 663.59 309.14 663.59 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "676.455px 327.49px",
              }}
              id="elzv6sekfc1sg"
              className="animable"
            />
            <path
              d="M689.82,225.29a2.88,2.88,0,1,1,2.88-2.87A2.88,2.88,0,0,1,689.82,225.29Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,689.82,220.54Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "689.82px 222.41px",
              }}
              id="elcx803daoafs"
              className="animable"
            />
            <rect
              x="649.07"
              y="263.82"
              width={1}
              height="166.41"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "649.57px 347.025px",
              }}
              id="elnptuoc3fae"
              className="animable"
            />
            <path
              d="M649.57,264.32a2.88,2.88,0,1,1,2.88-2.87A2.88,2.88,0,0,1,649.57,264.32Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,649.57,259.57Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "649.57px 261.44px",
              }}
              id="elpv4dat0pafr"
              className="animable"
            />
            <polygon
              points="643.69 430.23 642.69 430.23 642.69 345.78 624.54 311.55 624.54 250.06 625.54 250.06 625.54 311.3 643.69 345.53 643.69 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "634.115px 340.145px",
              }}
              id="elgexgnv18mf"
              className="animable"
            />
            <path
              d="M625,250.56a2.88,2.88,0,1,1,2.88-2.87A2.87,2.87,0,0,1,625,250.56Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,625,245.81Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "625px 247.68px",
              }}
              id="el3vyurjdyo8l"
              className="animable"
            />
            <polygon
              points="637.34 430.23 636.34 430.23 636.34 349.45 614.94 311.61 614.94 271.06 615.94 271.06 615.94 311.34 637.34 349.18 637.34 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "626.14px 350.645px",
              }}
              id="eleuz3zrzzykq"
              className="animable"
            />
            <path
              d="M615.44,271.56a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,615.44,271.56Zm0-4.75a1.88,1.88,0,1,0,1.88,1.87A1.87,1.87,0,0,0,615.44,266.81Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "615.44px 268.68px",
              }}
              id="elgu567zkmfph"
              className="animable"
            />
            <polygon
              points="94.29 430.23 93.29 430.23 93.29 364.73 66.56 328.25 66.56 289.75 67.56 289.75 67.56 327.92 94.29 364.41 94.29 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "80.425px 359.99px",
              }}
              id="el2gzdoiab4n1"
              className="animable"
            />
            <path
              d="M67.06,290.29a2.88,2.88,0,1,1,2.88-2.87A2.88,2.88,0,0,1,67.06,290.29Zm0-4.75a1.88,1.88,0,1,0,1.88,1.88A1.88,1.88,0,0,0,67.06,285.54Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "67.06px 287.41px",
              }}
              id="elgytyalgg2ns"
              className="animable"
            />
            <polygon
              points="87.41 430.23 86.41 430.23 86.41 369.14 59.68 332.66 59.68 314.75 60.68 314.75 60.68 332.33 87.41 368.81 87.41 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "73.545px 372.49px",
              }}
              id="elzbhah0zng8"
              className="animable"
            />
            <path
              d="M60.18,315.29a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,60.18,315.29Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,60.18,310.54Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "60.17px 312.41px",
              }}
              id="el4zbr1vpyb18"
              className="animable"
            />
            <rect
              x="99.93"
              y="323.82"
              width={1}
              height="106.41"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "100.43px 377.025px",
              }}
              id="ellvhgcb3jciq"
              className="animable"
            />
            <path
              d="M100.43,324.32a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,100.43,324.32Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.88,1.88,0,0,0,100.43,319.57Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "100.42px 321.44px",
              }}
              id="eljj7scm22cz8"
              className="animable"
            />
            <polygon
              points="107.31 430.23 106.31 430.23 106.31 375.53 106.37 375.42 124.46 341.3 124.46 280.06 125.46 280.06 125.46 341.55 125.4 341.66 107.31 375.78 107.31 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "115.885px 355.145px",
              }}
              id="el0f3qajd3xhpm"
              className="animable"
            />
            <path
              d="M125,280.56a2.88,2.88,0,1,1,2.87-2.87A2.88,2.88,0,0,1,125,280.56Zm0-4.75a1.88,1.88,0,1,0,1.87,1.88A1.89,1.89,0,0,0,125,275.81Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "124.99px 277.68px",
              }}
              id="el3bjp2itht0u"
              className="animable"
            />
            <polygon
              points="113.66 430.23 112.66 430.23 112.66 379.18 112.72 379.07 134.06 341.34 134.06 301.06 135.06 301.06 135.06 341.61 134.99 341.72 113.66 379.45 113.66 430.23"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "123.86px 365.645px",
              }}
              id="elgok3xj7mqys"
              className="animable"
            />
            <path
              d="M134.56,301.56a2.88,2.88,0,1,1,2.87-2.88A2.88,2.88,0,0,1,134.56,301.56Zm0-4.75a1.88,1.88,0,1,0,1.87,1.87A1.88,1.88,0,0,0,134.56,296.81Z"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "134.55px 298.68px",
              }}
              id="elef10on1x0xh"
              className="animable"
            />
          </g>
          <g
            id="freepik--Floor--inject-2"
            className="animable"
            style={{ transformOrigin: "375px 429.735px" }}
          >
            <polygon
              points="50.72 429.73 131.79 429.49 212.86 429.4 375 429.24 537.14 429.4 618.21 429.49 699.28 429.73 618.21 429.98 537.14 430.07 375 430.23 212.86 430.06 131.79 429.98 50.72 429.73"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "375px 429.735px",
              }}
              id="elz4dt61ycy9m"
              className="animable"
            />
          </g>
          <g
            id="freepik--Plant--inject-2"
            className="animable animator-hidden"
            style={{ transformOrigin: "101.697px 346.804px" }}
          >
            <path
              d="M97.46,343.63c-7.25-14.9-19.93-22.54-34.22-18.32s-16.5,24.16-5.84,29.19,21.34-9.66,27-12.68S97.46,343.63,97.46,343.63Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "74.0903px 339.848px",
              }}
              id="eltrmlwcg3qx"
              className="animable"
            />
            <path
              d="M104.47,383.28c0-1.8-.1-3.59-.17-5.39-.12-3.58-.24-7.17-.38-10.75-.24-6.56-1-13-3.66-19a29.57,29.57,0,0,0-11.5-13.25,24.4,24.4,0,0,0-6-2.6,14.11,14.11,0,0,0-7-3.88,28.81,28.81,0,0,0-10.06-.25c-.12,0-.11.2,0,.19a24,24,0,0,1,9.51.68A19.61,19.61,0,0,1,79,330.48a18.07,18.07,0,0,1,2.22,1.4,20.62,20.62,0,0,0-8.74.09,19.54,19.54,0,0,0-3,1,9.14,9.14,0,0,0-2.38-.21,14.06,14.06,0,0,0-3.09.45,23.11,23.11,0,0,0-5.88,2.56c-.11.06,0,.22.1.16a22.17,22.17,0,0,1,5.73-2.16,19.79,19.79,0,0,1,3-.43,15.14,15.14,0,0,1,1.65,0,19,19,0,0,0-9.25,9.21c-.05.11.12.2.17.09a17.37,17.37,0,0,1,11.78-9.44c.31-.08.63-.12,1-.18a14.36,14.36,0,0,0-.15,1.44q-.15,1.08-.36,2.16A36.4,36.4,0,0,1,70.46,341a32.94,32.94,0,0,1-1.73,4.23,20.66,20.66,0,0,1-2.41,4.08c-.09.1.07.22.15.11.86-1.35,1.85-2.59,2.64-4a30.8,30.8,0,0,0,1.94-4.16,28.84,28.84,0,0,0,1.27-4.47c.16-.8.28-1.61.38-2.42a6.11,6.11,0,0,0,.09-1.48,19.55,19.55,0,0,1,5.07-.1c-.63,3.55-1.31,7.25-3.31,10.34-.06.1.11.2.17.09a41.7,41.7,0,0,0,2.46-5.08,19.29,19.29,0,0,0,1.22-5.29,23.33,23.33,0,0,1,8.39,2.81A27.7,27.7,0,0,1,98,347.89a45.85,45.85,0,0,1,4.14,17.52c.48,7.61.58,15.27,1.34,22.86,0,.5.83.66.93.1A26.22,26.22,0,0,0,104.47,383.28Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "81.3249px 358.278px",
              }}
              id="el2oere6p1gnb"
              className="animable"
            />
            <path
              d="M87.73,333.66a8.52,8.52,0,0,0-1.65-1.52,22.85,22.85,0,0,0-1.84-1.4,13.69,13.69,0,0,0-4.12-1.91c-.11,0-.16.16,0,.18a11.1,11.1,0,0,1,4.06,2,21.69,21.69,0,0,1,1.75,1.42,11.23,11.23,0,0,0,1.67,1.41A.13.13,0,0,0,87.73,333.66Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "83.879px 331.335px",
              }}
              id="elqt6ibwidid"
              className="animable"
            />
            <path
              d="M64.6,339.06a42.83,42.83,0,0,0-3.32,4.39,26.6,26.6,0,0,0-2.48,4.91c0,.05.07.08.09,0a58,58,0,0,1,5.85-9.2C64.81,339.09,64.68,339,64.6,339.06Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "61.78px 343.725px",
              }}
              id="el6k1tjlamuv"
              className="animable"
            />
            <path
              d="M80.19,337.58a11.08,11.08,0,0,1-.82,2.7A17.83,17.83,0,0,1,78,342.67a.05.05,0,0,0,.08.06,9.69,9.69,0,0,0,2.3-5.1C80.39,337.51,80.21,337.46,80.19,337.58Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "79.1852px 340.131px",
              }}
              id="elzjepfksikmr"
              className="animable"
            />
            <path
              d="M103.46,344s10.26-12.9,30.07-8.33c18.82,4.35,27.51,38.86,8.49,43s-15.67-27.81-22.6-33.23C114.33,341.45,107,342.31,103.46,344Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "128.066px 356.865px",
              }}
              id="elg6980y1sbfq"
              className="animable"
            />
            <path
              d="M148.82,354.52a38.7,38.7,0,0,0-4.08-3.13,17.88,17.88,0,0,0-4.35-2.17,26.39,26.39,0,0,0-2.58-3.32,25.69,25.69,0,0,0-6-4.76,5.65,5.65,0,0,1,.59,0,29.9,29.9,0,0,1,3.14,0,17.77,17.77,0,0,1,6.12,1.52.09.09,0,0,0,.08-.17,27.61,27.61,0,0,0-6.11-1.84,16.82,16.82,0,0,0-3.09-.27c-.55,0-1.11.05-1.66.1l-.32,0a26.25,26.25,0,0,0-5.71-2.1l.55-.15a26.48,26.48,0,0,1,2.94-.48,22.57,22.57,0,0,1,5.92.09c.12,0,.16-.17,0-.19a37.48,37.48,0,0,0-6-.24,24.89,24.89,0,0,0-5,.64,27.13,27.13,0,0,0-3.61-.41c-6.91-.29-14.44,2.29-18.69,8S96,358.89,95.22,365.74a152.14,152.14,0,0,0-.76,28.78c0,.57.84.47.86-.07.26-8.14.54-16.28,1.32-24.39.36-3.76.76-7.53,1.39-11.25a32.91,32.91,0,0,1,2.73-9.26,16.84,16.84,0,0,1,6.68-7.18,22.63,22.63,0,0,1,9.47-2.92,25.27,25.27,0,0,1,8.88.84,6,6,0,0,0-.05,1.75,10,10,0,0,0,.44,2.16,10.91,10.91,0,0,0,.83,1.92c.36.68.82,1.29,1.23,1.94.07.1.23,0,.16-.11a9,9,0,0,1-1.54-3.9,17.38,17.38,0,0,1-.24-2c0-.51,0-1,0-1.53a26,26,0,0,1,8.64,4.56c.49.39,1,.8,1.41,1.21a9.6,9.6,0,0,0-1.68,2.07,18.87,18.87,0,0,0-1.76,3.13,27,27,0,0,0-1.84,6.89c0,.12.17.16.19,0a20,20,0,0,1,2.25-6.53,26.65,26.65,0,0,1,1.76-2.9c.33-.48.68-1,1-1.41.19-.24.4-.49.59-.76a27.4,27.4,0,0,1,5.62,7.91l0,0a13.4,13.4,0,0,0-2.32,3.34,31.75,31.75,0,0,0-1.86,4,68.41,68.41,0,0,0-2.36,8.07c0,.12.16.16.19,0a33,33,0,0,1,2.81-7.95c.59-1.25,1.21-2.47,1.87-3.67a33.05,33.05,0,0,0,1.86-3.47,25.44,25.44,0,0,1,2.26,9.18c0,.12.2.1.19,0A32,32,0,0,0,141,350.13c1.21.52,2.43,1,3.59,1.64a19.75,19.75,0,0,1,4.12,2.9C148.79,354.75,148.92,354.6,148.82,354.52Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "121.48px 366.142px",
              }}
              id="el5h0lbel97ui"
              className="animable"
            />
            <path
              d="M134.3,355.25c-.59,2-1.34,3.86-1.88,5.83a22.52,22.52,0,0,0-.87,5.82,0,0,0,0,0,.09,0,56.48,56.48,0,0,1,2.84-11.6A.1.1,0,1,0,134.3,355.25Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "133.025px 361.02px",
              }}
              id="elmby643sd33"
              className="animable"
            />
            <path
              d="M146.85,350.4a10.84,10.84,0,0,0-2.63-1.87,18.78,18.78,0,0,0-3-1.07c-.12,0-.16.15,0,.18a11.82,11.82,0,0,1,2.92,1.2,23.26,23.26,0,0,1,2.7,1.66A.06.06,0,0,0,146.85,350.4Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "143.993px 348.98px",
              }}
              id="elir9j3nk87dk"
              className="animable"
            />
            <path
              d="M113.22,326.77s1.89-23.16,19.11-30.67c14.39-6.27,22.62,9.89,12.45,19.78C136.56,323.88,123.37,320.27,113.22,326.77Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "131.259px 310.732px",
              }}
              id="el2gq8pee8q2"
              className="animable"
            />
            <path
              d="M141.6,300.58a73.46,73.46,0,0,0-7.66,3.63,56.3,56.3,0,0,0-6,4c.29-.59.53-1.2.82-1.77a29.24,29.24,0,0,1,1.47-2.62,24.15,24.15,0,0,1,3.79-4.8c.09-.09,0-.22-.14-.13a37.55,37.55,0,0,0-4,4.64,27.48,27.48,0,0,0-1.64,2.63,10.33,10.33,0,0,0-1.16,2.66l-.19.14a56.36,56.36,0,0,0-8.22,8c0-.23.09-.45.15-.66.33-1.13.67-2.25,1.05-3.37a28.39,28.39,0,0,1,3.11-6.76c.07-.1-.1-.19-.17-.09a54,54,0,0,0-3.6,6.59,24.41,24.41,0,0,0-1.19,3.43,13.57,13.57,0,0,0-.56,2.32c-.75.95-1.49,1.91-2.19,2.9-7.39,10.53-11.1,23.26-12,36a108.61,108.61,0,0,0-.19,11.41,69.83,69.83,0,0,0,1,11.46c0,.24.42.16.41-.07-.08-3.35-.43-6.68-.5-10s.06-6.72.33-10.06a93.74,93.74,0,0,1,3.75-19.71,70.55,70.55,0,0,1,8.76-18.53c.73-.15,1.45-.35,2.16-.53.86-.22,1.72-.44,2.58-.68a25.82,25.82,0,0,0,4.8-1.76c.11-.06,0-.22-.1-.16a15.47,15.47,0,0,1-4.87,1.68c-1.35.27-2.75.44-4.1.75a64,64,0,0,1,4-5.26,30,30,0,0,0,9.8-.73,39.84,39.84,0,0,0,5-1.57c1.73-.66,3.36-1.51,5.06-2.23a.09.09,0,1,0-.08-.17,28.45,28.45,0,0,1-9.93,3.25,60.23,60.23,0,0,1-9.29.77c1.22-1.4,2.49-2.77,3.84-4,4.52-4.31,9.89-8.27,15.85-10.33C141.77,300.72,141.71,300.54,141.6,300.58Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "122.354px 339.598px",
              }}
              id="elojv7f2wxo3n"
              className="animable"
            />
            <path
              d="M127.58,300.66a25.81,25.81,0,0,0-2.11,3.08,17,17,0,0,0-1.52,3.6s0,.06.07,0c.57-1.13,1.06-2.31,1.64-3.44a17.1,17.1,0,0,1,2.06-3.13C127.8,300.69,127.66,300.56,127.58,300.66Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "125.847px 303.995px",
              }}
              id="elpo6gpga8c5"
              className="animable"
            />
            <path
              d="M143.6,308.37a18,18,0,0,1-3.54,1.47,34.6,34.6,0,0,1-3.59,1c-.06,0,0,.11,0,.1a17.63,17.63,0,0,0,3.73-.82,31,31,0,0,0,3.48-1.56C143.8,308.48,143.7,308.31,143.6,308.37Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "140.089px 309.649px",
              }}
              id="el4s8y851nq2k"
              className="animable"
            />
            <path
              d="M99.83,322.6c-.11,1-2.61-8.41-17.17-16.76-13.23-7.59-29-27.33-16-39.8C77.08,256,104.66,277.92,99.83,322.6Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "81.0979px 293.155px",
              }}
              id="el88s4y7b9shk"
              className="animable"
            />
            <path
              d="M109.85,376.56a258.6,258.6,0,0,0-2.31-29.28,131.75,131.75,0,0,0-6.15-26.21q-1.64-4.59-3.67-9a21,21,0,0,0-.06-3.46c-.07-1.49-.2-3-.34-4.45s-.29-2.95-.46-4.43-.49-2.94-.55-4.41a.1.1,0,1,0-.19,0,30.62,30.62,0,0,1,.53,4.68c.11,1.43.19,2.86.23,4.29s0,2.87,0,4.3c0,.52,0,1.07,0,1.62-1.37-2.89-2.83-5.74-4.42-8.52,1-6-.3-12.35-1.53-18.25,0-.12-.21-.06-.18.06a51.49,51.49,0,0,1,1,9.35c0,2.48-.06,5-.07,7.44-.35-.59-.7-1.19-1.06-1.77q-2.82-4.63-6-9a13.78,13.78,0,0,0-.14-4.3,33,33,0,0,0-1-4.71c-.47-1.54-1-3-1.62-4.54s-1.47-3-2.07-4.52c-.06-.13-.25,0-.18.08a21.82,21.82,0,0,1,1.83,4.57,43.7,43.7,0,0,1,1.19,4.53c.31,1.53.56,3.07.77,4.61.15,1,.24,2.09.41,3.13a111.07,111.07,0,0,0-10.19-11.87c-.09-.09-.21.05-.13.14a134.45,134.45,0,0,1,14.8,20.14l.47.81c-.8-.5-1.72-.9-2.48-1.32Q84,295,81.89,293.89t-4.13-2.26c-.71-.39-1.4-.8-2.12-1.17a16.93,16.93,0,0,1-2.47-1.37c-.11-.08-.23.09-.11.15,1.4.77,2.64,1.92,4,2.78s2.75,1.71,4.16,2.5c2.59,1.44,5.4,2.48,8,4q2.66,4.65,4.9,9.51c-1.55-1-3.12-1.94-4.73-2.83-1-.58-2.06-1.15-3.11-1.7a15.46,15.46,0,0,1-2.94-1.78c-.09-.07-.21.06-.13.14a27.09,27.09,0,0,0,5.6,3.89c1.87,1.1,3.76,2.15,5.71,3.11q2.33,5.12,4.21,10.42c6.13,17.36,8.38,35.78,9.46,54.08.32,5.46.48,10.91.55,16.37s-.22,10.91-.21,16.36c0,.16.27.2.29,0,.66-4.83.77-9.75,1-14.63S110,381.52,109.85,376.56Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "91.4938px 338.81px",
              }}
              id="eloxb7rara7qi"
              className="animable"
            />
            <path
              d="M83.39,298a71.71,71.71,0,0,1-7.74-4,79.13,79.13,0,0,1-7.34-4.67s-.09,0,0,.07a61.4,61.4,0,0,0,7,4.93,69.78,69.78,0,0,0,8,3.84C83.41,298.2,83.5,298,83.39,298Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "75.8552px 293.752px",
              }}
              id="el61ogiigncon"
              className="animable"
            />
            <path
              d="M87.61,278.37c-.21-.85-.48-1.68-.77-2.5a.08.08,0,0,0,0-.1c-.18-.47-.38-.94-.57-1.4a1,1,0,0,0-.07-.17c0-.09-.07-.18-.11-.27s0,0,0,0,.09.32.14.48a1.62,1.62,0,0,0,.08.27,69.16,69.16,0,0,1,2.16,9.89.09.09,0,0,0,.17,0A33.55,33.55,0,0,0,87.61,278.37Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "87.3561px 279.26px",
              }}
              id="el6udh2dsjsl"
              className="animable"
            />
            <polygon
              points="125.24 380.08 124.2 392.4 121.02 429.97 86.94 429.97 83.76 392.4 82.72 380.08 125.24 380.08"
              style={{
                fill: "rgb(55, 71, 79)",
                transformOrigin: "103.98px 405.025px",
              }}
              id="elrk5ofo14cx"
              className="animable"
            />
            <polygon
              points="125.24 380.08 124.2 392.4 83.76 392.4 82.72 380.08 125.24 380.08"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "103.98px 386.24px",
              }}
              id="elpo6lrcoq5m"
              className="animable"
            />
            <rect
              x="79.38"
              y="375.14"
              width="49.2"
              height="11.82"
              style={{
                fill: "rgb(55, 71, 79)",
                transformOrigin: "103.98px 381.05px",
              }}
              id="elon7f83jnr1o"
              className="animable"
            />
          </g>
          <g
            id="freepik--Device--inject-2"
            className="animable"
            style={{ transformOrigin: "374.995px 273.965px" }}
          >
            <rect
              x="100.32"
              y="409.73"
              width="549.35"
              height="20.01"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "374.995px 419.735px",
              }}
              id="el26lqbbp0vme"
              className="animable"
            />
            <rect
              x="321.45"
              y="409.73"
              width="107.1"
              height="8.14"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "375px 413.8px",
              }}
              id="elkyyisbzmibp"
              className="animable"
            />
            <rect
              x="157.09"
              y="118.19"
              width="435.82"
              height="291.53"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "375px 263.955px",
              }}
              id="el0ebv2c7fsg8c"
              className="animable"
            />
            <rect
              x="160.26"
              y="121.75"
              width="429.49"
              height="284.42"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "375.005px 263.96px",
              }}
              id="elwrgurero4fn"
              className="animable"
            />
            <rect
              x="179.27"
              y="140.03"
              width="391.45"
              height="247.86"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "374.995px 263.96px",
              }}
              id="elhux4w35qqe"
              className="animable"
            />
            <rect
              x="179.27"
              y="140.03"
              width="391.45"
              height="9.54"
              style={{
                fill: "rgb(69, 90, 100)",
                transformOrigin: "374.995px 144.8px",
              }}
              id="elazpoztdgw3f"
              className="animable"
            />
            <path
              d="M188.25,144.8a1.81,1.81,0,1,1-1.8-1.81A1.8,1.8,0,0,1,188.25,144.8Z"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "186.44px 144.8px",
              }}
              id="elldquhoi8rpr"
              className="animable"
            />
            <path
              d="M195.62,144.8a1.81,1.81,0,1,1-1.8-1.81A1.8,1.8,0,0,1,195.62,144.8Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "193.81px 144.8px",
              }}
              id="el3b1lk87hd6z"
              className="animable"
            />
            <path
              d="M203,144.8a1.81,1.81,0,1,1-1.8-1.81A1.8,1.8,0,0,1,203,144.8Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "201.19px 144.8px",
              }}
              id="elt9h9z55boy"
              className="animable"
            />
            <rect
              x="179.27"
              y="149.57"
              width="385.29"
              height="53.09"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "371.915px 176.115px",
              }}
              id="elmpq7ry4v9kn"
              className="animable"
            />
            <rect
              x="564.57"
              y="149.57"
              width="6.16"
              height="211.18"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "567.65px 255.16px",
              }}
              id="elmriggudk8nj"
              className="animable"
            />
            <path
              d="M567.64,222.78h0a1.59,1.59,0,0,1-1.59-1.59v-68.8a1.6,1.6,0,0,1,1.59-1.59h0a1.59,1.59,0,0,1,1.59,1.59v68.8A1.59,1.59,0,0,1,567.64,222.78Z"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "567.64px 186.79px",
              }}
              id="elbvsgkvmgxdj"
              className="animable"
            />
            <rect
              x="464.67"
              y="159.87"
              width="82.91"
              height="11.07"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "506.125px 165.405px",
              }}
              id="elazsq3fma965"
              className="animable"
            />
            <path
              d="M547.69,171.05H464.55V159.76h83.14Zm-82.91-.23h82.68V160H464.78Z"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "506.12px 165.405px",
              }}
              id="el2xa6pfeep94"
              className="animable"
            />
            <rect
              x="536.39"
              y="159.76"
              width="11.3"
              height="11.3"
              style={{
                fill: "rgb(69, 90, 100)",
                transformOrigin: "542.04px 165.41px",
              }}
              id="elr4fnehvxzo"
              className="animable"
            />
            <path
              d="M545.55,168.51l-1,.77-1.9-2.5-.69-.91a1.8,1.8,0,0,0,.4-.23,1.64,1.64,0,0,0,.52-.68l.74,1Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "543.755px 167.12px",
              }}
              id="el7b9qls9bh5q"
              className="animable"
            />
            <path
              d="M543.54,162.62a2.79,2.79,0,1,0-.91,4.16,2,2,0,0,0,.39-.25,3,3,0,0,0,.58-.6A2.79,2.79,0,0,0,543.54,162.62Zm-1.2,3a1.8,1.8,0,0,1-.4.23,1.67,1.67,0,1,1,.71-2.57,1.69,1.69,0,0,1,.21,1.66A1.64,1.64,0,0,1,542.34,165.64Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "541.324px 164.315px",
              }}
              id="elwfc5y18szf8"
              className="animable"
            />
            <rect
              x="466.98"
              y="162.05"
              width="0.59"
              height="6.72"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "467.275px 165.41px",
              }}
              id="el0fyfacmbek0r"
              className="animable"
            />
            <rect
              x="224.5"
              y="159.87"
              width="225.53"
              height="11.07"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "337.265px 165.405px",
              }}
              id="elmznw9wdncw"
              className="animable"
            />
            <path
              d="M450.15,171.05H224.39V159.76H450.15Zm-225.53-.23h225.3V160H224.62Z"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "337.27px 165.405px",
              }}
              id="eloxko0tnz1i"
              className="animable"
            />
            <rect
              x="226.82"
              y="162.05"
              width="0.59"
              height="6.72"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "227.115px 165.41px",
              }}
              id="eluibhehkt68"
              className="animable"
            />
            <polygon
              points="203.62 169.19 199.59 165.48 203.61 161.62 204.24 162.27 200.91 165.47 204.23 168.53 203.62 169.19"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "201.915px 165.405px",
              }}
              id="elfs9h2dyqzi"
              className="animable"
            />
            <polygon
              points="213.34 161.62 217.38 165.33 213.35 169.19 212.73 168.53 216.06 165.34 212.73 162.28 213.34 161.62"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "215.055px 165.405px",
              }}
              id="ele58tqk5op3"
              className="animable"
            />
            <g id="elxyft1xaf7n">
              <rect
                x="332.13"
                y="293.99"
                width="44.66"
                height="47.49"
                style={{
                  fill: "rgb(235, 235, 235)",
                  transformOrigin: "354.46px 317.735px",
                  transform: "rotate(180deg)",
                }}
                className="animable"
              />
            </g>
            <path
              d="M338.29,304.38A5.62,5.62,0,1,0,342.7,300,5.63,5.63,0,0,0,338.29,304.38Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "343.795px 305.512px",
              }}
              id="el5ucktjuh8ao"
              className="animable"
            />
            <rect
              x="332.13"
              y="331.25"
              width="44.66"
              height="10.23"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "354.46px 336.365px",
              }}
              id="elvsiy8dnit5"
              className="animable"
            />
            <polygon
              points="346.88 331.29 376.79 331.25 361.85 312.83 346.88 331.29"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "361.835px 322.06px",
              }}
              id="elwj47hmslki"
              className="animable"
            />
            <polygon
              points="332.13 331.29 358.69 331.29 345.41 319.27 332.13 331.29"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "345.41px 325.28px",
              }}
              id="el61m5j5b5m4c"
              className="animable"
            />
            <g id="el5m80z7oo3vm">
              <rect
                x="197.04"
                y="217.2"
                width="87.82"
                height="67.38"
                style={{
                  fill: "rgb(235, 235, 235)",
                  transformOrigin: "240.95px 250.89px",
                  transform: "rotate(180deg)",
                }}
                className="animable"
              />
            </g>
            <path
              d="M205.78,231.93a8,8,0,1,0,6.26-6.26A8,8,0,0,0,205.78,231.93Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "213.622px 233.512px",
              }}
              id="el8ox01cct5ig"
              className="animable"
            />
            <polygon
              points="226.01 270.06 284.85 270.06 255.43 233.79 226.01 270.06"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "255.43px 251.925px",
              }}
              id="elgit1xlig4bv"
              className="animable"
            />
            <polygon
              points="197.04 270.06 249.22 270.06 223.13 246.44 197.04 270.06"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "223.13px 258.25px",
              }}
              id="eln59ref5ymc"
              className="animable"
            />
            <rect
              x="197.04"
              y="270.06"
              width="87.82"
              height="14.52"
              style={{
                fill: "rgb(219, 219, 219)",
                transformOrigin: "240.95px 277.32px",
              }}
              id="elch9f07ebi2s"
              className="animable"
            />
            <rect
              x="196.44"
              y="293.99"
              width="46.5"
              height="3.91"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "219.69px 295.945px",
              }}
              id="elxgjf4fxrq9a"
              className="animable"
            />
            <rect
              x="196.44"
              y="303.07"
              width="124.56"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "258.72px 305.025px",
              }}
              id="elnz3q8wlfsh8"
              className="animable"
            />
            <rect
              x="196.44"
              y="311.67"
              width="124.56"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "258.72px 313.625px",
              }}
              id="eloiftvbdsho"
              className="animable"
            />
            <rect
              x="196.44"
              y="320.28"
              width="124.56"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "258.72px 322.235px",
              }}
              id="el54uh7yjf0nc"
              className="animable"
            />
            <rect
              x="196.44"
              y="328.89"
              width="124.56"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "258.72px 330.845px",
              }}
              id="elgtiwwtdy4"
              className="animable"
            />
            <rect
              x="196.44"
              y="337.49"
              width="124.56"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "258.72px 339.445px",
              }}
              id="ele3qwmr3ma1a"
              className="animable"
            />
            <rect
              x="391.78"
              y="217.2"
              width="48.49"
              height="3.91"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "416.025px 219.155px",
              }}
              id="eluz2u3rfa3c"
              className="animable"
            />
            <rect
              x="391.78"
              y="226.27"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 228.225px",
              }}
              id="elr3setzq8ycp"
              className="animable"
            />
            <rect
              x="391.78"
              y="234.88"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 236.835px",
              }}
              id="el4rwzqqrbjae"
              className="animable"
            />
            <rect
              x="391.78"
              y="243.49"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 245.445px",
              }}
              id="ela7loo131o1"
              className="animable"
            />
            <rect
              x="391.78"
              y="252.09"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 254.045px",
              }}
              id="el9pfnm38ophm"
              className="animable"
            />
            <rect
              x="391.78"
              y="269.31"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 271.265px",
              }}
              id="elc36qaoxacv"
              className="animable"
            />
            <rect
              x="391.78"
              y="277.91"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 279.865px",
              }}
              id="elbtixs4l945b"
              className="animable"
            />
            <rect
              x="391.78"
              y="286.52"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 288.475px",
              }}
              id="el1u1ny9j7w3l"
              className="animable"
            />
            <rect
              x="391.78"
              y="295.13"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 297.085px",
              }}
              id="elv09jdp2jisb"
              className="animable"
            />
            <rect
              x="391.78"
              y="312.34"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 314.295px",
              }}
              id="elf2ar8lzg6qm"
              className="animable"
            />
            <rect
              x="391.78"
              y="320.95"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 322.905px",
              }}
              id="el2bydumnoozw"
              className="animable"
            />
            <rect
              x="391.78"
              y="329.55"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 331.505px",
              }}
              id="el2jbq6bxjch4"
              className="animable"
            />
            <rect
              x="391.78"
              y="338.16"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "427.085px 340.115px",
              }}
              id="el56c2eg6bk6i"
              className="animable"
            />
            <rect
              x="477.08"
              y="226.27"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 228.225px",
              }}
              id="elsdek7qvi5rr"
              className="animable"
            />
            <rect
              x="477.08"
              y="234.88"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 236.835px",
              }}
              id="elj9de78whnl"
              className="animable"
            />
            <rect
              x="477.08"
              y="217.67"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 219.625px",
              }}
              id="el7926370v75o"
              className="animable"
            />
            <rect
              x="477.08"
              y="243.49"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 245.445px",
              }}
              id="elb9sijhz54e"
              className="animable"
            />
            <rect
              x="477.08"
              y="252.09"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 254.045px",
              }}
              id="el7zssr8rv9l"
              className="animable"
            />
            <rect
              x="477.08"
              y="260.7"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 262.655px",
              }}
              id="el7bmm847zovv"
              className="animable"
            />
            <rect
              x="477.08"
              y="269.31"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 271.265px",
              }}
              id="el4ms4tnnq6cv"
              className="animable"
            />
            <rect
              x="477.08"
              y="286.52"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 288.475px",
              }}
              id="el60mowimiph9"
              className="animable"
            />
            <rect
              x="477.08"
              y="295.13"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 297.085px",
              }}
              id="el8wz10m3i88m"
              className="animable"
            />
            <rect
              x="477.08"
              y="303.73"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 305.685px",
              }}
              id="elo0enoeduwl"
              className="animable"
            />
            <rect
              x="477.08"
              y="312.34"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 314.295px",
              }}
              id="el9zn7v8vgg9t"
              className="animable"
            />
            <rect
              x="477.08"
              y="320.95"
              width="70.61"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "512.385px 322.905px",
              }}
              id="elr1xdupl1qj8"
              className="animable"
            />
            <rect
              x="298.51"
              y="217.2"
              width="46.5"
              height="10.47"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "321.76px 222.435px",
              }}
              id="elpsubr3pxy6d"
              className="animable"
            />
            <rect
              x="298.51"
              y="234.88"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 236.835px",
              }}
              id="el2o9wembz2fs"
              className="animable"
            />
            <rect
              x="298.51"
              y="243.49"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 245.445px",
              }}
              id="elrngfd1p0zgm"
              className="animable"
            />
            <rect
              x="298.51"
              y="252.09"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 254.045px",
              }}
              id="elz1qw61lic1d"
              className="animable"
            />
            <rect
              x="298.51"
              y="260.7"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 262.655px",
              }}
              id="elcjeux69nxzu"
              className="animable"
            />
            <rect
              x="298.51"
              y="272.15"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 274.105px",
              }}
              id="elua8rj6sy7cd"
              className="animable"
            />
            <rect
              x="298.51"
              y="280.75"
              width="78.29"
              height="3.91"
              style={{
                fill: "rgb(235, 235, 235)",
                transformOrigin: "337.655px 282.705px",
              }}
              id="el20v70ef6lv5"
              className="animable"
            />
            <g id="elalmcqnpk86">
              <rect
                x="179.27"
                y="140.03"
                width="391.45"
                height="247.86"
                style={{
                  fill: "rgb(255, 255, 255)",
                  opacity: "0.7",
                  transformOrigin: "374.995px 263.96px",
                }}
                className="animable"
              />
            </g>
          </g>
          <g
            id="freepik--Lockpad--inject-2"
            className="animable animator-active"
            style={{ transformOrigin: "375px 258.97px" }}
          >
            <path
              d="M394.93,241v16.85h-6.08V241c0-8.18-6.21-14.83-13.85-14.83s-13.85,6.65-13.85,14.83v16.85h-6.08V241c0-11.72,8.94-21.24,19.93-21.24S394.93,229.24,394.93,241Z"
              style={{
                fill: "rgb(69, 90, 100)",
                transformOrigin: "375px 238.805px",
              }}
              id="elxorfv1go6ws"
              className="animable"
            />
            <polygon
              points="355.07 241.89 361.14 242.13 361.14 257.81 355.07 257.81 355.07 241.89"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "358.105px 249.85px",
              }}
              id="elnumi34ydsim"
              className="animable"
            />
            <polygon
              points="394.93 243.51 394.93 257.81 388.86 257.81 388.86 243.26 394.93 243.51"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "391.895px 250.535px",
              }}
              id="eliewx5kj8m4n"
              className="animable"
            />
            <rect
              x="347.28"
              y="245.17"
              width="55.45"
              height="50.3"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "375.005px 270.32px",
              }}
              id="el69489kzdw4y"
              className="animable"
            />
            <g id="elhtabckrx28d">
              <rect
                x={375}
                y="245.17"
                width="27.72"
                height="50.3"
                style={{ opacity: "0.1", transformOrigin: "388.86px 270.32px" }}
                className="animable"
              />
            </g>
            <path
              d="M377.34,272.16l2.33,11h-9.85l2.32-11a4.93,4.93,0,1,1,5.2,0Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "374.74px 273.101px",
              }}
              id="el8kjkw5wgp45"
              className="animable"
            />
            <polygon
              points="375.17 175.07 377.29 180.12 382.75 180.58 378.6 184.16 379.85 189.49 375.17 186.65 370.48 189.49 371.73 184.16 367.59 180.58 373.04 180.12 375.17 175.07"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "375.17px 182.28px",
              }}
              id="elznndwafwhln"
              className="animable"
            />
            <polygon
              points="333.2 186.23 337.56 189.54 342.51 187.21 340.71 192.38 344.46 196.37 338.99 196.26 336.35 201.06 334.76 195.81 329.39 194.79 333.88 191.66 333.2 186.23"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "336.925px 193.645px",
              }}
              id="elyb5cah1dxqe"
              className="animable"
            />
            <polygon
              points="302.43 216.88 307.86 217.56 310.98 213.07 312.01 218.44 317.25 220.03 312.46 222.67 312.57 228.14 308.58 224.39 303.41 226.19 305.73 221.24 302.43 216.88"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "309.84px 220.605px",
              }}
              id="ele5btzexst0i"
              className="animable"
            />
            <polygon
              points="291.1 258.8 296.15 256.68 296.61 251.22 300.19 255.37 305.52 254.12 302.68 258.8 305.52 263.49 300.19 262.24 296.61 266.38 296.15 260.93 291.1 258.8"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "298.31px 258.8px",
              }}
              id="el40mviz29el2"
              className="animable"
            />
            <polygon
              points="302.26 300.77 305.57 296.41 303.24 291.46 308.41 293.25 312.4 289.51 312.29 294.98 317.08 297.62 311.85 299.21 310.82 304.58 307.69 300.09 302.26 300.77"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "309.67px 297.045px",
              }}
              id="elfh2d3g9ziqa"
              className="animable"
            />
            <polygon
              points="332.91 331.54 333.59 326.11 329.1 322.99 334.47 321.96 336.06 316.72 338.7 321.51 344.17 321.4 340.43 325.39 342.22 330.56 337.27 328.24 332.91 331.54"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "336.635px 324.13px",
              }}
              id="elhkdzzpq8mas"
              className="animable"
            />
            <polygon
              points="374.83 342.87 372.71 337.82 367.25 337.36 371.4 333.78 370.15 328.45 374.83 331.29 379.52 328.45 378.27 333.78 382.41 337.36 376.96 337.82 374.83 342.87"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "374.83px 335.66px",
              }}
              id="elz9ozj1ybh9j"
              className="animable"
            />
            <polygon
              points="416.8 331.71 412.44 328.4 407.49 330.73 409.29 325.56 405.54 321.57 411.01 321.68 413.65 316.88 415.24 322.13 420.61 323.15 416.12 326.28 416.8 331.71"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "413.075px 324.295px",
              }}
              id="elin7nbyskh38"
              className="animable"
            />
            <polygon
              points="447.57 301.06 442.14 300.38 439.02 304.87 437.99 299.5 432.75 297.91 437.54 295.27 437.43 289.8 441.42 293.55 446.59 291.75 444.27 296.7 447.57 301.06"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "440.16px 297.335px",
              }}
              id="els4d3jnmecu"
              className="animable"
            />
            <polygon
              points="458.9 259.14 453.85 261.26 453.39 266.71 449.81 262.57 444.48 263.82 447.32 259.14 444.48 254.45 449.81 255.7 453.39 251.56 453.85 257.01 458.9 259.14"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "451.69px 259.135px",
              }}
              id="elhw9ozrveugg"
              className="animable"
            />
            <polygon
              points="447.74 217.17 444.43 221.53 446.76 226.48 441.59 224.68 437.6 228.43 437.71 222.96 432.92 220.32 438.15 218.73 439.18 213.36 442.31 217.85 447.74 217.17"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "440.33px 220.895px",
              }}
              id="el74jep54edq"
              className="animable"
            />
            <polygon
              points="417.09 186.4 416.41 191.83 420.9 194.95 415.53 195.98 413.94 201.22 411.3 196.43 405.83 196.54 409.57 192.55 407.78 187.38 412.73 189.7 417.09 186.4"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "413.365px 193.81px",
              }}
              id="elcvzzgikkbdq"
              className="animable"
            />
          </g>
          <g
            id="freepik--Key--inject-2"
            className="animable"
            style={{ transformOrigin: "612.22px 193.38px" }}
          >
            <circle
              cx="612.22"
              cy="193.36"
              r="50.04"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "612.22px 193.36px",
              }}
              id="elz25cfin8af"
              className="animable"
            />
            <path
              d="M612.22,248.38a55,55,0,1,1,55-55A55.08,55.08,0,0,1,612.22,248.38Zm0-109a54,54,0,1,0,54,54A54.07,54.07,0,0,0,612.22,139.35Z"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "612.22px 193.38px",
              }}
              id="el2sn91zncom2"
              className="animable"
            />
            <polygon
              points="607.03 187 630.45 163.58 641.88 163.58 641.88 174.75 634.57 174.75 634.57 182.32 627.25 182.32 627.25 189.9 619.67 189.9 619.67 197.35 618.45 198.57 607.03 187"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "624.455px 181.075px",
              }}
              id="eld1gfrdb44el"
              className="animable"
            />
            <g id="elb114r4012x4">
              <polygon
                points="641.87 163.59 641.87 174.75 634.56 174.75 634.56 182.33 627.25 182.33 627.25 189.91 619.67 189.91 619.67 197.35 618.49 198.53 612.71 192.75 641.87 163.59"
                style={{
                  fill: "rgb(255, 255, 255)",
                  opacity: "0.3",
                  transformOrigin: "627.29px 181.06px",
                }}
                className="animable"
              />
            </g>
            <g id="elvokt7el236r">
              <polygon
                points="618.13 184.49 616.98 183.33 629.39 170.91 630.53 172.05 618.13 184.49"
                style={{ opacity: "0.1", transformOrigin: "623.755px 177.7px" }}
                className="animable"
              />
            </g>
            <path
              d="M588,217.71a18.55,18.55,0,1,0,0-26.24A18.56,18.56,0,0,0,588,217.71Zm10.81-10.81a4.85,4.85,0,1,1-6.86,0A4.85,4.85,0,0,1,598.81,206.9Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "601.116px 204.59px",
              }}
              id="el61gkdefvm7k"
              className="animable"
            />
          </g>
          <g
            id="freepik--Shield--inject-2"
            className="animable"
            style={{ transformOrigin: "234.05px 341.08px" }}
          >
            <circle
              cx="234.05"
              cy="341.07"
              r="50.04"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "234.05px 341.07px",
              }}
              id="eld7d3rqnczb"
              className="animable"
            />
            <path
              d="M234.05,396.08a55,55,0,1,1,55-55A55.08,55.08,0,0,1,234.05,396.08Zm0-109a54,54,0,1,0,54,54A54.07,54.07,0,0,0,234.05,287.06Z"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "234.05px 341.08px",
              }}
              id="elpl5mfkfrfal"
              className="animable"
            />
            <path
              d="M265.58,325.17s-4.46,35.73-11.79,43.77c-8.81,9.64-15.2,8.23-18.75,13.21h0c-3.56-5-9.95-3.56-18.76-13.21-7.34-8-11.79-43.77-11.79-43.77,19.33-9.39,30.55-19.9,30.55-19.9h0C235.38,305.6,246.56,315.93,265.58,325.17Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "235.035px 343.71px",
              }}
              id="elwsx3l1iy089"
              className="animable"
            />
            <g id="elpcvnrhtiftj">
              <path
                d="M235,305.28v76.87h0c-3.56-5-9.95-3.56-18.76-13.21-7.34-8-11.79-43.77-11.79-43.77,19.33-9.39,30.55-19.9,30.55-19.9Z"
                style={{
                  opacity: "0.1",
                  transformOrigin: "219.725px 343.71px",
                }}
                className="animable"
              />
            </g>
            <path
              d="M209.22,328.05s3.77,30.18,10,37c7.44,8.16,12.84,7,15.85,11.17,3-4.2,8.4-3,15.85-11.17,6.19-6.79,9.95-37,9.95-37-16.32-7.93-25.8-16.82-25.8-16.82S225.55,320.12,209.22,328.05Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "235.045px 343.725px",
              }}
              id="el9tg9o4do2nm"
              className="animable"
            />
            <g id="elvri4pd2kwg">
              <path
                d="M260.84,328.05s-3.77,30.18-10,37c-7.44,8.15-12.83,7-15.84,11.15V311.24C235.32,311.52,244.76,320.24,260.84,328.05Z"
                style={{
                  fill: "rgb(255, 255, 255)",
                  opacity: "0.3",
                  transformOrigin: "247.92px 343.72px",
                }}
                className="animable"
              />
            </g>
            <path
              d="M240.65,337.56v4.69H239v-4.69a3.86,3.86,0,1,0-7.71,0v4.69h-1.69v-4.69a5.56,5.56,0,1,1,11.09,0Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "235.145px 336.921px",
              }}
              id="elx6ht37gdkxr"
              className="animable"
            />
            <path
              d="M227.39,338.73v14h15.43v-14Zm9,10.58h-2.74l.65-3.07a1.37,1.37,0,1,1,2.09-1.16,1.37,1.37,0,0,1-.64,1.16Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "235.105px 345.73px",
              }}
              id="elyildxn7enur"
              className="animable"
            />
          </g>
          <g
            id="freepik--Folder--inject-2"
            className="animable animator-hidden"
            style={{ transformOrigin: "157.09px 127.03px" }}
          >
            <g id="el0wuz93i19odq">
              <circle
                cx="157.09"
                cy="127.03"
                r="50.04"
                style={{
                  fill: "rgb(166, 166, 166)",
                  transformOrigin: "157.09px 127.03px",
                  transform: "rotate(-76.42deg)",
                }}
                className="animable"
              />
            </g>
            <path
              d="M157.09,182a55,55,0,1,1,55-55A55.06,55.06,0,0,1,157.09,182Zm0-109a54,54,0,1,0,54,54A54.07,54.07,0,0,0,157.09,73Z"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "157.09px 127px",
              }}
              id="elrxfqx24ny2"
              className="animable"
            />
            <polygon
              points="123.75 151.28 187.31 151.28 186.97 107.34 123.41 107.34 123.75 151.28"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "155.36px 129.31px",
              }}
              id="el2sfxs9cfzuf"
              className="animable"
            />
            <polygon
              points="164.27 102.79 186.73 102.79 186.97 107.34 163.22 107.34 164.27 102.79"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "175.095px 105.065px",
              }}
              id="ellgwhmu54nh"
              className="animable"
            />
            <g id="elkjc5kycdxd9">
              <g
                style={{
                  opacity: "0.1",
                  transformOrigin: "155.36px 127.035px",
                }}
                className="animable"
              >
                <polygon
                  points="123.75 151.28 187.31 151.28 186.97 107.34 123.41 107.34 123.75 151.28"
                  id="elund830oluj"
                  className="animable"
                  style={{ transformOrigin: "155.36px 129.31px" }}
                />
                <polygon
                  points="164.27 102.79 186.73 102.79 186.97 107.34 163.22 107.34 164.27 102.79"
                  id="eluyziixqb3x9"
                  className="animable"
                  style={{ transformOrigin: "175.095px 105.065px" }}
                />
              </g>
            </g>
            <polygon
              points="123.8 151.28 187.36 151.28 192.26 109.92 128.7 109.92 123.8 151.28"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "158.03px 130.6px",
              }}
              id="elfznlqr84ck9"
              className="animable"
            />
            <polygon
              points="170.31 105.64 192.77 105.64 192.26 109.92 168.51 109.92 170.31 105.64"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "180.64px 107.78px",
              }}
              id="elha8r49wa26p"
              className="animable"
            />
            <path
              d="M164,123.66v4.86h-1.75v-4.86a4,4,0,1,0-8,0v4.86h-1.75v-4.86a5.76,5.76,0,1,1,11.49,0Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "158.245px 123.002px",
              }}
              id="el9uo2aeyx8cc"
              className="animable"
            />
            <path
              d="M150.29,124.87v14.5h16v-14.5Zm9.34,11h-2.84l.67-3.18a1.42,1.42,0,1,1,1.5,0Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "158.29px 132.12px",
              }}
              id="elchfuu66qeod"
              className="animable"
            />
          </g>
          <g
            id="freepik--Character--inject-2"
            className="animable animator-hidden"
            style={{ transformOrigin: "549.756px 331.276px" }}
          >
            <path
              d="M550.61,239.9c11.27,5,18.18,25.7,10.24,44.53-7.23,17.12-27.25,26.46-39,22.25-12.49-4.46-16.34-18.22-10.72-26.49,5.44-8,14.43-10.39,16.83-17.68,1.15-3.49-.11-9.57,1.36-14.8C531,241.88,541.56,235.85,550.61,239.9Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "536.438px 273.132px",
              }}
              id="elcfkz3kv05v"
              className="animable"
            />
            <path
              d="M534.44,307.86a37.47,37.47,0,0,0,16.83-8.28,41.54,41.54,0,0,0,11.07-15.27,35.65,35.65,0,0,0,2.81-18.62,35.16,35.16,0,0,0-7.36-17.23,37,37,0,0,1,6.79,17.3,34.56,34.56,0,0,1-.26,9.31,38.23,38.23,0,0,1-2.68,8.94,44.92,44.92,0,0,1-4.54,8.16,40.32,40.32,0,0,1-13.92,12.28A35.9,35.9,0,0,1,534.44,307.86Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "549.918px 278.16px",
              }}
              id="elkgptlds9r2c"
              className="animable"
            />
            <path
              d="M509.18,282.45a18.57,18.57,0,0,1,3.07-4.92,25.07,25.07,0,0,1,4.24-3.93c1.58-1.14,3.14-2.33,4.65-3.58a25.5,25.5,0,0,0,4.15-4.22,17.3,17.3,0,0,0,1.6-2.54,11.33,11.33,0,0,0,.88-2.87,48.88,48.88,0,0,0,.48-5.87,22.88,22.88,0,0,1,.94-5.7,16,16,0,0,1,2.71-5.12,15,15,0,0,0-3,5,22.49,22.49,0,0,0-1.17,5.78c-.2,2-.23,3.9-.59,5.78a10.43,10.43,0,0,1-.86,2.69,15.46,15.46,0,0,1-1.55,2.39c-2.29,3.08-5.51,5.36-8.54,7.79A25,25,0,0,0,512,277.3,17.5,17.5,0,0,0,509.18,282.45Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "520.54px 263.075px",
              }}
              id="eldag72yfdq4c"
              className="animable"
            />
            <path
              d="M631.84,420.37c-.58-2.26-36.24-56.62-38.85-57.8-.3-.15-18.64,11.13-18.64,11.13-.78.91-33.57-8.36-33.57-8.36l-3,40.11,60.68,2.79s7.87,5.36,15.09,9.79c0,0,0,0,0,0a93.62,93.62,0,0,0,9.53,5.45C626,424.46,632.44,422.62,631.84,420.37Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "584.83px 393.159px",
              }}
              id="els8vrt8oxbkn"
              className="animable"
            />
            <path
              d="M623.06,423.47c-1.15-.37-5.08-2.69-9.52-5.44,0,0,0,0,0,0-7.22-4.42-15.08-9.79-15.08-9.79l-13.51-.62L566.1,372.13c4.58,1.13,8,1.86,8.26,1.56,0,0,18.33-11.27,18.63-11.12,2.61,1.18,38.27,55.54,38.85,57.8S626,424.46,623.06,423.47Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "598.988px 393.157px",
              }}
              id="el4f5cqm8hi2q"
              className="animable"
            />
            <path
              d="M632.2,420.47c-.62-2.32-36.46-56.67-39.14-57.88-1-.46-9.93,4.25-18.71,11.11l23.15,35s22.6,14,25.67,15S632.81,422.78,632.2,420.47Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "603.295px 393.265px",
              }}
              id="ellfmnkozvt2i"
              className="animable"
            />
            <path
              d="M629.51,421.12c-6.23-9-30.52-45.7-36-55,0-.08,0-.11,0,0,6.38,8.75,30.37,45.68,36.1,55C629.78,421.22,629.64,421.31,629.51,421.12Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "611.599px 393.64px",
              }}
              id="elunfje3es9m"
              className="animable"
            />
            <path
              d="M618.91,421.5a8.78,8.78,0,0,1-1.37-6.92,8.48,8.48,0,0,1,3.53-5.24s.12.06.07.11a10.12,10.12,0,0,0-2.11,12A.07.07,0,0,1,618.91,421.5Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "619.242px 415.431px",
              }}
              id="elm4we0uixdoe"
              className="animable"
            />
            <path
              d="M600.3,411c-2.45-3.61-3.95-8.69-2.47-12.92.06-.16.29-.08.31.06a60.66,60.66,0,0,0,2.65,12.63A.27.27,0,0,1,600.3,411Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "599.029px 404.574px",
              }}
              id="el4ndaizwr71x"
              className="animable"
            />
            <path
              d="M603.26,413.05c-2.46-3.61-4-8.69-2.47-12.92.06-.16.29-.08.3.07a60.72,60.72,0,0,0,2.66,12.62A.27.27,0,0,1,603.26,413.05Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "601.977px 406.622px",
              }}
              id="eldtswmjorm9g"
              className="animable"
            />
            <path
              d="M606.21,415.14c-2.45-3.61-4-8.69-2.47-12.92.06-.16.29-.08.31.07a60.06,60.06,0,0,0,2.65,12.62A.27.27,0,0,1,606.21,415.14Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "604.928px 408.714px",
              }}
              id="eld3ztjvqdjms"
              className="animable"
            />
            <path
              d="M609.16,417.23c-2.45-3.61-3.95-8.68-2.46-12.92,0-.16.29-.08.3.07A60.59,60.59,0,0,0,609.66,417,.28.28,0,0,1,609.16,417.23Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "607.887px 410.785px",
              }}
              id="elw6eifxcquo"
              className="animable"
            />
            <path
              d="M586.38,374.11c-2.68-4.09,3.68-8.28,6.37-4.17S589.08,378.22,586.38,374.11Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "589.567px 372.027px",
              }}
              id="eld66mi8jti2f"
              className="animable"
            />
            <path
              d="M598.32,417.5c1.25-3.64.87-7.69.95-11.47a.53.53,0,0,0-.73-.46.11.11,0,0,0-.18-.05,23,23,0,0,0-7.14,9.47c-.83,2.18-.8,5.49,1.95,6.33S597.6,419.63,598.32,417.5Zm-2.9,2.16c-4.21,2.26-3.52-3.33-2.84-4.92a25.31,25.31,0,0,1,1.58-2.95,67.82,67.82,0,0,1,4.05-5.62c-.16,1.85-.15,3.71-.24,5.56C597.87,414,597.73,418.42,595.42,419.66Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "595.007px 413.488px",
              }}
              id="el2rbephdmh84"
              className="animable"
            />
            <path
              d="M587.5,394.11c-2.19,1.85-.92,4.91.72,6.58a22.9,22.9,0,0,0,10.3,5.87.12.12,0,0,0,.15-.11.54.54,0,0,0,.48-.72c-1.57-3.43-2.83-7.31-5.41-10.16C592.23,393.91,589.57,392.35,587.5,394.11Zm8.2,6.9c.82,1.67,1.54,3.37,2.42,5a66.05,66.05,0,0,1-5.94-3.55,25.27,25.27,0,0,1-2.62-2.09c-1.26-1.19-4.1-6.05.65-5.64C592.84,395,594.73,399,595.7,401Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "592.757px 399.949px",
              }}
              id="elk2hx1nefzk"
              className="animable"
            />
            <path
              d="M550.92,356.84s-51-23.5-73.73-23.28c-27.08.26-48.2,32.36-15.05,55.61C491.44,409.72,584,408.44,584,408.44l-21.53-38.87a197.65,197.65,0,0,0-59-11.09l46.67,9.6Z"
              style={{
                fill: "rgb(69, 90, 100)",
                transformOrigin: "514.591px 371.007px",
              }}
              id="el1y7mjkvwq5q"
              className="animable"
            />
            <path
              d="M550,364.57c-4-1-8.07-1.72-12.13-2.46s-8.2-1.41-12.32-2a244.83,244.83,0,0,0-24.77-2.56c-.77,0-1.55-.06-2.33-.09l-.5-.16c-.39-.12-.78-.25-1.17-.35-.74-.19-1.49-.35-2.23-.52-1.55-.34-3.11-.62-4.65-1a.07.07,0,0,0-.05.14c1.55.45,3.06,1,4.6,1.46l1.34.38c-3.05-.05-6.09,0-9.14.05-.1,0-.11.15,0,.15,4.14,0,8.29.29,12.42.64s8.19.71,12.27,1.22c8.25,1,16.48,2.33,24.67,3.75,4.6.8,9.2,1.69,13.84,2.19A.41.41,0,0,0,550,364.57Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "518.461px 360.421px",
              }}
              id="el81qdutvr2gs"
              className="animable"
            />
            <path
              d="M574.54,406.66c-1.32-2.47-2.41-5.08-3.6-7.62s-2.39-5.09-3.59-7.63c-2.4-5.07-11.06-22.16-12-23.37a.09.09,0,0,0-.16.08c.37,1.3,5.74,12.6,7,15.12,2.48,5,5.11,10,7.7,15,.74,1.42,1.5,2.83,2.26,4.25s1.55,2.81,2.23,4.26C574.45,406.82,574.59,406.75,574.54,406.66Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "564.868px 387.389px",
              }}
              id="elg6dapzs8egs"
              className="animable"
            />
            <path
              d="M454,419.84c.67-2.24,38.46-55.15,41.11-56.22.3-.14,18.18,11.85,18.18,11.85.75.95,33.88-7,33.88-7l1.45,40.2-60.75.39s-8.07,5-15.46,9.18h0a89.26,89.26,0,0,1-9.74,5.06C459.68,424.15,453.35,422.06,454,419.84Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "501.287px 393.556px",
              }}
              id="elo2kev0i03kl"
              className="animable"
            />
            <path
              d="M462.69,423.28a86.67,86.67,0,0,0,9.73-5.06h0c7.39-4.13,15.46-9.19,15.46-9.19l13.52-.08,20.21-34.72c-4.62.95-8.07,1.54-8.31,1.24,0,0-17.87-12-18.18-11.85-2.65,1.07-40.44,54-41.11,56.21S459.68,424.15,462.69,423.28Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "487.785px 393.55px",
              }}
              id="elil8fidokod"
              className="animable"
            />
            <path
              d="M453.68,419.92c.71-2.3,38.68-55.18,41.4-56.28,1-.43,9.75,4.63,18.25,11.83l-24.52,34.07s-23.13,13.08-26.24,14S453,422.21,453.68,419.92Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "483.48px 393.683px",
              }}
              id="elt6antmo1p5"
              className="animable"
            />
            <path
              d="M456.34,420.68c6.58-8.71,32.31-44.46,38.15-53.57.05-.07,0-.11-.05,0-6.71,8.49-32.15,44.44-38.24,53.5C456.07,420.76,456.2,420.86,456.34,420.68Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "475.329px 393.909px",
              }}
              id="el06en80swgzce"
              className="animable"
            />
            <path
              d="M466.91,421.47a8.78,8.78,0,0,0,1.65-6.85,8.48,8.48,0,0,0-3.32-5.38c-.05,0-.12.06-.07.1a10.13,10.13,0,0,1,1.63,12.05C466.76,421.47,466.86,421.54,466.91,421.47Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "466.925px 415.369px",
              }}
              id="el5qcheir6jg3"
              className="animable"
            />
            <path
              d="M485.93,411.68c2.59-3.51,4.29-8.53,3-12.81,0-.17-.28-.1-.3.05a60.84,60.84,0,0,1-3.15,12.51A.27.27,0,0,0,485.93,411.68Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "487.423px 405.262px",
              }}
              id="el6n288yieywx"
              className="animable"
            />
            <path
              d="M482.89,413.65c2.6-3.51,4.3-8.52,3-12.81-.05-.17-.29-.09-.31.05a60.05,60.05,0,0,1-3.15,12.51A.27.27,0,0,0,482.89,413.65Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "484.379px 407.24px",
              }}
              id="el42vuiyf9omf"
              className="animable"
            />
            <path
              d="M479.86,415.63c2.59-3.52,4.3-8.53,3-12.82,0-.17-.29-.09-.31.05a60.68,60.68,0,0,1-3.15,12.51A.28.28,0,0,0,479.86,415.63Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "481.351px 409.207px",
              }}
              id="el93ggqr8vtq5"
              className="animable"
            />
            <path
              d="M476.82,417.6c2.6-3.52,4.3-8.53,3-12.82-.05-.16-.28-.09-.31.06a60.21,60.21,0,0,1-3.15,12.51A.27.27,0,0,0,476.82,417.6Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "478.309px 411.188px",
              }}
              id="el6wnzkfk8yub"
              className="animable"
            />
            <path
              d="M501.29,375.41c2.85-4-3.35-8.42-6.2-4.42S498.44,379.41,501.29,375.41Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "498.19px 373.2px",
              }}
              id="el1khpgz50ufc"
              className="animable"
            />
            <path
              d="M492.64,422.31c2.78-.73,2.95-4,2.2-6.25a23,23,0,0,0-6.76-9.74c-.07-.06-.14,0-.18,0a.54.54,0,0,0-.75.43c-.06,3.78-.6,7.82.49,11.5C488.28,420.45,490,423,492.64,422.31Zm-4.41-9.77c0-1.86.06-3.71,0-5.56a67.76,67.76,0,0,1,3.82,5.77,24.61,24.61,0,0,1,1.47,3c.61,1.62,1.08,7.23-3,4.81C488.2,419.24,488.24,414.76,488.23,412.54Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "491.093px 414.35px",
              }}
              id="elpot0bovsoe9"
              className="animable"
            />
            <path
              d="M493.09,396.56c-2.69,2.75-4.1,6.57-5.81,9.94a.54.54,0,0,0,.46.73.11.11,0,0,0,.14.12,22.88,22.88,0,0,0,10.53-5.45c1.7-1.61,3.1-4.61,1-6.55S494.66,395,493.09,396.56Zm3.56-.69c4.77-.22,1.73,4.53.43,5.66a22.82,22.82,0,0,1-2.7,2,67.53,67.53,0,0,1-6.08,3.32c.94-1.6,1.74-3.27,2.62-4.91C492,400,494,396,496.65,395.87Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "493.856px 400.905px",
              }}
              id="elldlbrimwkn"
              className="animable"
            />
            <path
              d="M548.71,356.07s51.38-15.7,78.85-12.53c26.9,3.1,38.07,36.77,11.92,55.15-28.55,20.07-136.38,13.52-136.38,13.52L526,370.91s36.67-8.79,70.15-8.59l-47,7.74Z"
              style={{
                fill: "rgb(69, 90, 100)",
                transformOrigin: "578.715px 378.174px",
              }}
              id="elbn00yndkmy8"
              className="animable"
            />
            <path
              d="M524.43,373.57a.72.72,0,0,0,.05-.21l0-.17c0-.13.07-.26.1-.39.07-.28.16-.54.25-.81s.21-.52.32-.78a6.65,6.65,0,0,1,.43-.73s0,0,.06-.05l0,0,.06,0,.06,0c4-.87,31.49-5.57,35.58-6.15s8.25-1.09,12.39-1.55a248.52,248.52,0,0,1,24.84-1.59H601c.16-.06.34-.09.51-.14.39-.11.78-.22,1.18-.3.75-.17,1.5-.3,2.25-.43,1.56-.29,3.13-.5,4.69-.83.09,0,.13.12,0,.14-1.56.39-3.09.89-4.66,1.28l-1.35.32c3.05.08,6.09.23,9.14.42.09,0,.1.15,0,.15-4.14-.13-8.3,0-12.44.14s-8.21.39-12.3.74c-8.29.7-16.56,1.68-24.81,2.77-4.62.61-32.61,5.49-37.26,5.82h-.06a3,3,0,0,1-.12.32c-.12.26-.24.51-.38.76s-.25.49-.39.72l-.22.34-.11.17a1.88,1.88,0,0,0-.12.18C524.54,373.74,524.38,373.67,524.43,373.57Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "568.626px 366.579px",
              }}
              id="elotm2ijk02rh"
              className="animable"
            />
            <path
              d="M511.68,409.5c1.42-2.41,2.62-5,3.91-7.47l3.89-7.48c2.59-5,11.93-21.7,12.87-22.88,0-.08.18,0,.15.09-.42,1.28-6.23,12.35-7.57,14.82-2.68,4.94-5.51,9.79-8.29,14.67-.79,1.4-1.62,2.77-2.42,4.16s-1.67,2.75-2.4,4.17A.08.08,0,0,1,511.68,409.5Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "522.086px 390.629px",
              }}
              id="elsr6xt1k93q"
              className="animable"
            />
            <path
              d="M535.92,282.34c-22.59,12.18-43.74,26.89-47.6,36.94-2.43,6.33.35,15.64,3,22.46,1.29,3.32,30.82-6.84,29.09-10.23-4.19-8.18-5.65-11.49-5.65-11.49.43-.89,25-22.89,31.85-32.75C556.14,273.54,545.8,277,535.92,282.34Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "518.887px 310.02px",
              }}
              id="eluju46wm5zp"
              className="animable"
            />
            <path
              d="M511.55,315.09a8.4,8.4,0,0,1,2.3,2.49,7.65,7.65,0,0,1,1.2,3.17c-.68-.93-1.24-1.85-1.83-2.77S512.06,316.12,511.55,315.09Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "513.3px 317.92px",
              }}
              id="ely8k31m2kurk"
              className="animable"
            />
            <path
              d="M503.31,300.57l16.91,16.09s21.62-19.74,27.81-30.41,1.47-11.58-11.1-5.37S503.31,300.57,503.31,300.57Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "527.024px 296.837px",
              }}
              id="eltu3luaa7pr"
              className="animable"
            />
            <path
              d="M503.31,300.57l16.91,16.09s21.62-19.74,27.81-30.41,1.47-11.58-11.1-5.37S503.31,300.57,503.31,300.57Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "527.024px 296.837px",
              }}
              id="eld4642ux7hz9"
              className="animable"
            />
            <path
              d="M523.62,312.33a167.28,167.28,0,0,1-14.93-14.42c-.06-.06.05-.15.11-.09,1.26,1.34,9.09,8.3,14.94,14.42C523.81,312.31,523.7,312.4,523.62,312.33Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "516.217px 305.078px",
              }}
              id="elz1vd00o0ezb"
              className="animable"
            />
            <g id="eldfv9hb2d765">
              <path
                d="M548,286.24c-6.18,10.67-27.8,30.42-27.8,30.42l-5.86-5.57,36-33.21C551.25,279.17,550.54,281.89,548,286.24Z"
                style={{
                  opacity: "0.1",
                  transformOrigin: "532.529px 297.27px",
                }}
                className="animable"
              />
            </g>
            <path
              d="M529.15,313.82l-4.65,27.06s21,12.08,56.45,1.19c0,0-6.06-43.9-19.55-59.07-6.75-7.59-14.84-7.17-20.08-2-1.32,1.29-12.25,14.66-14,22.48C525.75,310.35,529.15,313.82,529.15,313.82Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "552.725px 311.908px",
              }}
              id="elxvu9jjtshz"
              className="animable"
            />
            <path
              d="M529.15,313.82,526.08,325l15.85-4.82L579,325s-4.13-26.8-17.62-42c-6.75-7.59-14.84-7.17-20.08-2-1.32,1.29-12.25,14.66-14,22.48C525.75,310.35,529.15,313.82,529.15,313.82Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "552.54px 301.098px",
              }}
              id="el521alhnixj3"
              className="animable"
            />
            <path
              d="M538.08,312.85a50.38,50.38,0,0,1,7.26.46c1.19.18,2.37.42,3.54.68.59.13,1.18.24,1.77.35a7.4,7.4,0,0,0,1.87.16,0,0,0,0,1,0,.09,11.73,11.73,0,0,1-1.77.14,11.12,11.12,0,0,1-1.73-.15c-1.21-.17-2.41-.4-3.62-.58-2.44-.38-4.88-.75-7.34-.95A.1.1,0,0,1,538.08,312.85Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "545.245px 313.789px",
              }}
              id="el73njo86hkwx"
              className="animable"
            />
            <path
              d="M532.34,315.58a54.57,54.57,0,0,1,5.94-.89c1-.08,2-.11,3-.12l1.43,0c.53,0,1,.1,1.58.11.06,0,.06.08,0,.08-.5,0-1,.1-1.49.14l-1.49.11-3,.23c-2,.13-4,.28-6,.48A.08.08,0,0,1,532.34,315.58Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "538.307px 315.145px",
              }}
              id="elh0wrsg1ozp"
              className="animable"
            />
            <path
              d="M540.45,321.55s-2,5.27-6.72,6.7c0,0-.69-4.78.7-6.66.69-.94,4.39-2,4.94-1.42A8.46,8.46,0,0,1,540.45,321.55Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "537.004px 324.127px",
              }}
              id="elozsrnolwrk"
              className="animable"
            />
            <path
              d="M540.45,321.55s-2,5.27-6.72,6.7c0,0-.69-4.78.7-6.66.69-.94,4.39-2,4.94-1.42A8.46,8.46,0,0,1,540.45,321.55Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "537.004px 324.127px",
              }}
              id="elbfi10tbddyw"
              className="animable"
            />
            <path
              d="M540.45,321.55a12.84,12.84,0,0,1-2.53,4.19,10.09,10.09,0,0,1-1.87,1.64,9.39,9.39,0,0,1-2.25,1.1l-.26.09,0-.28a17.27,17.27,0,0,1-.16-3.52,6.07,6.07,0,0,1,1-3.43,4.53,4.53,0,0,1,1.59-.87,11.37,11.37,0,0,1,1.71-.46,5.15,5.15,0,0,1,.88-.09,1.26,1.26,0,0,1,.89.21A7.34,7.34,0,0,1,540.45,321.55Zm0,0a9.34,9.34,0,0,0-1.12-1.31,1.14,1.14,0,0,0-.77-.11,8.22,8.22,0,0,0-.84.15,10,10,0,0,0-1.63.52,4.35,4.35,0,0,0-1.41.83,5.76,5.76,0,0,0-.86,3.16,23.1,23.1,0,0,0,.14,3.43l-.3-.19a9,9,0,0,0,2.15-1,10.33,10.33,0,0,0,1.86-1.51A14.89,14.89,0,0,0,540.45,321.55Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "536.901px 324.239px",
              }}
              id="elklvw4i6dblh"
              className="animable"
            />
            <g id="elbm4ub966lpv">
              <path
                d="M540.45,321.55a8.77,8.77,0,0,0-1.07-1.38l-.09-.06-.12-.05h-.11a5.23,5.23,0,0,0-2.09.27,11.69,11.69,0,0,0,.9,2,3.15,3.15,0,0,0,1.67,1A11,11,0,0,0,540.45,321.55Z"
                style={{
                  fill: "rgb(5, 4, 0)",
                  opacity: "0.1",
                  transformOrigin: "538.71px 321.688px",
                }}
                className="animable"
              />
            </g>
            <path
              d="M541,321.73s3.7,5.78,7,7.25c0,0,1.47-3.67-.12-6.8-.71-1.4-5.74-2.91-6.18-2.2A6.69,6.69,0,0,0,541,321.73Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "544.81px 324.389px",
              }}
              id="el06ug5oe1fqrb"
              className="animable"
            />
            <path
              d="M541,321.73s3.7,5.78,7,7.25c0,0,1.47-3.67-.12-6.8-.71-1.4-5.74-2.91-6.18-2.2A6.69,6.69,0,0,0,541,321.73Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "544.81px 324.389px",
              }}
              id="elp6kxcn82zwi"
              className="animable"
            />
            <path
              d="M541,321.73a41.44,41.44,0,0,0,3.2,3.92A19.92,19.92,0,0,0,546,327.4a8.85,8.85,0,0,0,2,1.37l-.31.13a10.71,10.71,0,0,0,.59-3.77,7.39,7.39,0,0,0-.31-1.87,5.63,5.63,0,0,0-.34-.89,1.62,1.62,0,0,0-.57-.66,10.81,10.81,0,0,0-3.49-1.57,8.09,8.09,0,0,0-1-.21,1.69,1.69,0,0,0-.9,0A5.62,5.62,0,0,0,541,321.73Zm0,0a5.48,5.48,0,0,1,.68-1.87,1.68,1.68,0,0,1,1-.15,6.39,6.39,0,0,1,1,.16,10.76,10.76,0,0,1,3.69,1.5,2.15,2.15,0,0,1,.71.81,6.37,6.37,0,0,1,.38.94,8,8,0,0,1,.35,2,10.11,10.11,0,0,1-.64,4l-.08.23-.23-.11a9.35,9.35,0,0,1-2.14-1.47,18.18,18.18,0,0,1-1.77-1.85A30.62,30.62,0,0,1,541,321.73Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "544.91px 324.52px",
              }}
              id="eli0of8bg575k"
              className="animable"
            />
            <g id="elpx25l7ge8p">
              <path
                d="M541.85,323a4,4,0,0,0,1.6-1.58,3.27,3.27,0,0,0,.25-1.4c-1-.26-1.89-.32-2.06,0a6.75,6.75,0,0,0-.68,1.75S541.29,322.25,541.85,323Z"
                style={{
                  fill: "rgb(5, 4, 0)",
                  opacity: "0.1",
                  transformOrigin: "542.331px 321.401px",
                }}
                className="animable"
              />
            </g>
            <path
              d="M542.65,320.18a2.23,2.23,0,1,0-1.82,2.57A2.23,2.23,0,0,0,542.65,320.18Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "540.451px 320.552px",
              }}
              id="elraacu03qkl"
              className="animable"
            />
            <path
              d="M542.65,320.18a2.1,2.1,0,1,0-.4,1.64A2.14,2.14,0,0,0,542.65,320.18Zm0,0a2.35,2.35,0,0,1-3.61,2.37,2.34,2.34,0,0,1-1-1.57,2.35,2.35,0,1,1,4.64-.8Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "540.345px 320.581px",
              }}
              id="elxqvkxr41xcs"
              className="animable"
            />
            <path
              d="M539.73,328.48a3.36,3.36,0,0,1,1.79,2.21,4.88,4.88,0,0,1,.22,1.46,5.37,5.37,0,0,1-.13,1.48,2.28,2.28,0,0,1-.33.76,1.09,1.09,0,0,1-.85.49,1,1,0,0,1-.88-.45,1.78,1.78,0,0,1-.27-.79,3.79,3.79,0,0,1,.17-1.47,2.58,2.58,0,0,1,.74-1.23,2.9,2.9,0,0,0-.4,1.3,4.07,4.07,0,0,0,.06,1.31c.2.94.88.79,1-.1a7.15,7.15,0,0,0,0-2.61,5.13,5.13,0,0,0-.4-1.26A2.92,2.92,0,0,0,539.73,328.48Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "540.507px 331.68px",
              }}
              id="el54k3zcpdoi2"
              className="animable"
            />
            <path
              d="M564.82,282.5c28.37,20.65,53.07,49.53,50.88,58.83-2.31,9.83-21.53,21.08-38.62,23.58-3.5.51-14.74-25-11.16-26.27,2.78-1,15.45-4.78,17-6.55,1-1.23-19.14-28.7-25.78-43.11C550.13,273.9,557.23,277,564.82,282.5Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "585.052px 321.443px",
              }}
              id="els4gyyuh5jtc"
              className="animable"
            />
            <path
              d="M573.13,337.75s-10.68,1.23-17.63,2.37c-3.88.64-10.43,2.32-13.07,4.09-3.94,2.65-18.73,16.24-15.51,19.9,2.91,3.31,7.15-2.07,7.15-2.07s-5.19,5.44-1.29,7.84,8.78-5.12,8.78-5.12-5.91,5.39-1.5,7.55c3.82,1.88,10.34-6.2,10.34-6.2s-6.82,5-3.09,7.29c4.12,2.54,15.41-8.34,15.41-8.34,9.18.89,14.81.18,21.23-1.73C585.13,363,573.13,337.75,573.13,337.75Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "555.248px 355.767px",
              }}
              id="elf4s3tsszdj4"
              className="animable"
            />
            <path
              d="M540,366.57l1.24-1.71a31.6,31.6,0,0,1,2.51-3.25c.89-1,1.83-1.95,2.8-2.87s2.15-1.85,3.14-2.85a.08.08,0,1,1,.11.11,37.59,37.59,0,0,0-2.81,3.1c-.9,1-1.78,2.08-2.67,3.12s-1.79,2-2.68,3c-.44.49-.87,1-1.29,1.52a10.71,10.71,0,0,1-1.23,1.45.06.06,0,0,1-.09-.07A8.34,8.34,0,0,1,540,366.57Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "544.432px 362.026px",
              }}
              id="elvu8i5aifrdc"
              className="animable"
            />
            <path
              d="M533,363.2a55.32,55.32,0,0,1,4.53-6.14,37.76,37.76,0,0,1,5.57-5.23.08.08,0,0,1,.11.11c-1.68,1.93-3.38,3.83-5.08,5.74-.84.93-4.48,5.28-4.92,5.69C533.11,363.46,533,363.31,533,363.2Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "538.111px 357.608px",
              }}
              id="elivd98nb1fq"
              className="animable"
            />
            <path
              d="M546.43,369.92a53.64,53.64,0,0,1,4.37-5.7,50.84,50.84,0,0,1,5.67-4.65c.08,0,.18.05.11.12-1.77,1.68-3.56,3.34-5.24,5.11-.82.86-3.9,4.49-4.75,5.24C546.51,370.11,546.39,370,546.43,369.92Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "551.513px 364.816px",
              }}
              id="elm1opegv6pse"
              className="animable"
            />
            <path
              d="M596.41,308l-21.15,14.17S559,297.89,554.75,286.33s.51-11.66,11.84-3.41S596.41,308,596.41,308Z"
              style={{
                fill: "rgb(64, 123, 255)",
                transformOrigin: "574.811px 299.654px",
              }}
              id="eldihgekmemt6"
              className="animable"
            />
            <path
              d="M591.51,305a172.73,172.73,0,0,1-17.6,12.27c-.08,0-.15-.07-.08-.12,1.61-1,10.22-7.55,17.59-12.28C591.51,304.83,591.58,305,591.51,305Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "582.67px 311.067px",
              }}
              id="elkhozsl89qn"
              className="animable"
            />
            <path
              d="M576.61,321.21a12.89,12.89,0,0,0-1.28.7c-2.44-4.06-2-3.13-4.46-7.16l-3.64-5.94c-1.25-2.06-2.4-4.22-3.77-6.21,0-.06-.12,0-.1.06.86,2,2,3.87,3,5.76-.23-.29-.44-.59-.68-.86-.53-.62-1.06-1.24-1.55-1.9-.07-.1-.24,0-.17.1.47.63.88,1.28,1.3,1.94s.86,1.3,1.32,1.93.93,1.19,1.41,1.76l.18.22c.67,1.18,1.32,2.37,2,3.54a59.47,59.47,0,0,0,4.95,7.23,12.23,12.23,0,0,0,1.53-1.05C576.74,321.28,576.67,321.2,576.61,321.21Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "570.025px 312.478px",
              }}
              id="eldtszddcwpk6"
              className="animable"
            />
            <path
              d="M579.6,333.89q-3.37,1.33-6.76,2.6T566,339H566c-1.94.21-3.87.49-5.81.74s-3.87.56-5.79.9-3.84.69-5.74,1.16a19.7,19.7,0,0,0-5.46,2,11,11,0,0,1,2.57-1.43,27.86,27.86,0,0,1,2.78-1c1.89-.54,3.8-1,5.73-1.39s3.87-.69,5.81-1,3.89-.5,5.84-.7h-.07q3.42-1.17,6.87-2.27T579.6,333.89Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "561.4px 338.845px",
              }}
              id="elrg6dvv21i4d"
              className="animable"
            />
            <path
              d="M554.82,279.55c-.32,1.51-4.14,8.28-8.77,8-2.37-.16-2.15-8.5-2.15-8.5l-.1-.62-1.36-8.63,11.9-6.54S554.91,279.12,554.82,279.55Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "548.635px 275.409px",
              }}
              id="eluu4esjtiyqt"
              className="animable"
            />
            <path
              d="M554.55,269.16a17.57,17.57,0,0,1-10.75,9.25l-1.37-8.63,11.92-6.55S554.45,265.94,554.55,269.16Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "548.49px 270.82px",
              }}
              id="el7ot69am97x"
              className="animable"
            />
            <path
              d="M529.68,259.69c1.08,15.31,10.37,16.39,13.67,16.16,3-.22,13.17-1.33,13.68-16.66s-6.95-19.68-13.83-19.55S528.61,244.39,529.68,259.69Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "543.317px 257.756px",
              }}
              id="ele5j4i4knboc"
              className="animable"
            />
            <path
              d="M533.07,257.72c.35-.1.65-.25,1-.38a1.69,1.69,0,0,0,.93-.58.55.55,0,0,0-.1-.66,1.37,1.37,0,0,0-1.37-.1,2,2,0,0,0-1.12.89A.58.58,0,0,0,533.07,257.72Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "533.706px 256.8px",
              }}
              id="el6ll5dw77265"
              className="animable"
            />
            <path
              d="M542.91,256.22c-.37,0-.7,0-1.06-.05a1.73,1.73,0,0,1-1.07-.26.55.55,0,0,1-.11-.66,1.35,1.35,0,0,1,1.27-.52,2,2,0,0,1,1.34.49A.58.58,0,0,1,542.91,256.22Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "542.033px 255.468px",
              }}
              id="elplqo4qyhiob"
              className="animable"
            />
            <path
              d="M541.51,260.37c0-.05.09,0,.09.08.11,1,.41,2.21,1.4,2.42,0,0,0,.06,0,.06C541.83,262.93,541.45,261.37,541.51,260.37Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "542.252px 261.64px",
              }}
              id="eld6gqajbmlo6"
              className="animable"
            />
            <path
              d="M542.28,259.16c1.65-.36,2.24,3,.72,3.29S540.9,259.46,542.28,259.16Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "542.689px 260.801px",
              }}
              id="el81j3za2piny"
              className="animable"
            />
            <path
              d="M543,259.22c.32.13.66.41,1,.38s.65-.43.86-.81c0,0,0,0,.06,0a1.21,1.21,0,0,1-.67,1.54c-.6.2-1.15-.28-1.42-.89C542.77,259.35,542.84,259.16,543,259.22Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "543.902px 259.583px",
              }}
              id="ely1a5choi5zj"
              className="animable"
            />
            <path
              d="M535.67,261.18s-.07.06-.06.1c.22,1,.3,2.23-.57,2.75,0,0,0,.06,0,.05C536.17,263.71,536,262.11,535.67,261.18Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "535.478px 262.631px",
              }}
              id="elvs4ku2oc8xg"
              className="animable"
            />
            <path
              d="M534.55,260.27c-1.67.18-1.19,3.52.36,3.36S536,260.13,534.55,260.27Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "534.689px 261.951px",
              }}
              id="elx95w4iin7ws"
              className="animable"
            />
            <path
              d="M533.89,260.59c-.24.22-.45.58-.77.64s-.68-.24-1-.55c0,0-.06,0,0,0,.07.64.38,1.27,1,1.31s.91-.58,1-1.23C534.1,260.67,534,260.51,533.89,260.59Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "533.107px 261.28px",
              }}
              id="elyskxq8cj22"
              className="animable"
            />
            <path
              d="M543.61,268.18c-.12.33-.2.74-.53.93a2.55,2.55,0,0,1-1.12.25s-.05.05,0,.07a1.38,1.38,0,0,0,1.44-.06,1.12,1.12,0,0,0,.34-1.19C543.71,268.12,543.63,268.13,543.61,268.18Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "542.865px 268.871px",
              }}
              id="el1q0e277eq9f"
              className="animable"
            />
            <path
              d="M538.18,265.57s.12,1.57.27,2.29a3.94,3.94,0,0,0,3.53-2c0-.07-.08-.13-.13-.07a5.26,5.26,0,0,1-2.94,1.56c-.09-.19-.1-2.7-.22-2.68a6.33,6.33,0,0,0-1.56.72c-.33-3.27-.14-6.56-.52-9.81a.1.1,0,0,0-.2,0,52.64,52.64,0,0,0,.12,10.58C536.56,266.61,537.92,265.76,538.18,265.57Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "539.09px 261.671px",
              }}
              id="elccnbaeaovv6"
              className="animable"
            />
            <path
              d="M539.42,267.58a3.74,3.74,0,0,0,1.75.8,1.71,1.71,0,0,0,1-.17c.68-.37.55-1,.28-1.58a4.07,4.07,0,0,0-.54-.81A4.91,4.91,0,0,1,539.42,267.58Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "541.044px 267.107px",
              }}
              id="elztk7pub60ac"
              className="animable"
            />
            <path
              d="M541.17,268.38a1.71,1.71,0,0,0,1-.17c.68-.37.55-1,.28-1.58A1.76,1.76,0,0,0,541.17,268.38Z"
              style={{
                fill: "rgb(255, 154, 187)",
                transformOrigin: "541.919px 267.513px",
              }}
              id="el9n3scxw23pk"
              className="animable"
            />
            <path
              d="M556.11,260.21c-2.8.77-4.47-5.65-4.47-5.65s-12.43-.29-14.9-10a16.86,16.86,0,0,1-7.1,10.37s-1.15-10.89,7.13-14.24c0,0,10.09-5.42,17.31,2.16S558,259.69,556.11,260.21Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "544.057px 249.544px",
              }}
              id="eljdcokvmjwe"
              className="animable"
            />
            <path
              d="M554.68,260.53s2.56-5.25,4.89-4.41.93,8.09-1.44,9.43a2.6,2.6,0,0,1-3.65-.84Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "557.622px 261.001px",
              }}
              id="elhd9dpc6yr1e"
              className="animable"
            />
            <path
              d="M559.12,258.19a0,0,0,1,1,0,.06c-1.65,1-2.32,2.78-2.71,4.59a1.45,1.45,0,0,1,2.06-.73.06.06,0,0,1,0,.11,1.59,1.59,0,0,0-1.65.82,7.6,7.6,0,0,0-.61,1.54c-.06.17-.36.13-.32-.06v0C555.48,262.23,556.74,258.8,559.12,258.19Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "557.467px 261.439px",
              }}
              id="eln7520l4ube"
              className="animable"
            />
            <path
              d="M557.75,265a.65.65,0,0,1-1.3-.14.65.65,0,1,1,1.3.14Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "557.1px 264.926px",
              }}
              id="el6sy9ftu1id3"
              className="animable"
            />
            <path
              d="M536.6,243.82a10.84,10.84,0,0,0,1.19,4.43,11.31,11.31,0,0,0,2.87,3.65,7.9,7.9,0,0,0,.94.7l.48.34c.16.1.34.19.51.28.35.18.69.37,1,.53l1.09.4.55.19.56.13,1.13.25c.76.1,1.53.16,2.29.21-.74-.21-1.49-.33-2.22-.54l-1.07-.34-.54-.17-.51-.23c-.34-.15-.68-.31-1-.45s-.65-.37-1-.55l-.48-.27-.45-.33a8.26,8.26,0,0,1-.88-.68,12.54,12.54,0,0,1-2.85-3.38,12.94,12.94,0,0,1-1-2A12.29,12.29,0,0,1,536.6,243.82Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "542.905px 249.375px",
              }}
              id="el5vc025hj5r"
              className="animable"
            />
            <path
              d="M533.57,359.48c-3,1.71-6.56-5.92-6.56-5.92a6.28,6.28,0,0,1-1.7,3.93c-1.86,1.8-5.5-.33-5.5-.33a7.81,7.81,0,0,1-2.45,3.86c-2,1.55-5.94-.08-5.94-.08a6.81,6.81,0,0,1-4.29,2c-4.37.2-8.16-5.29-10.61-8.75s-6.14-15.59-7.08-17.83l22.1-15.9s15.39,12.41,17.69,16.9C531.17,341.17,538.07,356.86,533.57,359.48Z"
              style={{
                fill: "rgb(235, 148, 129)",
                transformOrigin: "512.253px 341.703px",
              }}
              id="el6abbnh5gpic"
              className="animable"
            />
            <path
              d="M527.06,353.45c-1.1-4.5-3.27-8.74-5.41-12.81a.08.08,0,0,0-.14.08c2.17,4,3.33,8.71,5.34,12.81A.11.11,0,0,0,527.06,353.45Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "524.283px 347.1px",
              }}
              id="el5a5se00qpov"
              className="animable"
            />
            <path
              d="M520.05,356.88a12.07,12.07,0,0,0-1.43-2.82c-.57-1-1.16-1.92-1.76-2.87q-1.82-2.83-3.76-5.58c-.06-.08-.2,0-.14.08,1.2,1.88,2.37,3.79,3.49,5.73.56,1,1.11,1.94,1.66,2.92a14,14,0,0,0,1.66,2.69A.17.17,0,0,0,520.05,356.88Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "516.498px 351.324px",
              }}
              id="el8b1g5ko8hup"
              className="animable"
            />
            <path
              d="M511.37,360.86a8.5,8.5,0,0,0-1.41-2.48c-.57-.85-1.14-1.7-1.69-2.56-1.12-1.71-2.24-3.43-3.29-5.18-.05-.08-.19,0-.14.08,1.06,1.78,2.1,3.58,3.18,5.36.53.87,1.07,1.74,1.61,2.61a8.11,8.11,0,0,0,1.66,2.23S511.38,360.9,511.37,360.86Z"
              style={{
                fill: "rgb(38, 50, 56)",
                transformOrigin: "508.1px 355.763px",
              }}
              id="el5frt7kd3ml6"
              className="animable"
            />
            <rect
              x="495.92"
              y="367.26"
              width="79.5"
              height="3.57"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "535.67px 369.045px",
              }}
              id="elfl3xa1ryg2c"
              className="animable"
            />
            <polygon
              points="557.82 367.26 558.47 370.82 495.92 370.82 495.92 367.26 557.82 367.26"
              style={{
                fill: "rgb(166, 166, 166)",
                transformOrigin: "527.195px 369.04px",
              }}
              id="elcl38b2fiv1p"
              className="animable"
            />
            <polygon
              points="555.01 370.82 487.51 370.82 481.89 324.97 549.39 324.97 555.01 370.82"
              style={{
                fill: "rgb(199, 199, 199)",
                transformOrigin: "518.45px 347.895px",
              }}
              id="elvrce85dupt"
              className="animable"
            />
            <path
              d="M522.56,347.9a4,4,0,0,1-4,4.11,4.2,4.2,0,0,1-4.18-4.11,4,4,0,0,1,4-4.11A4.2,4.2,0,0,1,522.56,347.9Z"
              style={{
                fill: "rgb(255, 255, 255)",
                transformOrigin: "518.47px 347.9px",
              }}
              id="elogx4hetdv79"
              className="animable"
            />
          </g>
          <defs>
            {" "}
            <filter id="active" height="200%">
              {" "}
              <feMorphology
                in="SourceAlpha"
                result="DILATED"
                operator="dilate"
                radius={2}
              />{" "}
              <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{" "}
              <feComposite
                in="PINK"
                in2="DILATED"
                operator="in"
                result="OUTLINE"
              />{" "}
              <feMerge>
                {" "}
                <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{" "}
              </feMerge>{" "}
            </filter>{" "}
            <filter id="hover" height="200%">
              {" "}
              <feMorphology
                in="SourceAlpha"
                result="DILATED"
                operator="dilate"
                radius={2}
              />{" "}
              <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{" "}
              <feComposite
                in="PINK"
                in2="DILATED"
                operator="in"
                result="OUTLINE"
              />{" "}
              <feMerge>
                {" "}
                <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{" "}
              </feMerge>{" "}
              <feColorMatrix
                type="matrix"
                values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
              />{" "}
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Image;
