import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { blue, orange } from '@material-ui/core/colors';

import { users } from "./tasks";
import { withStyles } from "@material-ui/core/styles";

import { fetchTeamUsers } from "../../actions/UserAction";
import { fetchOperationsByUsers } from "../../actions/OperationAction";

import H3 from "@material-tailwind/react";
import Paragraph from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import InputPerson from "./InputPerson";
import { Typography, Button } from "@material-tailwind/react";
import Schedule from "./Schedule";
import jwt from "jwt-decode";
import useMediaQuery from "useMediaQuery";
import SelectAutocomplete from "./Select";
import { Backdrop, CircularProgress } from "@mui/material";
import Translation from "Translation";
import { useTranslation } from "react-i18next";

const getWorkWeekStartAndEndDates = (date) => {
  const workDays = [1, 2, 3, 4, 5];
  const dayOfWeek = date.getDay();
  const startDate = new Date(date);
  startDate.setDate(startDate.getDate() - dayOfWeek);
  if (!workDays.includes(startDate.getDay())) {
    startDate.setDate(startDate.getDate() + 1);
  }
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 5);

  return { startDate, endDate };
};

export default function CalendarView(props) {
  const color = "#294e87";

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [person, setPerson] = useState([]);
  const [scheduleUsers, setScheduleUsers] = useState([]);
  const [loading, setloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    getWorkWeekStartAndEndDates(new Date())
      ?.startDate?.toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    getWorkWeekStartAndEndDates(new Date())
      ?.endDate?.toISOString()
      .substring(0, 10)
  );

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchTeamUsers(token, user.id);
      setUsers(result);
    };
    fetch();
  }, []);

  // useEffect(() => {
  //   const fetch = async () => {
  //     const result = await fetchOperationsManager(token, user.id);
  //     var tab = [];
  //     result.map((op) => {
  //       if (op) {
  //         if (op.OperationType === "WFH" || op.OperationType == "Client site") {
  //           var date = new Date(op.date);
  //           var d = date.toISOString().substring(0, 10);
  //           const year = date.getFullYear();
  //           const month = date.getMonth();
  //           const day = d.split("-").pop();
  //           if (op.timeslot === "AM") {
  //             var start = new Date(year, month, day, 8, 0);
  //             var end = new Date(year, month, day, 12, 0);
  //           } else {
  //             var start = new Date(year, month, day, 13, 0);
  //             var end = new Date(year, month, day, 18, 0);
  //           }
  //           tab.push({
  //             id: op._id,
  //             title:
  //               op.OperationType == "Client site"
  //                 ? "Third party site"
  //                 : op.OperationType,
  //             startDate: start,
  //             endDate: end,
  //             userId: op.user,
  //             request: op.request,
  //             status: op.request && op.request.status,
  //             client:
  //               op.OperationType == "Client site" ? op.request.client : "",
  //           });
  //         } else if (op.OperationType === "RESERVATION") {
  //           var date = new Date(op.reservationdate);
  //           var d = date.toISOString().substring(0, 10);
  //           const year = date.getFullYear();
  //           const month = date.getMonth();
  //           const day = d.split("-").pop();
  //           if (op.timeslot === "AM") {
  //             var start = new Date(year, month, day, 8, 0);
  //             var end = new Date(year, month, day, 12, 0);
  //           } else {
  //             var start = new Date(year, month, day, 13, 0);
  //             var end = new Date(year, month, day, 18, 0);
  //           }
  //           tab.push({
  //             id: op._id,
  //             title: "On site",
  //             startDate: start,
  //             endDate: end,
  //             userId: op.user,
  //             desk: op.desk.name,
  //             status: op.status,
  //           });
  //         } else if (op.OperationType === "REMOTE_WORKING") {
  //           var startDate = new Date(op.date_debut);
  //           var endDate = new Date(op.date_fin);
  //           var s = startDate.toISOString().substring(0, 10);
  //           var e = endDate.toISOString().substring(0, 10);

  //           const yearStart = startDate.getFullYear();
  //           const monthStart = startDate.getMonth();
  //           const dayStart = s.split("-").pop();

  //           const yearEnd = endDate.getFullYear();
  //           const monthEnd = endDate.getMonth();
  //           const dayEnd = e.split("-").pop();

  //           var start = new Date(yearStart, monthStart, dayStart, 8, 0);
  //           var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);

  //           tab.push({
  //             id: op._id,
  //             title: op.OperationType,
  //             startDate: start,
  //             endDate: end,
  //             userId: op.user,
  //             request: op.request,
  //             status: op.request && op.request.status,
  //             allDay: true,
  //           });
  //         }
  //       }
  //     });

  //     setData(tab);
  //   };
  //   fetch();
  // }, []);

  // const handleSend = async () => {
  //   setloading(true);
  //   setVisible(false);
  //   // var usersSelected = Array.from(person, ({_id}) => _id)
  //   var user = [];

  //   person.map((item) => {
  //     user.push({
  //       text: item.firstname + " " + item.lastname,
  //       id: item._id,
  //       color: "#e8eaed",
  //     });
  //   });

  //   setScheduleUsers(user);
  //   setloading(false);
  //   setVisible(true);
  // };

  var tab = [];

  const handleSend = async () => {
    setloading(true);
    setVisible(false);
    var user = [];

    person.map((item) => {
      user.push({
        // text: item.firstname + " " + item.lastname,
        text: item.label,
        id: item.value,
        color: "#e8eaed",
      });
    });
    setScheduleUsers(user);

    const idsUsers = Array.from(person, ({ value }) => value);
    const result = await fetchOperationsByUsers(idsUsers, startDate, endDate);
    result &&
      result.map((op) => {
        if (op.OperationType === "WFH" || op.OperationType === "Client site") {
          var date = new Date(op.date);
          var d = date.toISOString().substring(0, 10);
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = d.split("-").pop();
          if (op.timeslot === "AM") {
            var start = new Date(year, month, day, 8, 0);
            var end = new Date(year, month, day, 12, 0);
          } else {
            var start = new Date(year, month, day, 13, 0);
            var end = new Date(year, month, day, 18, 0);
          }
          tab.push({
            id: op._id,
            name: op.OperationType,
            title:
              op.OperationType === "Client site"
                ? t("Third party site")
                : t(op.OperationType),
            startDate: start,
            endDate: end,
            userId: op.user._id,
            request: op.request,
            slId: op.user.serviceLine,
            status: op.request && op.request.status,
          });
        } else if (op.OperationType === "RESERVATION") {
          var date = new Date(op.reservationdate);
          var d = date.toISOString().substring(0, 10);
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = d.split("-").pop();
          if (op.timeslot === "AM") {
            var start = new Date(year, month, day, 8, 0);
            var end = new Date(year, month, day, 12, 0);
          } else {
            var start = new Date(year, month, day, 13, 0);
            var end = new Date(year, month, day, 18, 0);
          }
          tab.push({
            id: op._id,
            name: op.OperationType,
            title: t("On site"),
            startDate: start,
            endDate: end,
            userId: op.user._id,
            desk: op.desk.name,
            status: op.status,
          });
        } else if (op.OperationType === "REMOTE_WORKING") {
          var startDate = new Date(op.date_debut);
          var endDate = new Date(op.date_fin);
          var s = startDate.toISOString().substring(0, 10);
          var e = endDate.toISOString().substring(0, 10);

          const yearStart = startDate.getFullYear();
          const monthStart = startDate.getMonth();
          const dayStart = s.split("-").pop();

          const yearEnd = endDate.getFullYear();
          const monthEnd = endDate.getMonth();
          const dayEnd = e.split("-").pop();

          var start = new Date(yearStart, monthStart, dayStart, 8, 0);
          var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);

          tab.push({
            id: op._id,
            title: t("Exceptional WFH"),
            name: op.OperationType,
            startDate: start,
            endDate: end,
            userId: op.user._id,
            request: op.request,
            status: op.request && op.request.status,
            allDay: true,
          });
        } else if (op.OperationType == "Leave") {
          var startDate = new Date(op.date_debut);
          var endDate = new Date(op.date_fin);
          var s = startDate.toISOString().substring(0, 10);
          var e = endDate.toISOString().substring(0, 10);

          const yearStart = startDate.getFullYear();
          const monthStart = startDate.getMonth();
          const dayStart = s.split("-").pop();

          const yearEnd = endDate.getFullYear();
          const monthEnd = endDate.getMonth();
          const dayEnd = e.split("-").pop();

          if (op.slot_debut == "AM" && op.slot_fin == "AM") {
            var start = new Date(yearStart, monthStart, dayStart, 8, 0);
            var end = new Date(yearEnd, monthEnd, dayEnd, 12, 0);
          } else if (op.slot_debut == "PM") {
            var start = new Date(yearStart, monthStart, dayStart, 13, 0);
            var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
          } else {
            var start = new Date(yearStart, monthStart, dayStart, 8, 0);
            var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
          }

          tab.push({
            id: op._id,
            title: op.OperationType,
            startDate: start,
            endDate: end,
            userId: op.user._id,
            Color: "#1ca858",
            status: op.status,
            allDay:
              op.slot_debut == "Full" ||
              (op.slot_debut == "AM" && op.slot_fin == "PM")
                ? true
                : false,
          });
        }
      });

    setData(tab);

    // setScheduleUsers(user);
    setloading(false);
    setVisible(true);
  };

  useEffect(() => {
    // handleSend();
    // console.log("start date", startDate, endDate);
    const fetchData = async () => {
      // console.log("feeetch Data", startDate, endDate);
      // person.map((item) => {
      //   user.push({
      //     // text: item.firstname + " " + item.lastname,
      //     text: item.label,
      //     id: item.value,
      //     color: "#e8eaed",
      //   });
      // });
      // setScheduleUsers(user);
      setloading(true);
      const idsUsers = Array.from(person, ({ value }) => value);
      const result = await fetchOperationsByUsers(idsUsers, startDate, endDate);

      result &&
        result.map((op) => {
          if (
            op.OperationType === "WFH" ||
            op.OperationType === "Client site"
          ) {
            var date = new Date(op.date);
            var d = date.toISOString().substring(0, 10);
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = d.split("-").pop();
            if (op.timeslot === "AM") {
              var start = new Date(year, month, day, 8, 0);
              var end = new Date(year, month, day, 12, 0);
            } else {
              var start = new Date(year, month, day, 13, 0);
              var end = new Date(year, month, day, 18, 0);
            }
            tab.push({
              id: op._id,
              name: op.OperationType,
              title:
                op.OperationType === "Client site"
                  ? t("Third party site")
                  : t(op.OperationType),
              startDate: start,
              endDate: end,
              userId: op.user._id,
              request: op.request,
              slId: op.user.serviceLine,
              status: op.request && op.request.status,
            });
          } else if (op.OperationType === "RESERVATION") {
            var date = new Date(op.reservationdate);
            var d = date.toISOString().substring(0, 10);
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = d.split("-").pop();
            if (op.timeslot === "AM") {
              var start = new Date(year, month, day, 8, 0);
              var end = new Date(year, month, day, 12, 0);
            } else {
              var start = new Date(year, month, day, 13, 0);
              var end = new Date(year, month, day, 18, 0);
            }
            tab.push({
              id: op._id,
              title: t("On site"),
              name: op.OperationType,
              startDate: start,
              endDate: end,
              userId: op.user._id,
              desk: op.desk.name,
              status: op.status,
            });
          } else if (
            op.OperationType === "REMOTE_WORKING" ||
            op.OperationType == "TRAVEL"
          ) {
            var startDate = new Date(op.date_debut);
            var endDate = new Date(op.date_fin);
            var s = startDate.toISOString().substring(0, 10);
            var e = endDate.toISOString().substring(0, 10);

            const yearStart = startDate.getFullYear();
            const monthStart = startDate.getMonth();
            const dayStart = s.split("-").pop();

            const yearEnd = endDate.getFullYear();
            const monthEnd = endDate.getMonth();
            const dayEnd = e.split("-").pop();

            var start = new Date(yearStart, monthStart, dayStart, 8, 0);
            var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);

            tab.push({
              id: op._id,
              title:
                op.OperationType == "REMOTE_WORKING"
                  ? t("Exceptional WFH")
                  : t(op.OperationType),
              name: op.OperationType,
              startDate: start,
              endDate: end,
              userId: op.user._id,
              request: op.request,
              status: op.request && op.request.status,
              allDay: true,
            });
          } else if (op.OperationType == "Leave") {
            var startDate = new Date(op.date_debut);
            var endDate = new Date(op.date_fin);
            var s = startDate.toISOString().substring(0, 10);
            var e = endDate.toISOString().substring(0, 10);

            const yearStart = startDate.getFullYear();
            const monthStart = startDate.getMonth();
            const dayStart = s.split("-").pop();

            const yearEnd = endDate.getFullYear();
            const monthEnd = endDate.getMonth();
            const dayEnd = e.split("-").pop();

            if (op.slot_debut == "AM" && op.slot_fin == "AM") {
              var start = new Date(yearStart, monthStart, dayStart, 8, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 12, 0);
            } else if (op.slot_debut == "PM") {
              var start = new Date(yearStart, monthStart, dayStart, 13, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
            } else {
              var start = new Date(yearStart, monthStart, dayStart, 8, 0);
              var end = new Date(yearEnd, monthEnd, dayEnd, 18, 0);
            }

            tab.push({
              id: op._id,
              name: op.OperationType,
              title: t("Leave"),
              startDate: start,
              endDate: end,
              userId: op.user._id,
              Color: "#1ca858",
              status: op.status,
              allDay:
                op.slot_debut == "Full" ||
                (op.slot_debut == "AM" && op.slot_fin == "PM")
                  ? true
                  : false,
            });
          }
        });

      setData(tab);
      setVisible(true);
      setloading(false);
    };

    fetchData();
  }, [startDate, endDate]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full lg:w-8/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div className="flex-auto p-5 lg:p-10">
            {/* <div className="w-full text-center">
              <Typography
                variant={isDesktop ? "h3" : "h6"}
                style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
              >
                Want to keep track of your team's planning
              </Typography>
              <Typography color="gray">
                Choose team's members to see their schedules.
              </Typography>
            </div> */}
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="flex justify-center mt-20">
                <div className="w-150">
                  <Typography className="text-sm text-gray-700 pt-1">
                    <Translation message={"Team's members"} />
                  </Typography>
                </div>

                {/* <InputPerson users={users} setPerson={setPerson} /> */}
                {users && (
                  <SelectAutocomplete users={users} setPerson={setPerson} />
                )}

                {/* <Button 
                                    style={{ backgroundColor : '#083985' }}
                                     ripple="light" 
                                     onClick={() => {handleSend()}}
                                     >
                                    <ClipLoader color="white" loading={loading}  size={20} />
                                        Sarch
                                    </Button> */}
              </div>

              <div className="flex justify-center mt-20">
                <Button
                  style={{ backgroundColor: "#083985" }}
                  ripple="light"
                  onClick={() => {
                    handleSend();
                  }}
                >
                  <ClipLoader color="white" loading={loading} size={20} />
                  <Translation message={"Search"} />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {visible && scheduleUsers.length !== 0 && (
        <Schedule
          users={scheduleUsers}
          data={data}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}
      <Backdrop open={loading}>
        {" "}
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
