import React, { useState, useEffect } from "react";
import {
  Dialog,
  Typography,
  Button,
  DialogFooter,
} from "@material-tailwind/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./style.css";
import Lottie from "react-lottie";
import lottieData from "assets/lotties/information";
import sound from "assets/audio/clock-ticking-2.mp3";
import { handleUnlock } from "actions/RessourcesAction";
import { bookDesk } from "actions/OperationAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ClipLoader } from "react-spinners";
import useMediaQuery from "useMediaQuery";
import { useTranslation } from "react-i18next";
import Translation from "Translation";
import { bookGuestDesk } from "actions/OperationAction";
const options = {
  loop: true,
  autoplay: true,
  animationData: lottieData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Popup = (props) => {
  const { i18n, t } = useTranslation();
  const {
    openPopup,
    setOpenPopup,
    AM,
    PM,
    start,
    end,
    guestReservation = { guestReservation },
  } = props;

  const [text] = useState(
    t(
      "This desk is available for you, if you want to book it click BOOK before time runs out"
    )
  );
  const [isPlaying, setIsPlaying] = useState(true);
  const [loading, setLoading] = useState(false);

  const [audio] = useState(new Audio(sound));

  const renderTime = ({ remainingTime }) => {
    isPlaying ? audio.play() : audio.pause();
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <Translation message={"Too late..."} />
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="text">
          <Translation message={"Remaining"} />
        </div>
        <div className="value">{remainingTime}</div>
        <div className="text">
          <Translation message={"seconds"} />
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   if (
  //     (AM == 201 && PM == 201) ||
  //     (PM == 201 &&
  //       isToday(new Date(start)) == true &&
  //       moment(new Date(), "ddd DD-MMM-YYYY, hh:mm A").format("A") == "PM")
  //   ) {
  //     setText("Booking is not allowed");
  //     setAllowed(false);
  //   } else {
  //     setAllowed(true);
  //     // handleLock(openPopup.desk.Desk._id);
  //   }
  // }, []);

  const handleUnlockRessource = async () => {
    await handleUnlock(openPopup.desk._id, start, end);
    audio?.pause();
    // audio.preload("none");
    setOpenPopup({ open: false });
  };

  const handleBook = async () => {
    // setOpenPopup({ open: false });
    // audio.pause();
    setIsPlaying(false);
    setLoading(true);
    // audio?.preload("none");
    audio.volume = 0;

    if (guestReservation.book == true) {
      if (
        guestReservation.name?.length == 0 &&
        guestReservation.email?.length == 0
      ) {
        NotificationManager.error("Guest informations are required ! ");
        setLoading(false);
        setOpenPopup({ open: false });
        return;
      } else {
        const data = {
          ressource: openPopup.desk._id,
          start: start,
          end: end,
          user: guestReservation.name,
          email: guestReservation.email,
        };

        var res = await bookGuestDesk(data);
        if (res.status == 200) {
          NotificationManager.success(t("Booked successfully"));
          setLoading(false);
          setOpenPopup({ open: false });
        } else {
          NotificationManager.error(res.message);
          setLoading(false);
          setOpenPopup({ open: false });
        }
      }
    } else {
      var res = await bookDesk(openPopup.desk._id, start, end);
      if (res.status == 200) {
        NotificationManager.success(t("Booked successfully"));
        setLoading(false);
        setOpenPopup({ open: false });
      } else {
        NotificationManager.error(res.message);
        setLoading(false);
      }
    }
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <div>
      <Dialog
        open={openPopup.open}
        // handler={() => {
        //   setOpenPopup({ open: false });
        // }}
        style={{
          outline: "none",
        }}
        size={isDesktop ? "md" : "lg"}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
        {/* {allowed ? ( */}
        <div className="timer-wrapper mt-10">
          <CountdownCircleTimer
            isPlaying={isPlaying}
            duration={10}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[10, 6, 3, 0]}
            // onComplete={() => ({ shouldRepeat: false, delay: 1 })}
            onComplete={handleUnlockRessource}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
        {/* ) : (
          <div className="mt-10">
            <Lottie options={options} height={100} width={100} />
          </div>
        )} */}

        <div className="flex content-center items-center justify-center mt-10">
          <Typography variant="h4" color="blue-gray">
            {" "}
            {openPopup.desk.name + " / " + openPopup.desk.zone.name}
          </Typography>
        </div>
        <div className="flex content-center items-center justify-center mt-5 mb-5">
          <Typography variant="text" color="blue-gray">
            {start.split("-").reverse().join("-") +
              " to " +
              end.split("-").reverse().join("-")}
          </Typography>
        </div>
        <div className="text-center">
          <Typography variant="text" color="blue-gray">
            {text}
          </Typography>
        </div>
        <DialogFooter className="flex content-center items-center justify-center mt-5 mb-5">
          <div className="text-center">
            <Button
              color="indigo"
              onClick={handleBook}
              style={{
                outline: "none",
              }}
              className="mr-1"
              disabled={loading}
            >
              <ClipLoader
                color="white"
                loading={loading}
                size={15}
                style={{ marginTop: 1 }}
              />
              <span className="ml-1">
                <Translation message={"BOOK"} />
              </span>
            </Button>

            <Button
              color="indigo"
              onClick={handleUnlockRessource}
              style={{
                outline: "none",
              }}
              variant="outlined"
              disabled={loading}
            >
              <span>
                <Translation message={"Cancel"} />
              </span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
      <NotificationContainer />
    </div>
  );
};

export default Popup;
