import {
  //   Accordion,
  //   AccordionBody,
  //   AccordionHeader,
  Alert,
  Breadcrumbs,
  Button,
  Step,
  Stepper,
  Typography,
} from "@material-tailwind/react";
import Translation from "Translation";
import { fetchUser } from "actions/UserAction";
import { fetchALLUsers } from "actions/UserAction";
import { fetchManagers } from "actions/UserAction";
import { fetchCountries } from "actions/travel.service";
import { fetchMissionRequestByID } from "actions/travel.service";
import { fetchAllCities } from "actions/travel.service";
import { fetchFormulas } from "actions/travel.service";
import { getWorkflowSteps } from "actions/travel.service";
import ReactIconComponent from "components/ReactIconComponent";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import useMediaQuery from "useMediaQuery";
import UserPanel from "./UserPanel";
import MissionPanel from "./MissionPanel";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";
import PerdiemPanel from "./PerdiemPanel";
import TransportationPanel from "./TransportationPanel";
import AccomodationPanel from "./AccomodationPanel";
import { Home } from "@mui/icons-material";
import ConfirmDialog from "components/ConfirmDialog";
import { GrPowerReset } from "react-icons/gr";
import { t } from "i18next";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { editRequest } from "actions/travel.service";
import { addFile } from "actions/travel.service";
import VisaPanel from "./VisaPanel";
import BillsPanel from "./BillsPanel";
import { updateMission } from "actions/travel.service";

const color = "#083985";

function SortArray(x, y) {
  if (x.firstname < y.firstname) {
    return -1;
  }
  if (x.firstname > y.firstname) {
    return 1;
  }
  return 0;
}

function Sort(x, y) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}

const MissionDetails = () => {
  const { id } = useParams();

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwtDecode(token);
  const [request, setRequest] = useState({});

  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formules, setFormules] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [allCities, setCities] = useState([]);
  const [formData, setFormData] = useState({});
  const [missions, setMissions] = useState([]);
  const [isEngagementCodeValid, setIsEngagementCodeValid] = useState(true);
  const [missionData, setMissionData] = useState();
  const [engagementCodeData, setEngagementCodeData] = useState({});

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [editing, setEditing] = useState(false);

  const location = useLocation();

  const edit = location.state?.edit || false;

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
    // onConfirm: null,
  });
  const [expanded, setExpanded] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const requiredFields = [
    "passportNumber",
    "expiryPassport",
    "title",
    "statusEngagementCode",
    "startDate",
    "endDate",
    "missionCountry",
    "missionCity",
    "Formula",
  ];

  console.log("formData", formData);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var result = await getWorkflowSteps("initial");
      if (result.status == 200) {
        var sotedResult = result.data?.steps?.sort((a, b) => a.order - b.order);
        setSteps(sotedResult);
        // sotedResult.forEach((item, index) => {
        //   icons[index + 1] = <i className={item.userIcon} key={item.index} />;
        // });
        // fillIcons(sotedResult);
        // sotedResult.map((item, index) => {
        //   //   icons[index + 1] = <i className={item.userIcon} />;
        //   icons[index + 1] = item.userIcon;
        // });

        // console.log("iiiic", icons);
      }

      var res = await fetchMissionRequestByID(id);
      if (res.status == 200) {
        setRequest(res?.data);
        setMissionData(res.data?.mission);
        setMissions(
          res.data?.mission?.missionType === "compound"
            ? res.data.mission?.missions
            : []
        );
        // const newFormData = {
        //   needTransportation:
        //     res?.data?.mission?.transportation?.needTransportation,
        //   chargeable: res?.data?.mission?.chargeable,
        //   firstTime: res?.data?.mission?.firstTime,
        //   getVisa: res?.data?.mission?.visa?.getVisa,
        //   personalVisa: res?.data?.mission?.visa?.personalVisa,
        // };
        // setFormData(newFormData);
        // setInitialData(newFormData);
      }

      const usersResult = await fetchALLUsers();
      var users = usersResult
        .filter((item) => item._id !== user.id)
        .sort(SortArray);
      setManagers(
        users?.map((item) => ({
          ...item,
          label: item.firstname + " " + item.lastname,
          value: item._id,
        }))
      );
      setLoading(false);
    };

    fetchData();
  }, []);
  useEffect(() => {
    const getCountries = async () => {
      try {
        const allCountries = await fetchCountries();
        const result = allCountries
          .sort(Sort)
          ?.map((item) => ({ ...item, value: item._id, label: item.name }));
        setCountries(result);
      } catch (error) {
        setCountries([]);
      }
    };

    const getUser = async () => {
      const result = await fetchUser("", user.id);
      setCurrentUser(result);
      // const resVisa = await fetchVisaHistory(user.id);
      // setHistoryVisa(resVisa);
      // const resVaccine = await fetchVaccineHistory(user.id);
      // setHistoryVaccines(resVaccine);
    };

    const getFormulas = async () => {
      const result = await fetchFormulas();
      setFormules(
        result?.map((item) => ({ ...item, value: item._id, label: item.name }))
      );
    };
    const getAllCities = async () => {
      const allCities = await fetchAllCities();
      setCities(
        allCities
          ?.sort(Sort)
          ?.map((item) => ({ ...item, value: item._id, label: item.name }))
      );
    };

    getCountries();
    getUser();
    getFormulas();
    getAllCities();
  }, []);

  console.log("request", request);
  console.log("formData", formData);
  console.log("missionData", missionData);

  const handleFormChange = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData,
    }));

    setMissionData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleChange = (index) => {
    const updatedExpanded = [...expanded];
    updatedExpanded[index] = !updatedExpanded[index];
    setExpanded(updatedExpanded);
  };

  const handleSave = async () => {
    setConfirmDialog({ isOpen: false });

    setEditing(true);
    const missingRequiredFields = requiredFields.some((field) => {
      return formData[field] === undefined || formData[field] === null;
    });
    if (missingRequiredFields.length > 0) {
      NotificationManager.error(
        `${t("Please fill in all required fields before proceeding.")}`
      );
      setEditing(false);

      return;
    }

    // if (!formData.departureCity) {
    //   formData.departureCity = request.mission.transportation.departureCity;
    // }

    // if (!formData.destinationCity) {
    //   formData.destinationCity = request.mission.transportation.destinationCity;
    // }

    var result = await editRequest(formData, request?._id);

    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        if (formData.passportFile) {
          const formDataFile = new FormData();
          formDataFile.append("file", formData.passportFile);
          formDataFile.append("fieldName", "passportFile");
          // console.log("ressssult", result.data);
          await addFile(formDataFile, request.mission?._id);
        }
        if (formData.visaFile) {
          const formDataFile = new FormData();
          formDataFile.append("file", formData.visaFile);
          formDataFile.append("fieldName", "visa");
          // console.log("ressssult", result.data);
          await addFile(formDataFile, request?.mission?._id);
        }
        setEditing(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      setEditing(false);
      NotificationManager.error(result.message);
    }
  };

  const handleEditMission = async () => {
    setEditing(true);
    if (
      engagementCodeData.statusEngagementCode == "obtained" &&
      (!engagementCodeData.engagementCode ||
        engagementCodeData.engagementCode === "")
    ) {
      NotificationManager.error(
        "Please fill in the engagement code before proceeding."
      );
      setEditing(false);
      setLoading(false);
      return;
    }
    setConfirmDialog({ isOpen: false });
    if (missionData?.missionType == "simple") {
      var result = await updateMission(
        engagementCodeData,
        request?.mission?._id
      );
    } else {
      var result = await updateMission(
        { missions: missions },
        request?.mission?._id
      );
    }
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);

        setEngagementCodeData({});
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      setLoading(false);
      NotificationManager.error(result.message);
    }
  };

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <a href="#" className="opacity-60">
                  <span>
                    <Translation message="Business travel requests" />
                  </span>
                </a>
                {/* <a href="#" className="opacity-60">
                  <span>
                    <Translation message="Mission details" />
                  </span>
                </a> */}
              </Breadcrumbs>
            </div>
          )}
          <div className="w-full lg:w-10/12 px-4 mt-2">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation
                      message={"Follow up on your mission request"}
                    />
                  </Typography>
                  <Typography color="gray">
                    <Translation
                      message={"Check the progress of your mission folder"}
                    />
                  </Typography>
                </div>
              </div>

              {steps.length > 0 && (
                <Stepper
                  activeStep={request?.mission?.currentStep?.order}
                  //   isLastStep={(value) => setIsLastStep(value)}
                  //   isFirstStep={(value) => setIsFirstStep(value)}
                  activeLineClassName="bg-indigo-500"
                >
                  {steps?.map((step, index) => (
                    <Step
                      // onClick={() => setActiveStep(index)}
                      activeClassName="bg-indigo-500"
                      completedClassName="bg-indigo-500 "
                    >
                      {/* <Icon name={step.name} size={32} /> */}

                      {/* {step.icon} */}
                      {/* <step.icon /> */}
                      <ReactIconComponent
                        name={step.icon}
                        size={20}
                        color={
                          index == request?.mission?.currentStep?.order &&
                          "var(--rs-primary-800)"
                        }
                      />
                      <div className="absolute -bottom-[4.5rem] w-max text-center">
                        <Typography
                          variant="medium"
                          color={
                            request?.mission?.currentStep?.order === index
                              ? "blue-gray"
                              : "gray"
                          }
                        >
                          <Translation message={step.name} />
                        </Typography>
                        <Typography
                          color={
                            request?.mission?.currentStep?.order === index
                              ? "blue-gray"
                              : "gray"
                          }
                          variant="small"
                          className="font-normal"
                        >
                          <Translation message={step.desc} />
                        </Typography>
                      </div>
                    </Step>
                  ))}
                </Stepper>
              )}

              <div style={{ marginTop: 150 }}>
                {loading ? (
                  <center>
                    <ClipLoader
                      color="blue"
                      loading={true}
                      size={50}
                      className="justify-center"
                    />
                  </center>
                ) : (
                  <div className="mt-50">
                    {request?.status == "refused" && (
                      <Alert color="amber">This request is rejected!</Alert>
                    )}

                    <div className="mt-50">
                      {/* <Accordion open={expanded[0]}>
                        <AccordionHeader onClick={() => handleChange(0)}> */}
                      <Accordion
                        expanded={expanded[0]}
                        onChange={() => handleChange(0)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-user mr-2" />
                            <Translation message="Submitter information" />
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <UserPanel
                            request={request}
                            edit={edit}
                            handleFormChange={handleFormChange}
                            formData={formData}
                            missionData={missionData}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded[1]}
                        onChange={() => handleChange(1)}
                        className="mt-10"
                      >
                        {/* <AccordionHeader onClick={() => handleChange(1)}> */}
                        <AccordionSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-folder mr-2" />
                            <Translation message="Mission information" />
                          </Typography>
                          {/* </AccordionHeader> */}
                        </AccordionSummary>
                        {/* <AccordionBody> */}
                        <AccordionDetails>
                          <MissionPanel
                            request={request}
                            edit={edit}
                            handleFormChange={handleFormChange}
                            formData={formData}
                            setFormData={setFormData}
                            setIsEngagementCodeValid={setIsEngagementCodeValid}
                            isEngagementCodeValid={isEngagementCodeValid}
                            countries={countries}
                            allCities={allCities}
                            formules={formules}
                            users={managers}
                            missions={missions}
                            setMissions={setMissions}
                            missionData={missionData}
                            engagementCodeData={engagementCodeData}
                            setEngagementCodeData={setEngagementCodeData}
                            // handleEngagementCodeDataChange={
                            //   handleEngagementCodeDataChange
                            // }
                            // editIcon={editIcon}
                            // selectedCountry={selectedCountry}
                            // setSelectedCountry={setSelectedCountry}
                            // setErrors={setErrors}
                            // errors={errors}
                            // allCities={allCities}
                            // reloadRequest={reloadRequest}
                          />
                          {/* </AccordionBody> */}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded[2]}
                        onChange={() => handleChange(2)}
                        className="mt-10"
                      >
                        {/* <AccordionHeader onClick={() => handleChange(1)}> */}
                        <AccordionSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-passport mr-2" />
                            <Translation message="Visa information" />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <VisaPanel
                            request={request}
                            formData={formData}
                            missionData={missionData}
                            missions={missions}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded[3]}
                        onChange={() => handleChange(3)}
                      >
                        <AccordionSummary
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-dollar-sign mr-2" /> Perdiem
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <PerdiemPanel
                            request={request}
                            edit={edit}
                            handleFormChange={handleFormChange}
                            formData={formData}
                            setFormData={setFormData}
                            formules={formules}
                            // selectedCountry={selectedCountry}
                            currentUser={currentUser}
                            missionData={missionData}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded[4]}
                        onChange={() => handleChange(4)}
                      >
                        <AccordionSummary
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-plane mr-2" />{" "}
                            <Translation message="Transportation" />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TransportationPanel
                            request={request}
                            edit={edit}
                            handleFormChange={handleFormChange}
                            formData={formData}
                            countries={countries}
                          />
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded[5]}
                        onChange={() => handleChange(5)}
                      >
                        <AccordionSummary
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                        >
                          <Typography className="title-field text-indigo-900">
                            <i className="fas fa-hotel mr-2" />{" "}
                            <Translation message="Accommodation" />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AccomodationPanel
                            request={request}
                            edit={edit}
                            handleFormChange={handleFormChange}
                            formData={formData}
                            missions={missions}
                            missionData={missionData}
                          />
                        </AccordionDetails>
                      </Accordion>

                      {steps.findIndex(
                        (st) => st.name == request?.mission?.currentStep?.name
                      ) >=
                        steps.findIndex((s) =>
                          s.name?.toLowerCase().includes("refund")
                        ) &&
                        request?.idSender?._id == user.id && (
                          <Accordion
                            expanded={expanded[6]}
                            onChange={() => handleChange(6)}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography className="title-field text-indigo-900">
                                <i className="fas fa-file-invoice-dollar mr-2" />{" "}
                                <Translation message="Expenses and bills" />
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <BillsPanel request={request} edit={edit} />
                            </AccordionDetails>
                          </Accordion>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <div className={`flex justify-center mt-5`}>
                {edit && (
                  <>
                    {/* {editing ? (
                      <ClipLoader
                        color="blue"
                        loading={true}
                        size={50}
                        className="justify-center"
                      />
                    ) : ( */}
                    <div className="flex items-center gap-3">
                      <Button
                        color="indigo"
                        onClick={() => {
                          if (
                            Object.keys(formData)?.length > 0 &&
                            Object.keys(formData).some(
                              (key) =>
                                key !== "engagementCode" &&
                                key !== "statusEngagementCode"
                            )
                          ) {
                            setConfirmDialog({
                              isOpen: true,
                              title: t("Are you sure to edit this request ?"),
                              subtitle: t(
                                "If you confirm the modification, your request will return to the Facility checks step."
                              ),

                              onConfirm: () => {
                                handleSave();
                              },
                            });
                            return;
                          }

                          if (Object.keys(engagementCodeData)?.length > 0) {
                            setConfirmDialog({
                              isOpen: true,
                              title: t(
                                "Are you sure to edit engagement code ?"
                              ),
                              subtitle: "",

                              onConfirm: () => {
                                handleEditMission();
                              },
                            });
                            return;
                          }
                          NotificationManager.warning(t("No changes found"));

                          // setConfirmDialog({
                          //   isOpen: true,
                          //   title: t("Are you sure to edit this request ?"),
                          //   subtitle: t(
                          //     "If you confirm the modification, your request will return to the Facility checks step."
                          //   ),

                          //   onConfirm: () => {
                          //     handleSave();
                          //   },
                          // });
                        }}
                        disabled={editing}
                        size="sm"
                        className="flex items-center gap-3"
                      >
                        {editing ? (
                          <>
                            <ClipLoader
                              color="white"
                              loading={true}
                              size={10}
                              className="justify-center"
                            />
                            <Translation message="Saving.." />
                          </>
                        ) : (
                          <>
                            <FaSave /> <Translation message="Save" />
                          </>
                        )}
                      </Button>
                      <Button
                        color="indigo"
                        variant="outlined"
                        className="flex items-center gap-3"
                        onClick={() => {
                          setFormData({});
                          setMissionData(request?.mission);
                          setEngagementCodeData({});
                        }}
                        size="sm"
                        disabled={editing}
                      >
                        <GrPowerReset /> <Translation message="Reset" />
                      </Button>
                    </div>
                    {/* )} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <NotificationContainer />
    </section>
  );
};

export default MissionDetails;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  borderRadius: "10px",
  marginTop: "20px",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // expandIcon={
    //   <ArrowForwardIosSharpIcon
    //     fontSize="small"
    //     sx={{ fontSize: "0.2rem", color: color }}
    //   />
    // }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
