import api from "./api";

const url = process.env.REACT_APP_API_URL + "Operation/";

export const fetchOperations = async (token, id) => {
  const result = await api
    .get(url + `getOperationsByUser/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchOperationsByRequest = async (token, id) => {
  const result = await api
    .get(url + `getOperationsByRequest/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const deleteOperation = async (token, id) => {
  const result = await api
    .delete(url + `deleteOperation/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchOperationsManager = async (token, id) => {
  const result = await api
    .get(url + `getOperationsByManager/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getRoomsOperations = async (rooms) => {
  const result = await api
    .post(url + `getRoomsOperations/`, rooms)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addRoomReservation = async (reservation) => {
  const result = await api
    .post(url + `addRoomReservation/`, reservation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addReservation = async (data) => {
  const result = await api
    .post(url + `addNewReservation`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const addReservations = async (data) => {
  const result = await api
    .post(url + `addNewReservations`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const checkAvailibility = async (user, date, slot, ressource) => {
  const result = await api
    .get(url + `checkReservation/${user}/${date}/${slot}/${ressource}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const ScanQR = async (user, qrCode) => {
  const result = await api
    .get(url + `ScanQR/${user}/${qrCode}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const checkUserReservation = async (user, date, slot, type) => {
  const result = await api
    .get(url + `checkUserReservation/${user}/${date}/${slot}/${type}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const bookDesk = async (id, start, end) => {
  return await api
    .post(url + `addReservations`, {
      ressource: id,
      // start: "2022-12-24",
      // end: "2022-12-25",
      start: start,
      end: end,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      // const errorMsg = error.message;
      return errorMsg;
    });

  // console.log("result", result);
};

export const fetchOperationsByUsers = async (users, startDate, endDate) => {
  const result = await api
    .post(url + `getOperations`, {
      users: users,
      startDate: startDate,
      endDate: endDate,
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchTeamOperationsByManager = async (id_manager, start, end) => {
  const result = await api
    .get(url + `getAllOperationsByManager/${id_manager}/${start}/${end}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchUsersWithNoTraceByManager = async (
  id_manager,
  start,
  end
) => {
  const result = await api
    .get(url + `getUsersWithNoTraceByManager/${id_manager}/${start}/${end}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const fetchPendingDays = async (id) => {
  const result = await api
    .get(url + `getNB_BalanceDaysByUser/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const getWFHOperationsDates = async (startDate, endDate) => {
  const result = await api
    .get(url + `getWFHOperationsDates/${startDate}/${endDate}`)
    .then((response) => {
      console.log("🚀 ~ .then ~ response:", response);
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });

  return result;
};

export const bookGuestDesk = async (data) => {
  return await api
    .post(url + `addGuestReservations`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      // const errorMsg = error.message;
      return errorMsg;
    });

  // console.log("result", result);
};

export const getReservationsByUser = async (id) => {
  const result = await api
    .get(url + `getReservationsByUser/${id}`)
    .then((response) => {
      console.log(":fusée: ~ .then ~ response:", response);
      return response.data.data;
    })
    .catch((error) => {
      const errorMsg = error.message;
      return errorMsg;
    });
  return result;
};
export const deleteManyOperations = async (ids) => {
  const result = await api
    .post(url + `deleteManyOperations`, ids)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      console.log("🚀 ~ deleteManyOperations ~ errorMsg:", errorMsg);
      return errorMsg;
    });
  return result;
};
