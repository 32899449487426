import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Typography,
} from "@material-tailwind/react";
import "./form.css";
import Close from "@mui/icons-material/Close";
import ConfirmDialog from "../ConfirmDialog";
import { deleteClaim } from "../../actions/RequestAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Icon } from "@material-tailwind/react";
// import { fetchOperationsByRequest } from '../../actions/OperationAction'
import moment from "moment";
import { Link } from "react-router-dom";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import jwt from "jwt-decode";
import { Support } from "@mui/icons-material";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";

export default function CardRequest(props) {
  const { data, setData } = props;
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);
  const [informations, setInformations] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const handleDelete = async (id) => {
    const result = await deleteClaim(id, user.id);

    setConfirmDialog({ isOpen: false });
    if (result.status === 200) {
      NotificationManager.success("Deleted successfully ");
      const newData = data.filter((item) => item._id !== id);
      setData(newData);
    } else {
      NotificationManager.error(result.message, "Error");
    }
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <Card className={isDesktop ? "width-Card" : "mt-5"}>
        <Link
          // to={{
          //   pathname: `/details_claim`,
          //   state: props.request,
          // }}
          to={"/details_claim"}
          state={props.request}
        >
          <CardBody>
            <div className="flex gap-8  mb-2">
              <Close
                className="close-btn"
                fontSize="small"
                htmlColor="red"
                onClick={() => {
                  setInformations(props.request);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete  this draft ?",
                    subtitle: "You can't undo this operation !",
                    onConfirm: () => {
                      handleDelete(props.request._id);
                    },
                  });
                }}
              />
            </div>

            <div className="card-title">
              <div>
                <div className="header">
                  <Support className="ml-1" fontSize="large" htmlColor="red" />

                  <p className="title">Support</p>
                </div>

                {/* <div className="title ml-15">
                {props.request.status === "processing" && (
                  <CardFooter color="green" amount="Processing"></CardFooter>
                )}

                {props.request.status === "pending" && (
                  <CardFooter color="orange" amount="Pending"></CardFooter>
                )}
                {props.request.status === "closed" && (
                  <CardFooter color="yellow" amount="Closed"></CardFooter>
                )}
              </div>

              <p className="title ml-15">
                Created at :{" "}
                {props.request?.createdAt
                  ?.substring(0, 10)
                  .split("-")
                  .reverse()
                  .join("-") +
                  " " +
                  props.request?.createdAt?.substring(11, 16)}
              </p> */}

                <div className="ml-15 title">
                  <Typography variant="small">
                    <i className="fas fa-paper-plane mr-2" />
                    {props.request.createdAt
                      .substring(0, 10)
                      .split("-")
                      .reverse()
                      .join("-") +
                      " " +
                      props.request.createdAt.substring(11, 16)}
                  </Typography>
                  {/* <UserIcon color="gray" className="h-4 w-4" /> */}
                  <Typography variant="small">
                    <i className="fas fa-user mr-2" />
                    {props.request.recipient}
                  </Typography>
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter className="flex items-center justify-between py-3">
            <Typography variant="small">
            
            </Typography>
            <Chip
              style={{
                float: "right",
                color:
                  props.request.status == "pending"
                    ? "#B78103"
                    : props.request.status == "closed"
                    ? "#b70303"
                    : "#229A16",
                // width: 50,
                // fontSize: 12,
                // height: 25,
                // "& svg, img": { width: 1, height: 1, objectFit: "cover" },
                backgroundColor:
                  props.request.status == "pending"
                    ? "rgba(255, 193, 7, 0.16)"
                    : props.request.status == "closed"
                    ? "rgba(255, 0, 0, 0.16)"
                    : "rgba(84, 214, 44, 0.16)",
              }}
              className="text-white"
              
              value={
                <Typography>
                {" "}
                      {props.request &&
                        props.request.status &&
                        (props.request.status === "processing"
                          ? <Translation message={"Processing"} />
                          : props.request.status === "closed"
                          ? <Translation message={"Closed"} />
                          : props.request.status === "pending"
                          ? <Translation message={"Pending"} />
                          : props.request.status)}{" "}
              </Typography>
              }
              // value={props.request.status}
            />
          </CardFooter>
        </Link>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        token={props.token}
        informations={informations}
        details={props.request.operations}
      />

      <NotificationContainer />
    </>
  );
}
