import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
  DayView,
  MonthView,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  WeekView,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import { locale, loadMessages } from "devextreme/localization";

import {
  data as appointments,
  // users
} from "./tasks";
import { withStyles } from "@material-ui/core/styles";
import DoneIcon from "@mui/icons-material/Done";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PendingIcon from "@mui/icons-material/Pending";
import EditIcon from "@mui/icons-material/Edit";

import Sick from "@mui/icons-material/Sick";
import BeachAccess from "@mui/icons-material/BeachAccess";
import HomeWork from "@mui/icons-material/HomeWork";
import Cached from "@mui/icons-material/Cached";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Place from "@mui/icons-material/Place";
import FormSick from "./FormSick";
import FormWorkHome from "./FormWorkHome";
import FormCasual from "./FormCasual";
import FormComponsatory from "./FormCompensatory";
import FlightIcon from "@mui/icons-material/Flight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HailIcon from "@mui/icons-material/Hail";
import { fetchTeamUsers } from "../../actions/UserAction";
import { useTranslation } from "react-i18next";
import Translation from "Translation";
import { AirplanemodeActive, WorkOff } from "@mui/icons-material";

const styles = (theme) => ({
  flexibleSpace: {
    flex: "none",
  },
  tooltip: {
    display: "flex",
    paddingLeft: "1.8em",
    paddingTop: "1em",
  },
  AppointmentContent: { backgroundColor: "#eeeeee" },
  tooltipHeader: {
    marginRight: "5em",
    marginTop: "-2.3em",
    "&:hover": {
      opacity: 0.8,
      cursor: "pointer",
    },
  },
});

const FlexibleSpace = withStyles(styles, { name: "ToolbarRoot" })(
  ({ classes, ...restProps }) => (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      <div className={classes.flexContainer}>
        {/*         
      <Sick fontSize="meduim" htmlColor="indigo" />
      <Typography variant="h6" style={{ marginLeft: '10px', color: "indigo" }}>My schedule</Typography>
      <BeachAccess fontSize="meduim" htmlColor="indigo" />
      <Typography variant="h6" style={{ marginLeft: '10px', color: "indigo" }}>My schedule</Typography> */}
      </div>
    </Toolbar.FlexibleSpace>
  )
);

const groupOrientation = (viewName) => viewName.split(" ")[0];
// const groupOrientation = viewName => viewName === 'Week' || viewName === 'Month'

const Schedule = (props) => {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const [data, setData] = React.useState(props.data);
  const [state, setState] = React.useState({ left: false });
  const [FormData, setFormData] = React.useState();
  const [visible, setVisibility] = useState();

  // const [users, setUsers] = useState (props.users)
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const toggleDrawer = (anchor, open, data) => (e) => {
    setState({ ...state, ["left"]: open });
    setFormData(data);
    setVisibility(false);
    setTimeout(() => {
      setVisibility();
    }, 2000);
  };

  // useEffect(async() => {
  //   var userDara = []
  //   const result = await fetchTeamUsers(token, storage.user._id)
  //   result.map(item => {
  //     userDara.push({
  //       text: item.firstname + ' ' + item.lastname,
  //       id : item._id,
  //       color: '#e8eaed'
  //     })
  //   })
  //   // setUsers(userDara)
  // }, []);

  const grouping = [
    {
      resourceName: "userId",
    },
  ];

  const resources = [
    {
      fieldName: "userId",
      title: "User",
      instances: props.users,
    },
  ];

  // const fetchRessources = async () => {
  //   var userData = []
  //   var ressources = []
  //   const result = await fetchTeamUsers(token, storage.user._id)
  //   result.map(item => {
  //     userData.push({
  //       text: item.firstname + ' ' + item.lastname,
  //       id : item._id,
  //       color: '#e8eaed'
  //     })
  //   })
  //   ressources.push({
  //     fieldName: 'userId',
  //     title: 'User',
  //     instances: userData,

  //   })
  // }

  const onCommitChanges = React.useCallback(
    ({ added, changed, deleted }) => {
      if (added) {
        const startingAddedId =
          data.length > 0 ? data[data.length - 1].id + 1 : 0;
        setData([...data, { id: startingAddedId, ...added }]);
      }
      if (changed) {
        setData(
          data.map((appointment) =>
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment
          )
        );
      }
      if (deleted !== undefined) {
        setData(data.filter((appointment) => appointment.id !== deleted));
      }
    },
    [setData, data]
  );

  const Content = withStyles(styles, { name: "Content" })(
    ({ children, appointmentData, classes, ...restProps }) => (
      <>
        <AppointmentTooltip.Header
          {...restProps}
          appointmentData={appointmentData}
        >
          <div className={classes.tooltipHeader}>
            {/* {console.log(appointmentData.id)} */}
            {/* <Link to={{
            pathname: `/request/${appointmentData.id}`,
            requestProps: appointmentData
          }}> */}

            {/* {appointmentData.title === "WFH" && (
              <EditIcon
                htmlColor="#767676"
                onClick={toggleDrawer("left", true, appointmentData)}
              />
            )} */}
            {/* </Link> */}
          </div>
        </AppointmentTooltip.Header>

        <AppointmentTooltip.Content
          headerTitle={"Nouveau titre de tooltip"}
          {...restProps}
          appointmentData={appointmentData}
        >
          <div className={classes.tooltip}>
            {appointmentData.name === "WFH" ||
            appointmentData.name === "Client site" ||
            appointmentData.name === "REMOTE_WORKING" ||
            appointmentData.name === "Leave" ||
            appointmentData.title === "TRAVEL" ? (
              appointmentData.status === "accepted" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#1bcc64",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "2em", marginBottom: "1em" }}>
                    <Translation message="Approved" />
                  </p>
                </>
              ) : appointmentData.status === "refused" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#ed2121",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "2em", marginBottom: "1em" }}>
                    <Translation message="Rejected" />
                  </p>
                </>
              ) : appointmentData.status === "pending" ? (
                <>
                  <span
                    style={{
                      height: "12px",
                      width: "12px",
                      backgroundColor: "#f0dc2b",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  <p style={{ paddingLeft: "2em", marginBottom: "1em" }}>
                    <Translation message="Pending" />
                  </p>
                </>
              ) : (
                ""
              )
            ) : appointmentData.name === "RESERVATION" ||
              appointmentData.name === "MEETING" ? (
              <>
                <Place fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "2em", marginBottom: "1em" }}>
                  {appointmentData.desk}
                </p>
              </>
            ) : appointmentData.name === "Client site" ? (
              <>
                <Place fontSize="small" htmlColor="#767676" />
                <p style={{ paddingLeft: "2em", marginBottom: "1em" }}>
                  {appointmentData.client}
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </AppointmentTooltip.Content>
      </>
    )
  );

  const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
    ({ classes, ...restProps }, style) => (
      <Appointments.AppointmentContent
        {...restProps}
        className={classes.AppointmentContent}
      >
        <div className={restProps.container}>
          {(restProps.data.name === "WFH" ||
            restProps.data.name === "REMOTE_WORKING") && (
            <HomeWork fontSize="medium" htmlColor="#2ec6ff" />
          )}

          {restProps.data.name === "TRAVEL" && (
            <AirplanemodeActive fontSize="medium" htmlColor="#de62b4" />
          )}

          {restProps.data.name === "Leave" && (
            <WorkOff fontSize="medium" htmlColor="#c6d13e" />
          )}
          {restProps.data.name === "RESERVATION" && (
            <ApartmentIcon fontSize="medium" htmlColor="#5262ff" />
          )}
          {restProps.data.name === "Client site" && (
            <HailIcon fontSize="medium" htmlColor="#b741c4" />
          )}

          <div style={{ position: "absolute", top: "5px", right: "8px" }}>
            {(restProps.data.status === "accepted" ||
              restProps.data.status === "ACTIVE") && (
              <span
                style={{
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#12e669",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
            )}
            {restProps.data.status === "refused" && (
              <span
                style={{
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#cc002c",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
            )}
            {restProps.data.status === "pending" && (
              <span
                style={{
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#f7e223",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></span>
            )}
          </div>
        </div>
      </Appointments.AppointmentContent>
    )
  );

  const getWorkWeekStartAndEndDates = (date) => {
    const workDays = [1, 2, 3, 4, 5];
    const dayOfWeek = date.getDay();
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - dayOfWeek);
    if (!workDays.includes(startDate.getDay())) {
      startDate.setDate(startDate.getDate() + 1);
    }
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 5);

    return { startDate, endDate };
  };

  const handleCurrentDateChange = (currentDate) => {
    const { startDate, endDate } = getWorkWeekStartAndEndDates(currentDate);
    props.setStartDate(startDate.toISOString().substring(0, 10));
    props.setEndDate(endDate.toISOString().substring(0, 10));
  };

  return (
    <Paper style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <Scheduler data={data} height={660} locale={i18n.language}>
        <ViewState
          defaultCurrentDate={Date.now()}
          onCurrentDateChange={handleCurrentDateChange}

          // onCurrentDateChange={handleViewChange}
        />
        <EditingState onCommitChanges={onCommitChanges} />
        <GroupingState
          grouping={grouping}
          groupOrientation={groupOrientation}
        />

        <WeekView
          startDayHour={8}
          endDayHour={18}
          excludedDays={[0, 6]}
          // cellDuration={120}
          cellDuration={360}
          name="Vertical"
        />
        <WeekView
          startDayHour={8}
          endDayHour={18}
          excludedDays={[0, 6]}
          cellDuration={120}
          name="Horizontal"
        />

        <AllDayPanel />

        <Appointments appointmentContentComponent={AppointmentContent} />
        <Resources
          data={resources}
          // data={fetchRessources}
          mainResourceName="userId"
        />

        <IntegratedGrouping />
        <IntegratedEditing />
        <AppointmentTooltip
          contentComponent={Content}
          showCloseButton
          visible={visible}
        />

        <GroupingPanel />

        <Toolbar />

        <ViewSwitcher />
        <DateNavigator />
      </Scheduler>

      <React.Fragment key={"left"}>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {FormData && FormData.name === "WFH" ? (
            <FormWorkHome data={FormData} setVisibility={setVisibility} />
          ) : FormData && FormData.title === "Casual" ? (
            <FormCasual data={FormData} setVisibility={setVisibility} />
          ) : FormData && FormData.title === "Sick" ? (
            <FormSick
              data={FormData}
              setVisibility={setVisibility}
              visible={visible}
            />
          ) : FormData && FormData.title === "Compensatory" ? (
            <FormComponsatory data={FormData} setVisibility={setVisibility} />
          ) : (
            ""
          )}
        </SwipeableDrawer>
      </React.Fragment>
    </Paper>
  );
};

export default Schedule;
