import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { KeyboardArrowDown, Done } from "@mui/icons-material";

import { Button, Typography, Textarea, Input } from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./form.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import StaticDatePicker from "@mui/lab/StaticDatePicker";
// import PickersDay from "@mui/lab/PickersDay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import HomeWork from "@mui/icons-material/HomeWork";
import {
  fetchValidators,
  fetchUser,
  fetchALLUsers,
  fetchUserBalances,
  fetchBalances,
} from "../../actions/UserAction";
import { addRequest } from "../../actions/RequestAction";
import NotifPerson from "./NotifPerson";
import PersonIcon from "@mui/icons-material/Person";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import Box from "@mui/material/Box";
import moment from "moment";
import jwt from "jwt-decode";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import { DateRange } from "react-date-range";
import { addDays, format } from "date-fns";

import "./style.css";
import useMediaQuery from "useMediaQuery";
import { Hail } from "@mui/icons-material";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/fr";
dayjs.extend(isBetweenPlugin);

const color = "#294e87";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function TeleworkForm() {
  const [value, setValue] = React.useState(dayjs());
  const [selectedDates, setSelectedDates] = useState([]);
  const [checkedAM, setcheckedAM] = useState([]);
  const [checkedPM, setcheckedPM] = useState([]);
  const [checkedFull, setcheckedFull] = useState([]);
  const [selectedAM, setselectedAM] = useState([]);
  const [selectedPM, setselectedPM] = useState([]);
  const [selectedFull, setselectedFull] = useState([]);

  const [lastDate, setlastDate] = useState();
  const [managers, setManagers] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setloading] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [nbrWeek, setNbrWeek] = useState();
  const [nbrMonth, setNbrMonth] = useState();
  const [nbrMonthTwo, setNbrMonthTwo] = useState();

  const [personNotif, setPersonNotif] = useState([]);
  const [weekBalance, setWeekBalance] = useState();
  const [monthBalance, setMonthBalance] = useState();

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const decodedToken = jwt(token);

  const [week, setWeek] = useState();
  const [month, setMonth] = useState();
  const [monthTwo, setMonthTwo] = useState();
  const [diff, setDiff] = useState(true);
  const [comment, setComment] = useState("");
  const [client, setClient] = useState("");

  const [firstMonth, setFirstMonth] = useState("");
  const [secondMonth, setSecondMonth] = useState("");
  const [isManager, setIsManager] = useState(decodedToken.role === "manager");

  const [type, setType] = useState("day");
  const { i18n } = useTranslation();

  var tab = [];
  var startDate;
  var endDate;
  var startWeek = startOfWeek(new Date());
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [slots, setslots] = useState([]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const getDate = (date) => {
    setlastDate(date);
  };

  const handleSlots = async (event, newslots) => {
    await setslots(newslots);
  };

  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  useEffect(() => {
    const start = selectedDates[0] && selectedDates[0].date;
    var end =
      selectedDates[selectedDates.length - 1] &&
      selectedDates[selectedDates.length - 1].date;
    if (start) {
      if (start.substring(5, 7) === end.substring(5, 7)) {
        setDiff(false);
      } else {
        setDiff(true);
        setFirstMonth(startDate.toLocaleString("en-GB", { month: "long" }));
        setSecondMonth(endDate.toLocaleString("en-GB", { month: "long" }));
        // end.substring(5,7).charAt(0) === 0 ? setMonthTwo(end.substring(6,7)) : setMonthTwo(end.substring(5,7))
        // console.log(end.substring(6,7))
        // setMonthTwo(end.substring(5,7).charAt(0))
      }
    }
  }, [selectedDates]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = value.startOf("week");
    const end = value.endOf("week");
    var result = getWeekNumber(new Date(end));
    setWeek(result);
    setMonth(new Date(start).getMonth() + 1);

    const dayIsBetween = date.isBetween(start, end, null, "[]");
    const isFirstDay = date.isSame(start, "day");
    const isLastDay = date.isSame(end, "day");

    startDate = new Date(start);
    endDate = new Date(end);

    if (endDate.getMonth() + 1 != startDate.getMonth() + 1) {
      setMonthTwo(endDate.getMonth() + 1);
    }

    // const startWeek =  selectedDates[0] && selectedDates[0].date
    // var endWeek = selectedDates[selectedDates.length-1] && selectedDates[selectedDates.length-1].date
    //  if (startWeek) {
    //    if (startWeek.substring(5,7) === endWeek.substring(5,7) ) {
    //      setDiff(false)
    //      } else {
    //          setDiff( true)
    //          setFirstMonth(startDate.toLocaleString('en-GB', { month: 'long' }))
    //          setSecondMonth(endDate.toLocaleString('en-GB', { month: 'long' }))
    //          setMonthTwo(end.getMonth()+1)

    //      }
    //    }

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  function SortArray(x, y) {
    if (x.firstname < y.firstname) {
      return -1;
    }
    if (x.firstname > y.firstname) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    const fetchData = async () => {
      const user = await fetchUser(token, decodedToken.id);
      setUser(user);
      const result = await fetchValidators(token);
      var listValidators = result
        .filter((item) => item._id !== user._id)
        .sort(SortArray);
      setManagers(listValidators);

      const users = await fetchALLUsers(token);
      setUsers(users.sort(SortArray));
      user.manager &&
        setSelected(result.find((item) => item._id === user.manager._id));
      const data = await fetchBalances(token);
      setWeekBalance(data?.WFHweekBalance);
      setMonthBalance(data?.WFHmonthBalance);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const balances = await fetchUserBalances(token, decodedToken.id);
      const valueWeek = balances[0]?.WFHweekBalance?.find(
        (element) => element.nb === week
      );
      const valueMonth =
        balances &&
        balances[0]?.WFHmonthBalance.find((element) => element.nb === month);

      if (monthTwo) {
        const valueMonthTwo = balances[0]?.WFHmonthBalance.find(
          (element) => element.nb === parseInt(endDate.getMonth() + 1)
        );
        valueMonthTwo && setNbrMonthTwo(valueMonthTwo.count);
      }
      valueWeek && setNbrWeek(valueWeek.count);
      valueMonth && setNbrMonth(valueMonth.count);
    };
    fetchData();
  }, [selectedDates, week, monthTwo]);

  function getWeekdayNames(startDate, endDate, weekdays) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // Ignorer les samedis (dayOfWeek = 6) et les dimanches (dayOfWeek = 0)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        dates.push({
          day: weekdays[dayOfWeek],
          // date: new Date(currentDate),
          date: format(currentDate, "yyyy-MM-dd"),
          value: `${weekdays[dayOfWeek]} ${format(currentDate, "dd-MM-yyyy")} `,
        });
      }

      currentDate.setDate(currentDate.getDate() + 1); // Passer au jour suivant
    }

    return dates;
  }

  useEffect(() => {
    setselectedAM([]);
    setselectedPM([]);
    setselectedFull([]);
    const frDays = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    const enDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (i18n.language == "fr") {
      setSelectedDates(getWeekdayNames(startDate, endDate, frDays));
      var tab = getWeekdayNames(startDate, endDate, frDays);
    } else {
      setSelectedDates(getWeekdayNames(startDate, endDate, enDays));
      var tab = getWeekdayNames(startDate, endDate, enDays);
    }

    // const weekdaysInRange = getWeekdayNames(startDate, endDate);

    // console.log("weekdaysInRange", weekdaysInRange);

    // while (d < endDate) {
    //   const date = d.toISOString().substring(0, 10);
    //   console.log("date", date);
    //   const str =
    //     i18n.language == "fr"
    //       ? DAYSFr[d.getDay()] + " " + date.split("-").reverse().join("-")
    //       : DAYSEng[d.getDay()] + " " + date.split("-").reverse().join("-");
    //   var obj = {
    //     date: date,
    //     value: str,
    //   };

    //   console.log("obj", obj);

    //   tab.push(obj);

    //   d = new Date(d.getTime() + 24 * 60 * 60 * 1000);
    // }
    // console.log("taaaaaaaaaaaab", tab);
    // // setSelectedDates(tab.slice(2));
    // setSelectedDates(tab.slice(1, tab.length - 1));

    // setSelectedDates(tab.slice(2));
    tab.length > 0 && setcheckedAM(new Array(tab.length).fill(false));
    tab.length > 0 && setcheckedPM(new Array(tab.length).fill(false));
    tab.length > 0 && setcheckedFull(new Array(tab.length).fill(false));
    setslots([]);
  }, [value, i18n.language]);

  const handleChange = (event, newType) => {
    setType(newType);
  };

  const handleChangeAM = (position) => {
    const updatedCheckedState = checkedAM.map((item, index) =>
      index === position ? !item : item
    );

    setcheckedAM(updatedCheckedState);
    // if (checkedPM[position] === false ){
    if (checkedAM[position] === false) {
      setNbrWeek(nbrWeek + 0.5);

      var d = new Date(selectedDates[position].date);

      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth + 0.5);
      } else {
        setNbrMonthTwo(nbrMonthTwo + 0.5);
      }
    } else {
      var d = new Date(selectedDates[position].date);
      setNbrWeek(nbrWeek - 0.5);
      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth - 0.5);
      } else {
        setNbrMonthTwo(nbrMonthTwo - 0.5);
      }
    }
    // }
    setselectedAM([]);
    const slot = updatedCheckedState.reduce(
      (selectedAM, currentState, index) => {
        if (currentState === true) {
          setselectedAM((selectedAM) => [
            ...selectedAM,
            selectedDates[index].date,
          ]);
        }
      },
      0
    );
  };

  const handleChangePM = (position) => {
    const updatedCheckedState = checkedPM.map((item, index) =>
      index === position ? !item : item
    );

    setcheckedPM(updatedCheckedState);
    // if (checkedAM[position] == false ){
    if (checkedPM[position] == false) {
      setNbrWeek(nbrWeek + 0.5);
      var d = new Date(selectedDates[position].date);

      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth + 0.5);
      } else {
        setNbrMonthTwo(nbrMonthTwo + 0.5);
      }
    } else {
      setNbrWeek(nbrWeek - 0.5);
      var d = new Date(selectedDates[position].date);

      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth - 0.5);
      } else {
        setNbrMonthTwo(nbrMonthTwo - 0.5);
      }
    }
    // }

    setselectedPM([]);
    const slot = updatedCheckedState.reduce(
      (selectedPM, currentState, index) => {
        if (currentState === true) {
          setselectedPM((selectedPM) => [
            ...selectedPM,
            selectedDates[index].date,
          ]);
        }
      },
      0
    );
  };

  const handleChangeFULL = (position) => {
    const updatedCheckedState = checkedFull.map((item, index) =>
      index === position ? !item : item
    );

    setcheckedFull(updatedCheckedState);
    // if (checkedAM[position] == false ){
    if (checkedFull[position] == false) {
      setNbrWeek(nbrWeek + 1);
      var d = new Date(selectedDates[position].date);

      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth + 1);
      } else {
        setNbrMonthTwo(nbrMonthTwo + 1);
      }
    } else {
      setNbrWeek(nbrWeek - 1);
      var d = new Date(selectedDates[position].date);

      if (d.getMonth() + 1 === month) {
        setNbrMonth(nbrMonth - 1);
      } else {
        setNbrMonthTwo(nbrMonthTwo - 1);
      }
    }
    // }

    setselectedFull([]);
    const slot = updatedCheckedState.reduce(
      (selectedFull, currentState, index) => {
        if (currentState === true) {
          setselectedFull((selectedFull) => [
            ...selectedFull,
            selectedDates[index].date,
          ]);
        }
      },
      0
    );
  };

  const handleSend = async () => {
    var data;
    if (!selected || Object.keys(selected).length === 0) {
      NotificationManager.warning(
        <Translation message={"Please choose your validator"} />
      );
      return;
    }

    if (personNotif.length > 0) {
      var usersNotif = Array.from(personNotif, ({ _id }) => _id);
    } else {
      var usersNotif = [];
    }

    if (
      selectedAM.length === 0 &&
      selectedPM.length === 0 &&
      selectedFull.length === 0
    ) {
      NotificationManager.warning(
        <Translation message={"Please choose the slots"} />
      );
      return;
    } else {
      var dates = [];
      // slots.map((slot) => {
      //   dates.push({
      //     day: slot.slice(0, 10),
      //     slot: slot.split(" ").pop(),
      //   });
      // });

      selectedAM.length > 0 &&
        selectedAM.map((date) => {
          dates.push({
            day: date.slice(0, 10),
            slot: "AM",
          });
        });
      selectedPM.length > 0 &&
        selectedPM.map((date) => {
          dates.push({
            day: date.slice(0, 10),
            slot: "PM",
          });
        });
      selectedFull.length > 0 &&
        selectedFull.map((date) => {
          dates.push({
            day: date.slice(0, 10),
            slot: "AM",
          });
          dates.push({
            day: date.slice(0, 10),
            slot: "PM",
          });
        });

      data = {
        name: "Client site",
        idSender: user._id,
        idReciever: selected._id,
        UserNotif: usersNotif,
        date: dates,
        commentUser: comment,
        client: client,
      };
    }

    setloading(true);
    const result = await addRequest(token, data);

    setloading(false);
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        setTimeout(() => {
          window.location.href = "/requests";
        }, 2000);
      }
    } else {
      NotificationManager.error(result.message);
    }
  };

  return (
    <>
      <div className="flex flex-wrap justify-center mt-20">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <>
                <div className="w-full text-center">
                  <Hail style={{ color: color }} fontSize="large" />

                  <Typography variant="h3" style={{ color: color }}>
                    <Translation
                      message={
                        "Are you planning to work from a third party site?"
                      }
                    />
                  </Typography>

                  <Typography variant="small" color="gray" className="mt-2">
                    <Translation
                      message={"Complete this form to send a request."}
                    />
                  </Typography>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="flex gap-8 mt-16 mb-12">
                    <div className="w-150">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Validator"} />
                      </Typography>
                    </div>
                    <Listbox
                      value={
                        selected && selected.firstname + " " + selected.lastname
                      }
                      onChange={setSelected}
                    >
                      {/* <Listbox.Label className="block text-md text-gray-700 mt-4">Manager</Listbox.Label> */}
                      <div className="mt-1 relative">
                        <Listbox.Button className="relative w-300 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="flex items-center">
                            {selected && selected.photo ? (
                              <img
                                src={
                                  process.env.REACT_APP_UPLOADS_URL +
                                  selected.photo
                                }
                                alt=""
                                className="flex-shrink-0 h-6 w-6 rounded-full"
                              />
                            ) : (
                              <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                <PersonIcon style={{ color: "gray" }} />{" "}
                              </div>
                            )}
                            {/* <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpcLzYU8SsybUPTpqpI01wbVK1Ysqi5FU98w&usqp=CAU' alt="" className="flex-shrink-0 h-6 w-6 rounded-full" /> */}
                            <span className="ml-3 block truncate">
                              {selected && selected.firstname}{" "}
                              {selected && selected.lastname}
                            </span>
                          </span>
                          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            {/* <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            /> */}
                            <KeyboardArrowDown
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-300 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {managers.map((person) => (
                              <Listbox.Option
                                key={person._id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={person}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      {person && person.photo ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_UPLOADS_URL +
                                            person.photo
                                          }
                                          alt=""
                                          className="flex-shrink-0 h-6 w-6 rounded-full"
                                        />
                                      ) : (
                                        <div className="flex-shrink-0 h-6 w-6 rounded-full">
                                          <PersonIcon
                                            style={{ color: "gray" }}
                                          />{" "}
                                        </div>
                                      )}

                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {person.firstname} {person.lastname}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        {/* <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        /> */}
                                        <Done
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div className="flex gap-8 mt-16 mb-12">
                    <div className="w-150">
                      <Typography className="text-sm text-gray-700 pt-1">
                        <Translation message={"Persons to notify"} />
                      </Typography>
                    </div>
                    <NotifPerson
                      users={users}
                      setPersonNotif={setPersonNotif}
                    />
                  </div>

                  <div className="flex gap-8 mt-16">
                    <div className="w-150">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Week"} />
                      </Typography>
                    </div>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n?.language}
                    >
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        label="Week picker"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                          setNbrWeek(3);
                        }}
                        renderDay={renderWeekPickerDay}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="'Week of' MMM d"
                        minDate={startWeek}
                        // maxDate={lastDayOfMonth}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="flex gap-8 mb-12">
                    <div className="w-150">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        <Translation message={"Days"} />
                      </Typography>
                    </div>

                    <FormGroup>
                      {selectedDates &&
                        selectedDates.map((day, index) => {
                          if (day.date < today.toISOString().substring(0, 10)) {
                            return (
                              <>
                                <div className="flex gap-8">
                                  <div className="w-250">
                                    <Typography className=" text-sm  text-gray-700 pt-1">
                                      {day.value}
                                    </Typography>
                                  </div>
                                  <ToggleButtonGroup
                                    color="primary"
                                    className="mt-1"
                                  >
                                    <ToggleButton value="sick" disabled>
                                      <p htmlColor="#083985">AM</p>
                                    </ToggleButton>
                                    <ToggleButton value="casual" disabled>
                                      <p htmlColor="#083985"> PM </p>
                                    </ToggleButton>
                                    <ToggleButton value="casual" disabled>
                                      <p htmlColor="#083985"> Full </p>
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <div className="flex gap-8">
                                <div className="w-250">
                                  <Typography className=" text-sm  text-gray-700 pt-1">
                                    {day.value}
                                  </Typography>
                                </div>
                                <ToggleButtonGroup
                                  color="primary"
                                  // value={slots}
                                  // onChange={handleSlots}
                                  aria-label="text formatting"
                                  className="mt-1"
                                  // exclusive
                                  // onClick={() => {
                                  //   getDate(day.date);
                                  // }}
                                >
                                  <ToggleButton
                                    selected={checkedAM[index]}
                                    onClick={() => handleChangeAM(index)}
                                    value={`${day.date} AM`}
                                    // value={"AM"}
                                    style={{ outline: "none" }}
                                    disabled={
                                      selectedPM.includes(day.date) ||
                                      selectedFull.includes(day.date)
                                    }
                                  >
                                    AM
                                  </ToggleButton>
                                  <ToggleButton
                                    selected={checkedPM[index]}
                                    onClick={() => handleChangePM(index)}
                                    value={`${day.date} PM`}
                                    // value="PM"
                                    style={{ outline: "none" }}
                                    disabled={
                                      selectedAM.includes(day.date) ||
                                      selectedFull.includes(day.date)
                                    }
                                  >
                                    PM
                                  </ToggleButton>
                                  <ToggleButton
                                    selected={checkedFull[index]}
                                    onClick={() => {
                                      handleChangeFULL(index);
                                    }}
                                    // value="Full"
                                    // value={`${day.date} FULL`}
                                    value={[`${day.date} AM`, `${day.date} PM`]}
                                    style={{ outline: "none" }}
                                    disabled={
                                      selectedPM.includes(day.date) ||
                                      selectedAM.includes(day.date)
                                    }
                                  >
                                    FULL
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </div>
                            );
                          }
                        })}
                    </FormGroup>
                  </div>

                  <Input
                    color="indigo"
                    variant="static"
                    // placeholder="Client"
                    label={<Translation message={"Client"} />}
                    value={client}
                    onChange={(e) => {
                      setClient(e.target.value);
                    }}
                  />

                  <div className="mt-20">
                    <Textarea
                      color="indigo"
                      variant="static"
                      label={<Translation message={"Comment"} />}
                      // placeholder="Leave a comment here"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex justify-center mt-20">
                    <Button
                      style={{ backgroundColor: "#083985" }}
                      ripple="light"
                      onClick={() => {
                        handleSend();
                      }}
                    >
                      <ClipLoader color="white" loading={loading} size={20} />
                      <Translation message={"Send Request"} />
                    </Button>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
      </div>

      <NotificationContainer />
    </>
  );
}
