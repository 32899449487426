import {
  IconButton,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import Translation from "Translation";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import useMediaQuery from "useMediaQuery";
import MissionsCard from "./MissionsCard";
import { MdOutlinePending, MdOutlinePendingActions } from "react-icons/md";
import { FaArchive, FaHistory } from "react-icons/fa";
import OngoingMissions from "./OngoingMissions";
import { RadioGroup } from "@headlessui/react";
import History from "./History";
import { Link } from "react-router-dom";

const color = "#083985";

const MissionsList = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const tabs = [
    {
      label: "Ongoing Missions",
      value: "pending",
      desc: <OngoingMissions />,
      icon: <MdOutlinePending color={color} size={20} />,
    },
    {
      label: "Closed missions",
      icon: <FaHistory color={color} size={20} />,
      value: "history",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
  ];

  const [type, setType] = useState(
    window.location.hash.indexOf("/pending") != -1 ? "pending" : "history"
  );
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation
                      message={"Follow up on your mission requests"}
                    />
                  </Typography>
                  <Typography color="gray">
                    <Translation message={"Check your requests' status"} />
                  </Typography>
                </div>
              </div>

              <div className="flex justify-center mt-2 mb-5">
                <RadioGroup
                  value={type}
                  onChange={(e) => {
                    setType(e);
                  }}
                >
                  <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 lg:grid-cols-2">
                    <a href="#/pending">
                      <RadioGroup.Option
                        key={"pending"}
                        value={"pending"}
                        className={({ active }) =>
                          classNames(
                            "bg-white shadow-sm text-gray-900 cursor-pointer",
                            active ? "ring-2 ring-indigo-500" : "",
                            "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                        title="Pending"
                        // onClick={() => {
                        //   setType("list");
                        // }}
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              <MdOutlinePending color={color} />
                              <span
                                className={classNames(
                                  type == "pending" ? "border" : "border-2",
                                  type == "pending"
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                    </a>
                    <a href="#/history">
                      <RadioGroup.Option
                        key={"history"}
                        value={"history"}
                        // onClick={() => {
                        //   setType("calendar");
                        // }}
                        className={({ active }) =>
                          classNames(
                            "bg-white shadow-sm text-gray-900 cursor-pointer",
                            active ? "ring-2 ring-indigo-500" : "",
                            "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                        title="History"
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              {/* <CalendarMonthIcon htmlColor={color} /> */}
                              <FaArchive color={color} />
                              <span
                                className={classNames(
                                  type == "history" ? "border" : "border-2",
                                  type == "history"
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                    </a>
                  </div>
                </RadioGroup>
              </div>
              <div className="flex justify-end">
                <Link to="/travel">
                  <IconButton className="rounded-full" color="indigo">
                    <i className="fas fa-plus" />
                  </IconButton>
                </Link>
              </div>

              {window.location.hash.indexOf("/pending") != -1 && (
                <OngoingMissions />
              )}
              {window.location.hash.indexOf("/history") != -1 && <History />}

              {/* <div
                className={
                  isDesktop
                    ? "flex flex-wrap justify-center flex-grow-3 gap-8 mt-16"
                    : "justify-center flex-grow-3 gap-8 mt-16 mb-12"
                }
              >
                <Tabs value={"pending"}>
                  <div
                    className={
                      isDesktop
                        ? "flex flex-wrap justify-center "
                        : "justify-center 2"
                    }
                  >
                    <TabsHeader
                      className="bg-transparent max-w-[96rem]"
                      // className="bg-transparent"
                      indicatorProps={{
                        className: "bg-gray-900/10 shadow-none !text-gray-900",
                      }}
                    >
                      {tabs.map(({ label, value, icon }) => (
                        <Tab key={value} value={value}>
                          <div className="flex gap-5 w-70">
                            {icon} {label}
                          </div>
                        </Tab>
                      ))}
                    </TabsHeader>
                  </div>
                  <TabsBody>
                    {tabs.map(({ value, desc }) => (
                      <TabPanel key={value} value={value}>
                        {desc}
                      </TabPanel>
                    ))}
                  </TabsBody>
                </Tabs>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionsList;
