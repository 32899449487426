import React, { useEffect, useState } from "react";
import Card from "./Card";
import { Typography } from "@material-tailwind/react";
import Box from "@mui/material/Box";

import "./form.css";

import { fetchRequestsByUser } from "actions/RequestAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import jwt from "jwt-decode";
import { Hail, HomeWork } from "@mui/icons-material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ClipLoader from "react-spinners/ClipLoader";
import useMediaQuery from "useMediaQuery";
// import { featuresData } from "data";
import { Link, useNavigate } from "react-router-dom";
import Translation from "Translation";
import { getMyRequests } from "actions/RequestAction";
import { useTranslation } from "react-i18next";
import { fetchSetting } from "actions/SettingAction";
import IconComponent from "components/IconComponent";

const color = "#083985";
const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  ru: "__.__.____",
  de: "__.__.____",
};

// const actions = [
//   { icon: <HomeWork />, name: "WFH", color: "#2ec6ff", href: "/onlinework" },

//   {
//     icon: <Hail />,
//     name: "Third party site",
//     color: "#b741c4",
//     href: "/thirdpartysite_request",
//   },
// ];

export default function Requests() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const [data, setData] = useState();
  const [dataFiltred, setDataFiltred] = useState();
  const [status, setStatus] = useState(null);
  const [date, setDate] = useState(null);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [features, setFeatures] = useState([]);
  const [featuresFilter, setFeaturesFilter] = useState([]);

  // console.log(manager)
  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);
      try {
        // const result = await fetchRequestsByUser(token, user.id);
        const result = await getMyRequests(user.id);

        setData(result);
        const sortedResult = result?.sort(
          (objA, objB) =>
            Number(new Date(objB._id.updatedAt)) -
            Number(new Date(objA._id.updatedAt))
        );
        setDataFiltred(sortedResult);
        // setManager(result[0].idReciever.firstname + ' ' + result[0].idReciever.lastname )
        // setManagers(Array.from(result, ({idReciever}) => idReciever.firstname + ' ' + idReciever.lastname ))
        const array = Array.from(
          result,
          ({ idReciever }) => idReciever?.firstname + " " + idReciever?.lastname
        );
        // setManagers(array.filter(item => item ))
        setManagers([...new Set(array)]);
        setLoading(false);
        const res = await fetchSetting("features");
        setFeatures(res[0]?.Web);
        const filters = [];
        res[0]?.Web?.map((item) => {
          if (item.name == "Third party site")
            filters.push({ value: "Client site", name: item.name });
          else if (item.name == "Leave")
            filters.push({ value: "Leave", name: item.name });
          else if (item.name == "WFH")
            filters.push(
              { value: "WFH", name: item.name },
              { value: "REMOTE_WORKING", name: "Exceptional WFH" }
            );
        });
        filters.push({ value: null, name: "ALL" });
        setFeaturesFilter(filters);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log("🚀 ~ fetchData ~ filters:", featuresFilter);

  useEffect(() => {
    if (status !== null && date === null && manager === null && type === null) {
      data && setDataFiltred(data.filter((item) => item._id.status === status));
    } else if (
      status === null &&
      date === null &&
      manager === null &&
      type === null
    ) {
      setDataFiltred(data);
    } else if (
      status === null &&
      date !== null &&
      manager === null &&
      type === null
    ) {
      function getDate(element) {
        return (
          element._id.updatedAt.substring(0, 10) ===
          date.toISOString().substring(0, 10)
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status !== null &&
      date !== null &&
      manager === null &&
      type === null
    ) {
      function getDate(element) {
        return (
          element._id.updatedAt.substring(0, 10) ===
            date.toISOString().substring(0, 10) && element._id.status === status
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status !== null &&
      date !== null &&
      manager !== null &&
      type === null
    ) {
      function getDate(element) {
        return (
          element._id.updatedAt.substring(0, 10) ===
            date.toISOString().substring(0, 10) &&
          element._id.status === status &&
          element.idReciever.firstname + " " + element.idReciever.lastname ===
            manager
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status === null &&
      date !== null &&
      manager !== null &&
      type == null
    ) {
      function getDate(element) {
        return (
          element._id.updatedAt.substring(0, 10) ===
            date.toISOString().substring(0, 10) &&
          element.idReciever.firstname + " " + element.idReciever.lastname ===
            manager
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status === null &&
      date === null &&
      manager !== null &&
      type === null
    ) {
      function getDate(element) {
        return (
          element.idReciever.firstname + " " + element.idReciever.lastname ===
          manager
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status !== null &&
      date === null &&
      manager !== null &&
      type === null
    ) {
      function getDate(element) {
        return (
          element.idReciever.firstname + " " + element.idReciever.lastname ===
            manager && element._id.status === status
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status !== null &&
      date === null &&
      manager !== null &&
      type !== null
    ) {
      function getDate(element) {
        return (
          element.idReciever.firstname + " " + element.idReciever.lastname ===
            manager &&
          element._id.status === status &&
          element._id.name === type
        );
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status == null &&
      date == null &&
      manager == null &&
      type !== null
    ) {
      function getDate(element) {
        return element._id.name == type;
      }
      var filtre = data?.filter(getDate);
      setDataFiltred(filtre);
    } else if (
      status != null &&
      date == null &&
      manager == null &&
      type !== null
    ) {
      function getDate(element) {
        return element._id.name == type && element._id.status === status;
      }
      var filtre = data.filter(getDate);
      setDataFiltred(filtre);
    }
  }, [status, date, manager, type]);

  const isDesktop = useMediaQuery("(min-width: 960px)");
  const navigate = useNavigate();

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    {/* Follow up on your requests */}
                    <Translation message={"Follow up on your requests"} />
                  </Typography>
                  <Typography color="gray">
                    <Translation message={"Check your requests' status"} />
                  </Typography>
                </div>
              </div>
              <div className="flex justify-center flex-wrap gap-10">
                <div>
                  <FormControl sx={{ m: 1, minWidth: 80 }}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      label="Type"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      {featuresFilter.map((item) => {
                        return (
                          <MenuItem value={item.value}>
                            <Translation message={item.name} />
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value="WFH">
                        <Translation message={"WFH"} />
                      </MenuItem>
                      <MenuItem value="REMOTE_WORKING">
                        <Translation message={"Exceptional WFH"} />
                      </MenuItem>
                      <MenuItem value="Client site">
                        <Translation message={"Third party site"} />
                      </MenuItem>
                      <MenuItem value="Leave">
                        <Translation message={"Leave"} />
                      </MenuItem>
                      <MenuItem value={null}>
                        <Translation message={"ALL"} />
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                <div className="">
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="demo-simple-select-label">
                      <Translation message={"Status"} />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="pending">
                        <Translation message={"Pending"} />
                      </MenuItem>
                      <MenuItem value="accepted">
                        <Translation message={"Approved"} />
                      </MenuItem>
                      <MenuItem value="refused">
                        <Translation message={"Rejected"} />
                      </MenuItem>
                      <MenuItem value={null}>
                        <Translation message={"ALL"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="">
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">
                      <Translation message={"Manager"} />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={manager}
                      label="Manager"
                      onChange={(e) => {
                        setManager(e.target.value);
                      }}
                    >
                      <MenuItem value={null}>
                        <Translation message={"ALL"} />
                      </MenuItem>
                      {managers.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="mt-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={<Translation message={"Update Date"} />}
                      inputFormat="DD/MM/YYYY"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      // mask={maskMap['fr']}
                      inputFormat="dd/MM/yyyy"
                      label="Update Date"
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider> */}
                </div>
              </div>

              <div>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  // sx={{ position: "absolute", bottom: 30, right: 40 }}
                  icon={<SpeedDialIcon />}
                  // color='red'
                  // ButtonProps={{ color: "secondary" }}
                  FabProps={{
                    size: "large",
                    style: { backgroundColor: "#136bc2", outline: "none" },
                  }}
                  direction="left"
                >
                  {features
                    .filter((item) => item.link != null && item.link != "")
                    ?.map((action) => (
                      // <Link to={action.href}>
                      <SpeedDialAction
                        key={action.name}
                        icon={<IconComponent name={action.icon} />}
                        tooltipTitle={t(action.name)}
                        // onClick={() => {
                        //   window.location.pathname = action.href;
                        // }}
                        onClick={() => {
                          navigate(action.link);
                        }}
                        FabProps={{
                          style: { color: action.color, outline: "none" },
                        }}
                      />
                      // </Link>
                    ))}
                </SpeedDial>
              </div>
              <div
                className={
                  isDesktop
                    ? "flex flex-wrap justify-center flex-grow-3 gap-8 mt-16 mb-12"
                    : "justify-center flex-grow-3 gap-8 mt-16 mb-12"
                }
              >
                {loading ? (
                  <ClipLoader
                    color="blue"
                    loading={loading}
                    size={50}
                    className="justify-center"
                  />
                ) : (
                  <>
                    {dataFiltred && dataFiltred.length !== 0 ? (
                      dataFiltred.map((request) => {
                        return (
                          <Card
                            request={request._id}
                            item={request}
                            operations={request.operations}
                            token={token}
                            data={data}
                            setData={setData}
                          />
                        );
                      })
                    ) : (
                      <Typography color="gray">
                        <Translation message={"You have no requests"} />
                      </Typography>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Box sx={{ height: 100, transform: "translateZ(0px)", flexGrow: 0 }}>
          {/* <Fab
            color="primary"
            aria-label="add"
            style={{
              backgroundColor: "#083985",
              position: "fixed",
              bottom: 150,
              right: 50,
              outline: "none",
            }}
            onClick={() => {
              window.location.href = "/onlinework";
            }}
          >
            <AddIcon />
          </Fab> */}

          {/* <Paragraph
            style={{ position: "fixed", bottom: 90, right: 30, color: color }}
          >
            New Request
          </Paragraph> */}
        </Box>
      </div>
    </section>
  );
}
