import React, { useEffect, useState } from "react";
import {
  Avatar,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
  Button,
  Icon,
  Typography,
} from "@material-tailwind/react";

// import Typography from "@material-tailwind/react/components/Typography";
import ProfilePicture from "assets/img/team-3-800x800.jpg";
// import AvatarImage from "assets/img/avatar.png";

// import AnimatedNumbers from "react-animated-numbers";
import Chart from "react-google-charts";
import {
  fetchUser,
  fetchNbrReservations,
  fetchNbrWfhs,
  fetchUserBalances,
  fetchNbrCS,
} from "actions/UserAction";
import AnimatedNumber from "animated-number-react";
import jwt from "jwt-decode";
import { Link } from "react-router-dom";
import { fetchUserImage } from "actions/UserAction";
import { HistoryOutlined, Password } from "@mui/icons-material";
import UpdatePassword from "./UpdatePassword";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import { format } from "date-fns";
import { t } from "i18next";
import { fetchCombinedModesByUser } from "actions/UserAction";
import DelegateValidation from "./DelegateValidation";
import { fetchSetting } from "actions/SettingAction";
export default function Content() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const decodedToken = jwt(token);
  const date = new Date();
  var startDate = new Date("2020-01-01");
  var endDate = new Date("2020-01-01");
  endDate.setMonth(date.getMonth() + 1);
  endDate.setFullYear(date.getFullYear());
  startDate.setFullYear(date.getFullYear());
  startDate.setMonth(date.getMonth());

  const month = date.getMonth() + 1;

  const [user, setUser] = useState({});
  const [nbWFH, setNbWFH] = useState(0);
  const [nbReservation, setNbReservation] = useState(0);
  const [balance, setBalance] = useState(0);
  const [nbCS, setNBCS] = useState(0);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [extraBalance, setExtraBalance] = useState();
  const [balances, setBalances] = useState();
  const [combinedModes, setCombinedModes] = useState(null);
  const [openDelegatePopup, setOpenDelegatePopup] = useState(false);
  const [features, setFeatures] = useState([]);

  const formatValue = (value) => `${Number(value).toFixed(1)}`;

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchUser(token, decodedToken.id);

      const res = await fetchUserImage();
      setImage(res);
      setUser(result);
      // const reservations = await fetchNbrReservations(
      //   token,
      //   decodedToken.id,
      //   startDate.toISOString().substring(0, 10),
      //   endDate.toISOString().substring(0, 10)
      // );
      // const wfhs = await fetchNbrWfhs(
      //   token,
      //   decodedToken.id,
      //   startDate.toISOString().substring(0, 10),
      //   endDate.toISOString().substring(0, 10)
      // );
      // const nbCs = await fetchNbrCS(
      //   "",
      //   decodedToken.id,
      //   startDate.toISOString().substring(0, 10),
      //   endDate.toISOString().substring(0, 10)
      // );
      const data = await fetchUserBalances(token, decodedToken.id);
      const monthBalance = data[0].WFHmonthBalance.filter(
        (item) => item.nb === month
      );

      const modes = await fetchCombinedModesByUser(decodedToken.id);

      setExtraBalance(data[0]?.extraBalance);
      setBalance(monthBalance[0].count);
      setBalances(data[0]);
      setCombinedModes(modes[0] || {});

      const featuresResult = await fetchSetting("features");
      setFeatures(featuresResult[0]?.Web);

      // setNbReservation(reservations);
      // setNbWFH(wfhs);
      // setNBCS(nbCs);
      // setNBLeaves(leaves);
    };

    fetchData();
  }, []);

  console.log("ffffffffffffff", features);

  return (
    <section className="relative py-16 bg-gray-100">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div className="relative">
                  <div className="w-40 -mt-20">
                    {/* {user.photo ? (
                      <Avatar
                        // src={ProfilePicture}
                        src={
                          //"https://backend.ld-arcueil.work-point.tech/uploads/" +

                          process.env.REACT_APP_UPLOADS_URL + user.photo
                        }
                        alt="Profile picture"
                        variant="circular"
                        className="h-full w-full shadow-xl"
                      />
                    ) : (
                      <Avatar
                        src={AvatarImage}
                        alt="Profile picture"
                        variant="circular"
                        className="h-full w-full shadow-xl"
                      />
                    )} */}
                    <Avatar
                      src={image}
                      alt="Profile picture"
                      variant="circular"
                      className="h-full w-full shadow-xl"
                    />
                    {/* <img id="image" /> */}
                  </div>
                </div>
              </div>
              {/* <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:self-center flex justify-center mt-10 lg:justify-end lg:mt-0">
                           
                                        <span className="text-xl  block tracking-wide text-gray-900">
                                            22 Leave balance
                                        </span>
                                      
                               
                            </div> */}
              {/* <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            22
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Sick Leaves
                                        </span>
                                    </div>
                                    <div className="mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            10
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Casual Leaves
                                        </span>
                                    </div>
                                    <div className="lg:mr-4 p-3 text-center">
                                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                            89
                                        </span>
                                        <span className="text-sm text-gray-700">
                                            Telework
                                        </span>
                                    </div>
                                </div>
                            </div> */}
            </div>

            <div className="text-center my-8">
              <Typography variant="h3" color="gray">
                {user && user.firstname} {user && user.lastname}
              </Typography>
              {/* <div className="mt-0 mb-2 text-gray-700 font-medium flex items-center justify-center gap-2">
                                <Icon name="place" size="xl" />
                                EY
                            </div> */}
              <div className="mb-2 text-gray-700 mt-5 flex items-center justify-center gap-2">
                {/* <Icon name="work" size="xl" /> */}
                <i className="fas fa-briefcase" />
                {user && user.grade && user.grade.grade_name}
              </div>
              <div className="mb-2 text-gray-700 flex items-center justify-center gap-2">
                {/* <Icon name="account_balance" size="xl" /> */}
                {/* <i className="fas fa-apartment" /> */}
                <i className="fas fa-building"></i>
                {user && user.serviceLine && user.serviceLine.serviceLine}
              </div>
              {/* <Typography color="blueGray">
              <Translation message={"You have"} /> <b>{balance}</b> <Translation message={"days left in your WFH balance for"} /> {" "}
                {date.toLocaleString("En", { month: "long" })}.
              </Typography> */}
            </div>
            <div className="flex justify-center flex-wrap mt-5">
              <div className="lg:mr-4 p-3 text-center">
                <span className="text-sm font-bold block  tracking-wide text-gray-700">
                  <Translation message={"Hire date"} />{" "}
                </span>
                <span className="text-sm text-gray-700">
                  {user.hire_date &&
                    format(new Date(user.hire_date), "dd-MM-yyyy")}
                </span>
              </div>
              <div className="lg:mr-4 p-3 text-center">
                <span className="text-sm font-bold block tracking-wide text-gray-700">
                  <Translation message={"Authorized WFH days"} />{" "}
                </span>
                <span className="text-sm text-gray-700">
                  {user.wfhProfile?.authorizedWfhDays.map((day, index) =>
                    index == user.wfhProfile.authorizedWfhDays.length - 1
                      ? t(day)
                      : t(day) + ", "
                  )}
                </span>
              </div>

              {user.partTime && (
                <>
                  <div className="lg:mr-4 p-3 text-center">
                    <span className="text-sm font-bold block tracking-wide text-gray-700">
                      <Translation message={"Extra balance"} />
                    </span>
                    <span className="text-sm text-gray-700">
                      {extraBalance}
                    </span>
                  </div>
                  <div className="lg:mr-4 p-3 text-center">
                    <span className="text-sm font-bold block tracking-wide text-gray-700">
                      <Translation message={"Part-time"} />
                    </span>
                    <span className="text-sm text-gray-700">
                      {user.partTime}%
                    </span>
                  </div>
                </>
              )}

              {balances?.LeaveBalance && (
                <>
                  <div className="lg:mr-4 p-3 text-center">
                    <span className="text-sm font-bold block tracking-wide text-gray-700">
                      <Translation message={"Leave balance"} />
                    </span>
                    <span className="text-sm text-gray-700">
                      {balances.LeaveBalance}
                    </span>
                  </div>
                </>
              )}
              {balances?.sickBalance && (
                <>
                  <div className="lg:mr-4 p-3 text-center">
                    <span className="text-sm font-bold block tracking-wide text-gray-700">
                      <Translation message={"Sick balance"} />
                    </span>
                    <span className="text-sm text-gray-700">
                      {balances.sickBalance}
                    </span>
                  </div>
                </>
              )}
            </div>

            <div className="mb-10 py-2 border-t border-gray-200 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4 flex flex-col items-center">
                  {isDesktop ? (
                    <div className="flex flex-wrap justify-center justify-between mt-20">
                      <Link to="/history">
                        <Button
                          color="indigo"
                          buttonType="link"
                          ripple="dark"
                          size="sm"
                          // onClick={()=> { setOpen(true) }}
                        >
                          <HistoryOutlined />{" "}
                          <Translation message={"Show History"} />
                        </Button>
                      </Link>
                      <Button
                        className="ml-2"
                        color="indigo"
                        variant="outlined"
                        ripple="dark"
                        onClick={() => {
                          setOpen(true);
                        }}
                        size="sm"
                      >
                        <Password /> <Translation message={"Update Password"} />
                      </Button>
                      {features?.find(
                        (item) => item.name?.toLowerCase() == "leave"
                      ) && (
                        <Button
                          className="ml-2"
                          color="indigo"
                          variant="outlined"
                          ripple="dark"
                          onClick={() => {
                            setOpenDelegatePopup(true);
                          }}
                          // size="sm"
                          title="Delagate leave validation"
                        >
                          <i
                            className="fas fa-people-arrows"
                            style={{ fontSize: 15 }}
                          ></i>{" "}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex flex-wrap justify-center justify-between mt-20"
                      style={{ flexDirection: "column" }}
                    >
                      <Link to="/history">
                        <Button
                          color="indigo"
                          buttonType="link"
                          ripple="dark"
                          size="sm"
                          // onClick={()=> { setOpen(true) }}
                        >
                          <HistoryOutlined />{" "}
                          <Translation message={"Show History"} />
                        </Button>
                      </Link>
                      <Button
                        className="mt-2"
                        color="indigo"
                        variant="outlined"
                        ripple="dark"
                        size="sm"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <Password /> <Translation message={"Update Password"} />
                      </Button>
                    </div>
                  )}

                  {open && (
                    <UpdatePassword
                      open={open}
                      handleOpen={() => {
                        setOpen(!open);
                      }}
                    />
                  )}

                  {openDelegatePopup && (
                    <DelegateValidation
                      open={openDelegatePopup}
                      handleOpen={() => {
                        setOpenDelegatePopup(!openDelegatePopup);
                      }}
                    />
                  )}
                  {/*
                                    <a
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Button
                                            color="lightBlue"
                                            buttonType="link"
                                            ripple="dark"
                                        >
                                            Show more
                                        </Button>
                                    </a> */}

                  <div className="w-full lg:w-12/12 px-4 lg:order-1 mt-5">
                    <Typography color="blueGray">
                      <Translation
                        message={
                          "You carried out the following operations during this month"
                        }
                      />{" "}
                      {/* {date.toLocaleString("En", { month: "long" })} */}
                    </Typography>

                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      {combinedModes &&
                        Object.entries(combinedModes)?.map(([key, value]) => {
                          return (
                            <div className="lg:mr-4 p-3 text-center">
                              <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                                <AnimatedNumber
                                  value={value}
                                  formatValue={formatValue}
                                  duration={1000}
                                />
                              </span>
                              <span className="text-sm text-gray-700">
                                <Translation message={key} />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                    {/* <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                       
                          <AnimatedNumber
                            value={nbWFH}
                            formatValue={formatValue}
                            duration={1000}
                          />
                        </span>
                        <span className="text-sm text-gray-700">
                          <Translation message={"WFH"} />
                        </span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                       
                          <AnimatedNumber
                            value={nbReservation}
                            formatValue={formatValue}
                            duration={1000}
                          />
                        </span>
                        <span className="text-sm text-gray-700">
                          <Translation message={"On Site"} />
                        </span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                          <AnimatedNumber
                            value={nbCS}
                            formatValue={formatValue}
                            duration={1000}
                          />
                        </span>
                        <span className="text-sm text-gray-700">
                          <Translation message={"Third party site"} />
                        </span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-900">
                          <AnimatedNumber
                            value={nbCS}
                            formatValue={formatValue}
                            duration={1000}
                          />
                        </span>
                        <span className="text-sm text-gray-700">
                          <Translation message={"Leave"} />
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="w-full lg:w-9/12 px-4 flex flex-col items-center">
                  {/* <Chart
                                        width={'700px'}
                                        height={'500px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['Task', 'Days per month'],
                                            ['On Site', 11],
                                            ['WFH', 2],
                                            ['Customer Site', 2],
                                            ['Mission', 3],
                                            ['Leave', 2],
                                        ]}
                                        options={{
                                            title: 'My Daily Activities',
                                            is3D: true,
                                            colors: ['#5262ff', '#2ec6ff', '#b741c4', '#FF4081', '#c6d13e'],
                                        }}
                                        rootProps={{ 'data-testid': '2' }}
                                        /> */}
                  {combinedModes && (
                    <Chart
                      width={"700px"}
                      height={"500px"}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      // data={[
                      //   ["Task", "Days per month"],
                      //   ["On Site", 0],
                      //   ["WFH", 0],
                      //   ["Third party site", 0],
                      //   ["LEAVE", 0],
                      // ]}
                      data={[
                        ["Task", "Days per month"],
                        ...Object.entries(combinedModes).map(([key, value]) => [
                          // <Translation message={key} />,
                          t(key),
                          value,
                        ]),
                      ]}
                      options={{
                        // title: 'My Activities for this month',
                        is3D: true,
                        colors: [
                          "#2ec6ff",
                          "#5262ff",
                          "#b741c4",
                          "#ab9a00",
                          "#de62b4",
                        ],
                      }}
                      rootProps={{ "data-testid": "2" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
