import { Button, Input, Typography } from "@material-tailwind/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { fetchFile } from "actions/travel.service";
import CustomFileInput from "components/CustomFileInput";
import { format } from "date-fns";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import FileViewer from "./FileViewer";
import Translation from "Translation";

const UserPanel = ({
  request,
  edit,
  handleFormChange,
  formData,
  missionData,
}) => {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const currentUser = jwtDecode(token);
  const [fileData, setFileData] = useState(null);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [fileType, setFileType] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    request?.mission?._id && showFile("passportFile");
  }, [request?.mission?._id]);

  const showFile = async (name) => {
    // await fetchFile(request?.mission?._id, name);
    // const res = await fetchFile(request?.mission?._id, name);
    // setFileData(res);
    // setOpen(true);

    await fetchFile(request?.mission?._id, name)
      .then((response) => {
        // if (response.status == 400) {
        //   throw Error("Not exist");
        // }

        const contentType = response?.headers["content-type"];
        // const fileType = response.type;
        // console.log("Type de fichier :", fileType);
        // const contentType = response.headers.get("Content-Type");
        setFileType(contentType?.split("/")?.pop());
        return response.data;

        // return response.arrayBuffer();
      })
      .then((data) => {
        // console.log("eeeeeeeeeeeee", data);
        // setOpen(false);

        // window.open(new Uint8Array(data), "_blank");
        setFileData(new Uint8Array(data));
        // setView(true);
      })

      .catch((error) => {
        console.error("Erreur lors du chargement du fichier:", error);
      });

    // fetch(
    //   process.env.REACT_APP_API_TRAVEL +
    //     `Mission/getFile/${request?.mission?._id}/${name}`,
    //   {
    //     method: "GET", // *GET, POST, PUT, DELETE, etc.
    //     mode: "cors", // no-cors, *cors, same-origin
    //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //     headers: {
    //       // "Content-Type": "image/jpeg",
    //       "x-access-token": token,
    //     },
    //   }
    // )
    //   .then((response) => {
    //     if (response.status == 400) {
    //       throw Error("Not exist");
    //     }
    //     // const fileType = response.type;
    //     // console.log("Type de fichier :", fileType);
    //     const contentType = response.headers.get("Content-Type");
    //     setFileType(contentType.split("/").pop());
    //     console.log("response", response);
    //     return response.arrayBuffer();
    //   })
    //   .then((data) => {
    //     // console.log("eeeeeeeeeeeee", data);
    //     // setOpen(false);

    //     // window.open(new Uint8Array(data), "_blank");
    //     setFileData(new Uint8Array(data));
    //     // setView(true);
    //   })

    //   .catch((error) => {
    //     console.error("Erreur lors du chargement du fichier:", error);
    //   });

    // .then((response) => response.arrayBuffer())
    // .then((data) => {
    //   console.log("dataaaaa", data);
    //   // data.arrayBuffer();
    //   const blob = base64toBlob(data);
    //   const url = URL.createObjectURL(blob);

    // const fileData = URL.createObjectURL(data);
    // window.open(data, "_blank");
    // setFileData(data);
    // });
  };

  const handleFileChange = (file) => {
    handleFormChange({ passportFile: file });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileDownload = () => {
    const fileBlob = new Blob([fileData]);
    const fileURL = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = `file.${fileType}`;
    a.click();
  };

  return (
    <div>
      <div className="flex flex-wrap gap-10">
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Full Name" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender
              ? `${request?.idSender?.firstname} ${request?.idSender?.lastname}`
              : request?.guest}
          </p>
        </div>
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Grade" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender?.grade?.grade_name}
          </p>
        </div>
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Department" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender?.serviceLine?.serviceLine}
          </p>
        </div>
        {/* <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Subserviceline" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender?.subServiceLine &&
            request?.idSender?.subServiceLine?.toString() != "undefined"
              ? request?.idSender?.subServiceLine
              : ""}{" "}
          </p>
        </div> */}
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Email" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender?.Email}{" "}
          </p>
        </div>
        <div className="m-2">
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-large text-gray-800"
          >
            <Translation message="Phone" />{" "}
          </Typography>{" "}
          <p className="text-field text-gray-600">
            {" "}
            {request?.idSender?.telephone}{" "}
          </p>
        </div>
        {currentUser.id == request?.idSender?._id && (
          <div className="flex flex-wrap">
            <div className="m-2">
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-large text-gray-800"
              >
                <Translation message="Passport Number" />{" "}
              </Typography>{" "}
              {edit ? (
                <Input
                  color="blue-gray"
                  onChange={(e) => {
                    handleFormChange({ passportNumber: e.target.value });
                  }}
                  value={missionData?.passportNumber}
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              ) : (
                <p className="text-field text-gray-600">
                  {" "}
                  {request?.mission?.passportNumber}{" "}
                </p>
              )}
            </div>
            <div className="m-2">
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-large text-gray-800"
              >
                <Translation message="Place of passport issue" />{" "}
              </Typography>{" "}
              {edit ? (
                <Input
                  color="blue-gray"
                  onChange={(e) => {
                    handleFormChange({ placePassport: e.target.value });
                  }}
                  value={missionData?.placePassport}
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              ) : (
                <p className="text-field text-gray-600">
                  {" "}
                  {request?.mission?.placePassport}{" "}
                </p>
              )}
            </div>

            <div className="m-2">
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-large text-gray-800"
              >
                <Translation message="Date of passport issue" />
              </Typography>{" "}
              {edit ? (
                // <Input
                //   color="indigo"
                //   type="date"
                //   onChange={(e) =>
                //     handleFormChange({ datePassport: e.target.value })
                //   }
                //   value={
                //     formData?.datePassport
                //       ? formData?.datePassport
                //       : request?.mission?.datePassport
                //   }
                //   format="dd-MM-yyyy"
                // />
                <LocalizationProvider
                  style={{ outline: "none" }}
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language}
                >
                  <DatePicker
                    style={{ outline: "none" }}
                    // label="From"
                    value={missionData?.datePassport}
                    onChange={(e) => {
                      const date = new Date(e);
                      if (!isNaN(date.getTime())) {
                        const newDate = new Date(date.getTime());
                        newDate.setFullYear(date.getFullYear() + 5);
                        newDate.setDate(newDate.getDate() - 1);

                        handleFormChange({
                          expiryPassport: newDate?.toISOString()?.split("T")[0],
                        });
                      }
                      handleFormChange({
                        datePassport: format(new Date(e), "yyyy-MM-dd"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: 200,
                          "& .MuiInputBase-input": {
                            borderColor: "rgb(176 190 197)",
                            // borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: "16px",
                            height: 9,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgb(96 125 139)",
                            },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(176 190 197)",
                          },
                        }}
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              ) : (
                <p className="text-field text-gray-600">
                  {" "}
                  {request?.mission?.datePassport &&
                    format(
                      new Date(request?.mission?.datePassport),
                      "dd-MM-yyyy"
                    )}
                </p>
              )}
            </div>
            <div className="m-2">
              <Typography
                variant="small"
                className="flex gap-1 mb-2 font-large text-gray-800"
              >
                <Translation message="Expiry passport date" />{" "}
              </Typography>{" "}
              {edit ? (
                // <LocalizationProvider
                //   style={{ outline: "none" }}
                //   dateAdapter={AdapterDayjs}
                //   adapterLocale={i18n?.language}
                // >
                //   <DatePicker
                //     style={{ outline: "none" }}
                //     // label="From"
                //     value={
                //       formData?.expiryPassport
                //         ? formData?.expiryPassport
                //         : request?.mission?.expiryPassport
                //     }
                //     onChange={(e) => {
                //       handleFormChange({ expiryPassport: e });
                //     }}
                //     renderInput={(params) => (
                //       <TextField
                //         variant="standard"
                //         {...params}
                //         sx={{
                //           width: 200,
                //           "& .MuiInputBase-input": {
                //             borderColor: "rgb(176 190 197)",
                //             // borderWidth: 1,
                //             borderStyle: "solid",
                //             borderRadius: "10px",
                //             height: 9,
                //           },

                //           "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                //             {
                //               borderColor: "rgb(176 190 197)",
                //             },
                //           "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                //             borderColor: "rgb(176 190 197)",
                //           },
                //         }}
                //       />
                //     )}
                //     inputFormat="DD/MM/YYYY"
                //     minDate={new Date()}
                //   />
                // </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n?.language}
                >
                  <DatePicker
                    value={missionData?.expiryPassport}
                    onChange={(e) => {
                      handleFormChange({
                        expiryPassport: format(new Date(e), "yyyy-MM-dd"),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: 200,
                          "& .MuiInputBase-input": {
                            borderColor: "rgb(176 190 197)",
                            // borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: "16px",
                            height: 9,
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "rgb(96 125 139)",
                            },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgb(176 190 197)",
                          },
                        }}

                        // onChange={() => null}
                        // value={
                        //   formData.datePassport
                        //     ? format(new Date(formData.datePassport), "dd/MM/yyyy")
                        //     : ""
                        // }
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={new Date()}
                  />
                </LocalizationProvider>
              ) : (
                <p className="text-field text-gray-600">
                  {request?.mission?.expiryPassport &&
                    format(
                      new Date(request?.mission?.expiryPassport),
                      "dd-MM-yyyy"
                    )}
                </p>
              )}
            </div>
            {/* {request?.mission?.documents?.find(
          (element) => "passportFile" in element
        ) && ( */}
            {fileData && (
              <div className="m-2">
                <Typography
                  variant="small"
                  className="flex gap-1 mb-2 font-large text-gray-800"
                >
                  <Translation message="Passport" />{" "}
                </Typography>{" "}
                <Button
                  variant="text"
                  onClick={() => {
                    // showFile("passportFile");
                    setView(true);
                  }}
                >
                  <FaEye
                    fontSize={"medium"}
                    style={{ color: "#294e87", cursor: "pointer" }}
                  />
                </Button>
                {/* {fileData && (
              <div>
                <Save
                  fontSize={"small"}
                  style={{ color: "#294e87" }}
                  onClick={handleFileDownload}
                />

                <p>Image :</p>
                <img
                  src={`data:image/jpeg;base64,${window.btoa(
                    String.fromCharCode.apply(null, fileData)
                  )}`}
                  alt="Image"
                />
               
              </div>
            )} */}
                {/* {fileData && <img src={fileData} alt="Fichier" />} */}
                {/* <Button sm >
              <EyeIcon fontSize={"xs"} style={{ color: "#294e87" }} />
              show file
            </Button> */}
                {/* <Button
              size="sm"
              className="pl-20"
              color="indigo"
              ripple="light"
              buttonType="outline"
              // onClick={() => {
              //   SaveDraft();
              // }}
            >
              <Icon name="show" size="sm" />
            </Button> */}
                {/* <IconButton
              edge="start"
              color="inherit"
              // onClick={handleClose}
              aria-label="close"
              sx={{ position: "absolute", top: 10, right: 10 }}
            >
              <EyeIcon style={{ color: "#294e87" }} />
            </IconButton> */}
              </div>
            )}
            {edit && (
              <div className=" m-2">
                <Typography
                  variant="small"
                  className="flex gap-1 mb-2 font-medium text-gray-800"
                >
                  <Translation message="Upload passport file" />
                </Typography>
                <CustomFileInput onFileChange={handleFileChange} />{" "}
                {formData?.passportFile?.name}
              </div>
            )}
          </div>
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <div className="flex flex-col place-items-center">
            <CircularProgress color="inherit" />
            <Translation message="Loading file" />
          </div>
        </Backdrop>
        {fileData && fileType && (
          <FileViewer
            fileData={fileData}
            view={view}
            setView={setView}
            handleDownload={handleFileDownload}
            fileType={fileType}
          />
        )}
      </div>
    </div>
  );
};

export default UserPanel;
