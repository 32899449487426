import React, { useState, useEffect } from "react";
import jwt from "jwt-decode";
import { fetchAccesszone } from "../../actions/ZoneAction";
// import { fetchBuilding } from "../../actions/BuildingAction";
import useMediaQuery from "useMediaQuery";
// import { ClipLoader } from "react-spinners";

// import { Tooltip } from "@material-tailwind/react";
import { Tooltip, ThemeProvider } from "@material-tailwind/react";
const theme = {
  tooltip: {
    styles: {
      base: {
        bg: "bg-white",
        color: "text-black",
      },
    },
  },
};

export default function Building(props) {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  // var [floor, setFloors] = useState([]);
  const { floor } = props;
  var [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = jwt(token);

  // useEffect(() => {
  //   const fetch = async () => {
  //     const result = await fetchBuilding(token);
  //     console.log("result", result);
  //     let data = Array.from(result, ({ Floors }) => Floors);
  //     const sorted = data[0].sort((a, b) =>
  //       a.floor_num > b.floor_num ? 1 : b.floor_num > a.floor_num ? -1 : 0
  //     );
  //     setFloors(sorted);
  //     setLoading(false);
  //   };
  //   fetch();
  // }, [token]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchAccesszone(token, user.id);
      // console.log(result,"access");
      let data = Array.from(result, ({ floor_num }) => floor_num);
      setAccess(data);
    };
    fetchData();
  }, []);

  // let history = useHistory();
  // const clickHandler = (id) =>{
  //    history.push("/floor/"+id)}

  //        <>
  //        <a href={`/floor/${item._id}`} >
  //            {item.floor_map_web_active }
  //        </a>
  //    </>

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const htmlFromCMSDesktop = `
      <svg viewBox="0 0 900 900"  height="900"
      width="1000" fill="none">
      ${
        floor &&
        floor.map((item) => {
          return access && access.includes(item.floor_num)
            ? // console.log(item._id)
              `<a href="/floor/${item._id}" > <title>Floor ${item.floor_num}</title>` +
                item.floor_map_web_active +
                "</a>"
            : item.floor_map_web;
        })
      }
    </svg>`;

  // document.getElementById("tooltip").mouseover.styled = "show";

  // console.log(
  //   `gggggggggggggggggggg`,
  //   document.getElementById("tooltip").onmouseover(() => {
  //     console.log("yeeeeeeeeeeeees");
  //   })
  // );
  // $(document).ready(function () {
  //   $("a.tooltip-test").mouseover(function () {
  //     $(this).tooltip("show");
  //   });
  // });

  //   const htmlFromCMSDesktop = `
  //   <svg viewBox="0 0 1000 900"  height="900"
  //   width="1000" fill="none">
  //   ${
  //     floor &&
  //     floor.map((item) => {
  //       return access && access.includes(item.floor_num) ? (
  //         // console.log(item._id)
  //         <Tooltip content="Material Tailwind">
  //           {`<a id="tooltip" data-toggle="tooltip" title="ttiiitle" class="tooltip-test" href="/floor/${item._id}" >` +
  //             item.floor_map_web_active +
  //             "</a>"}
  //         </Tooltip>
  //       ) : (
  //         <Tooltip content="Material Tailwind">{item.floor_map_web}</Tooltip>
  //       );
  //     })
  //   }
  // </svg>`;

  const htmlFromCMSMobile = `  
    <svg viewBox="0 0 1000 900"  height="400"
    width="400" fill="none">
    ${
      floor &&
      floor.map((item) => {
        return access && access.includes(item.floor_num)
          ? // console.log(item._id)
            `<a href="/floor/${item._id}" >` +
              item.floor_map_web_active +
              "</a>"
          : item.floor_map_web;
      })
    }
  </svg>`;
  return (
    <ThemeProvider value={theme}>
      <div
        dangerouslySetInnerHTML={{
          __html: isDesktop ? htmlFromCMSDesktop : htmlFromCMSMobile,
        }}
      />
    </ThemeProvider>
  );

  // return loading ? (
  //   <ClipLoader color="blue" loading={loading} size={50} />
  // ) : (
  //   <div
  //     dangerouslySetInnerHTML={{
  //       __html: isDesktop ? htmlFromCMSDesktop : htmlFromCMSMobile,
  //     }}
  //   />
  // );
}
