import { Input, Textarea, Typography } from "@material-tailwind/react";
import Translation from "Translation";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaHome, FaHotel } from "react-icons/fa";

const Expenses = (props) => {
  const { formData, setFormData, missions } = props;
  const { i18n } = useTranslation();

  return (
    <div className="lg:w-12/12 mt-50 ">
      <Typography className=" text-sm  text-gray-700 pt-1">
        <Translation message="Per diem" />
      </Typography>
      <div className="w-full mt-10">
        {formData.perdiems?.map((item, index) => (
          <div key={index}>
            <div className="flex flex-row flex-wrap gap-4 mt-10" key={index}>
              <div className="mt-10">
                {item.type == "hotel" ? (
                  <FaHotel color="#294e87" />
                ) : (
                  <FaHome color="#294e87" />
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Accommodation type" />
                </Typography>
                <Input
                  type="text"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={item.type}
                />
              </div>
              {/* <div>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Applied per diem policy" />
                </Typography>
                <Input
                  type="text"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={item.perdiem ? item.perdiem?.name : ""}
                />
              </div> */}
              <div style={{ width: 100 }}>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700"
                >
                  <Translation message="Indemnity" />
                </Typography>
                <Input
                  type="text"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={
                    item.perdiem
                      ? item?.indemnity + " " + item.perdiem?.currency?.name
                      : ""
                  }
                  style={{ width: 100 }}
                />
              </div>
              <div style={{ width: 150 }}>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700 "
                >
                  <Translation message={"Start date"} />
                </Typography>

                <Input
                  type="text"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={
                    item.startDate
                      ? format(new Date(item.startDate), "dd-MM-yyyy")
                      : ""
                  }
                  style={{ width: 150 }}
                />
              </div>
              <div style={{ width: 150 }}>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700 "
                >
                  <Translation message={"End date"} />
                </Typography>

                <Input
                  type="text"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={
                    item.endDate
                      ? format(new Date(item.endDate), "dd-MM-yyyy")
                      : ""
                  }
                  style={{ width: 150 }}
                />
              </div>
              <div style={{ width: 180 }}>
                <Typography
                  variant="small"
                  className="mb-2 font-medium text-gray-700 "
                >
                  <Translation message={"Extra expenses"} />
                </Typography>

                <Input
                  type="number"
                  className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  color="blue-gray"
                  value={item.extraExpenses}
                  onChange={(e) => {
                    let newValue = [...formData.perdiems];
                    newValue[index].extraExpenses = Number(e.target.value);
                    setFormData((prevData) => ({
                      ...prevData,
                      perdiems: newValue,
                    }));
                  }}
                  style={{ width: 180 }}
                />
              </div>
              {formData.missionType === "compound" && (
                <>
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Mission"} />
                    </Typography>

                    <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.title}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="small"
                      className="mb-2 font-medium text-gray-700 "
                    >
                      <Translation message={"Engagement code"} />
                    </Typography>

                    <Input
                      type="text"
                      className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      color="blue-gray"
                      value={item.engagementCode ? item.engagementCode : ""}
                    />
                  </div>
                </>
              )}
            </div>
            <hr className="my-6 border-gray-300" />
          </div>
        ))}

        <div className="mt-5">
          <Typography
            variant="small"
            className="mb-2 font-medium text-gray-700"
          >
            <Translation message={"Why do you need extra expenses?"} />{" "}
          </Typography>
          <Textarea
            value={formData.expensesComment}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                expensesComment: e.target.value,
              }));
            }}
            className="w-full border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
          />
        </div>
      </div>
    </div>
  );
};

export default Expenses;
