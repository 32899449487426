import {
  Checkbox,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Radio,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Translation from "Translation";
import { format } from "date-fns";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { fr, enUS } from "date-fns/locale";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "react-select";
import { NotificationManager } from "react-notifications";

const Mission = (props) => {
  const {
    formData,
    setFormData,
    setIsEngagementCodeValid,
    isEngagementCodeValid,
    countries,
    allCities,
    users,
    formules,
    missions,
    setMissions,
    index,
    errors,
  } = props;

  const { i18n } = useTranslation();

  return (
    <div className="lg:w-12/12 mt-10 ">
      <div className="my-3 flex flex-wrap items-center gap-5">
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission title"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <Input
            type="text"
            placeholder={t("Mission title")}
            className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({
                  ...prevData,
                  title: e.target.value,
                }));
              } else {
                let newMissions = [...missions];
                const newTitle = e.target.value;

                const isTitleUnique = newMissions.every((mission, idx) => {
                  if (idx === index) return true;
                  return mission.title !== newTitle;
                });

                if (isTitleUnique) {
                  newMissions[index].title = newTitle;
                  setMissions(newMissions);
                } else {
                  NotificationManager.error("The title must be unique");
                }
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.title
                : missions[index]?.title
            }
          />
          {/* {errors[`title`] ? (
            <p className="text-red-500 text-xs mt-1">
              <Translation message={errors[`title`]} />
            </p>
          ) : (
            ""
          )} */}
        </div>

        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Engagement code status"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <div className="flex gap-10">
            <Radio
              name="statusEngagementCode"
              label={t("Processing")}
              icon={<Icon />}
              value="processing"
              onChange={(e) => {
                if (formData.missionType == "simple") {
                  setFormData((prevData) => ({
                    ...prevData,
                    statusEngagementCode: e.target.value,
                  }));
                } else {
                  let newMissions = [...missions];

                  newMissions[index].statusEngagementCode = e.target.value;
                  setMissions(newMissions);
                }
              }}
              checked={
                formData.missionType == "simple"
                  ? formData.statusEngagementCode == "processing"
                  : missions[index]?.statusEngagementCode == "processing"
              }
              color="indigo"
            />
            <Radio
              name="statusEngagementCode"
              label={t("Obtained")}
              icon={<Icon />}
              value="obtained"
              onChange={(e) => {
                if (formData.missionType == "simple") {
                  setFormData((prevData) => ({
                    ...prevData,
                    statusEngagementCode: e.target.value,
                  }));
                } else {
                  let newMissions = [...missions];

                  newMissions[index].statusEngagementCode = e.target.value;
                  setMissions(newMissions);
                }
              }}
              checked={
                formData.missionType == "simple"
                  ? formData.statusEngagementCode == "obtained"
                  : missions[index]?.statusEngagementCode == "obtained"
              }
              color="indigo"
            />
            <Radio
              name="statusEngagementCode"
              label={t("Not applicable")}
              icon={<Icon />}
              value="not applicable"
              onChange={(e) => {
                if (formData.missionType == "simple") {
                  setFormData((prevData) => ({
                    ...prevData,
                    statusEngagementCode: e.target.value,
                  }));
                } else {
                  let newMissions = [...missions];

                  newMissions[index].statusEngagementCode = e.target.value;
                  setMissions(newMissions);
                }
              }}
              checked={
                formData.missionType == "simple"
                  ? formData.statusEngagementCode === "not applicable"
                  : missions[index]?.statusEngagementCode === "not applicable"
              }
              color="indigo"
            />
          </div>
        </div>
        {((formData?.missionType == "simple" &&
          formData?.statusEngagementCode == "obtained") ||
          (formData?.missionType == "compound" &&
            missions[index]?.statusEngagementCode == "obtained")) && (
          <div className="my-2 " style={{ width: 250 }}>
            <Typography
              variant="small"
              className="flex gap-1 mb-2 font-medium text-gray-700"
            >
              <Translation message={"Engagement code"} />{" "}
              <p className="text-red-500">*</p>
            </Typography>
            <Input
              type="text"
              placeholder={t("Engagement code")}
              className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              color="blue-gray"
              //   onChange={(e) => {
              //     setFormData((prevData) => ({
              //       ...prevData,
              //       engagementCode: e.target.value,
              //     }));
              //   }}
              onChange={(e) => {
                const regex = /^[EI]-\d{8}$/;
                const isValid = regex.test(e.target.value);
                setIsEngagementCodeValid(isValid);
                if (formData?.missionType == "simple") {
                  setFormData((prevData) => ({
                    ...prevData,
                    engagementCode: e.target.value,
                  }));
                } else {
                  let newMissions = [...missions];
                  newMissions[index].engagementCode = e.target.value;
                  setMissions(newMissions);
                }
              }}
              onBlur={() => {
                if (formData.missionType == "simple") {
                  const regex = /^[EI]-\d{8}$/;
                  const isValid = regex.test(formData.engagementCode);
                  setIsEngagementCodeValid(isValid);
                } else {
                  const regex = /^[EI]-\d{8}$/;
                  const isValid = regex.test(missions[index]?.engagementCode);
                  setIsEngagementCodeValid(isValid);
                }
              }}
              value={
                formData.missionType == "simple"
                  ? formData.engagementCode
                  : missions[index]?.engagementCode
              }
            />
            {!isEngagementCodeValid && (
              <p className="text-red-500 text-xs mt-1">
                <Translation message="Invalid format. The value should start with E or I followed by - and then 8 digits." />
              </p>
            )}
          </div>
        )}
      </div>
      <div className="my-3 flex flex-wrap items-center gap-10 mt-10">
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission start date"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>

          {/* <Popover placement="right">
            <PopoverHandler>
              <Input
                onChange={() => null}
                // value={
                //   formData.startDate
                //     ? format(formData.startDate, "dd-MM-yyyy")
                //     : ""
                // }
                value={
                  formData.startDate
                    ? format(new Date(formData.startDate), "dd-MM-yyyy")
                    : ""
                  //   format(new Date("2024-07-23"), "dd-MM-yyyy")
                }
                className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                color="blue-gray"
                placeholder="dd-mm-yyyy"
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                locale={language}
                mode="single"
                // selected={formData.startDate ? formData.startDate : ""}
                selected={
                  formData.startDate ? new Date(formData.startDate) : ""
                }
                onSelect={(date) => {
                  if (date) {
                    const newDate = new Date(date.getTime());
                    newDate.setFullYear(date.getFullYear() + 5);
                    newDate.setDate(newDate.getDate() - 1);
                  } else {
                    console.error("Date invalide :", date);
                  }
                  setFormData((prevData) => ({
                    ...prevData,
                    startDate: format(date, "yyyy-MM-dd"),
                  }));
                }}
                showOutsideDays
                className="border-0"
                classNames={{
                  caption:
                    "flex justify-center py-2 mb-4 relative items-center",
                  caption_label: "text-sm font-medium text-gray-900",
                  nav: "flex items-center",
                  nav_button:
                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                  nav_button_previous: "absolute left-1.5",
                  nav_button_next: "absolute right-1.5",
                  table: "w-full border-collapse",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "m-0.5 w-9 font-normal text-sm",
                  row: "flex w-full mt-2",
                  cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                  day: "h-9 w-9 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                  day_today: "rounded-md bg-gray-200 text-gray-900",
                  day_outside:
                    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                  day_disabled: "text-gray-500 opacity-50",
                  day_hidden: "invisible",
                }}
                components={{
                  IconLeft: ({ ...props }) => (
                    <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                  IconRight: ({ ...props }) => (
                    <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                }}
                modifiers={{
                  disabled: [
                    {
                      before: new Date(),
                    },
                  ],
                }}
                formatters={{
                  formatDate: (date) => format(date, "dd-MM-yyyy"),
                }}
                // month={new Date()}
                // month={
                //   formData.startDate ? new Date(formData.startDate) : undefined
                // }
                // month={null}
              />
              {console.log("formData.startDate", formData.startDate)}
            </PopoverContent>
          </Popover> */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n?.language}
          >
            <DatePicker
              value={
                formData.missionType == "simple" && formData.startDate
                  ? new Date(formData.startDate)
                  : formData.missionType == "compound" &&
                    missions[index].startDate
                  ? new Date(missions[index].startDate)
                  : null
              }
              onChange={(e) => {
                const date = new Date(e);
                if (!isNaN(date.getTime())) {
                  if (formData.missionType == "simple") {
                    setFormData((prevData) => ({
                      ...prevData,
                      startDate: format(date, "yyyy-MM-dd"),
                    }));
                  } else {
                    let newMissions = [...missions];
                    newMissions[index].startDate = format(date, "yyyy-MM-dd");
                    setMissions(newMissions);

                    // setMissions((prevData) => ({
                    //   ...prevData,
                    //   [index]: {
                    //     ...missions[index],
                    //     startDate: format(date, "yyyy-MM-dd"),
                    //   },
                    // }));
                  }
                } else {
                  return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: 200,
                    "& .MuiInputBase-input": {
                      borderColor: "rgb(176 190 197)",
                      // borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: "16px",
                      height: 9,
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "rgb(96 125 139)",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(176 190 197)",
                    },
                  }}
                />
              )}
              inputFormat="DD/MM/YYYY"
              minDate={new Date()}
            />
          </LocalizationProvider>
        </div>
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission end date"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>

          {/* <Popover placement="right">
            <PopoverHandler>
              <Input
                onChange={() => null}
                value={
                  formData.endDate
                    ? format(new Date(formData.endDate), "dd-MM-yyyy")
                    : ""
                }
                className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                color="blue-gray"
                placeholder="dd-mm-yyyy"
              />
            </PopoverHandler>
            <PopoverContent>
              <DayPicker
                locale={language}
                mode="single"
                selected={formData.endDate ? new Date(formData.endDate) : ""}
                onSelect={(date) => {
                  if (date) {
                    const newDate = new Date(date.getTime());
                    newDate.setFullYear(date.getFullYear() + 5);
                    newDate.setDate(newDate.getDate() - 1);
                  } else {
                    console.error("Date invalide :", date);
                  }
                  setFormData((prevData) => ({
                    ...prevData,
                    endDate: format(date, "yyyy-MM-dd"),
                  }));
                }}
                showOutsideDays
                className="border-0"
                classNames={{
                  caption:
                    "flex justify-center py-2 mb-4 relative items-center",
                  caption_label: "text-sm font-medium text-gray-900",
                  nav: "flex items-center",
                  nav_button:
                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                  nav_button_previous: "absolute left-1.5",
                  nav_button_next: "absolute right-1.5",
                  table: "w-full border-collapse",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "m-0.5 w-9 font-normal text-sm",
                  row: "flex w-full mt-2",
                  cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                  day: "h-9 w-9 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md bg-indigo-600 text-white hover:bg-indigo-600 hover:text-white focus:bg-indigo-600 focus:text-white",
                  day_today: "rounded-md bg-gray-200 text-gray-900",
                  day_outside:
                    "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                  day_disabled: "text-gray-500 opacity-50",
                  day_hidden: "invisible",
                }}
                components={{
                  IconLeft: ({ ...props }) => (
                    <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                  IconRight: ({ ...props }) => (
                    <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
                  ),
                }}
                modifiers={{
                  disabled: [
                    {
                      before: new Date(),
                    },
                  ],
                }}
                // formatters={{
                //   formatDate: (date) => format(date, "dd-MM-yyyy"),
                // }}
                month={
                  formData.endDate ? new Date(formData.endDate) : new Date()
                }
              />
            </PopoverContent>
          </Popover> */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n?.language}
          >
            <DatePicker
              value={
                formData.missionType == "simple" && formData.endDate
                  ? new Date(formData.endDate)
                  : formData.missionType == "compound" &&
                    missions[index].endDate
                  ? new Date(missions[index].endDate)
                  : null
              }
              onChange={(e) => {
                const date = new Date(e);
                if (!isNaN(date.getTime())) {
                  if (formData.missionType == "simple") {
                    setFormData((prevData) => ({
                      ...prevData,
                      endDate: format(date, "yyyy-MM-dd"),
                    }));
                  } else {
                    let newMissions = [...missions];
                    newMissions[index].endDate = format(date, "yyyy-MM-dd");
                    setMissions(newMissions);
                  }
                } else {
                  return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: 200,
                    "& .MuiInputBase-input": {
                      borderColor: "rgb(176 190 197)",
                      // borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: "16px",
                      height: 9,
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "rgb(96 125 139)",
                      },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(176 190 197)",
                    },
                  }}
                />
              )}
              inputFormat="DD/MM/YYYY"
              minDate={
                formData.missionType == "simple" && formData.startDate
                  ? new Date(formData.startDate)
                  : formData.missionType == "compound" &&
                    missions[index].startDate
                  ? new Date(missions[index].startDate)
                  : new Date()
              }
            />
          </LocalizationProvider>
        </div>

        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission country"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <Select
            options={countries}
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({ ...prevData, country: e }));
              } else {
                let newMissions = [...missions];

                newMissions[index].country = e;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.country
                : missions[index].country
            }
            placeholder={<Translation message="Mission country" />}
            isClearable={true}
            className="select-custom-width"
          />
        </div>

        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission city"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <Select
            options={
              formData.missionType == "simple" && formData.country
                ? allCities?.filter(
                    (item) => item.country === formData.country?._id
                  )
                : formData.missionType == "compound" && missions[index].country
                ? allCities?.filter(
                    (item) => item.country === missions[index].country?._id
                  )
                : []
            }
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({ ...prevData, city: e }));
              } else {
                let newMissions = [...missions];
                newMissions[index].city = e;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.city
                : missions[index].city
            }
            placeholder={<Translation message="Mission city" />}
            isClearable={true}
            className="select-custom-width"
          />
        </div>
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission manager"} />
          </Typography>
          <Select
            options={users}
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({ ...prevData, manager: e }));
              } else {
                let newMissions = [...missions];
                newMissions[index].manager = e;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.manager
                : missions[index].manager
            }
            placeholder={<Translation message="Mission manager" />}
            isClearable={true}
            className="select-custom-width"
          />
        </div>
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Mission partner"} />
          </Typography>
          <Select
            options={users}
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({ ...prevData, partner: e }));
              } else {
                let newMissions = [...missions];
                newMissions[index].partner = e;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.partner
                : missions[index].partner
            }
            placeholder={<Translation message="Mission partner" />}
            isClearable={true}
            className="select-custom-width"
          />
        </div>
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Formula"} />{" "}
            <p className="text-red-500">*</p>
          </Typography>
          <Select
            options={formules}
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({ ...prevData, formula: e }));
              } else {
                let newMissions = [...missions];
                newMissions[index].Formula = e;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.Formula
                : missions[index].Formula
            }
            placeholder={<Translation message="Formula" />}
            isClearable={true}
            className="select-width-300"
          />
        </div>
        <div>
          <Typography
            variant="small"
            className="flex gap-1 mb-2 font-medium text-gray-700"
          >
            <Translation message={"Customer"} />
          </Typography>
          <Input
            type="text"
            placeholder={t("Customer")}
            className=" border-t-blue-gray-200 focus:border-t-blue-gray-400"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            color="blue-gray"
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({
                  ...prevData,
                  customer: e.target.value,
                }));
              } else {
                let newMissions = [...missions];
                newMissions[index].customer = e.target.value;
                setMissions(newMissions);
              }
            }}
            value={
              formData.missionType == "simple"
                ? formData.customer
                : missions[index].customer
            }
          />
        </div>

        <div className="flex gap-10">
          <Checkbox
            ripple={true}
            className="h-5 w-5 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
            // label={t("Billable")}
            onChange={(e) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({
                  ...prevData,
                  chargeable: e.target.checked,
                }));
              } else {
                let newMissions = [...missions];
                newMissions[index].chargeable = e.target.checked;
                setMissions(newMissions);
              }
            }}
            checked={
              formData.missionType == "simple"
                ? formData.chargeable
                : missions[index].chargeable
            }
            color="indigo"
            label={
              <div>
                <Typography color="blue-gray" className="font-medium">
                  <Translation message="Billable" />
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  <Translation message="mission re-invoiced to the customer" />
                </Typography>
              </div>
            }
            containerProps={{
              className: "-mt-5",
            }}
          />
          <Checkbox
            ripple={true}
            className="h-5 w-5 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
            // label={t("Billable")}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                needTransportation: e.target.checked,
              }));
            }}
            checked={formData.needTransportation}
            color="indigo"
            label={
              <div>
                <Typography color="blue-gray" className="font-medium">
                  <Translation message="Need transportation" />
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  <Translation message="flight, train, etc" />
                </Typography>
              </div>
            }
            containerProps={{
              className: "-mt-5",
            }}
          />
          <Checkbox
            ripple={true}
            className="h-5 w-5 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
            // label={t("Billable")}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                firstTime: e.target.checked,
              }));
            }}
            checked={formData.firstTime}
            color="indigo"
            label={
              <div>
                <Typography color="blue-gray" className="font-medium">
                  <Translation message="First time travel" />
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className="font-normal"
                >
                  <Translation message="It's the first time that I travel with the company" />
                </Typography>
              </div>
            }
            containerProps={{
              className: "-mt-5",
            }}
          />
        </div>
        <div>
          <Typography
            variant="small"
            className="mb-2 font-medium text-gray-700"
          >
            <Translation
              message={"What expenses are covered by the customer"}
            />
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={
              formData.missionType == "simple"
                ? formData.customerSupported
                : missions[index].customerSupported
            }
            // exclusive
            onChange={(event, newAlignment) => {
              if (formData.missionType == "simple") {
                setFormData((prevData) => ({
                  ...prevData,
                  customerSupported: newAlignment,
                }));
              } else {
                let newMissions = [...missions];
                newMissions[index].customerSupported = newAlignment;
                setMissions(newMissions);
              }
            }}
          >
            <ToggleButton value="perdiem" style={{ outline: "none" }}>
              <Translation message="Perdiem" />
            </ToggleButton>
            <ToggleButton value="accomodation" style={{ outline: "none" }}>
              <Translation message="Accommodation" />
            </ToggleButton>
            <ToggleButton value="transport" style={{ outline: "none" }}>
              <Translation message="Transportation" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default Mission;

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-full w-full scale-105"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}
