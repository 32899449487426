import { useState } from "react";
import CalendarView from "./CalendarView";
import "./style.css";
import { users } from "./tasks";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonOff from "@mui/icons-material/PersonOff";
import { RadioGroup } from "@headlessui/react";
import ListView from "./ListView";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import NoTraceView from "./NoTraceView";
import Translation from "Translation";
import { useTranslation } from "react-i18next";

const color = "#294e87";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Section() {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [type, setType] = useState("list");
  const { t } = useTranslation();

  return (
    <>
      {/* <div className="pt-100">
        <CalendarView />
      </div> */}
      <div className="pt-100">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation message={"Want to keep track of your team's planning?"} />
                  </Typography>
                  {/* <Typography color="gray">
                    Choose team's members to see their schedules.
                  </Typography> */}
                </div>
                <div className="flex justify-center flex-row mt-5">
                  <RadioGroup value={type}>
                    <div className="grid grid-cols-8 gap-2 sm:grid-cols-3 ">
                      <RadioGroup.Option
                        key={"calendar"}
                        value={"calendar"}
                        className={({ active }) =>
                          classNames(
                            "bg-white shadow-sm text-gray-900 cursor-pointer",
                            active ? "ring-2 ring-indigo-500" : "",
                            "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                        onClick={() => {
                          setType("list");
                        }}
                        title= {t("List view")}
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              <ViewListIcon htmlColor={color} />
                              <span
                                className={classNames(
                                  type == "list" ? "border" : "border-2",
                                  type == "list"
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>

                      <RadioGroup.Option
                        key={"calendar"}
                        value={"calendar"}
                        onClick={() => {
                          setType("calendar");
                        }}
                        className={({ active }) =>
                          classNames(
                            "bg-white shadow-sm text-gray-900 cursor-pointer",
                            active ? "ring-2 ring-indigo-500" : "",
                            "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                        title={t("Calendar view")}
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              {/* <CalendarMonthIcon htmlColor={color} /> */}
                              <PermContactCalendarIcon htmlColor={color} />
                              <span
                                className={classNames(
                                  type == "calendar" ? "border" : "border-2",
                                  type == "calendar"
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>

                      <RadioGroup.Option
                        key={"calendar"}
                        value={"calendar"}
                        className={({ active }) =>
                          classNames(
                            "bg-white shadow-sm text-gray-900 cursor-pointer",
                            active ? "ring-2 ring-indigo-500" : "",
                            "group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6"
                          )
                        }
                        onClick={() => {
                          setType("listNoTrace");
                        }}
                        title="list of untraced members"
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">
                              <PersonOff htmlColor={color} />
                              <span
                                className={classNames(
                                  type == "listNoTrace" ? "border" : "border-2",
                                  type == "listNoTrace"
                                    ? "border-indigo-500"
                                    : "border-transparent",
                                  "pointer-events-none absolute -inset-px rounded-md"
                                )}
                                aria-hidden="true"
                              />
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>

          {/* {type == "list" ? "" : <CalendarView />} */}
        </div>

        {type == "list" ? (
          <ListView />
        ) : type == "calendar" ? (
          <CalendarView />
        ) : (
          <NoTraceView />
        )}
      </div>
    </>
  );
}
