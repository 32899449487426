// import { Typography, Icon, IconButton } from "@material-tailwind/react";
// import logo from "../../assets/img/logo_transparent.png";
// import "./style.css";
// // import { GlobeAltIcon, You } from "@heroicons/react/solid";
// const color = "#083985";

// export default function DefaultFooter() {
//   return (
//     <>
//       <footer
//         // style={{ position: "relative", bottom: 0, width: "100%" }}
//         className="bg-gray-100 pt-8 relative px-4 pt-8 pb-6"
//       >
//         <div className="container max-w-7xl mx-auto px-4">
//           <div className="flex flex-wrap text-center lg:text-left pt-6">
//             <div className="w-full lg:w-6/12 px-4">
//               <div className="-mt-4">
//                 <Typography variant="lead" color="blueGray">
//                   A new conception of work!
//                 </Typography>

//                 {/* <img className='logo-lineData' src={logo} width={150} /> */}
//               </div>
//               <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center">
//                 <a
//                   key={"index"}
//                   className="grid place-items-center bg-white text-pink-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
//                   href="https://workpoint.tn/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <IconButton color="white" className="rounded-full">
//                     <i className="fas fa-globe" style={{ color: "red" }} />
//                   </IconButton>
//                 </a>
//                 <a
//                   key={"index"}
//                   className="grid place-items-center bg-white text-pink-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
//                   href="https://www.youtube.com/watch?v=hTbHX9363Jo&ab_channel=WorkPoint"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <IconButton color="white" className="rounded-full">
//                     <i className="fab fa-youtube" style={{ color: "red" }}></i>
//                   </IconButton>
//                 </a>
//                 {/* <a
//                   href="https://workpoint.tn/"
//                   className="grid place-items-center bg-white text-pink-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <Icon family="font-awesome" name="fab fa-dribbble" />
//                 </a>
//                 <a
//                   href="https://www.youtube.com/watch?v=hTbHX9363Jo&ab_channel=WorkPoint"
//                   className="grid place-items-center bg-white text-red-600 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <Icon family="font-awesome" name="fab fa-youtube" />
//                 </a> */}
//               </div>
//             </div>
//           </div>
//           <hr className="my-6 border-gray-300" />
//           <div className="flex flex-wrap items-center md:justify-between justify-center">
//             <div className="w-full md:w-4/12 px-4 mx-auto text-center">
//               <div className="text-sm text-gray-700 font-medium py-1">
//                 Copyright © {new Date().getFullYear()} WorkPoint by{" "}
//                 <a
//                   href="https://x-point.tech/"
//                   className="text-gray-700 hover:text-gray-900 transition-all"
//                 >
//                   X-Point
//                 </a>
//                 .
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// }
import { Typography, Icon, IconButton } from "@material-tailwind/react";
import useMediaQuery from "useMediaQuery";
import logo from "../../assets/img/logo_transparent.png";
import "./style.css";
// import { GlobeAltIcon, You } from "@heroicons/react/solid";
const color = "#083985";

export default function DefaultFooter() {
  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      {}
      <footer
        // style={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}
        className={
          isDesktop
            ? "footer-web bg-gray-100  pt-4 pb-2"
            : "bg-gray-100  pt-4 pb-2"
        }
      >
        <div className="container max-w-7xl mx-auto px-4">
          <div className="flex flex-wrap  md:justify-between">
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <Typography variant="lead" color="blueGray" className="mr-5">
                A new work style!
              </Typography>
              <div className="flex gap-2  md:justify-start md:mb-0 mb-8 justify-center">
                <a
                  key={"index"}
                  className="grid place-items-center bg-white text-pink-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  href="https://work-point.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton color="white" className="rounded-full">
                    <i className="fas fa-globe" style={{ color: "red" }} />
                  </IconButton>
                </a>
                <a
                  key={"index"}
                  className="grid place-items-center bg-white text-pink-400 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  href="https://www.youtube.com/watch?v=nIApsJRHvKI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton color="white" className="rounded-full">
                    <i className="fab fa-youtube" style={{ color: "red" }}></i>
                  </IconButton>
                </a>
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-700 font-medium py-1">
                Copyright © {new Date().getFullYear()} WorkPoint by{" "}
                <a
                  href="https://x-point.tech/"
                  className="text-gray-700 hover:text-gray-900 transition-all"
                >
                  X-Point
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
