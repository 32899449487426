// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "@material-tailwind/react";
// import NavbarContainer from "@material-tailwind/react";
// import NavbarWrapper from "@material-tailwind/react";
// import NavbarBrand from "@material-tailwind/react";
// import NavbarToggler from "@material-tailwind/react";
// import NavbarCollapse from "@material-tailwind/react";
// import Nav from "@material-tailwind/react";
// import NavLink from "@material-tailwind/react";
// import Dropdown from "@material-tailwind/react";
// import DropdownItem from "@material-tailwind/react";
// import Icon from "@material-tailwind/react";
// import logo from "../../assets/img/logo_WP_web_bleu.png";
// import { logout } from "../../actions/UserAction";
// import Badge from "@mui/material/Badge";
// import openSocket from "socket.io-client";
// import jwt from "jwt-decode";

// // const color = '#052d6b'
// // const color = '#0d47a1'
// // const color = '#3f51b5'
// const color = "#083985";
// // const color = '#000063'

// // import Button from '@material-tailwind/react/components/Button';

// export default function DefaultNavbar() {
//   const [openNavbar, setOpenNavbar] = useState(false);
//   // const history = useHistory();
//   const storage = JSON.parse(localStorage.getItem("user"));
//   const token = storage.token;

//   const [user, setUser] = useState(jwt(token));
//   // const [ counter, setCounter ] = useState(0)
//   const [socket, setSocket] = useState(null);
//   const [notif, setNotif] = useState(0);

//   var counter = 0;

//   // const socket = openSocket("http://localhost:5000/", {
//   //     transports: ["websocket", "polling", "flashsocket"],
//   //     });

//   // useEffect(() => {
//   //     setSocket(openSocket("http://localhost:5000/", {transports: ["websocket", "polling", "flashsocket"]}))
//   // }, []);

//   // const getData = idUser => {
//   //     console.log(idUser);
//   //     if (user._id === idUser ) {
//   //         console.log('notiiiiiiiiiiiiiif')
//   //         console.log('counterbefore', counter)
//   //         setCounter(counter+1)
//   //         console.log('counterafter', counter)
//   //     }
//   // };

//   // useEffect(() => {

//   //     // socket?.on("change_counter", getData);
//   //     socket?.on('change_counter', (idUser) => {
//   //         console.log('msg', idUser)
//   //         if (user._id === idUser ) {
//   //             console.log('notiiiiiiiiiiiiiif')
//   //             // setCounter(idUser)
//   //             counter = counter +1
//   //             // console.log('counter', counter)

//   //         }
//   //     } )

//   //     // socket?.on('change_counter', (id) => {
//   //     //     console.log('hey')
//   //     //     setCounter(id);
//   //     // });

//   //     return () => socket?.disconnect();

//   // }, [socket, counter]);

//   // useEffect(() => {
//   //     console.log('kkkkkkk')
//   //    setNotif(counter)
//   // }, [counter]);

//   // console.log(counter)
//   // console.log('notif', notif)

//   const handleSignOut = async (event) => {
//     event.preventDefault();
//     await logout();
//   };

//   const handleClick = (e) => {
//     e.preventDefault();
//     // setCounter(0)
//   };

//   // setTimeout(() => {
//   //     setCounter(counter+1)
//   // }, 4000);

//   // console.log('counter', counter)

//   return (
//     <Navbar color="transparent" navbar>
//       <NavbarContainer>
//         <NavbarWrapper>
//           <a href="/home" target="_blank" rel="noreferrer">
//             <NavbarBrand color="gray">
//               {/* WORK POINT */}
//               <img src={logo} width={150} />
//             </NavbarBrand>
//           </a>
//           <NavbarToggler
//             onClick={() => setOpenNavbar(!openNavbar)}
//             color="white"
//           />
//         </NavbarWrapper>
//         <NavbarCollapse open={openNavbar}>
//           <Nav>
//             <div className="flex  flex-col z-50 lg:flex-row lg:items-center text-gray">
//               {/* <div className="text-gray">
//                                 <NavLink
//                                     href="#"
//                                     target="_blank"
//                                     rel="noreferrer"
//                                     ripple="light"
//                                 >
//                                     <Icon name="people" size="2xl"  />
//                                     &nbsp;<p className="text-gray-900">Team requests</p>
//                                 </NavLink>
//                             </div> */}
//               <div className="text-blue ">
//                 <div className="py-2.5 font-medium flex items-center">
//                   {/* <Icon name="dashboard" size="xl"  />
//                                     <Link to="/home">
//                                         <span className="text-gray-900 ml-2">
//                                             Home
//                                         </span>
//                                     </Link> */}
//                   <NavLink
//                     href="/home"
//                     // target="_blank"
//                     rel="noreferrer"
//                     ripple="light"
//                   >
//                     <Icon style={{ color: color }} name="dashboard" size="xl" />
//                     <span style={{ color: color }} className="ml-1">
//                       <b> Home</b>
//                     </span>
//                   </NavLink>
//                 </div>
//               </div>
//               <div className="text-blue">
//                 <div className="py-2.5 font-medium flex items-center">
//                   {/* <Icon name="calendar_today" size="xl"  />
//                                     <Link to="/schedule">
//                                         <span className="text-gray-900 ml-2">
//                                             My schedule
//                                         </span>
//                                     </Link> */}
//                   <NavLink
//                     href="/schedule"
//                     // target="_blank"
//                     rel="noreferrer"
//                     ripple="light"
//                   >
//                     <Icon
//                       style={{ color: color }}
//                       name="calendar_today"
//                       size="xl"
//                     />
//                     <span style={{ color: color }} className="ml-1">
//                       <b> My schedule</b>
//                     </span>
//                   </NavLink>
//                 </div>
//               </div>
//               <div className="text-blue ">
//                 <div className="py-2.5 font-medium flex items-center">
//                   <NavLink
//                     href="/requests"
//                     // target="_blank"
//                     rel="noreferrer"
//                     ripple="light"
//                   >
//                     <Icon
//                       style={{ color: color }}
//                       name="fa fa-file"
//                       family="font-awesome"
//                     />
//                     <span style={{ color: color }} className="ml-1">
//                       <b> My requests</b>
//                     </span>
//                   </NavLink>
//                 </div>
//               </div>
//               {(user.role === "manager" ||
//                 user.role === "manager_validator") && (
//                 <>
//                   <div className="text-gray">
//                     <Dropdown
//                       color="transparent"
//                       size="sm"
//                       buttonType="link"
//                       style={{ color: color }}
//                       buttonText={
//                         <div className="py-2.5 font-medium flex items-center">
//                           <Icon
//                             style={{ color: color }}
//                             name="people"
//                             size="2xl"
//                           />
//                           <span
//                             style={{ color: color }}
//                             className="text-gray-900 ml-2 dropdown-menu"
//                           >
//                             My Team
//                           </span>
//                         </div>
//                       }
//                       ripple="light"
//                     >
//                       <Link to="/team">
//                         <DropdownItem color="indigo">
//                           My Team's schedule
//                         </DropdownItem>
//                       </Link>
//                       <Link to="/teamRequests">
//                         <DropdownItem color="indigo">
//                           My Pending requests
//                         </DropdownItem>
//                       </Link>
//                     </Dropdown>
//                   </div>
//                   {/* <div className="text-gray  px-1">
//                                     <div className="py-2.5 font-medium flex items-center">

//                                         <NavLink
//                                             href="/team"
//                                             // target="_blank"
//                                             rel="noreferrer"
//                                             ripple="light"
//                                         >
//                                             <Icon style={{ color: color }} name="people" size="2xl"  />
//                                             <span style={{ color: color }} className=" ml-2">
//                                                 <b> My team</b>
//                                             </span>
//                                         </NavLink>
//                                         <NavLink
//                                             href="/teamRequests"
//                                             // target="_blank"
//                                             rel="noreferrer"
//                                             ripple="light"
//                                         >
//                                             <Icon style={{ color: color }} name="fa fa-file" family="font-awesome"   />
//                                             <span style={{ color: color }} className=" ml-2">
//                                                 <b> Team Requests</b>
//                                             </span>
//                                         </NavLink>

//                                 </div>
//                                 </div> */}
//                 </>
//               )}

//               <div className="text-gray">
//                 <Dropdown
//                   color="transparent"
//                   size="sm"
//                   buttonType="link"
//                   style={{ color: color }}
//                   buttonText={
//                     <div className="py-2.5 font-medium flex items-center">
//                       <Icon
//                         name="fa fa-user"
//                         // size="xl"
//                         family="font-awesome"
//                         style={{ color: color }}
//                       />
//                       <span
//                         style={{ color: color }}
//                         className="text-gray-900 ml-2 dropdown-menu"
//                       >
//                         {user.firstname} {user.lastname}
//                       </span>
//                     </div>
//                   }
//                   ripple="light"
//                 >
//                   <Link to="/profile">
//                     <DropdownItem color="indigo">Profile</DropdownItem>
//                   </Link>
//                   <Link to="/history">
//                     <DropdownItem color="indigo">History</DropdownItem>
//                   </Link>

//                   {/* <Link to="/login"> */}
//                   <DropdownItem color="indigo" onClick={handleSignOut}>
//                     Logout
//                   </DropdownItem>
//                   {/* </Link> */}
//                 </Dropdown>
//               </div>

//               {/* <div className="text-gray">
//                                 <Dropdown
//                                     color="transparent"
//                                     size="sm"
//                                     buttonType="link"
//                                     style={{ color: color }}
//                                     buttonText={
//                                         <div className="py-2.5 font-medium flex items-center">
//                                             {
//                                                 notif !== 0 ?
//                                                     <Badge badgeContent={notif} color="error" onClick={(e) => {handleClick(e)}} >
//                                                         <Icon
//                                                             name="notifications"
//                                                             size="xl"
//                                                             style={{ color: color }}
//                                                         />
//                                                     </Badge>
//                                                 :
//                                                     <Icon
//                                                         name="notifications"
//                                                         size="xl"
//                                                         style={{ color: color }}
//                                                     />

//                                             }

//                                         </div>
//                                     }
//                                     ripple="light"
//                                 >
//                                     <Link to="/profile">
//                                         <DropdownItem color="indigo">
//                                         <label
//                                                                 className="
//                                                                     items-center
//                                                                     flex
//                                                                     cursor-pointer
//                                                                     hover:bg-indigo-500 hover:text-white
//                                                                     text-gray-700
//                                                                     ease-linear
//                                                                     transition-all
//                                                                     duration-150
//                                                                     px-2
//                                                                     py-2

//                                                                 "
//                                                                 >
//                                                                 <i className="fas fa-bell"></i>
//                                                                 <p className='pl-5' >Amira accepted your sick leave</p>
//                                                 </label>
//                                         </DropdownItem>
//                                     </Link>

//                                     <Link to="/register">
//                                         <DropdownItem color="indigo">
//                                         <label
//                                                                 className="
//                                                                     items-center
//                                                                     flex
//                                                                     cursor-pointer
//                                                                     hover:bg-indigo-500 hover:text-white
//                                                                     text-gray-700
//                                                                     ease-linear
//                                                                     transition-all
//                                                                     duration-150
//                                                                     px-2
//                                                                     py-2

//                                                                 "
//                                                                 >
//                                                                 <i className="fas fa-bell"></i>
//                                                                 <p className='pl-5' >Amira accepted your sick leave</p>
//                                                 </label>
//                                         </DropdownItem>
//                                     </Link>
//                                 </Dropdown>
//                             </div> */}
//             </div>
//           </Nav>
//         </NavbarCollapse>
//       </NavbarContainer>
//     </Navbar>
//   );
// }

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Navbar as MTNavbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";

import jwt from "jwt-decode";

import logo from "../../assets/img/blue.png";
import routes from "../../routes";
import "./style.css";
import { logout } from "actions/UserAction";
// import { GlobeAltIcon } from "@heroicons/react/solid";
import i18n from "i18n";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
export function DefaultNavbar({ brandName, action }) {
  const [openNav, setOpenNav] = React.useState(false);
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwt(token);

  const { t } = useTranslation();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const handleSignOut = async (event) => {
    event.preventDefault();
    await logout();
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {routes.map(({ name, path, icon, target, childs, parent, isManager }) => {
        return isManager ? (
          user.role == "validator" || user.role == "manager_validator" ? (
            <Typography
              key={name}
              as="li"
              variant="small"
              color="inherit"
              className="capitalize"
            >
              {path ? (
                <Link
                  to={path}
                  target={target}
                  className="flex items-center gap-1 p-1 font-normal"
                >
                  {icon && (
                    // React.createElement(icon, {
                    //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                    // })
                    <i className={icon} />
                  )}
                  {/* {name} */}
                  <Translation message={name} />
                </Link>
              ) : (
                parent && (
                  <Menu>
                    <MenuHandler className="flex">
                      <button
                        style={{
                          backgroundColor: "transparent",
                          backgroundRepeat: "no-repeat",
                          border: "none",
                          outline: "none",
                          overflow: "hidden",
                        }}
                      >
                        {icon && (
                          // React.createElement(icon, {
                          //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                          // })
                          <i className={`${icon} mr-1 mt-1`} />
                        )}
                        {name == "Profile" ? (
                          user.firstname + " " + user.lastname
                        ) : (
                          <Translation message={name} />
                        )}
                      </button>
                    </MenuHandler>
                    <MenuList>
                      {childs.map((item) =>
                        item.path ? (
                          <Link
                            to={item.path}
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            <MenuItem className="flex">
                              {/* <Link
                              to={item.path}
                              target={target}
                              className="flex items-center gap-1 p-1 font-normal"
                            > */}{" "}
                              {item.icon && (
                                // React.createElement(item.icon, {
                                //   className:
                                //     "w-[18px] h-[18px] opacity-75 mr-1",
                                // })
                                <i className={item.icon} />
                              )}
                              {/* {item.name} */}
                              <Translation message={item.name} />
                              {/* </Link> */}
                            </MenuItem>
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            <MenuItem className="flex" onClick={handleSignOut}>
                              {/* <Link
                              to="#"
                              target={target}
                              className="flex items-center gap-1 p-1 font-normal"
                            > */}
                              {item.icon && (
                                // React.createElement(item.icon, {
                                //   className:
                                //     "w-[18px] h-[18px] opacity-75 mr-1",
                                // })
                                <i className={item.icon} />
                              )}
                              {/* {item.name} */}
                              <Translation message={item.name} />
                              {/* </Link> */}
                            </MenuItem>
                          </Link>
                        )
                      )}
                    </MenuList>
                  </Menu>
                )
              )}
            </Typography>
          ) : (
            ""
          )
        ) : (
          <Typography
            key={name}
            as="li"
            variant="small"
            color="inherit"
            className="capitalize"
          >
            {path ? (
              <Link
                to={path}
                target={target}
                className="flex items-center gap-1 p-1 font-normal"
              >
                {icon && (
                  // React.createElement(icon, {
                  //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                  // })
                  <i className={icon} />
                )}
                {/* {name} */}
                <Translation message={name} />
              </Link>
            ) : (
              parent && (
                <Menu>
                  <MenuHandler className="flex">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        backgroundRepeat: "no-repeat",
                        border: "none",
                        outline: "none",
                        overflow: "hidden",
                      }}
                    >
                      {icon && (
                        // React.createElement(icon, {
                        //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                        // })
                        <i className={`${icon} mr-1 mt-1`} />
                      )}
                      {name == "Profile" ? (
                        user.firstname + " " + user.lastname
                      ) : (
                        <Translation message={name} />
                      )}
                    </button>
                  </MenuHandler>
                  <MenuList>
                    {childs.map((item) =>
                      item.path ? (
                        <MenuItem className="flex">
                          <Link
                            to={item.path}
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            {" "}
                            {item.icon && (
                              // React.createElement(item.icon, {
                              //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                              // })
                              <i className={item.icon} />
                            )}
                            {/* {item.name} */}
                            <Translation message={item.name} />
                          </Link>
                        </MenuItem>
                      ) : (
                        <MenuItem className="flex" onClick={handleSignOut}>
                          <Link
                            to="#"
                            target={target}
                            className="flex items-center gap-1 p-1 font-normal"
                          >
                            {item.icon && (
                              // React.createElement(item.icon, {
                              //   className: "w-[18px] h-[18px] opacity-75 mr-1",
                              // })
                              <i className={item.icon} />
                            )}
                            {/* {item.name} */}
                            <Translation message={item.name} />
                          </Link>
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </Menu>
              )
            )}
          </Typography>
        );
      })}

      <Menu>
        <MenuHandler className="flex">
          <button
            style={{
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              border: "none",
              outline: "none",
              overflow: "hidden",
            }}
          >
            {/* {React.createElement(GlobeAltIcon, {
              className: "w-[18px] h-[18px] opacity-75 mr-1",
            })} */}
            <i className="fas fa-globe" />
          </button>
        </MenuHandler>
        <MenuList>
          <MenuItem
            className="flex"
            onClick={() => {
              i18n.changeLanguage("fr");
            }}
          >
            French
          </MenuItem>
          <MenuItem
            className="flex"
            onClick={() => {
              i18n.changeLanguage("en");
            }}
          >
            English
          </MenuItem>
        </MenuList>
      </Menu>
    </ul>
  );

  return (
    <MTNavbar color="transparent" className="p-3">
      <div className="container mx-auto flex items-center justify-between text-blue-dark">
        {/* <Link to="/"> */}
        <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-bold">
          <img
            src={logo}
            width={150}
            className="mr-4 ml-2 cursor-pointer py-1.5 font-bold"
          />
        </Typography>
        {/* </Link> */}
        <div className="hidden lg:block">{navList}</div>

        {/* <IconButton
          variant="text"
          size="sm"
          color="white"
          className="ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <MenuIcon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton> */}
      </div>
      <MobileNav
        className="rounded-xl bg-white px-4 pt-2 pb-4 text-blue-dark"
        open={openNav}
      >
        <div className="container mx-auto">
          {navList}

          {/* {React.cloneElement(action, {
            className: "w-full block",
          })} */}
        </div>
      </MobileNav>
    </MTNavbar>
  );
}

DefaultNavbar.defaultProps = {
  brandName: "Material Tailwind React",
  action: (
    <a
      // href="https://www.creative-tim.com/product/material-tailwind-kit-react"
      target="_blank"
    ></a>
  ),
};

DefaultNavbar.propTypes = {
  brandName: PropTypes.string,
  action: PropTypes.node,
};

DefaultNavbar.displayName = "/src/widgets/layout/navbar.jsx";

export default DefaultNavbar;
