import DefaultFooter from "../components/Footers/DefaultFooter";
// import FloorPlan from "components/Reservation/Floors/FloorPlan";
import FloorPlan from "components/Reservation/FloorPlan";
import DefaultNavbar from "../components/Navbars/DefaultNavbar";
export default function Floor() {
  return (
    <>
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <DefaultNavbar />
      </div>
      <main>
        <FloorPlan />
      </main>
      <DefaultFooter />
    </>
  );
}
