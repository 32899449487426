import { useState } from "react";

import { Typography, Button } from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationManager } from "react-notifications";
import "./form.css";
import { deleteClaim } from "../../actions/RequestAction";

import ConfirmDialog from "../ConfirmDialog";
import { useLocation } from "react-router-dom";
import jwt from "jwt-decode";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";

const color = "#294e87";

export default function Details(props) {
  const location = useLocation();
  const request = location.state;
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [loading, setloading] = useState(false);

  const handleDelete = async (id) => {
    setloading(true);
    const result = await deleteClaim(id, user.id);
    setConfirmDialog({ isOpen: false });

    if (result.status === 200) {
      NotificationManager.success("Deleted successfully ");
      setTimeout(() => {
        setloading(false);
        window.location.pathname = "/claims";
      }, 2000);
    } else {
      NotificationManager.error(result.message, "Error");
    }
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <section className="pb-20 relative block">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
          <div className="flex flex-wrap justify-center mt-5">
            <div className="w-full lg:w-10/12">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                <div className="flex-auto p-5 lg:p-10">
                  <div className="w-full text-center">
                    <Typography
                      variant={isDesktop ? "h3" : "h6"}
                      style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                    >
                      <Translation message={"Support request"} />
                      
                    </Typography>
                    <Typography color="gray">
                    <Translation message={"Consult the details of your claim."} />
                      
                    </Typography>
                  </div>

                  <div className="flex justify-center mb-5 mt-20">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"My comment"} />
                        
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {request?.CommentUser}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center mb-5 mt-20">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                       <Translation message={"Status"} />
                        
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                      {" "}
                      {request &&
                        request.status &&
                        (request.status === "processing"
                          ? <Translation message={"Processing"} />
                          : request.status === "closed"
                          ? <Translation message={"Closed"} />
                          : request.status === "pending"
                          ? <Translation message={"Pending"} />
                          : request.status)}{" "}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center mb-5 mt-20">
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                      <Translation message={"Admin's comment"} />
                        
                      </Typography>
                    </div>
                    <div className="w-full lg:w-4/12 px-6">
                      <Typography className=" text-sm  text-gray-700 pt-1">
                        {request?.CommentAdmin}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center mt-20">
                    <Button
                      color="indigo"
                      // ripple="light"
                      disabled={loading}
                      style={{ marginRight: "15px" }}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            "Are you sure to delete  this support request ?",
                          subtitle: "You can't undo this operation !",
                          onConfirm: () => {
                            handleDelete(request._id);
                          },
                        });
                      }}
                    >
                      {loading ? (
                        <ClipLoader color="white" loading={loading} size={20} />
                      ) : (
                        <><Translation message={"Delete Request"} /></>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      /> */}
    </>
  );
}
