import React, { useEffect, useState } from "react";

import { fetchManagerHistory } from "actions/UserAction";
import jwt from "jwt-decode";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import HomeWork from "@mui/icons-material/HomeWorkOutlined";
import Typography from "@mui/material/Typography";
import WorkOffIcon from "@mui/icons-material/WorkOff";
// import ToggleButton from './ToggleButton'
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import moment from "moment";
import Icon from "@material-tailwind/react";
import { Hail } from "@mui/icons-material";
import useMediaQuery from "useMediaQuery";
import Translation from "Translation";

const color = "#294e87";

// const requests = [
//     {
//         OperationType: 'Leave',
//         type: 'Sick',
//         date_debu: '25-05-2022',
//         endDate: '25-05-2022',
//         startSlot: 'full',
//         endSlot: 'full',
//         updatedAt: '22-04-2022 9:30 am',
//         TransactionType: 'VALIDATION',
//         user: {
//             firstname: 'Firas',
//             lastname: 'Salem',
//         },
//         createdAt: '23-05-2022',
//     },

//     {
//         OperationType: 'WFH',
//         date: '25-05-2022',
//         timeslot: 'AM',
//         updatedAt: '20-04-2022 9:30 pm',
//         TransactionType: 'REJECTION',
//         user: {
//             firstname: 'Rawen',
//             lastname: 'Mersani',
//         },
//         createdAt: '01-05-2022',
//     },
//     {
//         OperationType: 'Leave',
//         type: 'Sick',
//         date_debu: '25-05-2022',
//         endDate: '25-05-2022',
//         startSlot: 'full',
//         endSlot: 'full',
//         updatedAt: '20-04-2022 9:30 pm',
//         TransactionType: 'REJECTION',
//         user: {
//             firstname: 'Rawen',
//             lastname: 'Mersani',
//         },
//         createdAt: '01-05-2022',
//     },
//     {
//         OperationType: 'WFH',
//         date: '25-05-2022',
//         timeslot: 'AM',
//         updatedAt: '19-04-2022 9:30 pm',
//         TransactionType: 'VALIDATION',
//         user: {
//             firstname: 'Rawen',
//             lastname: 'Mersani',
//         },
//         createdAt: '01-05-2022',
//     },
//     {
//         OperationType: 'Leave',
//         type: 'Sick',
//         date_debu: '25-05-2022',
//         endDate: '25-05-2022',
//         startSlot: 'full',
//         endSlot: 'full',
//         updatedAt: '20-04-2022 9:30 pm',
//         TransactionType: 'REJECTION',
//         user: {
//             firstname: 'Rawen',
//             lastname: 'Mersani',
//         },
//         createdAt: '01-05-2022',
//     },
// ]

export default function TeamTab() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const decodedToken = jwt(token);
  const date = new Date();
  var startDate = new Date("2020-01-01");
  var endDate = new Date("2020-01-01");
  endDate.setMonth(date.getMonth() + 1);
  endDate.setFullYear(date.getFullYear());
  startDate.setFullYear(date.getFullYear());
  startDate.setMonth(date.getMonth());

  const month = date.getMonth() + 1;

  const [open, setOpen] = useState(false);

  const [user, setUser] = useState({});
  const [history, setHistory] = useState([]);

  const [type, setType] = React.useState("Leave");

  const handleChange = (event, newAlignment) => {
    setType(newAlignment);
  };

  const [filter, setFilter] = React.useState();

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchManagerHistory();
      setHistory(result);
    };
    fetch();
  }, []);

  useEffect(() => {
    history.length != 0 &&
      setFilter(history?.filter((item) => item.Request.name == type));
  }, [type, history]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <>
      <div className="mb-10 py-2 border-t border-gray-200">
        {/* <div  className='mt-16' style={{float: 'right', paddingRight: '2em'}}><ToggleButton /></div> */}
        <div className="flex flex-wrap justify-center mt-16">
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={handleChange}
          >
            {/* <ToggleButton value="Leave" style={{ outline: "none" }}>
              <WorkOffIcon />{" "}
            </ToggleButton> */}
            <ToggleButton value="WFH" style={{ outline: "none" }}>
              <HomeWork />
            </ToggleButton>
            <ToggleButton value="Client site" style={{ outline: "none" }}>
              <Hail />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="flex flex-wrap ">
          <div className="w-full lg:w-12/12 px-4 flex flex-col ">
            {filter && filter.length != 0 ? (
              <div className="mt-20">
                <Timeline
                  align="center"
                  style={{
                    marginLeft: isDesktop ? 0 : "-80%",
                    // position: "fixed",
                    // left: -20,
                  }}
                >
                  {filter &&
                    filter.map((request) => {
                      return (
                        <>
                          <TimelineItem>
                            {isDesktop && (
                              <TimelineOppositeContent
                                style={{ flex: 0.1 }}
                                sx={{ m: "auto 0" }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                              >
                                {moment(request.updatedAt).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                                {/* {request.updatedAt.substring(0,10).split('-').reverse().join('-') + ' ' + request.updatedAt.substring(11,16)} */}
                              </TimelineOppositeContent>
                            )}

                            <TimelineSeparator>
                              {/* <TimelineConnector /> */}
                              <TimelineDot
                                sx={{
                                  bgcolor:
                                    request.Request.name == "Leave"
                                      ? "#c6d13e"
                                      : request.Request.name == "Client site"
                                      ? "#b741c4"
                                      : "#2ec6ff",
                                }}
                              >
                                {request.Request.name == "Leave" ? (
                                  <WorkOffIcon />
                                ) : request.Request.name == "Client site" ? (
                                  <Hail />
                                ) : (
                                  <HomeWork />
                                )}
                              </TimelineDot>
                              <TimelineConnector sx={{ bgcolor: "#d9d9d9" }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              {request.Request.name == "Leave" ? (
                                <div className="content_timeline">
                                  <div>
                                    <Typography>
                                      <b>{request.Request?.type}</b>
                                    </Typography>
                                    <Typography color="text.secondary">
                                      {" "}
                                      {/* <Icon color="gray" name="person" />{" "} */}
                                      {request.user?.firstname}{" "}
                                      {request.user?.lastname}
                                    </Typography>
                                    <Typography color="text.secondary">
                                      {" "}
                                      {/* <Icon color="gray" name="event" />{" "} */}
                                      {request.Request?.startDate
                                        ?.toString()
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}{" "}
                                      {request.Request?.startSlot}{" "}
                                      <i className="fas fa-light fa-arrow-right" />{" "}
                                      {request.Request?.endDate
                                        ?.toString()
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}{" "}
                                      {request.Request?.endSlot}
                                    </Typography>
                                    <Typography color="text.secondary">
                                      New Balance :{" "}
                                      <b>{request.Request?.NewLeaveBalance}</b>
                                    </Typography>
                                  </div>

                                  <div className="footer-right">
                                    {request.TransactionType ==
                                    "LEAVE_CANCELLATION" ? (
                                      <Typography fontSize={14} color="red">
                                        Cancellation
                                      </Typography>
                                    ) : request.TransactionType ==
                                      "LEAVE_SUBMISSION" ? (
                                      <Typography fontSize={14} color="orange">
                                        Submission
                                      </Typography>
                                    ) : request.TransactionType ==
                                      "LEAVE_REVIEWING" ? (
                                      <Typography fontSize={14} color="orange">
                                        Edition
                                      </Typography>
                                    ) : request.TransactionType ==
                                      "LEAVE_APPROVAL" ? (
                                      <Typography fontSize={14} color="green">
                                        Approval
                                      </Typography>
                                    ) : request.TransactionType ==
                                      "LEAVE_REJECTION" ? (
                                      <Typography fontSize={14} color="red">
                                        Rejection
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                    <div className="content_timeline">
                                      <Typography color="text.secondary">
                                        {request.Request?.oldLeaveBalance}{" "}
                                      </Typography>
                                      {Math.sign(
                                        request.Request?.NewLeaveBalance -
                                          request.Request?.oldLeaveBalance
                                      ) == -1 ? (
                                        <Typography className="pl-2">
                                          {" "}
                                          (
                                          {request.Request?.NewLeaveBalance -
                                            request.Request?.oldLeaveBalance}
                                          ){" "}
                                        </Typography>
                                      ) : (
                                        <Typography className="pl-2">
                                          {" "}
                                          (+
                                          {request.Request?.NewLeaveBalance -
                                            request.Request?.oldLeaveBalance}
                                          ){" "}
                                        </Typography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>
                                      <b>
                                        {request.Request.name ==
                                        "Client site" ? (
                                          <Translation
                                            message={"Third party site"}
                                          />
                                        ) : request.Request.name == "WFH" ? (
                                          <Translation message={"WFH"} />
                                        ) : request.Request.name ==
                                          "REMOTE_WORKING" ? (
                                          <Translation
                                            message={"REMOTE_WORKING"}
                                          />
                                        ) : (
                                          request.Request.name
                                        )}
                                      </b>
                                    </Typography>

                                    {request.TransactionType.includes(
                                      "CANCELLATION"
                                    ) ? (
                                      <Typography fontSize={14} color="red">
                                        <Translation message={"Cancellation"} />
                                      </Typography>
                                    ) : request.TransactionType.includes(
                                        "SUBMISSION"
                                      ) ? (
                                      <Typography fontSize={14} color="orange">
                                        <Translation message={"Submission"} />
                                      </Typography>
                                    ) : request.TransactionType.includes(
                                        "REVIEWING"
                                      ) ? (
                                      <Typography fontSize={14} color="orange">
                                        <Translation message={"Edition"} />
                                      </Typography>
                                    ) : request.TransactionType.includes(
                                        "APPROVAL"
                                      ) ? (
                                      <Typography fontSize={14} color="green">
                                        <Translation message={"Approval"} />
                                      </Typography>
                                    ) : request.TransactionType.includes(
                                        "REJECTION"
                                      ) ? (
                                      <Typography fontSize={14} color="red">
                                        <Translation message={"Rejection"} />
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <Typography color="text.secondary">
                                    {/* <Icon color="gray" name="person" />{" "} */}
                                    <i className="far fa-user mr-2" />
                                    {request.user?.firstname}{" "}
                                    {request.user?.lastname}
                                  </Typography>
                                  <Typography color="text.secondary">
                                    {/* <Icon color="gray" name="event" />{" "} */}
                                    <i className="far fa-calendar mr-2" />
                                    {request.Request?.dates?.map((date) => {
                                      return (
                                        date?.day
                                          ?.substring(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-") +
                                        " " +
                                        date?.timeslot +
                                        ", "
                                      );
                                    })}
                                  </Typography>
                                  {request.Request.name == "Client site" && (
                                    <Typography color="text.secondary">
                                      {/* <Icon color="gray" name="location_on" />{" "} */}
                                      <i className="fas fa-map-pin mr-2" />
                                      {request.Request?.client}{" "}
                                    </Typography>
                                  )}

                                  <Typography color="text.secondary">
                                    <Translation message={"Sent: "} />{" "}
                                    {request.createdAt
                                      .substring(0, 10)
                                      .split("-")
                                      .reverse()
                                      .join("-") +
                                      " " +
                                      request.createdAt.substring(11, 16)}{" "}
                                  </Typography>
                                  {!isDesktop && (
                                    <>
                                      <Typography color="text.secondary">
                                        <Translation message={"Last update:"} />{" "}
                                        {moment(request.updatedAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}
                                      </Typography>
                                      {/* <div
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                        }}
                                      >
                                        {request.TransactionType.includes(
                                          "CANCELLATION"
                                        ) ? (
                                          <Typography fontSize={14} color="red">
                                            Cancellation
                                          </Typography>
                                        ) : request.TransactionType.includes(
                                            "SUBMISSION"
                                          ) ? (
                                          <Typography
                                            fontSize={14}
                                            color="orange"
                                          >
                                            Submission
                                          </Typography>
                                        ) : request.TransactionType.includes(
                                            "REVIEWING"
                                          ) ? (
                                          <Typography
                                            fontSize={14}
                                            color="orange"
                                          >
                                            Edition
                                          </Typography>
                                        ) : request.TransactionType.includes(
                                            "APPROVAL"
                                          ) ? (
                                          <Typography
                                            fontSize={14}
                                            color="green"
                                          >
                                            Approval
                                          </Typography>
                                        ) : request.TransactionType.includes(
                                            "REJECTION"
                                          ) ? (
                                          <Typography fontSize={14} color="red">
                                            Rejection
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </div> */}
                                    </>
                                  )}
                                </div>
                              )}
                              {request.isSystemOp == true && (
                                <Typography color="text.secondary">
                                  <Translation message={"System operation"} />
                                </Typography>
                              )}
                            </TimelineContent>
                          </TimelineItem>
                        </>
                      );
                    })}
                  {/* <Timeline  >
                                                            
                                                           
                                                           
                                                            <TimelineItem>
                                                                <TimelineOppositeContent
                                                                sx={{ m: 'auto 0' }}
                                                                align="right"
                                                                variant="body2"
                                                                color="text.secondary"
                                                                >
                                                                20-04-2022 9:30 pm
                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator>
                                                                <TimelineConnector />
                                                                <TimelineDot color="error">
                                                                    <WorkOffIcon />
                                                                </TimelineDot>
                                                                <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                                <Typography>
                                                                    <b>REJECTION</b>
                                                                </Typography>
                                                                <Typography color="text.secondary">Rawen Mersani wants wants to take a <b>Sick</b> leave on 25-05-2022 all day </Typography>
                                                                <Typography color="text.secondary">Sent : 01-05-2022 </Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                            <TimelineItem>
                                                                <TimelineOppositeContent
                                                                sx={{ m: 'auto 0' }}
                                                                align="right"
                                                                variant="body2"
                                                                color="text.secondary"
                                                                >
                                                                19-04-2022 9:30 am
                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator>
                                                                <TimelineConnector />
                                                                <TimelineDot color="success">
                                                                    <WorkOffIcon />
                                                                </TimelineDot>
                                                                <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                                <Typography >
                                                                    <b>VALIDATION</b>
                                                                </Typography>
                                                                <Typography color="text.secondary">Rawen Mersani wants wants to take a <b>Sick</b> leave on 25-05-2022 all day </Typography>
                                                                <Typography color="text.secondary">Sent : 01-05-2022 </Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                            <TimelineItem>
                                                                <TimelineOppositeContent
                                                                sx={{ m: 'auto 0' }}
                                                                align="right"
                                                                variant="body2"
                                                                color="text.secondary"
                                                                >
                                                                19-04-2022 9:30 am
                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator>
                                                                <TimelineConnector />
                                                                <TimelineDot color="success">
                                                                    <HomeWork />
                                                                </TimelineDot>
                                                                <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                                <Typography >
                                                                    <b>VALIDATION</b>
                                                                </Typography>
                                                                <Typography color="text.secondary">Rawen Mersani wants wants to work remotely on 25-05-2022 all day </Typography>
                                                                <Typography color="text.secondary">Sent : 01-05-2022 </Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                            <TimelineItem>
                                                                <TimelineOppositeContent
                                                                sx={{ m: 'auto 0' }}
                                                                align="right"
                                                                variant="body2"
                                                                color="text.secondary"
                                                                >
                                                                19-04-2022 9:30 am
                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator>
                                                                <TimelineConnector />
                                                                <TimelineDot color="success">
                                                                    <HomeWork />
                                                                </TimelineDot>
                                                                <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                                <Typography >
                                                                    <b>VALIDATION</b>
                                                                </Typography>
                                                                <Typography color="text.secondary">Rawen Mersani wants wants to work remotely on 25-05-2022 all day </Typography>
                                                                <Typography color="text.secondary">Sent : 01-05-2022 </Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                          
                                                            </Timeline> */}
                </Timeline>
              </div>
            ) : (
              <div className="flex flex-wrap justify-center mt-16">
                <Typography color="text.secondary">
                  <Translation message={"No history"} />{" "}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
