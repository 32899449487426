import React, { useState } from "react";
import {
  Tabs,
  Tab,
  TabsHeader,
  TabsBody,
  TabPanel,
  ThemeProvider,
} from "@material-tailwind/react";
// import TabList from "@material-tailwind/react";
// import TabItem from "@material-tailwind/react";
// import TabContent from "@material-tailwind/react";
// import TabPane from "@material-tailwind/react";
import HomeWork from "@mui/icons-material/HomeWork";
import WFH from "./WFH";
import "./form.css";
import { Flight, Hail, WorkOff } from "@mui/icons-material";
import ClientSite from "./ClientSite";
import Translation from "Translation";
import Leave from "./Leave";
import { fetchSetting } from "actions/SettingAction";
import { FaPlane } from "react-icons/fa";
import Travel from "./Travel";

const data = [
  {
    name: "WFH",
    label: <Translation message={"WFH"} />,
    value: "#/WFH",
    link: "#/WFH",
    icon: <HomeWork fontSize="medium" className="mr-2" />,
    desc: <WFH />,
    active: window.location.hash.indexOf("/WFH") != -1 ? true : false,
  },
  {
    name: "Third party site",
    label: <Translation message={"Third party site"} />,
    value: "#/ThirdPartySite",
    link: "#/ThirdPartySite",
    icon: <Hail fontSize="medium" className="mr-2" />,
    active:
      window.location.hash.indexOf("/ThirdPartySite") != -1 ? true : false,
    desc: <ClientSite />,
  },

  {
    name: "Leave",
    label: <Translation message={"Leave"} />,
    value: "#/Leave",
    link: "#/Leave",
    icon: <WorkOff fontSize="medium" className="mr-2" />,
    active: window.location.hash.indexOf("/Leave") != -1 ? true : false,
    desc: <Leave />,
  },
  {
    name: "Travel",
    label: <Translation message={"Travel"} />,
    value: "#/travel",
    link: "#/travel",
    icon: <Flight fontSize="medium" className="mr-2" />,
    active: window.location.hash.indexOf("/travel") != -1 ? true : false,
    desc: <Travel />,
  },
];
export default function MUITabs() {
  const [features, setFeatures] = useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSetting("features");
      var arr = [];
      data?.map((item) => {
        if (
          result[0].Web?.find((value) => value.name == item.name) &&
          !features.find((value) => value.name == item.name)
        ) {
          arr.push(item);
          // setFeatures((prevFeatures) => [...prevFeatures, item]);
        }
      });
      // setFeatures(result[0]?.Web);
      setFeatures(arr);
    };

    fetchData();
  }, []);

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <Tabs style={{ width: "100%" }} value={window.location.hash}>
        <TabsHeader
          className="tab-content"
          color="indigo"
          style={{ height: 50 }}
        >
          {features.map(({ label, value, link, icon, active }) => (
            <a href={link}>
              <Tab
                key={value}
                value={value}
                style={{ color: "rgb(8, 57, 133)" }}
                // className={`mr-2 ${active && "active"} `}
                // className="mr-2"
                className="uppercase mr-20"
              >
                {icon}
                {label}
              </Tab>
            </a>
          ))}
        </TabsHeader>
        <TabsBody
          animate={{
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
        {/* <TabList className="tab-content" color="indigo">
          <a href="#/WFH">
            <TabItem
              ripple="light"
              active={window.location.hash.indexOf("/WFH") != -1 ? true : false}
            >
              <HomeWork fontSize="medium" />
              WFH
            </TabItem>
          </a>

          <a href="#/ThirdPartySite">
            <TabItem
              ripple="light"
              active={
                window.location.hash.indexOf("/ThirdPartySite") != -1
                  ? true
                  : false
              }
            >
              <Hail fontSize="medium" />
              Third Party Site
            </TabItem>
          </a>
        </TabList>

        <TabContent>
          <TabPane
            active={window.location.hash.indexOf("/WFH") != -1 ? true : false}
          >
            <WFH />
          </TabPane>

          <TabPane
            active={
              window.location.hash.indexOf("/ThirdPartySite") != -1
                ? true
                : false
            }
          >
            <ClientSite />
          </TabPane>
        </TabContent> */}
      </Tabs>
      {/* </ThemeProvider> */}
    </>
  );
}
