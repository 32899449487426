import { Typography } from "@material-tailwind/react";
import Translation from "Translation";
import { getVisaType } from "actions/travel.service";
import React, { useEffect } from "react";

const VisaPanel = (props) => {
  const { request, formData, missionData, missions } = props;
  const [suggestedVisas, setSuggestedVisas] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (missionData?.missionType == "compound") {
        const uniqueItems = new Set();

        for (const item of missions) {
          const res = await getVisaType("TN", item.country?.country_code);
          console.log("🚀 ~ fetchData ~ res:", res);
          const text = `${item?.country?.name} : ${res.category} `;
          uniqueItems.add(text);
        }
        setSuggestedVisas(Array.from(uniqueItems).join(", "));
      }
    };

    fetchData();
  }, [missions]);
  return (
    <div>
      {missionData?.missionType == "simple" ? (
        <div className="flex gap-8 mb-1 mt-5">
          <div className="">
            <Typography className=" text-sm  text-gray-700 pt-1">
              <Translation message="Required visa for the mission's country" />
            </Typography>
          </div>
          <Typography className=" text-sm  text-gray-700 pt-1">
            {request?.mission?.visa?.visa
              ? request?.mission?.visa?.visa?.name
              : formData.missionCountry?.visa?.name}{" "}
            (
            {request?.mission?.visa?.visa
              ? request?.mission?.visa?.visa?.type
              : formData.missionCountry?.visa?.type}
            )
          </Typography>
        </div>
      ) : (
        <div className="flex gap-8 mb-1 mt-5">
          <div className="">
            <Typography className=" text-sm  text-gray-700 pt-1">
              <Translation message="Required visas for the mission's countries" />
            </Typography>
          </div>
          <Typography className=" text-sm  text-gray-700 pt-1">
            {/* {missions?.map((item) => {
              return `${item?.country?.name} : ${item?.country?.visa?.name} (${item?.country?.visa?.type})`;
            })} */}
            {suggestedVisas}
          </Typography>
        </div>
      )}
      <div className="flex gap-8 mb-2 mt-1">
        <Typography className=" text-sm  text-gray-700 pt-1">
          <Translation message="For more information about visas you can visit" />{" "}
          <b>
            <a
              href="https://www.passportindex.org/passport/tunisia/"
              target="_blank"
              rel="noreferrer"
            >
              <Translation message="this link" />
            </a>{" "}
          </b>
          <Translation message="and pick your destination's country" />
        </Typography>
      </div>
    </div>
  );
};

export default VisaPanel;
