import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useMediaQuery from "useMediaQuery";
import {
  Breadcrumbs,
  Carousel,
  Input,
  Typography,
} from "@material-tailwind/react";
import { CalendarMonth, Home, PersonPin } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import { DateRange } from "react-date-range";
import { fetchAccesszone } from "actions/ZoneAction";
import jwt from "jwt-decode";
import Floor from "./Floor";
import moment from "moment";
import { fetchHorizon } from "actions/RessourcesAction";
import { Space } from "react-zoomable-ui";
import Translation from "Translation";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { t } from "i18next";

const color = "#083985";

export default function Section() {
  const [searchParams] = useSearchParams();
  // const [startDate, setStartDate] = useState(searchParams.get("startDate"));
  // const [endDate, setEndDate] = useState(searchParams.get("endDate"));
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [period, setPeriod] = useState([
    {
      startDate: new Date(searchParams.get("startDate")),
      endDate: new Date(searchParams.get("endDate")),
      key: "selection",
    },
  ]);

  const [guestReservation, setGuestReservation] = useState({
    book: false,
    name: "",
    email: "",
  });
  const [showGuest, setShowGuest] = useState(false);
  var [access, setAccess] = useState();
  const [horizon, setHorizon] = useState();
  const [guestErrors, setGuestErrors] = useState({ name: null, email: null });

  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const user = jwt(token);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchHorizon();
      setHorizon(data.NBDays);
    };
    fetch();
  }, []);

  // useEffect(() => {
  //   setStartDate(period[0].startDate.toISOString().substring(0, 10));
  //   setEndDate(period[0].endDate.toISOString().substring(0, 10));
  // }, [period]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchAccesszone("", user.id);
      // console.log(result, "access");
      let data = Array.from(result, ({ floor_num }) => floor_num);
      // setAccess([...new Set(data)]);
      setAccess(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <Link to="/reservation" className="opacity-60">
                  <span>Flex Office</span>
                </Link>
                {/* <a href="#">Building </a> */}
              </Breadcrumbs>
            </div>
          )}
          <div className="w-full lg:w-12/12 px-4">
            <div className="w-full text-center mt-20">
              {/* <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} /> */}

              <Typography
                variant={isDesktop ? "h3" : "h6"}
                style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
              >
                <Translation message={"Are you planning to work on site?"} />
              </Typography>
              <Typography color="gray">
                <Translation message={"Swipe the floors and choose a desk."} />
              </Typography>

              <ToggleButtonGroup
                color="primary"
                // value={alignment}
                // exclusive
                // onChange={handleChange}
                aria-label="Platform"
                className="mt-5"
              >
                <ToggleButton
                  value="calendar"
                  title="Choose the period to book"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <CalendarMonth htmlColor={color} className="cursor" />
                </ToggleButton>
                {user.role === "manager" ||
                  (user.role === "manager_validator" && (
                    <ToggleButton
                      value="guest"
                      title="Book a desk for a guest"
                      onClick={() => {
                        setShowGuest(!showGuest);
                        setGuestReservation({
                          ...guestReservation,
                          book: !guestReservation.book,
                        });
                      }}
                      selected={guestReservation.book}
                    >
                      <PersonPin
                        htmlColor={color}
                        className="cursor"

                        // selected={selected}
                      />
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>

              <div>
                {showGuest && (
                  // <div className="flex text-center mt-20">
                  //   <div style={{ width: "200px" }}>
                  <center className="mt-5">
                    <Typography color="gray">
                      <Translation message="You have activated someone else's reservation, please enter their information and choose the position to book" />
                    </Typography>
                    <div
                      className="flex mt-5"
                      style={{ width: "700px", justifyContent: "space-around" }}
                    >
                      <div className="w-300">
                        <Input
                          color="indigo"
                          value={guestReservation.name}
                          onChange={(e) => {
                            setGuestReservation({
                              ...guestReservation,
                              name: e.target.value,
                            });
                            setGuestErrors({ name: null });
                          }}
                          label={t("Guest name")}
                          type="text"
                          error={guestErrors.name != null}
                        />
                        {guestErrors.name != null && (
                          <Typography
                            variant="small"
                            color="red"
                            className="mt-2 flex items-center gap-1 font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="red"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {guestErrors.name}
                          </Typography>
                        )}
                      </div>
                      <div className="ml-2 w-300">
                        <Input
                          color="indigo"
                          value={guestReservation.email}
                          onChange={(e) => {
                            setGuestReservation({
                              ...guestReservation,
                              email: e.target.value,
                            });
                            setGuestErrors({ email: null });
                          }}
                          label={t("Guest email")}
                          type="email"
                          error={guestErrors.email != null}
                        />
                        {guestErrors.email != null && (
                          <Typography
                            variant="small"
                            color="red"
                            className="mt-2 flex items-center gap-1 font-normal"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="red"
                              className="-mt-px h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {guestErrors.email}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </center>
                )}
              </div>

              <div className="gap-12 mt-16">
                {show && (
                  <DateRange
                    onChange={(item) => setPeriod([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={period}
                    direction={isDesktop ? "horizontal" : "vertical"}
                    rangeColors={["#1976d2"]}
                    maxDate={moment().add("days", horizon - 1)._d}
                    minDate={new Date()}
                  />
                )}
              </div>

              {isDesktop ? (
                <>
                  <div className="flex flex-wrap justify-center  gap-4 mb-6 mt-10">
                    {loading ? (
                      <ClipLoader color="blue" loading={loading} size={50} />
                    ) : (
                      // <div
                      //   id="carouselExampleCaptions"
                      //   className="carousel slide relative"
                      //   style={{ width: "100%" }}
                      //   data-bs-interval="false"
                      //   // data-bs-ride="carousel"
                      // >
                      //   <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                      //     {access.map((floor, i) => {
                      //       return (
                      //         <button
                      //           type="button"
                      //           data-bs-target="#carouselExampleCaptions"
                      //           data-bs-slide-to={i}
                      //           className={`${i == 0 && "active"}`}
                      //           aria-current="true"
                      //           aria-label={floor}
                      //           style={{ backgroundColor: "#083985" }}
                      //         ></button>
                      //       );
                      //     })}
                      //   </div>
                      //   <div className="carousel-inner relative w-full overflow-hidden">
                      //     {access.map((floor, i) => {
                      //       return (
                      //         <div
                      //           className={`carousel-item ${
                      //             i == 0 ? "active" : ""
                      //           } relative float-left w-full`}
                      //         >
                      //           <div className="block w-full">
                      //             {/* <Building floor={sorted} /> */}
                      //             <Floor
                      //               num={floor}
                      //               start={moment(period[0].startDate)
                      //                 .format()
                      //                 .substring(0, 10)}
                      //               end={moment(period[0].endDate)
                      //                 .format()
                      //                 .substring(0, 10)}
                      //             />
                      //           </div>
                      //           <div className="carousel-caption hidden md:block absolute text-center">
                      //             <h5
                      //               style={{ color: "#083985" }}
                      //               className="text-xl"
                      //             >
                      //               {floor}
                      //             </h5>
                      //           </div>
                      //         </div>
                      //       );
                      //     })}
                      //   </div>
                      //   <button
                      //     className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline "
                      //     type="button"
                      //     // data-bs-target="#carouselExampleCaptions"
                      //     // data-bs-slide="prev"
                      //     style={{
                      //       marginRight: "100px",
                      //       width: "50px",
                      //     }}
                      //   >
                      //     <span
                      //       className="carousel-control-prev-icon inline-block bg-no-repeat"
                      //       aria-hidden="true"
                      //       data-bs-target="#carouselExampleCaptions"
                      //       data-bs-slide="prev"
                      //       style={{
                      //         backgroundColor: "#083985",
                      //       }}
                      //     ></span>
                      //     <span class="visually-hidden">Previous</span>
                      //   </button>
                      //   <button
                      //     class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                      //     type="button"
                      //     // data-bs-target="#carouselExampleCaptions"
                      //     // data-bs-slide="next"
                      //     style={{
                      //       // marginLeft: "100px",
                      //       width: "50px",
                      //     }}
                      //   >
                      //     <span
                      //       class="carousel-control-next-icon inline-block bg-no-repeat"
                      //       aria-hidden="true"
                      //       style={{
                      //         backgroundColor: "#083985",
                      //       }}
                      //       data-bs-target="#carouselExampleCaptions"
                      //       data-bs-slide="next"
                      //     ></span>
                      //     <span class="visually-hidden">Next</span>
                      //   </button>
                      // </div>

                      <Carousel
                        className="rounded-xl"
                        navigation={({
                          setActiveIndex,
                          activeIndex,
                          length,
                        }) => (
                          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                              <span
                                key={i}
                                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                  activeIndex === i
                                    ? "bg-white w-8"
                                    : "bg-white/50 w-4"
                                }`}
                                onClick={() => setActiveIndex(i)}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {access.map((item, i) => {
                          return (
                            <div className="relative h-full w-full">
                              <Floor
                                start={moment(period[0].startDate)
                                  .format()
                                  .substring(0, 10)}
                                end={moment(period[0].endDate)
                                  .format()
                                  .substring(0, 10)}
                                setGuestErrors={setGuestErrors}
                                guestReservation={guestReservation}
                                floor={item.floor}
                              />
                              <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                <Typography
                                  variant="h5"
                                  color="indigo"
                                  className="mb-4"
                                >
                                  {item.floor_num}
                                </Typography>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                </>
              ) : loading ? (
                <ClipLoader color="blue" loading={loading} size={50} />
              ) : (
                // <div className="flex flex-wrap justify-center  gap-4 mb-6">
                // <div
                //   style={{ width: "100%", height: 600, position: "relative" }}
                // >
                //   <Space
                //     onCreate={(vp) =>
                //       vp.setBounds({ x: [0, 600], y: [0, 600] })
                //     }
                //   >
                <>
                  <div
                    id="carouselExampleCaptions"
                    className="carousel slide relative"
                    // data-bs-ride="carousel"
                    data-bs-interval="false"
                  >
                    <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
                      {access.map((floor, i) => {
                        return (
                          <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to={i}
                            className={`${i == 0 && "active"}`}
                            aria-current="true"
                            aria-label={floor}
                            style={{ backgroundColor: "#083985" }}
                          ></button>
                        );
                      })}
                    </div>
                    <div className="carousel-inner relative w-full overflow-hidden">
                      {access.map((item, i) => {
                        return (
                          <div
                            className={`carousel-item ${
                              i == 0 ? "active" : ""
                            } relative float-left w-full`}
                          >
                            <div className="block w-full mt-10">
                              <div
                                style={{
                                  width: "100%",
                                  height: 600,
                                  position: "relative",
                                }}
                              >
                                {/* <Space
                                  onCreate={(vp) =>
                                    vp.setBounds({ x: [0, 600], y: [0, 600] })
                                  }
                                > */}
                                <Floor
                                  floor={item.floor}
                                  start={moment(period[0].startDate)
                                    .format()
                                    .substring(0, 10)}
                                  end={moment(period[0].endDate)
                                    .format()
                                    .substring(0, 10)}
                                />
                                {/* </Space> */}
                              </div>
                            </div>

                            <div className="carousel-caption hidden md:block absolute text-center">
                              <h5
                                style={{ color: "#083985" }}
                                className="text-xl"
                              >
                                {item.floor_num}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline "
                      type="button"
                      // data-bs-target="#carouselExampleCaptions"
                      // data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon inline-block bg-no-repeat"
                        aria-hidden="true"
                        style={{
                          backgroundColor: "#083985",
                          marginRight: "20px",
                        }}
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                      type="button"
                      // data-bs-target="#carouselExampleCaptions"
                      // data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon inline-block bg-no-repeat"
                        aria-hidden="true"
                        style={{
                          backgroundColor: "#083985",
                          marginLeft: "20px",
                        }}
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
