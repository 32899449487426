import { useState, useEffect } from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
// import Landing from "./pages/Landing";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
// import Register from './pages/Register';

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@material-tailwind/react";
import "react-notifications/lib/notifications.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import "assets/styles/style.css";
import Home from "./pages/Home";
import OnlineWork from "./pages/OnlineWork";
import ThirdSite from "./pages/ThirdSite";
import Leave from "./pages/Leave";
import Requests from "./pages/Requests";
import Schedule from "./pages/Schedule";
import Team from "./pages/Team";
import Request from "./pages/Request";
import TeamRequests from "./pages/TeamRequests";
import DetailsRequest from "./pages/DetailsRequest";
import MeetingSchedule from "./pages/MeetingSchedule";
import jwt from "jwt-decode";
import axios from "axios";
import Reservation from "pages/Reservation";
import Floor from "pages/Floor";
import ClientSite from "pages/ClientSite";
import History from "pages/History";
import Claims from "./pages/Claims";
import DetailsClaim from "./pages/DetailsClaim";
import AddClaim from "pages/AddClaim";
import Scan from "pages/Scan";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
// import OnSiteSearch from "pages/OnSiteSearch";
import Page404 from "pages/Page404";
import TeamMembers from "pages/TeamMembers";
import ReservationsList from "pages/ReservationsList";
import OnSiteSearch from "pages/OnSiteSearch";
import Travel from "pages/Travel";
import Missions from "pages/Missions";
import MissionDetails from "pages/MissionDetails";
import Mission from "components/TeamRequests/MissionDetails";

// import { Suspense } from "react";
// import { useTranslation } from "react-i18next";

function App() {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("user")));
  // const token = JSON.parse(localStorage.getItem("user"))
  // const [user, setUser] = useState(jwt(localStorage.getItem('user')));
  // const user = jwt(JSON.parse(localStorage.getItem("user")))
  const t = JSON.parse(localStorage.getItem("user"));

  const user = t && jwt(t?.token);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const tokenParams = urlParams.get("token");
  //   const refreshTokenParams = urlParams.get("refresh_token");
  //   console.log(
  //     "🚀 ~ useEffect ~ token params:",
  //     tokenParams,
  //     refreshTokenParams
  //   );

  //   if (tokenParams && refreshTokenParams) {
  //     localStorage.setItem(
  //       "user",
  //       JSON.stringify({ token: tokenParams, refreshToken: refreshTokenParams })
  //     );

  //     // axios.post('/api/verify-token', { token: tokenParams })
  //     // .then(response => {
  //     //   if (response.data.valid) {
  //     //     // Si le token est valide, mettre à jour l'état d'authentification
  //     //     setToken(tokenParams);
  //     //     // Rediriger vers la page d'accueil
  //     //     navigate('/home');
  //     //   } else {
  //     //     // Si le token n'est pas valide, rediriger vers la page de connexion
  //     //     navigate('/login');
  //     //   }
  //     // })
  //     // .catch(error => {
  //     //   console.error('Error verifying token:', error);
  //     //   // Rediriger vers la page de connexion en cas d'erreur
  //     //   navigate('/login');
  //     // });

  //     setToken(
  //       JSON.stringify({ token: tokenParams, refreshToken: refreshTokenParams })
  //     );

  //     navigate("/home");
  //   }
  //   // else {
  //   //   if (token && (!token.refresh_token || user.isBlocked === true)) {
  //   //     window.location.pathname = "/login";
  //   //     localStorage.removeItem("user");
  //   //     setToken();
  //   //   }
  //   // }
  // }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParams = urlParams.get("token");
    const refreshTokenParams = urlParams.get("refresh_token");

    if (tokenParams && refreshTokenParams) {
      localStorage.setItem(
        "user",
        JSON.stringify({ token: tokenParams, refreshToken: refreshTokenParams })
      );
      setToken({ token: tokenParams, refreshToken: refreshTokenParams });
    }

    if (tokenParams && refreshTokenParams) {
      axios
        .post(`${process.env.REACT_APP_API_URL}User/verify-token`, {
          token: tokenParams,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.valid) {
            navigate("/home");
          } else {
            localStorage.removeItem("user");
            setToken(null);
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Error verifying token:", error);
          // Rediriger vers la page de connexion en cas d'erreur
          localStorage.removeItem("user");
          setToken(null);
          navigate("/login");
        });

      // navigate("/home");
    }
    // else {
    //   navigate("/login");
    // }
  }, []);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const tokenParams = urlParams.get("token");
  //   const refreshTokenParams = urlParams.get("refresh_token");

  //   if (tokenParams && refreshTokenParams) {
  //     // Vérifier la validité du token avec le backend
  //     axios
  //       .post("/api/verify-token", { token: tokenParams })
  //       .then((response) => {
  //         if (response.data.valid) {
  //           localStorage.setItem(
  //             "user",
  //             JSON.stringify({
  //               token: tokenParams,
  //               refreshToken: refreshTokenParams,
  //             })
  //           );
  //           // Mettre à jour l'état du token
  //           setToken({ token: tokenParams, refreshToken: refreshTokenParams });
  //           navigate("/home");
  //         } else {
  //           navigate("/login");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error verifying token:", error);
  //         navigate("/login");
  //       });
  //   } else {
  //     // Si aucun token n'est présent dans l'URL, ne rien faire
  //   }
  // }, []);

  // if (token){
  //    let decodedData = jwt(token, { header: true });
  //    let expirationDate = decodedData.exp;
  //    console.log('exp', expirationDate)
  //     var current_time = Date.now() / 1000;
  //     if(expirationDate < current_time)
  //     {
  //         localStorage.removeItem("user");
  //         setToken()
  //     }
  // }

  // useEffect(() => {
  //   async function Auth() {
  //     if (token && (!token.refresh_token || user.isBlocked === true)) {
  //       window.location.pathname = "/login";
  //       localStorage.removeItem("user");
  //       setToken();
  //     }
  //   }

  //   Auth();
  // }, []);

  return (
    <Routes>
      {token && <Route exact path="/mission/:id" element={<Mission />} />}

      {token && (
        <Route exact path="/mission_details/:id" element={<MissionDetails />} />
      )}

      {token && <Route exact path="/missions" element={<Missions />} />}

      {token && <Route exact path="/travel" element={<Travel />} />}

      {token && <Route exact path="/home" element={<Home />} />}
      {token && <Route exact path="/reservation" element={<Reservation />} />}
      {token && <Route exact path="/floor/:id" element={<Floor />} />}
      {token && <Route exact path="/onlinework" element={<OnlineWork />} />}
      {token && (
        <Route exact path="/thirdpartysite_request" element={<ThirdSite />} />
      )}
      {token && <Route exact path="/search_desk" element={<OnSiteSearch />} />}
      {token && <Route exact path="/requests" element={<Requests />} />}
      {token && <Route exact path="/leave" element={<Leave />} />}
      {token && <Route exact path="/schedule" element={<Schedule />} />}
      {token && <Route exact path="/claims" element={<Claims />} />}
      {token && <Route exact path="/new_claim" element={<AddClaim />} />}
      {token && (
        <Route exact path="/details_claim" element={<DetailsClaim />} />
      )}

      {/* {token && ( <Route exact path={`${encodeURIComponent('/request/:id')}`} component={Request} /> )} */}
      {token && (
        <Route exact path="/details/:id" element={<DetailsRequest />} />
      )}
      {token &&
        (user.role == "manager_validator" || user.role == "validator") && (
          <Route exact path="/team" element={<Team />} />
        )}
      {token &&
        (user.role == "manager_validator" || user.role == "validator") && (
          <Route exact path="/request/:id" element={<Request />} />
        )}
      {token && <Route exact path="/profile" element={<Profile />} />}
      {token &&
        (user.role == "manager_validator" || user.role == "manager") && (
          <Route exact path="/teamRequests" element={<TeamRequests />} />
        )}
      {token && (
        <Route exact path="/meeting-schedule" element={<MeetingSchedule />} />
      )}

      {token && (
        <Route exact path="/reservationsList" element={<ReservationsList />} />
      )}

      {token && (
        <Route exact path="/thirdpartysite_request" element={<ClientSite />} />
      )}

      {token && <Route exact path="/history" element={<History />} />}
      {token && <Route exact path="/scan" element={<Scan />} />}
      {/* {token && <Route path="*" element={<Page404 />} />} */}

      {token &&
        (user.role == "manager_validator" || user.role == "manager") && (
          <Route exact path="/teamMembers" element={<TeamMembers />} />
        )}

      <Route
        exact
        path="/login"
        element={<Login user={token} setUser={setToken} />}
      ></Route>

      {token ? (
        <Route exact path="/" element={<Navigate to="/home" replace />} />
      ) : (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
      {token && <Route path="*" element={<Page404 />} />}

      {/* <Navigate from="*" to="/login" /> */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default App;
