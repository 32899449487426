import DefaultFooter from "../components/Footers/DefaultFooter";
import Form from "../components/Leave/Form";
import Section from "../components/Leave/Section";
import DefaultNavbar from "../components/Navbars/DefaultNavbar";

export default function Leave() {
  return (
    <>
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <DefaultNavbar />
      </div>
      <main>
        <section className="pb-20 relative block">
          <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
            <Form />
          </div>
        </section>
      </main>
      <DefaultFooter />
    </>
  );
}
