import React, { useState, useEffect } from "react";
import "./form.css";
import { useLocation, useParams } from "react-router";
import Calendar from "./Calendar";
import Floor from "./Floor";
import { fetchFloorById } from "actions/RessourcesAction";
import useMediaQuery from "useMediaQuery";
import { Link, useSearchParams } from "react-router-dom";
import { Breadcrumbs } from "@material-tailwind/react";
import { Home } from "@mui/icons-material";

export default function FloorPlan() {
  let params = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [floor, setFloor] = useState();

  // const [searchParams] = useSearchParams();
  // const userId = searchParams.get("id");
  // const date = searchParams.get("date");
  // const reservations = searchParams.get("reservations");
  const { state } = useLocation();

  useEffect(() => {
    state?.date && setSelectedDate(new Date(state?.date));
  }, [state]);

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchFloorById(params.id);
      setFloor(result);
    };
    fetch();
  }, [params]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  console.log("floor", params.id, floor);

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-12xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <Link to="/reservation" className="opacity-60">
                  <span>Flex Office</span>
                </Link>
                <a href="#">Floor {floor?.floor_num} </a>
              </Breadcrumbs>
            </div>
          )}
          <div className="w-full lg:w-12/12 px-4 mt-20">
            <div
              className="w-full text-center mt-10"
              style={{ marginTop: isDesktop ? 0 : "30%" }}
            >
              <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />

              <div className="flex flex-wrap justify-center flex-grow-3 gap-4 mb-6">
                {floor && (
                  <Floor
                    floorId={params.id}
                    selectedDate={selectedDate}
                    floor={floor}
                    userId={state?.user}
                    reservations={state?.reservations}
                    floorNumber={floor?.floor_num}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
