import React from "react";
// import { RiUpload2Line } from 'react-icons/ri';
// import styled from 'styled-components';
import { styled } from "@mui/material/styles";
import { Upload } from "@mui/icons-material";

const FileInputContainer = styled("label")`
  padding: 9px;
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  color: #294e87;
  input[type="file"] {
    display: none;
  }
`;

const UploadIcon = styled(Upload)`
  // margin-right: 8px;
  color: #294e87;
  width: 50px;
`;

const CustomFileInput = ({ onFileChange }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileChange(file);
  };

  const allowedExtensions = ".jpg, .jpeg, .png, .pdf, .msg";

  return (
    <FileInputContainer>
      <UploadIcon />
      {/* <span>Upload</span> */}
      <input
        type="file"
        onChange={handleFileChange}
        accept={allowedExtensions}
      />
    </FileInputContainer>
  );
};

export default CustomFileInput;
