import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { Navbar } from "@material-tailwind/react";
// import Navbar from "@material-tailwind/react";
// import NavbarContainer from "@material-tailwind/react";
// import NavbarWrapper from "@material-tailwind/react";
// import NavbarBrand from "@material-tailwind/react";
// import NavbarToggler from "@material-tailwind/react";
import logo from "../../assets/img/white.png";
import { Link } from "react-router-dom";
// import NavbarCollapse from '@material-tailwind/reactbarCollapse';
// import Nav from '@material-tailwind/react';
// import NavLink from '@material-tailwind/react';
// import Dropdown from '@material-tailwind/react';
// import DropdownItem from '@material-tailwind/reactItem';
// import Icon from '@material-tailwind/react';
// import Button from '@material-tailwind/react/components/Button';

export default function LoginNavbar() {
  return (
    <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
      <Navbar color="transparent" className="p-3">
        <div className="container mx-auto flex items-center justify-between text-white">
          <Link to="/">
            {/* <Typography className="mr-4 ml-2 cursor-pointer py-1.5 font-bold"> */}
            <img
              src={logo}
              width={150}
              className="mr-4 ml-2 cursor-pointer py-1.5 font-bold"
            />
            {/* </Typography> */}
          </Link>
          {/* <div className="hidden lg:block">{navList}</div> */}
          <div className="hidden gap-2 lg:flex">
            <a href="https://workpoint.tn" target="_blank"></a>
          </div>
        </div>
      </Navbar>
    </div>
  );
}
