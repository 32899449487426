import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";

import useMediaQuery from "useMediaQuery";
import Translation from "Translation";
import ListView from "./ListView";
const color = "#083985";

export default function Section() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isDesktop = useMediaQuery("(min-width: 960px)");

  return (
    <section className="pb-20 relative block">
      <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
        <div className="flex flex-wrap justify-center mt-5">
          <div className="w-full lg:w-12/12 px-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <Typography
                    variant={isDesktop ? "h3" : "h6"}
                    style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                  >
                    <Translation message={"Team members"} />
                  </Typography>
                  <Typography color="gray">
                    <Translation message={"Follow your team"} />
                  </Typography>
                </div>
              </div>
              <div className="flex flex-wrap justify-center flex-grow-3 gap-8 mt-16 mb-12">
                <ListView />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
