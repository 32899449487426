import React, { useState, useEffect } from "react";

import { DataGrid, GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import Button from "@material-tailwind/react/components/Button";
import {
  getPendingRequestsByManager,
  updateRequest,
} from "../../actions/RequestAction";
import jwt from "jwt-decode";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { fetchOperationsByRequest } from "actions/OperationAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import PopupDeledate from "components/Team/PopupDelegate";
import { Link } from "react-router-dom";
import Translation from "Translation";
import PopupDeledate from "components/Team/PopupDelegate";
import { format } from "date-fns";
import { Chip } from "@mui/material";
import "./form.css";

export default function Leave() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;
  const config = JSON.parse(localStorage.getItem("config"));

  const [rows, setRows] = useState([]);
  const user = jwt(token);
  const [openPopup, setOpenPopup] = useState({ open: false });
  const [validator, setValidator] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const columns = [
    {
      field: "fullName",
      headerName: <Translation message={"Full name"} />,
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
    {
      field: "type",
      headerName: "Type",
      // type: 'number',
      width: 200,
      renderCell: (params) => {
        return (
          <p>
            {params.row.type}{" "}
            {params.row.relationship ? params.row.relationship : ""}
          </p>
        );
      },
    },

    {
      field: "operations",
      headerName: <Translation message={"Requested Days"} />,
      sortable: true,
      width: 300,
      renderCell: (params) => {
        var ch = "";
        params.formattedValue?.map((day) => {
          ch =
            day.date_debut && day.date_fin
              ? "From " +
                format(new Date(day.date_debut), "dd-MM-yyyy") +
                " " +
                day.slot_debut +
                " To " +
                format(new Date(day.date_fin), "dd-MM-yyyy") +
                " " +
                day.slot_fin
              : "";
        });
        return <p>{ch}</p>;
      },
    },
    {
      field: "statusCancellation",
      headerName: "",
      // type: 'number',
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {params.row.statusCancellation ? (
              <Chip label="cancellation" color="error" variant="outlined" />
            ) : (
              <Chip label="validation" color="success" variant="outlined" />
            )}
          </>
        );
      },
    },
    {
      field: "nbDays",
      headerName: <Translation message={"Number of counted days"} />,
      // type: 'number',
      width: 100,
    },
    {
      field: "balance",
      headerName: <Translation message={"Current Leave balance"} />,
      // type: 'number',
      width: 90,
    },
    {
      field: "sickBalance",
      headerName: <Translation message={"Current Sick balance"} />,
      // type: 'number',
      width: 90,
    },
    {
      field: "actions",
      // style: {
      //   position: "sticky",
      //   right: 0,
      //   zIndex: 1,
      //   backgroundColor: "#fff",
      // },
      cellClassName: "custom-action-column",
      // headerClassName: "custom-header-sticky",
      headerName: "",
      sortable: false,
      width: 250,
      renderCell: (params) => {
        const onApprove = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          // NotificationManager.success('Updated successfully')
          // setRows(rows.filter(item => item.id != params.id ))
          const result = await updateRequest("", params.id, {
            status: "accepted",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message);
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(result.message);
          }
        };
        const onDelegate = async (e) => {
          e.preventDefault();

          setOpenPopup({ open: true, id: params.id });
        };
        const onReject = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDisabled(true);

          NotificationManager.success("Updated successfully");
          setRows(rows.filter((item) => item.id != params.id));
          const result = await updateRequest("", params.id, {
            status: "refused",
          });
          if (result.status === 200) {
            NotificationManager.success(result.message);
            setRows(rows.filter((item) => item.id != params.id));
            setDisabled(false);
          } else {
            NotificationManager.error(result.message);
          }
        };

        return (
          <div className="flex gap-2 justify-around">
            <Button
              color="green"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApprove}
              disabled={disabled}
            >
              <i className="fas fa-check fa"></i>
            </Button>
            <Button
              color="red"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onReject}
              disabled={disabled}
            >
              <i className="fas fa-times fa"></i>
            </Button>
            {/* <Button
              color="indigo"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onDelegate}
              disabled={disabled}
            >
              <i className="fas fa-paper-plane fa"></i>
            </Button> */}
            <Link
              to={{
                pathname: `/request/${params.id}`,
                state: window.location.pathname + "#/Leave",
              }}
            >
              <Button
                color="indigo"
                variant="text"
                size="regular"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="dark"
                // onClick={onClick}
              >
                <Translation message={"Details"} />
              </Button>
            </Link>
          </div>
        );
      },
    },
    // {
    //   field: "accept",
    //   headerName: "",
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => {
    //     setHide(params.row.status == "pending" ? false : true);
    //     const onApprove = async (e) => {
    //       e.stopPropagation(); // don't select this row after clicking

    //       // NotificationManager.success('Updated successfully')
    //       // setRows(rows.filter(item => item.id != params.id ))
    //       const result = await updateRequest("", params.id, {
    //         status: "accepted",
    //       });
    //       if (result.status === 200) {
    //         NotificationManager.success(result.message, "Success");
    //         setRows(rows.filter((item) => item.id != params.id));
    //       } else {
    //         NotificationManager.error(result.message, "Error");
    //       }
    //     };

    //     return (
    //       <Button
    //         color="green"
    //         variant="text"
    //         size="regular"
    //         rounded={false}
    //         block={false}
    //         iconOnly={false}
    //         ripple="dark"
    //         onClick={onApprove}
    //         disabled={params.row.status == "pending" ? false : true}
    //       >
    //         <i className="fas fa-check fa"></i>
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "refuse",
    //   headerName: "",
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => {
    //     setHide(params.row.status == "pending" ? false : true);
    //     const onReject = async (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       // console.log('params', params)
    //       // const api: GridApi = params.api;
    //       // const thisRow: Record<string, GridCellValue> = {};

    //       // api
    //       //   .getAllColumns()
    //       //   .filter((c) => c.field !== '__check__' && !!c)
    //       //   .forEach(
    //       //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //       //   );
    //       // NotificationManager.success('Updated successfully')
    //       // setRows(rows.filter(item => item.id != params.id ))
    //       const result = await updateRequest("", params.id, {
    //         status: "refused",
    //       });
    //       if (result.status === 200) {
    //         NotificationManager.success(result.message, "Success");
    //         setRows(rows.filter((item) => item.id != params.id));
    //       } else {
    //         NotificationManager.error(result.message, "Error");
    //       }
    //     };

    //     return (
    //       <Button
    //         color="red"
    //         variant="text"
    //         size="regular"
    //         rounded={false}
    //         block={false}
    //         iconOnly={false}
    //         ripple="dark"
    //         onClick={onReject}
    //         disabled={params.row.status == "pending" ? false : true}
    //       >
    //         <i className="fas fa-times fa"></i>
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   field: "details",
    //   headerName: "",
    //   sortable: false,
    //   width: 120,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       // console.log('params', params)
    //       // const api: GridApi = params.api;
    //       // const thisRow: Record<string, GridCellValue> = {};

    //       // api
    //       //   .getAllColumns()
    //       //   .filter((c) => c.field !== '__check__' && !!c)
    //       //   .forEach(
    //       //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //       //   );
    //       let path = `/request/${params.id}`;
    //       //   console.log(thisRow)

    //       return (window.location.href = path);
    //     };

    //     return (
    //       <Link to={`/request/${params.id}`}>
    //         <Button
    //           color="indigo"
    //           variant="text"
    //           size="regular"
    //           rounded={false}
    //           block={false}
    //           iconOnly={false}
    //           ripple="dark"
    //           // onClick={onClick}
    //         >
    //           <Translation message={"Details"} />
    //         </Button>
    //       </Link>
    //     );
    //   },
    // },

    // {
    //     field: 'action',
    //     headerName: 'Action',
    //     sortable: false,
    //     width: 260,
    //     renderCell: (params) => {
    //       const onClick = (e) => {
    //         e.stopPropagation(); // don't select this row after clicking
    //         console.log('params', params)
    //         const api: GridApi = params.api;
    //         const thisRow: Record<string, GridCellValue> = {};

    //         api
    //           .getAllColumns()
    //           .filter((c) => c.field !== '__check__' && !!c)
    //           .forEach(
    //             (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
    //           );
    //           let path = `/request/${params.id}`;
    //         //   console.log(thisRow)

    //         return window.location.href = path;
    //       };

    //       return <Button  color="indigo"
    //             buttonType="link"
    //             size="regular"
    //             rounded={false}
    //             block={false}
    //             iconOnly={false}
    //             ripple="dark" onClick={onClick}>Edit</Button>;
    //     },
    //   }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      const result = await getPendingRequestsByManager(token, user.id, "Leave");
      const requests = result.filter((item) => item._id.name == "Leave");
      var rowsData = [];
      for (const item of requests) {
        // const balances = await fetchUserBalances(token, item.idSender._id)
        // console.log(balances)
        rowsData.push({
          id: item._id._id,
          lastName: item.idSender?.lastname,
          firstName: item.idSender?.firstname,
          type: item._id.type,
          status: item._id.status,
          date:
            item._id.createdAt.substring(0, 10).split("-").reverse().join("-") +
            " " +
            item._id.createdAt.substring(11, 16),
          operations: item.operations,
          balance: item.balance.LeaveBalance,
          sickBalance: item.balance.sickBalance,
          nbDays: item.operations[0]?.NB_BalanceDays,
          relationship: item._id.dead,
          statusCancellation: item._id.cancellationStatus,
        });
      }

      setRows(rowsData);
      setOpen(false);
    };
    fetchData();
  }, []);

  const onApproveAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "accepted",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request approved");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onRejectAll = async (e) => {
    var index = 1;
    setOpen(true);
    var arr = rows;
    for (const item of selectionModel) {
      const result = await updateRequest("", item, {
        status: "refused",
      });
      if (result.status == 200) {
        NotificationManager.success(index + " request rejected");
        index++;
        arr = arr.filter((request) => request.id != item);
      } else {
        NotificationManager.error(result.message);
      }
    }
    setRows(arr);
    setOpen(false);
  };

  const onDelegateAll = async (e) => {
    e.preventDefault();

    setOpenPopup({ open: true, ids: selectionModel, multiple: true });
  };

  useEffect(() => {
    selectionModel.length > 0 ? setVisible(true) : setVisible(false);
  }, [selectionModel]);

  return (
    <>
      <div className="mt-10" style={{ height: 600, width: "100%" }}>
        {visible && (
          <div className="flex justify-center mb-5">
            <Button
              color="green"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onApproveAll}
              disabled={disabled}
            >
              <i className="fas fa-check fa"></i> Accept selected
            </Button>
            <Button
              color="red"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onRejectAll}
              disabled={disabled}
            >
              <i className="fas fa-times fa"></i> Reject selected
            </Button>
            <Button
              color="indigo"
              variant="text"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="dark"
              onClick={onDelegateAll}
              disabled={disabled}
            >
              <i className="fas fa-paper-plane fa"></i>Delegate selected
            </Button>
          </div>
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={open}
          disableColumnSelector
          stickyHeader={true}
          columnVisibilityModel={{
            accept: !hide,
            refuse: !hide,
            sickBalance:
              config?.DISPLAY_SICK_BALANCE &&
              config?.DISPLAY_SICK_BALANCE === true
                ? true
                : false,
          }}
        />
      </div>
      <PopupDeledate
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setValidator={setValidator}
        validator={validator}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
      />
      <NotificationContainer />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </>
  );
}
