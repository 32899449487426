import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import {
  Breadcrumbs,
  Button,
  Step,
  Stepper,
  Typography,
} from "@material-tailwind/react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./form.css";
import Textarea from "@material-tailwind/react/components/Textarea";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import StaticDatePicker from "@mui/lab/StaticDatePicker";
// import PickersDay from "@mui/lab/PickersDay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";

import {
  fetchValidators,
  fetchUser,
  fetchALLUsers,
  fetchUserBalances,
  fetchBalances,
} from "../../actions/UserAction";
import { addRequest } from "../../actions/RequestAction";

import NotifPerson from "./NotifPerson";
import PersonIcon from "@mui/icons-material/Person";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import Box from "@mui/material/Box";
import DateRangePicker from "@mui/lab/DateRangePicker";
import moment from "moment";
import jwt from "jwt-decode";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import { DateRange } from "react-date-range";
import { addDays, format } from "date-fns";
import useMediaQuery from "useMediaQuery";
import { Link } from "react-router-dom";
import { KeyboardArrowDown, Done, Home } from "@mui/icons-material";
import Translation from "Translation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/fr";
import { t } from "i18next";
import { getWFHOperationsDates } from "actions/OperationAction";
import { CircularProgress, Skeleton } from "@mui/material";
import {
  BuildingLibraryIcon,
  CogIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { FaCcVisa, FaHotel, FaPassport, FaPlane } from "react-icons/fa";
import { BiSolidDetail } from "react-icons/bi";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import Expenses from "./Expenses";
import Visa from "./Visa";
import Mission from "./Mission";
import Passport from "./Passport";
import { fetchCountries } from "actions/travel.service";
import { fetchAlCities } from "actions/travel.service";
import { fetchVisaHistory } from "actions/travel.service";
import { fetchFormulas } from "actions/travel.service";
import { getUserPermissions } from "actions/travel.service";
import MissionStep from "./MissionStep";
import Accommodation from "./Accommodation";
import { fetchCityCap } from "actions/travel.service";
import { fetchPerdiem } from "actions/travel.service";
import { differenceInDays } from "date-fns";
import Transportation from "./Transportation";
import { addMission } from "actions/travel.service";
import { addFile } from "actions/travel.service";

const color = "#083985";
dayjs.extend(isBetweenPlugin);

function Sort(x, y) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}

export default function Form() {
  const storage = JSON.parse(localStorage.getItem("user"));
  const token = storage.token;

  const user = jwt(token);
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [steps, setSteps] = useState([
    {
      name: "Passport",
      icon: <FaPassport className="h-5 w-5" />,
      desc: "Passport's details",
      component: <Passport />,
      requiredFields: ["passportNumber", "expiryPassport"],
    },
    {
      name: "Mission",
      icon: <BiSolidDetail className="h-5 w-5" />,
      desc: "Mission's details",
      component: <MissionStep />,
      requiredFields: [
        // "missionType",
        // "title",
        // "statusEngagementCode",
        // "startDate",
        // "endDate",
        // "country",
        // "city",
        // "formula",
      ],
    },
    {
      name: "Visa",
      icon: <FaCcVisa className="h-5 w-5" />,
      desc: "Visa's details",
      component: <Visa />,
      requiredFields: ["visaOption"],
    },
    {
      name: "Expenses",
      icon: <GiMoneyStack className="h-5 w-5" />,
      desc: "Perdiem's details",
      component: <Expenses />,
      requiredFields: [],
    },
    {
      name: "Transportation",
      icon: <FaPlane className="h-5 w-5" />,
      desc: "Transport's details",
      component: <Transportation />,
      requiredFields: [],
    },
  ]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const [formData, setFormData] = useState({
    senderType: "myself",
    missionType: "simple",
    needTransportation: true,
    // title: "title",
    // statusEngagementCode: "obtained",
    // engagementCode: "E-12345678",
    // startDate: "2024-07-22",
    // endDate: "2024-07-31",
    // country: {
    //   vaccines: [],
    //   _id: "62330e1b20b02f1dc75aa838",
    //   country_code: "FR",
    //   currency: "620d49a957cc5ffca9b7915a",
    //   name: "France",
    //   region: {
    //     _id: "620f8ac2925c04818193b8a0",
    //     name: "Europe",
    //   },
    //   visa: {
    //     _id: "64218fe18489033814e4469b",
    //     name: "Visa Schengen",
    //     documents_list: [
    //       {
    //         document: {
    //           _id: "63e4fc71207fda72d041a1df",
    //           name: "Extrait de naissance",
    //           __v: 0,
    //         },
    //         number_of_document: 1,
    //       },
    //       {
    //         document: {
    //           _id: "64218fbf8489033814e44699",
    //           name: "Copie CIN",
    //           cost: null,
    //           __v: 0,
    //         },
    //         number_of_document: 1,
    //       },
    //       {
    //         document: {
    //           _id: "64218fdc8489033814e4469a",
    //           name: "Attestation de travail",
    //           cost: null,
    //           __v: 0,
    //         },
    //         number_of_document: 1,
    //         comment: "pour les employées ",
    //       },
    //     ],
    //     __v: 0,
    //     type: "on departure",
    //   },
    //   visa_cost: "200",
    //   visaCurrency: {
    //     _id: "620d49a957cc5ffca9b791c6",
    //     monetary_unit: "Tunisian dinar",
    //     name: "TND",
    //     sub_monetary_unit: "Millime",
    //   },
    //   value: "62330e1b20b02f1dc75aa838",
    //   label: "France",
    // },
    // city: {
    //   _id: "6408542dc923bb64a8034486",
    //   name: "Paris",
    //   code: "fr",
    //   country: "62330e1b20b02f1dc75aa838",
    //   __v: 0,
    //   country_name: {
    //     _id: "62330e1b20b02f1dc75aa838",
    //     country_code: "FR",
    //     currency: "620d49a957cc5ffca9b7915a",
    //     name: "France",
    //     region: "620f8ac2925c04818193b8a0",
    //     visa: "64218fe18489033814e4469b",
    //     visa_cost: "200",
    //     visaCurrency: "620d49a957cc5ffca9b791c6",
    //     vaccines: ["642c11da6dfcee6340a86114", "642be79c5766973c3459bda4"],
    //   },
    //   value: "6408542dc923bb64a8034486",
    //   label: "Paris",
    // },
    // manager: {
    //   _id: "6687d13a100f29d51062892b",
    //   firstname: "Mersani",
    //   lastname: "Rawen",
    //   role: "user",
    //   Accesses: [],
    //   label: "Mersani Rawen",
    //   value: "6687d13a100f29d51062892b",
    // },
    // Formula: {
    //   needTransportation: false,
    //   needAccomdation: true,
    //   _id: "64de459ee8f93329ffce73a5",
    //   name: "With accomodation",
    //   description:
    //     "Facility's team will take charge of booking an accomodation for you.",
    //   __v: 0,
    //   type: "Hotel",
    //   value: "64de459ee8f93329ffce73a5",
    //   label: "With accomodation",
    // },
  });
  const [permissions, setPermissions] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [formules, setFormules] = useState([]);
  const [passportFile, setPassportFile] = useState(null);
  const [isEngagementCodeValid, setIsEngagementCodeValid] = useState(true);
  const [missions, setMissions] = useState([
    // {
    //   title: "test m 1",
    //   manager: null,
    //   partner: null,
    //   country: {
    //     vaccines: [],
    //     _id: "62330e1b20b02f1dc75aa838",
    //     country_code: "FR",
    //     currency: "620d49a957cc5ffca9b7915a",
    //     name: "France",
    //     region: {
    //       _id: "620f8ac2925c04818193b8a0",
    //       name: "Europe",
    //     },
    //     visa: {
    //       _id: "64218fe18489033814e4469b",
    //       name: "Visa Schengen",
    //       documents_list: [
    //         {
    //           document: {
    //             _id: "63e4fc71207fda72d041a1df",
    //             name: "Extrait de naissance",
    //             __v: 0,
    //           },
    //           number_of_document: 1,
    //         },
    //         {
    //           document: {
    //             _id: "64218fbf8489033814e44699",
    //             name: "Copie CIN",
    //             cost: null,
    //             __v: 0,
    //           },
    //           number_of_document: 1,
    //         },
    //         {
    //           document: {
    //             _id: "64218fdc8489033814e4469a",
    //             name: "Attestation de travail",
    //             cost: null,
    //             __v: 0,
    //           },
    //           number_of_document: 1,
    //           comment: "pour les employées ",
    //         },
    //       ],
    //       __v: 0,
    //       type: "on departure",
    //     },
    //     visa_cost: "200",
    //     visaCurrency: {
    //       _id: "620d49a957cc5ffca9b791c6",
    //       monetary_unit: "Tunisian dinar",
    //       name: "TND",
    //       sub_monetary_unit: "Millime",
    //     },
    //     value: "62330e1b20b02f1dc75aa838",
    //     label: "France",
    //   },
    //   city: {
    //     _id: "6408542dc923bb64a8034486",
    //     name: "Paris",
    //     code: "fr",
    //     country: "62330e1b20b02f1dc75aa838",
    //     __v: 0,
    //     country_name: {
    //       _id: "62330e1b20b02f1dc75aa838",
    //       country_code: "FR",
    //       currency: "620d49a957cc5ffca9b7915a",
    //       name: "France",
    //       region: "620f8ac2925c04818193b8a0",
    //       visa: "64218fe18489033814e4469b",
    //       visa_cost: "200",
    //       visaCurrency: "620d49a957cc5ffca9b791c6",
    //       vaccines: ["642c11da6dfcee6340a86114", "642be79c5766973c3459bda4"],
    //     },
    //     value: "6408542dc923bb64a8034486",
    //     label: "Paris",
    //   },
    //   startDate: "2024-07-22",
    //   endDate: "2024-07-31",
    //   Formula: {
    //     needTransportation: false,
    //     needAccomdation: true,
    //     _id: "65b3cabb38894d2ceeaf043b",
    //     name: "Hybride",
    //     description:
    //       "Facility's team will take charge of booking an accomodation for you.",
    //     __v: 0,
    //     type: "hybride",
    //     value: "65b3cabb38894d2ceeaf043b",
    //     label: "Hybride",
    //   },
    //   engagementCode: null,
    //   statusEngagementCode: "processing",
    // },
    // {
    //   title: "test m 2",
    //   manager: null,
    //   partner: null,
    //   country: {
    //     vaccines: [],
    //     _id: "62330e1b20b02f1dc75aa7f8",
    //     country_code: "DE",
    //     currency: "620d49a957cc5ffca9b7915a",
    //     name: "Germany",
    //     region: {
    //       _id: "620f8ac2925c04818193b8a0",
    //       name: "Europe",
    //     },
    //     value: "62330e1b20b02f1dc75aa7f8",
    //     label: "Germany",
    //   },
    //   city: {
    //     _id: "64412a40b6283d3938a9bc22",
    //     name: "Aschaffenburg",
    //     code: "DE",
    //     country: "62330e1b20b02f1dc75aa7f8",
    //     __v: 0,
    //     country_name: {
    //       _id: "62330e1b20b02f1dc75aa7f8",
    //       country_code: "DE",
    //       currency: "620d49a957cc5ffca9b7915a",
    //       name: "Germany",
    //       region: "620f8ac2925c04818193b8a0",
    //     },
    //     value: "64412a40b6283d3938a9bc22",
    //     label: "Aschaffenburg",
    //   },
    //   startDate: "2024-08-01",
    //   endDate: "2024-08-09",
    //   Formula: {
    //     needTransportation: false,
    //     needAccomdation: false,
    //     _id: "65b3cad238894d2ceeaf043c",
    //     name: "Personal housing",
    //     description:
    //       "Facility's team will take charge of booking an accomodation for you.",
    //     __v: 0,
    //     type: "personal accomodation",
    //     value: "65b3cad238894d2ceeaf043c",
    //     label: "Personal housing",
    //   },
    //   engagementCode: "E-12345678",
    //   statusEngagementCode: "obtained",
    // },
  ]);
  const [errors, setErrors] = useState({});
  const [visaFile, setVisaFile] = useState(null);
  const [stay, setStay] = useState([]);
  const [perdiems, setPerdiems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRequiredFields = (step) => {
    switch (step) {
      case 0:
        if (formData.senderType === "colleague") {
          return ["idSender"];
        } else if (formData.senderType === "guest") {
          return ["guest", "idSender"];
        }
        return [];
      case 1:
        if (formData.missionType == "compound") {
          if (missions?.length <= 1) {
            NotificationManager.error(
              t("The composite mission must consist of at least two missions")
            );
            return ["composed"];
          }
          const allFieldsFilled = missions.every((item) => {
            return (
              item.title &&
              item.statusEngagementCode &&
              item.startDate &&
              item.endDate &&
              item.country &&
              item.city &&
              item.Formula
            );
          });

          if (!allFieldsFilled) {
            NotificationManager.error(
              "Please fill in all required fields in missions array before proceeding."
            );
            return [];
          }
        } else {
          if (!formData.statusEngagementCode) {
            return ["statusEngagementCode"];
          }

          if (
            formData.statusEngagementCode == "obtained" &&
            (formData.engagementCode?.length == 0 || !formData.engagementCode)
          ) {
            return ["engagementCode"];
          }
          return [
            "missionType",
            "title",
            "statusEngagementCode",
            "startDate",
            "endDate",
            "country",
            "city",
            "formula",
          ];
        }
      case 2:
        if (formData.visaOption == "validVisa" && !visaFile) {
          // NotificationManager.error(
          //   "Please fill in all required fields in missions array before proceeding."
          // );
          return ["visaFile"];
        }

      default:
        return [];
    }
  };

  // const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handleNext = () => {
    setErrors({});
    let errorsMessage = {};
    const currentStep = steps[activeStep];
    // const requiredFields = currentStep.requiredFields;
    const requiredFields = [
      ...currentStep.requiredFields,
      ...getRequiredFields(activeStep),
    ];
    // const missingFields = requiredFields.filter(field => !formData[field]);

    // if (missingFields.length > 0) {
    //   const fieldsList = missingFields.join(', ');
    //   NotificationManager.error('Erreur', `Veuillez remplir les champs suivants : ${fieldsList} avant de continuer.`);
    //   return;
    // }

    const areRequiredFieldsFilled = requiredFields.every((field) =>
      Boolean(formData[field])
    );

    const isPassportFilePresent =
      activeStep === 0 ? Boolean(passportFile) : true;

    if (!areRequiredFieldsFilled || !isPassportFilePresent) {
      if (!areRequiredFieldsFilled) {
        requiredFields.forEach((field) => {
          if (!formData[field]) {
            errorsMessage[field] = "required field";
          }
        });
        NotificationManager.warning(
          <Translation message="Please fill in all required fields before continuing" />
        );
      } else if (!isPassportFilePresent) {
        NotificationManager.warning(
          <Translation message="Please upload the passport file before continuing" />
        );
      }
      setErrors(errorsMessage);
      return;
    }

    if (currentStep == 1 && !isEngagementCodeValid) {
      NotificationManager.error(
        t(
          "Invalid format. The value should start with E or I followed by - and then 8 digits."
        )
      );
      return;
    }
    // if (!areRequiredFieldsFilled) {
    //   NotificationManager.warning(
    //     <Translation message="Please fill in all required fields before continuing" />
    //   );
    //   return;
    // }

    if (!isLastStep) {
      setActiveStep((cur) => cur + 1);
    }
  };
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const allCountries = await fetchCountries();
        const result = allCountries
          .sort(Sort)
          ?.map((item) => ({ ...item, value: item._id, label: item.name }));
        // console.log("🚀 ~ getCountries ~ result:", result);

        setCountries(result);
      } catch (error) {
        setCountries([]);
      }
    };

    const getAllCities = async () => {
      try {
        const allCities = await fetchAlCities();
        setAllCities(
          allCities
            ?.sort(Sort)
            ?.map((item) => ({ ...item, value: item._id, label: item.name }))
        );
      } catch (error) {
        setAllCities([]);
      }
    };

    const getUser = async () => {
      const result = await fetchUser("", user.id);
      setCurrentUser(result);
      // const resVisa = await fetchVisaHistory(user.id);
      // setHistoryVisa(resVisa);
    };
    function SortArray(x, y) {
      if (x.firstname < y.firstname) {
        return -1;
      }
      if (x.firstname > y.firstname) {
        return 1;
      }
      return 0;
    }
    const getUsers = async () => {
      const result = await fetchALLUsers("");
      setUsers(
        result.sort(SortArray)?.map((item) => ({
          ...item,
          label: item.firstname + " " + item.lastname,
          value: item._id,
        }))
      );
    };

    const getFormulas = async () => {
      const result = await fetchFormulas();
      setFormules(
        result?.map((item) => ({ ...item, value: item._id, label: item.name }))
      );
    };
    const getPermissions = async () => {
      var res = await getUserPermissions();
      if (res.status == 200) {
        const permissions = res.data;
        setPermissions(permissions);
      }
    };

    getCountries();
    getUser();
    getFormulas();
    getAllCities();
    getUsers();
    getPermissions();
  }, []);

  // useEffect(() => {
  //   if (
  //     formData?.Formula?.needAccomdation &&
  //     !steps.some((step) => step.name === "Accommodation")
  //   ) {
  //     const newSteps = [
  //       ...steps.slice(0, 3),
  //       {
  //         name: "Accommodation",
  //         icon: <FaHotel className="h-5 w-5" />,
  //         desc: "Accommodation's details",
  //         component: <Accommodation />,
  //         requiredFields: [],
  //       },
  //       ...steps.slice(3),
  //     ];
  //     setSteps(newSteps);
  //   } else if (
  //     missions.some((item) => item.Formula?.needAccomdation) &&
  //     !steps.some((step) => step.name === "Accommodation")
  //   ) {
  //     const newSteps = [
  //       ...steps.slice(0, 3),
  //       {
  //         name: "Accommodation",
  //         icon: <FaHotel className="h-5 w-5" />,
  //         desc: "Accommodation's details",
  //         component: <Accommodation />,
  //         requiredFields: [],
  //       },
  //       ...steps.slice(3),
  //     ];
  //     setSteps(newSteps);
  //   } else {
  //     setSteps((prevSteps) =>
  //       prevSteps.filter((step) => step.name !== "Accommodation")
  //     );
  //   }
  // }, [formData?.Formula, missions]);

  useEffect(() => {
    if (
      formData.missionType == "simple" &&
      formData.formula?.needAccomdation &&
      !steps.some((step) => step.name === "Accommodation")
    ) {
      const expensesIndex = steps.findIndex((step) => step.name === "Expenses");
      if (
        expensesIndex !== -1 &&
        !steps.some((step) => step.name === "Accommodation")
      ) {
        const newSteps = [
          ...steps.slice(0, expensesIndex),
          {
            name: "Accommodation",
            desc: "Accommodation's details",
            component: <Accommodation />,
            icon: <FaHotel />,
            requiredFields: [],
          },
          ...steps.slice(expensesIndex),
        ];
        setSteps(newSteps);
      }
    } else if (
      formData.missionType == "compound" &&
      missions.length > 0 &&
      missions.find((item) => item.Formula?.needAccomdation == true)
    ) {
      const expensesIndex = steps.findIndex((step) => step.name === "Expenses");
      if (
        expensesIndex !== -1 &&
        !steps.some((step) => step.name === "Accommodation")
      ) {
        const newSteps = [
          ...steps.slice(0, expensesIndex),
          {
            name: "Accommodation",
            desc: "Accommodation's details",
            component: <Accommodation />,
            icon: <FaHotel />,
            requiredFields: [],
          },
          ...steps.slice(expensesIndex),
        ];
        setSteps(newSteps);
      }
    } else {
      setSteps((prevSteps) =>
        prevSteps.filter((step) => step.name !== "Accommodation")
      );
    }
  }, [formData.formula, missions]);

  useEffect(() => {
    const updateAccomodation = async () => {
      let updatedStay = [];
      if (formData.missionType == "simple") {
        if (formData.formula) {
          const res = await fetchCityCap(
            formData.country?._id,
            formData.city?._id
          );
          if (formData.formula?.type.toLowerCase() === "hybride") {
            updatedStay = [
              {
                type: "hotel",
                startDate: formData.startDate,
                endDate: formData.startDate,
                cityCap: res?._id ? res : null,
              },
              {
                type: "personal accomodation",
                startDate: formData.endDate,
                endDate: formData.endDate,
                cityCap: res?._id ? res : null,
              },
            ];
          } else {
            updatedStay = [
              {
                type: formData.formula.type?.toLowerCase(),
                startDate: formData.startDate,
                endDate: formData.endDate,
                cityCap: res?._id ? res : null,
              },
            ];
          }
        }
      } else {
        missions?.map((item) => {
          const formule = item.Formula;
          console.log("🚀 ~ updateAccomodation ~ formule:", formule);
          if (formule) {
            if (formule?.type.toLowerCase() === "hybride") {
              updatedStay.push({
                type: "hotel",
                startDate: item.startDate,
                endDate: item.startDate,
                engagementCode: item.engagementCode,
                title: item.title,
                country: item.country,
                undeletable: true,
              });
              updatedStay.push({
                type: "personal accomodation",
                startDate: item.endDate,
                endDate: item.endDate,
                engagementCode: item.engagementCode,
                title: item.title,
                country: item.country,
                undeletable: true,
              });
            } else {
              updatedStay.push({
                type: formule.type?.toLowerCase(),
                startDate: item.startDate,
                endDate: item.endDate,
                engagementCode: item.engagementCode,
                title: item.title,
                country: item.country,
                undeletable: true,
              });
            }
          }
        });
      }
      console.log("updatedStay", updatedStay);
      setFormData((prevData) => ({
        ...prevData,
        stay: updatedStay,
      }));
      setStay(updatedStay);
    };

    updateAccomodation();
  }, [missions, formData.formula]);

  useEffect(() => {
    const updatePerdiems = async () => {
      console.log("channnnnge", stay);
      let updatedPerdiems = [];
      if (formData.stay.length > 0) {
        for (const stayEntry of formData.stay) {
          // Check if there is already a perdiem entry with the same dates
          if (stayEntry.startDate != null && stayEntry.endDate != null) {
            const existingPerdiem = updatedPerdiems.find(
              (perdiemEntry) =>
                perdiemEntry.startDate === stayEntry.startDate &&
                perdiemEntry.endDate === stayEntry.endDate
            );

            // If there is no existing perdiem entry with the same dates, fetch and add it
            if (!existingPerdiem) {
              const formulaSelected = formules.find(
                (item) =>
                  item.type?.toLowerCase() == stayEntry.type?.toLowerCase()
              );
              if (formData.missionType == "simple") {
                var perdiemResult = await fetchPerdiem(
                  formData.country?.region?._id,
                  formulaSelected?._id,
                  currentUser?.grade?._id
                );
              } else {
                const country = missions.find(
                  (item) => item.title == stayEntry.title
                )?.country;
                var perdiemResult = await fetchPerdiem(
                  country?.region?._id,
                  formulaSelected?._id,
                  currentUser?.grade?._id
                );
              }

              console.log("perdiemResult", perdiemResult);
              // Add the new perdiem result to the updatedPerdiems array
              updatedPerdiems.push({
                ...stayEntry,
                formula: formulaSelected,
                perdiem: perdiemResult?._id ? perdiemResult : null,
                indemnity: perdiemResult?.indemnity,
                currency: perdiemResult?.currency?._id,
                nb:
                  differenceInDays(
                    new Date(stayEntry.endDate),
                    new Date(stayEntry.startDate)
                  ) + 1,
              });
            }
          }
        }
      } else if (formData.formula) {
        // If there are no stay entries, prepare perdiems based on formule type
        if (formData.formula.type.toLowerCase() === "hybride") {
          // Prepare perdiems with two types: 'hotel' and 'personal accomodation'
          const formulaHotel = formules.find(
            (item) => item.type?.toLowerCase() == "hotel"
          );
          const formulaPersonal = formules.find(
            (item) => item.type?.toLowerCase() == "personal accomodation"
          );

          const perdiemHotel = await fetchPerdiem(
            formData.country?.region?._id,
            formulaHotel?._id,
            currentUser?.grade?._id
          );

          const perdiemPersonal = await fetchPerdiem(
            formData.country?.region?._id,
            formulaPersonal?._id,
            currentUser?.grade?._id
          );
          updatedPerdiems.push({
            type: "hotel",
            perdiem: perdiemHotel?._id ? perdiemHotel : null,
            indemnity: perdiemHotel?.indemnity,
            currency: perdiemHotel?.currency?._id,
          });
          updatedPerdiems.push({
            type: "personal accomodation",
            perdiem: perdiemPersonal._id ? perdiemPersonal : null,
            indemnity: perdiemPersonal?.indemnity,
            currency: perdiemPersonal?.currency?._id,
          });
        } else {
          // Prepare perdiems with a single entry based on the formule
          const perdiem = await fetchPerdiem(
            formData.country?.region?._id,
            formData.formula?._id,
            currentUser?.grade?._id
          );
          console.log("perdiem", perdiem);
          updatedPerdiems.push({
            type: formData.formula.type?.toLowerCase(),
            perdiem: perdiem?._id ? perdiem : null,
            startDate: formData.startDate,
            endDate: formData.endDate,
            indemnity: perdiem?.indemnity,
            currency: perdiem?.currency?.name,
            nb:
              differenceInDays(
                new Date(formData.endDate),
                new Date(formData.startDate)
              ) + 1,
          });
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        perdiems: updatedPerdiems,
      }));
      setPerdiems(updatedPerdiems);
    };

    updatePerdiems();
  }, [formData.stay]);

  useEffect(() => {
    const updateTransportation = async () => {
      let updatedItinerary = [];
      if (formData.needTransportation) {
        if (formData.missionType == "simple") {
          const departureCountry = countries?.find(
            (item) => item.name?.toLowerCase() == "tunisia"
          );
          console.log("departureCountry", departureCountry);
          const departureCity = allCities?.find(
            (item) => item.name?.toLowerCase() == "tunis"
          );
          console.log("departureCity", departureCity);
          console.log("allCities", allCities);
          const departureCities = allCities
            .filter((city) => city.country == departureCountry?._id)
            .map((item) => ({
              ...item,
              label: item.name,
              value: item._id,
            }));

          const arrivalCities = allCities
            .filter((city) => city.country == formData.country?._id)
            ?.map((item) => ({
              ...item,
              label: item.name,
              value: item._id,
            }));

          updatedItinerary = [
            {
              departureCountry: departureCountry,
              departureCity: departureCity,
              arrivalCountry: formData.country,
              arrivalCity: formData.city,
              departureDate: formData.startDate,
              transportType: "flight",
              departureCities: departureCities,
              arrivalCities: arrivalCities,
            },
            {
              departureCountry: formData.country,
              departureCity: formData.city,
              arrivalCountry: departureCountry,
              arrivalCity: departureCity,
              departureDate: formData.endDate,
              transportType: "flight",
              departureCities: arrivalCities,
              arrivalCities: departureCities,
            },
          ];
        } else {
          missions.map((mission, index) => {
            if (index == 0) {
              var departureCountry = countries?.find(
                (item) => item.name?.toLowerCase() == "tunisia"
              );
              var departureCity = allCities?.find(
                (item) => item.name?.toLowerCase() == "tunis"
              );
              var departureCities = allCities
                .filter((city) => city.country == departureCountry?._id)
                .map((item) => ({
                  ...item,
                  label: item.name,
                  value: item._id,
                }));

              var arrivalCities = allCities
                .filter((city) => city.country == mission.country?._id)
                ?.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item._id,
                }));
              updatedItinerary.push({
                departureCountry: departureCountry,
                departureCity: departureCity,
                arrivalCountry: mission.country,
                arrivalCity: mission.city,
                departureDate: mission.startDate,
                transportType: "flight",
                departureCities: departureCities,
                arrivalCities: arrivalCities,
              });
            } else {
              console.log(
                "mission[index-1]",
                missions[index - 1],
                "mission",
                mission
              );

              if (missions[index - 1]?.city?._id != mission.city?._id) {
                var departureCountry = countries?.find(
                  (item) => item._id == missions[index - 1]?.country?._id
                );
                var departureCity = allCities?.find(
                  (item) => item._id == missions[index - 1]?.city?._id
                );
                var departureCities = allCities
                  .filter((city) => city.country == missions[index - 1]?._id)
                  .map((item) => ({
                    ...item,
                    label: item.name,
                    value: item._id,
                  }));

                // else {
                // updatedItinerary.push({
                //   departureCountry: departureCountry,
                //   departureCity: departureCity,
                //   arrivalCountry: item.country,
                //   arrivalCity: item.city,
                //   departureDate: item.startDate,
                //   transportType: "flight",
                //   departureCities: departureCities,
                //   arrivalCities: arrivalCities,
                // });

                var arrivalCities = allCities
                  .filter((city) => city.country == mission.country?._id)
                  ?.map((item) => ({
                    ...item,
                    label: item.name,
                    value: item._id,
                  }));

                updatedItinerary.push({
                  departureCountry: departureCountry,
                  departureCity: departureCity,
                  arrivalCountry: mission.country,
                  arrivalCity: mission.city,
                  departureDate: mission.startDate,
                  transportType: "flight",
                  departureCities: arrivalCities,
                  arrivalCities: departureCities,
                });
                // }

                if (index == missions.length - 1) {
                  var arrivalCountry = countries?.find(
                    (item) => item.name?.toLowerCase() == "tunisia"
                  );
                  var arrivalCity = allCities?.find(
                    (item) => item.name?.toLowerCase() == "tunis"
                  );

                  var arrivalCities = allCities
                    .filter((city) => city.country == arrivalCountry?._id)
                    ?.map((item) => ({
                      ...item,
                      label: item.name,
                      value: item._id,
                    }));
                  updatedItinerary.push({
                    departureCountry: mission.country,
                    departureCity: mission.city,
                    arrivalCountry: arrivalCountry,
                    arrivalCity: arrivalCity,
                    departureDate: mission.endDate,
                    transportType: "flight",
                    departureCities: arrivalCities,
                    arrivalCities: departureCities,
                  });
                }
              }
            }
          });
        }
      }

      // setItinerary(updatedItinerary);
      setFormData((prevData) => ({
        ...prevData,
        itinerary: updatedItinerary,
      }));
    };

    countries.length > 0 && allCities.length > 0 && updateTransportation();
  }, [missions, formData.country, formData.city, countries, allCities]);

  console.log("formData", formData);
  console.log("stayyyyyy", stay);
  // console.log(" formData perdiems", missions);

  const handleSubmit = async () => {
    if (
      activeStep == steps.findIndex((step) => step.name === "Transportation")
    ) {
      const allFieldsFilled = formData.itinerary.every((item) => {
        return (
          item.transportType &&
          item.departureCountry &&
          item.departureCity &&
          item.arrivalCountry &&
          item.arrivalCity
        );
      });

      if (!allFieldsFilled) {
        NotificationManager.error(
          "Please fill in all required fields before proceeding."
        );
        return;
      }
    }
    setLoading(true);

    if (formData.missionType == "simple") {
      var data = {
        ...formData,
        submittedBy: user.id,
        idSender: formData.idSender ? formData.idSender : user.id,
      };
    } else {
      var data = {
        ...formData,
        submittedBy: user.id,
        missions: missions,
        idSender: formData.idSender ? formData.idSender : user.id,
      };
    }

    console.log("data", data);

    var result = await addMission(data);
    if (result.status === 200) {
      if (result.data && result.data.status == 201) {
        NotificationManager.error(result.data.message);
      } else {
        NotificationManager.success(result.message);
        if (passportFile) {
          const formData = new FormData();
          formData.append("file", passportFile);
          formData.append("fieldName", "passportFile");
          // console.log("ressssult", result.data);
          await addFile(formData, result.data?.mission);
        }
        if (visaFile) {
          const formData = new FormData();
          formData.append("file", visaFile);
          formData.append("fieldName", "visa");
          // console.log("ressssult", result.data);
          await addFile(formData, result.data?.mission);
        }
        window.location.href = "/missions#/pending";
      }
    } else {
      NotificationManager.error(result.message);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="flex flex-wrap justify-center mt-5">
        <div className="container max-w-7xl mx-auto px-4">
          {isDesktop && (
            <div style={{ position: "absolute", right: "13%" }}>
              <Breadcrumbs>
                <Link to="/home">
                  <Home fontSize="small" htmlColor="gray" />
                </Link>
                <Link to="/onlinework" className="opacity-60">
                  <span>Business Travel </span>
                </Link>
                {/* <a href="#">Building </a> */}
              </Breadcrumbs>
            </div>
          )}
        </div>
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              {isDesktop ? (
                <>
                  <div className="w-full text-center">
                    <Typography variant="h3" style={{ color: color }}>
                      <Translation message={"You have a business travel?"} />
                    </Typography>

                    <Typography variant="small" color="gray" className="mt-2">
                      <Translation
                        message={
                          "Complete this form to send a business travel request."
                        }
                      />
                    </Typography>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex gap-8 mt-16 mb-12">
                      <div className="w-full px-24 py-4">
                        <Stepper
                          activeStep={activeStep}
                          isLastStep={(value) => setIsLastStep(value)}
                          isFirstStep={(value) => setIsFirstStep(value)}
                          activeLineClassName="bg-indigo-500"
                        >
                          {steps?.map((step, index) => (
                            <Step
                              // onClick={() => setActiveStep(index)}
                              activeClassName="bg-indigo-500"
                              completedClassName="bg-indigo-500 "
                            >
                              {step.icon}
                              <div className="absolute -bottom-[4.5rem] w-max text-center">
                                <Typography
                                  variant="h6"
                                  color={
                                    activeStep === index ? "blue-gray" : "gray"
                                  }
                                >
                                  <Translation message={step.name} />
                                </Typography>
                                <Typography
                                  color={
                                    activeStep === index ? "blue-gray" : "gray"
                                  }
                                  variant="small"
                                  className="font-normal"
                                >
                                  <Translation message={step.desc} />
                                </Typography>
                              </div>
                            </Step>
                          ))}
                        </Stepper>
                        <div className="mt-32">
                          {React.cloneElement(steps[activeStep].component, {
                            formData,
                            setFormData,
                            permissions,
                            currentUser,
                            users,
                            formules,
                            passportFile,
                            setPassportFile,
                            setIsEngagementCodeValid,
                            isEngagementCodeValid,
                            allCities,
                            countries,
                            missions,
                            setMissions,
                            errors,
                            visaFile,
                            setVisaFile,
                            stay,
                            setStay,
                            perdiems,
                            setPerdiems,
                          })}
                        </div>
                        <div className="mt-10 flex justify-between">
                          <Button
                            onClick={handlePrev}
                            disabled={isFirstStep}
                            color="indigo"
                          >
                            <Translation message="Prev" />
                          </Button>
                          {isLastStep ? (
                            <Button
                              onClick={handleSubmit}
                              color="indigo"
                              disabled={loading}
                            >
                              {loading ? (
                                <ClipLoader color="white" size={20} />
                              ) : (
                                <Translation message="Submit" />
                              )}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleNext}
                              disabled={isLastStep}
                              color="indigo"
                            >
                              <Translation message="Next" />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="w-full text-center">
                    <Typography
                      variant="h5"
                      style={{ color: color, marginTop: isDesktop ? 0 : "30%" }}
                    >
                      <Translation message={"You have a business travel?"} />
                    </Typography>

                    <Typography variant="small" color="gray" className="mt-2">
                      <Translation
                        message={
                          "Complete this form to send a business travel request."
                        }
                      />
                    </Typography>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()} className="mt-16">
                    <div className="flex justify-center mt-20">
                      <Button
                        style={{ backgroundColor: "#083985" }}
                        ripple="light"
                        onClick={() => {
                          // handleSend();
                        }}
                      >
                        {/* <ClipLoader color="white" loading={loading} size={20} /> */}
                        <Translation message={"Send Request"} />
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <NotificationContainer />
    </>
  );
}
