import React, { useState } from "react";
import { Dialog, Card, CardBody, Typography } from "@material-tailwind/react";
import schedulerData from "data/scheduler-data";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Translation from "Translation";
function DialogPage(props) {
  const {
    open,
    handleOpen,
    setName,
    setOpenForm,
    horizon,
    startDate,
    endDate,
  } = props;

  const handleClick = (name) => {
    if (
      (name == "On Site" &&
        // moment(startDate).format("L") >=
        // moment()
        //   .add("days", horizon - 1)
        //   .format("L")) ||
        moment(startDate).isAfter(
          moment()
            .add("days", horizon - 1)
            .format()
            .substring(0, 10),
          "year"
        )) ||
      (name == "On Site" &&
        // moment(endDate).format("L") >
        //   moment()
        //     .add("days", horizon - 1)
        //     .format("L"))
        moment(endDate).isAfter(
          moment()
            .add("days", horizon - 1)
            .format()
            .substring(0, 10),
          "year"
        ))
    ) {
      NotificationManager.error(
        <Translation message={"You cannot select date out of horizon"} />
      );
      return;
    }
    setName(name);
    setOpenForm(true);
    handleOpen();
  };

  return (
    <div>
      {/* <Dialog
        open={open}
        handler={handleOpen}
        style={{ backgroundColor: "transparent" }}
      > */}
      <div className="flex flex-wrap items-center mt-10">
        <div className="ml-auto mr-auto w-full px-4 text-center lg:w-12/12 mt-20">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {schedulerData.map(({ name, icon }) => (
              <Card
                className="rounded-2xl shadow-lg shadow-gray-500/10 pointer"
                // onClick={() => {
                //   console.log("hiiii", name);
                //   toggleDrawer("left", true, name);
                // }}
                onClick={() => {
                  handleClick(name);
                }}
              >
                <CardBody className="px-8 text-center">
                  {icon}
                  <Typography variant="h6" color="blue-gray">
                    <Translation message={name} />
                  </Typography>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      </div>
      {/* </Dialog> */}
    </div>
  );
}

export default DialogPage;
