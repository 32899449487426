import DefaultFooter from "../components/Footers/DefaultFooter";

import Section from "../components/ClientSite/Section";
import DefaultNavbar from "../components/Navbars/DefaultNavbar";

export default function ClientSite() {
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Section />
      </main>
      <DefaultFooter />
    </>
  );
}
